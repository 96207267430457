/* eslint-disable no-restricted-imports */
/* jshint asi:true */

import React, { useState } from "react";
import { Link,useHistory } from "react-router-dom";
import { FormikConsumer, useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../modules/Auth/_redux/authRedux"
import Helmet from "react-helmet"
import { Formik,  Field } from 'formik';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import alertService from "../_services/alertservice";
import { useDispatch } from "react-redux";
import { logout } from "../../actions/auth";


const validateConfirmPassword = (pass, value) => {
  let error = "";
  if (pass && value) {
    if (pass !== value) {
      error = "Password not matched";
    }
  } else {
    error = "Password not matched";
  }
  return error;
};


// const { REACT_APP_TEST } = process.env;
// console.log(REACT_APP_TEST)
/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/
/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  oldPassword: "",
  password: "",
  confirmPassword: "",
};



function ChangePasswordPage(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
 
  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Minimum 8 Characters")
      .max(12, "Maximum 12 Characters")
      .required('Please Enter your password')
      .matches(
        /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
      confirmPassword: Yup.string()
      
      .required('Please Enter your password'),
     

      oldPassword: Yup.string()
      .required('Please Enter your password')
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    validateOnChange:true,
    validateOnBlur: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      if (values.password == values.confirmPassword) {
        resetPassword(values, setStatus, setSubmitting);
      }
    },
  });


  const histroy =  useHistory();
  const resetPassword = (values, setStatus, setSubmitting) => {
       

    if(values.password !== values.confirmPassword){
      return;
  }

    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/user/change_password`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token

      },
      body: JSON.stringify(values)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          disableLoading();
          histroy.push("/dashboard");
          alertService.alert({ message : res.message, type : 'success'  });
          setSubmitting(false);
          values.password="";
          values.oldPassword="";
          values.confirmPassword="";
          Formik.resetForm();

        }
        else if(res.sessionExpired){
          logoutMethod();
          alertService.alert({ message :res.message, type : 'error'  });
        }
          else {
          alertService.alert({ message : res.message, type : 'error'  });
          disableLoading();
          setSubmitting(false);
        }
      })
      .catch(() => {
        disableLoading();
        setSubmitting(false);
      });
  }

  const  submitFormMethod = () => {
    var submitbtn =  document.getElementById("submit-btn");
    if(submitbtn) {
      submitbtn.click();
    }
  }

  
  const history = useHistory() 
  const dispatch = useDispatch();

  const logoutMethod = () => {
    dispatch(logout());
    history.push('/auth/signin');
};



  
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false
  });

  const [values1, setValues1] = React.useState({
    password: '',
    showConfirmPassword: false
  });


  const [values2, setValues2] = React.useState({
    password: '',
    showConfirmPassword: false
  });


  const handleClickPassword = () => {
    setValues2({ ...values2, showPassword: !values2.showPassword });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowConfirmPassword = () => {
    setValues1({ ...values1, showConfirmPassword: !values1.showConfirmPassword });
  };

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };


  return (
    <>
      <Helmet>
        <title> VRekruit | Change Password</title>
      </Helmet>

         {/* subeader */}
         <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Change Password</h3></div>
          </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
      {/* subeader */}
   


    <Row className="bg-white pt-4 pb-4">
    <Col md={6} sm={12} offset= {4}  className="pr-3 pl-3">      
      <div>
       <div className="d-flex flex-grow-1 align-items-center  justify-content-start   rounded">     
        <div>
            <h5 className="font-weight-bold f-w-600 pl-5" >Change Password </h5>
            <p className="text-muted mb-0 pl-5"> Change your account password</p>
        </div>
      </div>
      </div>
      </Col>
      <Col md={6} sm={12} offset= {4}  className="pr-3 pl-3">
      <div>
       <div className="d-flex flex-grow-1 align-items-center justify-content-end    rounded">
        <div className="text-muted d-block mt-1">
           <button type="button" className="btn btn-theme-green btn-lg mr-5"  onClick={submitFormMethod} >
              Save
           </button>
      
      </div>
      </div>
      </div>
      </Col>
      <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5 mb-5"></Col>  

      <div className="container" style={{"minHeight":"60vh"}}>
            <Form  onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">

              <Form.Group as={Row}>
                <Form.Label column sm={2}>
                  Current Password*
                </Form.Label>
                <Col sm={10} md={7}>
                <TextField
                    id="filled-adornment-password1"
                    className={`1clsx(classes.margin, classes.textField) form-control form-control-solid h-auto `}
                    variant="filled"
                    type={values2.showPassword ? 'text' : 'password'}
                    label="Password"
                    value={values2.password}
                    onChange={handleChange('oldPassword')}
                    InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                        <IconButton
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={handleClickPassword}
                        >
                            {values2.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                        </InputAdornment>
                    ),
                    }}
                    name="oldPassword"
                    {...formik.getFieldProps("oldPassword")}
                />  
            {formik.touched.oldPassword && formik.errors.oldPassword ? (
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.oldPassword}</div>
                </div>
            ) : null}          
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    New Password*
                  </Form.Label>
                  <Col sm={10} md={7}>
                  <TextField
                                        id="filled-adornment-password2"
                                        className={`1clsx(classes.margin, classes.textField) form-control form-control-solid h-auto `}
                                        variant="filled"
                                        type={values.showPassword ? 'text' : 'password'}
                                        label="Password"
                                        value={values.password}
                                        onChange={handleChange('password')}
                                        InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                aria-label="Toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                            </InputAdornment>
                                        ),
                                        }}
                                        name="password"
                                        {...formik.getFieldProps("password")}
                                    />  
                                {formik.touched.password && formik.errors.password ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.password}</div>
                                    </div>
                                ) : null}
                  </Col>
              </Form.Group>
              <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    Confirm Password*
                  </Form.Label>
                  <Col sm={10} md={7}>
                  <TextField
                                        id="filled-adornment-password3"
                                        className={`1clsx(classes.margin, classes.textField) form-control form-control-solid h-auto `}
                                        variant="filled"
                                        type={values1.showConfirmPassword ? 'text' : 'password'}
                                        label="Password"
                                        value={values.password}
                                        onChange={handleChange('password')}
                                        InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                aria-label="Toggle password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                            >
                                                {values1.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                            </InputAdornment>
                                        ),
                                        }}
                                        name="confirmPassword"
                                    {...formik.getFieldProps("confirmPassword")}
                                    /> 

                                {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                  <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.confirmPassword}</div>
                               </div>
                               ) : null}  
                           

                                <span className="d-flex" > 
                                    {(formik.touched.confirmPassword || formik.isSubmitting) && formik.values.confirmPassword != formik.values.password ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                        {(<div>Password do not match</div>)}
                                        </div>
                                    </div>
                                    ) : null}
                                </span>

                  </Col>
              </Form.Group>

              <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                  </Form.Label>
                  <Col sm={10} md={7}>
                    <small>
                      Note: Use atleast 8 characters Use upper and lower case characters. Use atleast 
                      one number. Use atleast one special character. Max 12 characters
                    </small>
                  </Col>
              </Form.Group>

             
              <button type="submit" id="submit-btn" className="d-none" >
              {loading && <span className="ml-3 spinner spinner-white"></span>}
                 submit
              </button>
            </Form>   
            </div> 
      </Row>

      </>
      
  );
}

export default injectIntl(connect(null, auth.actions)(ChangePasswordPage));
