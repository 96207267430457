/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React, { useEffect, useState } from 'react';
import Helmet from "react-helmet"
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useHistory,Link } from "react-router-dom";
import { setStorage } from './../../../_metronic/_helpers/LocalStorageHelpers';
import alertService from '../../_services/alertservice';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'









const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing { from } to { to } of { size } Results
    </span>
  );
 
 

 
 
 const selectRow = {
    mode: 'radio',
    clickToSelect: true
  };
  



function TabContainer({ children, dir }) {
    return (
      <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
        {children}
      </Typography>
    );
  }
  
  TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired,
  };
  
  const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      
    },
  }));







function ViewCategory() {


  const [requistions, setRequistions] = useState([]);
  const [subCategoryList, setsubCategoryList] = useState([]);
  const [parentCategoryName, setCategoryName] = useState("")

  const [staticData, setStaticData] = useState();

  const history = useHistory();


  var i = 1;

  // get categoryList
  const get_Requisition_List = () => {
    const token = localStorage.getItem('x-access-token');
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/test/categories?page=1&limit=1000`;


    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setRequistions(res.data);
          setStaticData(staticData + 10);
        }
        else {
        }
      })
      .catch(() => {
      });
  }
  //get categoryList


  //get SubCategoryList
     
  const get_SubCategory_List = (id) => {
    const token = localStorage.getItem('x-access-token');
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/test/sub_categories?page=1&limit=1000&parentCategoryId=`+id;

    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setsubCategoryList(res.data);
          if(res.data.length > 0) {
            setCategoryName(res.data[0].parentCategory.categoryName);
            handleChangeIndex(1);
          }
           else if(res.data) {
            setCategoryName("");
            alertService.alert({ message: "No Sub-Categories Found For this Category", type : 'info'  });
          }
          setStaticData(staticData + 10);
        }
        else {
        }
      })
      .catch(() => {
      });

      
  }


  //get SubCategoryList
  const  deleteSubCategory = (value) => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/test/delete_sub_category/` + value, {
      
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
    .then((res) => res.json())
    .then((res) => {
      if(res.status==true){
     setModalShow3(false);
     alertService.alert({ message : res.message, type : 'error'  });
     get_SubCategory_List(parentCategoryId);

    //  get_sub_categories(selectedId);    

      }
      else{
       setModalShow3(false);
        alertService.alert({ message : res.message, type : 'error'  });

      }
    })
    .catch(() => {          
    });
}










  useEffect(() => {
    let mounted = true;
    get_Requisition_List();
    return () => mounted = false;
  }, [])



  function actionFormatter(column, colIndex) {
    return (
      <div className="text-left">
        <img src="/media/VRekruitIcons/eyeorview.svg" className="view-option c-p view-eye-ico" />
        {/* <img src="/media/VRekruitIcons/edit.svg" className="sort-icon ml-3 c-p" width="30" height="30" /> */}
          {/* <img src="/media/VRekruitIcons/delete.svg" className="sort-icon ml-3 c-p" width="30" height="30" /> */}
      </div>
    );
  }

  function headerformatter(column, colIndex) {
    return (
      <div>
        {column.text}       
      <img src="/media/VRekruitIcons/sort.svg" className="sort-icon c-p" width="30" height="30" />
      </div>
    );
  }


  function actionFormatterSubCategory(column, colIndex) {
    return (
      <div className="text-left">
        {/* <img src="/media/VRekruitIcons/edit.svg" className="sort-icon ml-3 c-p" width="30" height="30" /> */}
          <img src="/media/VRekruitIcons/delete.svg" className="sort-iconc c-p delete_sub_category"  width="20" height="20" />
      </div>
    );
  }

  function headerformatter(column, colIndex) {
    return (
      <div>
        {column.text}   
          <img src="/media/VRekruitIcons/sort.svg" className="sort-icon c-p" width="30" height="30" />
          

      </div>
    );
  }

  function headerformatterAction(column, colIndex) {
    return (
      <div>
        {column.text}   
          
      </div>
    );
  }


  function headerformatterAction(column, colIndex) {
    return (
      <div>
        {column.text}   
          
      </div>
    );
  }

  function headerformatterSubCategoryAction(columnsSubcategory, colIndex) {
    return (
      <div>
        {columnsSubcategory.text}   
          
      </div>
    );
  }


  

  function  CreatedByUserFormater(column, colIndex) {
    return(
      <span>
        {column.firstName}  {column.lastName} 
      </span>
    );
  }






  // parent Category columns
 var columns = [{
    dataField: 'categoryName',
    text: "PARENT CATEGORY NAME",
    sort: true,
    headerFormatter: headerformatter
  }, 

  {
    dataField: 'createdByUser',
    text: "Created By",
    sort: true,
    formatter:CreatedByUserFormater,
    headerFormatter: headerformatter
  },
  {
    dataField: 'updatedAt' ? 'updatedAt' :"" ,
    text: "MODIFIED ON",
    sort: true,
    headerFormatter: headerformatter,
    formatter: (cell) => {
    let dateObj = cell;
    if (typeof cell !== 'object') {
      dateObj = new Date(cell);
    }
    return `${('0' + dateObj.getUTCDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${dateObj.getUTCFullYear()}`;
  }
  },


  {
    dataField: "action",
    text: "Action",
    sort: true,
    formatter: actionFormatter,
    headerFormatter: headerformatterAction
  },
  ];
//parent category 


//sub categories

var columnsSubcategory = [{
  dataField: 'subCategoryName',
  text: "Sub CATEGORY NAME",
  sort: true,
  headerFormatter: headerformatter 
}, 

{
  dataField: 'createdByUser.firstName',
  text: "Created By",
  sort: true,
  headerFormatter: headerformatter
},

{
  dataField: 'updatedAt' ,
  text: "MODIFIED ON",
  sort: true,
  headerFormatter: headerformatter,
  formatter: (cell) => {
  let dateObj = cell;
  if (typeof cell !== 'object') {
    dateObj = new Date(cell);
  }
  return `${('0' + dateObj.getUTCDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${dateObj.getUTCFullYear()}`;
}
},

{
  dataField: "action",
  text: "Action",
  sort: true,
  formatter: actionFormatterSubCategory,
  headerFormatter: headerformatterSubCategoryAction
},
];

//sub categories

  const options = {
    paginationSize: 10,
    // custom: true,
    pageSize: 20,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    //  withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    onPageChange: (page, sizePerPage) => {
     
    },
    disablePageTitle: true,
    sizePerPageList: [ {
      text: '20', value: 20
    },{
    text: '50', value: 50
    },
    {
      text: 'All', value: requistions.length
    }] // A numeric array is also available. the purpose of above example is custom the text
  };


  const actionControls = {
    onClick: (e, row, rowIndex) => {
   if(row) {
       let id = row.id;
       if (e.target.classList.contains("view-option")) {
        SetParentCategoryId(id);
        get_SubCategory_List(id);
      //  history.push("/view-requistions/" + id)
       // get_Requisition_List();
      }
    }
  }
  };

  const actionControlssubCategories = {
    onClick: (e, row, rowIndex) => {
   if(row) {
       let id = row.id;
       if (e.target.classList.contains("delete_sub_category")) {
        setModalShow3(true);
        SetDeleteSubCategoryId(id);
        // get_SubCategory_List(id);
      //  history.push("/view-requistions/" + id)
       // get_Requisition_List();
      }
    }
  }
  };



  const [modalShow3, setModalShow3] = React.useState(false);
  const [delId, SetDeleteSubCategoryId   ] =useState("");
  const [parentCategoryId, SetParentCategoryId   ] =useState("");

  function DeleteConfirmationModal(props) {
    return (
          <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          >

          <Modal.Body>
            <Modal.Title id="contained-modal-title-vcenter text-center">
              <p className="text-center">
              <img alt="logo" className="brand-dim" src="/media/logos/UPDATED_VRekruit _Logo.png" />
                </p>
              <p className="text-dark text-center pt-3"> Are you sure want to delete this sub category ?</p>
            </Modal.Title>
          </Modal.Body>
          <Modal.Title className="d-flex justify-content-center align-items-center pb-5" >
          <p className="text-center">

          <Button className="btn btn-theme text-white btn-lg mr-5  pl-5 pr-5 pt-3 pb-3" style={{"minWidth": "100px"}} onClick={() => deleteSubCategory(delId)}> Yes </Button>
          <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green" style={{"minWidth": "100px"}}  onClick={props.onHide}>No</Button> 
          </p>
          </Modal.Title>
          </Modal>
    );
  }






  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }

    return <>
        <Helmet>
            <title> VRekruit | View Category</title>
       </Helmet>


        {/* subeader */}
        <div id="kt_subheader" className="subheader py-2 py-lg-4    subheader-solid">
      <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <div className="d-flex align-items-center flex-wrap mr-1">
          <div className="d-flex align-items-baseline mr-5">
            <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Category Management</h3></div>
            </div>
        <div className="d-flex align-items-center" />
      </div>
    </div>
  {/* subeader */}
  <Col className="bg-white" >


  <Row className="bg-white pt-4 pb-4 align-items-center" style={{"width":"100%"}}>
    <Col md={10} sm={12} offset= {4}  className="pr-3 pl-3">      
      <div>
       <div className="d-flex flex-grow-1 align-items-center  justify-content-start   rounded">     
        <div>
        <h5 className="font-weight-bold f-w-600 pl-5" > Category Management &nbsp;&nbsp; {'>'} &nbsp;&nbsp; View Category   
           {parentCategoryName ?     "   >  " + parentCategoryName    : ""  }
        </h5>
        </div>
      </div>
      </div>
      </Col>
      <Col md={2}  sm={12} offset= {4}  className="pr-3 pl-3">
      <div>
       <div className="d-flex flex-grow-1 align-items-center justify-content-end rounded mt-1">
        <div className="text-muted d-block">
           <Link to="/parent-category" className="btn btn-theme-green btn-lg mr-5"  >
              Create Category
           </Link>
      </div>
      </div>
      </div>
      </Col>


      <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5"></Col>  

     {/* <switch> */}

      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Parent Category" />
          <Tab label="Sub Category" style={{"pointerEvents": "none" }} />
        </Tabs>
      </AppBar>
      <SwipeableViews style={{"width":"100%"}}
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabContainer dir={theme.direction}>
    
        {requistions  && requistions.length > 20 && (
              <BootstrapTable className="bg-white" keyField='id' data={requistions} columns={columns} options={options} pagination={paginationFactory(options)} rowEvents={actionControls} />
            )}
            {requistions  && requistions.length <= 20 &&
            (
              <BootstrapTable className="bg-white" keyField='id' data={requistions} columns={columns} options={options}  rowEvents={actionControls} />
            )
            }     
              
        </TabContainer>
        <TabContainer dir={theme.direction} >
    



              {subCategoryList  && subCategoryList.length > 20 && (
              <BootstrapTable className="bg-white" keyField='id' data={subCategoryList} columns={columns} options={options} pagination={paginationFactory(options)} rowEvents={actionControls} />
            )}
            {subCategoryList  && subCategoryList.length <= 20 &&
            (
              <BootstrapTable className="bg-white" keyField='id' data={subCategoryList} columns={columns} options={options}  rowEvents={actionControls} />
            )
            } 
               
        </TabContainer>
      </SwipeableViews>

    </Row>


   
   </Col>
    <DeleteConfirmationModal show={modalShow3}onHide={() => setModalShow3(false)}/>





     
    </>;
}

export default ViewCategory