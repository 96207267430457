/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo, useLayoutEffect, useEffect} from "react";
import objectPath from "object-path";
import {useLocation} from "react-router-dom";
import {QuickActions} from "./components/QuickActions";
import {BreadCrumbs} from "./components/BreadCrumbs";
import {getBreadcrumbsAndTitle, useSubheader} from "../../_core/MetronicSubheader";
import {useHtmlClassService} from "../../_core/MetronicLayout"

export function SubHeader() {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
          uiService.config,
          "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
          "subheader_container",
          true
      )
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    const breadcrumbs = (aside && aside.breadcrumbs.length > 0) ? aside.breadcrumbs : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle((aside && aside.title && aside.title.length > 0) ? aside.title : header.title);
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);

  return (
      <>
      </>
  )
}




          // <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
          //   <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          //     <div className="d-flex align-items-center flex-wrap mr-1">
          //       <div className="d-flex align-items-baseline mr-5">
          //         <h5 className="text-dark font-weight-bold my-2 mr-5">View Requistion</h5></div>
          //         <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2">
          //           <li className="breadcrumb-item">
          //             <a href="/dashboard">
          //               <i className="flaticon2-shelter text-muted icon-1x" />
          //              </a>
          //           </li>
          //           <li className="breadcrumb-item">
          //             <a className="text-muted" href="/view-requistion">View Requistion</a>
          //           </li>
          //           </ul>
          //         </div>
          //       <div className="d-flex align-items-center" />
          //     </div>
          // </div>




        //   <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
        //   <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        //     <div className="d-flex align-items-center flex-wrap mr-1">
        //       <div className="d-flex align-items-baseline mr-5">
        //         <h5 className="text-dark font-weight-bold my-2 mr-5">View Requistion</h5></div>
        //         <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2">
        //           <li className="breadcrumb-item">
        //             <a href="/dashboard">
        //               <i className="flaticon2-shelter text-muted icon-1x" />
        //              </a>
        //           </li>
        //           <li className="breadcrumb-item">
        //             <a className="text-muted" href="/view-requistion">View Requistion</a>
        //           </li>
        //           </ul>
        //         </div>
        //       <div className="d-flex align-items-center" />
        //     </div>
        // </div>

