
/* eslint-disable no-restricted-imports */
/* jshint asi:true */

import React, { useState,useEffect } from "react";
//import { Link } from "react-router-dom";
//import * as Yup from "yup";
//import { connect } from "react-redux";
//import { FormattedMessage, injectIntl } from "react-intl";
//import * as auth from "../_redux/authRedux";
import Helmet from "react-helmet"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import Carousel from 'react-bootstrap/Carousel'
import { useParams, useHistory } from 'react-router-dom';
// import VideoRecorder from 'react-video-recorder'
import { isAndroid, isIOS, isSafari, isChrome, isFirefox, osName} from "react-device-detect";
import ReactPlayer from "react-player";
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
//import {browserHistory} from 'react-router';
import alertService from "../../../_services/alertservice";
import axios from 'axios';
import {logout} from "../../../../actions/auth";
import { useDispatch } from 'react-redux';
//import { ControlBar, PlayToggle, LoadingSpinner } from 'video-react';
import './recorder-custom.css'
//import FileUploadProgress  from 'react-fileupload-progress';
// import { window } from "rxjs/operators";
import { Media, Player, controls, utils } from 'react-media-player'
//import { withMediaProps } from 'react-media-player'
import{ useRef} from 'react'
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {usePageVisibility }  from 'react-page-visibility';
import ysFixWebmDuration from 'fix-webm-duration';


const queryString = require('query-string');
const { keyboardControls } = utils
function InterviewLink(props) {

  const[replayButtonState, SetreplayButtonState]=useState(false);
  const[questionReplayCounter,SetQuestionReplayCounter]=useState(false);
  const[interviewStarted,SetInterviewStarted]=useState(false);
  const isPageVisible = usePageVisibility()
  const [onBlurCalled,SetOnBlurFlag]=useState(false)
  const [OfflineTime,SetOfflineTime]=useState()
  const [OfflineTime1,SetOfflineTime1]=useState()
  const [bgCount, SetBgCount] = useState(0)
    const [internetCount, SetInternetCount] = useState(0)
  const theme = useTheme();
  const [playing,SetPlaying]=useState(false)
  const [NextQues,SetNextState]=useState(false)
  const [ShowAnsVideo,SetShowAnsVideo]=useState(true)
  const [replayingQuesVideo,SetReplayingQuesVideo]=useState(false)
  const [startTimer,SetStartTimer]=useState(false)
  const [stopAnswerVideo,SetStopAnswerVideo]=useState(false)
  const [showSafeMessage,setShowSafeMessage]=useState(false)
  
  const [quesReplayed,SetQuesReplayed]=useState([])
  const[interviewDetails,SetInterviewDetails] = useState()
  const[interviewWindow, setInterviewWindow]=useState(false);
  const [answerStartIn,setAnswerStartIn]=useState()
  const [mediaRecorderObj, setMediaRecorderObj] = useState()
  const [showStopBtn,setShowStopBtn] = useState(false)
  const [cameraEnabled, setCameraEnabled] = useState(false);
  const [videoStartTime, setVideoStartTime] =useState()
  const [alreadyInterrupted, setAlreadyInterrupted] = useState(false);

  let media_recorder = null;
  let blobs_recorded = [];
  let camera_stream = null;

  window.replayingQuesVideo = false
  
  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });



  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  function onButtonClick() {
    // This will allow us to play video later...
     document.getElementById('ques-video').load();
  //  video.load();
    fetchVideoAndPlay();
  }

  function fetchVideoAndPlay() {
    var video = document.getElementById('ques-video')
    var videoPath = InterviewQuestionData[CurrentQuesIndex].videoPath
    fetch(videoPath)
    .then(response => response.blob())
    .then(blob => {
      video.srcObject = blob;
      return video.play();
    })
    .then(_ => {
      // Video playback started ;)
    })
    .catch(e => {
      // Video playback failed ;(
    })
  }

  function CustomizedDialogs() {  
    return (
    <div>
    <Dialog 
            style={{"backgroundColor":"#F0F0F0 !important"}}

        fullWidth={fullWidth}
        maxWidth={maxWidth}
        fullScreen={fullScreen}
        onClose={handleClose} aria-labelledby="customized-dialog-title1"
        open={open}>
          {/* Temporary Hiddeen */}
      <DialogTitle id="customized-dialog-title"
              style={{"backgroundColor":"#F0F0F0 !important"}}

      onClose={handleClose}>
   
      </DialogTitle>
      <DialogContent dividers className="mt-5 mb-5"
              style={{"backgroundColor":"#F0F0F0 !important"}}

      >
        <Typography gutterBottom className="ml-10">

          <h2 className="d-flex">
          <img className="d-inline mr-5" width="25" src="/media/VRekruitIcons/Job Title.svg" />
          Job Title
          </h2>
          <h4 className="pl-32rem pb-5">
          {interviewDetails?.requisition.position}

          </h4>
          <h2 className="d-flex">
          <img className="d-inline mr-5" width="25" src="/media/VRekruitIcons/job-description.svg" />
                Job Descripition 
          </h2>

          <div dangerouslySetInnerHTML={{__html: interviewDetails?.requisition.jobDescription}} />
         
       


       {interviewDetails && interviewDetails?.requisition?.file  &&
          <>
          <h2 className="d-flex">
          <img className="d-inline mr-5" width="25" src="/media/VRekruitIcons/attachment.svg" />
          Attached File

          </h2>

         <h4 className="pl-32rem pb-5">
         <a href={interviewDetails?.requisition?.file} className="text-theme-green mt-0 f-w-600" target="_blank">
           {interviewDetails?.requisition?.fileName} fdsgfd
         </a>
         </h4>   
         </>  
         } 
         
        </Typography>
      </DialogContent>
      <DialogActions>
      
      </DialogActions>
    </Dialog>
    </div>
    );
    }

    function SoundMeter(context) {
      this.context = context;
      this.instant = 0.0;
      this.slow = 0.0;
      this.clip = 0.0;
      this.script = context.createScriptProcessor(2048, 1, 1);
      const that = this;
      this.script.onaudioprocess = function(event) {
        const input = event.inputBuffer.getChannelData(0);
        let i;
        let sum = 0.0;
        let clipcount = 0;
        for (i = 0; i < input.length; ++i) {
          sum += input[i] * input[i];
          if (Math.abs(input[i]) > 0.99) {
            clipcount += 1;
          }
        }
        that.instant = Math.sqrt(sum / input.length);
        that.slow = 0.95 * that.slow + 0.05 * that.instant;
        that.clip = clipcount / input.length;
      };
    }
    
    SoundMeter.prototype.connectToSource = function(stream, callback) {
      try {
        this.mic = this.context.createMediaStreamSource(stream);
        this.mic.connect(this.script);
        // necessary to make sample run, but should not be.
        this.script.connect(this.context.destination);
        if (typeof callback !== 'undefined') {
          callback(null);
        }
      } catch (e) {
        console.error(e);
        if (typeof callback !== 'undefined') {
          callback(e);
        }
      }
    };
    
    SoundMeter.prototype.stop = function() {
      this.mic.disconnect();
      this.script.disconnect();
    };


    const DialogContent = withStyles((theme) => ({
      root: {
        padding: theme.spacing(2),
      },
    }))(MuiDialogContent);
    
    const DialogActions = withStyles((theme) => ({
      root: {
        margin: 0,
        padding: theme.spacing(1),
      },
    }))(MuiDialogActions);
    const [open, setOpen] = React.useState(false);
    
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('md');

  const {
    PlayPause,
    CurrentTime,
    Progress,
    SeekBar,
    Duration,
    MuteUnmute,
    Volume,
    Fullscreen,
  } = controls;

  const [ interviewAnswerData, SetinterviewAnswerData] = useState(
    {
      interviewQuestionId : "",
      answerName : "",
      answer : ""
    }
  )

  const  PlayVideo = () => {
    SetPlaying(true)
  }


 const [ recorderAnswerTime, setRecorderAnswerTime ] = useState();

  const AddInterviewAnswer = (reqdata) => {
    let internetStatus = navigator.onLine;
  if(!internetStatus){
    alertService.alert({ message : 'Internet Disconnected', type : 'error'  });
    return false
  }
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/candidate/add_interview_answer`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(reqdata)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          // alertService.alert({ message: res.message, type: 'info' });
          //SetCurrentQuestionIndex((count) => count+1)
         // setVidoBlob("");
        }
        else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
        else {
          if (res.errors) {
            var errmsg = "";
            res.errors.forEach(function (value, index, array) {
              errmsg = errmsg + "(" + (index + 1) + ") " + value.msg + "\n\r";
            });
            alertService.alert({ message: errmsg, type: 'error' });
          }
          else {
            alertService.alert({ message: res.message, type: 'error' });
          }
        }
      })
      .catch(() => {
      });
    }

  
  const { intl } = props;    
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };


 //show LandingPage is landing page
 const[ showLandingPage, SetShowLandingPage] = useState(true);
 const[ showTermsPage, SetShowTermsPage] = useState(false);
 const[ showIntroVideo, SetShowIntroVideo] = useState(false);
 const[ showThanksVideo, SetShowThanksVideo] = useState(false);
 const[ showFinalThankyouPage, SetShowFinalThankyouPage] = useState(false);

 const showtermspage = (value) => {
  SetShowLandingPage(false);
  SetShowTermsPage(true)
  if(value==="mock"){
    get_Mock_Interview_questions();
  }
  else {
    get_Interview_questions()
  }
 }


 const[formSubmitted,SetformSubmitted]=useState(false)  
const [terms,SetTems]=useState(false);
const acceptTerms = () => {
  if(terms){
    SetTems(false)
  }
  else{
    SetTems(true)
  }
}  

const ProceedInstructions = () => {
  let internetStatus = navigator.onLine
  if(!internetStatus){
    alertService.alert({ message : 'Internet Disconnected', type : 'error'  });
    return false
  }
  if(!cameraEnabled){
    alertService.alert({ message: "Please allow Camera and Microphone access to start the Interview", type: 'error' });
    return false;
  }
  SetformSubmitted(true) ;
  if(terms) {
    var parsed = queryString.parse(props.location.search);
  var key = parsed.shareKey;
  SetShowLandingPage(false);
  SetShowTermsPage(false)
            
            // return false;
   
    if(interviewDetails.introVideo)
     {
       SetShowIntroVideo(true)
     }
     else {
      var newurl = "interview" + "?shareKey=" + key +"&interviewStart=1";

      // window.open("URL_here","Tab_NAME_HERE", "height=800,width=800")

      // var params = "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1200,height=1000,left=100,top=100";
      var width = window.innerWidth
          || document.documentElement.clientWidth
          || document.body.clientWidth;

      var height = window.innerHeight
          || document.documentElement.clientHeight
          || document.body.clientHeight;
      var params = "scrollbars=yes,resizable=no,status=yes,location=yes,toolbar=no,menubar=no,width=" + 1920 + ",height=" + 1080;

      
      if (newurl) {

          var isSafari1 = window.safari !== undefined;
          if (isSafari) {
           window.open(newurl, "name of", params);
            //  histroy.push(newurl);
            //  SetLoader(false)
          }

          else {
              window.open(newurl, "name of", params);
            //  SetLoader(false)
              alertService.alert({ message: "Please enable pop-ups in your browser to start interview", type: 'info' });
          }




      } 
      SetShowIntroVideo(false)
      setShowSafeMessage(true)
    //  get_Interview("start");
      
     }
  }
  }  
  



  const[showVideoQuestion,SetShowVideoQuestion] = useState(false);
  const[showVideoAnswer,SetShowVideoAnswer] = useState(false);

  const DisplayvideoQuestion = () => {
    var parsed = queryString.parse(props.location.search);
  var key = parsed.shareKey;
    var newurl = "interview" + "?shareKey=" + key +"&interviewStart=1";

      // window.open("URL_here","Tab_NAME_HERE", "height=800,width=800")

      // var params = "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1200,height=1000,left=100,top=100";
      var width = window.innerWidth
          || document.documentElement.clientWidth
          || document.body.clientWidth;

      var height = window.innerHeight
          || document.documentElement.clientHeight
          || document.body.clientHeight;
      var params = "scrollbars=yes,resizable=no,status=yes,location=yes,toolbar=no,menubar=no,width=" + 1920 + ",height=" + 1080;

      
      if (newurl) {

          var isSafari1 = window.safari !== undefined;
          if (isSafari) {
           window.open(newurl, "name of", params);
            //  histroy.push(newurl);
            //  SetLoader(false)
          }

          else {
              window.open(newurl, "name of", params);
            //  SetLoader(false)
              alertService.alert({ message: "Please enable pop-ups in your browser to start interview", type: 'info' });
          }




      } 
      SetShowIntroVideo(false)
      setShowSafeMessage(true)
    //get_Interview('start');    
  }

  const showHomePage = () => {
      SetShowLandingPage(true);
      SetTems(false)
      SetShowTermsPage(false);
      SetShowIntroVideo(false);
      }
// SetShowIntroVideo
// SetShowThanksVideo
const [introVideos, SetIntroVideos] = useState([]); 
const [thanksVideos, SetThanksVideos] = useState([]); 
const [YTVideos, SetYTVideos] = useState([]); 
const [FeatureImage, SetFeatureImg] = useState([]); 
const [interviewRules, SetInterviewRules] = useState([]); 
const [isRecording,SetIsRecording]=useState(false);
const [isIntroVideoEnded,SetIsIntroVideoEnded ]=useState(false);
const [isInterviewSubmitted,SetIsInterviewSubmitted ]=useState(false);


const onIntroVideoEnded = () => {
   SetIsIntroVideoEnded(true);
}



const [ succefullUploadCount, SetSuccefullUploadCount ]= useState(0);
const [CurrentQuesIndex,SetCurrentQuestionIndex]=useState(0);

const uploadblobfile = (file) => {
  var CId = interviewDetails.requisition.companyId;
  var called = 0
  if (file ) {
    // Split the filename to get the name and type
    let fileParts = file.name.split('.');
    let fileType = fileParts[1];
    const formData1 = new FormData();
          formData1.append("fileName", fileParts[0] + "." + fileParts[1]);
          formData1.append("fileType","video/"+fileType);
          formData1.append("fileFolder", 'video');
          //formData1.append("file", file);
          formData1.append("companyId", CId);

          
    axios.post(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/upload_answer_file`,  {
      fileName: fileParts[0] + "." + fileParts[1],
      fileType: "video/"+fileType,
      fileFolder: "video",
      companyId: CId,
    },
    {
      headers: {
        'x-access-token': localStorage.getItem('x-access-token'),
        //'Content-Type': 'multipart/form-data'

      }
    })
      .then(response => {
        if(response.data.sessionExpired){
          logoutMethod();
          alertService.alert({ message :response.data.message, type : 'error'  });
        }
        else if(response.status){
        //  var url = response.data.data.filePath;
          
        var returnData = response.data.data.returnData;
        var signedRequest = returnData.signedRequest;
        var url = returnData.url;
        var answerdata= false;
        if (url) {
          var updatequestionIndex = CurrentQuesIndex +1
          var reqDatatoAddAnswerDetails = {
            interviewUserId:interviewDetails.id,
            interviewQuestionId: InterviewQuestionData[CurrentQuesIndex].id,
            answerName : "Answer "+  updatequestionIndex,
            answer : url
          }
          
          SetVideoAnswerUrl(url)
          SetUploadState(true);
          
        }
        // Put the fileType in the headers for the upload
        var options = {
          headers: {
            'Content-Type': 'video/'+ fileParts[1],
            'Content-Disposition':"inline" 
          }
        };
        axios.put(signedRequest, file, options)
          .then(result => {
            if(reqDatatoAddAnswerDetails.interviewQuestionId &&  reqDatatoAddAnswerDetails.answer) {
              AddInterviewAnswer(reqDatatoAddAnswerDetails);
              SetSuccefullUploadCount((count) => count+1);
            }
          })
            .catch(error => {
              
          //  alertService.alert({ message: JSON.stringify(error), type: 'error' });
          }) 
        } 
      })
      .catch(error => {
       // alertService.alert({ message: JSON.stringify(error), type: 'error' });
      })
      
      SetCurrentQuestionIndex((count) => count+1)
      DisplayNextQuestion();
      setVidoBlob("");
  }
  
}


const[showreplayCountdown,SetShowreplayCountdown]=useState(false);

const GotoMockInterview = () => {
  let internetStatus = navigator.onLine
    if(!internetStatus){
      alertService.alert({ message : 'Internet Disconnected', type : 'error'  });
      return false
    }
    if(!cameraEnabled){
      alertService.alert({ message: "Please allow Camera and Microphone access to start the Interview", type: 'error' });
      return false;
    }
  var parsed = queryString.parse(props.location.search);
  var key = parsed.shareKey;
   histroy.push("/mock?shareKey="+key)
}

const [InterviewQuestionData,setInterviewQuestionsData] = useState();
const get_Mock_Interview_questions = () => {
  var parsed = queryString.parse(props.location.search);
  var key = parsed.shareKey;
  fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/candidate/get_mock_interview_questions`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status == true) {
        var test = res.data;
        var testQue = test.map(x=> {
          x.interviewQuestionDetail.answerDuration = x.answerTime;
          x.interviewQuestionDetail.id = x.id;
          return x.interviewQuestionDetail;
        });
        setInterviewQuestionsData(testQue);
        setRecorderAnswerTime(testQue[CurrentQuesIndex].answerDuration + 1);
        SetQuestionCounter(testQue[CurrentQuesIndex].answerDuration )
      }
      else {
        alert('here')
          histroy.push({pathname : '/interview-expired',
                state: { message: res.message }
        });

       
      }
    })
    .catch(() => {
    });
}



const get_Interview_questions = () => {
  var parsed = queryString.parse(props.location.search);
  var key = parsed.shareKey;
  fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/candidate/get_interview_questions?module=interview&shareKey=`+ key, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status == true) {
        var test = res.data;
        var testQue = test.map(x=> {
          x.interviewQuestionDetail.answerDuration = x.answerTime;
          x.interviewQuestionDetail.id = x.id;
          x.interviewQuestionDetail.replayed = 0
          return x.interviewQuestionDetail;
        });
        setInterviewQuestionsData(testQue);
        setRecorderAnswerTime(testQue[CurrentQuesIndex].answerDuration + 1);
        SetQuestionCounter(testQue[CurrentQuesIndex].answerDuration)
      }
      else {
        alert('here1')

          histroy.push({pathname : '/interview-expired',
                state: { message: res.message }
        });
        
      }
    })
    .catch(() => {
    });
}



const [countdown,SetCountDown]=useState(3)
const [showPlayerStartingText,SetshowPlayerStartingText]=useState(false);

useEffect( async () => {
  SetShowAnsVideo(false)
  if(NextQues){
  await SetReplayingQuesVideo(true)
  }
}, [NextQues]);

useEffect( async () => {
  console.log(videoStartTime)
}, [videoStartTime]);

useEffect( async () => {
  console.log(replayButtonState)
}, [replayButtonState]);


const PlayQuesVideo = () => {
 var elem = document.getElementsByClassName("ques-video")
 if(elem && elem.length){
 elem[0].click()
 }
}
const PlayVideoQuestionTimer = () => {
  var newcounter;
  SetCountDown(3); 
  SetshowPlayerStartingText(true);
  setTimeout(() => {
    // var ele = document.querySelector('.media-controls button');
    // if (ele ) {
    //   ele.click();
    // }   
    SetshowPlayerStartingText(false);
  }, 3500);

  var counter = 3;
  setInterval(() => {
    counter = counter -1; 
    if(counter>0){
      SetCountDown(counter); 
      if(counter >=1 ){
        SetshowPlayerStartingText(true); 
      }else{
       // SetshowPlayerStartingText(false); 
      //  if(CurrentQuesIndex > 0){
      //  onButtonClick()
      //  }
      //  var video = document.getElementById('ques-video')
    //    video.play()
      }
    }
   else {
    clearInterval(0);
   }
  },1000);
   
}

// background scenarios
window.OfflineTime = 0;
window.isTabFocus  = true;
window.tabLeftCount = 0;
window.bgCount = 0
window.interNetDownCount = 0;
const backgroundMonitor = () => {  
//window.addEventListener('blur', onBlur.bind(this));
//window.addEventListener('focus', onFocus);
window.addEventListener('offline', gotOffLine);
}

const onFocus = () => {
  SetOnBlurFlag(false)
  window.isTabFocus  = true;
  SetBackgroundActive(true);
 // if(replayButtonState){
  //  SetSeconds(pauseseconds)
 // }
  if(isRecordringControlsEnabled && !replayButtonState){
    SetShowVideoAnswer(false)
    SetShowVideoAnswer(true)
    DisplayvideoAnswer()
   // startRecorder();
  }
  var ele = document.querySelector('.media-controls button');
  if (ele){
    if(!replayButtonState){
    //  ele.click();
    }
  }
}



 const gotOffLine = () => {
        SetInternetCount((count) => count + 1)
  window.interNetDownCount++;
}
const[isBackgroundActive,SetBackgroundActive]=useState(false);
const ShowOffline = () => {
  if(!isInterviewSubmitted && interviewWindow){
  SetShowInternetDisconnected(true)
  SetShowFinalThankyouPage(false);
  SetShowIntroVideo(false);
  SetShowLandingPage(false);
  SetShowVideoAnswer(false);
  SetShowVideoQuestion(false);
  SetShowThanksVideo(false);
  UpdateInterviewStatus("interrupted");
  }
}


const BGinteruppt = () => {
 /* setInterval(() => {
   if(!window.isTabFocus){
     window.OfflineTime = window.OfflineTime+ 3;
     if(window.OfflineTime > 60){
       ShowOffline()
     }
   }
   else if(window.isTabFocus){
     window.OfflineTime = 0;
   }
  }, 3000);*/
 }
 



function closeTab() {
  if (!window.isTabFocus) {
  }
}



// background scenarios

useEffect( async () => {
  var  status; 
  var previousStatus = true;
  setInterval(() => {
    status = navigator.onLine;
    if(!status){
      if(previousStatus){
      SetOfflineTime1((time) => time + 1)
      }
      previousStatus = navigator.onLine
    }else{
      previousStatus = navigator.onLine
    }
  },1000);
 
}, []);



const UpdateInterviewStatus = (statusState,interviewId=null) => {
  if(statusState == 'end'){
    SetIsInterviewSubmitted(true)
  }
  if(alreadyInterrupted && statusState == 'interrupted'){
    return false;
  }
  if(statusState == 'interrupted'){
    setAlreadyInterrupted(true)
  }
  let id= interviewId ? interviewId : interviewDetails.id;
   var reqData = {
    status: statusState,
    internetDownCount : OfflineTime1 , //window.interNetDownCount,
    backgroundCount : bgCount
    }
    console.log('OfflineTime',OfflineTime1)
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/candidate/update_interview/`+ id, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(reqData)
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status == true) {
        SetInterviewStarted(true)
      }
      else {
        if (res.errors) {
          var errmsg = "";
          res.errors.forEach(function (value, index, array) {
            errmsg = errmsg + "(" + (index + 1) + ") " + value.msg + "\n\r";
          });
          alertService.alert({ message: errmsg, type: 'error' });
        }
        else {
          alertService.alert({ message: res.message, type: 'error' });
        }
      }
    })
    .catch(() => {
    });  
}


const[ showInternetDisconnected ,SetShowInternetDisconnected]=useState(false);


const checkIfOnline = () => {
  var  status;
  setInterval(() => {
    status = navigator.onLine;
    if(!status){
      SetOfflineTime1((time) => time + 1)
    }else{
      SetOfflineTime1(0)
    }
  },1000);

  /*  if(!status) {
      setTimeout(() => {       
        if(!status) {
          ShowOffline();
        }
      }, 60000);
    }*/
}




 const get_Interview = (interviewStatus = null) => {
  let internetStatus = navigator.onLine;
  if(!internetStatus){
    alertService.alert({ message : 'Internet Disconnected', type : 'error'  });
    return false
  }
  var parsed = queryString.parse(props.location.search);
  var key = parsed.shareKey;
  fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/candidate/get_interview?module=interview&shareKey=`+key, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((res) => {
        if (res.status == true) {
            TakeCameraPermission();
            SetInterviewDetails(res.data);
            if(res.data.companyIntroVideos){
              SetIntroVideos(res.data.companyIntroVideos.split(","));
            }
            if(res.data.thanksVideo){
              SetThanksVideos(res.data.thanksVideo.split(","))
            }

            if(res.data.requisition.company.companyYoutubeLinks){
              SetYTVideos(res.data.requisition.company.companyYoutubeLinks.split(","));
            }
            if(res.data.requisition.company.companyImages){

            }
          if(res.data.requisition.company.companyImages){
            SetFeatureImg(res.data.requisition.company.companyImages.split(","));
          }
          if(res.data.interviewRules){
            SetInterviewRules(res.data.interviewRules);
          }

           
        

        var parsed = queryString.parse(props.location.search);

        var interviewStart = parsed.interviewStart
          if(interviewStart){
            
            if(res.data.companyIntroVideos)
            {
              
              SetShowIntroVideo(true)
            }
            else {
             // get_Interview("start");
             SetShowIntroVideo(false);   
             SetShowLandingPage(false);
              SetShowTermsPage(false)
              SetShowVideoQuestion(true);
            // to play video question
            PlayVideoQuestionTimer();
            backgroundMonitor();
            checkIfOnline();    
            UpdateInterviewStatus('start',res.data.id)
    
            
              
            }
          }
          if(interviewStatus == 'start'){
            UpdateInterviewStatus('start')
            SetShowVideoQuestion(true);
            // to play video question
            PlayVideoQuestionTimer();
            backgroundMonitor();
            checkIfOnline();        
            SetShowIntroVideo(false);    
            
          } 
        
     
      }
      else {
        alert('here23')

          histroy.push({pathname : '/interview-expired',
                state: { message: res.message }
        });
       
      }
    })
    .catch(() => {
    });
}
const[ isRecordringControlsEnabled,SetIsRecordringControlsEnabled]=useState(false)

 const [seconds,SetSeconds] = useState(10)
 const [pauseseconds,SetPauseSeconds] = useState(0)
 const [counter,SetCounter] = useState(null)

 const DisplayNextQuestion = () => {
   if(CurrentQuesIndex < InterviewQuestionData.length - 1  ){
  clearInterval(counter);
  SetReplayingQuesVideo(false)
  SetShowreplayCountdown(false)
  SetreplayButtonState(false)
  SetSeconds(10);
  SetShowVideoAnswer(false);
  // // to play video question
  
  setRecorderAnswerTime(InterviewQuestionData[CurrentQuesIndex].answerDuration + 1);
  SetQuestionCounter(InterviewQuestionData[CurrentQuesIndex].answerDuration)
  PlayVideoQuestionTimer();
 // setTimeout(() => {
    // var ele = document.querySelector('.media-controls button');
    // if (ele ) {
    //   ele.click();
    // }
    SetShowVideoQuestion(true);

 // }, 500);
  
   }
   else  {
    if(thanksVideos.length > 0){
      SetShowThanksVideo(true);
      SetShowVideoQuestion(false);
      SetShowVideoAnswer(false);
      UpdateInterviewStatus("end");
      // alert("all answers submited already")
    }
    else  {
      SetShowFinalThankyouPage(true);
      SetShowThanksVideo(false);
      SetShowVideoQuestion(false);
      SetShowVideoAnswer(false);
    
      // if(succefullUploadCount < InterviewQuestionData.length){
      //   SetShowUploadLoader(true)
      // } 
      // else {
      //   SetShowUploadLoader(false)
      // }
      UpdateInterviewStatus("end");
    }
   }
}



const OnthanksVideoEnded =()=> {
  SetShowThanksVideo(false);
  SetShowVideoQuestion(false);
  SetShowVideoAnswer(false);
  SetShowFinalThankyouPage(true);
  // if(succefullUploadCount < InterviewQuestionData.length){
  //   SetShowUploadLoader(true)
  // } 
  // else {
  //   SetShowUploadLoader(false)
  // }
}


const [ShowUploadLoader,SetShowUploadLoader]= useState(false)


const ReplayVideo = () => {

  SetQuesReplayed(oldArray => [...oldArray, CurrentQuesIndex])
  SetNextState(true);
  SetQuestionReplayCounter(false);
  SetShowreplayCountdown(false);
  // vidRef.play();
   var ele = document.querySelector('.media-controls button');
  if (ele ) {  

    clearInterval(counter);
    SetSeconds("00");
    SetreplayButtonState(false)
  
    ele.click();
  }
}


 const DisplayvideoAnswer = () => {
   if(ShowAnsVideo == false){
   SetAnswerVideoWasStopped(false)
   if(CurrentQuesIndex < InterviewQuestionData.length){
  SetreplayButtonState(false);
 // SetNextState(false);
  clearInterval(counter);
  SetShowVideoQuestion(false);
  SetShowVideoAnswer(true);
  setRecorderAnswerTime(InterviewQuestionData[CurrentQuesIndex].answerDuration + 1);
  SetQuestionCounter(InterviewQuestionData[CurrentQuesIndex].answerDuration)
  startRecorder();
  setTimeout(() => {
    var startbutton = document.querySelectorAll('[data-qa="turn-on-camera"]');
    if( startbutton && startbutton[0]){
      startbutton[0].click();
    }
     
   },1000);
  }
}
 }

const [ShowRecordingText,SetShowRecordingText]=useState(false);
const [AnswerVideoWasStopped,SetAnswerVideoWasStopped]=useState(false);





    const startRecorder = () => {

      let meterRefresh = null;
      let instantMeter = document.querySelector('#instant meter');

      setAnswerStartIn(3);
      let counterSeconds = 3
      setTimeout(() => {
        SetShowRecordingText(true);
        let intervalCond = setInterval(() => {
          //  if(isPageVisible ){
            counterSeconds = counterSeconds - 1;      
            if (counterSeconds >= 0 ) {
              setAnswerStartIn(counterSeconds);
            } else {
             
                clearInterval(intervalCond)
              
            //  ;
      
            }
         //  }
           }, 1000);
       }, 100);
    
      setTimeout(() => {
        var mimeType = 'video/webm;codecs="vp8"'
        if(isSafari){
          mimeType = 'video/mp4'
        } else if(isFirefox) {
          mimeType = 'video/webm'
        }
        media_recorder = new MediaRecorder(window.stream, { mimeType: mimeType });
        window.media_recorder = media_recorder
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        window.audioContext = new AudioContext();

      

    
        media_recorder.addEventListener('dataavailable', function(e) {
          blobs_recorded.push(e.data);
        });
    
        media_recorder.addEventListener('stop', function() {
          var video_bolb = new Blob(blobs_recorded, { type: mimeType })
          let video_local = URL.createObjectURL(video_bolb);
          var duration = Date.now() - window.videoStartTime;
          console.log(duration)
          ysFixWebmDuration(video_bolb, duration, {logger: false})
            .then(function(fixedBlob) {
              setVidoBlob(fixedBlob);
              downloadVideo(fixedBlob)
            });
          
    
        });
        SetStopAnswerVideo(true);
        StartQuestionTimer();
        
    
        media_recorder.start(1000);

        
        const soundMeter = window.soundMeter = new SoundMeter(window.audioContext);
        soundMeter.connectToSource(window.stream, function(e) {
          if (e) {
           // alert(e);
            return;
          }
          meterRefresh = setInterval(() => {
            instantMeter = document.querySelector('#instant meter');
            if(instantMeter){
              instantMeter.value = (soundMeter.instant.toFixed(2) * 10);
            }
              //instantValueDisplay.innerText =
        //    slowMeter.value = slowValueDisplay.innerText =
         //     soundMeter.slow.toFixed(2);
         //   clipMeter.value = clipValueDisplay.innerText =
          //    soundMeter.clip;
          }, 200);
        });


        setVideoStartTime(Date.now())
        window.videoStartTime = Date.now();
        setMediaRecorderObj(media_recorder);
        var answerVideo = document.querySelector('video#answer_video')
        answerVideo.srcObject = window.stream
       // var startbutton = document.querySelectorAll('[data-qa="start-recording"]');
        // if(startbutton && startbutton[0])
        //  startbutton[0].click();
       },3400);
    
    
       setTimeout(() => {
        SetShowRecordingText(false);    
      }, 3100);
    
      setTimeout(() => {
        setShowStopBtn(true);    
      }, 5100);
      /*
      let cameraStarted = 0
        if (navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: true, audio: true })
                .then(function (stream) {
                    setTimeout(() => {
                        SetShowRecordingText(true);
                    }, 3400);

                    setTimeout(() => {
                        var startbutton = document.querySelectorAll('[data-qa="start-recording"]');
                        if (startbutton && startbutton[0])
                            startbutton[0].click();
                    }, 3400);


                    setTimeout(() => {
                        SetShowRecordingText(false);
                    }, 6400);
                })
                .catch(function (error) {
                    alertService.alert({ message: "Please allow Camera and Microphone access to start the Interview", type: 'error' });
                });
        }*/
  
 }


 
const vidRef = useRef(null);

const StartTimer = ()  => {
  SetPlaying(false)
  SetQuestionReplayCounter(true);
  SetShowreplayCountdown(true);
  SetreplayButtonState(true);

  if(!replayButtonState && !NextQues ){
    var counterSeconds = 10;
    SetIsRecordringControlsEnabled(true)
    SetSeconds(10);
    let intervalCond = setInterval(() => {
    //  if(isPageVisible ){
      counterSeconds = counterSeconds - 1;      
      if (counterSeconds >= 0 ) {
       SetSeconds(counterSeconds);
      } else {
        if(replayingQuesVideo === false){
          DisplayvideoAnswer();
          clearInterval(intervalCond)
        }
      //  ;

      }
   //  }
     }, 1000);
      SetCounter(intervalCond);
  }
  else {
    if(CurrentQuesIndex > 0 && !quesReplayed.includes(CurrentQuesIndex)){
      var counterSeconds = 10;

    SetSeconds(10);
    let intervalCond = setInterval(() => {
    //  if(isPageVisible ){
      counterSeconds = counterSeconds - 1;      
      if (counterSeconds >= 0 ) {
       SetSeconds(counterSeconds);
      } else {
        if(replayingQuesVideo === false){
        SetQuestionReplayCounter(false);
        DisplayvideoAnswer();
       // startRecorder();
        SetreplayButtonState(false);
          clearInterval(intervalCond)
        }
      //  ;

      }
   //  }
     }, 1000);
     SetCounter(intervalCond);
    }else{
     SetQuestionReplayCounter(false);
     DisplayvideoAnswer();
    // startRecorder();
     SetreplayButtonState(false);
    }
 
  }
 }


const [questionCounter, SetQuestionCounter]= useState();


 
 const StartQuestionTimer = ()  => {
    setTimeout(() => {
      var counterSeconds = parseInt(InterviewQuestionData[CurrentQuesIndex]?.answerDuration)
      SetCounter(setInterval(() => {
       if (counterSeconds >= 0) {
        if(window.isTabFocus){
          counterSeconds = counterSeconds - 1;
          if (counterSeconds >= 0) {
          SetQuestionCounter(counterSeconds);
          }
        }
        else{
          clearInterval(counter);
        }
       } 
      }, 1000));
      
    }, 3000);
  }

  // InterviewQuestionData[CurrentQuesIndex]?.answerDuration 


const StopRecorder = () => {
  console.log(Date.now(), 'stop recorder called')
  let internetStatus = navigator.onLine
  if(!internetStatus){
    alertService.alert({ message : 'Internet Disconnected', type : 'error'  });
    SetAnswerVideoWasStopped(true)
    return false
  }
  var mediaRecorderState = mediaRecorderObj.state
if(mediaRecorderState != 'inactive'){
  mediaRecorderObj.stop();
  setShowStopBtn(false)
  SetIsRecording(false);
  SetShowreplayCountdown(false);
  clearInterval(counter);
  // var submianswerbtn = document.getElementById("submitanswerbtn");
  // if(submianswerbtn){
  //   submianswerbtn.click();
  // }
  
}
/*
  var stopbutton = document.querySelectorAll('[data-qa="stop-recording"]');
  if(stopbutton && stopbutton[0]) {

    stopbutton[0].click();
  }else{
    if(AnswerVideoWasStopped){
      downloadVideo(videoBlob)
    }
  } */

}


const downloadVideo = (videoBlob) => {
 //if(isPageVisible){
  SetStopAnswerVideo(false)
  let internetStatus = navigator.onLine;
  if(!internetStatus){
    alertService.alert({ message : 'Internet Disconnected', type : 'error'  });
    SetAnswerVideoWasStopped(true)
    return false
  }
  var videoType = ".webm"
    if(isSafari){
      videoType = ".mp4"
    }
  if(videoBlob) {
    var file = blobToFile(videoBlob, `Video-${Date.now()}${videoType}`);
  }
    uploadblobfile(file);
    SetIsRecordringControlsEnabled(false);
 //}

}



const [uploadState, SetUploadState] = useState(false);
const histroy = useHistory();
const dispatch = useDispatch();
const logoutMethod = () => {
  dispatch(logout());
  histroy.push('/auth/signin');
};

const[VideoAnswerUrl, SetVideoAnswerUrl] =useState("")
function blobToFile(theBlob, fileName) {
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
}




    const TakeCameraPermission = () => {
        var counter = 0;
        var intervalId = setInterval(() => {
            counter++;
            if (counter > 59 && interviewStarted && interviewWindow) {
                SetShowInternetDisconnected(true)
                SetShowFinalThankyouPage(false);
                SetShowIntroVideo(false);
                SetShowLandingPage(false);
                SetShowVideoAnswer(false);
                SetShowVideoQuestion(false);
                SetShowThanksVideo(false);
                UpdateInterviewStatus("interrupted");
            }
            else if (navigator.mediaDevices.getUserMedia) {
              navigator.mediaDevices.getUserMedia({ video: true, audio: true })
                  .then(function (stream) {
                    var answerVideo = document.querySelector('video#answer_video');
                    window.stream = stream;
                    if(answerVideo){
                      
                      answerVideo.srcObject = stream;      
                    }          
                    setCameraEnabled(true)
                    clearInterval(intervalId)
                  })
                  .catch(function (error) {
                  
                    setCameraEnabled(false)
                      alertService.alert({ message: "Please allow Camera and Microphone access to start the Interview", type: 'error' });
                  });
          }
        }, 1000);
        /*
  if (navigator.mediaDevices.getUserMedia) {
    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then(function (stream) {
      })
      .catch(function (error) {
        alertService.alert({ message: "Please allow Camera and Microphone access to start the Interview", type: 'error' });
      });
     
  } */
}


const onBlur = () => {
  window.isTabFocus  = false;
  SetBackgroundActive(false);
  SetOnBlurFlag(true)
  BGinteruppt();
  StopRecorder();
  SetQuestionCounter(InterviewQuestionData[CurrentQuesIndex]?.answerDuration)
  // clearInterval(InterviewQuestionData[CurrentQuesIndex]?.answerDuration);
  // stopbutton[0].click();

 /* window.tabLeftCount++; */
  if(replayButtonState){
    SetPauseSeconds(seconds)
  }
  var ele = document.querySelector('.media-controls button');
  var ele1 = document.querySelector('start-replaying');
  if (ele) {
    if(!replayButtonState){
     // ele.click();
    }
    setTimeout(() => {
      if(ele1){
        if(!replayButtonState){
         // ele1.click();
        }
      }
    }, 500);
  }
}

 useEffect(() => {
  let mounted = true;
  var parsed = queryString.parse(props.location.search);
  var key = parsed.shareKey;
  var interviewStart = parsed.interviewStart
  
  if (isAndroid) {    
    window.location.replace(`intent://${process.env.REACT_APP_ANDROID_URL}?shareKey=${key}&module=interview#Intent;scheme=https;package=com.vrekruit.app;end`);
    return
}
 else if (isIOS) {
  window.location.replace("VRekruit://?shareKey="+key+"&module=interview");
  setTimeout(function () {
     window.location.href = 'https://apps.apple.com/in/app/vrekruit/id1587905819?shareKey="+key+"&module=interview'; //'YOUR_APP_STORE_LINK';
        }, 1500);
         // window.location.replace("https://apps.apple.com/us/app/instagram/id389801252");
          return true;
}
  else {
      if ((osName == 'Mac OS' && isSafari) || (osName == 'Windows' && ( isChrome || isFirefox))) {
        
          if(interviewStart){
            setInterviewWindow(true)
            get_Interview_questions()
          }

          get_Interview();
          SetOfflineTime(0)
          
      } else {
          window.location.replace(`${process.env.REACT_APP_URL}browser-not-supported`);


      }
}
// get_Interview();

return () => mounted = false;
}, [])

useEffect(() => {
  if(OfflineTime > 60){
    ShowOffline()
  }
}, [OfflineTime])

useEffect( async () => {
  
}, [mediaRecorderObj]);


useEffect(() => {
  if(OfflineTime1 > 60){
    ShowOffline()
  }
}, [OfflineTime1])

useEffect(() => {
  if(interviewStarted && !isPageVisible && !onBlurCalled){
    const timer = setInterval(() => {
      SetOfflineTime((time) => time + 1);
    }, 1000);
    return () => {
      clearInterval(timer);
    };       
  } 

  if((interviewStarted && isPageVisible) || showThanksVideo || showFinalThankyouPage) {
    SetOfflineTime(0)
  }
  
  }, [isPageVisible])

  useEffect(() => {
    let timer1 = null
    if(stopAnswerVideo){
      timer1 =  setTimeout(() => {
   //   var stopbutton = document.querySelectorAll('[data-qa="stop-recording"]');
        var stopbutton = document.getElementById("submitanswerbtn")
      if(stopbutton) {
        var questionId = document.getElementById("submitanswerbtn").getAttribute("data-current-ques");
        if(questionId == CurrentQuesIndex){
          stopbutton.click();
           SetIsRecording(false);
        }
      }                         
      }, 1000 * recorderAnswerTime);
    }else{
      return () => window.clearTimeout(timer1);
    }
    
    }, [stopAnswerVideo])

useEffect(() => {
if(interviewStarted && !isPageVisible && !onBlurCalled){
//  onBlur()
  SetBgCount((count) => count + 1);     

} else if((interviewStarted && isPageVisible && onBlurCalled) || showThanksVideo || showFinalThankyouPage){
  SetOfflineTime(0)
 // onFocus()  
} else if(isPageVisible && interviewStarted && pauseseconds > 0){
 // SetSeconds(pauseseconds)
}
  

}, [isPageVisible])

useEffect(() => {
}, [interviewDetails])






document.addEventListener("keydown", keydown);
document.addEventListener("keyup", keyup);
// document.addEventListener('contextmenu', event => event.preventDefault());
var ctrlKeyDown = false;

function keydown(e) { 
  if(e.keyCode == 116) {
        e.preventDefault();
  }
  else if ((e.which || e.keyCode) == 116 || ((e.which || e.keyCode) == 82 && ctrlKeyDown)) {
      // Pressing F5 or Ctrl+R
      e.preventDefault();
  } else if ((e.which || e.keyCode) == 17) {
      // Pressing  only Ctrl
      ctrlKeyDown = true;
  }
};

function keyup(e){
  // Key up Ctrl
  if ((e.which || e.keyCode) == 17) 
      ctrlKeyDown = false;
};

const [videoBlob, setVidoBlob] = useState();
const[videoEnded, SetVideoEnded] = useState(false)
const EnableInterviewButton = () => {
  SetVideoEnded(true);
}







return (
  
<div style={{"overflowX":"hidden"}} className="bg-white" id="i">
   {!(isAndroid || isIOS) ?  
 <>        
    <Helmet>
       <title> VRekruit |  Interview </title>
    </Helmet>
    {showSafeMessage ?
    <section className="d-flex centerd justify-content-center align-item-center"  style={{"backgroundColor":"#003577","height":"100vh"}}>
   
     
       <p className="text-white">
         
          You can safely close this window.
          </p>
        
          


    </section> : ''}
    
{/* <LandingPage 1> */}

{showLandingPage ? (
<>
<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark"  style={{ "background": "#003577 !important"}}>
  <Container className="pt-3 pb-3">
  <Navbar.Brand href="#">
    Welcome,
       {interviewDetails ? " " + interviewDetails.interviewUserDetail.firstName + " ": ""} 
       {interviewDetails ? interviewDetails.interviewUserDetail.lastName: ""}     </Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
      <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
      </NavDropdown>
    </Nav>
    <Nav>
      <Nav.Link href="#" className="text-white">
      <img src="/media/logos/email (1).svg"   className="icon-w30 fill-white svg-white mr-2" ></img>
      {interviewDetails ? interviewDetails.interviewUserDetail.email: ""} 
      </Nav.Link>
      <Nav.Link eventKey={2} href="#" className="text-white">
      <img src="/media/logos/call (1).svg" className="icon-w30 fill-white svg-white mr-2" ></img>
        {interviewDetails ? interviewDetails.interviewUserDetail.dialCode: ""} 
        { interviewDetails ? " " + interviewDetails.interviewUserDetail.phone + " "  : ""} 
      </Nav.Link>
    </Nav>
  </Navbar.Collapse>
  </Container>
</Navbar>
<section className="bg-white">
  <Row  >
    <Col sm={12} xs={12} md={4} className="mt-50px text-center mb-50px" >
    { interviewDetails && interviewDetails.requisition?.company?.logo ?  
    <Image src={interviewDetails ? interviewDetails.requisition.company.logo: ""}  className="bg-circle"  width="120" height="110" alt="Company Logo bg-circle" ></Image>
   : 
   <span  className="bg-image-logo d-flex justify-content-center align-items-center"    >
      <p className="mt-3">
       Company 
       <br/>
       Logo
       </p>
   </span>
    }
    </Col>
    <Col xs={12} sm={12} md={4} className="mt-50px text-center mb-50px " >
      <h6 className="text-theme-blue d-block text-left pt-2 pl-5"> 
      <img src="/media/logos/buildingreen.svg" className="icon-w30 fill-green" ></img>
      {interviewDetails ? interviewDetails.requisition.company.companyName: ""} 
       </h6>
       <h6 className="text-theme-blue d-block text-left pt-2 pl-5"> 
       <img src="/media/logos/globegreen.svg" className="icon-w30 fill-green" ></img>
       {interviewDetails ? interviewDetails.requisition.company.companyWebsite: ""} 
       </h6>
      <h6 className="text-theme-blue d-block text-left pt-2 pl-5"> 
      <>
      <img src="/media/logos/callgreen.svg" className="icon-w30 fill-green" ></img>
      {interviewDetails ? interviewDetails.requisition.company.companyDialCode + " ": ""}
      {interviewDetails ? interviewDetails.requisition.company.companyPhone + " ": ""}
      </>
      </h6>
      <h6 className="text-theme-blue d-block text-left pt-2 pl-5"> 
      <img src="/media/logos/addressgreen.svg" className="icon-w30 fill-green" ></img>
      {interviewDetails ? interviewDetails.requisition.company.companyAddress + " ": ""}
      </h6>
    </Col>
    <Col xs={12} sm={12} md={4} className="mt-50px text-center mb-50px" >
      <div>
      <button className="btn btn-mock-interview mb-5"  onClick={GotoMockInterview} > Take Mock Interview </button>
      </div>
      <div>
      <button className="btn btn-interview mb-5" onClick={()=>showtermspage("int")}> Start Interview </button>
      </div>
    </Col>
  </Row>
    </section>  
    {FeatureImage.length > 0 ? (
    <section className="bg-2 pb-10"> 
      <>
     <h1 className="text-center mt-mb-25 pt-10"> Company Images </h1>
    <Carousel>
     {FeatureImage.map(x => 
    <Carousel.Item  key={x}  interval={1000} id="c-im">
      <img
        className="d-block  fe-img img-fe"
        src={x}
        alt="First slide"
       
      />
    </Carousel.Item>
     )}

    </Carousel>
     </>
    </section>
    ) : "" }  

    { interviewDetails?.requisition.company.companyVideo ? (
      <>
    <h1 style={{"backgroundColor":"#4AA9BF"}} className="text-center  pt-10 pb-0 mb-0 text-white">Company Video</h1>
    <section className="" style={{"backgroundColor":"#4AA9BF","minHeight":"470px","position":"relative"}}>
          <img className="mr-auto d-flex abs-center" style={{"margin":"auto"}} src="/media/bg/mcbook.png" />
          <video className="video-container video-container-overlay mr-auto abs-center" style={{"position":"absolute","width":"500px","height":"300px"}} autoPlay={false} loop muted={false} controls>
              <source src={interviewDetails? interviewDetails.requisition.company.companyVideo: ""} type="video/mp4" />
          </video>
   </section>
   </>
    ): "" } 

{YTVideos && YTVideos.length > 0 ? (
   <section className="bg-white">
    <h1  className="text-center  pt-10 pb-10 mb-0">More Videos</h1>
  <Carousel className=" pb-50px">

  {YTVideos.map((x, i) => 
<Carousel.Item  key={x+i} > 
<ReactPlayer url={x} 
          style={{"width":"100%","height":"760px"}} 
          className="video-container fe-img video-container-overlay mr-auto"
          controls={true} />
</Carousel.Item>
)}
</Carousel>
   </section> 
   ): ""}

{interviewDetails && interviewDetails?.requisition.company.companyPresentation ? 
(
    <section>
    <Row>
     <Col md={6} xs={12} className="bg-2  mr-auto "> 
    <Col sm={12} xs={12} md={6} className="d-flex  mr-auto  p-rel" >
      <div className="mr-5 d-block d-flex">
      <img src="/media/logos/pdf (1).svg" className=" fill-white" style={{"width":"100px"}} ></img>
      </div>
      <div className="text-left pb-2 mt-5">
        <span className="pl-2 pb-2 mb-5">
          <h6>
             Presentation.pdf 
           </h6>
      <a href={interviewDetails? interviewDetails.requisition.company.companyPresentation: ""}  style={{"maxWidth":"260px"}} target="_blank" className="btn d-block btn-interview-download mb-5 mt-5"> 
      <img src="/media/logos/downloadwhite.svg" className="icon-w30 fill-white mr-1" ></img>  Download File 
      </a>
      </span> 
      </div>
      <div>
      </div>
      </Col>

      <Col sm={12} xs={12} md={6} className="d-flex mr-auto">
      <div className="mr-5 d-block d-flex">
      <img src="/media/VRekruitIcons/job-description (1).svg" className=" fill-white" style={{"width":"100px"}} ></img>
      </div>
      <div className="text-left pb-2 mt-5">
        <span className="pl-2 pb-2 mb-5">
  
      <span  onClick={ () => setOpen(true)}
      style={{"maxWidth":"260px"}}
      target="_blank" className="btn d-block btn-interview-download mb-5 mt-5"> 
         Job Description
      </span>
      </span> 
      </div>
      <div>
      </div>


    </Col>
    </Col>


    <Col sm={12} xs={12} md={6} className="text-center bg-21 pt-70px pb-70px  p-rel" >
      <div className="abs-center pt-5 pb-3">
      <div className="">
      <button className="btn btn-interview mb-5" onClick={GotoMockInterview}> Take Mock Interview </button>
      </div>
      <div>
      <button className="btn btn-interview mb-5" onClick={showtermspage}> Start Interview </button>
      </div>
      </div>
    </Col>
    </Row>       
    </section>
)
  : "" 
  }

    </>
) :""} 

    
{showTermsPage ? (
  <>
{/* <instuctionPage 1> */}
   <section className="bg-white min-75">
    <div className="">
    
    <div className="d-flex">
    <Col md={10} className="bg-21">
    <div>
    <h1  className=" text-center text-white ml-20 pt-5 pb-5 ml-10"> Important Instructions 
    </h1>
    </div>
    </Col>
    <Col  className="bg-21">
    <div className="d-flex justify-content-end">
    <h6  className="bg-21 text-center text-white pt-5 pb-5 mt-3 text-underline c-p" 
     onClick = {showHomePage}
    > Back to Home
    </h6>
    </div>
    </Col>
    </div>
    <Col xs={12} md={12} className="text-center bg-white  p-rel" >
       <div className="pl-5 pr-5 pb-50px">
       <div className="ins-border">      
   
        {interviewRules && interviewRules.map(
          x =>         {
                return <h4 key={x} className="pt-5 pb-5 text-left abcd" dangerouslySetInnerHTML={{ __html: x }} />
          }
        )}

        <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              value={terms}
              className="m-1 custm-cb-1"
              // {...formik.getFieldProps("acceptTerms")}
              onChange= {acceptTerms}
              checked={terms ===true? true:false}
            />
            <span />
            <h6 className="mt-2">
              I have read and understood all the instructions  
            </h6>
        </label>

       {formSubmitted && !terms  ?  
        <div className="fv-plugins-message-container text-left" >
            <div className="fv-help-block">Required</div>
          </div> 
      :""}
  

      <button className="btn btn-interview-downloads  mb-5 mr-5"  onClick={GotoMockInterview} > 
         Take Mock Interview 
      </button>


        <button onClick={ProceedInstructions} className="btn btn-interview-download mb-5"> 
        Start Interview
        </button>



      </div>
      </div>
    </Col>
    </div>
    </section> 
    </>
    ): ""}



{showThanksVideo ? (
  <>
{/* <instuctionPage 1> */}
   <section className="bg-root-c">
    <Col md={12} className="bg-21">
    <div>
    <h1  className=" text-center text-white ml-5 pt-5 pb-5"> Thank You Video 
    </h1>
    </div>
    </Col>

    <Col xs={12} md={12} className="text-center bbg-root-c  p-rel" >
       <div className="pl-5 pr-5 pb-50px">
       <div className="ins-border">      
        <div className="d-flex justify-content-center align-items-center" style={{"width":"100%"}}>
          
  
         <Media className="d-block" style={{"position":"relative"}}>
        {mediaProps => (
          <div
            className="media"
            onKeyDown={keyboardControls.bind(null, mediaProps)}
          >
          
          <Player 
              src={interviewDetails? interviewDetails.thanksVideo: ""}
              className="media-player" 
            autoPlay="true"
            playing="true"
            onEnded={OnthanksVideoEnded}            
            />

            <div className="media-controls" style={{"maxWidth": "640px","margin": "auto"}}>
              <PlayPause />
              <CurrentTime />
              {/* <Progress /> */}
              <SeekBar />
              <Duration />
     
            </div>
          </div>
        )}
      </Media>
      


          </div>
      </div>
      </div>
    </Col>
    </section> 
    </>
    ): ""}





{showIntroVideo ? (
  <>
{/* <instuctionPage 1> */}
   <section className="bg-root-c">
    <div className="">
    
    <div className="d-flex">
    <Col md={12} className="bg-21">
    <div>
    <h1  className=" text-center text-white ml-20 pt-5 pb-5"> Introductory Video 
    </h1>
    </div>
    </Col>

    </div>
    <Col xs={12} md={12} className="text-center bg-root-c  p-rel" >
       <div className="pl-5 pr-5 pb-50px">
       <div className="ins-border">      
        <div className="d-flex justify-content-center align-items-center" style={{"width":"100%"}}>
 


        <Media className="d-block" style={{"position":"relative"}}>
        {mediaProps => (
          <div
            className="media"
           /// onKeyDown={keyboardControls.bind(null, mediaProps)}
          >
            <Player 
              src={interviewDetails? interviewDetails.introVideo: ""}
              className="media-player" 
            autoPlay="true"
            playing="true"
            onEnded={onIntroVideoEnded}            
            />
            <div className="media-controls">
              {/* <PlayPause /> */}
              <CurrentTime />
              {/* <Progress /> */}
              <SeekBar />
              <Duration />
              {/* <MuteUnmute /> */}
              {/* <Volume /> */}
              {/* <Fullscreen /> */}
            </div>
          </div>
        )}
      </Media>


          </div>
        <div  className="d-flex justify-content-center align-items-center mt-5">
        <button      
        className={`btn btn-interview-download mb-5 ${isIntroVideoEnded ? "" : "no-drop"}`}
        onClick={DisplayvideoQuestion}
        disabled={isIntroVideoEnded?false:true }
        > 
           Go To Interview
        </button> 
        </div>
      </div>
      </div>
    </Col>
    </div>
    </section> 
    </>
    ): ""}

    
{showVideoQuestion ? (

<>  
{/* <instuctionPage 1> */}
   <section className="bg-root-c">
    <div className="">
    <div className="d-flex">
    <Col md={12} className="bg-21">
    <div>
    <h1  className=" text-center text-white ml-5 pt-5 pb-5"> Video Question
    </h1>
    </div>
    </Col>
    </div>
    <Col xs={12} md={12} className="text-center bg-root-c  p-rel" >
       <div className="pl-5 pr-5 pb-50px">
       <div className="ins-border">      
        <div className="d-flex justify-content-center" style={{"width":"100%"}}>
         <h2 className="pr-3">
         Question {CurrentQuesIndex+1} of {InterviewQuestionData?.length}
         </h2>

         <Col md={7} className="rel">

        {!showPlayerStartingText ? (
        <>
        <Media className="d-block" style={{"position":"relative"}}>
        { 
        mediaProps => (
          <div
            className="media"
            onKeyDown={keyboardControls.bind(null, mediaProps)}
          >
            <Player 
              src={InterviewQuestionData? InterviewQuestionData[CurrentQuesIndex].videoPath+"#t=0.1": ""}
              className="media-player" 
              poster={InterviewQuestionData? InterviewQuestionData[CurrentQuesIndex].videoQuestion ? '' : '/media/bg/audio.gif' : ''}
              
            autoPlay={true}
           // muted={true}
            onEnded={() => StartTimer()}
         ref={vidRef} 
         /> 
            <div className="media-controls" style={{"margin": "auto"}}>
              {  <PlayPause  className="ques-video"/>  }
              <CurrentTime />
              { /* <Progress /> */}
              <SeekBar />
              <Duration />
              { /* <MuteUnmute /> */}
              { /* <Volume /> }
              { <Fullscreen /> */ }
            </div>
            {InterviewQuestionData?<h3 className="d-block mt-0 text-dark">{InterviewQuestionData[CurrentQuesIndex].question}</h3>: ''}
          </div>  
          
        )}
      </Media>
    
      </>
        ) : ""
     }

      {showPlayerStartingText  ? (
        <div className="bg-vid" >
        <h1 className="recording-text" >

             Question Video Will Play Within 
           <br/>
           <span className="fs-80 text-center text-white">
             {countdown}
            </span>
             </h1>
         </div>
      ) : ""
      }



        </Col>

          <div className={`  ${!showreplayCountdown ? "hideTimer":""}`}>
          <h2 className="pl-3">
            Replay Question 
            <br/>
            Within
         </h2>
         <button className="btn btn-timer mt-5 mb-5"> 00:{seconds} Sec </button>
         </div>
        </div>
        
        <div  className="d-flex justify-content-center align-items-center mt-5">
        <button  
        className={`btn btn-interview-downloads mb-5 ml-5 ${replayButtonState ? "" : "no-drop"}`}        
        onClick={ReplayVideo}
        disabled={replayButtonState?false:true }
        > 
           Replay
        </button> 


        <button  
        className={`btn btn-interview-download mb-5 ml-5 ${replayButtonState ? "" : "no-drop"}`}        
        onClick={DisplayvideoAnswer}
        disabled={replayButtonState?false:true }
        > 
           Start Recording
        </button> 
        </div>
      </div>
      </div>
    </Col>
    </div>
    </section> 
    </>
    ): ""}
{showVideoAnswer && !showFinalThankyouPage && !showThanksVideo && !showInternetDisconnected && (CurrentQuesIndex < InterviewQuestionData.length)? (
  <>
{/* <instuctionPage 1> */}
   <section className="bg-root-c">
    <div className="">
    
    <div className="d-flex">
    <Col md={12} className="bg-21">
    <div>
    <h1  className=" text-center text-white ml-5 pt-5 pb-5"> Record Answer
    </h1>
    </div>
    </Col>
    </div>
    <Col xs={12} md={12} className="text-center bg-root-c  p-rel" >
       <div className="pl-5 pr-5 pb-50px">
       <div className="ins-border">      
        <div className="d-flex justify-content-center recorder-height" >
         <h2 className="pr-3">
         Answer {CurrentQuesIndex+1} of  {InterviewQuestionData.length} 
         </h2>
         <Col md={8} className="div-rec" >
         <div id="custom-video-id" style={{"position" : "relative"}}>
         <video id="answer_video" width="100%" autoPlay muted></video>
         <div id="meters" className="position-absolute" style={{"top" : "0", "right" :'10px',"z-index":"99999"}}>
        <div id="instant"> 
        <div className="label d-none" >Slow:</div>          
            <meter high="0.25" max="1" value="0"></meter>
            <div className="value d-none"></div>
        </div>
        <div id="slow" className="d-none">
            <div className="label">Slow:</div>
            <meter high="0.25" max="1" value="0"></meter>
            <div className="value"></div>
        </div>
        <div id="clip" className="d-none">
            <div className="label">Clip:</div>
            <meter max="1" value="0"></meter>
            <div className="value"></div>
        </div>
    </div>



                       {!ShowRecordingText && showStopBtn?
                      <a onClick={StopRecorder} className="position-absolute" style={{ "zIndex": "99999","background" : "rgba(255, 255, 255, 0.4)","left" : 0, "right": 0, "width":"80px", "height":"80px", "borderRadius" : "50%", "margin": "0 auto",}}>
                      <span className="position-absolute" style={{ "border" : "none","left" : 0, "right": 0, "width":"40px", "height":"40px", "borderRadius" : "4%", "margin": "20px","background":"rgba(227, 73, 28, 0.6)"}}>&nbsp;</span>
                     </a>
                      :''
                        }
          </div>

          {ShowRecordingText ? 

          <p  className="recording-text"  > Recording will start within <br/>
          <span className="fs-80 text-center text-white"> {answerStartIn} </span>
          </p>
          :""
         }

          </Col>
           
          <div>
          <h2 className="pl-3">
            Submit Answer
            <br/>
             Within
         </h2>
         <button className="btn btn-timer mt-5 mb-5"> {questionCounter} Sec </button>
         </div>
        </div>
        <div  className="d-flex justify-content-center align-items-center mt-5">
        <button 
        className={`btn btn-interview-download mb-5 `}        
        onClick={StopRecorder}
        id="submitanswerbtn" data-current-ques={CurrentQuesIndex}
        disabled={!showStopBtn}
        > 
           Submit Answer
        </button> 



        </div>

      </div>

      </div>
    </Col>
    </div>
    </section> 
    </>
    ): ""}


{/* ThankyouPage */}

{showFinalThankyouPage ? (
<>

<section className="bg-white">
  <Row  >
    <Col sm={12} xs={12} md={4} className="mt-50px text-center mb-50px" >
    { interviewDetails && interviewDetails.requisition?.company?.logo ?  
    <Image src={interviewDetails ? interviewDetails.requisition.company.logo: ""}  className="bg-circle"  width="120" height="110" alt="Company Logo bg-circle" ></Image>
   : 
   <span  className="bg-image-logo d-flex justify-content-center align-items-center"    >
      <p className="mt-3">
       Company 
       <br/>
       Logo
       </p>
   </span>
    }
    </Col>
     

     {/* {succefullUploadCount < InterviewQuestionData.length ?
      <Col>
        <p>Uploading Video Question....  </p>  
        <p>Please Do Not  Close the Browser....  </p>   
     </Col>
      :""
    }   */}
     

    <Col xs={12} sm={12} md={4} className="mt-50px text-center mb-50px " >
      <h6 className="text-theme-blue d-block text-left pt-2 pl-5"> 
      <img src="/media/logos/buildingreen.svg" className="icon-w30 fill-green" ></img>
      {interviewDetails ? interviewDetails.requisition.company.companyName: ""} 
       </h6>
       <h6 className="text-theme-blue d-block text-left pt-2 pl-5"> 
       <img src="/media/logos/globegreen.svg" className="icon-w30 fill-green" ></img>
       {interviewDetails ? interviewDetails.requisition.company.companyWebsite: ""} 
       </h6>
  
    </Col>
    <Col xs={12} sm={12} md={4} className="mt-50px text-center mb-50px" >
    <h6 className="text-theme-blue d-block text-left pt-2 pl-5"> 
      <>
      <img src="/media/logos/callgreen.svg" className="icon-w30 fill-green" ></img>
      {interviewDetails ? interviewDetails.requisition.company.companyDialCode + " ": ""}
      {interviewDetails ? interviewDetails.requisition.company.companyPhone + " ": ""}
      </>
      </h6>

      <h6 className="text-theme-blue d-block text-left pt-2 pl-5"> 
      <img src="/media/logos/addressgreen.svg" className="icon-w30 fill-green" ></img>
      {interviewDetails ? interviewDetails.requisition.company.companyAddress + " ": ""}
      </h6>
        
    </Col>
  
  </Row>
    </section>  

    <section className="" style={{"backgroundColor":"#003577","minHeight":"78vh","position":"relative"}}>
      <p className="text-center text-white d-block pt-100"> Thank You!</p>
      


    { succefullUploadCount < InterviewQuestionData.length ? 
        <>
        <p className="text-center text-white d-block">Your interview is getting submitted </p>  
        <p className="text-center text-white d-block">(Please Do not close or refresh this page untill the interview is uploaded)</p> 
        <Row   className="justify-content-md-center">
      <Col sm={12} xs={12} md={6} className="text-center" >  
        <ProgressBar animated now={(succefullUploadCount/InterviewQuestionData.length) * 100} label={`${((succefullUploadCount/InterviewQuestionData.length) * 100).toFixed(2)}%`}/>  
        </Col>
        </Row>
          </>
     :
   <><p className="text-center text-white d-block">Your Interview has been submitted.</p>
      <p className="text-center text-white d-block">You will hear back from us soon</p>
      </>
    }   
 </section>
    </>
) :""} 



{showInternetDisconnected ? (
<>

<section className="bg-white">
  <Row  >
    <Col sm={12} xs={12} md={4} className="mt-50px text-center mb-50px" >
    { interviewDetails && interviewDetails.requisition?.company?.logo ?  
    <Image src={interviewDetails ? interviewDetails.requisition.company.logo: ""}  className="bg-circle"  width="120" height="110" alt="Company Logo bg-circle" ></Image>
   : 
   <span  className="bg-image-logo d-flex justify-content-center align-items-center"    >
      <p className="mt-3">
       Company 
       <br/>
       Logo
       </p>
   </span>
    }
    </Col>
     

     {/* {succefullUploadCount < InterviewQuestionData.length ?
      <Col>
        <p>Uploading Video Question....  </p>  
        <p>Please Do Not  Close the Browser....  </p>   
     </Col>
      :""
    }   */}
     

    <Col xs={12} sm={12} md={4} className="mt-50px text-center mb-50px " >
      <h6 className="text-theme-blue d-block text-left pt-2 pl-5"> 
      <img src="/media/logos/buildingreen.svg" className="icon-w30 fill-green" ></img>
      {interviewDetails ? interviewDetails.requisition.company.companyName: ""} 
       </h6>
       <h6 className="text-theme-blue d-block text-left pt-2 pl-5"> 
       <img src="/media/logos/globegreen.svg" className="icon-w30 fill-green" ></img>
       {interviewDetails ? interviewDetails.requisition.company.companyWebsite: ""} 
       </h6>
  
    </Col>
    <Col xs={12} sm={12} md={4} className="mt-50px text-center mb-50px" >
    <h6 className="text-theme-blue d-block text-left pt-2 pl-5"> 
      <>
      <img src="/media/logos/callgreen.svg" className="icon-w30 fill-green" ></img>
      {interviewDetails ? interviewDetails.requisition.company.companyDialCode + " ": ""}
      {interviewDetails ? interviewDetails.requisition.company.companyPhone + " ": ""}
      </>
      </h6>

      <h6 className="text-theme-blue d-block text-left pt-2 pl-5"> 
      <img src="/media/logos/addressgreen.svg" className="icon-w30 fill-green" ></img>
      {interviewDetails ? interviewDetails.requisition.company.companyAddress + " ": ""}
      </h6>
        
    </Col>
  
  </Row>
    </section>  
    <section className="d-flex justify-content-center" style={{"backgroundColor":"#003577","minHeight":"78vh","position":"relative"}}>
      <h3 className="text-center text-white d-block pt-100">
      Your Interview has been interrupted due to inactivity or connectivity issue. 
      <br/>
      Please contact the company.
      </h3>
   </section>

    </>
) :""} 



<CustomizedDialogs></CustomizedDialogs>




</>

: 
<div className="d-flex justify-content-center align-items-center" style={{"height":"100vh"}}>
    <i className="fas fa-spinner fa-pulse fa-2x"></i>
</div> 
} 
</div>
  );
}





export default InterviewLink;