/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React, { useState } from "react";
import { Link , useHistory} from "react-router-dom";
import { useFormik } from "formik";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import * as Yup from "yup";
import { connect,useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import Helmet from "react-helmet";
import {login} from '../../../../actions/auth';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LoginAside from './LoginAside';
import Button from 'react-bootstrap/Button'
import  alertService  from '../../../_services/alertservice';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Header from './Header'
import Footer from './Footer'
// alertService.alert({ message :"Hi", type : 'error'  });


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    flexBasis: 200,
  },
}));

const initialValues = {
    email: "",
    password: "",
    uuid: "",
    fcmToken:"",
    device:"web",
};





function Login(props) {
  const { login } = props;
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
       .required("Please Enter your email"),       
      password: Yup.string()
      .required('Please Enter your password')
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };


  const histroy  =useHistory();
  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    validateOnChange:false,
    validateOnBlur:false,
      onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      login(values, setLoading, setShowVerificationLink,histroy);
    },
  });

  const classes = useStyles();
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false
  });

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };
 
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const [showVerificationLink,setShowVerificationLink] = useState(false)
  const resendVerificationEmail = () => {
      const verification = localStorage.getItem('verification-token');    
  
      fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/auth/resend_verification_email`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'x-access-token': verification
        },
      })
      .then((res) => res.json())
      .then((res) => {
        if(res.status==true){
        alertService.alert({ message :res.message, type : 'success'  });
        }
        else{
          alertService.alert({ message :res.message, type : 'error'  });
         
        }
      })
      .catch(() => {
      
      });
      }


      const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
          /* you can also use 'auto' behaviour
             in place of 'smooth' */
        });
      };
    

  return (
    <>
    <Helmet>
       <title> VRekruit | Login </title>
    </Helmet>

    <Header/>
    {/* <Notifications /> */}
    <div className="d-flex flex-column flex-root">      
        {/*begin::Login*/}
        <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white " id="kt_login">
        <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden center-flex">
            <div className="" id="kt_login_signin_form"  >                    
                  <div className="mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 mb-5">
                   { /*   <img className="img-responsive mt-5 mb-5" width="200" src="/media/logos/UPDATED_VRekruit _Logo.png"  /> */}
                      {/* <div className="mt-15"></div> */}
                      <h3 className="text-dark font-weight-bold mt-5 mb-5 mt-50" style={{"color":"#000"}}>Enter Your Login Details </h3>
                    <div className="mt-15"></div>                         
                  </div>
              {/*begin::Form*/}
              <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
                <div className="form-group fv-plugins-icon-container">
                <label className="form-label auth-lable" htmlFor="formGridEmail">Email Address </label>
                  <input
                    placeholder="Email"
                    type="email"
                    className={`form-control form-control-solid h-auto py-12p pyb-12p`}
                    name="email"
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.email}</div>
                    </div>
                  ) : null}
                </div>
                <div className="form-group fv-plugins-icon-container">
                <span className="d-flex justify-content-space-between"> 
                <label className="form-label auth-lable" htmlFor="formGridPassword">Password</label>
                <label className="form-label auth-lable" htmlFor="formGridPassword">
                </label>
                </span>
                <TextField
                    id="filled-adornment-password"
                    className={`1clsx(classes.margin, classes.textField) form-control form-control-solid h-auto `}
                    variant="filled"
                    type={values.showPassword ? 'text' : 'password'}
                    label="Password"
                    value={values.password}
                    onChange={handleChange('password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    name="password"
                    {...formik.getFieldProps("password")}
                  />   
                  <span className="d-flex" > 
                  {formik.touched.password && formik.errors.password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.password}</div>
                    </div>
                  ) : null}
                  </span>
                  <label className="form-label auth-lable" htmlFor="formGridPassword" style={{"justifyContent": "flexEnd","width": "100%","textAlign":"end"}}>
                  <Link to="/auth/forgot-password" className="text-dark-50 text-hover-primary my-3 mr-2" id="kt_login_forgot">
                     <h4 className="text-dark mt-3"> Forgot Password? </h4>
                  </Link>
                  </label>
                </div>
                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">         
                  <Button id="kt_login_signin_submit" type="submit" className={`btn btn-primary btn-theme-green auth-btns auth-btns-text font-weight-bold`}
                  style={{"width":"100%"}} 
                  >
                    LOGIN
                    {loading && <span className="ml-3 spinner spinner-white"></span>}
                  </Button>
                  <span className="font-weight-bold pt-2  text-center"  style={{"width":"100%","color":"#000 !important","fontSize":"18px"}}> 
                        Don't have an account?
                    <Link to="/auth/registration" className="text-theme-green font-weight-bold ml-2 " >                
                      Register Now                
                    </Link>  
                    </span>   

                   

              
                    {showVerificationLink? (
                    <p className="font-weight-bold pt-2  text-center"  style={{"width":"100%","color":"#000 !important","fontSize":"21px"}}> 
                      <small className="font-weight-bold">  Not Verfied Yet? </small>

                    <small  onClick={resendVerificationEmail}  className="text-theme-green font-weight-bold ml-2 c-p" >                
                      Resend Verification Email                
                    </small>  
                    </p>  
                    ): ""}   

                </div>
              </form>
              {/*end::Form*/}
            </div>
        </div>
        {/*side image::Aside*/}
        <LoginAside />
        {/*side image::Aside*/}
      </div>
      </div>


      <Footer/>
    </>
  );
}

export default connect(null, {login})(Login);
