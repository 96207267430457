import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {BuilderPage} from "./pages/BuilderPage";
import {MyPage} from "./pages/MyPage";
import {DashboardPage} from "./pages/DashboardPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import Profile from "./pages/Profile";
import ProfileEdit from "./pages/ProfileEdit";
import ThankPage from './pages/../modules/Auth/pages/ThankPage';
import BrowserNotSupported from '../app/modules/Auth/pages/BrowserNotSupported'
//import TokenExpire from './pages/../modules/Auth/pages/TokenExpire';
import ViewQuestion from   '../app/pages/QuestionManagement/ViewQuestion'
import AddQuestion from   '../app/pages/QuestionManagement/AddQuestion'
import ParentCategory from   '../app/pages/CategoryManagement/AddNewParentCategory'
import ViewCategory from   '../app/pages/CategoryManagement/ViewCategory'
import CreateTest from '../app/pages/TestMangement/ManageTest'
import AddNewRequisition from '../app/pages/RequistionManagement/AddNewRequistion'
import ViewRequsition from '../app/pages/RequistionManagement/ViewRequistion'
import ViewRequistionRecord from './pages/RequistionManagement/ViewRequistionRecord'
import EditRequisitionByID from './pages/RequistionManagement/EditRequisitionByID'
import CreateManualTest from '../app/pages/CreateTest/CreateManualTest'
// import CreateRandomTest from '../app/pages/CreateTest/manageandomTest'
import ManageTest from '../app/pages/CreateTest/ManageTest'
import EditTest from '../app/pages/CreateTest/EditTest'
import ViewTest from '../app/pages/CreateTest/ViewTest'
import ShareTest from '../app/pages/TestMangement/ShareTest'
import ViewCompanyDetails from "./pages/CompanyMangement/ViewCompanyDetails";
import EditCompanyDetails from "./pages/CompanyMangement/EditCompanyDetails";
import InterviewAddCategory from '../app/pages/InterviewManagement/CategoryManagement/InterviewAddCategory'
import InterviewViewCategory from '../app/pages/InterviewManagement/CategoryManagement/InterviewViewCategory'
import InterviewAddQuestion from '../app/pages/InterviewManagement/QuestionMangement/InterviewAddQuestion'
import InterviewViewQuestion from '../app/pages/InterviewManagement/QuestionMangement/InterviewViewQuestion'
import InterviewAddFitment from '../app/pages/InterviewManagement/FitmentManagement/InterviewAddFitment'
import InterviewViewFitment from '../app/pages/InterviewManagement/FitmentManagement/InterviewViewFitment'
import CreateInterview from '../app/pages/InterviewManagement/CreateInterview/CreateInterview'
import ManageInterview from '../app/pages/InterviewManagement/ManageInterview/ManageInterview'
import ViewInterview from '../app/pages/InterviewManagement/ManageInterview/ViewInterview'
import EditInterview from '../app/pages/InterviewManagement/ManageInterview/EditInterview'
import ShareInterview from '../app/pages/InterviewManagement/ManageInterview/ShareInterview'
import AssignedInterview from '../app/pages/ReviewModule/AssignedInterview'
import InterruptedInterview from '../app/pages/ReviewModule/InterruptedInterview'
import ViewAssginedInterview from '../app/pages/ReviewModule/ViewAssginedInterview'
import ViewExpiredInterview from '../app/pages/ReviewModule/ViewExpiredInterview'
import ViewInterruptedInterview from '../app/pages/ReviewModule/ViewInterruptedInterview'

import AddNewUser from '../app/pages/UserManagement/AddNewUser'
import EditUser from '../app/pages/UserManagement/EditUser'
import UserList from '../app/pages/UserManagement/UserList'
import ViewUser from '../app/pages/UserManagement/ViewUser'
import AddPayment from './pages/PaymentModule/AddPayment'
import ViewPricing from '../app/pages/PaymentModule/ViewPricing'
import PaymentList from '../app/pages/PaymentModule/PaymentList'
import InvoiceList from "./pages/PaymentModule/InvoiceList";
import SuperAdminInvoiceList from './pages/PaymentModule/SuperAdminInvoiceList'




import ExpiredInterview from '../app/pages/ReviewModule/ExpiredInterview'
// import InterviewEditQuestion from './pages/InterviewManagement/QuestionMangement/InterviewEditQuestion';
import InterviewEditQuestion from '../app/pages/InterviewManagement/QuestionMangement/InterviewEditQuestion'
import ViewCompaniesListing from "./pages/SuperAdmin/ViewCompanyDetails/ViewCompaniesListing";
import ViewCompainesDetails from "./pages/SuperAdmin/ViewCompanyDetails/ViewCompainesDetails";

import CandidateEvaluation from "./pages/CandidateEvaluation/Filter";

import ProductDemo from "./pages/ProductDemo";






export default function BasePage() {
    // useEffect(() => {
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                <ContentRoute path="/dashboard" component={DashboardPage}/>
                <ContentRoute path="/add-question" component={AddQuestion}/>
                <ContentRoute path="/parent-category" component={ParentCategory}/>
                <ContentRoute path="/view-category" component={ViewCategory}/>
                <ContentRoute path="/view-question" component={ViewQuestion}/>
                <ContentRoute path="/builder" component={BuilderPage}/>
                <ContentRoute path="/my-page" component={MyPage}/>
                <ContentRoute path="/create-test" component={CreateTest}/>
                <ContentRoute path="/add-requistion" component={AddNewRequisition}/>

             

                <ContentRoute path="/view-requistion" component={ViewRequsition}/>
                <ContentRoute path="/manual-test" component={CreateManualTest}/>
                <ContentRoute path="/manage-test" component={ManageTest}/>


                <ContentRoute path="/user/add" component={AddNewUser}/>
                <ContentRoute path="/view-details/user/:id" render={(props) => <ViewUser {...props} />}/> 
                <ContentRoute path="/edit-details/user/:id" render={(props) => <EditUser {...props} />}/> 
                <ContentRoute path="/user/list" component={UserList}/>
                <ContentRoute path="/user/edit" component={EditUser}/>

    
                <ContentRoute path="/payment/add" component={AddPayment}/>
                <ContentRoute path="/payment/pricing" component={ViewPricing}/>
                <ContentRoute path="/payment/ledger" component={PaymentList}/>
                <ContentRoute path="/payment/invoices" component={InvoiceList}/>
                <SuperAdminInvoiceList path="/admin/invoices" component={SuperAdminInvoiceList}/>

                <ContentRoute path="/interview/add-category" component={InterviewAddCategory}/>
                <ContentRoute path="/interview/view-category" component={InterviewViewCategory}/>
                <ContentRoute path="/interview/add-question" component={InterviewAddQuestion}/>
                <ContentRoute path="/interview/view-question" component={InterviewViewQuestion} />
                <ContentRoute path="/interview/add-fitment" component={InterviewAddFitment} />
                <ContentRoute path="/interview/view-fitment" component={InterviewViewFitment} />
                
                <ContentRoute path="/interview/create-interview" component={CreateInterview}/> 
                <ContentRoute path="/interview/manage-interview" component={ManageInterview}/> 
                <ContentRoute path="/interview/View-interview/:id" render={(props) => <ViewInterview {...props} />}/> 
                <ContentRoute path="/interview/edit-interview/:id" render={(props) => <EditInterview {...props} />}/> 
                <ContentRoute path="/interview/share-interview/:id" render={(props) => <ShareInterview {...props} />}/> 
                <ContentRoute path="/interview/edit-question/:id" render={(props) => <InterviewEditQuestion {...props} />}/> 

                <ContentRoute path="/assigned-interview" component={AssignedInterview}/> 
                <ContentRoute path="/interrupted-interview" component={InterruptedInterview}/> 
                <ContentRoute path="/expired-interviews" component={ExpiredInterview}/> 
                <ContentRoute path="/thanks" component={ThankPage} />
                <ContentRoute path="/browser-not-supported" component={BrowserNotSupported} />

                
                <ContentRoute path="/interview-detail/view/:id" render={(props) => <ViewAssginedInterview {...props} />}/> 
                <ContentRoute path="/exp-interview-detail/view/:id" render={(props) => <ViewExpiredInterview {...props} />}/> 
                <ContentRoute path="/int-interview-detail/view/:id" render={(props) => <ViewInterruptedInterview {...props} />}/> 
                
        
                <ContentRoute path="/manage/edit-test/:id" render={(props) => <EditTest {...props} />}/> 
                <ContentRoute path="/manage/view-test/:id" render={(props) => <ViewTest {...props} />}/> 
                <ContentRoute path="/manage/share-test/:id" render={(props) => <ShareTest {...props} />}/> 
                {/* <ContentRoute path="/random-test" component={CreateRandomTest}/>   */}
                {/* <ContentRoute path="/edit-requistion" component={EditRequestion}/> */}
                <ContentRoute path="/view-requistions/:id" render={(props) => <ViewRequistionRecord {...props} />}/> 
                <ContentRoute path="/edit-requistion/:id" render={(props) => <EditRequisitionByID {...props} />}/> 
                {/* <ContentRoute path="/view-requistions/:id/:search/:assigned/:created/:status" render={(props) => <ViewRequistionRecord {...props} />}/>  */}
                <ContentRoute path="/change-password" component={ChangePasswordPage}/>
                <ContentRoute path="/profile" component={Profile}/>
                <ContentRoute path="/profile-edit" component={ProfileEdit}/>

               
                <ContentRoute path="/admin/comapanies-list" component={ViewCompaniesListing}/>
                <ContentRoute path="/comapanies-details/view/:id" render={(props) => <ViewCompainesDetails {...props} />}/> 


         
                <ContentRoute path="/company-mangement/view" component={ViewCompanyDetails}/>
                <ContentRoute path="/company-mangement/edit" component={EditCompanyDetails}/>

                <ContentRoute path="/candidate-evaluation" component={CandidateEvaluation}/>

                <ContentRoute path="/product-demo" component={ProductDemo}/>        
        
                {/* <Redirect to="/error/error-v1"/> */}
                {/* <Route render={() => <Redirect to="/error/error-v1" />} /> */}
                <Route render={() => <Redirect to="/dashboard" />} />

                

            
            </Switch>
        </Suspense>
    );
}
