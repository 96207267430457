import axios from 'axios';

const setAuthToken = token => {
    if (token) {
        localStorage.setItem("x-access-token", token);
        axios.defaults.headers.common['x-access-token'] = token;
    } else {
      //  delete axios.defaults.headers.common['x-access-token'];
    }
}


export default setAuthToken;