/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Helmet from 'react-helmet'
import { Link, useHistory } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { logout } from '../../../actions/auth';
import { useDispatch } from 'react-redux';
import alertService from '../../_services/alertservice';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const initialValues = {
  status: "open",
  page: 1,
  limit: 10,
  assignedToMe: false,
  createdByMe: "0",
  orderBy: "Id",
  orderType: "ASC"
}

function ViewRequestion() {
 
   const [requistions, setRequistions] = useState([]);
   const [staticData, setStaticData] = useState(10);
   const [searchTerm, SetSearchTerm] = useState("");
   const [createdbyme, SetCreatedbyme] = useState(false);
   const [assignedbyme, SetAssignedbyme] = useState(false);
   const [status, SetStatus] = useState("");



   const resetFilter = () => {
    SetAssignedbyme(false);
    SetCreatedbyme(false)
    SetSearchTerm("");
    SetStatus("");
    get_List_without_filters();
   }




   const dispatch = useDispatch();
   const logoutMethod = () => {
     dispatch(logout());
     history.push('/auth/signin');
 };



// filter option 
const SetSearchTermData = (x) => {
  let data =  x.target.value;
 if(data){
  SetSearchTerm(x.target.value);
 }
 else{
  SetSearchTerm("");
 }

} 

const SetStatusData = (x) => {
    let data =  x.target.value;
   if(data){
     SetStatus(x.target.value);
   }
}

const SetAssignedbymeData = (x) => {
  SetAssignedbyme(x.target.checked);
}

const SetCreatedbymeData = (x) => {
  SetCreatedbyme(x.target.checked);
}






// filter option 
const [loading,SetLoading]=useState(false);

  var i = 1;
  const get_Requisition_List = () => {
 
    const token = localStorage.getItem('x-access-token');
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/company/requisitions?page=1&limit=1000&forListing=true`;
    SetLoading(true);
    if(searchTerm) {
      url = url + "&position=" + searchTerm;
      

    }
    if(status) {
      url = url + "&status=" + status;
    }
    if(createdbyme) {
      url = url + "&createdByMe=" + createdbyme;
    }
    if(assignedbyme) {
      url = url + "&assignedToMe=" + assignedbyme;
    }
 
    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setRequistions(res.data);
          setStaticData(staticData + 10);
          SetLoading(false);
        }
        else if(res.sessionExpired){
          logoutMethod();
          alertService.alert({ message :res.message, type : 'error'  });
          SetLoading(false);
        }
  
        else {
          SetLoading(false);
        }
      })
      .catch(() => {
        SetLoading(false);
      });
  }



  const get_List_without_filters = () => {
    const token = localStorage.getItem('x-access-token');
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/company/requisitions?page=1&limit=1000&forListing=true`;
    SetLoading(true);
    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setRequistions(res.data);
          setStaticData(staticData + 10);
          SetLoading(false);
        }
        else if(res.sessionExpired){
          logoutMethod();
          SetLoading(false);
          alertService.alert({ message :res.message, type : 'error'  });
        }
  
        else {
          SetLoading(false);
        }
      })
      .catch(() => {
        SetLoading(false);
      });
  }



  useEffect(() => {
    let mounted = true;
    get_Requisition_List();
    return () => mounted = false;
  }, [])

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();



  const actionControls = {
    onClick: (e, row, rowIndex) => {
   if(row) {
       let id = row.id;
       if (e.target.classList.contains("view-option")) {
       history.push("/view-requistions/" + id)
       // get_Requisition_List();
      }
    }
  }
  };


  function actionFormatter(column, colIndex) {
    return (
      <div className="text-left">
        <img src="/media/VRekruitIcons/eyeorview.svg" className="view-option c-p view-eye-ico" title="View" alt="View"  />
      </div>
    );
  }

  function headerformatter(column, colIndex) {
    return (
      <div>
             Requisition ID
            <img src="/media/VRekruitIcons/sort.svg" className="sort-icon c-p" width="30" height="30" alt="" />
      </div>
    );
  }

  function headerformatter1(column, colIndex) {
    return (
      <div>
            Job Title
            <img src="/media/VRekruitIcons/sort.svg" className="sort-icon c-p" width="30" height="30" alt="" />
      </div>
  );
  }


  function headerformatter2(column, colIndex) {
    return (
      <div>
            Created By
            <img src="/media/VRekruitIcons/sort.svg" className="sort-icon c-p" width="30" height="30" alt="" />
      </div>
    );
  }


  function headerformatter3(column, colIndex) {
    return (
      <div>
            Status
            <img src="/media/VRekruitIcons/sort.svg" className="sort-icon c-p" width="30" height="30" alt="" />
      </div>
    );
  }


  function headerformatter4(column, colIndex) {
    return (
      <div>
         Action
      </div>
    );
  }


  function  CreatedByUserFormater(column, colIndex) {
    return(
      <span>
        {column.firstName}  {column.lastName} 
      </span>
    );
  }


  function  JobTitleFormatter(column, colIndex) {
    return(
      <span>
          {column}  
          </span>
    );
  }

  


 var columns = [{
    dataField: 'requisitionId',
    text: "Requisition ID",
    sort: true,
    headerFormatter: headerformatter
  }, {
    dataField: 'position',
    text: "Job Title",
    sort: true,
    formatter:JobTitleFormatter,
    headerFormatter: headerformatter1
  },
  {
    dataField: 'createdByUser',
    text: "Created By",
    sort: true,
    formatter:CreatedByUserFormater,
    headerFormatter: headerformatter2
  },
  {
    dataField: 'status',
    text: "Status",
    sort: true,
    headerFormatter: headerformatter3
  },

  {
    dataField: "action",
    text: "Action",
    sort: true,
    formatter: actionFormatter,
    headerFormatter: headerformatter4
  },
  ];


  const options = {
    paginationSize: 5,
    // custom: true,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    //  withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    
    onPageChange: (page, sizePerPage) => {

    },
    disablePageTitle: true,
    sizePerPageList: [ {
      text: '20', value: 20
    },{
    text: '50', value: 50
    },
    {
      text: 'All', value: requistions.length
    }] // A numeric array is also available. the purpose of above example is custom the text    
  };


  return (
    <>
      <Helmet>
        <title>
          View Requisition
        </title>
      </Helmet>


      {/* subeader */}
      <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Requisition Management</h3></div>
          </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
      {/* subeader */}


      <Col className={classes.root} >   



    <Row className="bg-white  d-flex flex-grow-1 align-items-center  justify-content-start mb-sm-5 sticky-header"> 

    <Col md={6} sm={6} offset= {4}  className="pr-3 pl-3 mt-3">      
      <div>
       <div className="   rounded">     
        <div>
        <h5 className="font-weight-bold f-w-600 pl-5" >Requisition List</h5>
        </div>
      </div>
      </div>
      </Col>
      <Col md={6} sm={6} offset= {4}  className="pr-3 pl-3 mt-3">
      <div>
       <div className="d-flex flex-grow-1 align-items-center justify-content-end rounded mt-1">
        <div className="text-muted d-block">
           <Link to="/add-requistion" type="button" className="btn btn-theme-green btn-lg mr-5 mt-2 mb-0"  >
              Create Requisition
           </Link>
       
      </div>
      </div>
      </div>
      </Col>
      <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5"></Col>  
      </Row>


        <Col className="Content-custom-padding mb-sm-5"  style={{"minWidth":"200px"}}>
          <Row className="align-items-center">
            <Col sm={4} md={2} className="mb-sm-5">
              <Form.Control as="select" placeholder="Status"  value={status}  onChange={(e) => SetStatusData(e)} >
                <option value="">Status</option>
                <option value="open"> Open </option>
                <option value="close"> Close </option>
              </Form.Control>
            </Col>


            <Col sm={4} md={2} className="mb-sm-5" style={{"minWidth":"200px"}}>
              <span className="pl-5">
                <Form.Group as={Row} controlId="formHorizontalPassword" className="pl-5 pt-3">
                  <Form.Label className="pr-5">
                    Created By me
                  </Form.Label>
                  <Form.Check aria-label="option 1" size="lg"  checked={createdbyme} onChange={(e) => SetCreatedbymeData(e) } />
                </Form.Group>
              </span>
            </Col>

            <Col sm={4} md={2} className="mb-sm-5" style={{"minWidth":"200px"}}>

              <span className="pl-5">
                <Form.Group as={Row} controlId="formHorizontalPassword" className="pl-5 pt-3">
                  <Form.Label className="pr-5">
                    Assigned to me
                </Form.Label>
                  <Form.Check aria-label="option 1" size="lg"  checked={assignedbyme}  onChange={ (e) => SetAssignedbymeData(e)} />
                </Form.Group>
              </span>
            </Col>

            <Col sm={4} md={3} className="d-flex mb-sm-5" style={{"minWidth":"200px"}}>
            <Form.Label >
              </Form.Label>
              <div style={{"position":"relative","width":"100%"}}>
          
            <div className="input-group" >
    
              <Form.Control type="text" value={searchTerm ? searchTerm : "" }  placeholder="Search..." id="search-text" className="border-radius-12" onInput={(e) => SetSearchTermData(e)}  style={{"paddingLeft":"35px",}} />
              <div className="input-group-prepend">
          
             </div>
              </div>
              <i className="fa fa-search"  style={{"position":"absolute","left":"7px","top": "50%","transform": "translate(7px, -50%)","borderRadius":"8px"}} ></i> 

              </div>
      
            </Col>

            <Col className="text-center mb-sm-5">
            <Button  type="button" className="btn btn-search ml-5" id="filter-btn" onClick={get_Requisition_List}  style={{"borderRadius":"8px"}} >
                 Apply
              </Button>
           
            <Button  type="button" className="btn btn-reset ml-5"  id="reset-filter-btn"  onClick={resetFilter}  style={{"borderRadius":"8px"}} >
                  Reset
              </Button>
            </Col>

          </Row>
 
          <div style={{"minHeight": "50vh"}}>
            {requistions &&  requistions.length > 20 &&  requistions.length > 0  && (
              <BootstrapTable className="bg-white" keyField='id' data={requistions} columns={columns} options={options} pagination={paginationFactory(options)} rowEvents={actionControls} />
            )}
           {requistions &&  requistions.length === 0  && (
             <>
            { /*  <BootstrapTable className="bg-white" keyField='id' data={requistions} columns={columns} options={options}  rowEvents={actionControls} /> */}
            
            {!loading ?
             <p className="text-center mt-5 pt-5" style={{"fontSize":"30px","minHeight":"200px","display":"flex","justifyContent":"center","alignItems":"center"}}>
                No Records Found
             </p>
            :""}
              
            {loading ?
              <p className="text-center mt-5 pt-5" style={{"fontSize":"30px","minHeight":"200px","display":"flex","justifyContent":"center","alignItems":"center"}}> 
                <ele id="no-data">  
                  <i className="fas fa-spinner fa-pulse fa-2x"></i>
                </ele> 
              </p>
            :""}

              </>
           )}

            {requistions  && requistions.length <= 20 && requistions.length > 0 &&
            (
              <BootstrapTable className="bg-white" keyField='id' data={requistions} columns={columns} options={options}  rowEvents={actionControls} />
            )
            }
          </div>
        </Col>
      </Col>


    </>
  );
}

export default ViewRequestion