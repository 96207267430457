/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
import React, { useState, useEffect, FC, memo, } from "react";
import { isAndroid, isIOS, isSafari, isChrome, isFirefox, osName } from "react-device-detect";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {Layout} from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import LandingPage from '../app/modules/Auth/pages/LandingPage'
import Login from '../app/modules/Auth/pages/Login'
import Registration from '../app/modules/Auth/pages/Registration'
import ForgotPassword from '../app/modules/Auth/pages/ForgotPassword'
import ThankPage from '../app/modules/Auth/pages/ThankPage'
import BrowserNotSupported from '../app/modules/Auth/pages/BrowserNotSupported'

import TokenExpire from '../app/modules/Auth/pages/TokenExpire'
import ResetPassword from '../app/modules/Auth/pages/ResetPassword'
import {connect} from 'react-redux';
import  Notifications from '../app/_services/Notifications';
import  Terms from '../app/modules/Auth/pages/TermsPage';
import Privacy from './modules/Auth/pages/PrivacyPage';
import CandidateInterview from '../app/pages/Candidate/Interview/Interview'
import InterviewCandidate from "../app/modules/Auth/pages/InterviewCandidate";
import InterviewLinkExpired from "../app/modules/Auth/pages/InterviewLinkExpired";
import InterviewLinkSubmitted from "../app/modules/Auth/pages/InterviewLinkSubmitted";
import Interview from './pages/Candidate/Interview/Interview';
import InterviewLink from  "../app/modules/Auth/pages/InterviewLink";
import InterviewMock from "../app/modules/Auth/pages/InterviewMock";
import ResetTempPassword from '../app/modules/Auth/pages/ResetTempPassword'
import Contact from "./modules/Auth/pages/Contact"



 function Routes({auth:{isAuthenticated}}) {
    // const auth = useSelector(state => state.auth);

    return (
        <>
         <Notifications />
        <Switch>
            {!isAuthenticated ? (
                
                /*Render auth page when user at `/auth` and not authorized.*/
               <>
                <Switch>
                    <Route path="/auth/signin" component={Login} />
                    <Route path="/auth/registration" component={Registration} />
                    <Route path="/change/temp-password" component={ResetTempPassword} />

                    <Route path="/reset-password" render={(props) => <ResetPassword {...props} />}/>
                    {/* <ContentRoute path="/manage/share-test/:id" render={(props) => <ShareTest {...props} />}/>  */}
                    <Route path="/auth/forgot-password" component={ForgotPassword} />
                    <Route path="/browser-not-supported" component={BrowserNotSupported} />
                    <Route path="/terms" component={Terms} />
                    <Route path="/privacy" component={Privacy} />
                    <Route path="/thanks" component={ThankPage} />
                    <Route path="/token-expire" component={TokenExpire} />

                    <Route path="/interview-expired" component={InterviewLinkExpired} />
                    <Route path="/interview-submitted" component={InterviewLinkSubmitted} />

                    {/* /candidate/interview?shareKey=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjYW5kaWF0ZUlkIjoxMDAsImludGVydmlld0lkIjoiNjIiLCJpYXQiOjE2MjU4MDUzNDksImV4cCI6MTYyNTgwNTk0OX0.R8qvglaPYO2Z69mZMIna8n5_y4sjUoBmH3VQ8yfhB_o&module=interview */}
                    <Route path="/candidate/interview" component={InterviewCandidate} />

                    <Route path="/interview" component={InterviewLink} />

                    <Route path="/mock" component={InterviewMock} />

                    <Route path="/interview/candidate" component={Interview} />
                    <Route path="/homepage" component={LandingPage} />
                    <Route path="/contact" component={Contact} />  
                    <Route path="/homepage" component={LandingPage} />    
                    <Route render={() => <Redirect to="/homepage" />} />
                </Switch>
               </>
            
            ) : (
                        /*Otherwise redirect to root page (`/`)*/
                        (isSafari || (osName == 'Mac OS' && isSafari) || (osName == 'Windows' && ( isChrome || isFirefox))) ?
                    <Redirect from="/auth" to="/" />
                            :
                            <>
                                <Redirect to="/browser-not-supported" />
                                <Switch>
                                    <Route path="/browser-not-supported" component={BrowserNotSupported} />
                                    </Switch>
                                </>
                  
            )}
            <Route path="/error" component={ErrorsPage}/>
            <Route path="/logout" component={Logout}/>
            {!isAuthenticated ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to="/auth/signin"/>
            ) : (
                <Switch>
                            <Route exact path="/thanks" component={ThankPage} />
                            <Route exact path="/browser-not-supported" component={BrowserNotSupported} />

                <Layout>
                    <BasePage/>
                </Layout>
            </Switch>
            )}
        </Switch>

        </>
    );
}


const mapStateToProps = (state) => ({
    auth:state.auth
})

export default connect(mapStateToProps)(Routes);
