/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormFile from 'react-bootstrap/FormFile'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Table from 'react-bootstrap/Table'
import { divide } from 'lodash';
import Helmet from 'react-helmet'
import { Link, useHistory } from "react-router-dom";
import { Redirect } from 'react-router';
import alertService from '../../_services/alertservice';
import { Button } from 'react-bootstrap';
import { CALL_HISTORY_METHOD } from 'react-router-redux';


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const initialValues = {
  status: "open",
  page: 1,
  limit: 10,
  assignedToMe: false,
  createdByMe: "0",
  orderBy: "Id",
  orderType: "ASC"
}

function ViewQuestion() {
 
   const [QuestionsList, setQuestionsList] = useState([]);
   const [staticData, setStaticData] = useState(10);
   const [ parentCategories, setParentCategories ] = useState([]); 
   const [searchTerm, SetSearchTerm] = useState("");
   const [createdbyme, SetCreatedbyme] = useState(false);
   const [assignedbyme, SetAssignedbyme] = useState(false);
   const [status, SetStatus] = useState("");
   const [selectedId,setSelectedOption] = useState(true);
   const [ subCategory, setSubCategory ] = useState([]);
   const [ subCategories, setSubCategories ] = useState([]);  
   const [categorySelected, SetcategorySelected] = useState("");
   const [subcategorySelected, SetSubcategorySelected] = useState("");
   const [difficultyLevel, setDifficultyLevel ] = useState("");
   const [questionType, setQuestionType ] = useState("")




   











// filter option 
const SetSearchTermData = (x) => {
  let data =  x.target.value;
 if(data){
  SetSearchTerm(x.target.value);
 }
 else{
  SetSearchTerm("");
 }
} 

function handleCategorySelect(event) {
  if(event.target.value){
    SetcategorySelected(event.target.value);  
  }
  else{
    SetcategorySelected("");
  }          
  // document.getElementById("subcategoryvalue").value= ""    
  var parentId = (event.target.value);
  get_sub_categories(parentId);
}

function handleSubCategorySelect(event) {
  if(event.target.value){
    SetSubcategorySelected(event.target.value);
  }
  else{
    SetSubcategorySelected("");
  }          
 
}


function handleDifficultyLevelSelect(event) {
  if(event.target.value){
    setDifficultyLevel(event.target.value);
  }
  else {
    setDifficultyLevel("");

  }
          
}



function handleQuestionTypeSelect(event) {
  if(event.target.value){
    setQuestionType(event.target.value);
  }
  else {
    setQuestionType("");

  }
          
}















const SetAssignedbymeData = (x) => {
  SetAssignedbyme(x.target.checked);
}

const SetCreatedbymeData = (x) => {
  SetCreatedbyme(x.target.checked);
}






// filter option 

const resetFilter = () => {
  SetAssignedbyme(false);
  SetCreatedbyme(false)
  SetSearchTerm("");
  SetStatus("");
  SetcategorySelected("");
  SetSubcategorySelected("");
  get_List_without_filters();
 }




  var i = 1;
  const get_Question_List = () => {
 
    const token = localStorage.getItem('x-access-token');
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/test/questions?page=1&limit=1000`;
    //  history.push("/searchTerm");
    if(difficultyLevel) {
      url = url + "&difficultyLevel=" + difficultyLevel;

    }

    if(categorySelected) {    
      url = url + "&categoryId=" + categorySelected;

    }

    if(subcategorySelected) {
      url = url + "&subCategoryId=" + subcategorySelected;

    }

    if(questionType) {
      url = url + "&questionType=" + questionType;

    }

    if(searchTerm) {
      url = url + "&searchBy=" + searchTerm;
    }

  
 
    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setQuestionsList(res.data);
          setStaticData(staticData + 10);
        }
        else {
        }
      })
      .catch(() => {
      });
  }



  const get_List_without_filters = () => {
    const token = localStorage.getItem('x-access-token');
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/test/questions?page=1&limit=1000`;
    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setQuestionsList(res.data);
          setStaticData(staticData + 10);
        }
        else {
        }
      })
      .catch(() => {
      });
  }



  const get_parent_categories = (values) => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/test/categories?page=1&limit=1000`, {
      
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(values)
    })
    .then((res) => res.json())
    .then((res) => {
      if(res.status==true){
        setParentCategories(res.data);
      }
      else{
      }
    })
    .catch(() => {
    });
    }




  useEffect(() => {
    let mounted = true;
    get_Question_List();
    get_parent_categories();
    return () => mounted = false;
  }, [])

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();



  const actionControls = {
    onClick: (e, row, rowIndex) => {
   if(row) {
       let id = row.id;
       if (e.target.classList.contains("view-option")) {
       history.push("/view-QuestionsList/" + id)
       // get_Question_List();
      }
    }
  }
  };


  function actionFormatter(column, colIndex) {
    return (
      <div className="text-left d-flex">
        <img src="/media/VRekruitIcons/eyeorview.svg"  className="view-option c-p view-eye-ico-1" />

        <img src="/media/VRekruitIcons/edit.svg" className="edit-option c-p view-eye-ico ml-5" />


        <img src="/media/VRekruitIcons/delete.svg" className="delete-option c-p view-eye-ico ml-5" />

      </div>
    );
  }

  function  CreatedByUserFormater(column, colIndex) {
    return(
      <span>
        {column.firstName}  {column.lastName} 
      </span>
    );
  }


  function headerformatter(column, colIndex) {
    return (
      <div>
        {column.text}
        <img src="/media/VRekruitIcons/sort.svg" className="sort-icon c-p" width="30" height="30" />
      </div>
    );
  }


  function headerformatterAction(column, colIndex) {
    return (
      <div>
         Action
      </div>
    );
  }

  






  const get_sub_categories = (parentId) =>{
    if(parentId){
      const token = localStorage.getItem('x-access-token');
      fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/test/sub_categories?page=1&limit=1000&parentCategoryId=` + parentId, {        
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'x-access-token': token
        },
        // body: JSON.stringify(values)
      })
      .then((res) => res.json())
      .then((res) => {
        if(res.status==true){
          setSubCategories(res.data);
   
        }
       
      })
      .catch(() => {
      });
    }
    else {
      setSubCategories([]);
    }
  }

  function  questionformatter(column, colIndex) {
    return(
      <div className="overflow-text">
          {column}  
      </div>
    );
  }




 var columns = [{
    dataField: 'question',
    text: "Question",
    sort: true,
    formatter: questionformatter,
    headerFormatter: headerformatter
  }, {
    dataField: 'questionType',
    text: "Question Type",
    sort: true,
    headerFormatter: headerformatter
  },
  {
    dataField: 'questionCategory.categoryName',
    text: "Parent Category",
    sort: true,
    headerFormatter: headerformatter
  },
  {
    dataField: 'questionSubCategory.subCategoryName',
    text: "Sub Category",
    sort: true,
    headerFormatter: headerformatter
  },

  {
    dataField: 'difficultyLevel',
    text: "Difficulty Level",
    sort: true,
    headerFormatter: headerformatter
  },

  {
    dataField: 'createdByUser'  ,
    text: "Created By",
    sort: true,
    formatter: CreatedByUserFormater,
    headerFormatter: headerformatter
  },


  {
    dataField: "action",
    text: "Action",
    sort: true,
    formatter: actionFormatter,
    headerFormatter: headerformatterAction
  },
  ];




  const options = {
    paginationSize: 5,
    // custom: true,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    //  withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    
    onPageChange: (page, sizePerPage) => {
   
    },
    disablePageTitle: true,
    sizePerPageList: [ {
      text: '20', value: 20
    },{
    text: '50', value: 50
    },
    {
      text: 'All', value: QuestionsList.length
    }] // A numeric array is also available. the purpose of above example is custom the text    
  };


  
  return (
    <>
      <Helmet>
        <title>
          View Question
        </title>
      </Helmet>


      {/* subeader */}
      <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Test Management</h3></div>
          </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
      {/* subeader */}


      <Col className={classes.root} >   



    <Row className="bg-white  d-flex flex-grow-1 align-items-center  justify-content-start">
    <Col md={6} sm={6} offset= {4}  className="pr-3 pl-3 mt-3">      
      <div>
       <div className="   rounded">     
        <div>
        <h5 className="font-weight-bold f-w-600 pl-5" > Question Management &nbsp;&nbsp; {'>'} &nbsp;&nbsp; Question List  </h5>
        </div>
      </div>
      </div>
      </Col>
      <Col md={6} sm={6} offset= {4}  className="pr-3 pl-3 mt-3">
      <div>
       <div className="d-flex flex-grow-1 align-items-center justify-content-end rounded mt-1">
        <div className="text-muted d-block">
         

           <Button  type="button" className="btn btn-theme-green btn-lg mr-5 mt-2 mb-0"  >
              View Public Question Bank
           </Button>

           <Link to="/add-question" type="button" className="btn btn-theme-green btn-lg mr-5 mt-2 mb-0"  >
              Add Question
           </Link>

      </div>
      </div>
      </div>
      </Col>
      <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5"></Col>  
      </Row>


        <Col className="Content-custom-padding">
          <Row className="align-items-center mt-5">
            <Col sm={4} md={3} className="mb-sm-5">
              <Form.Control as="select" placeholder="Status"  value={categorySelected}  onChange={(e) => handleCategorySelect(e)} >
                <option value="">-Parent Category-</option>
                {parentCategories.map
                      ( x=><option value={x.id}>{x.categoryName}</option>)
                }
\              </Form.Control>
            </Col>

            <Col sm={4} md={3} className="mb-sm-5">
              <Form.Control as="select" placeholder="-Sub Category-"  value={subcategorySelected}  onChange={(e) => handleSubCategorySelect(e)} >
                <option value="">-Sub Category-</option>
                

                {subCategories.map
                      ( x=><option value={x.id}>{x.subCategoryName}</option>)
                }


              </Form.Control>
            </Col>

            <Col sm={4} md={3} className="mb-sm-5">
              <Form.Control as="select" placeholder="Status"  value={questionType}  onChange={(e) => handleQuestionTypeSelect(e)} >
                <option value="">-Question Type-</option>
                <option value="MCQ"> MCQ </option>
                <option value="Subjective"> Subjective </option>
              </Form.Control>
            </Col>

            <Col sm={4} md={3} className="mb-sm-5">
              <Form.Control as="select" placeholder="Status"  value={difficultyLevel}  onChange={(e) => handleDifficultyLevelSelect(e)} >
                <option value="">-Difficulty Level-</option>
                <option value="Easy"> Easy </option>
                <option value="Medium"> Medium </option>
                <option value="Hard"> Hard </option>
              </Form.Control>
            </Col>


            
            <Col sm={4} md={3} className="d-flex mb-sm-5">
            <Form.Label >
              </Form.Label>
              <div style={{"position":"relative", "width":"100%"}}>
                <div className="input-group">
                  <Form.Control type="text" value={searchTerm ? searchTerm : "" }   className="border-radius-12" placeholder="Search..." id="search-text" onInput={(e) => SetSearchTermData(e)}  style={{"paddingLeft":"35px",}} />
                     <div className="input-group-prepend">          
                    </div>
                </div>
                    <i className="fa fa-search"  style={{"position":"absolute","left":"7px","top": "50%","transform": "translate(7px, -50%)","borderRadius":"8px"}} ></i> 
                </div>
            </Col>


            <Col  sm={4} md={9} className="text-right mb-sm-5">
            <Button  type="button" className="btn btn-search ml-5 mb-sm-2" id="filter-btn" onClick={get_Question_List}  style={{"borderRadius":"8px"}} >
                 Apply
              </Button>
           
            <Button  type="button" className="btn btn-reset ml-5 pl-3 pr-3 mb-sm-2" id="reset-filter-btn"  onClick={resetFilter}  style={{"borderRadius":"8px"}} >
                  Reset
              </Button>
            </Col>

          </Row>
     

          <div style={{"minHeight": "50vh"}}>   
            {QuestionsList  && QuestionsList.length > 20 && (
              <BootstrapTable className="bg-white" keyField='id' data={QuestionsList} columns={columns} options={options} pagination={paginationFactory(options)} rowEvents={actionControls} />
            )}
            {QuestionsList  && QuestionsList.length <= 20 &&
            (
              <BootstrapTable className="bg-white" keyField='id' data={QuestionsList} columns={columns} options={options}  rowEvents={actionControls} />
            )
            }
          </div>
        </Col>
      </Col>


    </>
  );
}

export default ViewQuestion