/* eslint-disable no-restricted-imports */
/* jshint asi:true */

import React, { useState,useEffect } from "react";
import { Link,useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {  injectIntl } from "react-intl";
import * as auth from "../../modules/Auth/_redux/authRedux"
import Helmet from "react-helmet"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import alertService from "../../_services/alertservice";
import { logout } from "../../../actions/auth";
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Chart from 'react-apexcharts'
import Rating from '@material-ui/lab/Rating';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Pagination from '@material-ui/lab/Pagination';
import ReactPlayer from "react-player";






const  ViewUser = ({BacktoList,HideDetails,interviewId}) => {
 /* const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [renderPage, setRenderPage]= useState(false); 
 // const [selectedRole,SetSelectedRole]= useState("");*/
  const history = useHistory();
  const[series, setSeries] = useState([{
    name: 'Actual Score',
    data: [1, 1, 1],
  }, 
  {
    name: 'Expected Score',
    data: [5, 5, 5],
  }]);
  const[options, setCobChat] = useState({
    chart: {
      height: 350,
      type: 'radar',
      dropShadow: {
        enabled: true,
        blur: 1,
        left: 1,
        top: 1
      }
    },
    title: {
      text: ''
    },
    stroke: {
      width: 2
    },
    fill: {
      opacity: 0.1
    },
    markers: {
      size: 0
    },
    xaxis: {
      categories: ['2011', '2012', '2013', '2014', '2015', '2016']
    }
  }


);
  const [roleName, setRoleName] = React.useState('');
  const [selectedRoleId, setInputValue] = React.useState('');
  const[interviewDetails,setInterviewDetails]=useState();
  const [selectedVideo,setSelectedVideo] = React.useState('')
  const [scoreGotExist,setScoreGotExist] = React.useState(false);
 

  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  }));
  
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [candidateStatus, setStatus] = React.useState('');
    const [candidateStatusDisplay, setStatusDisplay] = React.useState('');
    const statusList= [
    {
      value :'rejected',
      title : 'Rejected'
    },
    {
      value :'on_hold',
      title : 'On Hold'
    },
    {
      value :'selected',
      title : 'Selected'
    },
    {
      value :'next_round',
      title : 'Next Round'
    }   
    ]
  
    const handleChange = panel => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
  
  
  useEffect(() => {
   let mounted = true;
   getInterviewDetails(); 
   return () => mounted = false;
  }, [])

  const showFiltersMethod = () => {
    document.body.classList.add('aside-enabled');
    document.body.classList.add('aside-fixed');
    document.getElementById('kt_aside').classList.add('d-flex');
    document.getElementById('kt_aside').classList.remove('d-none');
    document.getElementById('kt_header').classList.remove('d-none');
    document.getElementById('content-container').classList.add('container');
    document.getElementById('content-container').classList.remove('container-fluid');
   
}

const BacktoList1 = () => {
  HideDetails(false)
  BacktoList(true)
}
const logoutMethod = () => {
  dispatch(logout());
  history.push('/auth/signin');
};

const[currentQuestion,setCurrentQuestion] = React.useState(1)

let {id} = useParams();
  const getInterviewDetails = () =>{
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/candidate_interview_reviews?id=`+ interviewId, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
    .then((res) => res.json())
    .then((res) => {
      if(res.status===true){
        setInterviewDetails(res.data)
        var categories =[]
        var scoreGot = []
        var scoreGotFinal = []
        var fitmentOrder = []
        var expectedScore = []
        if(res.data.fitmentsList) {
          res.data.fitmentsList.map((fitment,f) => {
            categories.push(fitment.parameterName)
            expectedScore.push(fitment.expectedScore)
            fitmentOrder.push(fitment.id)
          })
        }
        setSelectedVideo(res.data.interview.interviewQuestions[0].interviewQuestionAnswer.answer)
        if(res.data.candidateStatus && res.data.candidateStatus !="pending"){
          let selectedStatus = statusList.filter(status => status.value == res.data.candidateStatus)
         // setStatus(res.data.candidateStatus)
          if(selectedStatus){
            handleSelectStatus(selectedStatus[0])
          //  setStatusDisplay(selectedStatus.title)
          }
        }
        if(res.data.interviewReview){
          let overallRating = []
          let pendingReviewers = []
          res.data.interviewReview.map((review,i) =>{
             let clearScore = review.fitmentScores.replace(/\\/g, '')    
             let scoreArray = JSON.parse(clearScore)
    
              overallRating.push(scoreArray)        
              
    
          })
        
        let value =0 
       
          if(overallRating && overallRating.length > 0){
            var res = 0
            var length = 0
            for(var r=0;r < overallRating.length; r++){
              for(var fr=0; fr < overallRating[r].length; fr++){
                for(var fo=0; fo < fitmentOrder.length; fo++){
                  if(r == 0 && fr == 0) {
                    scoreGot[fo] = 0
                  }
                if(fitmentOrder[fo] == overallRating[r][fr].fitmentId ){
                  scoreGot[fo] =  Number(scoreGot[fo] + Number(overallRating[r][fr].fitmentScore)  )
                }
                }
              }
            }
            for(var sg=0; sg < scoreGot.length; sg++){            
              categories[sg] = categories[sg] + ' ' + ((scoreGot[sg] * 100 )/ (overallRating.length * 5)).toFixed(1) +'%'
              scoreGot[sg] = (scoreGot[sg]/overallRating.length).toFixed(2)
              
            }
    
        

      

        
        if(scoreGot && scoreGot.length > 0){
          setScoreGotExist(true)
        setCobChat({
          chart: {
            height: 350,
            type: 'radar',
            dropShadow: {
              enabled: true,
              blur: 1,
              left: 1,
              top: 1
            }
          },          
          stroke: {
            width: 2
          },
          fill: {
            opacity: 0.3
          },
          
          plotOptions: {
            radar: {
             // size: undefined,
              offsetX: 0,
              offsetY: 0,
              polygons: {
                strokeColors: '#333',
                strokeWidth: 1,
                connectorColors: '#333',
                fill: {
                //  colors: undefined
                }
              }
            }
          },
         
          xaxis: {
            categories,           
           floating: true,         
            position: 'bottom',          
            labels: {
                show: true,    
                rotate: 45,
                style: {
                  colors:['#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333','#333']
                },
          rotateAlways: true,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: true,
          minHeight: 20,
          maxHeight: 30,           
                formatter:(value)=>{
                  var len = value.length
                  var longLabels = value.split(" ")
                  return len > 25 ?  longLabels : value  
                }          
            }, 
           
            
        },        
          yaxis: {
            show: true,
            showAlways: false,
            min:0,
            max:5,
            tickAmount: 5,            
          labels: {            
            style: {
                colors: ['#ff0e00',"#ff0e00","#ff0e00","#ff0e00","#ff0e00","#ff0e00"],      
                cssClass: 'apexcharts-yaxis-label',          
            },
            offsetX: 5
         
        },
          },
          
          markers: {
            size: 3,
            hover: {
              size: 10
            }
          }
          
        })
        setSeries([{
          name: 'Score',
          data: scoreGot,
        }, 
    /*    {
          name: 'Expected Score',
          data: expectedScore,
        }  */ ])
      }
      }
    }
  }
      
        else if(res.sessionExpired){
          logoutMethod();
          alertService.alert({ message :res.message, type : 'error'  });
        }

      
      else{
      }
    })
    .catch(() => {
 
    });
    }

    const dispatch = useDispatch();
  

  
  const RatingDisplay= (props) => {
    let fitmentList =  interviewDetails.fitmentsList
    var fitmentParameter = fitmentList.filter(fl => fl.id == props.score.fitmentId)

    return (
      <>
    <p className="w-50">{  fitmentParameter[0].parameterName }</p>
    <Rating
      className="w-50"
      name={`simple-controlled-`}
      value={props.showRating ? props.score.fitmentScore : 0}
      precision={0.5} readOnly
     
  />
  </>
    )
  }
  const RatingFormatter = (scores,showRating) => {
    let clearScore  = scores.replace(/\\/g, '')
    let value =0 
    let ratingHtml = ''
    let scoreArray = []
    if(scores){
      scoreArray  = JSON.parse(clearScore)
    }

    let totalScore =  0
    scoreArray.map((score,i) => (
       
       totalScore += score.fitmentScore
    )) 

    return (    
      scoreArray.map((score,i) => (<>
       
        <div className="d-flex  justify-content-around ">
         
         <RatingDisplay score={score} showRating={showRating}/>
         </div>
         {(i==scoreArray.length-1 ? 
         <>
          <hr/>
          <div className="d-flex  justify-content-around">
         
         <p className="w-50"><b>Overall Rating</b></p>
         <Rating
           className="w-50"
           name={`simple-controlled-`}
           value={showRating ? totalScore/scoreArray.length : 0}
           precision={0.5} readOnly
          
       />
       </div>
       </>
         : "")

         }
         
       
        </>
      )) 
      
       
    )
  }

  const OverallRatingFormatter = () => {
    let overallRating = []
    if(interviewDetails.interviewReview){
      interviewDetails.interviewReview.map((review,i) =>{
         let clearScore = review.fitmentScores.replace(/\\/g, '')    
         let scoreArray = JSON.parse(clearScore)
        if(scoreArray && scoreArray.length > 0){
          overallRating.push(scoreArray)      
        }  

      })
    }
    let value =0 
   
      if(overallRating && overallRating.length > 0){
        var res = 0
        var length = 0
        for(var r=0;r < overallRating.length; r++){
         res += overallRating[r].map(score => score.fitmentScore).reduce((acc, score) => score + acc);
         length += overallRating[r].length
        }
     value = res/length


    }


    return (
     // <>{value > 0 ?
    <Rating
      name={`simple-controlled-`}
      value={value}
      precision={0.5} readOnly
     
  />
//  :
//  ''
 //     }
 //     </>       
    )
  }

  const handleSelectStatus = (value) => {
    console.log(value)
    if(value){
      setStatus(value.value)   
      setStatusDisplay(value)   

      
    }  
  }
  const UpdateResult = () => {
    const token = localStorage.getItem('x-access-token');
    if(candidateStatus){
     var formData = {candidateStatus}
      fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/update_review/${interviewId}`, {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          'x-access-token': token
        },
        body: JSON.stringify(formData)
      })
        .then((res) => res.json())
        .then((res) => {
          if(res.sessionExpired){
            alertService.alert({ message :res.message, type : 'error'  });
            logoutMethod();
          }
          else if (res.status == true) {
              alertService.alert({ message: res.message, type: 'info' });
        

          }
          else {
            if (res.errors) {
              var errmsg = "";
              res.errors.forEach(function (value, index, array) {
                errmsg = errmsg + "(" + (index + 1) + ") " + value.msg + "\n\r";
      
              });
              alertService.alert({ message: errmsg, type: 'error' });
            }
            else {
              alertService.alert({ message: res.message, type: 'error' });
      
            }
          }
        })
    }else{
      alertService.alert({ message: "Please select result status", type: 'error' });
    }
  }

  const handleChangeQuestion = (event, value) => {
    setCurrentQuestion(value);
  };

  return (
    <>
       <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid" style={{height:'auto'}}>
         <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
             <div className="container-fluid d-flex align-items-center  justify-content-around flex-wrap mr-1">
             {interviewDetails ? <> 
             <div className="flex-fill">
             <Row  >
    <Col sm={12} xs={12} md={4} className=" text-center " >
    <img src={interviewDetails.candidateImage ?  interviewDetails.candidateImage : interviewDetails.interviewUserDetail.userImage ? `${process.env.REACT_APP_API_BASE_URL}${interviewDetails.interviewUserDetail.userImage}` : "/media/users/default.jpg"} className="img-fluid aside-img"   style={{ 'borderRadius' : '50%', "width" : "100px", "height" : "100px"}} />

  
    </Col>
    <Col xs={12} sm={12} md={6} className=" text-center  cd-ev-us" >
      <h6 className=" d-flex text-left align-items-center justify-content-left pl-5"> 
      <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root icon-w30 css-10cscxr" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="PersonOutlineIcon"><path d="M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"></path></svg>
      <p className="d-flex m-0">{interviewDetails.interviewUserDetail.firstName +' ' + interviewDetails.interviewUserDetail.lastName}</p>
       </h6>
       <h6 className=" d-flex text-left align-items-center justify-content-left  pl-5"> 
       <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root icon-w30 css-10cscxr" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="MailOutlineIcon"><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"></path></svg>
      {interviewDetails.interviewUserDetail.email}  
       </h6>
      <h6 className=" d-flex text-left align-items-center justify-content-left pl-5"> 
      <>
      <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root icon-w30 css-10cscxr" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="LocalPhoneOutlinedIcon"><path d="M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79h1.51m9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75l1.2-1.19M7.5 3H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1-1.24 0-2.45-.2-3.57-.57-.1-.04-.21-.05-.31-.05-.26 0-.51.1-.71.29l-2.2 2.2c-2.83-1.45-5.15-3.76-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1z"></path></svg>
    <p className="d-flex m-0">  {interviewDetails ? interviewDetails.interviewUserDetail.dialCode + " ": ""}
      {interviewDetails ? interviewDetails.interviewUserDetail.phone + " ": ""}
      </p>
      </>
      </h6>      
    </Col>
   
  </Row> 
              </div>
             <div className="flex-fill">
            <p> <b>Overall Rating</b></p>
             <OverallRatingFormatter /> 
             <p> ({interviewDetails.interviewReview.length} of {interviewDetails.reviewersCount} Reviewers rated)</p>
             </div>
            
            
             {interviewDetails.canEdit ? 
             <div className="flex-fill pr-5 mr-5">
                <p><b>Result</b></p>
                <Autocomplete size="small" style={{"maxWidth" : "250px"}}
                  id="roles-titles"
                  options={statusList}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Status" margin="normal" variant="outlined" />
                  )}
                  value={candidateStatusDisplay}
                  getOptionLabel={
                    (options) => options.title}
                  onChange={(event, newValue) => {
                    handleSelectStatus(newValue);
                  }}
                />
                </div>
                 : ''
                }
 
         <div className="text-muted  ml-auto text-right  mt-1 ml-5" >
           <p><b>&nbsp;</b></p>
           <div className="d-flex">
           {interviewDetails.canEdit ? 
         <a className="btn btn-theme-green btn-lg mr-5 text-white"  onClick={UpdateResult}
            
            >
               Update

 
            </a>
             : ''
           }
            <a className="btn btn-theme btn-lg mr-5 text-white"  onClick={BacktoList1}
            
            >
               Back

 
            </a>
            </div>
            
       
       </div>
       </>
       : <p className="text-center mt-5 pt-5" style={{"fontSize":"30px","minHeight":"200px","display":"flex","justifyContent":"center","alignItems":"center"}}> 
       <ele id="no-data">  
          <i className="fas fa-spinner fa-pulse fa-2x"></i>
       </ele> 
     </p>}
       </div>
      
     
       </div>
       </div>
       <div className="clearfix my-5"></div>
       <div className="clearfix my-5"></div>
       <div className="clearfix my-5"></div>
       {interviewDetails ? 
      <div className={ classes.root} style={{'marginTop': '5.25em'}}>
      <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={`${classes.heading}  font-weight-bold`}>Interview Response</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
        <Row className="w-100">
            <Col md={12} className="mx-auto">
      <Pagination count={interviewDetails.interview.interviewQuestions.length} 
        page={currentQuestion} 
        onChange={handleChangeQuestion}
        className="d-flex justify-content-center" />
        </Col>
        
        <br />

        <br />
        <br />
       
          <Col md={6}>
            <p>{interviewDetails.interview.interviewQuestions[currentQuestion-1].interviewQuestionDetail.question}</p></Col>
          <Col md={6}>
         <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4  pl-5   mt-3 mb-3 mr-auto">
                    <ReactPlayer url={ interviewDetails.interview.interviewQuestions[currentQuestion-1].interviewQuestionAnswer?.answer} width="98%" height="540px" controls={true} />
                  </div> 
          </Col>
          </Row>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={`${classes.heading}  font-weight-bold`}>Candidate Review</Typography>
         
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <> 
          <Row className="w-100 " style={{display: "flex",  flexWrap: "wrap"}}>
           {interviewDetails.interviewReview.map((reviewDetail,i)=>(
             <Col md={4} className="mt-5 col">
               <div className={`MuiPaper-root MuiPaper-elevation1 h-100 ${classes.card}`}>
             <div
             style={{'background':'#ccc',borderRadius:'5px'}}               
              
               className="text-center p-5"
               
             ><h5>{'Review By '+ reviewDetail.firstName +' '+ reviewDetail.lastName} </h5></div>
             
             <div className="text-center p-5">
             {RatingFormatter(reviewDetail.fitmentScores,1) }
             <hr />
             <p className="text-left"><b>Remark:</b> {reviewDetail.remarks } </p> 
     
            
             </div>
            
             
           </div>
           </Col>
          )
          )} 
          {interviewDetails.pendingReviewers && interviewDetails.pendingReviewers.length > 0 && interviewDetails.pendingReviewers.map((pendingReviewer,Ri)=>(
             <Col md={4} className="mt-5 col">
                <div className={`MuiPaper-root MuiPaper-elevation1 h-100 ${classes.card}`}>
             <div
             style={{'background':'#ccc',borderRadius:'5px'}}
               
              
               title={'Review By '+ pendingReviewer.firstName +' '+ pendingReviewer.lastName} 
               className="text-center p-5"
               
               ><h5>{'Review By '+ pendingReviewer.firstName +' '+ pendingReviewer.lastName} </h5></div>
             
             <div className="text-center p-5 ">
               <div className="pending-review">
             {RatingFormatter(interviewDetails.interviewReview[0].fitmentScores, 0) }
             </div>
             <hr />
             
             <h4>Review Pending</h4>
            
     
            
             </div>
            
             
           </div>
           </Col>
          )
          )} 
          </Row>
         </>
        </ExpansionPanelDetails>
      </ExpansionPanel>
          {scoreGotExist ?
      <ExpansionPanel  expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className={`${classes.heading}  font-weight-bold`}>Candidate Cob Web Chart</Typography>
          
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Row className="w-100">
            <Col md={12}  className="mx-auto">
              <p className="text-center" style={{"marginBottom" : "-60px"}}><b>Scale of 1-5, 5 being the highest</b></p>
        <Chart options={options} series={series} type="radar" height={650}    className="mx-auto"/>
        </Col>
        </Row>
    
        </ExpansionPanelDetails>
      </ExpansionPanel>
      :''
          }
      
    </div>
    :
    ''
        }

      </>
      
  );
          
}

export default injectIntl(connect(null, auth.actions)(ViewUser));
