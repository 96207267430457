/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormFile from 'react-bootstrap/FormFile'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Table from 'react-bootstrap/Table'
import { divide } from 'lodash';
import Helmet from 'react-helmet'
import { Link, useHistory } from "react-router-dom";
import { Redirect } from 'react-router';
import alertService from '../../_services/alertservice';
import { Button } from 'react-bootstrap';
import { CALL_HISTORY_METHOD } from 'react-router-redux';
import Modal from 'react-bootstrap/Modal'
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { logout } from '../../../actions/auth';
import { useDispatch } from 'react-redux';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

// const useStyles = makeStyles(theme => ({
//   root: {
//     backgroundColor: theme.palette.background.paper,
//   },
// }));


{/* <tab style css statrt> */}
function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}
TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

{/* <tab style css statrt> */}

const initialValues = {
  status: "open",
  page: 1,
  limit: 10,
  assignedToMe: false,
  createdByMe: "0",
  orderBy: "Id",
  orderType: "ASC"
}

function ExpiredInterview() {

  const [QuestionsList, setQuestionsList] = useState([]);
  const [staticData, setStaticData] = useState(10);
  const [parentCategories, setParentCategories] = useState([]);
  const [searchTerm, SetSearchTerm] = useState("");
  const [createdbyme, SetCreatedbyme] = useState(false);
  const [assignedbyme, SetAssignedbyme] = useState(false);
  const [status, SetStatus] = useState("");
  const [selectedId, setSelectedOption] = useState(true);
  const [subCategory, setSubCategory] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [InterviewFilter, SetInterviewStatus] = useState("");
  const [resultFilter, SetResultStatus] = useState("");
  const [difficultyLevel, setDifficultyLevel] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [buttonState,SetButtonState] = useState({
    "previewBtnState": true,
    "addQuestionButtonState": true,
  })
  const [rangeValues, SetRangeValues ]= useState({
    "minRange": 10,
    "maxRange": 70, 
  })



  const[bankType,SetBankType]= useState("private");
  const handleOptionChange = (e) =>  {
      var banktypeval = e;
      SetBankType(banktypeval);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }
   
  const [value, setValue] = React.useState([10, 70]);

  const handleChange = (event, newValue) => {
    
    setValue(newValue);
    SetRangeValues({
      ...rangeValues,
       minRange: newValue[0],
       maxRange: newValue[1],
    })
  };


  function valuetext(value) {
    return `${value}`;
  }
  

  const resetFilter = () => {
    SetInterviewStatus("");
    SetResultStatus("");
    SetSearchTerm("");
    get_List_without_filters();
  }

  // filter option 
  const SetSearchTermData = (x) => {
    let data = x.target.value;
    if (data) {
      SetSearchTerm(x.target.value);
    }
    else {
      SetSearchTerm("");
    }
  }

  function handleSelectedInterviewStatus(event) {
    if (event.target.value) {
      SetInterviewStatus(event.target.value);
    }
    else {
      SetInterviewStatus("");
    }   
  }

  function handleSelectedResultStatus(event) {
    if (event.target.value) {
      SetResultStatus(event.target.value);
    }
    else {
      SetResultStatus("");
    }

  }


  function handleDifficultyLevelSelect(event) {
    if (event.target.value) {
      setDifficultyLevel(event.target.value);
    }
    else {
      setDifficultyLevel("");

    }

  }


  function handleQuestionTypeSelect(event) {
    if (event.target.value) {
      setQuestionType(event.target.value);
    }
    else {
      setQuestionType("");

    }

  }

  const SetAssignedbymeData = (x) => {
    SetAssignedbyme(x.target.checked);
  }

  const SetCreatedbymeData = (x) => {
    SetCreatedbyme(x.target.checked);
  }

  // filter option 

  const [loading,SetLoading]=useState(false);
  const [filter,SetFilter]=useState(false);

  var i = 1;
  const get_Interview_List = () => {
    const token = localStorage.getItem('x-access-token');
    SetLoading(true);
    SetFilter(true);
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/interview/review_list?page=1&limit=1000&listType=expired`;
    //  history.push("/searchTerm");
     if(resultFilter){
      url = url + "&candidateStatus=" + resultFilter;
    }

    if (InterviewFilter) {
      url = url + "&interviewStatus=" + InterviewFilter;
    }

    if (searchTerm) {
      url = url + "&searchBy=" + searchTerm;
    }

    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
          SetLoading(false);
        }
  
        else  if (res.status == true) {
          setQuestionsList(res.data);
          setStaticData(staticData + 10);
          SetLoading(false);
        }
        else {
          SetLoading(false);
        }
      })
      .catch(() => {
        SetLoading(false);
      });
  }





  const get_List_without_filters = () => {
      
    const token = localStorage.getItem('x-access-token');
    SetLoading(true);
    SetFilter(false);
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/interview/review_list?page=1&limit=1000&listType=expired`;
    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {

        if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
          SetLoading(false);

        }
  
  
        else  if (res.status == true) {
          setQuestionsList(res.data);
          setStaticData(staticData + 10);
          SetLoading(false);

        }
        else {
          SetLoading(false);

        }
      })
      .catch(() => {
        SetLoading(false);

      });
  }


 


  useEffect(() => {
    let mounted = true;
    get_Interview_List();
    return () => mounted = false;
  }, [])

  const classes = useStyles();
  const theme = useTheme();



  const[ questionID,SetQuestionId] = useState("");
  const [QuestionData,setQuestionData]= useState({})

  const actionControls = {
    onClick: (e, row, rowIndex) => {
      if (row) {
        let id = row.id;
        if (e.target.classList.contains("view-option")) {
        history.push("/exp-interview-detail/view/" + id)
         
        }
        else if(e.target.classList.contains("delete-option")) {
           SetDelId(row.id);
           SetModalShow3(true);
           SetRowIndex(rowIndex);
        }
      }
    }
  };

  const[rowindexvalue,SetRowIndex] = useState();

  const deletethisques = (delId) => {
    const newtestQuestions = [...testQuestions ];
    newtestQuestions.splice(rowindexvalue,1);
    setTestQuestions(newtestQuestions)
    SetModalShow3(false);
  }


  function actionFormatter(column, colIndex) {
    return (
      <div className="text-left d-flex">
        <img src="/media/VRekruitIcons/eyeorview.svg" className="view-option c-p view-eye-ico-1" />
      </div>
    );
  }


  function actionFormatterTestQuestion(column, colIndex) {
    return (
      <div className="text-left d-flex">
        <img src="/media/VRekruitIcons/eyeorview.svg" className="view-option c-p view-eye-ico-1" />

        <img src="/media/VRekruitIcons/delete.svg" className="delete-option c-p view-eye-ico ml-5" />

      </div>
    );
  }


  function CandidateNameFormater(column, colIndex) {
    return (
      <span>
        {column.firstName}  {column.lastName}
      </span>
    );
  }

  function CandidateNameFormaterTest(columnsTestQustionData, colIndex) {
    return (
      <span>
        {columnsTestQustionData.firstName}  {columnsTestQustionData.lastName}
      </span>
    );
  }


  function headerformatter(column, colIndex) {
    return (
      <div>
        {column.text}
        <img src="/media/VRekruitIcons/sort.svg" className="sort-icon c-p" width="30" height="30" />
      </div>
    );
  }




  function headerformatterAction(column, colIndex) {
    return (
      <div>
        Action
      </div>
    );
  }



  const get_sub_categories = (parentId) => {
    if (parentId) {
      const token = localStorage.getItem('x-access-token');
      fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/sub_categories?page=1&limit=1000&parentCategoryId=` + parentId, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'x-access-token': token
        },
        // body: JSON.stringify(values)
      })
        .then((res) => res.json())
        .then((res) => {

          if(res.sessionExpired){
            alertService.alert({ message :res.message, type : 'error'  });
            logoutMethod();
          }
    
          else  if (res.status == true) {
            setSubCategories(res.data);

          }

        })
        .catch(() => {
        });
    }
    else {
      setSubCategories([]);
    }
  }
  const history = useHistory();



  const dispatch = useDispatch();
  const logoutMethod = () => {
    dispatch(logout());
    history.push('/auth/signin');
};


  const Create_Test = (e) => {

    e.preventDefault();

    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/create`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(formData)
    })
      .then((res) => res.json())
      .then((res) => {
        if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
  
  
        else if (res.status == true) {

          alertService.alert({ message: res.message, type: 'info' });
          history.push('/interview/manage-interview');

        }
        else {
          if (res.errors) {
            var errmsg = "";
            res.errors.forEach(function (value, index, array) {
              errmsg = errmsg + "(" + (index + 1) + ") " + value.msg + "\n\r";

            });
            alertService.alert({ message: errmsg, type: 'error' });

          }

          else {
            alertService.alert({ message: res.message, type: 'error' });
          }
        }

      })
      .catch(() => {

      });
  }


  const save_share_test = (e) => {

    e.preventDefault();
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/create`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(formData)
    })
      .then((res) => res.json())
      .then((res) => {
        if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
  
  
        else if (res.status == true) {
          
          alertService.alert({ message: res.message, type: 'info' });
          history.push('/interview/share-interview/'+ res.data.id);

        }
        else {
          if (res.errors) {
            var errmsg = "";
            res.errors.forEach(function (value, index, array) {
              errmsg = errmsg + "(" + (index + 1) + ") " + value.msg + "\n\r";

            });
            alertService.alert({ message: errmsg, type: 'error' });

          }

          else {
            alertService.alert({ message: res.message, type: 'error' });
          }
        }

      })
      .catch(() => {

      });
  }





  const [isSelected, SelectedQuestion] = useState(false);
  
  
  const [testQuestions, setTestQuestions] = useState([]);

  const [formData, SetFormData] = useState(
    {
      interviewTitle:"",
      questions: [],
    }
  )

  const setSelectedValue = (value) => {
    if (formData.questions.find(x => x.questionId == value)) {
      formData.questions.splice(formData.questions.findIndex(x => x.questionId == value), 1)
    } else {
      formData.questions.push({ questionId: value });
    }


    SetFormData({
      ...formData,
      questions: formData.questions
    })
    
    if(formData.questions.length > 0) {
      SetButtonState({
        ...buttonState,
        addQuestionButtonState: false
      })
    }
    else {
      SetButtonState({
        ...buttonState,
        addQuestionButtonState: true
      })
    }
  }

  const settestName = (event) => {
    SetFormData({
      ...formData,
      interviewTitle: event.target.value
    })
  }

  const [totalTestMarks, SetTotalTestMarks] = useState();  
  const addQuestionsToTest = () => {
    formData.questions.forEach(que => {
      var element = QuestionsList.splice(QuestionsList.findIndex(x => x.id == que.questionId), 1);
      setQuestionsList(QuestionsList.map(x=> x)) ;     
      testQuestions.push(element[0]);
    });
    setTestQuestions(testQuestions);
    alertService.alert({ message : "Questions Added Successfully", type : 'success'  });
  }

  const roundsList =[
    {
      value : 'One',
      title: '1st'
    },
    {
      value : 'Two',
      title: '2nd'
    },
    {
      value : 'Three',
      title: '3rd'
    },
    {
      value : 'Four',
      title: '4th'
    },
    {
      value : 'Five',
      title: '5th'
    }
    ]



  function selectallFormatter(column, colIndex) {
    return (
      <div className="text-left">
        <Form.Check inline label="" className="ml-2 mt-2" />
      </div>
    );
  }

  function  CandidateNameFormater(column, colIndex) {
    return(
      <span>
        {column.firstName}  {column.lastName} 
      </span>
    );
  }

  function interviewRoundFormatter(column){
    const round = roundsList.filter((roundData,i) => { return  roundData.value == column})
    return (
     <> {round && round.length ? round[0].title : ''}
     </>
    );

  }

  function ResultFormater(column, colIndex) {

    if(column === "next_round") {
      column = "Next Round"
    }

    else if (column === "on_hold"){
      column = "On Hold"
    }
     
    return (

      <span className="text-capitalize">  
        {column}        
     </span>
    );
  }

  var columns = [
    {
      dataField: 'requisition.requisitionId',
      text: "REQUISITION ID",
      sort: true,
      headerFormatter: headerformatter
  },


    {
      dataField: 'requisition.position',
      text: "JOB TITLE",
      sort: true,
      headerFormatter: headerformatter
  },

    {
      dataField: 'interviewUserDetail',
      text: "CANDIDATE NAME",
      sort: true,
      formatter: CandidateNameFormater,
      headerFormatter: headerformatter
    },
   
    {
      dataField: 'interviewRound',
      text: "INTERVIEW ROUND",
      sort: true,
      formatter: interviewRoundFormatter,
      headerFormatter: headerformatter
    },
    {
        dataField: 'createdAt',
        text: "INVITATION DATE",
        sort: true,
        headerFormatter: headerformatter,
        formatter: (cell) => {
            let dateObj = cell;
            if (typeof cell !== 'object') {
              dateObj = new Date(cell);
            }
            return `${('0' + dateObj.getUTCDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${dateObj.getUTCFullYear()}`;
          }   
    },
    {
        dataField: 'interviewStatus',
        text: "STATUS",
        sort: true,
        headerFormatter: headerformatter
    },   
    {
        dataField: 'candidateStatus',
        text: "RESULT",
        sort: true,
        formatter: ResultFormater,
        headerFormatter: headerformatter
    },
    // {
    //   dataField: "action",
    //   text: "Action",
    //   sort: true,
    //   formatter: actionFormatter,
    //   headerFormatter: headerformatterAction
    // },
  ];






  const options = {
    paginationSize: 5,
    // custom: true,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    //  withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    
    onPageChange: (page, sizePerPage) => {
   
    },
    disablePageTitle: true,
    sizePerPageList: [ {
      text: '20', value: 20
    },{
    text: '50', value: 50
    },
    {
      text: 'All', 
    }] // A numeric array is also available. the purpose of above example is custom the text    
  };

  const [showpage, setShowPage] = useState({
    showCreateTest: true,
    showPreviewTestPage: false,
  })



  const showCreatePage = () => {
    setShowPage({
      ...showpage,
      showCreateTest: true,
      showPreviewTestPage: false
    })
  }

  const showPreviewPage = () => {
    setShowPage({
      ...showpage,
      showCreateTest: false,
      showPreviewTestPage: true
    })
  }



  const [modalShow, setModalShow] = React.useState(false);


  const [modalShow3, SetModalShow3] = React.useState(false);
  const[delId, SetDelId]= useState(); 

  function DeleteConfirmationModal(props) {
    return (
    <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
    
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            <p className="text-center">
            <img alt="logo" className="brand-dim" src="/media/logos/UPDATED_VRekruit _Logo.png" />
              </p>
            <p className="text-dark text-center pt-3"> Are you sure want to delete this question?</p>
          </Modal.Title>
        </Modal.Body>
        <Modal.Title className="d-flex justify-content-center align-items-center pb-5" >
        <p className="text-center">

        <Button className="btn btn-theme text-white btn-lg mr-5  pl-5 pr-5 pt-3 pb-3" style={{"minWidth": "100px"}} onClick={() => deletethisques(delId)}> Yes </Button>
       <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green" style={{"minWidth": "100px"}}  onClick={props.onHide}>No</Button> 
       </p>
        </Modal.Title>
      </Modal>
    );
  }


  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);
  const [open, setOpen] = React.useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('lg');


  function CustomizedDialogs() {  
    return (
      <div>

        <Dialog 
        onClose={handleClose} 
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        fullScreen={fullScreen}
        aria-labelledby="customized-dialog-title" 
        open={open}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          </DialogTitle>
          <DialogContent >
            <Typography gutterBottom>
            <Row className="pt-5" >
              <Form.Label className="fw-600" column sm="2">
                Question Text*
              </Form.Label>
              <Col sm="10">
                <Form.Group as={Col} controlId="formGridState" >
                  <Form.Control as="textarea" placeholder="" rows={3}
                    name="question" 
                    readOnly={true}
                  >
                       {QuestionData ? QuestionData.question: "" }

                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            </Typography>
            <Typography gutterBottom>
            <Row className="pt-5" >
              <Form.Label className="fw-600" column sm="2">
                Question Video*
              </Form.Label>
              <Col sm="10">
                <Form.Group as={Col} controlId="formGridState" >
                   <video className="video-container video-container-overlay modal-video-player" autoPlay="false" loop muted={false}
                   controls
                   src={QuestionData ? QuestionData.videoPath: "" }
                   poster={QuestionData ? QuestionData.videoQuestion ? '' : '/media/bg/audio.gif' : ''}
                   preload="metadata"
                   >
                  </video>
                </Form.Group>
              </Col>
            </Row>
            </Typography>
            <Typography gutterBottom>
            <Row className="pt-5" >
              <Form.Label className="fw-600" column sm="2">
                Keywords*
              </Form.Label>
              <Col sm="10">
                <Form.Group as={Col} controlId="formGridState" >
                  <Form.Control as="textarea" placeholder="" rows={3}
                    name="question" 
                    readOnly={true}
                  > 
                  {QuestionData ? QuestionData.keywords: "" }


                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            </Typography>
          </DialogContent>
       
        </Dialog>
      </div>
    );
  }


  if(localStorage.getItem("userTypeId") == "1")
  {
      
     return ""
  
  }
  else {


  return (
    <>
      <Helmet>
        <title>
          Create Interview
        </title>
      </Helmet>


      {/* subeader */}
      <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Review</h3></div>
          </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
      {/* subeader */}



      {/*Start  addQuestionSection */}
      { showpage.showCreateTest ? (
        <Col className={classes.root} >
          <Row className="bg-white  d-flex flex-grow-1 align-items-center  justify-content-start">
            <Col md={6} sm={6} offset={4} className="pr-3 pl-3 mt-3">
              <div>
                <div className="mt-3 rounded">
                  <div>
                    <h5 className="font-weight-bold f-w-600 pl-5" > Expired Interview &nbsp;&nbsp; {'>'} &nbsp;&nbsp; Interview List
                    </h5>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5"></Col>
          </Row>
          <Col className="Content-custom-padding mt-5">



            <Row className="align-items-center mt-0 space-around">
              {/* <Col sm={4} md={3} className="mb-2">
                <Form.Control as="select" placeholder="Status" value={InterviewFilter} onChange={(e) => handleSelectedInterviewStatus(e)} >
                  <option value=""> Status</option>
                  <option value="sent"> Sent</option>
                     <option value="submitted"> Submitted</option>   
                </Form.Control>
              </Col> */}

              {/* <Col sm={4} md={3} className="mb-2 pl-0 ml-0">
                <Form.Control as="select" placeholder="-Sub Category-" value={resultFilter} onChange={(e) => handleSelectedResultStatus(e)} >
                  <option value="">Result</option>
                    <option value="pending"> Pending</option>
                    <option value="selected"> Selected</option>
                    <option value="on_hold"> On Hold</option>
                    <option value="next_round">Next Round</option>
                    <option value="rejected"> Rejected</option>                  
                </Form.Control>
              </Col> */}
     

              <Col sm={6} md={4} className="d-flex mb-2">
                <div style={{ "position": "relative" }}>
                  <div className="input-group">
                    <Form.Control type="text" value={searchTerm ? searchTerm : ""} placeholder="Search..." id="search-text" onInput={(e) => SetSearchTermData(e)} style={{ "paddingLeft": "35px", }} />
                    <div className="input-group-prepend">
                    </div>
                  </div>
                  <i className="fa fa-search" style={{ "position": "absolute", "left": "7px", "top": "50%", "transform": "translate(7px, -50%)", "borderRadius": "8px" }} ></i>
                </div>
              </Col>



              <Col sm={12} md={8}  className="mb-2 text-right">
                <Button type="button" className="btn btn-search ml-5 mb-sm-2" id="filter-btn" onClick={get_Interview_List} style={{ "borderRadius": "8px" }} >
                  Apply
              </Button>

              <Button type="button" onClick={resetFilter} className="btn btn-reset ml-5   mb-sm-2 btn btn-primary" id="filter-btn"  style={{ "borderRadius": "8px" }} >
                  Reset              
              </Button>
              
              </Col>
            </Row>
            <div>
            {QuestionsList &&  QuestionsList.length > 20 &&  QuestionsList.length > 0  && (
              <BootstrapTable className="bg-white" keyField='id' data={QuestionsList} columns={columns} options={options} pagination={paginationFactory(options)} rowEvents={actionControls} />
            )}
           {QuestionsList &&  QuestionsList.length === 0  && (
             <>
              <BootstrapTable className="bg-white" keyField='id' data={QuestionsList} columns={columns} options={options}  rowEvents={actionControls} />
              {!loading ?
             <p className="text-center mt-5 pt-5" style={{"fontSize":"30px","minHeight":"200px","display":"flex","justifyContent":"center","alignItems":"center"}}> 
                 
                 {!filter ? 
                <small>
                    Please select the above filters {`&`} 
                    <br/>
                    click on apply button.
                </small>
                :""}


                {filter?
                <small>
                   No Records Found!
                </small>
                :""
                }
             </p>
             :""}
    

             {loading ?
            <p className="text-center mt-5 pt-5" style={{"fontSize":"30px","minHeight":"200px","display":"flex","justifyContent":"center","alignItems":"center"}}> 
              <ele id="no-data">  
                 <i className="fas fa-spinner fa-pulse fa-2x"></i>
              </ele> 
            </p>
             :""}

              </>
           )}
            {QuestionsList  && QuestionsList.length <= 20 && QuestionsList.length > 0 &&
            (
              <BootstrapTable className="bg-white" keyField='id' data={QuestionsList} columns={columns} options={options}  rowEvents={actionControls} />
            )
            }
          </div>
          </Col>
        </Col>
      ) : ""}
      {/*End addQuestionSection */}



      <DeleteConfirmationModal show={modalShow3} onHide={() => SetModalShow3(false)} />


    </>
  );
          }
}

export default ExpiredInterview