/* eslint-disable no-restricted-imports */
/* jshint asi:true */

import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import Helmet from "react-helmet"
import { makeStyles } from '@material-ui/core/styles';
import LoginAside from './LoginAside'
import  alertService  from '../../../_services/alertservice';
import Footer from "./Footer";
import Header from "./Header";
// alertService.alert({ message :"Hi", type : 'error'  });





const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    flexBasis: 200,
  },
}));



const initialValues = {
  email: "",
};

function ForgotPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Please Enter your email")
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    validateOnChange:false,
    validateOnBlur:false,    
    onSubmit: (values, { setStatus, setSubmitting }) => {
      forgetpasswordReq(values, setStatus, setSubmitting)
    },
  });


  
  const forgetpasswordReq = (values,setStatus, setSubmitting) =>{
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/auth/forgetpassword`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
          // 'x-access-token': token
      },
      body: JSON.stringify(values)
    })
    .then((res) => res.json())
    .then((res) => {
      if(res.status==true){
      disableLoading();
      // alert(res.message);
      alertService.alert({ message :res.message, type : 'info'  });

      setSubmitting(false);

      }
      else {
        // alert(res.message);
        alertService.alert({ message :res.message, type : 'info'  });
        setSubmitting(false);
        disableLoading();
      }

    })
    .catch(() => {
      disableLoading();
      setSubmitting(false);
      setStatus();
    });
    }

    const enableLoading = () => {
      setLoading(true);
    };
  
    const disableLoading = () => {
      setLoading(false);
    };


  return (
    <>
        <Helmet>
            <title> VRekruit | Forget Password </title>
        </Helmet>

      <Header />

      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (

        <div className="bg-white" style={{"min-height":"100vh"}}>
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white "
          id="kt_login">
        <div className="flex-row-fluid d-flex flex-column position-relative p-3 overflow-hidden center-flex">

            <div className="" id="kt_login_signin_form" >
              <div className="mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 mb-5">
            {/*  <img className="img-responsive mb-5" width="200" src="/media/logos/UPDATED_VRekruit _Logo.png"  /> */}
              <h3 className="text-dark font-weight-bold mt-5 mb-5 mt-50 text-dark" >Forgot Password?</h3>
              <p className="font-weight-bold text-grey"> Enter your email address to reset your password</p>
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp mt-50"
            >
              {formik.status && (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                </div>
              )}
              <div className="form-group fv-plugins-icon-container">
                  <label className="form-label auth-lable pl-2" for="formGridEmail">Email Address</label>
                <input
                  type="email"
                  
                  // onChange={(e) => onChange(e)}
                  className={`form-control form-control-solid h-auto py-5 px-6`}
                  name="email"
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                ) : null}
              </div>
              <div className="form-group d-flex flex-wrap justify-content-space-between mt-50 auth-btns">
                <button 
                  style={{"width":"40%"}}
                  id="kt_login_forgot_submit"
                  type="submit"
                  className="btn btn-theme-green btn-primary font-weight-bold  my-3 auth-btns-text"
                  disabled={formik.isSubmitting}
                >
                  Submit
                </button>
                 
                <Link to="/auth/signin" style={{"width":"40%"}}>

                  <button type="button" id="kt_login_forgot_cancel"
                    style={{"width":"100%"}}
                 
                  className="btn text-white btn-light-primary btn-theme font-weight-bold  my-3 mx-4 auth-btns auth-btns-text">
                       Cancel
                  </button>
                  </Link>

              </div>
            </form>
          </div>
          
         </div>

       {/*side image::Aside*/}
          <LoginAside/>
        {/* end:: Side Image */}
      </div>
      </div>
      )}

    <Footer/>  
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
