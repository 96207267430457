/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React, { useState,useEffect } from "react";
import { useParams, useHistory } from 'react-router-dom';
import { Link, Redirect } from "react-router-dom";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormFile from 'react-bootstrap/FormFile'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import  alertService  from '../../_services/alertservice';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';






{/* <tab style css statrt> */}
function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}
TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

{/* <tab style css statrt> */}



  function AddQuestion(props) {
    const formintialData = {
      question: "",
      categoryId:"",
      subCategoryId:"",
      imagePath:"",
      imageName:"",
      questionType:"",
      difficultyLevel:"",
      marks:"",
    };
    

  const [formData, SetFormData] = useState(
    {
      question:"",
      marks:"",
      categoryId:"",
      subCategoryId:"",
      difficultyLevel:"",
      questionType:"",
      answers:[
       
      ],
    }
  )



  const handleFormInput = (e) => {
     if(e && e.target.name === 'answers') {   
      var index = parseInt(e.target.getAttribute('data-index'));
      var name = e.target.getAttribute('data-name');
       if(name === "rightAnswer"){
        formData.answers.forEach(element => {
           element.rightAnswer = false;
         });
         if(e.target.value=="on"){
          formData.answers[index][name] = true;
        }
        else{
          formData.answers[index][name] = false;
        }
       }
       else {
        formData.answers[index][name] = e.target.value;
       }
      SetFormData({...formData});
    } 
    else {
    SetFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }
  }


  const histroy = useHistory();

  const question_add = (e) =>{  


   e.preventDefault();

    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/test/add_question`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
          'x-access-token': token
      },
      body: JSON.stringify(formData)
    })
    .then((res) => res.json())
    .then((res) => {
      if(res.status==true){
    
      alertService.alert({ message :res.message, type : 'info'  });
      histroy.push('/view-question');

      }
      else  {
        if(res.errors){
          var errmsg ="";
          res.errors.forEach( function(value, index, array) 
          {
            errmsg = errmsg + "(" + (index + 1) + ") " +   value.msg +  "\n\r"  ; 
            
          });
           alertService.alert({ message :errmsg, type : 'error'  });

        }

        else {
           alertService.alert({ message :res.message, type : 'error'  });
        }
      }
    })
    .catch(() => {
    });
    }

    const [ parentCategories, setParentCategories ] = useState([]); 
    const [ subCategories, setSubCategories ] = useState([]);  
    const [ selectedParentCategoryId , setselectedParentCategoryId] = useState();
    const [ selectedSubCategoryId , setselectedSubCategoryId] = useState();
    const [selectedId,setSelectedOption] = useState();
    const [ fieldActive, setFieldActive] = useState(true);
    const [pdFileName, SetSeletedFileName] = useState("");

    const uploadFile = (x) => {
      SetSeletedFileName(x.target.files[0].name)
     }


const[isCategoryselected, setCategoryselected ] = useState(true)


    function handleSelectCategoryChange(value, i) {
      setSelectedParentCategory(value);
      if(value){
        setSelectedOption(value.id);
        // document.getElementById("subcategoryvalue").value= ""    
        var parentId = (value.id);
        get_sub_categories(value.id);

        SetFormData({
          ...formData,
          categoryId: value.id
        })
        setCategoryselected(false)
      }
      else {
        setCategoryselected(true)
      }
  }

  function handleSelectSubCategoryChange(value, i) {
    if(value){
     SetFormData({
        ...formData,
        subCategoryId: value.id
      })
    }
}


    const get_parent_categories = (setDefault) => {
      const token = localStorage.getItem('x-access-token');
      fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/test/categories?page=1&limit=10`, {        
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'x-access-token': token
        }
      })
      .then((res) => res.json())
      .then((res) => {
        if(res.status==true){
          setParentCategories(res.data);
          if (setDefault)  {
            setSelectedParentCategory(res.data[0]);
            setSubCategories([]);
          }
        }
        else{
        }
      })
      .catch(() => {
      });
      }


      const get_sub_categories = (parentId) =>{
        if(parentId){
          const token = localStorage.getItem('x-access-token');
          fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/test/sub_categories?page=1&limit=10&parentCategoryId=` + parentId, {        
            method: 'GET',
            headers: {
              'content-type': 'application/json',
              'x-access-token': token
            },
            // body: JSON.stringify(values)
          })
          .then((res) => res.json())
          .then((res) => {
            if(res.status==true){
              setSubCategories(res.data);
       
            }
            else{
              // alert(res.message);
            }
          })
          .catch(() => {
          });
        }
      }
    
      useEffect(() => {
        let mounted = true;
        get_parent_categories();
        return () => mounted = false;
      }, [])
    
    
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }


  function handleChangeIndex(index) {
    setValue(index);
  }
  
  const Submit_Form = () => {
     var btnSubmit = document.getElementById("submit-btn");
     if(btnSubmit){
      btnSubmit.click();
     }
  }


 const addMoreOption = () => {
   if(formData.answers.length > 4) {
     alertService.alert({ message :"Maximmun 5 Answers are Allowed", type : 'info'  });
   }
   else {
    SetFormData({
      ...formData,   
      answers: [...formData.answers,  { answer:"", imagePath: "", imageName: "", rightAnswer:false}],
    })
   }
 }

 const DeleteAnswer = (value) => {
  if(formData.answers.length > 2) {
   const newAnswers = [...formData.answers];
   newAnswers.splice(value, 1);
    SetFormData({
      ...formData,   
      answers: newAnswers,
    })
}
else {
  alertService.alert({ message :"Minimum 2 Answers are Required", type : 'warning'  });

}
  
 }

 const[ selectedParentCategory, setSelectedParentCategory] =useState("")

 const add_Category = (values) =>{
  var CatName = document.getElementById("category").value;
if(CatName){
    const reqData = {
       "categoryName": CatName
     }
   const token = localStorage.getItem('x-access-token');
 
   fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/test/add_category`, {
     method: 'POST',
     headers: {
       'content-type': 'application/json',
       'x-access-token': token
 
     },
     body: JSON.stringify(reqData)
   })
   .then((res) => res.json())
   .then((res) => {
     if(res.status==true){
      alertService.alert({ message : res.message, type : 'success'  });
     get_parent_categories(true);
     setCategoryselected(false);
     setModalShow(false);
     }
     else{
      alertService.alert({ message : res.message, type : 'error'  });
       setModalShow(false);          
     }
   })
   .catch(() => {
   });
}

else {
  alertService.alert({ message : "Please Enter Parent Category Name First", type : 'warning'  });
}
}

 const showAnswerTab = () => {
  handleChangeIndex(1);
 }  
 
 const [QuestionType,SetQuestionType] = useState("")
 const  handleShowAnswer = ( value, event) => {
    if(QuestionType === "MCQ" && value === "Subjective") {
      setModalShow3(true);
      // event.target.value==="Subjective";
    }
    else {
      SetQuestionType(value);  
      if(value === "MCQ" ){
        SetFormData({
          ...formData,  
           answers: [
              { answer:"", imagePath: "", imageName: "", rightAnswer:false},
              { answer:"", imagePath: "", imageName: "", rightAnswer:false},
            ],
            questionType: value
           })
      } else {
      SetFormData({
        ...formData,
        questionType: value 
      })}
    }       
 }


 const ChangeQuestionType= () => {
  SetQuestionType("Subjective");
  setModalShow3(false);
  SetFormData({
    ...formData,
    questionType: "Subjective", answers: []
  })
 }

 
 
 
 const [modalShow3, setModalShow3] = React.useState(false);
function DeleteConfirmationModal(props) {
   return (
      <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      >

      <Modal.Body>
        <Modal.Title id="contained-modal-title-vcenter text-center">
          <p className="text-center">
          <img alt="logo" className="brand-dim" src="/media/logos/UPDATED_VRekruit _Logo.png" />
            </p>
          <p className="text-dark text-center pt-3 pb-0 mb-0"> Are you sure want to change answer type?</p>
          <p className="text-dark text-center pt-0 mt-0"> Saved answer data will be lost if any. </p>

        </Modal.Title>
      </Modal.Body>
      <Modal.Title className="d-flex justify-content-center align-items-center pb-5" >
      <p className="text-center">
      <Button className="btn btn-theme text-white btn-lg mr-5  pl-5 pr-5 pt-3 pb-3" style={{"minWidth": "100px"}} onClick={ChangeQuestionType }> Yes </Button>
      <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green" style={{"minWidth": "100px"}}  onClick={props.onHide}>No</Button> 
      </p>
      </Modal.Title>
      </Modal>

   );
 }


 const [modalShow, setModalShow] = React.useState(false);
 function CategoryModal(props) {
  return (
       <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      >
      <Modal.Header closeButton className="d-flex justify-content-flex-end">
        <Modal.Title id="contained-modal-title-vcenter">
        <Button className="btn btn-theme text-white btn-lg mr-5 pl-4 pr-4 pt-3 pb-3"   onClick={add_Category}> Save </Button>
      <Button className="btn btn-theme-green btn-lg mr-5 pl-4 pr-4 pt-3 pb-3 bg-theme-green" onClick={props.onHide}>Close</Button>  </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Modal.Title id="contained-modal-title-vcenter text-center d-flex">

              <div className="form-group row mb-0 mt-3">
                <label for="formPlaintextEmail" className="form-label col-form-label col-sm-3">Parent Category</label>
                <div className="col-sm-9"><div className="form-group col">
                
                    <Form.Control size="lg" type="text" placeholder="" id="category" />
                    </div>
                  </div>
                  <label for="formPlaintextEmail" className="text-theme-green form-label col-form-label col-sm-2">
                  </label></div>
        </Modal.Title>
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
      </Modal>
 );
}


const [modalShow1, setModalShow1] = React.useState(false);
function SubCategoryCModal(props) {
  return (



<Modal
{...props}
size="lg"
aria-labelledby="contained-modal-title-vcenter"
centered
>
<Modal.Header closeButton className="d-flex justify-content-flex-end">
  <Modal.Title id="contained-modal-title-vcenter">
  <Button className="btn btn-theme text-white btn-lg mr-5 pl-4 pr-4 pt-3 pb-3"   onClick={add_SubCategory}> Save </Button>
<Button className="btn btn-theme-green btn-lg mr-5 pl-4 pr-4 pt-3 pb-3 bg-theme-green" onClick={props.onHide}>Close</Button>  </Modal.Title>
</Modal.Header>
<Modal.Body>
<Modal.Title id="contained-modal-title-vcenter text-center d-flex">

        <div className="form-group row mb-0 mt-3">
          <label for="formPlaintextEmail" className="form-label col-form-label col-sm-3">Sub Category</label>
          <div className="col-sm-9"><div className="form-group col">
          
              <Form.Control size="lg" type="text" placeholder="" id="subcategoryvalue" />
              </div>
            </div>
            <label for="formPlaintextEmail" className="text-theme-green form-label col-form-label col-sm-2">
            </label></div>
  </Modal.Title>
</Modal.Body>
<Modal.Footer>
</Modal.Footer>
</Modal>



  );
}

const add_SubCategory = () =>{
  var subCatName = document.getElementById("subcategoryvalue").value;
if(subCatName && selectedId){
   const reqData = {
       "subCategoryName": subCatName ,
       "parentCategoryId": selectedId,
     }
   const token = localStorage.getItem('x-access-token');      
   fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/test/add_sub_category`, {
     method: 'POST',
     headers: {
       'content-type': 'application/json',
       'x-access-token': token
 
     },
     body: JSON.stringify(reqData)
   })
   .then((res) => res.json())
   .then((res) => {
     if(res.status==true){
     alertService.alert({ message : res.message, type : 'success'  });
     get_sub_categories(selectedId);    
     setModalShow1(false)
     document.getElementById("subcategoryvalue").value= "";      
     }
     else{
       alertService.alert({ message : res.message, type : 'error'  });
       setModalShow1(false)
     }
   })
   .catch(() => {
 
   });
   

}
else {
 alertService.alert({ message : "Please Enter Sub Category Name First", type : 'warning'  });
}
}

  return (
<>

<Form onSubmit={ (e) => question_add(e)}>
    {/* subeader */}
    <div id="kt_subheader" className="subheader py-2 py-lg-4    subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Test Management</h3></div>
              </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
    {/* subeader */}
  <Col className="bg-white" >
  <Row className="bg-white pt-4 pl-4 pb-1 align-items-center">
    <Col md={6} sm={12} offset= {4}  className="pr-3 pl-3">      
      <div>
       <div className="d-flex flex-grow-1 align-items-center  justify-content-start   rounded">     
        <div>
        <h5 className="font-weight-bold f-w-600 pl-5" > Question Management &nbsp;&nbsp; {'>'} &nbsp;&nbsp; Add Question  </h5>
        </div>
      </div>
      </div>
      </Col>
      <Col md={6} sm={12} offset= {4}  className="pr-3 pl-3">
      <div>
       <div className="d-flex flex-grow-1 align-items-center justify-content-end rounded mt-1">
        <div className="text-muted d-block">
           <Button type="submit"  className="btn btn-theme-green btn-lg mr-5">
              Save
           </Button>
           <Link to="/view-question" type="button"  className="btn btn-theme btn-lg mr-5  text-white">
              Cancel
           </Link>
      </div>
      </div>
      </div>
      </Col>
      <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5"></Col>  
      </Row>

      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          >
          <Tab label="Question" />
          <Tab label="Answer"  disabled={QuestionType== "Subjective" || QuestionType== "" } />
        </Tabs>
      </AppBar>


      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
       <TabContainer dir={theme.direction}>
                <Row controlId="formPlaintextEmail">
                    <Form.Label column sm="2">
                      Parent Category*
                    </Form.Label>
                    <Col sm="7">
                    <Form.Group as={Col} controlId="formGridState">
                    {/* <Form.Control as="select"
                      // onChange={(e) => handleSelectCategoryChange(e), (e) => handleFormInput(e)}
                        onChange={(event, newValue) => {
                          handleSelectCategoryChange(newValue, event);
                        }}                        
                        name="categoryId"
                    >
                        <option >-Select Parent Category-</option>
                        {parentCategories.map
                    (
                        x=><option value={x.id}>{x.categoryName}</option>
                    )
                      } */}
                       
                    {/* </Form.Control> */}
                    <Autocomplete
                        name="categoryId"
                        size="small"
                        value={selectedParentCategory}
                        options={parentCategories}
                        onChange={(event, newValue) => {
                          handleSelectCategoryChange(newValue, event);
                        }}
                        defaultValue={parentCategories[0]}
                        getOptionLabel={(options) => options.categoryName}
                        renderInput={(params) => <TextField {...params} label="Select Parent Category" variant="outlined" />} />

                    </Form.Group>
                    </Col>
                    <Link to="#" onClick={() => setModalShow(true)}  className="d-c">
                     <Form.Label column sm="2" className="text-theme-green">
                     <span className="c-p">   
                     <img src="/media/VRekruitIcons/addmore.svg" className="c-p" width="24"></img>
                      
                     </span>
                    </Form.Label> 
                    </Link>
                </Row>

                <Row className="{`$(fieldActive) ? 'show' : 'hidden'`}"  disabled={true}>
                    <Form.Label column sm="2">
                    Sub Category
                    </Form.Label>
                    <Col sm="7">
                    <Form.Group as={Col} controlId="formGridState">
                    {/* <Form.Control as="select" defaultValue="Choose..."
                    name="subCategoryId"
                    onChange={ (e) => handleFormInput(e)}>
                    <option value="">-Select Sub Category-</option>
                        {subCategories.map
                          (
                              x=><option value={x.id}>{x.subCategoryName}</option>
                          )
                        }
                    </Form.Control> */}
                        <Autocomplete
                        className={`c-p ${isCategoryselected ? "p-none no-drop" : ""}`}
                        size="small"
                        name="subCategoryId"
                        options={subCategories}
                        onChange={(event, newValue) => {
                          handleSelectSubCategoryChange(newValue, event);
                        }}
                        getOptionLabel={(options) => options.subCategoryName}
                        renderInput={(params) => <TextField {...params} label="Select Sub Category" variant="outlined" />} />
                    </Form.Group>
                    </Col>
                    <Link to="#" onClick={() => setModalShow1(true)} className={`c-p ${isCategoryselected ? "p-none no-drop" : ""}`} >
                    <Form.Label column sm="2" className="text-theme-green"  >
                     <span >   
                     <img src="/media/VRekruitIcons/addmore.svg"  width="24"></img>
                     </span>
                    </Form.Label>
                    </Link>
                </Row>
                <Row >
                    <Form.Label column sm="2">
                    Question Type*
                    </Form.Label>
                    <Col sm="7">
                    <Form.Group as={Col} controlId="formGridState">
                    {/* <Form.Control as="select" defaultValue="Choose..."
                      name="questionType"
                      value={QuestionType}
                      onChange={(e) => handleShowAnswer(e)}
                    >
                        <option >Select Question Type</option>
                        <option value="MCQ">MCQ</option>
                        <option Value="Subjective">Subjective</option>
                    </Form.Control> */}

                    <Autocomplete
                        name="questionType"
                        size="small"
                        value={QuestionType}
                        options={["MCQ" ,"Subjective" ]}
                        onChange={(e, newValue) => {
                          handleShowAnswer(newValue, e);
                        }}
                        getOptionLabel={(options) => options}
                        renderInput={(params) => <TextField {...params} label="Select Question Type" variant="outlined" />} />
                    </Form.Group>
                    </Col>
                    <Form.Label column sm="2"  onClick={showAnswerTab} className={`text-theme-green c-p  ${ QuestionType !== "MCQ" ? "d-none" : "d-inline"}`}  >                       
                       <img src="/media/VRekruitIcons/addmore.svg" className="c-p mr-3" width="24" />  
                    </Form.Label>
                </Row> 
                <Row >
                    <Form.Label column sm="2">
                    Type Your Question*
                    </Form.Label>
                    <Col sm="7">
                    <Form.Group as={Col} controlId="formGridState" >
                    <Form.Control as="textarea" placeholder="" rows={3} 
                     name="question"  onChange={(e) => handleFormInput(e)}
                     value={formData.question}
                    >  
                    
                    </Form.Control>
                    </Form.Group>
                    </Col>
                </Row>
        <Row>
          <Form.Label column sm={2}>
             Question Image
          </Form.Label>
          <Col sm={10} md={7}>
          <div className="upload-container"  style={{"position":"relative"}}>  
            <div className="upload-btn-wrapper" >
              <button className="btn btn-file upload-btn">Choose File </button>
              <input type="file" name="myfile" 
                // {...formik.getFieldProps("file")}
                accept = "image/x-png,image/jpeg"        
                onChange={(e) => uploadFile(e)}  
              />


            </div>
             <span className="text-dark" style={{"position": "absolute", "top":"50%","transform": "translate(5px, -50%)" }}> 
             {/* { pdFileName ? pdFileName : "No File Selected" }  */}
             { pdFileName ? pdFileName : "No File Selected" }
             </span>
          </div>
          <div>
          <span className="text-muted">
            <small>
                Note: File Type PNG/JPEG and Max size: 20MB
               </small>
          </span> 
         
          </div> 
          </Col>          
        </Row>



                <Row controlId="formPlaintextPassword"
                 style={{"display": "flex","alignItems": "baseline"}}>
                    <Form.Label column sm="2">
                      Select Difficulty Level*
                    </Form.Label>
                    <Col sm="7">
                    <Form.Group as={Col} controlId="formGridState" value={formData.difficultyLevel}>
                    {[ 'radio'].map((type) => (
                      <div key={`inline-${type}`} className="mb-3" style={{"display":"flex","justifyContent":"space-between","width":"100%"}}> 
                        <Form.Check inline label="Easy" className="pr-3-inp"  name="difficultyLevel" value="Easy" type={type} id={`inline-${type}-1`} 
                         onChange={(e) => handleFormInput(e)}
                        />
                        <Form.Check inline label="Medium" className="pr-3-inp"  name="difficultyLevel" value="Medium"  type={type} id={`inline-${type}-2`}
                        onChange={(e) => handleFormInput(e)}
                        />
                        <Form.Check inline label="Hard" className="pr-3-inp" name="difficultyLevel" value="Hard" type={type} id={`inline-${type}-3`} 
                        onChange={(e) => handleFormInput(e)}
                        />
                      </div>
                    ))}
                    </Form.Group>
                    </Col>
                </Row>
              
              
                <Row controlId="formPlaintextPassword">
                    <Form.Label column sm="2">
                       Set Marks*
                    </Form.Label>
                    <Col sm="7">
                    <Form.Group as={Col} controlId="formGridState">
                    <Form.Control type="number" 
                     name="marks"  onChange={(e) => handleFormInput(e)} 
                     value={formData.marks}
                     >
                    </Form.Control>
                    </Form.Group>
                    </Col>
                </Row>
        </TabContainer>
    <TabContainer dir={theme.direction}  style={{"pointerEvents": "none !important"}} >



  <Table responsive>
  <thead>
    <tr className="text-center">
      <th>Type Answer* </th>
      <th>Choose Correct* </th>
      <th>Answer Image</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    {
      formData.answers.map((ans, ind) => 
    <tr className="text-center">  
       <td style={{"maxWidth":"150px"}}>  
      <Form.Control as="textarea" placeholder="type here..." rows={3} 
        value={ans.answer}
        name='answers'
        data-index={ind}
        data-name='answer'
        onChange={(e) => handleFormInput(e)}
     />
    </td>      
      
    <td>
      {['radio'].map(type => (
    <div key={`custom-inline-${type}`} className="mb-3">
    <Form.Check inline pr-3 label="Correct" name="correctOption" type={type} id={`inline-${type}-1`}
      name='answers'
      data-index={ind}
      data-name='rightAnswer'
      onChange={(e) => handleFormInput(e)}
    />
    </div>
  ))}
      </td>
      <td className="inline-table ">
        <Form.Group as={Row}>
          <div className="upload-container mt-3"  style={{"position":"relative"}}>  
            <div className="upload-btn-wrapper" >
              <button className="btn btn-file upload-btn">Choose File </button>
              <input type="file" name="myfile" 
                // {...formik.getFieldProps("file")}
                accept = "image/x-png,image/jpeg"        
                // onChange={(e) => uploadFile(e)}  
              />

            </div>
             <span className="text-dark" style={{"position": "absolute", "top":"50%","transform": "translate(5px, -50%)" }}> 
             {/* { pdFileName ? pdFileName : "No File Selected" }  */}
                No File Selected
             </span>
          </div>
          <div>
          <span className="text-muted">         
          </span> 
          </div> 
        </Form.Group>     
      </td>

      <td > 
      { formData.answers.length !== ind+1 ? (
      <>
      <img src="/media/VRekruitIcons/delete.svg" onClick={() => DeleteAnswer(ind)} className="c-p mr-5" width="24" />  

      </>          
      ) : "" }
        
       { formData.answers.length === ind+1 ? (
      <>
      <img src="/media/VRekruitIcons/delete.svg" onClick={() => DeleteAnswer(ind)} className="c-p ml-5 mr-5" width="24" />  

      <img src="/media/VRekruitIcons/addmore.svg" className="c-p " width="24" onClick={addMoreOption}  />   
      </>          
      ) : "" }

      </td>
    </tr>
   )
  }

  </tbody>
</Table>

{/* <button type="submit" className="submit-btn"> submit button </button> */}
          
       </TabContainer>
      </SwipeableViews>
      </Col>
    {/* </Col> */}
    </Form>
    <DeleteConfirmationModal show={modalShow3}onHide={() => setModalShow3(false)}/>
    <CategoryModal show={modalShow} onHide={() => setModalShow(false)}/>
    <SubCategoryCModal show={modalShow1} onHide={() => setModalShow1(false)}/>

</>
  );
}

export default AddQuestion 