/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React from 'react';
import {Link} from 'react-router-dom';
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

function Footer(props) {

  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };


    return (
        <section className="bg-white pt-5 pb-5">
        <footer>
        <Container className="pt-4 pb-5">
         <Row>
          <Col md={6} sm={12} className="text-left fw-600 fs15rem text-center-sm">
          &copy; 2021 VRekruit. All Rights Reserved.
          </Col>
          <Col md={6} sm={12} className="text-right text-center-sm">
          <Link to="/privacy" onClick={scrollToTop}    className="pr-5 fw-600 text-dark fs15rem">
            Privacy |
          </Link>
          <Link to="/terms" onClick={scrollToTop} className="pr-5 fw-600 text-dark fs15rem">
             Terms of use 
          </Link>
           {/* <Link to="#" onClick={scrollToTop} className="pr-5 fw-600 text-dark fs15rem">
              FAQ |
          </Link> */}
          </Col>
         </Row>
      </Container>
        </footer>
      </section>
    );
}

export default Footer;