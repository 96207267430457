/* eslint-disable no-restricted-imports */
/* jshint asi:true */

import React, { useState,useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../modules/Auth/_redux/authRedux"
import Helmet from "react-helmet"
import { Formik,  Field } from 'formik';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import PhoneInput from 'react-phone-input-2'
import alertService from "../../_services/alertservice";
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useDispatch } from 'react-redux';
import { logout } from "../../../actions/auth";

import ViewUser from "./ViewUser"
import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Rating from '@material-ui/lab/Rating';




// const { REACT_APP_TEST } = process.env;
// console.log(REACT_APP_TEST)
/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/
/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  dialCode:"",
  phone:"",
  firstName:"",
  lastName:"",
  email:"",
  password:"",
  roleId:"",
  empId:""
};



function CandidateEvaluation(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [renderPage, setRenderPage]= useState(false); 
  const [selectedRole,SetSelectedRole]= useState("");
  

 
  const ValidationSchema = Yup.object().shape({
      firstName: Yup.string()
      .required('Required'),
      lastName: Yup.string()
      .required('Required'),
      email: Yup.string()
      .email("Wrong email format")
      .required('Required'),
      // roleId: Yup.string()
      // .required('Required'),
      password: Yup.string()
      .required('required')
      .min(8, 'Must Contain min 8 Characters!')
      .max(12, 'Must Contain max 12 Characters!!')
      .matches(
        /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };



  const [requisitions, setRequisitions] = useState([])
  const [candidates, setCandidates] = useState([])
  const [round, setRound] = React.useState('');
  const [selectedRound, setSelectedRound] = React.useState('');


  // password field
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false
  });


 

  // password field

  useEffect(() => {
    let mounted = true;
     getRequisitions(); 
    return () => mounted = false;
  }, [])


  const getRequisitions = () =>{
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/requisitions?page=1&limit=1000&forListing=true`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
    .then((res) => res.json())
    .then((res) => {
      if(res.status==true){
        setRequisitions(res.data)
        
      }
      else{
       
      }
    })
    .catch(() => {
      // disableLoading();
      // setSubmitting(false);
    });
    }
    const history = useHistory();



  const dispatch = useDispatch();
  const logoutMethod = () => {
    dispatch(logout());
    history.push('/auth/signin');
};



  const roundsList =[
    {
      value : 'One',
      title: '1st'
    },
    {
      value : 'Two',
      title: '2nd'
    },
    {
      value : 'Three',
      title: '3rd'
    },
    {
      value : 'Four',
      title: '4th'
    },
    {
      value : 'Five',
      title: '5th'
    }
    ]
  const statusList= [{
    value :'pending',
    title : 'Pending'
  },
  {
    value :'rejected',
    title : 'Rejected'
  },
  {
    value :'on_hold',
    title : 'On Hold'
  },
  {
    value :'selected',
    title : 'Selected'
  },
  {
    value :'next_round',
    title : 'Next Round'
  }   
  ]
  const [showFilters, setShowFilters]  = React.useState(true);
  const [roleName, setRoleName] = React.useState('');
  const [candidateStatus, setStatus] = React.useState('');
  const [candidateStatusDisplay, setStatusDisplay] = React.useState('');

 // const [statuses,setS]

  const [selectedRequisitionId, setInputValue] = React.useState('');
  const [showRequisitionError, setShowRequisitionError] = React.useState(false);
  const [showRoundError, setShowRoundError] = React.useState(false);
  const [showInterviewDetail, setShowInterviewDetail] = React.useState(false);
  const [selectedCandidate, setSelectedCandidate] = React.useState('');

  


  const [showCandidatesList, setShowCandidatesList] = React.useState(false) 

  const handleSelectChange = (value,e) => {
    if(value){
      setInputValue(value.id);
      setRoleName(value)   
      setShowRequisitionError(false)  
    }  
  }

  const handleSelectRound = (value,e) => {
    if(value){
      setRound(value.value)    
      setSelectedRound(value)
      setShowRoundError(false)
      
    }  
  }

  const handleSelectStatus = (value,e) => {
    if(value){
      setStatus(value.value)   
      setStatusDisplay(value)   

      
    }  
  }

  const RatingFormatter = (scores) => {
    let clearScore  = scores.replace(/\\/g, '')
    let value =0 
    if(scores){
      let scoreArray = JSON.parse(clearScore)
      if(scoreArray && scoreArray.length > 0){
        console.log(scoreArray)
        var res = scoreArray.map(score => score.fitmentScore).reduce((acc, score) => score + acc);
     value = res/scoreArray.length



      }
    }

    return (
    <Rating
      name={`simple-controlled-`}
      value={value}
      precision={0.5} readOnly
     
  />
    )
  }

  const getCandidatesList = () =>{
    let requisitionId = selectedRequisitionId
    if(!selectedRequisitionId){
      setShowRequisitionError(true)
      return false
    }
    if(!round){
      setShowRoundError(true)
      return false
    }
    
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/candidate_evaluation_list/?requisitionId=${requisitionId}&round=${round}&candidateStatus=${candidateStatus}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
    .then((res) => res.json())
    .then((res) => {
      if(res.status==true){
        setCandidates(res.data)
       // document.getElementById('kt_aside_toggle').click();

        document.body.classList.add('aside-minimize');
      /*  document.body.classList.remove('aside-enabled');
        document.body.classList.remove('aside-fixed');
        document.getElementById('kt_aside').classList.remove('d-flex');
        document.getElementById('kt_aside').classList.add('d-none');

        document.getElementById('kt_header').classList.add('d-none');
        document.getElementById('content-container').classList.remove('container');
        document.getElementById('content-container').classList.add('container-fluid'); */
        setShowFilters(false)
        setShowInterviewDetail(false)
        setShowCandidatesList(true)

        
      }
      else{
       
      }
    })
    .catch(() => {
      // disableLoading();
      // setSubmitting(false);
    });
    }

  const getInterviewDetails = (interviewId) => {
    setSelectedCandidate(interviewId)
    setShowCandidatesList(false)
    setShowFilters(false)
    setShowInterviewDetail(true)



  } 

  const showFiltersMethod = () => {
  //  document.getElementById('kt_aside_toggle').click();
    document.body.classList.remove('aside-minimize');

      /*  document.body.classList.add('aside-enabled');
        document.body.classList.add('aside-fixed');
        document.getElementById('kt_aside').classList.add('d-flex');
        document.getElementById('kt_aside').classList.remove('d-none');
        document.getElementById('kt_header').classList.remove('d-none');
        document.getElementById('content-container').classList.add('container');
        document.getElementById('content-container').classList.remove('container-fluid'); */
        setShowCandidatesList(false)
        setShowInterviewDetail(false)
        setShowFilters(true)
  }




  return (
    <>
      <Helmet>
        <title> VRekruit | Candidate Evaluation</title>
      </Helmet>
      {showInterviewDetail ? (
        <ViewUser 
        BacktoList={setShowCandidatesList}
        HideDetails={setShowInterviewDetail}

        interviewId={selectedCandidate}
        />
      )
      :
      ''}
      {showCandidatesList ? (
        <>
         <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid" style={{height:'auto'}}>
         <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <div></div>
            {/*} <img alt="logo" className="brand-dim"  src="/media/logos/UPDATED_VRekruit _Logo.png" /> */}

           
             <div className="container-fluid d-flex align-items-center  justify-content-around flex-wrap mr-1">
             <div className="flex-fill mr-5">&nbsp;</div>
               <div className="flex-fill mr-5">
            <Autocomplete size="small"
                  id="roles-titles"
                  options={requisitions}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Requisition" margin="normal" variant="outlined" />
                  )}
                  value={roleName}
                  getOptionLabel={
                    (options) => options.position}
                  onChange={(event, newValue) => {
                    handleSelectChange(newValue, event);
                  }}
                /></div>
                
                <div className="flex-fill mr-5">
                <Autocomplete size="small"
                  id="roles-titles"
                  options={roundsList}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Round" margin="normal" variant="outlined" />
                  )}
                  value={selectedRound}
                  getOptionLabel={
                    (options) => options.title}
                  onChange={(event, newValue) => {
                    handleSelectRound(newValue, event);
                  }}
                />
                </div>
                <div className="flex-fill mr-5">
                <Autocomplete size="small"
                  id="roles-titles"
                  options={statusList}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Status" margin="normal" variant="outlined" />
                  )}
                  value={candidateStatusDisplay}
                  getOptionLabel={
                    (options) => options.title}
                  onChange={(event, newValue) => {
                    handleSelectStatus(newValue, event);
                  }}
                />
                </div>
                </div>
 
         <div className="text-muted d-flex   mt-1" >
         <a className="btn btn-theme-green btn-lg mr-5 "  onClick={getCandidatesList} 
            disabled={loading}
            >
               Filter

 
            </a>
            <a className="btn btn-theme btn-lg mr-5 text-white"  onClick={showFiltersMethod} 
            disabled={loading}
            >
               Back

 
            </a>
            
       
       </div>
       </div>
      
     
       </div>
     
   
       <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5"></Col>  
      
       
      {candidates && candidates.length == 0 ? (
        <><h3 className="text-center mt-5">No Data Found</h3></>) : 
      
      <Row>
      {candidates.map((candidate,i) => (
       <Col md={4} className="mb-5 pb-5" style={{'cursor':'pointer'}}>
         <Box className="text-center pt-5" style={{ 'borderRadius' : '5%','border': '1px solid #ccc'}} onClick ={ () => getInterviewDetails(candidate.id)}>
          
           <div className="mt-5">
           <img src={candidate.candidateImage ? candidate.candidateImage : candidate.userImage ? `${process.env.REACT_APP_API_BASE_URL}${candidate.userImage}` : "/media/users/default.jpg"} className="img-fluid aside-img"   style={{ 'borderRadius' : '50%', "width" : "100px", "height" : "100px"}} />
           <br />
           {RatingFormatter(candidate.fitmentScores)}
           <br />
           <p><b>({candidate.reviewedByCount} of {candidate.totalReviewers} Reviewers rated)</b></p>
           <p><b>{candidate.firstName} {candidate.lastName}</b></p>
           <p><b>{candidate.email}</b></p>
           <p><b>{candidate.dailCode} {candidate.phone}</b></p>
          </div>
           
         </Box>
       </Col>

        
      )
      )}
      </Row>
      }
     
     </>) : ''
  }
  {showFilters ?
    (<>

         {/* subeader */}
         <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600"> Candidate Evaluation </h3></div>
          </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
      {/* subeader */}
  
    
     <Row className="bg-white mb-0 pb-0 pt-5 sticky-header"> 
     <Col md={6} sm={12} offset= {4}  className="pr-3 pl-3">      
      
      </Col>
      <Col md={6} sm={12} offset= {4}  className="pr-3 pl-3 text-center">
      <div>
       <div className="d-flex flex-grow-1 align-items-center justify-content-end    rounded">



        <div className="text-muted d-block mt-1" >
           <button type="submit" className="btn btn-theme-green btn-lg mr-5 "  onClick={getCandidatesList} 
           disabled={loading}
           >
              Filter
              {loading && <span className="ml-3 spinner spinner-white"></span>}

           </button>
           
      </div>
      </div>
      </div>
      </Col>
    
      <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5"></Col>  

      </Row>
      <Row className="bg-white pt-10 pb-4">


      <div className="container" style={{"minHeight":"60vh"}}>
            <Form className="form fv-plugins-bootstrap fv-plugins-framework">

              <Form.Group as={Row}>
                <Form.Label column sm={2} className="mt-3">
                  Select Job Requisition
                </Form.Label>
                <Col sm={10} md={7}>
  
                <Autocomplete size="small"
                  id="roles-titles"
                  options={requisitions}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Requisition" margin="normal" variant="outlined" />
                  )}
                  value={roleName}
                  getOptionLabel={
                    (options) => options.position}
                  onChange={(event, newValue) => {
                    handleSelectChange(newValue, event);
                  }}
                />
                {showRequisitionError ? (
                         <div className="fv-plugins-message-container">
                        <div className="fv-help-block">Required</div>
                       </div>
                   ) : null}
                   
                  
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={2} className="mt-3">
                  Select Round
                </Form.Label>
                <Col sm={10} md={7}>
  
                <Autocomplete size="small"
                  id="roles-titles"
                  options={roundsList}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Round" margin="normal" variant="outlined" />
                  )}
                  value={selectedRound}
                  getOptionLabel={
                    (options) => options.title}
                  onChange={(event, newValue) => {
                    handleSelectRound(newValue, event);
                  }}
                />{showRoundError ? (
                  <div className="fv-plugins-message-container">
                 <div className="fv-help-block">Required</div>
                </div>
            ) : null}
                   
                  
                </Col>
              </Form.Group>
              

            </Form>   
            </div> 
      </Row>
      </>
    ) 
    : ''
    }

      </>
      
  );
                
}

export default injectIntl(connect(null, auth.actions)(CandidateEvaluation));
