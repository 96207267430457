/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React, {useState,useCallback } from "react";
import {Link, Switch, Redirect} from "react-router-dom";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {ContentRoute} from "../../../../_metronic/layout"
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import Helmet from "react-helmet"
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Collapsible from 'react-collapsible';
import LoginAside from "./LoginAside"
import { ExpandMore } from '@material-ui/icons';
import BackgroundSlideshow from 'react-background-slideshow'
import Header from './Header'
import Footer from './Footer'
import  {Carousel}  from 'react-responsive-carousel';
// import GoogleMapReact from 'google-map-react';
// import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';



 export default function LandingPage() {

  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  return (

  <>
      
     <Helmet>
         <title> VRekruit | Homepage</title>
     </Helmet>


      <Header/>

<Carousel
      autoPlay
      infiniteLoop
      showThumbs={false}
      showArrows={false}
      showIndicators={false}
      >
        <div >
          <img alt="banner4" className="banner-height"  src="/media/VRekruitIcons/An online asynchronous interviewing tool.jpg" />
          <p className="legend text-white">
          	An online asynchronous interviewing tool
          {/* Post a job or project and get a short list of verified and
          <br />
          experienced designers,developers,and marketers. */}
          </p>
        </div>
        <div >
        <img alt="banner5" className="banner-height" src="/media/VRekruitIcons/Future of Recruitment.jpg" />
          <p className="legend fs3-2rem text-white">
          	Future of Recruitment
          </p>
        </div>
        <div >
          <img alt="banner4" className="banner-height" src="/media/VRekruitIcons/Interview candidates anytime…anywhere.jpg" />
          <p className="legend text-white">
          	Interview candidates anytime…anywhere
          </p>
        </div>
        <div >
          <img alt="banner4" className="banner-height" src="/media/VRekruitIcons/Cloud-based, fully secured.jpg" />
          <p className="legend fs3-2rem text-white">
          	Cloud-based, fully secured
          </p>
        </div>
        <div >
          <img alt="banner4" className="banner-height" src="/media/VRekruitIcons/The world is your office.jpg" />
          <p className="legend fs3-2rem text-white">
          	The world is your office
          </p>
        </div>
      </Carousel>

{/* 
<section className="bg-white bg-img-1" style={{"minHeight":"84vh"}} >

<Container className="" >
  <Row>
  <Col sm={12} md={12} className="mt-100 ml-60 pl-0 pr-0" >
    <div className="section-cont">
    <p className="text-recruitment"> Recruitment</p>
    <h1 className="fs45rem">A Better Way To Find and 
      <br/>
      Hire Professional</h1>
   <h1 className="fs7rem">Find & Post
   Jobs</h1>
   <h6 className="pt-3 pb-3 fs-19rem">
     Post a  job or project and get a short list of verified and 
     <br/>
     experienced designers,developers,and marketers.
   </h6>
     <button className="btn btn-interview mt-3 mb-3 fs2rem"> Start Your Free Trial </button>
   </div>
   </Col>

   </Row>
   </Container>

</section> */}


   {/* <Col md={7} className="pl-0 pr-0">
     <img className="img-responsive" style={{"width":"100%"}} src="/media/VRekruitIcons/ws-banner-1.jpg"/> 
   </Col> */}


<section className="bg-white pb-5 ">
<Container className="pt-5 mt-10 text-center mr-auto"  >
<Row className="d-flex space-around">
  <Col sm={12} md={12} className="pb-5 mb-5">
    <div>
    <p className="text-center font-weight-bold fs3-2rem"> How It Works ?</p>
    {/* <p className="text-center font-weight-bold fs-19rem fs-1-5rem">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p> */}
   </div>
   </Col>

   <Col md={6} sm={12} className="mb-5 mt-5 center-aligned">
     <div className="outer-circle mb-3 ">
     <img className="fa-31x" src="/media/VRekruitIcons/plan.svg"/>
     </div>
     <span className="fs-1-5rem fw-300 pl-5 text-left">
      	Create online tests and video interviews in minutes     
     </span>
    </Col>

    <Col md={6} sm={12} className="mt-5 mb-5 center-aligned">
     <div className="outer-circle mb-3 ">
     <img className="fa-31x" src="/media/VRekruitIcons/talent search.svg"/>
     </div>
     <span className="fs-1-5rem fw-300 pl-5 text-left">
       	Drag and drop from our vast library of test questions and pre-recorded interview questions, and you are ready      </span>
    </Col> 
    <Col md={6} sm={12} className="mt-5 mb-5 center-aligned">
     <div className="outer-circle mb-3 ">
     <img className="fa-31x" src="/media/VRekruitIcons/Screening.svg"/>
     </div>
     <span className="fs-1-5rem fw-300 pl-5 text-left">
       	Send the online test and video interview links to all your candidates at one go
      </span>
    </Col> 
    <Col md={6} sm={12} className="mt-5 mb-5 center-aligned">
     <div className="outer-circle mb-3 ">
     <img className="fa-31x" src="/media/VRekruitIcons/Interviewing.svg"/>
     </div>
     <span className="fs-1-5rem fw-300 pl-5 text-left">
      	Candidates give the online test or record the video interview, within a stipulated time period, at their convenience     </span>
    </Col> 
    <Col md={6} sm={12} className="mt-5 mb-5 center-aligned">
     <div className="outer-circle mb-3">
       <img className="fa-31x" src="/media/VRekruitIcons/Evaluation.svg">
       </img>
     </div>
     <span className="fs-1-5rem fw-300 pl-5 text-left">
     	Review these tests and interviews - Anytime... Anywhere...
     </span>
    </Col>
    <Col md={6} sm={12} className="mt-5 mb-5 center-aligned">
     <div className="outer-circle mb-3">
       <img className="fa-31x" src="/media/VRekruitIcons/collaborate.png">
       </img>
     </div>
     <span className="fs-1-5rem fw-300 pl-5 text-left">
     Make hiring decisions faster through the most collaborative process
     </span>
    </Col>
    </Row>
</Container>
</section>


{/* <section  style={{"backgroundColor":"#003577"}}>
<Container className="pt-5 mt-20 mb-20 text-center mr-auto" >
<Row className="d-flex space-around">
   <Col md={3} sm={12}>
     <div className="mr-auto text-white fs4rem">
       136%
     </div>
      <div className="line"></div>
     <span className=" text-white fs2rem">
       Lorem Ipsum
      </span>
    </Col>
    <Col md={3} sm={12}>
     <div className="mr-auto text-white fs4rem">
       136%
     </div>
     <div className="line"></div>

     <span  className="text-white fs2rem">
       Lorem Ipsum
      </span>
    </Col> <Col md={3} sm={12}>
     <div className="text-white  mr-auto fs4rem">
     136%
     </div>
     <div className="line"></div>
     <span className="text-white fs2rem">
      Lorem Ipsum
      </span>
    </Col> <Col md={3} sm={12}>
     <div className="text-white  mr-auto fs4rem">
       136%
     </div>
     <div className="line"></div>
     <span className="text-white fs2rem">
      Lorem Ipsum
     </span>
    </Col> 
    </Row>
</Container>
</section> */}



<section className="bg-white bg-bannner-1">
  <Container className="mt-10 ">
    <Row>
      <Col sm={6} md={6}>
        <img className="img-responsive ml-20 mt-10" width="80%" src="/media/VRekruitIcons/recruit-business_1.png"  />  
      </Col>
      <Col sm={6} md={6}>
      <h2 className="pb-5 mt-100  fs3-2rem">
         What it does?
      </h2>

      <h4 className=" fs-19rem">
        Access to a wider talent pool     
      </h4>

      <h4 className="pt-5  fs-19rem">
      	Well collaborated, transparent & consistent interview assessment      
      </h4>

      <h4 className="pt-5  fs-19rem">
      	No scheduling nightmares      
      </h4>
      <h4 className="pt-5  fs-19rem">
      	Deeper insights into the candidate profile      
      </h4>
      <h4 className="pt-5  fs-19rem">
      	Enhanced candidate experience      
      </h4>
      <h4 className="pt-5  fs-19rem">
      	Save time, save money      
      </h4>
      </Col>

    </Row>
  </Container>
</section>


{/* <section id="cs1"  style={{"backgroundColor":"#003577"}}>
<Container className="pt-5 mt-30 mb-30 text-center mr-auto" >
<Row className="d-flex space-around">
   <Col md={12} sm={12}>
     <div className="mr-auto text-white fs-30 mb-10">
       <h2 className="text-white text-center fs2rem">
         Frequently Asked Questions
      </h2>
     </div>
     <span className="text-white pt-3 pb-3 text-left fs-20">
     <Accordion style={{ "background": "transparent", "color":"white","borderBottom":"1px solid #f7f4ed","paddingBottom": "15px"}}>
        <AccordionSummary  expandIcon={<ExpandMore />} className="fs-1-5rem">
             How do I create an account
        </AccordionSummary>
        <AccordionDetails>
        <small>
           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
           sit amet blandit leo lobortis eget.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
           sit amet blandit leo lobortis eget.
         </small>
        </AccordionDetails>
        </Accordion>
    </span>

    <span className="text-white pt-3 pb-3 text-left fs-20">
    <Accordion style={{ "background": "transparent", "color":"white","borderBottom":"1px solid #f7f4ed","paddingBottom": "15px"}}>
        <AccordionSummary  expandIcon={<ExpandMore />} className="fs-1-5rem">
             How do I create an account
        </AccordionSummary>
        <AccordionDetails>
        <small className>
           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
           sit amet blandit leo lobortis eget.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
           sit amet blandit leo lobortis eget.
         </small>
        </AccordionDetails>
        </Accordion>
    </span>

    <span className="text-white pt-3 pb-3 text-left fs-20">
    <Accordion style={{ "background": "transparent", "color":"white","borderBottom":"1px solid #f7f4ed","paddingBottom": "15px"}}>
        <AccordionSummary  expandIcon={<ExpandMore />} className="fs-1-5rem">
             How do I create an account
        </AccordionSummary>
        <AccordionDetails>
         <small>
           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
           sit amet blandit leo lobortis eget.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
           sit amet blandit leo lobortis eget.
         </small>   
        </AccordionDetails>
        </Accordion>
    </span>

    <span className="text-white pt-3 pb-3 text-left fs-20">
    <Accordion style={{ "background": "transparent", "color":"white","borderBottom":"1px solid #f7f4ed","paddingBottom": "15px"}}>
        <AccordionSummary  expandIcon={<ExpandMore />} className="fs-1-5rem">
             How do I create an account
        </AccordionSummary>
        <AccordionDetails>
         <small>
           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
           sit amet blandit leo lobortis eget.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
           sit amet blandit leo lobortis eget.
         </small>   
        </AccordionDetails>
        </Accordion>
    </span>

    </Col>
    </Row>
</Container>
</section> */}


<section className="bg-info-1">
<Container>
  <Row className="mt-20 mb-20 d-flex space-around">
    <Col sm={12} md={4} >
    <img className="img-responsive mb-5 " width="200" src="/media/logos/UPDATED_VRekruit _Logo.png"  />   
    <p className="text-left"> 
    A fully integrated cloud-based asynchronous video interview and <br/> talent solution
    </p>

    </Col>

    <Col sm={12} md={4} >
    <h3 className="text-left pt-3 fw-600"> 
       Contact Info
    </h3>
    {/* <p className="text-left pt-3"> 
    <img className="d-inline mr-3" width="25" alt="" src="/media/VRekruitIcons/address.svg" />

    Building No. 000, Industrial Area,
    <br/>
    <span className="ml-10">   Mumbai- 000000 </span>    
    </p> */}
    <p className="text-left pt-3"> 
    <img className="d-inline mr-3" width="25" alt="" src="/media/VRekruitIcons/email (1).svg" />

       admin@vrekruit.com
    </p>
    {/* <p className="text-left pt-3"> 
    <img className="d-inline mr-3" width="25" alt="" src="/media/VRekruitIcons/call (1).svg" />    
      +91 89968465798
    </p> */}

    </Col>



    <Col sm={12} md={4}   >

    {/* <h3 className="text-left pt-3 pb-3 fw-600"> 
      Follow us On
    </h3>


    <img className="d-inline mr-3 c-p" width="25" alt="" src="/media/VRekruitIcons/facebook-app-symbol.svg" />

    <img className="d-inline mr-3 c-p" width="25" alt="" src="/media/VRekruitIcons/google-plus-logo.svg" />

    <img className="d-inline mr-3 c-p" width="25" alt="" src="/media/VRekruitIcons/linkedin-blue.png" />
   
    <img className="d-inline mr-3 c-p" width="25" alt="" src="/media/VRekruitIcons/twitter-blue.png" /> */}




    <h3 className="text-left  pb-3 text-theme-green"> 
     Download Our Apps 
    </h3>
    <h3>
      <a href={`${process.env.REACT_APP_ANDROID_APP_URL}`} target="_blank">
    <img className="img-responsive mb-5  mt-2 br-10 mr-5 c-p" width="150px" src="/media/VRekruitIcons/google.png"  />  
    </a>
    <a href={`${process.env.REACT_APP_IOS_APP_URL}`} target="_blank">

     <img className="img-responsive mb-5  br-10  mt-2 c-p" width="150px"  src="/media/VRekruitIcons/apple.png"  />  
     </a>
    </h3>
    </Col>


  </Row>
</Container>
</section>

<Footer/>



    {/* <div className="text-center mt-5">
       <span className="font-weight-bold text-dark-50">
         <h1> 
           Home Page
         </h1>
         </span>
       </div> */}

        {/* <div className="d-flex justify-content-center align-items-center mt-5">
                <div>
                 <Link to="/auth/registration">
           
                <button className="btn btn-primary"  >
                  Sign Up!
                </button>

                </Link>
                </div>
  
               <div>
                <Link className="btn btn-primay" to="/auth/signin">

                <button className="btn btn-primary"  >
                  Sign In!
                </button>                
                </Link>
                </div>
          </div> */}
          </>
             
           
  );
}

// export default GoogleApiWrapper({
//   apiKey: ('AIzaSyBtXGhg5NSAWeUP2fTVp-v_x4bJuqa34Mo')
//  })(LandingPage);


//  export default GoogleApiWrapper({
//   apiKey: ('AIzaSyBtXGhg5NSAWeUP2fTVp-v_x4bJuqa34Mo')
// })(LandingPage)


