import React from 'react'
import {Link} from "react-router-dom";

import Helmet from "react-helmet"



function BrowserNotSupported() {
    return (
        <>
            <Helmet>
                <title> VRekruit | Browser Not Supported</title>
            </Helmet>
            <div>
            <div className="d-flex justify-content-center align-items-center mt-5">  
            <img className="img-responsive mb-5" width="200" src="/media/logos/UPDATED_VRekruit _Logo.png"  />
                </div>
                <center>
            
                        <div className="container">
                        <div className="heading mb-5" style={{ "text-align": "center",    "font-size": "20px",  "margin-bottom": "50px"}}>
                                <h1>"This browser is not supported"</h1>
                                <p>Switch to one of these browsers to use the application</p>
                            </div>
                            <div className="row justify-content-around mt-5">
                                <div className="card col-md-3 col-sm-12 mt-5">
                                    <div className="card-header">
                                        <div className="img-box">
                                            <img src="/media/logos/chrome.png" style={{"width" : "100px"}} />
            </div>
                                    </div>
                                    <div className="card-body">
                                        <h3>Chrome (Windows)</h3>
                                        <p>Version 90+</p>
                                    </div>
                                </div>
                            <div className="card col-md-3 col-sm-12 mt-5">
                                    <div className="card-header">
                                        <div className="img-box">
                                            <img src="media/logos/firefox.png" style={{ "width": "100px" }} />
            </div>
                                    </div>
                                    <div className="card-body">
                                        <h3>Firefox (Windows)</h3>
                                        <p>Version 85+</p>
                                    </div>
                                </div>
                            <div className="card col-md-3 col-sm-12 mt-5">
                                    <div className="card-header">
                                        <div className="img-box">
                                            <img src="media/logos/safari.png" style={{ "width": "100px" }} />
            </div>
                                    </div>
                                    <div className="card-body">
                                        <h3>Safari (Mac OS)</h3>
                                        <p>Version 15.0+</p>
                                    </div>
                                </div>

                            </div>
                        </div>
               </center>
          
           </div>
        </>
    )
}

export default BrowserNotSupported
