/* eslint-disable no-restricted-imports */
/* jshint asi:true */

import React, { useState } from "react";
import { Link , useHistory} from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import Helmet from "react-helmet";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import LoginAside from './LoginAside';
import { useParams} from 'react-router-dom';
import { id } from "date-fns/locale";
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import alertService from "../../../_services/alertservice";
import { Formik,  Field } from 'formik';
import Footer from "./Footer";
import Header from "./Header";

const queryString = require('query-string');




// const { REACT_APP_TEST } = process.env;
// console.log(REACT_APP_TEST)
/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/
/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
    oldPassword: "",
    password: "",
    confirmPassword: "",
  };

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(1),
    },
    textField: {
      flexBasis: 200,
    },
  }));


  
function ResetTempPassword(props) {

    let { id } = useParams();    
    const { intl } = props;
    const [loading, setLoading] = useState(false);
    const histroy  =useHistory();

    const ResetPasswordSchema = Yup.object().shape({
      
      password: Yup.string()
      .min(8, "Minimum 8 Characters")
      .max(12, "Maximum 12 Characters")
      .required('Please Enter your password')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),

      confirmPassword: Yup.string()
      .required('Please Enter your password')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      )
      .min(8, "Minimum 8 Characters")
      .max(12, "Maximum 12 Characters"),
     
      oldPassword: Yup.string()
      .required('Please Enter your password')

    });


    const enableLoading = () => {
        setLoading(true);
    };
    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }
        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }
        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: ResetPasswordSchema,
        validateOnChange:true,
        validateOnBlur:true,


        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            resetPassword(values, setStatus, setSubmitting);
        },
    });



    const resetPassword = (values, setStatus, setSubmitting) => {
        if(values.password !== values.confirmPassword){
            return;
        }
      
          const token = localStorage.getItem('TempPasswoedToken');
          fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/user/change_password`, {
            method: 'POST',
            headers: {
              'content-type': 'application/json',
              'x-access-token': token
      
            },
            body: JSON.stringify(values)
          })
            .then((res) => res.json())
            .then((res) => {
              if (res.status == true) {
                disableLoading();
                var ele = document.getElementById("login-link");

                if(ele){
                    ele.click();  
                }
                alertService.alert({ message : res.message, type : 'success'  });
                setSubmitting(false);
                values.password="";
                values.oldPassword="";
                values.confirmPassword="";
                Formik.resetForm();
           
      
              }
              else {
                alertService.alert({ message : res.message, type : 'error'  });
                disableLoading();
                setSubmitting(false);
              }
            })
            .catch(() => {
              disableLoading();
              setSubmitting(false);
            });
        }
      
        const  submitFormMethod = () => {
          var submitbtn =  document.getElementById("submit-btn");
          if(submitbtn) {
            submitbtn.click();
          }    }

    const [values, setValues] = React.useState({
        password: '',
        showPassword: false
      });
  
      const [values1, setValues1] = React.useState({
        password: '',
        showConfirmPassword: false
      });

      const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
      };
  
      const handleClickShowConfirmPassword = () => {
        setValues1({ ...values1, showConfirmPassword: !values1.showConfirmPassword });
      };

      const handleChange = prop => event => {
        setValues({ ...values, [prop]: event.target.value });
      };


      const [values2, setValues2] = React.useState({
        password: '',
        showConfirmPassword: false
      });
    
    
      const handleClickPassword = () => {
        setValues2({ ...values2, showPassword: !values2.showPassword });
      };
      
    return (
        <>
            <Helmet>
                <title> VRekruit | Change Password</title>
            </Helmet>
           
           <Header/>
            <div className="d-flex flex-column flex-root">
                <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white " id="kt_login">
                <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden center-flex">
                    <div className="" id="kt_login_signin_form">
                        <div className=" mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 mb-5">
                            <div>
                          {/*  <img className="img-responsive mb-5" width="200" src="/media/logos/UPDATED_VRekruit _Logo.png"  /> */}
                            </div>
                            <h3 className="text-dark font-weight-bold mt-5 mb-2 mt-50 text-dark">Change Password </h3>   

                            <p className="font-weight-bold text-dark-50 d-inline "> Enter your temporary password and set a new password</p>                     
                            </div>
                        {/*begin::Form*/}
                        <div className="pt-5"></div>
                        <div className="pt-5"></div>
                        <div className="pt-5"></div>
                        <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">


                            <div className="form-group fv-plugins-icon-container">
                                <span className="d-flex justify-content-space-between">
                                    <label className="form-label auth-lable" htmlFor="formGridPassword">Temporary Password</label>
                                    <label className="form-label" htmlFor="formGridPassword">
                                    </label>
                                </span>
                                <TextField
                                        id="filled-adornment-old-password"
                                        className={`1clsx(classes.margin, classes.textField) form-control form-control-solid h-auto `}
                                        variant="filled"
                                        type={values2.showPassword ? 'text' : 'password'}
                                        label="Password"
                                        value={values2.password}
                                        onChange={handleChange('oldPassword')}
                                        InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                aria-label="Toggle password visibility"
                                                onClick={handleClickPassword}
                                            >
                                                {values2.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                            </InputAdornment>
                                        ),
                                        }}
                                        name="oldPassword"
                                        {...formik.getFieldProps("oldPassword")}
                                    />  
                                {formik.touched.oldPassword && formik.errors.oldPassword ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.oldPassword}</div>
                                    </div>
                                ) : null}
                            </div>

                            <div className="form-group fv-plugins-icon-container">
                                <span className="d-flex justify-content-space-between">
                                    <label className="form-label auth-lable" htmlFor="formGridPassword">New Password</label>
                                    <label className="form-label" htmlFor="formGridPassword">
                                    </label>
                                </span>
                                <TextField
                                        id="filled-adornment-password"
                                        className={`1clsx(classes.margin, classes.textField) form-control form-control-solid h-auto `}
                                        variant="filled"
                                        type={values.showPassword ? 'text' : 'password'}
                                        label="Password"
                                        value={values.password}
                                        onChange={handleChange('password')}
                                        InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                aria-label="Toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                            </InputAdornment>
                                        ),
                                        }}
                                        name="password"
                                        {...formik.getFieldProps("password")}
                                    />  
                                {formik.touched.password && formik.errors.password ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.password}</div>
                                    </div>
                                ) : null}
                            </div>


                            <div className="form-group fv-plugins-icon-container">
                                <span className="d-flex justify-content-space-between">
                                    <label className="form-label auth-lable" htmlFor="formGridPassword">Confirm Password</label>
                                </span>

                                <TextField
                                        id="filled-adornment-password"
                                        className={`1clsx(classes.margin, classes.textField) form-control form-control-solid h-auto `}
                                        variant="filled"
                                        type={values1.showConfirmPassword ? 'text' : 'password'}
                                        label="Password"
                                        value={values.password}
                                        onChange={handleChange('password')}
                                        InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                aria-label="Toggle password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                            >
                                                {values1.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                            </InputAdornment>
                                        ),
                                        }}
                                        name="confirmPassword"
                                    {...formik.getFieldProps("confirmPassword")}
                                    /> 
                                {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.confirmPassword}</div>
                                    </div>
                                ) : null}


                                <span className="d-flex" > 
                                    {(formik.touched.confirmPassword || formik.isSubmitting) &&    formik.values.confirmPassword != formik.values.password ? (
                                    <div className="fv-plugins-message-container">
                                       <div className="fv-help-block">
                                        {(<div>Password do not match</div>)}
                                        </div>
                                    </div>
                                    ) : null}
                                </span>
                        
                            </div>

                            <div className="form-group d-flex flex-wrap justify-content-space-between mt-50">
                                    <button 
                                    style={{"width":"40%"}}
                                    id="kt_login_forgot_submit"
                                    type="submit"
                                    className="btn btn-theme btn-primary font-weight-bold px-9 py-4 my-3 "
                                    >
                                    {/* {loading && <span className="ml-3 spinner spinner-white"></span>} */}
                                    Submit
                                    </button>
                                    <Link to="auth/forgot-password" style={{"width":"40%"}}>
                                    <button type="button" id="kt_login_forgot_cancel"
                                        style={{"width":"100%"}}
                                    
                                    className="btn  btn-light-primary btn-theme-green font-weight-bold px-9 py-4 my-3 mx-4">
                                        Cancel
                                    </button>
                                    </Link>

                            </div>
                        </form>
                        {/*end::Form*/}
                    </div>
                </div>

                 <Link to="/auth/signin"  id="login-link" className="d-none">Link</Link>
                <div className="mt-5">
                </div>
 
                    <LoginAside />
                    
                </div>
            </div>
            <Footer />
        </>
    );
}

export default injectIntl(connect(null, auth.actions)(ResetTempPassword));
