
/* eslint-disable no-restricted-imports */
/* jshint asi:true */

import React, { useState,useEffect } from "react";
import { Link , useLocation  } from "react-router-dom";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import Helmet from "react-helmet"
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";



function InterviewLinkExpired(props) {
  const { intl } = props;    
  const location = useLocation();
  const[message,SetMessage]=useState('');
  useEffect(() => {
    console.log(location)
    SetMessage(location.state.message);
 }, [location]);

  return (
    <>
    <Helmet>
       <title> VRekruit | Interview</title>
    </Helmet>
         
<section className="bg-white" style={{"overflow":"hidden"}}>
  <Row  >
    <Col sm={12} xs={12} md={12} className="text-center" >
    <img src={"/media/logos/UPDATED_VRekruit _Logo.png"}  className="pt-5 pb-5 mr-auto"    height="80" alt="Company Logo bg-circle" ></img>
    </Col>  
  </Row>
</section>   
    <section className="" style={{"backgroundColor":"#003577","minHeight":"90vh","overflowY":"hidden"}}>
      {message ?
               
               <h3   className="text-center text-white d-block pt-100" style={{"whiteSpace": "pre-wrap", "lineHeight" : "30px"}}> {message} </h3>
          
        : "" }
   </section>
    </>
  );
}

export default InterviewLinkExpired;