/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React from "react";
import {Link, Switch, Redirect} from "react-router-dom";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {ContentRoute} from "../../../../_metronic/layout"
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import Helmet from "react-helmet"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Collapsible from 'react-collapsible';
import LoginAside from "./LoginAside"
import { ExpandMore } from '@material-ui/icons';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import Header from './Header'
import Footer from './Footer'



export default function PrivacyPage() {


  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  return (

  <>
      
     <Helmet>
         <title> VRekruit | Terms</title>
     </Helmet>



  <Header/>


<section id="cs1"  style={{"backgroundColor":"#003577"}}>
<Container className="pt-5 mt-20 mb-20 text-center mr-auto" >
<Row className="d-flex space-around">
   <Col md={12} sm={12}>
     <div className="mr-auto text-white fs-30 mb-10">
       <h2 className="text-white text-center fs2rem">
         Terms of use
      </h2>
     </div>
    </Col>
    </Row>
</Container>
</section>




<section className="bg-white">



<h1 className=" pl-5 pt-5 pb-5text-style-2">1. Introduction</h1>

<p className="pl-5 pt-5">
These Terms of Service (the “Terms of Service” or this “Agreement”) govern your access to and use of vRekruit (“vRekruit”, “we”, “us” or “our”) website (the “Site”), platform, services, code and/ or software (collectively, the "Services"). These Terms of Service limit vRekruit’s liability and obligations to you, grant vRekruit certain rights and allow vRekruit to change, suspend or terminate your access to and use of the Services. These Terms of Services also provide for certain rights you may grant to others, including Prospective Recruiters and Prospective Recruits (as such terms are defined below.) Your access to and use of the Services are expressly conditioned on your compliance with these Terms of Service. vRekruit reserves the right to update and change these Terms of Service from time to time without notice or acceptance by you, so please check this page frequently for updates and changes.</p>



<h1 className=" pl-5 pt-5 pb-5text-style-2">2.	SERVICES</h1>


<p className="pl-10 pt-0">
1.
	Subject to the terms of this agreement, vRekruit grants to licensee the non-transferable and nonexclusive right and license to remotely access and use the service solely for running assessments for its prospective employees during the term stated in the account type, as permitted by this agreement. This license is a worldwide license, with the exception that the licensee and users cannot access the services from, or use the services in, those countries to which exports are prohibited or restricted under any export control laws or regulations.
</p>

<p className="pl-10 pt-0">

2.	When using the service you shall not: (a) copy, translate, decompile, reverse-engineer or otherwise modify any parts of the service (b) transmit any content, data or information that is unlawful, harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, libellous, invasive of another's privacy or right of publicity, hateful, or racially, ethnically or otherwise objectionable; (c) infringe the intellectual property rights of any entity; (d) interfere with or disrupt the vRekruit software, the vRekruit systems used to host the service, other equipment or networks connected to the service, or disobey any requirements, procedures, policies or regulations of networks connected to the service (e) use the service in the operation of a service bureau or time-sharing service; (f) circumvent the user authentication or security of the site or service or any host, network, or account related thereto; (g) use any application programming interface to access the service other than those made available by vRekruit; (h) mirror the service on any server without prior approval from vRekruit; (i) make any use of the service that violates any applicable local, state, national, international or foreign law; or (j) fail to use commercially reasonable efforts to prevent the unauthorized license, sale, transfer, lease, transmission, distribution or other disclosure of the service, or (k) except for licenses provided to users as permitted in this agreement, allow any third party to use any user identification(s), code(s), password(s), procedure(s) and user keys issued to, or selected by, licensee for access to the service.</p>

<p className="pl-10 pt-0">
3.	The site may contain links to external web sites and information provided on such external websites by vRekruit partners and third-party service providers. vRekruit shall not be responsible for the contents of any linked web site, or any changes or updates to such sites. You further agree that vRekruit shall not be directly or indirectly responsible or liable for any damage or loss caused or alleged to be caused by or in connection with your use of or reliance on any content, goods or services available on or through any such linked web site. any article, information, data, code, text, software, documentation, graphics, image, marketing material, video, photograph, message, or posting to any forum, wiki, or blog on the site, whether publicly posted or privately transmitted, is the sole responsibility of the person or entity providing the content.
</p>

<p className="pl-10 pt-0">
4.	vRekruit will use commercially reasonable security technologies (such as encryption, password protection and firewall protection) in providing the service, and the user shall comply with the applicable vRekruit security guidelines and procedures made known to you through the service or otherwise. However, vRekruit does not control the transfer of data, including but not limited to user data, over telecommunications facilities, including the internet, and vRekruit does not warrant secure operation of the service or that such security technologies will be able to prevent third party disruptions of the service.
</p>

<p className="pl-10 pt-0">
5.	vRekruit shall have the right to use third parties (the "subcontractors") in performance of its obligations and services hereunder. Should vRekruit use any subcontractors in performance of its obligations and services, vRekruit shall be fully responsible to the user for the acts and omissions of its subcontractors as it is for vRekruit's own acts and omissions. Nothing contained in this agreement shall create any contractual relationship between any subcontractor and the user.
</p>

<p className="pl-10 pt-0">
6.	Use of the service for purposes of monitoring its availability, performance or functionality, or for any other benchmarking or competitive purposes are prohibited.
</p>



<h1 className=" pl-5 pt-5 pb-5text-style-2">3.	RESPONSIBILITIES</h1>

<p className="pl-10 pt-0">
1.	You are responsible for all its activity occurring under this license.
</p>

<p className="pl-10 pt-0">
2.	You shall not use the service for employees or candidates for any third-party organizations without licensor's express written consent and permission.
</p>

<p className="pl-10 pt-0">
3.	You shall abide by all applicable local, state, national and foreign laws, treaties and regulations in connection with use of the service.
</p>
<p className="pl-10 pt-0">
4.	You shall indemnify the licensor against any misuse or abuse of the service.
</p>
<p className="pl-10 pt-0">
5.	You shall notify licensor immediately of any unauthorized use or any known or suspected breach of security, notify licensor immediately, and use all reasonable efforts to stop any known or suspected copying or distribution of content.

</p>
<p className="pl-10 pt-0">
6.	You shall not allow an unauthorized third party to gain access to the service.

</p>
<p className="pl-10 pt-0">
7.	You shall keep all log-in information confidential. 

</p>
<p className="pl-10 pt-0">
8.	You shall not license, sublicense, sell, resell, transfer, assign, distribute or otherwise commercially exploit or make the service or the content available to any third party in any way. 

</p>
<p className="pl-10 pt-0">
9.	You shall not create internet "links" to the service or "frame" or "mirror" any content on any other server or wireless or internet-based device; or reverse engineer or access the service in order to build a competitive product or service,

</p>
<p className="pl-10 pt-0">
10.	You shall not send or store infringing, obscene, threatening, libellous, or otherwise unlawful or tortuous material, including material harmful to children or violative of third party privacy rights;
</p>

<p className="pl-10 pt-0">
11.	You shall not send or store material containing software viruses, worms, trojan horses or other harmful computer code, files, scripts, agents or programs;
</p>

<p className="pl-10 pt-0">
12.	You shall not interfere with or disrupt the integrity or performance of the service or the data contained therein; or attempt to gain unauthorized access to the service or its related systems or networks.
</p>











<h1 className=" pl-5 pt-5 pb-5text-style-2">4.	CONTENT </h1>
<p className="pl-10 pt-0">
1.	The Services include functionality that permits users to post text, images, audiovisual media and other forms of content and intellectual property, including the data, ideas, inventions, designs, patterns and processes in such material (collectively, "Content") to the Services. Subject to the grant of rights to vRekruit herein, as between you and vRekruit, any Content that you post on or through the Services belongs to you. “Content” does not include non-identifiable aggregate data compiled by vRekruit (including in connection with analyzing your Content) for purposes of improving, maintaining and/or optimizing the Services. 


</p>

<p className="pl-10 pt-0">
2.	Please note that certain Content that you wish to post may be protected by copyright, even if not marked with the © symbol, and other intellectual property rights. If you are not the creator of the Content you post or otherwise use in connection with the Services, then you must get permission from the creator of that Content or the copyright holder to use the Content. For example: 
   <span className="pl-5 d-block">
   a.	professionally-taken photographs should not be used in or on the Services or in any Content unless you have received permission from the photographer to do so; 

    </span>

    <span className="pl-5 d-block">
    b.	musical Content that is not an original creation by you is also protectable under copyright laws. Copying and distributing music without purchasing or obtaining the rights to the music may be in breach of various laws. Prior to uploading your own musical Content, you must be sure that you have the permission to use such musical Content in connection with the Services.

    </span>

    <span className="pl-5 d-block">
    c.	Furthermore, if you want to publish Content that includes images of someone other than yourself, you must get permission from the individuals portrayed in that Content before using it in connection with the Services.

    </span>


</p>

<p className="pl-10 pt-0">

</p>

<p className="pl-10 pt-0">

</p>

<p className="pl-10 pt-0">
3.	Even though, as between you and vRekruit, you own all Content you post to the Services, you grant to vRekruit for purposes of providing the Services an irrevocable, nonexclusive, worldwide, royalty-free, sublicense able and transferable right and license to use, reproduce, modify, analyze, create derivative works of, distribute, publicly perform and publicly display your Content or Created Content on and through the Services, , in any media now existing or hereafter created. 
</p>

<p className="pl-10 pt-0">
4.	If you post Content on or through the Services, you represent and warrant to vRekruit that: 

<span className="pl-5 d-block">
a.	you understand and agree that, as between you and vRekruit, you are responsible for all Content posted to the Services; 

</span>

<span className="pl-5 d-block">
b.	you have the right to post that Content and to grant to vRekruit all rights granted herein and vRekruit’s use of the Content and/or Created Content will not violate or infringe the rights of any third party; 

</span>

<span className="pl-5 d-block">
c.	the Content is your own original work, or you own or have acquired, all necessary rights to post and disclose the Content;

</span>

<span className="pl-5 d-block">
d.	the Content is true, accurate and complete; 

</span>

<span className="pl-5 d-block">
e.	the Content is not unlawful, threatening, abusive, harassing, defamatory, libellous, deceptive, fraudulent, invasive of another's privacy, or impersonate another person; 

</span>

<span className="pl-5 d-block">
f.	the Content complies with all applicable laws (including without limitation applicable employment laws and human rights-based laws) and does not victimize, harass, degrade, or intimidate an individual or group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age, or disability; and 

</span>

<span className="pl-5 d-block">
g.	your disclosure of the Content does not violate a confidential relationship with any third party, establish a confidential relationship with vRekruit or any of its affiliates or suppliers or obligate vRekruit or any of its affiliates or suppliers to treat your Content as secret or confidential.


</span>


<h3 className=" pl-5 pt-5 pb-5text-style-2">5.	Rejection of Content:</h3>


<span className="pl-5 pt-5">
vRekruit has the right, but not the obligation, to remove or block any Content (including Objectionable Content) from the Services that it determines in its sole discretion to be in violation of these Terms of Service or that is detrimental to the quality or intended spirit of the Services, as determined by vRekruit in its sole discretion. vRekruit also has the right, but not the obligation, to limit or revoke the use privileges of the Account of anyone who posts such Content or engages in such behaviour.
</span>

</p>

<h1 className=" pl-5 pt-5 pb-5text-style-2">5.	INTELLETUAL PROPERTY OWNERSHIP</h1>
<p className="pl-10 pt-0">
1.	By signing this agreement, you irrevocably acknowledge that you have no ownership interest in the vRekruit materials. vRekruit shall own all right, title, and interest in such vRekruit materials, subject to any limitations associated with intellectual property rights of third parties. All the content may only be used solely as a part and for the duration of the service, in accordance with the terms of this agreement. vRekruit or its affiliates own all right, title and interest in any and all patent rights, copyrights, trademark rights and other rights in the site and service (including, but not limited to the content, reports, data generated by the site/services), as well as any improvements, design contributions or derivative works conceived or created by either party in or to the service, including the know-how, techniques, or procedures acquired or used by vRekruit in the performance of services hereunder. vRekruit reserves all rights not specifically granted herein. Licensee shall not remove notices and notations on the site or in the service that refer to copyrights, trademark rights, patent rights and other intellectual property rights.
</p>
<p className="pl-10 pt-0">
2.	To the extent that services to be provided herein involve vRekruit's use of your trademarks, service marks, trade names, or other intellectual property and content (the "licensee IP"), you hereby permit vRekruit to use licensee IP solely in connection with such listing. 
</p>

<h1 className=" pl-5 pt-5 pb-5text-style-2">
6.	Binding Nature 
</h1>
<p className="pl-5 pt-5">
1.	By accessing or using the site and/or the services, you agree to be bound by these terms of service. You are only authorized to access the site and/or to use the services (regardless of whether your access or use is intended). You agree to abide by all applicable laws. If you do not accept these terms of service in their entirety, you shall not use or access the site or the service.  
</p>
<h1 className=" pl-5 pt-5 pb-5text-style-2">
7. Refunds and Cancellation</h1>
<p className="pl-5 pt-5">
REFUNDS will not be provided for the credits purchased under any circumstances.
</p>
</section>




<section className="bg-info-1 pt-5">
<Container>
  <Row className="mt-20 mb-20 d-flex space-around">
    <Col sm={12} md={4} >
    <img className="img-responsive mb-5 " width="200" src="/media/logos/UPDATED_VRekruit _Logo.png"  />   
    <p className="text-left"> 
    There are many variations of 
    passages of Lorem Ipsum available, 
    <br/>
    but the majority have suffered alteration in some form
    </p>

    </Col>

    <Col sm={12} md={4} >
    <h3 className="text-left pt-3 fw-600"> 
       Contact Info
    </h3>
    {/* <p className="text-left pt-3"> 
    <img className="d-inline mr-3" width="25" alt="" src="/media/VRekruitIcons/address.svg" />

    Building No. 000, Industrial Area,
    <br/>
    <span className="ml-10">   Mumbai- 000000 </span>    
    </p> */}
    <p className="text-left pt-3"> 
    <img className="d-inline mr-3" width="25" alt="" src="/media/VRekruitIcons/email (1).svg" />
       admin@vrekruit.com
    </p>
    {/* <p className="text-left pt-3"> 
    <img className="d-inline mr-3" width="25" alt="" src="/media/VRekruitIcons/call (1).svg" />    
      +91 89968465798
    </p> */}

    </Col>

    <Col sm={12} md={4} >

    <h3 className="text-left pt-3 pb-3 fw-600"> 
      Follow us On
    </h3>


    <img className="d-inline mr-3 c-p" width="25" alt="" src="/media/VRekruitIcons/facebook-app-symbol.svg" />

    <img className="d-inline mr-3 c-p" width="25" alt="" src="/media/VRekruitIcons/google-plus-logo.svg" />

    <img className="d-inline mr-3 c-p" width="25" alt="" src="/media/VRekruitIcons/linkedin-blue.png" />
   
    <img className="d-inline mr-3 c-p" width="25" alt="" src="/media/VRekruitIcons/twitter-blue.png" />




    <h3 className="text-left pt-3 mt-4 pb-3 text-theme-green"> 
     Download Our Apps 
    </h3>
    <h3>
    <img className="img-responsive mb-5  mt-2 br-10 mr-5" width="150px" src="/media/VRekruitIcons/google.png"  />  
     <img className="img-responsive mb-5  br-10  mt-2" width="150px"  src="/media/VRekruitIcons/apple.png"  />  
    </h3>


    </Col>

  </Row>
</Container>

</section>

<Footer/>

</>         
           
  );
}
