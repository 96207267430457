/* eslint-disable no-restricted-imports */
/* jshint asi:true */

import React, { useState,useEffect } from "react";
import { Link,useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {  injectIntl } from "react-intl";
import * as auth from "../../modules/Auth/_redux/authRedux"
import Helmet from "react-helmet"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import alertService from "../../_services/alertservice";
import { logout } from "../../../actions/auth";
import { useDispatch } from 'react-redux';
import ReactFlagsSelect from 'react-flags-select';



function ViewUser(props) {
 /* const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [renderPage, setRenderPage]= useState(false); 
 // const [selectedRole,SetSelectedRole]= useState("");*/
  const history = useHistory();
  const[dialCode, setDialCode] = useState("");
  const[phone, setPhone] = useState("");
  const [roleName, setRoleName] = React.useState('');
  const [selectedRoleId, setInputValue] = React.useState('');
  
/*  const Roles = [
    { title: 'Recruiter', id: 4 },
    { title: 'Company Admin', id: 2 },
    { title: 'Hiring Manger', id: 3 },  
    { title: 'Super Admin', id: 1 },
  ]; 


  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

*/

  useEffect(() => {
  //  let mounted = true;
    // getUserProfile(); 
    getCompanyDetails();
  //  return () => mounted = false;
  }, [])


let {id} = useParams();
const[EmployeeDetails,SetEmployeeDetails]=useState();
  const getUserProfile = () =>{
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/get_user?id=`+ id, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
    .then((res) => res.json())
    .then((res) => {
      if(res.status===true){
        SetEmployeeDetails(res.data)
        setPhone(res.data.phone);
        setDialCode(res.data.dialCode);
        setInputValue(res.data.roleId)
        // initialValues.assignedTo = res.data.assignedTo;
        var roleId=  res.data.roleId;
       if(roleId){

        if(roleId === 1){
            setRoleName({ title: 'Super Admin', id: 1 })
           }  
          else if(roleId === 2){
            setRoleName({ title: 'Company Admin', id: 2 });
          }  
          else if(roleId === 3){
            setRoleName({ title: 'Hiring Manger', id: 3 });
          }  
          else if(roleId === 4){
            setRoleName({ title: 'Recruiter', id: 4 });
          }
        }              
       // setRenderPage(true)
      }
      
        else if(res.sessionExpired){
          logoutMethod();
          alertService.alert({ message :res.message, type : 'error'  });
        }

      
      else{
      }
    })
    .catch(() => {
 
    });
    }

    const dispatch = useDispatch();
    const logoutMethod = () => {
      dispatch(logout());
      history.push('/auth/signin');
  };



  const [companyDetails, setCompanyDetails] = useState([]);
  const getCompanyDetails = () => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setCompanyDetails(res.data);
        }
        else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
          history.push("/auth/signin")
        }
        })
      .catch(() => {
      });
  }


  if(localStorage.getItem("userTypeId") === "1")
  {
      
     return ""
    //  history.push("/error/error-v1")
  
  }
  else {


  return (
    <>
      <Helmet>
        <title> VRekruit | Pricing</title>
      </Helmet>

         {/* subeader */}
         <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600"> Payment Management </h3></div>
          </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
      {/* subeader */}
  
    <Row className="bg-white pt-4 sticky-header">
    <Col md={6} sm={12} offset= {4}  className="pr-3 pl-3">      
      <div>
       <div className="d-flex flex-grow-1 align-items-center  justify-content-start   rounded">     
        <div>
            <h5 className="font-weight-bold f-w-600 pl-5" >  View Pricing </h5>
        </div>
      </div>
      </div>
      </Col>
      <Col md={6} sm={12} offset= {4}  className="pr-3 pl-3">
      <div>
       <div className="d-flex flex-grow-1 align-items-center justify-content-end    rounded">
        <div className="text-muted d-block mt-1">

            {selectedRoleId  === 2 ? "" : (
              <Link to={"/payment/add"} type="submit" className="btn btn-theme-green btn-lg mr-5">
                  Buy Credits
             </Link>
             )
            } 
      </div>
      </div>
      </div>
      </Col>
      <div className="separator separator-solid mb-0 mt-3  col-md-12 col-sm-12" ></div>
      </Row>


      <Row className="bg-white ">
      <Col md={12} sm={12} offset={4} className="pt-2 pb-5 pl-5 pr-5" >
              <div className="bg--tile rounded">
              {/* <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                  <img className="d-inline grey-ico-30" alt="" width="25" src="/media/VRekruitIcons/building.svg" />
                </div>
                <div className="text-muted d-block">
                  <h6 className="text-dark"> Company Name</h6>
                  <p className="text-dark mt-0">
                  { companyDetails && companyDetails.companyName ? companyDetails.companyName  : ""}                    
                  </p>
                </div>
              </div>
                <div className="separator separator-solid bg-white" ></div> */}
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline grey-ico-30"  alt="role" width="25" src="/media/VRekruitIcons/country.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Country</h6>
                    <p className="text-dark mt-0 mb-0">
                    { companyDetails && companyDetails.companyCountry && companyDetails.companyCountry != 0 ?
                     <ReactFlagsSelect
                     style={{"position": "relative","left": "-16px !important"}}
                     selected = {'IN'}
                     disabled
                     className="view-country"
                     /> : ""}                     
                    </p>
                  </div>
                </div>
     
                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline filter-greyscale" width="25"  alt="" src="/media/VRekruitIcons/currency.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Currency</h6>
                    <p className="text-dark mt-0">
                      {/* { companyDetails && companyDetails.firstName ? companyDetails.firstName  : ""}
                      { EmployeeDetails && EmployeeDetails.lastName ? EmployeeDetails.lastName  : ""} */}
                      Rupees
                    </p>
                  </div>
                </div>

                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4" pl-4 >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25"  alt="email" src="/media/VRekruitIcons/cost-1.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Credit Pricing</h6>
                    {/* { EmployeeDetails && EmployeeDetails.email ? EmployeeDetails.email  : ""} */}
                  
                    <p className="text-dark">1 INR = 1 Credit</p>

                  </div>
                </div>


                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4" pl-4 >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25"  alt="call" src="/media/VRekruitIcons/credit.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Cost Per Interview</h6>
                     <p className="text-dark">100 Credits</p>
                  </div>
                </div>
                {/* <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4" pl-4 >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25"  alt="call" src="/media/VRekruitIcons/credit.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Cost Per Test</h6>
                    <p className="text-dark">50 credits</p>

                  </div>
                </div> */}
              </div>
            </Col>
      </Row>

      </>
      
  );
                    }
}

export default injectIntl(connect(null, auth.actions)(ViewUser));
