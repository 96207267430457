/* eslint-disable no-restricted-imports */
/* jshint asi:true */

import React, { useState,useEffect } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../modules/Auth/_redux/authRedux"
import Helmet from "react-helmet"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import alertService from "../_services/alertservice";
import { logout } from "../../actions/auth";
import { useDispatch } from 'react-redux';
import { useHistory,Link } from "react-router-dom";
import ReactPlayer from 'react-player'





// const { REACT_APP_TEST } = process.env;
// console.log(REACT_APP_TEST)
/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/
/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/



function ProductDemo(props) {
  const { intl } = props;

    const history = useHistory() 
    const dispatch = useDispatch();


  return (
    <>
      <Helmet>
        <title> VRekruit | Product Demo</title>
      </Helmet>

         {/* subeader */}
         <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Product Demo</h3></div>
          </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
      {/* subeader */}
   


    <Row className="pt-4 pb-4">
    
      <div className="container p-5" >

      <div
      className="video"
      style={{
        position: "relative",
        paddingBottom: "56.25%" /* 16:9 */,
        paddingTop: 25,
        height: 0
      }}
    >
      <iframe
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "80%"
        }}
        src={`https://www.youtube.com/embed/E0e1_i_RS8Y`}
        frameBorder="0"
      />
    </div>
           
            </div> 
      </Row>

      </>
      
  );
}

export default injectIntl(connect(null, auth.actions)(ProductDemo));
