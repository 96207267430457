/* eslint-disable no-restricted-imports */
/* jshint asi:true */

import React, { useEffect, useState } from 'react';
import Helmet from "react-helmet"
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormFile from 'react-bootstrap/FormFile'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'
import alertService from '../../_services/alertservice';
// alertService.alert({ message :"Hi", type : 'error'  });
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';



function AddNewParentCategory() {
  const [parentCategory, setParentCategory] = useState([])
  const [parentCategories, setParentCategories] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow1, setModalShow1] = React.useState(false);
  const [selectedId, setSelectedOption] = useState(true);


  const [state, setState] = useState({
    subCategoryName: "",
    parentCategory: ""
  });

  const AddSubCategoryData = () => {
    let subCategoryName = document.getElementById('subCategory').value;
    if (subCategoryName) {
      subCategory.push(subCategoryName)
      setSubCategory(subCategory.map(x => x));
      setModalShow1(false);
    }
  }


  const get_sub_categories = (parentId) => {
    if (parentId) {
      const token = localStorage.getItem('x-access-token');
      fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/test/sub_categories?page=1&limit=10&parentCategoryId=` + parentId, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'x-access-token': token
        },
        // body: JSON.stringify(values)
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status == true) {
            setSubCategories(res.data);

          }
          else {
            // alert(res.message);
          }
        })
        .catch(() => {
        });
    }
    else {
      setSubCategories([]);
    }
  }


  const get_parent_categories = (setDefault) => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/test/categories?page=1&limit=1000`, {

      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setParentCategories(res.data);
          if (setDefault)  {
            setSelectedParentCategory(res.data[0]);
            setSubCategories([]);
          }
        }
        else {
        }
      })
      .catch(() => {
      });
  }

  useEffect(() => {
    let mounted = true;
    get_parent_categories();
    return () => mounted = false;
  }, [])


  const [selectedParentCategory, setSelectedParentCategory] = useState("")

  const add_Category = (values) => {
    var CatName = document.getElementById("category").value;
    if (CatName) {
      const reqData = {
        "categoryName": CatName
      }
      const token = localStorage.getItem('x-access-token');

      fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/test/add_category`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'x-access-token': token

        },
        body: JSON.stringify(reqData)
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status == true) {
            alertService.alert({ message: res.message, type: 'success' });
            get_parent_categories(true);
            setModalShow(false);
            // setSelectedParentCategory(parentCategories[0]);
            var id = parentCategories[0].id
            setSelectedOption(id);
            SetcategorySelected(false);
          }
          else {
            alertService.alert({ message: res.message, type: 'error' });

            //  alert(res.message);
            setModalShow(false);
          }
        })
        .catch(() => {
        });
    }
    else {
      alertService.alert({ message: "Please Enter Parent Category Name First", type: 'warning' });
    }
  }

  const deleteSubCategory = (value) => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/test/delete_sub_category/` + value, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setModalShow3(false);
          alertService.alert({ message: res.message, type: 'error' });
          get_sub_categories(selectedId);

        }
        else {
          setModalShow3(false);
          alertService.alert({ message: res.message, type: 'error' });
        }
      })
      .catch(() => {
      });
  }

  const add_SubCategory = () => {
    var subCatName = document.getElementById("subcategoryvalue").value;
    if (subCatName && selectedId) {
      const reqData = {
        "subCategoryName": subCatName,
        "parentCategoryId": selectedId,
      }
      const token = localStorage.getItem('x-access-token');
      fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/test/add_sub_category`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'x-access-token': token

        },
        body: JSON.stringify(reqData)
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status == true) {
            alertService.alert({ message: res.message, type: 'success' });
            get_sub_categories(selectedId);
            document.getElementById("subcategoryvalue").value = "";
          }
          else {
            alertService.alert({ message: res.message, type: 'error' });
          }
        })
        .catch(() => {
        });
    }
    else {
      alertService.alert({ message: "Please Enter Sub Category Name First", type: 'warning' });
    }
  }

  useEffect(() => {
    let mounted = true;
    get_parent_categories();
    get_sub_categories();
    return () => mounted = false;
  }, [])

  const [categorySelected, SetcategorySelected] = useState(true);

  function handleSelectChange(value, i) {
    console.log(value)
    setSelectedParentCategory(value);
    if (value) {
      setSelectedOption(value.id);

      if (value.id) {
        SetcategorySelected(false);
      }
      else {
        SetcategorySelected(true);
      }
      document.getElementById("subcategoryvalue").value = ""
      var parentId = (value.id);
      get_sub_categories(value.id);

    }
  }


  function CategoryModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="d-flex justify-content-flex-end">
          <Modal.Title id="contained-modal-title-vcenter">
            <Button className="btn btn-theme text-white btn-lg mr-5 pl-4 pr-4 pt-3 pb-3" onClick={add_Category}> Save </Button>
            <Button className="btn btn-theme-green btn-lg mr-5 pl-4 pr-4 pt-3 pb-3 bg-theme-green" onClick={props.onHide}>Close</Button>  </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center d-flex">

            <div className="form-group row mb-0 mt-3">
              <label for="formPlaintextEmail" className="form-label col-form-label col-sm-3">Parent Category</label>
              <div className="col-sm-9"><div className="form-group col">
                <Form.Control size="lg" type="text" placeholder="" id="category" />
              </div>
              </div>
              <label for="formPlaintextEmail" className="text-theme-green form-label col-form-label col-sm-2">
              </label></div>
          </Modal.Title>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    );
  }


  function SubCategoryCModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Sub Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4> Click Add to Add  </h4>
          <p>
            <Form.Control size="lg" type="text" placeholder="Add SuB Category" id="subCategory" />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={AddSubCategoryData}> Add </Button>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }


  const [modalShow3, setModalShow3] = React.useState(false);


  function DeleteConfirmationModal(props) {
    return (
    <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
    
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            <p className="text-center">
            <img alt="logo" className="brand-dim" src="/media/logos/UPDATED_VRekruit _Logo.png" />
              </p>
            <p className="text-dark text-center pt-3"> Are you sure want to delete this sub category ?</p>
          </Modal.Title>
        </Modal.Body>
        <Modal.Title className="d-flex justify-content-center align-items-center pb-5" >
        <p className="text-center">

        <Button className="btn btn-theme text-white btn-lg mr-5  pl-5 pr-5 pt-3 pb-3" style={{"minWidth": "100px"}} onClick={() => deleteSubCategory(delId)}> Yes </Button>
       <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green" style={{"minWidth": "100px"}}  onClick={props.onHide}>No</Button> 
       </p>
        </Modal.Title>
      </Modal>
    );
  }


  const [delId, SetDeleteSubCategoryId] = useState("")
  const deleteSubCategoryModal = (value) => {
    setModalShow3(true);
    SetDeleteSubCategoryId(value);
  }


  // const []


  return <>
    <Helmet>
      <title> VRekruit | Add New Parent category</title>
    </Helmet>

    {/* subeader */}
    <div id="kt_subheader" className="subheader py-2 py-lg-4    subheader-solid">
      <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <div className="d-flex align-items-center flex-wrap mr-1">
          <div className="d-flex align-items-baseline mr-5">
            <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Category Management</h3></div>
        </div>
        <div className="d-flex align-items-center" />
      </div>
    </div>
    {/* subeader */}


    <Col className="bg-white" >
      <Row className="bg-white pt-4 pb-4 align-items-center">
        <Col md={6} sm={12} offset={4} className="pr-3 pl-3">
          <div>
            <div className="d-flex flex-grow-1 align-items-center  justify-content-start   rounded">
              <div>
                <h5 className="font-weight-bold f-w-600 pl-5" > Category Management &nbsp;&nbsp; {'>'} &nbsp;&nbsp; Add New Parent Category  </h5>
              </div>
            </div>
          </div>
        </Col>
        <Col md={6} sm={12} offset={4} className="pr-3 pl-3">
          <div>
            <div className="d-flex flex-grow-1 align-items-center justify-content-end rounded mt-1">
              <div className="text-muted d-block">

                <Link to="/view-category" type="button" className="btn btn-theme btn-lg mr-5  text-white">
                  Close
                </Link>
              </div>
            </div>
          </div>
        </Col>
        <Col md={12} sm={12} className="separator separator-solid mb-7 mt-5"></Col>
      </Row>

     <Col className="Content-custom-padding">
        <Form>
          <Row className="mt-5">
            <Form.Label column sm="2">
              Parent Category
            </Form.Label>
            <Col sm="7">
              <Form.Group className="autocomplete" as={Col} >
                {/* <Form.Control as="select" defaultValue="Select Parent Category" onChange={(e) => handleSelectChange(e)}>
                      <option value="">Select Parent Category</option>
                      {parentCategories.map
                      ( x=><option value={x.id}>{x.categoryName}</option>)
                      }
                      </Form.Control> */}

                <Autocomplete
                  size="small"
                  value={selectedParentCategory}
                  options={parentCategories}
                  onChange={(event, newValue) => {
                    handleSelectChange(newValue, event);
                  }}
                  getOptionLabel={(options) => options.categoryName}
                  renderInput={(params) => <TextField {...params} label="Select Parent Category" variant="outlined" />}
                />
              </Form.Group>
            </Col>
            <Form.Label column sm="2" className="text-theme-green">
              <span className="c-p">
                <img src="/media/VRekruitIcons/addmore.svg" className="c-p" width="24" onClick={() => setModalShow(true)} />
                {/* <i className="fa fa-plus-circle text-theme-green mr-3 c-p" onClick={() => setModalShow(true)}></i> */}
              </span>
            </Form.Label>
          </Row>
          <Row  style={{ paddingBottom: "200px" }}>
            <Form.Label column sm="2">
              Sub Category
            </Form.Label>
            <Col sm="7">
              <Form.Group as={Col} >
                <Form.Control placeholder="Sub Category" id="subcategoryvalue" disabled={categorySelected} />
              </Form.Group>
            </Col>
            <Form.Label column sm="2" className="text-theme-green">
              <span className="">


                {/* public\media\VRekruitIcons\addmore.svg */}
                <img src="/media/VRekruitIcons/addmore.svg" className={`c-p ${categorySelected ? "p-none no-drop" : ""}`} width="24" onClick={add_SubCategory} />
                {/* <i  className="fa fa-plus-circle text-theme-green mr-3 c-p" ></i> */}
                {/* Add Sub Category */}
              </span>
            </Form.Label>
            {subCategories.map
              (x =>
                <>
                  <Form.Label column sm="2">
                  </Form.Label>
                  <Col sm="7">
                    <Form.Group as={Col} >
                      <Form.Control value={x.subCategoryName} readOnly />
                    </Form.Group>
                  </Col>
                  <Form.Label column sm="2" className="text-theme-green">
                    <span className="c-p">
                      <img src="/media/VRekruitIcons/delete.svg" className="c-p" width="24"
                        onClick={() => deleteSubCategoryModal(x.id)}


                      />
                      {/* <i className="fa fa-trash text-dannger mr-3" onClick={() => deleteSubCategory(x.id)}></i>  */}
                      {/* <img src="/media/VRekruitIcons/delete.svg" className="c-p" width="24"    onClick={() => setModalShow(true)}  onClick={() => deleteSubCategory(x.id)} />   */}

                    </span>
                  </Form.Label>
                </>)}
          </Row>
          <CategoryModal show={modalShow} onHide={() => setModalShow(false)} />
          <SubCategoryCModal show={modalShow1} onHide={() => setModalShow1(false)} />
          <DeleteConfirmationModal show={modalShow3} onHide={() => setModalShow3(false)} />
        </Form>
      </Col>
    </Col>
  </>;
}

export default AddNewParentCategory;