/* eslint-disable no-restricted-imports */
/* jshint asi:true */

import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import alertService from "./alertservice"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));



 function Notifications() {
  const subscription = useState("");
  const historyUnlisten = useState("");  
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [alertMessage,setAlertMessage] = useState("")
  const[alertType,setAlertType]= useState("");

  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });


  const { vertical, horizontal } = state;

  const handleClick = (x) => {
    setAlertMessage(x.message)
    setAlertType(x.type ?  x.type : "info")
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };



  useEffect(() => {
    let mounted = true;
    var subscriptions = alertService.onAlert()
    .subscribe(alert => {
        // clear alerts when an empty alert is received
            // filter out alerts without 'keepAfterRouteChange' flag
            handleClick(alert);
    });

// clear alerts on location change
// historyUnlisten = history.listen(() => {
//     alertService.clear(this.props.id);
// });



    return () => mounted = false;
  }, [])

  

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }} 
        open={open} 
        autoHideDuration={5000} 
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={alertType}>
          {alertMessage}
        </Alert>
      </Snackbar>
      {/* <Alert severity="error">This is an error message!</Alert>
      <Alert severity="warning">This is a warning message!</Alert>
      <Alert severity="info">This is an information message!</Alert>
      <Alert severity="success">This is a success message!</Alert> */}
    </div>
  );
}

export default Notifications;