/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React, { useEffect, useState } from 'react';
import Helmet from "react-helmet"
import PropTypes from 'prop-types';
import { useForm, Controller } from "react-hook-form";
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useHistory,Link } from "react-router-dom";
import { setStorage } from './../../../../_metronic/_helpers/LocalStorageHelpers';
import alertService from '../../../_services/alertservice';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import TextField from '@material-ui/core/TextField';

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import { useFormik } from "formik";
import { useDispatch } from 'react-redux';
import { logout } from '../../../../actions/auth';
import Form from 'react-bootstrap/Form'



//https://stackoverflow.com/questions/61655199/proper-way-to-use-react-hook-form-controller-with-material-ui-autocomplete

const initialValues = {
  category:"",
  subcategory:"",
};




const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing { from } to { to } of { size } Results
    </span>
  );
 
 const selectRow = {
    mode: 'radio',
    clickToSelect: true
  };
  function TabContainer({ children, dir }) {
    return (
      <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
        {children}
      </Typography>
    );
  }
  
  TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired,
  };
  
  const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      
    },
  }));







function InterviewViewFitment() {

  const { register, handleSubmit, control } = useForm();



  var moduleid;

  const [requistions, setRequistions] = useState([]);
  const [subCategoryList, setsubCategoryList] = useState([]);
  const [parentCategoryName, setCategoryName] = useState("")
  const [staticData, setStaticData] = useState();



  var i = 1;

  // get categoryList
  const get_Fitment_List = () => {
    const token = localStorage.getItem('x-access-token');
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/interview/fitments?page=1&limit=2000`;


    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setRequistions(res.data);
          setStaticData(staticData + 10);
        }else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
        else {
        }
      })
      .catch(() => {
      });
  }
  //get categoryList




const deleteParentCategory = (delParentId) => {
  const token = localStorage.getItem('x-access-token');
  fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/delete_fitment/` + delParentId, {
    
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      'x-access-token': token
    },
  })
  .then((res) => res.json())
  .then((res) => {
    if(res.status==true){
   setModalShow4(false);
   alertService.alert({ message : res.message, type : 'success'  });
   get_Fitment_List()
  }else if(res.sessionExpired){
    alertService.alert({ message :res.message, type : 'error'  });
    logoutMethod();
  }
    else{
     setModalShow4(false);
      alertService.alert({ message : res.message, type : 'error'  });

    }
  })
  .catch(() => {          
  });
}



const UpdateFitment = () => {
    var textdata = document.getElementById("category-text").value;
  //  var updatedExpectedScore = document.getElementById("roles-titles").value;
    if (textdata && delParentId  ) {
      const reqData = {
          "parameterName": textdata,
          "expectedScore": 5
      }
      const token = localStorage.getItem('x-access-token');
      fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/update_fitment/`+delParentId, {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          'x-access-token': token


        },
        body: JSON.stringify(reqData)
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status == true) {
            get_Fitment_List()
            alertService.alert({ message: res.message, type: 'success' });
            // get_sub_categories(selectedId);
            setModalShow6(false)
          }else if(res.sessionExpired){
            alertService.alert({ message :res.message, type : 'error'  });
            logoutMethod();
          }
          else {
            alertService.alert({ message: res.message, type: 'error' });
          }
        })
        .catch(() => {
        });
    }
    else {
      alertService.alert({ message: "Please Enter Parent Category Name", type: 'warning' });
    }

}

const ExpectedScores = ['1', '2', '3', '4', '5']


  useEffect(() => {
    let mounted = true;
    get_Fitment_List();
    return () => mounted = false;
  }, [])



  function actionFormatter(column, colIndex) {
    return (
      <div className="text-left">
        <img src="/media/VRekruitIcons/edit.svg" className="edit-option sort-icon ml-3 c-p" width="30" height="30" title="Edit" alt="Edit"  />
          <img src="/media/VRekruitIcons/delete.svg" className="delete-option sort-icon ml-3 c-p" width="30" height="30" title="Delete" alt="Delete"  />
      </div>
    );
  }

  function headerformatter(column, colIndex) {
    return (
      <div>
        {column.text}       
      <img src="/media/VRekruitIcons/sort.svg" className="sort-icon c-p" width="30" height="30" />
      </div>
    );
  }




  function headerformatter(column, colIndex) {
    return (
      <div>
        {column.text}   
          <img src="/media/VRekruitIcons/sort.svg" className="sort-icon c-p" width="30" height="30" />
          

      </div>
    );
  }

  function headerformatterAction(column, colIndex) {
    return (
      <div>
        {column.text}   
          
      </div>
    );
  }


  function headerformatterAction(column, colIndex) {
    return (
      <div>
        {column.text}   
          
      </div>
    );
  }

  

  function  CreatedByUserFormater(column, colIndex) {
    return(
      <span>
        {column.firstName}  {column.lastName} 
      </span>
    );
  }



  // parent Category columns
 var columns = [{
    dataField: 'parameterName',
    text: "Parameter NAME",
    sort: true,
    headerFormatter: headerformatter
 },
 /* {
    dataField: 'expectedScore',
    text: "Expected Score",
    sort: true,
    headerFormatter: headerformatter
}, */

  {
    dataField: 'createdByUser',
    text: "Created By",
    sort: true,
    formatter:CreatedByUserFormater,
    headerFormatter: headerformatter
  },
  {
    dataField: 'updatedAt' ? 'updatedAt' :"" ,
    text: "MODIFIED ON",
    sort: true,
    headerFormatter: headerformatter,
    formatter: (cell) => {
    let dateObj = cell;
    if (typeof cell !== 'object') {
      dateObj = new Date(cell);
    }
    return `${('0' + dateObj.getUTCDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${dateObj.getUTCFullYear()}`;
  }
  },


  {
    dataField: "action",
    text: "Action",
    sort: true,
    formatter: actionFormatter,
    headerFormatter: headerformatterAction
  },
  ];



//sub categories

  const options = {
    paginationSize: 10,
    // custom: true,
    pageSize: 20,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    //  withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    onPageChange: (page, sizePerPage) => {
     
    },
    disablePageTitle: true,
    sizePerPageList: [ {
      text: '20', value: 20
    },{
    text: '50', value: 50
    },
    {
      text: 'All', value: requistions.length
    }] // A numeric array is also available. the purpose of above example is custom the text
  };

  const actionControls = {
    onClick: (e, row, rowIndex) => {
   if(row) {
       let id = row.id;
       if (e.target.classList.contains("view-option")) {
        SetParentCategoryId(id);

      }
    }


    if (e.target.classList.contains("delete-option")) {
      let id= row.id;
      SetModuleId(id);
      moduleid = id;
      SetDeleteParentCategoryId(id)
      CanDeleteParentCategory(id);
    }

    if (e.target.classList.contains("edit-option")) {
        canEditCategory();
        let id= row.id;
        
        setEditFitmentParameter(row.parameterName)
        SetExpectedScore(row.expectedScore.toString())
        console.log(row.expectedScore)
        moduleid = id;
        SetDeleteParentCategoryId(id)
        SetModuleId(id);
        setModalShow6(true)

      }
  
  }
  };


    const [editFitmentParameter, setEditFitmentParameter] = useState();
    const [expectedScore, SetExpectedScore] = useState(0);



  const setCategorytext = () => {

    setTimeout(function(){ 
        document.getElementById("category-text").value = editFitmentParameter;
      //  document.getElementById("roles-titles").value = expectedScore;
    }, 130);

  }

    const handleSelectChange = (value, e) => {
        if (value) {
            SetExpectedScore(value);
        }
        return false;
    }




 

  const [subCatName,setSubCatName]= useState();


  const [modalShow3, setModalShow3] = React.useState(false);
  const [modalShow4, setModalShow4] = React.useState(false);
  const [delId, SetDeleteSubCategoryId   ] =useState();
  const [delParentId, SetDeleteParentCategoryId   ] =useState();
  const [parentCategoryId, SetParentCategoryId   ] =useState("");

  
  function DeleteCategoryConfirmationModal(props) {
    return (
          <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          >

          <Modal.Body>
            <Modal.Title id="contained-modal-title-vcenter text-center">
              <p className="text-center">
              <img alt="logo" className="brand-dim" src="/media/logos/UPDATED_VRekruit _Logo.png" />
                </p>
              <p className="text-dark text-center pt-3"> Are you sure want to delete this fitment parameter ?</p>
            </Modal.Title>
          </Modal.Body>
          <Modal.Title className="d-flex justify-content-center align-items-center pb-5" >
          <p className="text-center">

          <Button className="btn btn-theme text-white btn-lg mr-5  pl-5 pr-5 pt-3 pb-3" style={{"minWidth": "100px"}} onClick={() => deleteParentCategory(delParentId)}> Yes </Button>
          <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green" style={{"minWidth": "100px"}}  onClick={props.onHide}>No</Button> 
          </p>
          </Modal.Title>
          </Modal>
    );
  }

  const [catdata,SetCatData] = useState(
    {
      category:"",
      subcategory:"",
    }

  );


 

  const [modalShow6, setModalShow6] = React.useState(false);

  function FitmentModal(props) {
    return (
      <Modal
      show={modalShow6} 
       onShow={setCategorytext}  
        onHide={() => setModalShow6(false)} 
        onClick={event => event.stopPropagation()}
         onMouseDown={event => event.stopPropagation()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="d-flex justify-content-flex-end">
          <Modal.Title id="contained-modal-title-vcenter">
            <Button className="btn btn-theme text-white btn-lg mr-5 pl-4 pr-4 pt-3 pb-3"   onClick={UpdateFitment}> Save </Button>
            <Button className="btn btn-theme-green btn-lg mr-5 pl-4 pr-4 pt-3 pb-3 bg-theme-green" onClick={props.onHide}>Close</Button>  </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center d-flex">

            <div className="form-group row mb-0 mt-3">
              <label  className="form-label col-form-label col-sm-3">Parameter Name </label>
              <div className="col-sm-9"><div className="form-group col">
                <Form.Control size="lg" type="text" placeholder="" name="category"   id="category-text" />

              </div>
              </div>
              <label  className="text-theme-green form-label col-form-label col-sm-2">
               </label></div>
                { /*    <div className="form-group row mb-0 mt-3">
                        <label className="form-label col-form-label col-sm-3">Expected Score </label>
                        <div className="col-sm-9"><div className="">
                            <Form.Group className="autocomplete expectedScoreClass" as={Col} >
                            
                            <ExpectedScoreTag  label="Standard" nested={true} />
                                

                            </Form.Group>
                        </div>
                        </div>
                        <label className="text-theme-green form-label col-form-label col-sm-2">
    </label></div> */ }
          </Modal.Title>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    );
  }

  const ExpectedScoreTag = function ({nested}) {
    return (
      <Autocomplete size="small"
              id="roles-titles"
              options={ExpectedScores}
              renderInput={(params) => (
                  <TextField {...params} label="Expected Score" margin="normal" variant="outlined" id ="expected-score-id"/>
              )}
              defaultValue={expectedScore}
              getOptionLabel={
                  (options) => options}

              renderTags={(value,getTagProps) =>
              value.map((option,index) => <FitmentModal />)}
            /*  onChange={(event, newValue) => {
                  handleSelectChange(newValue, event);
              }} */
      />
    );
  }


  const handleCategoryChange = (e) => {
    SetCatData({
      category: e.target.value,
    })
  }


  const  onSubCategorytextChange = (e) => {
    SetCatData({
      subcategory: e.target.value,
    })
  }

const[ModuleId, SetModuleId]= useState();



const history = useHistory() 
const dispatch = useDispatch();
const logoutMethod = () => {
  dispatch(logout());
  history.push('/auth/signin');
};



  const canEditCategory = () => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/edit_delete_premission?module=interview_fitment&moduleId=` + moduleid, {
      method: 'Get',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          if(res.data.canEdit){
            setModalShow6(true);

          }
          else if(res.data.editMessage)
            alertService.alert({ message: res.data.editMessage, type: 'error' });
          }
          else if(res.sessionExpired){
            setModalShow6(false);
            logoutMethod();
            alertService.alert({ message :res.message, type : 'error'  });
          }
    
        else {
          alertService.alert({ message: res.message, type: 'error' });

        }
      })
    
  }




  const canEditSubCategory = () => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/edit_delete_premission?module=interview_fitment&moduleId=` + moduleid, {
      method: 'Get',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          if(res.data.canEdit){
            setModalShow5(true);
          }
          else if(res.data.editMessage)
            alertService.alert({ message: res.data.editMessage, type: 'error' });
          }
          else if(res.sessionExpired){
            setModalShow5(false);
            logoutMethod();
            alertService.alert({ message :res.message, type : 'error'  });
          }
        else {
          alertService.alert({ message: res.message, type: 'error' });

        }
      })
    
  }


  const CanDeleteParentCategory = (id) => {

    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/edit_delete_premission?module=interview_fitment&moduleId=` + moduleid, {
      method: 'Get',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          if(res.data.canDelete) {
            setModalShow4(true);
          }
          if(res.data.deleteMessage)
            {
             alertService.alert({ message: res.data.deleteMessage, type: 'error' });
            }
           
           else if(res.sessionExpired){
            setModalShow4(false);
            alertService.alert({ message :res.message, type : 'error'  });
            logoutMethod();

          }
        }else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
        else {
          alertService.alert({ message: res.message, type: 'error' });
          setModalShow4(false);

        }
      })
  }


  const CanDeleteSubCategory = () => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/edit_delete_premission?module=interview_fitment&moduleId=` + moduleid, {
      method: 'Get',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
        
          if(res.data.canDelete) {
            setModalShow3(true);
          }
          if(res.data.deleteMessage)
            {
             alertService.alert({ message: res.data.deleteMessage, type: 'error' });
            }
           
           else if(res.sessionExpired){
            setModalShow3(true);
            alertService.alert({ message :res.message, type : 'error'  });
            logoutMethod();

          }
        }else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
        else {
          alertService.alert({ message: res.message, type: 'error' });
          setModalShow4(false);

        }
      })

  }


  const [modalShow5, setModalShow5] = React.useState(false);





  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }

    return <>
        <Helmet>
            <title> VRekruit | View Fitment Parameters</title>
       </Helmet>


        {/* subeader */}
        <div id="kt_subheader" className="subheader py-2 py-lg-4    subheader-solid">
      <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <div className="d-flex align-items-center flex-wrap mr-1">
          <div className="d-flex align-items-baseline mr-5">
            <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Interview Management</h3></div>
            </div>
        <div className="d-flex align-items-center" />
      </div>
    </div>
  {/* subeader */}
  <Col className="bg-white" >


  <Row className="bg-white pt-4 mb-0 align-items-center" style={{"width":"100%"}}>
    <Col md={8} sm={12} offset= {4}  className="pr-3 pl-3">      
      <div>
       <div className="d-flex flex-grow-1 align-items-center  justify-content-start   rounded">     
        <div>
        <h5 className="font-weight-bold f-w-600 pl-5" > Assessment Management &nbsp;&nbsp; {'>'} &nbsp;&nbsp; View Fitment Parameters  
         
        </h5>
        </div>
      </div>
      </div>
      </Col>
      <Col md={4} sm={12} offset= {4}  className="pr-3 pl-3">
      <div>
       <div className="d-flex flex-grow-1 align-items-center justify-content-end rounded mt-1">
        <div className="text-muted d-block">
           <Link to="/interview/add-fitment" className="btn btn-theme-green btn-lg mr-5"  >
            
              Create Fitment Parameter  

           </Link>
      </div>
      </div>
      </div>
      </Col>
     </Row>

     <Row className="bg-white pt-10 mb-0 align-items-center sticky-header" style={{"width":"100%"}}>
      <Col md={12} sm={12} className="separator separator-solid mb-0 "></Col>  
     {/* <switch> */}
            </Row>
      
      

            
            {requistions  && requistions.length > 20 && (
              <BootstrapTable className="bg-white" keyField='id' data={requistions} columns={columns} options={options} pagination={paginationFactory(options)} rowEvents={actionControls} />

            )}

            {requistions  && requistions.length <= 20 &&
            (
              <BootstrapTable className="bg-white" keyField='id' data={requistions} columns={columns} options={options}  rowEvents={actionControls} />
            )
            }

          
       

    


   
   </Col>

    <DeleteCategoryConfirmationModal show={modalShow4}onHide={() => setModalShow4(false)}/>

    <FitmentModal show={modalShow6}  onShow={setCategorytext}   onHide={() => setModalShow6(false)} />

     
    </>;
}

export default InterviewViewFitment