/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormFile from 'react-bootstrap/FormFile'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Table from 'react-bootstrap/Table'
import { divide } from 'lodash';
import Helmet from 'react-helmet'
import { useParams, Link, useHistory } from "react-router-dom";
import { Redirect } from 'react-router';
import alertService from '../../../_services/alertservice';
import { Button } from 'react-bootstrap';
import { CALL_HISTORY_METHOD } from 'react-router-redux';
import Slider from '@material-ui/core/Slider';
import Modal from 'react-bootstrap/Modal'
import { useDispatch } from 'react-redux';
import { logout } from '../../../../actions/auth';





const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const initialValues = {
  status: "open",
  page: 1,
  limit: 10,
  assignedToMe: false,
  createdByMe: "0",
  orderBy: "Id",
  orderType: "ASC"
}

function ManageInterview() {
  

 
   const [QuestionsList, setQuestionsList] = useState([]);
   const [staticData, setStaticData] = useState(10);
   const [ parentCategories, setParentCategories ] = useState([]); 
   const [searchTerm, SetSearchTerm] = useState("");
   const [createdbyme, SetCreatedbyme] = useState(false);
   const [assignedbyme, SetAssignedbyme] = useState(false);
   const [status, SetStatus] = useState("");
   const [selectedId,setSelectedOption] = useState(true);
   const [ subCategory, setSubCategory ] = useState([]);
   const [ subCategories, setSubCategories ] = useState([]);  
   const [categorySelected, SetcategorySelected] = useState("");
   const [subcategorySelected, SetSubcategorySelected] = useState("");
   const [difficultyLevel, setDifficultyLevel ] = useState("");
   const [questionType, setQuestionType ] = useState("")


   function valuetext(value) {
    return `${value}`;
  }








// filter option 
const SetSearchTermData = (x) => {
  let data =  x.target.value;
 if(data){
  SetSearchTerm(x.target.value);
 }
 else{
  SetSearchTerm("");
 }
} 

function handleCategorySelect(event) {
  if(event.target.value){
    SetcategorySelected(event.target.value);  
  }
  else{
    SetcategorySelected("");
  }          
  // document.getElementById("subcategoryvalue").value= ""    
  var parentId = (event.target.value);
}

function handleSubCategorySelect(event) {
  if(event.target.value){
    SetSubcategorySelected(event.target.value);
  }
  else{
    SetSubcategorySelected("");
  }          
 
}


function handleDifficultyLevelSelect(event) {
  if(event.target.value){
    setDifficultyLevel(event.target.value);
  }
  else {
    setDifficultyLevel("");

  }
          
}








function handleQuestionTypeSelect(event) {
  if(event.target.value){
    setQuestionType(event.target.value);
  }
  else {
    setQuestionType("");

  }
          
}

const SetAssignedbymeData = (x) => {
  SetAssignedbyme(x.target.checked);
}

const SetCreatedbymeData = (x) => {
  SetCreatedbyme(x.target.checked);
}


// filter option 


  var i = 1;
  const[loadingList,SetLoadingList]=useState(false);
  const get_Test_List = () => {
 
    const token = localStorage.getItem('x-access-token');
    SetLoadingList(true);
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/interview/get_list?page=1&limit=1000&orderType=DESC`;
   //created by filter
    if(categorySelected) {
      url = url + "&createdBy=" + categorySelected;
    }

    if(searchTerm) {
      url = url + "&searchBy=" + searchTerm;
    }

    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setQuestionsList(res.data);
          setStaticData(staticData + 10);
          SetLoadingList(false);

        } else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
          SetLoadingList(false);
        }
        else {
          SetLoadingList(false);
        }
      })
      .catch(() => {
        SetLoadingList(false);
      });
  }




  const get_List_without_filters = () => {
    SetSearchTerm("");
    SetcategorySelected("");
    const token = localStorage.getItem('x-access-token');
    SetLoadingList(true);
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/interview/get_list?page=1&limit=1000&orderType=DESC`;
    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setQuestionsList(res.data);
          setStaticData(staticData + 10);
        } else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
          SetLoadingList(false);

        }
        else {
          SetLoadingList(false);

        }
      })
      .catch(() => {
        SetLoadingList(false);

      });
  }

  const get_Created_By_List = () => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/users_list`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setParentCategories(res.data);
        } else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
        else {
        }
      })
      .catch(() => {
      });
  }


  useEffect(() => {
    let mounted = true;
    get_Test_List();
    get_Created_By_List();
    return () => mounted = false;
  }, [])

  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();
  const logoutMethod = () => {
    dispatch(logout());
    history.push('/auth/signin');
};

  const history = useHistory();


  const [testId, SetTestId] = useState();

  const actionControls = {
    onClick: (e, row, rowIndex) => {
   if(row) {
       let id = row.id;
       if (e.target.classList.contains("view-option")) {
        history.push("/interview/View-interview/"+id)
      }

      if (e.target.classList.contains("delete-option")) {
      CanDeleteInterview(id);
      SetTestId(id)
      }


      if (e.target.classList.contains("edit-option")) {
        SetTestId(id)
        canEditInterview(id);
        }

        if (e.target.classList.contains("share-option")) {
          SetTestId(id)
          history.push("/interview/share-interview/"+id)
          }



    }
  }
  };

  function parseToHHMMSS(sec) {
    var sec_num = parseInt(sec, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);
  
    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
           return(hours === "00" ? "" :  hours +' hour ')
          + ( minutes === "00" ? "" :  minutes === "01" ? minutes  + ' minute ' : minutes  +' minutes ' )
          + ( seconds === "00" ? "" :  seconds === "01" ? seconds  + ' second ' : seconds  +' seconds ' )
    }

  function actionFormatter(column, colIndex) {
    return (
      <div className="text-left d-flex">
        <img src="/media/VRekruitIcons/eyeorview.svg"  className="view-option c-p view-eye-ico-1" title="View" alt="View" />

        <img src="/media/VRekruitIcons/edit.svg" className="edit-option c-p view-eye-ico ml-5" title="Edit" alt="Edit"  />


        <img src="/media/VRekruitIcons/delete.svg" className="delete-option c-p view-eye-ico ml-5" title="Delete" alt="Delete"  />


     {localStorage.getItem("userTypeId") !== "1"  ? 
        <img src="/media/VRekruitIcons/share.svg" className="share-option c-p view-eye-ico ml-5" title="Share" alt="Share"  />
     :"" }



      </div>
    );
  }


  function  CreatedByUserFormater(column, colIndex) {
    return(
      <span>
        {column.firstName}  {column.lastName} 
      </span>
    );
  }


  function headerformatter(column, colIndex) {
    return (
      <div>
        {column.text}
        <img src="/media/VRekruitIcons/sort.svg" className="sort-icon c-p" width="30" height="30" />
      </div>
    );
  }




  function headerformatterAction(column, colIndex) {
    return (
      <div>
         Action
      </div>
    );
  }



  const get_sub_categories = (parentId) =>{
    if(parentId){
      const token = localStorage.getItem('x-access-token');
      fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/test/sub_categories?page=1&limit=10&parentCategoryId=` + parentId, {        
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'x-access-token': token
        },
        // body: JSON.stringify(values)
      })
      .then((res) => res.json())
      .then((res) => {
        if(res.status==true){
          setSubCategories(res.data);
   
        } else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
       
      })
      .catch(() => {
      });
    }
    else {
      setSubCategories([]);
    }
  }


  

const [isSelected, SelectedQuestion] = useState(false);


const [formData, SetFormData] = useState(
  {
    testTitle:"",   
    questions:[ ],
  }
)
 
const setSelectedValue = (value) => {
  if(formData.questions.find(x => x.questionId == value)) {
    var deleted = formData.questions.splice(formData.questions.findIndex(x=> x.questionId == value) ,1)
  } else {
    formData.questions.push({questionId: value});
  }
  SetFormData({
    ...formData,
    questions: formData.questions
  })
}


function TimetoAnsweFormatter(column, colIndex) {
  var parsedtime = parseToHHMMSS(column) 
  return (      
    <>
       <span style={{"maxWidth":"100px"}}> 
       {parsedtime}
     {/* {column +" "}  Seconds */ }
      </span>
      </>
  );
}

    




 var columns = [  
  {
    dataField: 'interviewTitle',
    text: "INTERVIEW NAME",
    sort: true,
    headerFormatter: headerformatter
  },
  
  
  
  {
    dataField: 'questionCount',
    text: "No. of Questions",
    sort: true,
    headerFormatter: headerformatter
  },


  {
    dataField: 'createdByUser'  ,
    text: "Created By",
    sort: true,
    formatter: CreatedByUserFormater,
    headerFormatter: headerformatter
  },
  {
    dataField: 'totalDuration',
    text: "Total Answer Time",
    sort: true,
    formatter: TimetoAnsweFormatter,
    headerFormatter: headerformatter
  },

  {
    dataField: "action",
    text: "Action",
    sort: true,
    formatter: actionFormatter,
    headerFormatter: headerformatterAction
  },
  ];


  const options = {
    paginationSize: 5,
    // custom: true,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    //  withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    

    onPageChange: (page, sizePerPage) => {

    },
    disablePageTitle: true,
    sizePerPageList: [ {
      text: '20', value: 20
    },{
    text: '50', value: 50
    },
    {
      text: 'All', value: QuestionsList.length
    }] // A numeric array is also available. the purpose of above example is custom the text
    
  };


  const [valueMarks, setMarksValue] = React.useState([25, 75]);
  const [valueQuestionsCount, setQuestionCountValue] = React.useState([25, 75]);

  const handleChangeMarks = (event, newValue) => {
    setMarksValue(newValue);
  };

  const handleChangeQuestionCount = (event, newValue) => {
    setQuestionCountValue(newValue);
  };


  const [modalShow, setModalShow] = React.useState(false);


  function DeleteConfirmationModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            <p className="text-center">
            <img alt="logo" className="brand-dim" src="/media/logos/UPDATED_VRekruit _Logo.png" />
              </p>
            <p className="text-dark text-center pt-3"> Are you sure want to delete this Interview?</p>
          </Modal.Title>
        </Modal.Body>
        <Modal.Title className="d-flex justify-content-center align-items-center pb-5" >
        <p className="text-center">

        <Button className="btn btn-theme text-white btn-lg mr-5  pl-5 pr-5 pt-3 pb-3" style={{"minWidth": "100px"}} onClick={DeleteListItem}> Yes </Button>
       <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green" style={{"minWidth": "100px"}}  onClick={props.onHide}>No</Button> 
       </p>
        </Modal.Title>
      </Modal>
    );
  }

  const DeleteListItem = () => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/delete/` + testId , {
      method: 'delete',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          alertService.alert({ message: res.message, type: 'success' });
          setModalShow(false);
          get_Test_List();
        } else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
        else {
          alertService.alert({ message: res.message, type: 'error' });
          setModalShow(false);
        }
      })
  }


  const canEditInterview = (value) => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/edit_delete_premission?module=interview&moduleId=` + value, {
      method: 'Get',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          if(res.data.canEdit){
            // setModalShow6(true);
            history.push("/interview/edit-interview/"+value);
          }
          else if(res.data.editMessage)
            alertService.alert({ message: res.data.editMessage, type: 'error' });
          }
          else if(res.sessionExpired){
            // setModalShow6(false);
            logoutMethod();
            alertService.alert({ message :res.message, type : 'error'  });
          }
    
        else {
          alertService.alert({ message: res.message, type: 'error' });

        }
      })    
  }

  const CanDeleteInterview = (value) => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/edit_delete_premission?module=interview&moduleId=` + value, {
      method: 'Get',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === true) {
          if(res.data.canDelete) {
            // setModalShow3(true);
            setModalShow(true);
          }
          if(res.data.deleteMessage)
            {
              setModalShow(false);
             alertService.alert({ message: res.data.deleteMessage, type: 'error' });
            }
           else if(res.sessionExpired){
            setModalShow(false);
            alertService.alert({ message :res.message, type : 'error'  });
            logoutMethod();
          }
        }else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
        else {
          alertService.alert({ message: res.message, type: 'error' });
          setModalShow(false);
        }
      })
  }


  return (
    <>
      <Helmet>
        <title>
           Manage Interview
        </title>
      </Helmet>

      {/* subeader */}
      <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Interview Management</h3></div>
          </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
      {/* subeader */}

    <Col className={classes.root} >   
    <Row className="bg-white  d-flex flex-grow-1 align-items-center  justify-content-start sticky-header">
    <Col md={6} sm={6} offset= {4}  className="pr-3 pl-3 mt-3">      
      <div>
       <div className="   rounded">     
        <div>
        <h5 className="font-weight-bold f-w-600 pl-5" > Interview Bank  </h5>
        <p className="text-b f-w-600 pl-5 ">
                    {/* <img width="24" src="/media/VRekruitIcons/costblue.svg" alt="" class="flaticon2-profile text-success bg-cost mr-3"/> */}
                    Credit Balance: V {localStorage?.getItem("companyCreditBalance")   }</p>
        </div>
      </div>
      </div>
      </Col>
      <Col md={6} sm={6} offset= {4}  className="pr-3 pl-3 mt-3">
      <div>
       <div className="d-flex flex-grow-1 align-items-center justify-content-end rounded mt-1">
        <div className="text-muted d-block">
           <Link  to="/interview/create-interview" className="btn btn-theme-green btn-lg mr-5 mt-2 mb-0"  >
              Create Interview 
           </Link>
      </div>
      </div>
      </div>
      </Col>
      <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5"></Col>  
      </Row>

      <Row className="mt-5 mb-sm-5">



       <Col sm={4} md={3} className="mb-2">
            <Form.Control as="select" placeholder="Status" value={categorySelected} onChange={(e) => handleCategorySelect(e)} >
              <option value=""  >Created By</option>
              {parentCategories.map
                (x => <option value={x.id} key={x.id}> { x.firstName + " " + x.lastName} </option>)
              }
          </Form.Control>
       </Col>

        <Col sm={6} md={3} className="d-flex">
              
                <div style={{ "position": "relative","width":"100%" }}>
                  <div className="input-group">
                    <Form.Control type="text" value={searchTerm ? searchTerm : ""} placeholder="Search..." id="search-text" onInput={(e) => SetSearchTermData(e)} style={{ "paddingLeft": "35px", }} />
                    <div className="input-group-prepend">
                    </div>
                  </div>
                  <i className="fa fa-search" style={{ "position": "absolute", "left": "7px", "top": "50%", "transform": "translate(7px, -50%)", "borderRadius": "8px" }} ></i>
                </div>
              </Col>



              <Col sm={6} md={6} className="text-right" >
                <Link to="#" type="button" onClick={get_Test_List} className="btn btn-search ml-5" id="filter-btn" style={{ "borderRadius": "8px" }} >
                  Filter
              </Link>
              <Link to="#" type="button" onClick={get_List_without_filters} className="btn reset-filter-btn text-white  ml-5" id="filter-btn" style={{ "borderRadius": "8px" }} >
                  Reset
              </Link>
              </Col>

        </Row>

        <Col className="Content-custom-padding mt-5 mb-0 mb-sm-5">
 
          <div>
            {QuestionsList &&  QuestionsList.length > 20 &&  QuestionsList.length > 0  && (
              <BootstrapTable className="bg-white" keyField='id' data={QuestionsList} columns={columns} options={options} pagination={paginationFactory(options)} rowEvents={actionControls} />
            )}
           {QuestionsList &&  QuestionsList.length === 0  && (
             <>
              <BootstrapTable className="bg-white" keyField='id' data={QuestionsList} columns={columns} options={options}  rowEvents={actionControls} />
              
              {!loadingList ?
             <p className="text-center mt-5 pt-5" style={{"fontSize":"30px","minHeight":"200px","display":"flex","justifyContent":"center","alignItems":"center"}}> No Records Found </p>
             :""}


             {loadingList ?
            <p className="text-center mt-5 pt-5" style={{"fontSize":"30px","minHeight":"200px","display":"flex","justifyContent":"center","alignItems":"center"}}> 
              <ele id="no-data">  
                 <i className="fas fa-spinner fa-pulse fa-2x"></i>
              </ele> 
            </p>
             :""}
              </>
           )}
            {QuestionsList  && QuestionsList.length <= 20 && QuestionsList.length > 0 &&
            (
              <BootstrapTable className="bg-white" keyField='id' data={QuestionsList} columns={columns} options={options}  rowEvents={actionControls} />
            )
            }
          </div>

        </Col>
      </Col>


      <DeleteConfirmationModal show={modalShow} onHide={() => setModalShow(false)} />


    </>
  );
}

export default ManageInterview