
/* eslint-disable no-restricted-imports */
/* jshint asi:true */

import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Helmet from "react-helmet"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import Carousel from 'react-bootstrap/Carousel'
import { useParams, useHistory } from 'react-router-dom';
//import VideoRecorder from 'react-video-recorder'
import { isAndroid, isIOS, isSafari, isChrome, isFirefox, osName,isMobile } from "react-device-detect";
//import ReactPlayer from "react-player";
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import {browserHistory} from 'react-router';
import alertService from "../../../_services/alertservice";
import axios from 'axios';
import {logout} from "../../../../actions/auth";
import { useDispatch } from 'react-redux';

//import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
//const ffmpeg = createFFmpeg({ log: true });
const queryString = require('query-string');

/*deepLink.setup({
  iOS: {
      appId: "284882215",
      appName: "vrekruit",
  },
  android: {
      appId: "com.vrekruit.app"
  }
});
*/


function InterviewCandidate(props) {

  const [ interviewAnswerData, SetinterviewAnswerData] = useState(
    {
      interviewQuestionId : "",
      answerName : "",
      answer : ""
    }
  )

 const [ recorderAnswerTime, setRecorderAnswerTime ] = useState();




  const AddInterviewAnswer = (reqdata) => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/candidate/add_interview_answer`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(reqdata)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          alertService.alert({ message: res.message, type: 'info' });
          DisplayNextQuestion();
          setVidoBlob("");
        }
        else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
        else {
          if (res.errors) {
            var errmsg = "";
            res.errors.forEach(function (value, index, array) {
              errmsg = errmsg + "(" + (index + 1) + ") " + value.msg + "\n\r";
            });
            alertService.alert({ message: errmsg, type: 'error' });
          }
          else {
            alertService.alert({ message: res.message, type: 'error' });
          }
        }
      })
      .catch(() => {
      });
    }

  (function (global) {

    if(typeof (global) === "undefined")
    {
      throw new Error("window is undefined");
    }
  
      var _hash = "!";
      var noBackPlease = function () {
          global.location.href += "#";
  
      // making sure we have the fruit available for juice....
      // 50 milliseconds for just once do not cost much (^__^)
          global.setTimeout(function () {
              global.location.href += "!";
          }, 50);
      };
    
    // Earlier we had setInerval here....
      global.onhashchange = function () {
          if (global.location.hash !== _hash) {
              global.location.hash = _hash;
          }
      };
  
      global.onload = function () {
          
      noBackPlease();
  
      // disables backspace on page except on input fields and textarea..
      document.body.onkeydown = function (e) {
              var elm = e.target.nodeName.toLowerCase();
              if (e.which === 8 && (elm !== 'input' && elm  !== 'textarea')) {
                  e.preventDefault();
              }
              // stopping event bubbling up the DOM tree..
              e.stopPropagation();
          };
      };
  
  })(window);

  window.history.forward();
  function noBack()
  {
      window.history.forward();
  }

  const { intl } = props;    
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };


 //show LandingPage is landing page
 const[ showLandingPage, SetShowLandingPage] = useState(true);
 const[ showTermsPage, SetShowTermsPage] = useState(false);
 const[ showIntroVideo, SetShowIntroVideo] = useState(false);
 const[ showThanksVideo, SetShowThanksVideo] = useState(false);
 const[ showFinalThankyouPage, SetShowFinalThankyouPage] = useState(false);

 

 const showtermspage = () => {
  SetShowLandingPage(false);
  SetShowTermsPage(true)
 }

 const showHomePage = () => {
   SetShowLandingPage(true);
   SetShowTermsPage(false);
   SetShowIntroVideo(false);
 }


const[formSubmitted,SetformSubmitted]=useState(false)  
const [terms,SetTems]=useState(false);
const acceptTerms = () => {
  if(terms){
    SetTems(false)
  }
  else{
    SetTems(true)
  }
}  

 const ProceedInstructions = () => {
  SetformSubmitted(true) ;
  if(terms) {
    SetShowLandingPage(false);
    SetShowTermsPage(false)
    if(interviewDetails.introVideo)
     {
       SetShowIntroVideo(true)
     }
     else {
      SetShowVideoQuestion(true);
     }
  }
  }  
  const[showVideoQuestion,SetShowVideoQuestion] = useState(false);
  const[showVideoAnswer,SetShowVideoAnswer] = useState(false);
  const DisplayvideoQuestion = () => {
    SetShowVideoQuestion(true);
    SetShowIntroVideo(false);
    UpdateInterviewStatus();
  }


// SetShowIntroVideo
// SetShowThanksVideo
const [introVideos, SetIntroVideos] = useState([]); 
const [thanksVideos, SetThanksVideos] = useState([]); 
const [YTVideos, SetYTVideos] = useState([]); 
const [FeatureImage, SetFeatureImg] = useState([]); 
const [interviewRules, SetInterviewRules] = useState([]); 
const [isRecording,SetIsRecording]=useState(false);
const[interviewDetails,SetInterviewDetails] = useState()
const [isIntroVideoEnded,SetIsIntroVideoEnded ]=useState(false);

const onIntroVideoEnded = () => {
   SetIsIntroVideoEnded(true);
}



const [CurrentQuesIndex,SetCurrentQuestionIndex]=useState(0);
const uploadblobfile = (file) => {
  var CId = interviewDetails.requisition.companyId;
  if (file) {
    var bytes = file.size;
    var Mbytes = bytes / (1024 * 1024);
  }
  if (file && Mbytes < 20) {
    // Split the filename to get the name and type
    let fileParts = file.name.split('.');
    let fileType = fileParts[1];
    const formData1 = new FormData();
    formData1.append("fileName", fileParts[0] + "." + fileParts[1]);
    formData1.append("fileType","video/"+fileType);
    formData1.append("fileFolder", 'video');
    formData1.append("file", file);
    axios.post(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/upload_answer_file`,  {
      fileName: fileParts[0] + "." + fileParts[1],
      fileType: "video/"+fileType,
      fileFolder: "video",
      companyId: CId,
    },
    {
      headers: {
        'x-access-token': localStorage.getItem('x-access-token'),
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        if(response.data.sessionExpired){
          logoutMethod();
          alertService.alert({ message :response.data.message, type : 'error'  });
        }
        else if(response.status){
          console.log(response)
          //var url = response.data.data.filePath;
          var returnData = response.data.data.returnData;
        var signedRequest = returnData.signedRequest;
        var url = returnData.url;
        console.log(url)
        var answerdata= false;
        if (url) {
          console.log('got here')
          var updatequestionIndex = CurrentQuesIndex +1
          var reqDatatoAddAnswerDetails = {
            interviewQuestionId: InterviewQuestionData[CurrentQuesIndex].id,
            answerName : "Answer "+  updatequestionIndex,
            answer : url
          }
          SetVideoAnswerUrl(url)
          SetUploadState(true);
          
          if(reqDatatoAddAnswerDetails.interviewQuestionId &&  reqDatatoAddAnswerDetails.answer) {
            AddInterviewAnswer(reqDatatoAddAnswerDetails);
          }
          
        }
        // Put the fileType in the headers for the upload
        var options = {
          headers: {
            'Content-Type': 'video/'+ fileParts[1],
            'Content-Disposition':"inline" 
          }
        };
        axios.put(signedRequest, file, options)
          .then(result => {
            console.log(result)
          })
          .catch(error => {
            alertService.alert({ message: JSON.stringify(error), type: 'error' });
          }) 
        }
      })
      .catch(error => {
        alertService.alert({ message: JSON.stringify(error), type: 'error' });
      })
  }
  else if (file && Mbytes > 20) {
    alertService.alert({ message: "Max Allowed File Size is 20MB", type: 'error' });
  }
}



const [InterviewQuestionData,setInterviewQuestionsData] = useState();
const get_Interview_questions = () => {
  var parsed = queryString.parse(props.location.search);
  var key = parsed.shareKey;
  fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/candidate/get_interview_questions?module=interview&shareKey=`+ key, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status == true) {
        var test = res.data;
        var testQue = test.map(x=> {
          x.interviewQuestionDetail.answerDuration = x.answerTime;
          x.interviewQuestionDetail.id = x.id;
          return x.interviewQuestionDetail;
        });
        setInterviewQuestionsData(testQue);
        setRecorderAnswerTime(testQue[CurrentQuesIndex].answerDuration + 3);
      }
      else {
       
          histroy.push({pathname : '/interview-expired',
                state: { message: res.message }
        });

        
      }
    })
    .catch(() => {
    });
}



const [interviewEndStatus, SetInterviewEndStatus] = useState(
  {
    status: "end",
  })


  const EndInterviewStatus = () => {
    let id= interviewDetails.id
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/candidate/update_interview/`+id, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(interviewEndStatus)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          // alertService.alert({ message: res.message, type: 'info' });
        }
        else {
          if (res.errors) {
            var errmsg = "";
            res.errors.forEach(function (value, index, array) {
              errmsg = errmsg + "(" + (index + 1) + ") " + value.msg + "\n\r";
  
            });
            alertService.alert({ message: errmsg, type: 'error' });
          }
          else {
            alertService.alert({ message: res.message, type: 'error' });
          }
        }
      })
      .catch(() => {
      });  
  }




const UpdateInterviewStatus = () => {
  let id= interviewDetails.id
  fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/candidate/update_interview/`+id, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(formDataStatus)
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status == true) {
        // alertService.alert({ message: res.message, type: 'info' });
      }
      else {
        if (res.errors) {
          var errmsg = "";
          res.errors.forEach(function (value, index, array) {
            errmsg = errmsg + "(" + (index + 1) + ") " + value.msg + "\n\r";

          });
          alertService.alert({ message: errmsg, type: 'error' });
        }
        else {
          alertService.alert({ message: res.message, type: 'error' });
        }
      }
    })
    .catch(() => {
    });  
}



 const get_Interview = () => {
  var parsed = queryString.parse(props.location.search);
  var key = parsed.shareKey;
  fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/candidate/get_interview?module=interview&shareKey=`+key, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status == true) {
        SetInterviewDetails(res.data);
        if(res.data.companyIntroVideos){
           SetIntroVideos(res.data.companyIntroVideos.split(","));
        }
        if(res.data.thanksVideo){
          SetThanksVideos(res.data.thanksVideo.split(","))
        }

        if(res.data.requisition.company.companyYoutubeLinks){
          SetYTVideos(res.data.requisition.company.companyYoutubeLinks.split(","));
        }
        if(res.data.requisition.company.companyImages){

        }
       if(res.data.requisition.company.companyImages){
         SetFeatureImg(res.data.requisition.company.companyImages.split(","));
       }
       if(res.data.interviewRules){
        SetInterviewRules(res.data.interviewRules);
      }
      }
      else {
        
          histroy.push({pathname : '/interview-expired',
                state: { message: res.message }
        });
       
      }
    })
    .catch(() => {
    });
}
const[ isRecordringControlsEnabled,SetIsRecordringControlsEnabled]=useState(false)

 const [seconds,SetSeconds] = useState(30)
 const [counter,SetCounter] = useState(null)

 const DisplayNextQuestion = () => {
    
   if(CurrentQuesIndex < InterviewQuestionData.length - 1  ){
  clearInterval(counter);
  SetSeconds(30);
  SetShowVideoAnswer(false);
  SetShowVideoQuestion(true);
  SetCurrentQuestionIndex(CurrentQuesIndex+1)
  setRecorderAnswerTime(InterviewQuestionData[CurrentQuesIndex].answerDuration + 3);

   }
   else  {
    if(thanksVideos.length > 0){
      SetShowThanksVideo(true);
      SetShowVideoQuestion(false);
      SetShowVideoAnswer(false);
      EndInterviewStatus();
      // alert("all answers submited already")
    }
    else  {
      SetShowFinalThankyouPage(true);
      SetShowThanksVideo(false);
      SetShowVideoQuestion(false);
      SetShowVideoAnswer(false);
    }

   }
}

const OnthanksVideoEnded =()=> {
  SetShowFinalThankyouPage(true);
  SetShowThanksVideo(false);
  SetShowVideoQuestion(false);
  SetShowVideoAnswer(false);
}


 const DisplayvideoAnswer = () => {
  clearInterval(counter);
  SetShowVideoAnswer(true);
  SetShowVideoQuestion(false);
 }


 const StartTimer = ()  => {
  var counterSeconds = 30;
  SetIsRecordringControlsEnabled(true)
  SetSeconds(30);
    SetCounter(setInterval(() => {
     counterSeconds = counterSeconds - 1;
          
     if (counterSeconds >= 0) {
      SetSeconds(counterSeconds);
     } else {
       DisplayvideoAnswer();
     }
    }, 1000));
 }


const downloadVideo = (videoBlob) => {
  var videoType = ".webm"
    if(isSafari){
      videoType = ".mp4"
    }
  if(videoBlob) {
    var file = blobToFile(videoBlob, `Video-${Date.now()}${videoType}`);
  }
  uploadblobfile(file);
  SetIsRecordringControlsEnabled(false)
}

// {
//   "interviewQuestionId" : 1,
//   "answerName: : "video name",
//   "answer" : "answer video path will go here"
// }

const [uploadState, SetUploadState] = useState(false);
const histroy = useHistory();
const dispatch = useDispatch();
const logoutMethod = () => {
  dispatch(logout());
  histroy.push('/auth/signin');
};

const[VideoAnswerUrl, SetVideoAnswerUrl] =useState("")
function blobToFile(theBlob, fileName) {
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
}

 useEffect(() => {
  let mounted = true;
  var parsed = queryString.parse(props.location.search);
  var key = parsed.shareKey;
     if (isAndroid) {
         //window.location.replace(`intent://${process.env.REACT_APP_ANDROID_URL}?shareKey=${key}&module=interview#Intent;scheme=https;package=com.vrekruit.app;end`);
         // return
     }
     else if (isIOS) {
         //  window.location.replace("VRekruit://?shareKey="+key+"&module=interview");
         //  return
     } else if(isMobile){
       //
     }
     else {
         if ((osName == 'Mac OS' && isSafari) || (osName == 'Windows' && (isChrome || isFirefox))) {
          var newurl = "interview" + "?shareKey=" + key;
          window.location.href = `${process.env.REACT_APP_URL}${newurl}`;
/*
             // window.open("URL_here","Tab_NAME_HERE", "height=800,width=800")

             // var params = "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1200,height=1000,left=100,top=100";
             var width = window.innerWidth
                 || document.documentElement.clientWidth
                 || document.body.clientWidth;

             var height = window.innerHeight
                 || document.documentElement.clientHeight
                 || document.body.clientHeight;
             var params = "scrollbars=yes,resizable=no,status=yes,location=yes,toolbar=no,menubar=no,width=" + 1920 + ",height=" + 1080;

             
             if (newurl) {

                 var isSafari1 = window.safari !== undefined;
                 if (isSafari1) {
                     histroy.push(newurl);
                     SetLoader(false)
                 }

                 else {
                     window.open(newurl, "name of", params);
                     SetLoader(false)
                     alertService.alert({ message: "Please enable pop-ups in your browser to start interview", type: 'info' });
                 }




             } */
         }
         else {
             window.location.replace(`${process.env.REACT_APP_URL}browser-not-supported`);


             // get_Interview_questions();
         }
     }
return () => mounted = false;
}, [])


const openMobileApp = () => {
  var parsed = queryString.parse(props.location.search);
  var key = parsed.shareKey;
  if (isAndroid) {    
  //  window.location.replace("VRekruit://?shareKey="+key+"&module=interview")
    window.location.replace(`intent://${process.env.REACT_APP_ANDROID_URL}/candidate/interview?shareKey=${key}&module=interview#Intent;scheme=https;package=com.vrekruit.app;end`);
    return
}
 else if (isIOS) {
    window.location.replace("VRekruit://?shareKey="+key+"&module=interview");
setTimeout(function () {
   window.location.href = 'https://apps.apple.com/in/app/vrekruit/id1587905819?shareKey="+key+"&module=interview'; //'YOUR_APP_STORE_LINK';
      }, 1500);
       // window.location.replace("https://apps.apple.com/us/app/instagram/id389801252");
        return true;

    
}
}

const [videoBlob, setVidoBlob] = useState();
const[videoEnded, SetVideoEnded] = useState(false)
const EnableInterviewButton = () => {
  SetVideoEnded(true);
}
const [formDataStatus, SetFormDataStatus] = useState(
  {
    status: "start",
  })
  const [loader,SetLoader]=useState(true);
return (
  <>
  {!(isAndroid || isIOS) ?  
    <>
    <Helmet>
       <title> VRekruit | Candidate Interview </title>
    </Helmet>
  <section className="d-flex centerd justify-content-center align-item-center"  style={{"backgroundColor":"#003577","height":"100vh"}}>
    {!loader ?    
       <p className="text-white">
         
          You can safely close this window.
          </p>
          :

          <p className="text-white">
            <i className="fas fa-spinner fa-pulse fa-2x"></i>
          </p>
          
}
    </section>

    </>
    :
    <>        
<Helmet>
   <title> VRekruit | Candidate Interview </title>
</Helmet>
<section className="bg-white" style={{"overflow-x":"hidden",}}>
  <Row  >
    <Col sm={12} xs={12} md={12} className="text-center" >
    <img src={"/media/logos/UPDATED_VRekruit _Logo.png"}  className="pt-5 pb-5 mr-ato"   height="90" alt="Company Logo bg-circle" ></img>
    </Col>  
  </Row>
</section>   
    <section className="d-flex justify-content-center align-items-center" style={{"backgroundColor":"#003577","minHeight":"75vh","overflow-y":"hidden"}}>
    <button className="btn btn-interview mb-5" onClick={openMobileApp}> Go To Interview </button>
   </section>

    
</>
} 

</>


  


  );
}

export default InterviewCandidate;