/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import { useHistory } from "react-router";
import $ from "jquery"

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    console.log('clicked')
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };
  const setActive = () => {
    setTimeout(function () {
    console.log('seta ctive claeed')
    $('.liActive').removeClass('liActive')
    $('.menu-link.active').parent('li.menu-item').parents('ul.menu-subnav').addClass('liActive')
  }, 500);
  }

  const history = useHistory();

  const openMainMenu = (value) => {
     history.push(value);
  }

  const stopPropo = (e) => {
    e.stopPropagation();
  }

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`} onClick={setActive}>
        
          {/*begin::1 Level*/}
          <li
              onClick={() => openMainMenu("/dashboard")}
              className={`menu-item `}
              >
            <NavLink activeClassName="active" className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
            <img  className="sidebar-svg-img" alt="dashboard" src={toAbsoluteUrl("/media/VRekruitIcons/dashboard.svg")}/>
            </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>
          <li className="pt-5">

          </li>

          {localStorage.getItem("userTypeId") !== "1"  ? ( 
          <li
              className="menu-item"  >
            <NavLink activeClassName="active" className="menu-link" to="/product-demo" id="product-demo-component">
            <span className="svg-icon menu-icon">
            <i class="fa fa-desktop"></i>
           {/* <!--img  className="sidebar-svg-img" alt="Product Demo"  src={toAbsoluteUrl("/media/VRekruitIcons/Evaluation.png")}/--> */}
            </span>
              <span className="menu-text">Product Demo</span>
            </NavLink>
          </li>

        ): "" }
          
          {localStorage.getItem("userTypeId") === "1"  ? ( 
          <li
              className="menu-item"  >
            <NavLink activeClassName="active" className="menu-link" to="/admin/comapanies-list">
            <span className="svg-icon menu-icon">
            <img  className="sidebar-svg-img" alt="company managment"  src={toAbsoluteUrl("/media/VRekruitIcons/company manage.svg")}/>
            </span>
              <span className="menu-text">Company Management</span>
            </NavLink>
          </li>

        ): "" }



          
          {localStorage.getItem("userType") === "Company Admin" ? ( 
          <li
              className="menu-item"  >
            <NavLink  activeClassName="active" className="menu-link" to="/company-mangement/view">
            <span className="svg-icon menu-icon">
            <img  className="sidebar-svg-img" alt="company managment"  src={toAbsoluteUrl("/media/VRekruitIcons/company manage.svg")}/>
            </span>
              <span className="menu-text">Company Management</span>
            </NavLink>
          </li>

        ): "" }
     

    {  localStorage.getItem("userTypeId") !=="1" && localStorage.getItem("userTypeId") !=="3"   ? 
        <>
          {/* start   User Management */}
          {/*begin::1 Level testing*/}
          
        <li 
        
        className={`menu-item menu-item-submenu ${getMenuItemActive( "/user/list", true)}  ${getMenuItemActive( "/user/add", true)} `} data-menu-toggle="hover"  
        
        onClick={ () => openMainMenu("/user/list") }
        
        >
            <NavLink activeClassName="active" className="menu-link menu-toggle" to="/user/list"  id="user-management-component">
            <span className="svg-icon menu-icon">
              <img  className="sidebar-svg-img" alt="user managment" src={toAbsoluteUrl("/media/VRekruitIcons/UserManage.svg")}/>
            </span>
              <span className="menu-text"> User Management </span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <ul className="menu-subnav">
                  <li
                      className="menu-item  menu-item-parent"
                      
                  >
                  <span className="menu-link">
                    <span className="menu-text"></span>
                  </span>
                  </li>

                  {/*begin::2 Level*/}
                  {/* <testing> */}

                  <li
                      className={`menu-item ${getMenuItemActive(
                          "/user/add")}`}>

                    <NavLink activeClassName="active" onClick={(e) => stopPropo(e) } className="menu-link" to="/user/add" >
                      <i className="menu-bullet fas fa-circle">
                        <span/>
                      </i>
                      <span className="menu-text">Add New User</span>
                    </NavLink>

                  </li>
                  {/*end::2 Level*/}

                  {/*begin::2 Level*/}
                  <li
                      className={`menu-item ${getMenuItemActive(
                          "/user/list"
                      )}`}>
                    
                    <NavLink activeClassName="active" onClick={(e) => stopPropo(e)}  className="menu-link" to="/user/list" >
                      <i className="menu-bullet fas fa-circle">
                        <span/>
                      </i>
                      <span className="menu-text">User List</span>
                    </NavLink>

                  </li>
                  {/*end::2 Level*/}
              </ul>
            </div>
          </li>
          {/*end::1 Level*/}
      {/* End   User Management */}

        </>

      :""  }



      {/* End  Test Management */}


          {/*begin::1 Requistion Management */}

          { localStorage.getItem("userTypeId") !=="1" ?
          <li
              className={`menu-item menu-item-submenu  ${getMenuItemActive(
                  "/add-requistion", true
              )}   ${getMenuItemActive(
                "/view-requistion", true
            )}`}
              
              data-menu-toggle="hover"
          >
            <NavLink onClick={(e) => openMainMenu("/view-requistion")} activeClassName="active" className={`menu-link menu-toggle`} to="/add-requistion"
            id="req-management-component"
            >
            <span className="svg-icon menu-icon">
            <img  className="sidebar-svg-img" alt="job requisition" src={toAbsoluteUrl("/media/VRekruitIcons/Job Requisition.svg")}/>
            </span>
              <span className="menu-text"> Requisition Management </span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <ul className="menu-subnav">
                  <li
                      className="menu-item  menu-item-parent"
                      
                  >
                  <span className="menu-link">
                    <span className="menu-text"> Add New Requistion </span>
                  </span>
                  </li>

                  {/*begin::2 Level*/}
                  <li
                      className={`menu-item ${getMenuItemActive(
                          "/add-requistion"
                      )}`}
                      onClick={() => openMainMenu("/add-requistion")}
                      
                  >
                    <NavLink onClick={(e) => stopPropo(e)} className="menu-link" to="/add-requistion">
                      <i className="menu-bullet fas fa-circle">
                        <span/>
                      </i>
                      <span className="menu-text">
                          Add New Requisition
                      </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}
                  <li
                      className={`menu-item ${getMenuItemActive(
                          "/view-requistion"
                      )}`}
                      
                  >
                    <NavLink onClick={(e) => stopPropo(e)} className="menu-link" to="/view-requistion">
                      <i className="menu-bullet fas fa-circle">
                        <span/>
                      </i>
                      <span className="menu-text">
                          View Requisition
                      </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
              </ul>
            </div>
          </li>

          :""}


          {/*end::1 Level*/}



      {/* End Requistion Management  */}
      {/* End Test Management */}




     {/* Start INTERVIEW Management */}
          {/*begin::1 Level*/}
          <li className={`menu-item menu-item-submenu  ${getMenuItemActive( "/interview", true)}`}

           data-menu-toggle="hover" >
            <NavLink  onClick={() => openMainMenu(localStorage.getItem("userTypeId") === '1' ? "/interview/view-question" : "/interview/manage-interview" )} 
            
            className="menu-link menu-toggle" to="/test-management">
            <span className="svg-icon menu-icon">
            <img  className="sidebar-svg-img" alt="interview management" src={toAbsoluteUrl("/media/VRekruitIcons/InterviewManage.svg")}
            id ="int-management-component"
            />
            </span>
              <span className="menu-text"> Interview Management </span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <ul className="menu-subnav">

          <li className={`menu-item menu-item-submenu ${getMenuItemActive(
                          "/interview/add-category",true)}  
                          ${getMenuItemActive(
                            "/interview/view-category",true)}
                            `} data-menu-toggle="hover">
            <NavLink onClick={(e) => stopPropo(e)} className="menu-link menu-toggle" to="/category-management">
              <span className="menu-text">
              <i className="menu-bullet fas fa-circle"><span></span></i>
                 Category Management </span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <ul className="menu-subnav">


                  {/*begin::2 Level*/}
                  <li
                      className={`menu-item ${getMenuItemActive(
                          "/interview/add-category"
                      )}`}
                      
                  >
                    <NavLink onClick={(e) => stopPropo(e)} className="menu-link" to="/interview/add-category">
                        <span/>
                      <span className="menu-text sub-menu-text">
                      <i className="menu-bullet fas fa-dot-circle "> </i>
                        Add Category
                      </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}

                  {/*begin::2 Level*/}
                  <li
                      className={`menu-item ${getMenuItemActive(
                          "/interview/view-category"
                      )}`}
                      
                  >
                    <NavLink onClick={(e) => stopPropo(e)} className="menu-link" to="/interview/view-category">
                        <span/>
                      <span className="menu-text sub-menu-text">
                         <i className="menu-bullet fas fa-dot-circle"> </i>
                         View Category </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                </ul>
            </div>
          </li>
          {/*end::1 Level*/}
          {/* end category management */}
          {/* start of question management */}
           {/*start Category Management */}
          {/*begin::1 Level*/}
          <li
              onClick={() => openMainMenu("/question-management")}

              className={`menu-item menu-item-submenu ${getMenuItemActive(	
                "/interview/add-question",true)}  	
                ${getMenuItemActive(	
                  "/interview/view-question",true)}	
                  ${getMenuItemActive(	
                    "/interview/edit-question",true)}	
                  `}	

              
              data-menu-toggle="hover"
          >
            <NavLink onClick={(e) => stopPropo(e)} className="menu-link menu-toggle" to="/question-management">

              <span className="menu-text">
              <i className="menu-bullet fas fa-circle"><span></span></i>
                 Question Management </span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
                <ul className="menu-subnav">


                  {/*begin::2 Level*/}
                  <li
                      className={`menu-item ${getMenuItemActive(
                          "/interview/add-question"
                      )}`}
                      onClick={() => openMainMenu("/interview/add-question")}
                    >
                    <NavLink onClick={(e) => stopPropo(e)} className="menu-link" to="/interview/add-question">
                        <span/>
                      <span className="menu-text sub-menu-text">
                         <i className="menu-bullet fas fa-dot-circle"> </i>
                         Add Question
                         </span>
                        </NavLink>
                  </li>
                  {/*end::2 Level*/}

                  {/*begin::2 Level*/}
                  <li
                      onClick={() => openMainMenu("/interview/view-question")}
                      className={`menu-item ${getMenuItemActive(
                          "/interview/view-question"
                      )}`}
                      
                  >
                    <NavLink onClick={(e) => stopPropo(e)} className="menu-link" to="/interview/view-question">
                        <span/>
                      <span className="menu-text sub-menu-text">
                         <i className="menu-bullet fas fa-dot-circle"> </i>
                           Manage Question
                         </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                  {/* start level3 */}


                  {/* end level3 */}
              </ul>
            </div>
                          </li>
          {localStorage.getItem("userTypeId") !== "1" && process.env.REACT_APP_API_ENVIRONMENT != 'PROD' ?

          <li className={`menu-item menu-item-submenu ${getMenuItemActive(
                              "/interview/add-fitment", true)}  
                          ${getMenuItemActive(
                                  "/interview/view-fitment", true)}
                            `} data-menu-toggle="hover">
                              <NavLink onClick={(e) => stopPropo(e)} className="menu-link menu-toggle" to="/assessment-management">
                                  <span className="menu-text">
                                      <i className="menu-bullet fas fa-circle"><span></span></i>
                 Assessment Management </span>
                                  <i className="menu-arrow" />
                              </NavLink>
                              <div className="menu-submenu ">
                                  <ul className="menu-subnav">


                                      {/*begin::2 Level*/}
                                      <li
                                          className={`menu-item ${getMenuItemActive(
                                              "/interview/add-fitment"
                                          )}`}

                                      >
                                          <NavLink onClick={(e) => stopPropo(e)} className="menu-link" to="/interview/add-fitment">
                                              <span />
                                              <span className="menu-text sub-menu-text">
                                                  <i className="menu-bullet fas fa-dot-circle "> </i>
                        Add Fitment Parameters
                      </span>
                                          </NavLink>
                                      </li>
                                      {/*end::2 Level*/}

                                      {/*begin::2 Level*/}
                                      <li
                                          className={`menu-item ${getMenuItemActive(
                                              "/interview/view-category"
                                          )}`}

                                      >
                                          <NavLink onClick={(e) => stopPropo(e)} className="menu-link" to="/interview/view-fitment">
                                              <span />
                                              <span className="menu-text sub-menu-text">
                                                  <i className="menu-bullet fas fa-dot-circle"> </i>
                         View Fitment Parameters </span>
                                          </NavLink>
                                      </li>
                                      {/*end::2 Level*/}
                                  </ul>
                              </div>
                          </li>
                          : "" }

          
      {/* End category Management */}
      {/* end of question management */}
      {/*begin::2 Level*/}

                          {localStorage.getItem("userTypeId") !== "1" ?
                              <>
                                  
                                      
                                      


                                              {/*begin::2 Level*/}
                                              <li
                                                  className={`menu-item ${getMenuItemActive(
                                                      "/interview/create-interview"
                                                  )}`}

                                              >
                                                  <NavLink onClick={(e) => stopPropo(e)} className="menu-link" to="/interview/create-interview">
                                                      <span />
                                                      <span className="menu-text ">
                                                          <i className="menu-bullet fas fa-dot-circle "> </i>
                        Create Interview
                      </span>
                                                  </NavLink>
                                              </li>
                                              {/*end::2 Level*/}

                                              {/*begin::2 Level*/}
                                              <li
                                                  className={`menu-item ${getMenuItemActive(
                                                      "/interview/manage-interview"
                                                  )}`}

                                              >
                                                  <NavLink onClick={(e) => stopPropo(e)} className="menu-link" to="/interview/manage-interview">
                                                      <span />
                                                      <span className="menu-text ">
                                                          <i className="menu-bullet fas fa-dot-circle"> </i>
                         Manage Interview </span>
                                                  </NavLink>
                                              </li>
                                              {/*end::2 Level*/}
                                          
                                  
                              
                                  </>

              :""}

                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}
                  {/*end::2 Level*/}
              </ul>
            </div>
          </li>
          {/*end::1 Level*/}
          {/* End interview Management */}




     {/* start  Test Management */}
          {/*begin::1 Level*/}
           <li className={`menu-item menu-item-submenu d-none`}  data-menu-toggle="hover" >
            <NavLink onClick={(e) => stopPropo(e)} className="menu-link menu-toggle" to="/test-management">
            <span className="svg-icon menu-icon">
            <img  className="sidebar-svg-img" alt="test management" src={toAbsoluteUrl("/media/VRekruitIcons/Test manage.svg")}/>
            </span>
              <span className="menu-text"> Test Management </span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <ul className="menu-subnav">

          <li
              className={`menu-item menu-item-submenu `}
              
              data-menu-toggle="hover"
          >

            <NavLink onClick={(e) => stopPropo(e)} className="menu-link menu-toggle" to="/category-management">
              <span className="menu-text">
              <i className="menu-bullet fas fa-circle"><span></span></i>
                 Category Management  </span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <ul className="menu-subnav">


                  {/*begin::2 Level*/}
                   <li
                      className={`menu-item ${getMenuItemActive(
                          "/parent-category"
                      )}`}
                      
                  >
                    <NavLink onClick={(e) => stopPropo(e)} className="menu-link" to="/parent-category">
                        <span/>
                      <span className="menu-text sub-menu-text">
                      <i className="menu-bullet fas fa-circle "> </i>
                        Add Category
                      </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}

                  {/*begin::2 Level*/}
                  <li
                      className={`menu-item ${getMenuItemActive(
                          "/view-category"
                      )}`}
                      
                  >
                    <NavLink onClick={(e) => stopPropo(e)} className="menu-link" to="/view-category">
                        <span/>
                      <span className="menu-text sub-menu-text">
                         <i className="menu-bullet fas fa-circle"> </i>
                         View Category </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                 </ul>
            </div>
          </li>
          {/*end::1 Level*/}
          {/* end category management */}
          {/* start of question management */}
           {/*start Category Management */}
          {/*begin::1 Level*/}
           <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/question-management", true
              )}`}
              
              data-menu-toggle="hover"
          >
            <NavLink onClick={(e) => stopPropo(e)} className="menu-link menu-toggle" to="/question-management">

              <span className="menu-text">
              <i className="menu-bullet fas fa-circle"><span></span></i>
                 Question Management </span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
                <ul className="menu-subnav">


                  {/*begin::2 Level*/}
                   <li
                      className={`menu-item ${getMenuItemActive(
                          "add-question"
                      )}`}
                      
                  >
                    <NavLink onClick={(e) => stopPropo(e)} className="menu-link" to="/add-question">
                        <span/>
                      <span className="menu-text sub-menu-text">
                         <i className="menu-bullet fas fa-circle"> </i>
                         Add Question
                         </span>
                        </NavLink>
                  </li>
                  {/*end::2 Level*/}

                  {/*begin::2 Level*/}
                   <li
                      className={`menu-item ${getMenuItemActive(
                          "view-question"
                      )}`}
                      
                  >
                    <NavLink onClick={(e) => stopPropo(e)} className="menu-link" to="/view-question">
                        <span/>
                      <span className="menu-text sub-menu-text">
                         <i className="menu-bullet fas fa-circle"> </i>
                           View Question
                         </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                  {/* start level3 */}


                  {/* end level3 */}
                 </ul>
            </div>
          </li>


           <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "", true
              )}`}
              
              data-menu-toggle="hover"
          >
            <NavLink onClick={(e) => stopPropo(e)} className="menu-link menu-toggle" to="/create-test">
              <span className="menu-text ">
              <i className="menu-bullet fas fa-circle"><span></span></i>
                 Create Test </span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <ul className="menu-subnav">


                  {/*begin::2 Level*/}
                   <li
                      className={`menu-item ${getMenuItemActive(
                          "/parent-category"
                      )}`}
                      
                  >
                    <NavLink onClick={(e) => stopPropo(e)} className="menu-link" to="/manual-test">
                        <span/>
                      <span className="menu-text sub-menu-text">
                      <i className="menu-bullet fas fa-circle "> </i>
                       Create  Manual Test
                      </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}

                  {/*begin::2 Level*/}
                   <li
                      className={`menu-item ${getMenuItemActive(
                          "/view-category"
                      )}`}
                      
                  >
                    <NavLink onClick={(e) => stopPropo(e)} className="menu-link" to="/manual-test">
                        <span/>
                      <span className="menu-text sub-menu-text">
                         <i className="menu-bullet fas fa-circle"> </i>
                        Create  Random Test
                      </span>
                    </NavLink>
                  </li> 
                  {/*end::2 Level*/}
                 </ul>
            </div>
          </li>
          {/*end::1 Level*/}

      {/* End category Management */}
      {/* end of question management */}
      {/*begin::2 Level*/}

 
                  <li
                      className={`menu-item ${getMenuItemActive(
                          "/manage-test"
                      )}`}
                      
                  >
                    <NavLink onClick={(e) => stopPropo(e)} className="menu-link" to="/manage-test">
                      <i className="menu-bullet fas fa-circle">
                        <span/>
                      </i>
                      <span className="menu-text"> Manage Test </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}
                  {/*end::2 Level*/}
              </ul>
            </div>
          </li>
          {/* end::1 Level */}
          {/* End Test Management */}

       {localStorage.getItem("userTypeId") !== "1"  ?
           <>

          {/* start of reviewModule */}
          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/assigned-interview", true
              )}

              ${getMenuItemActive(
                "/expired-interviews", true
            )}

            ${getMenuItemActive(
              "/interrupted-interview", true
          )}
              
              
              
              
              `}



              data-menu-toggle="hover">
            <NavLink onClick={(e) => openMainMenu("/assigned-interview")} className="menu-link menu-toggle" to="/assigned-interview"
            id="review-management-component"
            >
            <span className="svg-icon menu-icon">
            <img  className="sidebar-svg-img" alt="review" src={toAbsoluteUrl("/media/VRekruitIcons/Reports.svg")}/>
            </span>
              <span className="menu-text"> Review </span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <ul className="menu-subnav">
                  <li
                      className="menu-item  menu-item-parent"
                      
                  >
                  <span className="menu-link">
                    <span className="menu-text"></span>
                  </span>
                  </li>

                  {/*begin::1 Level*/}

                  <li
                      className={`menu-item ${getMenuItemActive(
                          "/assigned-interview"
                      )}`}

                      
                  >
                    <NavLink onClick={(e) => stopPropo(e)} className="menu-link" to="/assigned-interview">
                      <i className="menu-bullet fas fa-circle">
                        <span/>
                      </i>
                      <span className="menu-text"> Assigned Interview</span>
                    </NavLink>
                  </li>
                 
                  {/*end::1 Level*/}

                  {/*begin::3 Level*/}
                  <li
                      className={`menu-item ${getMenuItemActive(
                          "/interrupted-interview"
                      )}`}
                      
                  >
                    <NavLink onClick={(e) => stopPropo(e)} className="menu-link" to="/interrupted-interview">
                      <i className="menu-bullet fas fa-circle">
                        <span/>
                      </i>
                      <span className="menu-text"> Interrupted Interview  </span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}

                    {/*begin::4 Level*/}
                    <li
                      className={`menu-item ${getMenuItemActive(
                          "/expired-interviews"
                      )}`}
                      
                  >
                    <NavLink onClick={(e) => stopPropo(e)} className="menu-link" to="/expired-interviews">
                      <i className="menu-bullet fas fa-circle">
                        <span/>
                      </i>
                      <span className="menu-text"> Expired Interviews</span>
                    </NavLink>
                  </li>
                  {/*end::4 Level*/}

                  {/*begin::5 Level*/}
                  {/* <li
                      className={`menu-item ${getMenuItemActive(
                          "/assigned-tests"
                      )}`}
                      >
                    <NavLink className="menu-link" to="/assigned-tests">
                      <i className="menu-bullet fas fa-circle">
                        <span/>
                      </i>
                      <span className="menu-text">Assigned Tests</span>
                    </NavLink>
                  </li> */}
                  {/*end::5 Level*/}

                      {/*begin::6 Level*/}
                      {/* <li
                      className={`menu-item ${getMenuItemActive(
                          "/interrupted-tests"
                      )}`}
                      
                  >
                    <NavLink className="menu-link" to="/interrupted-tests">
                      <i className="menu-bullet fas fa-circle">
                        <span/>
                      </i>
                      <span className="menu-text">Interrupted Tests</span>
                    </NavLink>
                  </li> */}
                  {/*end::6 Level*/}

              {/*begin::7 Level*/}
              {/* <li
                      className={`menu-item ${getMenuItemActive(
                          "/candidate-list"
                      )}`}
                      
                  >
                    <NavLink className="menu-link" to="/candidate-list">
                      <i className="menu-bullet fas fa-circle">
                        <span/>
                      </i>
                      <span className="menu-text">Candidate List</span>
                    </NavLink>
                  </li> */}
                  {/*end::7 Level*/}
              </ul>
            </div>
          </li>
          {/*end::1 Level*/}
             
          {/* End of Review Module */}

          </>
           :""}


          {/*start Reports */}
          {/*begin::1 Level*/}
          <li
              className={`d-none menu-item menu-item-submenu ${getMenuItemActive(
                  "/reports", true
              )}`}
              
              data-menu-toggle="hover">
            <NavLink onClick={(e) => stopPropo(e)} className="menu-link menu-toggle" to="/reports">
            <span className="svg-icon menu-icon">
            <img  className="sidebar-svg-img" alt="reports" src={toAbsoluteUrl("/media/VRekruitIcons/Reports.svg")}/>
            </span>
              <span className="menu-text"> Reports </span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <ul className="menu-subnav">
                  <li
                      className="menu-item  menu-item-parent"
                      
                  >
                  <span className="menu-link">
                    <span className="menu-text">Bootstrap</span>
                  </span>
                  </li>

                  {/*begin::2 Level*/}
                  <li
                      className={`menu-item ${getMenuItemActive(
                          "/react-bootstrap/alert"
                      )}`}
                      
                  >
                    <NavLink className="menu-link" to="/react-bootstrap/alert">
                      <i className="menu-bullet fas fa-circle">
                        <span/>
                      </i>
                      <span className="menu-text">Alerts</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}

                  {/*begin::2 Level*/}
                  <li
                      className={`menu-item ${getMenuItemActive(
                          "/react-bootstrap/badge"
                      )}`}
                      
                  >
                    <NavLink className="menu-link" to="/react-bootstrap/badge">
                      <i className="menu-bullet fas fa-circle">
                        <span/>
                      </i>
                      <span className="menu-text">Badge</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
              </ul>
            </div>
          </li>
          {/*end::1 Level*/}
      {/* End category Management */}

      
      {/* start   Payment Management */}
          {/*begin::1 Level*/}
          { (localStorage.getItem("userTypeId") == 2) ? 
          <>
          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/payment", true
              )}`}
              onClick={() => openMainMenu("/payment/pricing")}                                    
              data-menu-toggle="hover"
          >
            <NavLink  
            onClick={(e) => openMainMenu("/payment/pricing")}
            className="menu-link menu-toggle" to="/payment/pricing"
            id="payment-management-component"
            >
            <span className="svg-icon menu-icon">
              {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")}/> */}
              {/* public\media\VRekruitIcons\InterviewManage.svg */}
              <img  className="sidebar-svg-img" alt="payment managment" src={toAbsoluteUrl("/media/VRekruitIcons/PAYMENT- MANAGEMENT.svg")}/>
            </span>
              <span className="menu-text"> Payment Management </span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <ul className="menu-subnav">
                  <li
                      className="menu-item  menu-item-parent"
                      
                  >
                  <span className="menu-link">
                    <span className="menu-text"></span>
                  </span>
                  </li>

                  {/*begin::2 Level*/}
                  <li
                      className={`menu-item ${getMenuItemActive(
                          "/payment/pricing"
                      )}`}
                      
                  >
                    <NavLink 
                    onClick={(e) => stopPropo(e)}
                    className="menu-link" to="/payment/pricing">
                      <i className="menu-bullet fas fa-circle">
                        <span/>
                      </i>
                      <span className="menu-text">View Pricing</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}

                  {/*begin::2 Level*/}
                  <li
                      className={`menu-item ${getMenuItemActive(
                          "/payment/ledger"
                      )}`}
                      
                  >
                    <NavLink 
                    onClick={(e) => stopPropo(e)}
                    className="menu-link" to="/payment/ledger">
                      <i className="menu-bullet fas fa-circle">
                        <span/>
                      </i>
                      <span className="menu-text">Credit Summary</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}
                  <li
                      className={`menu-item ${getMenuItemActive(
                          "/payment/invoices"
                      )}`}
                      
                  >
                    <NavLink 
                    onClick={(e) => stopPropo(e)}
                    className="menu-link" to="/payment/invoices">

                      <i className="menu-bullet fas fa-circle">
                        <span/>
                      </i>
                      <span className="menu-text">Invoices</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
              </ul>
            </div>
          </li>
          </>
          : ''
        }
          {/*end::1 Level*/}
      {/* End   Payment Management */}






            {/* start   Payment Management for SuperAdmin */}
          {/*begin::1 Level*/}
          { (localStorage.getItem("userTypeId") === "1") ? 
          <>


<li
              className="menu-item"  >
            <NavLink 
            onClick={(e) => stopPropo(e)}
            className="menu-link" to="/admin/invoices">
            <span className="svg-icon menu-icon">
            <img  className="sidebar-svg-img" alt="company managment"  src={toAbsoluteUrl("/media/VRekruitIcons/PAYMENT- MANAGEMENT.svg")} />
            </span>
            <span className="menu-text"> Payment Management </span>
            </NavLink>
          </li>



{/* 
          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/payment-management", true
              )}`}
              
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/payment-management">
            <span className="svg-icon menu-icon">
    
              <img  className="sidebar-svg-img" alt="payment managment" src={toAbsoluteUrl("/media/VRekruitIcons/PAYMENT- MANAGEMENT.svg")}/>
            </span>
              <span className="menu-text"> Payment Management </span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <ul className="menu-subnav">
                <ul className="menu-subnav">
            
                  <li
                      className={`menu-item ${getMenuItemActive(
                          "/admin/invoices"
                      )}`}          
                  >
                    <NavLink className="menu-link" to="/admin/invoices">
                      <i className="menu-bullet fas fa-circle">
                        <span/>
                      </i>
                      <span className="menu-text"> Invoices</span>
                    </NavLink>
                  </li>
                </ul>
              </ul>
            </div>
          </li> */}
          </>
          : ''
        }
          {/*end::1 Level*/}
      {/* End   Payment Management */}

      {localStorage.getItem("userTypeId") !== "1"  ? ( 
          <li
              className="menu-item"  >
            <NavLink activeClassName="active" className="menu-link" to="/candidate-evaluation" id="candidate-evaluation-component">
            <span className="svg-icon menu-icon">
            <img  className="sidebar-svg-img" alt="Candidate Evaluation"  src={toAbsoluteUrl("/media/VRekruitIcons/Evaluation.png")}/>
            </span>
              <span className="menu-text">Candidate Evaluation</span>
            </NavLink>
          </li>

        ): "" }

      


        </ul>
        {/* end::Menu Nav */}
      </>
  );
}
