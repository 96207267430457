/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React, {useState,useCallback } from "react";
import GoogleMapReact from 'google-map-react';



 export default function MapNew() {

    const AnyReactComponent = ({ text }) => (
        <div style={{
          color: 'white', 
          background: 'transparent',
          padding: '15px 10px',
          display: 'inline-flex',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '100%',
          transform: 'translate(-50%, -50%)'
        }}>
              <img className="img-responsive mb-5 " width="30" src="/media/VRekruitIcons/address.svg"  />   

        </div>
      );

      const  defaultProps = {
        center: {
          lat: 19.11,// process.env.REACT_APP_GOOGLEMAP_CENTRE_LAT, 
          lng: 72.85 //process.env.REACT_APP_GOOGLEMAP_CENTRE_LONG
        },
        zoom: 12
     };


  const containerStyle = {
    width: '100%',
    height: '400px'
  };
    const center = {
        lat: 35.54,
        lng: -120.70
  };


  return (
  <>
    <div style={{ height: '60vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key:`${process.env.REACT_APP_GOOGLEMAP_API_KEY}`}}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        
        >
        <AnyReactComponent 
         lat= {process.env.REACT_APP_GOOGLEMAP_LAT}
         lng={process.env.REACT_APP_GOOGLEMAP_LONG}
         text="Office"        />
      </GoogleMapReact>
      </div>
   </>                 
  );
}




