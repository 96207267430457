import {
    REGISTER_FAIL,
    REGISTER_SUCCESS,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT
} from '../actions/Types';

const initialState = {
    token: localStorage.getItem('x-access-token'),
    isAuthenticated: localStorage.getItem('x-access-token') ? true : false,
    loading: true,
    user: null,    
}

export default function authFunction(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: payload.data
            }
        case REGISTER_SUCCESS:
        case LOGIN_SUCCESS:
            localStorage.setItem('x-access-token', payload.data.token);
            return {
                ...state,
                ...payload,
                token: payload.data.token,
                isAuthenticated: true,
                loading: false
            }
        case REGISTER_FAIL:
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT:
            localStorage.clear();
            return {
                token: null,
                isAuthenticated: false,
                loading: false,
                status: false,
                user: null
            }
        default:
            return state;
    }
}