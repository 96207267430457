/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Helmet from 'react-helmet'
import { Link, useHistory } from "react-router-dom";
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import alertService from "../../_services/alertservice";
import { logout } from '../../../actions/auth';
import { useDispatch } from 'react-redux';



const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const initialValues = {
  status: "open",
  page: 1,
  limit: 10,
  assignedToMe: false,
  createdByMe: "0",
  orderBy: "Id",
  orderType: "ASC"
}

function UserList() {
 
   const [requistions, setRequistions] = useState([]);
   const [staticData, setStaticData] = useState(10);
   const [searchTerm, SetSearchTerm] = useState("");
   const [createdbyme, SetCreatedbyme] = useState(false);
   const [assignedbyme, SetAssignedbyme] = useState(false);
   const [role, SetRoleType] = useState("");



   const resetFilter = () => {
   
    SetSearchTerm("");
    SetRoleType("");
    get_List_without_filters();
   }






// filter option 
const SetSearchTermData = (x) => {
  let data =  x.target.value;
 if(data){
  SetSearchTerm(x.target.value);
 }
 else{
  SetSearchTerm("");
 }

} 

const SetRoleData = (x) => {
    let data =  x.target.value;
   if(data){
     SetRoleType(x.target.value);
   }
}

const SetAssignedbymeData = (x) => {
  SetAssignedbyme(x.target.checked);
}

const SetCreatedbymeData = (x) => {
  SetCreatedbyme(x.target.checked);
}






// filter option 
const [loading,SetLoading]=useState(false);



  const get_Requisition_List = () => {
 
    const token = localStorage.getItem('x-access-token');
    SetLoading(true);
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/company/users_list?removeMe=true&orderBy`;
    if(searchTerm) {
      url = url + "&searchBy=" + searchTerm;
    }
    if(role) {
      url = url + "&roleId=" + role;
    }
    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setRequistions(res.data);
          SetLoading(false);
        }
        else if(res.sessionExpired){
          logoutMethod();
          alertService.alert({ message :res.message, type : 'error'  });
          SetLoading(false);
        }  
        else {
          SetLoading(false);
        }
      })
      .catch(() => {
        SetLoading(false);
      });
  }


  
  const dispatch = useDispatch();
  const logoutMethod = () => {
    dispatch(logout());
    history.push('/auth/signin');
};


  const get_List_without_filters = () => {

    const token = localStorage.getItem('x-access-token');
    SetLoading(true);
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/company/users_list?removeMe=true&orderBy`;
    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setRequistions(res.data);
          SetLoading(false);
          setStaticData(staticData + 10);
        }
        else {
          SetLoading(false);
        }
      })
      .catch(() => {
        SetLoading(false);
      });
  }



  useEffect(() => {
    let mounted = true;
    get_Requisition_List();
    return () => mounted = false;
  }, [])

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();



  const actionControls = {
    onClick: (e, row, rowIndex) => {
   if(row) {
       let id = row.id;
       if (e.target.classList.contains("view-option")) {
       history.push("/view-details/user/" + id)
       // get_Requisition_List();
      }

      if (e.target.classList.contains("edit-option")) {
        history.push("/edit-details/user/" + id)
        // get_Requisition_List();
       }

       if (e.target.classList.contains("delete-option")) {
        setModalShow3(true);
        setDeleteId(row.id)
       }
    }

  }
  };


  function actionFormatter(column, userdetails) {
    return (
      
      <div className="text-left d-flex">
        <img src="/media/VRekruitIcons/eyeorview.svg" className="view-option c-p view-eye-ico" title="View" alt="View"  />

            { userdetails && localStorage.getItem("userTypeId") === "4" && (userdetails.roleId === 4 || userdetails.roleId === 2)? "" : (

                  <img src="/media/VRekruitIcons/edit.svg" className="edit-option ml-3 c-p view-eye-ico" title="Edit" alt="Edit"  />
        )

         }

        {/* <img src="/media/VRekruitIcons/delete.svg" className="delete-option ml-3 c-p view-eye-ico" alt="" /> */}

      </div>
    );
  }

  function headerformatter(column, userdetails) {
    return (
      <div>
            {column.text}
            <img src="/media/VRekruitIcons/sort.svg" className="sort-icon c-p" width="30" height="30" alt="" />
      </div>
    );
  }


  function headerformatterAction(column, userdetails) {
    return (
      <div>
            {column.text}
      </div>
    );
  }


  function  UserFormater(column, userdetails) {
    return(
      <span>
        {userdetails.firstName}  {userdetails.lastName} 
      </span>
    );
  }





  

  
function PhoneFormatter(column, userdetails) {
  return(
    <span>
          {userdetails.dialCode}  {userdetails.phone} 
    </span>
  );
}




function StatusFormatter(column, userdetails) {
  return(
    <span>
          {userdetails && userdetails.isDeleted === true ? "inactive":"active" }    
    </span>
  );
}


function  RoleFormater(column, userdetails) {
    var RoleType ="";
    if(userdetails.roleId === 1){
        RoleType = "Super Admin"
       }  
      else if(userdetails.roleId === 2){
        RoleType = "Company Admin"
      }  
      else if(userdetails.roleId === 3){
        RoleType = "Hiring Manger"
      }  
      else if(userdetails.roleId === 4){
        RoleType = "Recruiter"
      }
    return(
    <>
        
      <span>
        {RoleType} 
      </span>
      </>
    );
  }



  function  JobTitleFormatter(column, userdetails) {
    return(
      <div className="">
          {column}  
      </div>
    );
  }

  


 var columns = [{
    dataField: 'empId',
    text: "Employee Id",
    sort: true,
    headerFormatter: headerformatter
  },
  {
    dataField: 'firstName',
    text: "Name",
    sort: true,
    formatter:UserFormater,
    headerFormatter: headerformatter
  },

  {
    dataField: 'roleId',
    text: "Role",
    sort: true,
    formatter:RoleFormater,
    headerFormatter: headerformatter
  },
  {
    dataField: 'email',
    text: "Email",
    sort: true,
    formatter:JobTitleFormatter,
    headerFormatter: headerformatter
  },

  {
    dataField: 'phone',
    text: "Phone",
    sort: true,
    formatter:PhoneFormatter,
    headerFormatter: headerformatter
  },

  {
    dataField: 'isDeleted',
    text: "Status",
    sort: true,
    formatter:StatusFormatter,
    headerFormatter: headerformatter
  },

  {
    dataField: "action",
    text: "Action",
    sort: true,
    formatter: actionFormatter,
    headerFormatter: headerformatterAction
  },
  ];

  const options = {
    paginationSize: 5,
    // custom: true,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    //  withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,    
    onPageChange: (page, sizePerPage) => {
    },
    disablePageTitle: true,
    sizePerPageList: [ {
      text: '20', value: 20
    },{
    text: '50', value: 50
    },
    {
      text: 'All', value: requistions.length
    }] // A numeric array is also available. the purpose of above example is custom the text    
  };


  const[ delId,setDeleteId] = useState();
  const DeleteUser = (delId) => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/delete_user/` + delId, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setModalShow3(false);
          alertService.alert({ message: res.message, type: 'error' });
          get_Requisition_List();
        }
        else {
          setModalShow3(false);
          alertService.alert({ message: res.message, type: 'error' });
        }
      })
      .catch(() => {
      });  
  }

  
  const [modalShow3, setModalShow3] = React.useState(false);
  function DeleteConfirmationModal(props) {
    return (
    <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            <p className="text-center">
            <img alt="logo" className="brand-dim" src="/media/logos/UPDATED_VRekruit _Logo.png" />
              </p>
            <p className="text-dark text-center pt-3"> Are you sure want to delete this User's Account?</p>
          </Modal.Title>
        </Modal.Body>
        <Modal.Title className="d-flex justify-content-center align-items-center pb-5" >
        <p className="text-center">

        <Button className="btn btn-theme text-white btn-lg mr-5  pl-5 pr-5 pt-3 pb-3" style={{"minWidth": "100px"}} onClick={() => DeleteUser(delId)}> Yes </Button>
       <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green" style={{"minWidth": "100px"}}  onClick={props.onHide}>No</Button> 
       </p>
        </Modal.Title>
      </Modal>
    );
  }



  if(localStorage.getItem("userTypeId") === "3")
  {
      
     return ""
    //  history.push("/error/error-v1")
  
  }
  else {



  return (
    <>
      <Helmet>
        <title>
          User List
        </title>
      </Helmet>
      {/* subeader */}
      <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">User Management</h3></div>
          </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
      {/* subeader */}
  <Col className={classes.root} >   
    <Row className="bg-white mb-0 pb-0  sticky-header"> 
    <Col md={6} sm={6} offset= {4}  className="pr-3 pl-3 mt-3">      
      <div>
       <div className="   rounded">     
        <div>
        <h5 className="font-weight-bold f-w-600 pl-5 mt-3" >User List</h5>
        </div>
      </div>
      </div>
      </Col>
      <Col md={6} sm={6} offset= {4}  className="pr-3 pl-3 mt-3">
      <div>
       <div className="d-flex flex-grow-1 align-items-center justify-content-end rounded mt-1">
        <div className="text-muted d-block">
           <Link to="/user/add" type="button" className="btn btn-theme-green btn-lg mr-4 mt-2 mb-0"  >
              Create User
           </Link>
       
      </div>
      </div>
      </div>
      </Col>
      <Col md={12} sm={12} className="separator separator-solid mb-0 pt-5"></Col>  
      </Row>


      <Row className="bg-white  d-flex flex-grow-1 align-items-center  justify-content-start mb-sm-5 pt-5">

      </Row>
        <Col className="Content-custom-padding" className="mb-sm-5">
          <Row className="align-items-center">
            <Col sm={12} md={2} className="mb-sm-5">
              <Form.Control as="select" placeholder="Status"  value={role}  onChange={(e) => SetRoleData(e)} >
                <option value="">Role</option>
                <option value="4"> Recruiter </option>
                <option value="3"> Hiring Manager </option>
                {  localStorage.getItem("userTypeId") !=="2"  ?      <option value="2"> Company Admin </option> : "" }
              </Form.Control>
            </Col>


            <Col sm={12} md={3} className="d-flex mb-sm-5">
            <Form.Label >
              </Form.Label>
          <div style={{"position":"relative","width":"100%"}}>          
            <div className="input-group" >
              <Form.Control type="text" value={searchTerm ? searchTerm : "" }  placeholder="Search..." id="search-text" className="border-radius-12" onInput={(e) => SetSearchTermData(e)}  style={{"paddingLeft":"35px",}} />
              <div className="input-group-prepend">
             </div>
              </div>
              <i className="fa fa-search"  style={{"position":"absolute","left":"7px","top": "50%","transform": "translate(7px, -50%)","borderRadius":"8px"}} ></i> 
              </div>
            </Col>
            <Col  sm={12} md={7} className="text-right mb-sm-5">
            <Button  type="button" className="btn btn-search ml-5" id="filter-btn" onClick={get_Requisition_List}  style={{"borderRadius":"8px"}} >
                 Apply
              </Button>
            <Button  type="button" className="btn btn-reset ml-5"  id="reset-filter-btn"  onClick={resetFilter}  style={{"borderRadius":"8px"}} >
                  Reset
              </Button>
            </Col>
          </Row>
          <div style={{"minHeight": "50vh"}}>
            {requistions &&  requistions.length > 20 &&  requistions.length > 0  && (
              <BootstrapTable className="bg-white" keyField='id' data={requistions} columns={columns} options={options} pagination={paginationFactory(options)} rowEvents={actionControls} />
            )}
           {requistions &&  requistions.length === 0  && (
             <>
              <BootstrapTable className="bg-white" keyField='id' data={requistions} columns={columns} options={options}  rowEvents={actionControls} />
              {!loading ?
         
             <p className="text-center mt-5 pt-5" style={{"fontSize":"30px","minHeight":"200px","display":"flex","justifyContent":"center","alignItems":"center"}}>
                No Records Found
             </p>
              :""}


             {loading ?
            <p className="text-center mt-5 pt-5" style={{"fontSize":"30px","minHeight":"200px","display":"flex","justifyContent":"center","alignItems":"center"}}> 
              <ele id="no-data">  
                 <i className="fas fa-spinner fa-pulse fa-2x"></i>
              </ele> 
            </p>
             :""}




              </>
           )}
            {requistions  && requistions.length <= 20 && requistions.length > 0 &&
            (
              <BootstrapTable className="bg-white" keyField='id' data={requistions} columns={columns} options={options}  rowEvents={actionControls} />
            )
            }
          </div>
        </Col>
      </Col>
      <DeleteConfirmationModal show={modalShow3} onHide={() => setModalShow3(false)} />
    </>
  );
          }
}

export default UserList