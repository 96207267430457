/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Helmet from 'react-helmet'
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import AsyncSelect from 'react-select/async';
import alertService from '../../_services/alertservice';
import { useHistory, Link } from "react-router-dom";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReactFlagsSelect from 'react-flags-select';
import Modal from 'react-bootstrap/Modal'
import axios from 'axios';
import { useDispatch } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { logout } from '../../../actions/auth';
import { initial } from 'lodash';





const animatedComponents = makeAnimated();

const initialValues = {
  companyName: "",
  companyWebsite: "",
  logo: "",
  companyPhone: "",
  companyPan:"",
  companyGst:"",
  companyAddress: "",
  companyCountry: "",
  companyDialCode: "",
  companyImages: [""],
  companyIntroVideos: [""],
  companyIntroVideoNames: [""],
  companyThanksVideoNames: [],
  companyThanksVideos: [""],
  companyYoutubeLinks: [""],
  companyVideo: [],
  companyPresentation: "",
  companyStateId:"",
  companyState:""
};


function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));


function EditCompanyDetails() {
  const [colourOptions, setColourOptions] = useState([]);
  const [name, setName] = useState([]);
  const [idList, setIdList] = useState("");
  const [pdFileName, SetSeletedFileName] = useState("");
  const [hasfile, Sethasfile] = useState(false);


  const uploadFile = (x) => {
    // alert('Selected file: ' + x.target.files[0].name);
    SetSeletedFileName(x.target.files[0].name);
    if (x.target.files[0].name) {
      Sethasfile(true);
    }
    else {
      Sethasfile(false)
    }
    // formik.values.file =  x.target.files[0].name;
  }

  const dispatch = useDispatch();

  const logoutMethod = () => {
    dispatch(logout());
    history.push('/auth/signin');
  };


  const history = useHistory();
  const [companyDetails, setCompanyDetails] = useState([]);
  const getCompanyDetails = () => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setCompanyDetails(res.data);
          initialValues.companyName = res.data.companyName;
          initialValues.companyWebsite = res.data.companyWebsite;
          initialValues.companyAddress = res.data.companyAddress;
          initialValues.companyGst= res.data.companyGst;
          initialValues.companyPan= res.data.companyPan;
          initialValues.companyPhone = res.data.companyPhone;
          initialValues.logo = res.data.logo;
          setSelectedState(res.data.companyStateId);
         
         if(res.data.companyStateId !== 0){
          setStateName({  
            countryId: res.data.companyCountryId,
            createdAt: null,
            id: res.data.companyStateId,
            state: res.data.companyState,
            updatedAt: null }   ); 
           
         }
    
          setLogoName(res.data.logo);
          SetPresentationFile(res.data.companyPresentation)
          setCompanyPhone(res.data.companyPhone);
          setCompanyDialCode(res.data.companyDialCode);
          setSelected(res.data.companyCountry);
          initialValues.companyCountry = res.data.companyCountry;
          setVideoFileName(res.data.companyVideo);
          var introVideo = res.data.companyIntroVideos.split(",").map((ele, ind) => {
            return { url: ele, name: res.data.companyIntroVideoNames.split(",")[ind] }
          });
          var thankVideo = res.data.companyThanksVideos.split(",").map((ele, ind) => {
            return { url: ele, name: res.data.companyThanksVideoNames.split(",")[ind] }
          });

          var ytvideos = res.data.companyYoutubeLinks.split(",").map((ele, ind) => {
            return { url: res.data.companyYoutubeLinks.split(",")[ind] }
          });

          var CompanyImages = res.data.companyImages.split(",").map((ele, ind) => {
            return { url: res.data.companyImages.split(",")[ind] }
          });


          setCompanyImages(CompanyImages);

          SetFormData({
            ...formData,
            introVideos: introVideo,
            thanksVideos: thankVideo,
            youtubeVideos: ytvideos,
            companyImages: CompanyImages
          });

          setIntroVideos(introVideo);
          setThanksVideos(thankVideo);
          setYoutubeVideos(ytvideos);
          formik.resetForm();
          //setSt(res.data);
        }
        else if (res.sessionExpired) {
          logoutMethod();
          alertService.alert({ message: res.message, type: 'error' });
        }
      })
      .catch(() => {
      });
  }

  const [isformSubmitted, SetisformSubmitted] = useState(false)
   const[loading,SetLoading]=useState(false);

  const edit = (values) => {
    SetisformSubmitted(true)
    if (companyPhoneErrorMSg || !stateName) {
      return
    }

    // code to add intro video names and thanks video names mandatory if video uploadeed
    // else if (formData.introVideos.some(x=> x.url && !x.name) || formData.thanksVideos.some(x=> x.url && !x.name)) {
    //   return;
    // }


    else {
      var id = companyDetails.id;
      const reqData = {
        companyName: values.companyName,
        companyAddress: values.companyAddress,
        companyWebsite: values.companyWebsite,
        companyPan: values.companyPan,
        companyGst: values.companyGst,
        companyCountry: "india",
        logo: logo,
        companyVideo: videoFileName,
        companyImages: formData.companyImages.filter(x => x.url).map(x => x.url).join(),
        companyIntroVideoNames: formData.introVideos.filter(x => x.url).map(x => x.name).join(),
        companyIntroVideos: formData.introVideos.filter(x => x.url).map(x => x.url).join(),
        companyThanksVideoNames: formData.thanksVideos.filter(x => x.url).map(x => x.name).join(),
        companyThanksVideos: formData.thanksVideos.filter(x => x.url).map(x => x.url).join(),
        companyYoutubeLinks: formData.youtubeVideos.filter(x => x.url).map(x => x.url).join(),
        companyPresentation: Presentation,
        companyCountry: selected,
        companyPhone: companyPhone,
        companyDialCode: companyDialCode,
        companyStateId: selectedState
      }
      const token = localStorage.getItem('x-access-token');
      SetLoading(true);
      fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/` + id, {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          'x-access-token': token
        },
        body: JSON.stringify(reqData)
      })
        .then((res) => res.json())
        .then((res) => {

          if (res.sessionExpired) {
            logoutMethod();
            SetLoading(false);
            alertService.alert({ message: res.message, type: 'error' });
          }
          else  if (res.status == true) {
            alertService.alert({ message: res.message, type: 'success' });
            SetLoading(false);
            history.push('/company-mangement/view');
          }
          else {
            alertService.alert({ message: res.message, type: 'error' });
            SetLoading(false);
          }
        })
        .catch(() => {
          SetLoading(false);
        });
    }

  }

  useEffect(() => {
    let mounted = true;
    getCompanyDetails();
    getStateList(); 

    return () => mounted = false;
  }, [])


  const AddNewRequistionSchema = Yup.object().shape({
    // companyName: Yup.string()
    //    .required('Required'),
    companyWebsite: Yup.string()
      .required('Required'),
    companyName: Yup.string()
      .required('Required'),
    companyAddress: Yup.string()
      .required('Required'),
    companyCountry: Yup.string()
      .required('Required'),

  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const formik = useFormik({

    initialValues,
    validationSchema: AddNewRequistionSchema,
    validateOnChange: true,
    validateOnBlur: false,
    validator: () => { },
    onSubmit: (values) => {
      edit(values);
    },
  });


  const [formData, SetFormData] = useState(
    {
      introVideos: [{ url: "", name: "" }],
      companyImages: [{ url: "" }],
      thanksVideos: [{ url: "", name: "" }],
      youtubeVideos: [{ url: "" }],
    }
  )

  const addMoreIntroVideo = () => {
    if (formData.introVideos.length > 2) {
      alertService.alert({ message: "Maximmun 3 videos are Allowed", type: 'info' });
    }
    else {
      SetFormData({
        ...formData,
        introVideos: [...formData.introVideos, { url: "", name: "" }],
      });
      setIntroVideos(formData.introVideos);
      //  formData.introVideos.push("");
    }
  }

  const [thanksVideos, setThanksVideos] = useState([{ name: "", url: "" }]);
  const addMoreThanksVideo = () => {
    if (formData.thanksVideos.length > 4) {
      alertService.alert({ message: "Maximmun 5 videos are Allowed", type: 'info' });
    }
    else {
      SetFormData({
        ...formData,
        thanksVideos: [...formData.thanksVideos, { url: "", name: "" }],
      });
      setThanksVideos(formData.thanksVideos);
      //  formData.introVideos.push("");
    }
  }


  const [companyImages, setCompanyImages] = useState([{ url: "" }]);
  const addMoreCompanyImages = () => {
    if (formData.companyImages.length > 4) {
      alertService.alert({ message: "Maximmun 5 images are Allowed", type: 'info' });
    }
    else {
      SetFormData({
        ...formData,
        companyImages: [...formData.companyImages, { url: "" }],
      });
      setCompanyImages(formData.companyImages);

      //  formData.introVideos.push("");
    }
  }

  const [youtubeVideos, setYoutubeVideos] = useState([{ url: "" }]);
  const addMoreYoutubeVideo = () => {
    if (formData.youtubeVideos.length > 2) {
      alertService.alert({ message: "Maximmun 3 videos Links are Allowed", type: 'info' });
    }
    else {
      SetFormData({
        ...formData,
        youtubeVideos: [...formData.youtubeVideos, { url: "" }],
      });
      setYoutubeVideos(formData.youtubeVideos);
      //  formData.introVideos.push("");
    }
  }


  const [deleteCompanyIntroURL, SetCompanyIntroURL] = useState("");
  const [deleteCompanyIntroIndex, SetCompanyIntroIndex] = useState("");
  // delete Intro Video
  const deleteIntrovideo = (ind, ele) => {
    setIntroShow(true);
    SetCompanyIntroURL(ele);
    SetCompanyIntroIndex(ind);
  }

  const ConfirmDeleteIntroVideo = (deleteCompanyIntroIndex) => {
    if (formData.introVideos.length > 1) {
      const newintroVideos = [...formData.introVideos];
      newintroVideos.splice(deleteCompanyIntroIndex, 1);
      SetFormData({
        ...formData,
        introVideos: newintroVideos,
      })
    }
    else {
      SetFormData({
        ...formData,
        introVideos: [{ url: "", name: "" }]
      });
      setIntroVideos([{ url: "", name: "" }]);
    }
    setIntroShow(false);
  }



  // delete PRESENTATION Video
  const deletePresentationFile = (url) => {
    setPresentationShow(true);
  }

  const confrimDeletePresentationFile = () => {
    SetPresentationFile("")
    setPresentationShow(false);
  }


  const [deleteThanksVideoURL, SetThanksVideoURL] = useState("");
  const [deleteThanksVideoIndex, SetThanksVideoIndex] = useState("");

  // delete Thanks Video
  const deleteThanksvideo = (ind, ele) => {
    SetThanksVideoShow(true);
    SetThanksVideoURL(ele);
    SetThanksVideoIndex(ind);
  }

  const ConfirmDeleteThanksVideo = (deleteThanksVideoIndex, deleteThanksVideoURL) => {
    if (formData.thanksVideos.length > 1) {
      const newthanksVideos = [...formData.thanksVideos];
      newthanksVideos.splice(deleteThanksVideoURL, 1);
      SetFormData({
        ...formData,
        thanksVideos: newthanksVideos,
      })
    }
    else {

      SetFormData({
        ...formData,
        thanksVideos: [{ url: "", name: "" }],
      });
      setThanksVideos([{ url: "", name: "" }]);
    }
    SetThanksVideoShow(false);

  }




  // delete Youtube Video
  const [deleteYoutubeVideoURL, SetYoutubeVideoURL] = useState("");
  const [deleteYoutubeVideoIndex, SetYoutubeVideoIndex] = useState("");
  const deleteYoutubevideo = (ind, ele) => {
    SetYoutubeVideoShow(true);
    SetYoutubeVideoURL(ele);
    SetYoutubeVideoIndex(ind);
  }

  const confirmDeleteYoutubeVideo = (deleteYoutubeVideoIndex, deleteYoutubeVideoURL) => {
    if (formData.youtubeVideos.length > 1) {
      const newyoutubeVideos = [...formData.youtubeVideos];
      newyoutubeVideos.splice(deleteYoutubeVideoIndex, 1);
      SetFormData({
        ...formData,
        youtubeVideos: newyoutubeVideos,
      })
      SetYoutubeVideoShow(false);
    }
    else {
      SetFormData({
        ...formData,
        youtubeVideos: [{ url: "" }],
      })
      setYoutubeVideos([{ url: "" }]);
      SetYoutubeVideoShow(false);
    }

  }


  // delete Company Images
  const [DeleteCompanyImgURL, SetDeleteCompanyImgURL] = useState("");
  const [DeleteCompanyImgIndex, SetDeleteCompanyImgIndex] = useState("");

  const deleteCompanyImages = (ind, ele) => {
    SetCompanyImageShow(true);
    SetDeleteCompanyImgURL(ele);
    SetDeleteCompanyImgIndex(ind);
  }

  const confirmdeleteCompanyImage = (DeleteCompanyImgIndex, DeleteCompanyImgURL) => {
    if (formData.companyImages.length > 1) {
      const newCompanyImages = [...formData.companyImages];
      newCompanyImages.splice(DeleteCompanyImgIndex, 1);
      SetFormData({
        ...formData,
        companyImages: newCompanyImages
      })
    }
    else {
      SetFormData({
        ...formData,
        companyImages: [{ url: "" }],
      })
      setCompanyImages([{ url: "" }])
    }
    SetCompanyImageShow(false)
  }


  // delete Company Video 
  const deleteFileVideo = (url) => {
    SetCompanyVideoShow(true);
  }

  const ConfirmdeleteCompanyVideo = () => {
    setVideoFileName("");
    SetCompanyVideoShow(false);
  }



  // delete Company Logo 
  const deletefileLogo = (url) => {
    setModalShow(true);
  }

  const ConfirmdeleteCompanyLogo = () => {
    setLogoName("");
    setModalShow(false);
  }



  const [companyVideo, setCompanyVideo] = useState()

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  function handleChangeIndex(index) {
    setValue(index);
  }


  const [companyPhone, setCompanyPhone] = useState("");
  const [companyDialCode, setCompanyDialCode] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneErrorMSg, setPhoneErrorMsg] = useState("");
  const [companyPhoneErrorMSg, setCompanyPhoneErrorMsg] = useState("");


  const handlePhoneChange = (value, data, event, formattedValue) => {
    setPhone(value.slice(data.dialCode.length));
    setDialCode("+" + data.dialCode);
    setCompanyPhone(value.slice(data.dialCode.length));
    setCompanyDialCode("+" + data.dialCode)
  }

  const [selected, setSelected] = useState("");
  const onSelect = (code, a, c, g) => {
    setSelected(code);
  }

  const triggerClick = () => {
    let ClickItem = document.getElementById("fileUpload-inp");
    if (ClickItem) {
      ClickItem.click();
    }
  }


  const triggerClickCompanypresentation = () => {
    let ClickItem = document.getElementById("fileUpload-presentation");
    if (ClickItem) {
      ClickItem.click();
    }
  }


  const triggerComapanyImagesUpload = (inputId) => {
    let ClickItem = document.getElementById(inputId);
    if (ClickItem) {
      ClickItem.click();
    }
  }


  const triggerInrtovideosUpload = (inputId) => {
    let ClickItem = document.getElementById(inputId);
    if (ClickItem) {
      ClickItem.click();
    }
  }

  const triggerThanksvideosUpload = (inputId) => {

    let ClickItem = document.getElementById(inputId);
    if (ClickItem) {
      ClickItem.click();
    }
  }

  const triggerComapnyVideo = (inputId) => {

    let ClickItem = document.getElementById(inputId);
    if (ClickItem) {
      ClickItem.click();
    }
  }

  const [logoUrl, setFileUrl] = useState();





  const [deleteItems, setDeleteItems] = useState([]);
  const deleteselectedFiels = () => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/delete_file`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify({
        fileName: [deleteItems]
      })
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          //  view_requisition();
          setLogoName("");
          alertService.alert({ message: res.message, type: 'success' });
        }
        else {
          alertService.alert({ message: res.message, type: 'error' });

        }
      })
  }






  const [deletefileURLs, setDeleteFileURLs] = useState([]);

  const [modalShow, setModalShow] = React.useState(false);

  function DeleteConfirmationModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            <p className="text-center">
              <img alt="logo" className="brand-dim" src="/media/logos/UPDATED_VRekruit _Logo.png" />
            </p>
            <p className="text-dark text-center pt-3"> Are you sure want to delete Company Logo?</p>
          </Modal.Title>
        </Modal.Body>
        <Modal.Title className="d-flex justify-content-center align-items-center pb-5" >
          <p className="text-center">

            <Button className="btn btn-theme text-white btn-lg mr-5  pl-5 pr-5 pt-3 pb-3 delete" style={{ "minWidth": "100px" }} onClick={ConfirmdeleteCompanyLogo}> Yes </Button>
            <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green delete" style={{ "minWidth": "100px" }} onClick={props.onHide}>No</Button>
          </p>
        </Modal.Title>
      </Modal>
    );
  }


  const [presentationShow, setPresentationShow] = React.useState(false);

  function DeleteaPresentationFileConfirmationModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            <p className="text-center">
              <img alt="logo" className="brand-dim" src="/media/logos/UPDATED_VRekruit _Logo.png" />
            </p>
            <p className="text-dark text-center pt-3"> Are you sure want to  delete Presentation file?</p>
          </Modal.Title>
        </Modal.Body>
        <Modal.Title className="d-flex justify-content-center align-items-center pb-5" >
          <p className="text-center">

            <Button className="btn btn-theme text-white btn-lg mr-5  pl-5 pr-5 pt-3 pb-3 delete" style={{ "minWidth": "100px" }} onClick={confrimDeletePresentationFile}> Yes </Button>
            <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green delete" style={{ "minWidth": "100px" }} onClick={props.onHide}>No</Button>
          </p>
        </Modal.Title>
      </Modal>
    );
  }


  const [introShow, setIntroShow] = React.useState(false);

  function DeleteaintroConfirmationModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            <p className="text-center">
              <img alt="logo" className="brand-dim" src="/media/logos/UPDATED_VRekruit _Logo.png" />
            </p>
            <p className="text-dark text-center pt-3"> Are you sure want to  delete Introductory Video?</p>
          </Modal.Title>
        </Modal.Body>
        <Modal.Title className="d-flex justify-content-center align-items-center pb-5" >
          <p className="text-center">

            <Button className="btn btn-theme text-white btn-lg mr-5  pl-5 pr-5 pt-3 pb-3 delete" style={{ "minWidth": "100px" }} onClick={() => ConfirmDeleteIntroVideo(deleteCompanyIntroIndex, deleteCompanyIntroURL)}> Yes </Button>
            <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green delete" style={{ "minWidth": "100px" }} onClick={props.onHide}>No</Button>
          </p>
        </Modal.Title>
      </Modal>
    );
  }


  const [companyImageShow, SetCompanyImageShow] = React.useState(false);

  function DeletecompanyImageonfirmationModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            <p className="text-center">
              <img alt="logo" className="brand-dim" src="/media/logos/UPDATED_VRekruit _Logo.png" />
            </p>
            <p className="text-dark text-center pt-3"> Are you sure want to  delete Company Image?</p>
          </Modal.Title>
        </Modal.Body>
        <Modal.Title className="d-flex justify-content-center align-items-center pb-5" >
          <p className="text-center">

            <Button className="btn btn-theme text-white btn-lg mr-5  pl-5 pr-5 pt-3 pb-3 delete" style={{ "minWidth": "100px" }} onClick={(e) => confirmdeleteCompanyImage(DeleteCompanyImgIndex, DeleteCompanyImgURL)}  > Yes </Button>
            <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green delete" style={{ "minWidth": "100px" }} onClick={props.onHide}>No</Button>
          </p>
        </Modal.Title>
      </Modal>
    );
  }


  const [companyVideoShow, SetCompanyVideoShow] = React.useState(false);

  function DeletecompanyVideofirmationModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            <p className="text-center">
              <img alt="logo" className="brand-dim" src="/media/logos/UPDATED_VRekruit _Logo.png" />

            </p>
            <p className="text-dark text-center pt-3"> Are you sure want to  delete Company Video?</p>
          </Modal.Title>
        </Modal.Body>
        <Modal.Title className="d-flex justify-content-center align-items-center pb-5" >
          <p className="text-center">

            <Button className="btn btn-theme text-white btn-lg mr-5  pl-5 pr-5 pt-3 pb-3 delete" style={{ "minWidth": "100px" }} onClick={ConfirmdeleteCompanyVideo} > Yes </Button>
            <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green delete" style={{ "minWidth": "100px" }} onClick={props.onHide}>No</Button>
          </p>
        </Modal.Title>
      </Modal>
    );
  }




  const [thankVideoShow, SetThanksVideoShow] = React.useState(false);

  function DeleteThankVideofirmationModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            <p className="text-center">
              <img alt="logo" className="brand-dim" src="/media/logos/UPDATED_VRekruit _Logo.png" />
            </p>
            <p className="text-dark text-center pt-3"> Are you sure want to  delete Thank You Video?</p>
          </Modal.Title>
        </Modal.Body>
        <Modal.Title className="d-flex justify-content-center align-items-center pb-5" >
          <p className="text-center">

            <Button className="btn btn-theme text-white btn-lg mr-5  pl-5 pr-5 pt-3 pb-3 delete" style={{ "minWidth": "100px" }} onClick={() => ConfirmDeleteThanksVideo(deleteThanksVideoIndex, deleteThanksVideoURL)} > Yes </Button>
            <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green delete" style={{ "minWidth": "100px" }} onClick={props.onHide}>No</Button>
          </p>
        </Modal.Title>
      </Modal>
    );
  }


  const [youtubeVideoShow, SetYoutubeVideoShow] = React.useState(false);

  function DeleteYTVideofirmationModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            <p className="text-center">
              <img alt="logo" className="brand-dim" src="/media/logos/UPDATED_VRekruit _Logo.png" />
            </p>
            <p className="text-dark text-center pt-3"> Are you sure want to  delete Youtube Video?</p>
          </Modal.Title>
        </Modal.Body>
        <Modal.Title className="d-flex justify-content-center align-items-center pb-5" >
          <p className="text-center">

            <Button className="btn btn-theme text-white btn-lg mr-5  pl-5 pr-5 pt-3 pb-3 delete" style={{ "minWidth": "100px" }} onClick={() => confirmDeleteYoutubeVideo(deleteYoutubeVideoIndex, deleteYoutubeVideoURL)} > Yes </Button>
            <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green delete" style={{ "minWidth": "100px" }} onClick={props.onHide}>No</Button>
          </p>
        </Modal.Title>
      </Modal>
    );
  }







  // Logo File Upload
  const handleUpload = (ev) => {
    let file = ev.target.files[0];
    if (file) {
      var bytes = ev.target.files[0].size;
      var Mbytes = bytes / (1024 * 1024);
    }
    if (file && Mbytes < 20) {
      // Split the filename to get the name and type
      let fileParts = ev.target.files[0].name.split('.');
      let fileType = fileParts.slice(-1).pop()
      if (fileType === "png" || fileType === "jpg" || fileType === "jpeg") {

        axios.post(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/upload_file_company`, {
          fileName: fileParts[0] + "." + fileParts[1],
          fileType: "image/" + fileType,
          fileFolder: "image"
        },
          {
            headers: {
              'x-access-token': localStorage.getItem('x-access-token'),
            }
          })
          .then(response => {

            if (response.data.sessionExpired) {
              logoutMethod();
              alertService.alert({ message: response.data.message, type: 'error' });
            }
            else if (response.status) {
              var returnData = response.data.data.returnData;
              var signedRequest = returnData.signedRequest;
              var url = returnData.url;
              setFileUrl(url);
              setLogoName(returnData.url);
              if (url) {
                initialValues.logo = returnData.url;
              }
              // Put the fileType in the headers for the upload
              var options = {
                headers: {
                  'Content-Type': 'image/' + fileParts[1],
                  'Content-Disposition': "inline",
                }
              };
              axios.put(signedRequest, file, options)
                .then(result => {
                })
                .catch(error => {
                  if (error.message) {
                    alertService.alert({ message: JSON.stringify(error.message), type: 'error' });
                  }
                  else {
                    alertService.alert({ message: JSON.stringify(error), type: 'error' });
                  }
                })
            }


          })
          .catch(error => {
            if (error.message) {
              alertService.alert({ message: JSON.stringify(error.message), type: 'error' });
            }
            else {
              alertService.alert({ message: JSON.stringify(error), type: 'error' });
            }
          })

      }
      else {
        alertService.alert({ message: "File Format is Not Allowed", type: 'error' });

      }




    }
    else if (file && Mbytes > 20) {
      alertService.alert({ message: "Max Allowed File Size is 20MB", type: 'error' });
    }
  }




  const deleteCompanyImage = (url) => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/delete_file`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify({
        fileName: [url]
      })

    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          //  view_requisition();
          url = "";
          alertService.alert({ message: res.message, type: 'success' });
        }
        else {
          alertService.alert({ message: res.message, type: 'error' });

        }
      })
  }



  const handleCompanyImagesUpload = (ev, index) => {
    let file = ev.target.files[0];
    // if(ev.target.files[0].size > 20000){
    //   alertService.alert({ message: "max file size 20MB is allowed", type: 'error' });
    // }
    if (file) {
      // Split the filename to get the name and type
      let fileParts = ev.target.files[0].name.split('.');
      let fileType = fileParts.slice(-1).pop() //fileParts[1];

      axios.post(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/upload_file_company`, {
        fileName: fileParts[0] + "." + fileParts[1],
        fileType: "image/" + fileType,
        fileFolder: fileType == 'jpeg' ? "image" : "file"
      },
        {
          headers: {
            'x-access-token': localStorage.getItem('x-access-token'),
          }
        })
        .then(response => {
          if (response.data.status) {
            var returnData = response.data.data.returnData;
            var signedRequest = returnData.signedRequest;
            var url = returnData.url;
            if (url) {
              initialValues.companyImages[index] = returnData.url;
            }
            // Put the fileType in the headers for the upload
            var options = {
              headers: {
                'Content-Type': 'image/' + fileType,
                'Content-Disposition': "inline",
              }
            };
            axios.put(signedRequest, file, options)
              .then(result => {
              })
              .catch(error => {
                if (error.message) {
                  alertService.alert({ message: JSON.stringify(error.message), type: 'error' });
                }
                else {
                  alertService.alert({ message: JSON.stringify(error), type: 'error' });
                }
              })
          }
          else if (response.data.sessionExpired) {
            logoutMethod();
            alertService.alert({ message: response.data.message, type: 'error' });
          }
        })
        .catch(error => {
          if (error.message) {
            alertService.alert({ message: JSON.stringify(error.message), type: 'error' });
          }
          else {
            alertService.alert({ message: JSON.stringify(error), type: 'error' });
          }
        })
    }
  }

  const [videoFileName, setVideoFileName] = useState();
  const [introVideos, setIntroVideos] = useState([{ name: "", url: "" }]);




  const handleVideoFileUpload = (ev, cb) => {
    let file = ev.target.files[0];
    if (file) {
      var Parts = file.name.split('.');
      var Type = Parts.slice(-1).pop() //Parts[1];
    }

    if (file && Type === "mp4") {
      var bytes = ev.target.files[0].size;
      var Mbytes = bytes / (1024 * 1024);
      window.URL = window.URL || window.webkitURL;
      var video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        //in seconds duration
        var duration = video.duration;
        if (duration > 120 && duration != "Infinity") {
          alertService.alert({ message: "Max Allowed Video Duration is 2 mins", type: 'error' });
          return;
        }
        else {
          if (file && Mbytes < 200) {
            // Split the filename to get the name and type
            let fileParts = file.name.split('.');
            let fileType = fileParts.slice(-1).pop() //fileParts[1];

            axios.post(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/upload_file_company`, {
              fileName: fileParts[0] + "." + fileType,
              fileType: "video/" + fileType,
              fileFolder: "video",
            },
              {
                headers: {
                  'x-access-token': localStorage.getItem('x-access-token'),
                }
              })
              .then(response => {
                if (response.data.sessionExpired) {
                  logoutMethod();
                  alertService.alert({ message: response.data.message, type: 'error' });
                }
                else if (response.status) {
                  var returnData = response.data.data.returnData;
                  var signedRequest = returnData.signedRequest;
                  cb(response);

                  // Put the fileType in the headers for the upload
                  var options = {
                    headers: {
                      'Content-Type': 'video/' + fileType,
                      'Content-Disposition': "inline"
                    }
                  };
                  axios.put(signedRequest, file, options)
                    .then(result => {
                      // alertService.alert( {message:JSON.stringify(response.message),type:'info'});
                    })
                    .catch(error => {
                      if (error.message) {
                        alertService.alert({ message: JSON.stringify(error.message), type: 'error' });
                      }
                      else {
                        alertService.alert({ message: JSON.stringify(error), type: 'error' });
                      }
                    })

                }

              })
              .catch(error => {
                if (error.message) {
                  alertService.alert({ message: JSON.stringify(error.message), type: 'error' });
                }
                else {
                  alertService.alert({ message: JSON.stringify(error), type: 'error' });
                }
              })
          }

          else if (file && Mbytes > 200) {
            alertService.alert({ message: "Max Allowed File Size is 200MB", type: 'error' });
          }

        }
      }
      video.src = URL.createObjectURL(file);;
    }

    else if (file && Type !== "mp4") {
      alertService.alert({ message: "File Format is Not Allowed", type: 'error' });
    }

  }


  const handleImageFilesUpload = (ev, cb) => {
    let file = ev.target.files[0];

    if (file) {
      var bytes = ev.target.files[0].size;
      var Mbytes = bytes / (1024 * 1024);
    }

    if (file && Mbytes < 20) {

      let fileParts = ev.target.files[0].name.split('.');
      let fileType = fileParts.slice(-1).pop()
      if (fileType === "png" || fileType === "jpg" || fileType === "jpeg") {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/upload_file_company`, {
          fileName: fileParts[0] + "." + fileParts[1],
          fileType: "image/" + fileType,
          fileFolder: "image",
        },
          {
            headers: {
              'x-access-token': localStorage.getItem('x-access-token'),
            }
          })
          .then(response => {
            if (response.data.sessionExpired) {
              logoutMethod();
              alertService.alert({ message: response.data.message, type: 'error' });
            }

            else if (response.status) {
              var returnData = response.data.data.returnData;
              var signedRequest = returnData.signedRequest;
              cb(response);

              // Put the fileType in the headers for the upload
              var options = {
                headers: {
                  'Content-Type': 'image/' + fileType,
                  'Content-Disposition': "inline",
                }
              };
              axios.put(signedRequest, file, options)
                .then(result => {
                })
                .catch(error => {
                  if (error.message) {
                    alertService.alert({ message: JSON.stringify(error.message), type: 'error' });
                  }
                  else {
                    alertService.alert({ message: JSON.stringify(error), type: 'error' });
                  }
                })
            }
          })
          .catch(error => {
            if (error.message) {
              alertService.alert({ message: JSON.stringify(error.message), type: 'error' });
            }
            else {
              alertService.alert({ message: JSON.stringify(error), type: 'error' });
            }
          })
      }
      else {
        alertService.alert({ message: "File Format is Not Allowed", type: 'error' });
      }
    }
    else if (file && Mbytes > 20) {
      alertService.alert({ message: "Max Allowed File Size is 20MB", type: 'error' });
    }
  }





  const [logo, setLogoName] = useState("")
  const [Presentation, SetPresentationFile] = useState("");


  const uploadFilePresentation = (ev) => {
    let file = ev.target.files[0];
    if (file) {
      var bytes = ev.target.files[0].size;
      var Mbytes = bytes / (1024 * 1024);
    }

    if (file && Mbytes < 20) {
      // Split the filename to get the name and type
      let fileParts = ev.target.files[0].name.split('.');
      let fileType = fileParts.slice(-1).pop()
      if (fileType === "doc" || fileType === "docx" || fileType === "pdf" || fileType === "ppt"  || fileType === "pptx" ) {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/upload_file_company`, {
          fileName: fileParts[0] + "." + fileType,
          fileType: "application/" + fileType,
          fileFolder: "file"
        },
          {
            headers: {
              'x-access-token': localStorage.getItem('x-access-token'),
            }
          })
          .then(response => {
            if (response.data.sessionExpired) {
              logoutMethod();
              alertService.alert({ message: response.data.message, type: 'error' });
            }
            else if (response.status) {
              var returnData = response.data.data.returnData;
              var signedRequest = returnData.signedRequest;
              var url = returnData.url;
              SetPresentationFile(url);

              if (url) {
                initialValues.logo = returnData.url;
              }

              // Put the fileType in the headers for the upload
              var options = {
                headers: {
                  'Content-Type': 'application/' + fileParts[1],
                  'Content-Disposition': "inline",
                }
              };
              axios.put(signedRequest, file, options)
                .then(result => {
                })
                .catch(error => {
                  if (error.message) {
                    alertService.alert({ message: JSON.stringify(error.message), type: 'error' });
                  }
                  else {
                    alertService.alert({ message: JSON.stringify(error), type: 'error' });
                  }
                })
            }

          })
          .catch(error => {
            if (error.message) {
              alertService.alert({ message: JSON.stringify(error.message), type: 'error' });
            }
            else {
              alertService.alert({ message: JSON.stringify(error), type: 'error' });
            }
          })
      }
      else {
        alertService.alert({ message: "File Format is Not Allowed", type: 'error' });
      }

    }
    else if (file && Mbytes > 20) {
      alertService.alert({ message: "Max Allowed File Size is 20MB", type: 'error' });
    }
  }


  const PhoneValidation = (value, country) => {
    if (!companyPhone) {
      setCompanyPhoneErrorMsg("Required")
    }
    else if (companyPhone.length !== 10) {
      setCompanyPhoneErrorMsg("Phone Number must be 10 digits")
    }
    else {
      setCompanyPhoneErrorMsg("");
      return true;
    }
  }










  const[StateList,SetStateList]=useState();
  const getStateList = () =>{
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/get_states?countryId=1`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
    .then((res) => res.json())
    .then((res) => {
      if(res.status==true){
        SetStateList(res.data);
      }
      else{
      
      }
    })
    .catch(() => {
    });
    }


    const [stateName, setStateName] = React.useState('');
    const [selectedState, setSelectedState] = React.useState('');
  
    const handleSelectChange = (value,e) => {
      if(value){
        setSelectedState(value.id);
        setStateName(value)      
      }  
    }


  if(localStorage.getItem("userType")  !== "Company Admin"){
    return ""
   }

   else
{

  return (
    <>
      <Helmet>
        <title> Company Management </title>
      </Helmet>

      {/* subeader */}
      <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Company Management</h3></div>
          </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
      {/* subeader */}

      <Form onSubmit={formik.handleSubmit} className="Content-custom-padding">


        <Col className="bg-white" >
        <Row className="bg-white mb-0 pb-0 pt-5 sticky-header"> 
            <Col md={6} sm={12} offset={4} className="pr-3 pl-3">
              <div>
                <div className="d-flex flex-grow-1 align-items-center  justify-content-start   rounded">
                  <div>
                    <h5 className="font-weight-bold f-w-600 pl-5" > Company Information </h5>
                    <p className="text-muted mb-0 pl-5">Update Your Company Information</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} sm={12} offset={4} className="pr-3 pl-3">
              <div>
                <div className="d-flex flex-grow-1 align-items-center justify-content-end rounded mt-1">
                  <div className="text-muted d-block">
                    <button type="submit" className="btn btn-theme-green btn-lg mr-5"  
                    disabled={loading}>
                      Save
                      {loading && <span className="ml-3 spinner spinner-white"></span>}
                    </button>
                    <Link to="/company-mangement/view" type="button" className="btn btn-theme btn-lg mr-5  text-white">
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12} sm={12} className="separator separator-solid  mt-5"></Col>
          </Row>

          <Form.Group as={Row} className="pt-10">
            <Form.Label column sm={2}>
              Company Name*
            </Form.Label>
            <Col sm={10} md={7}>
              <Form.Control
                name="position"
                type="text" placeholder="" className={`form-control inp-h form-control-solid h-auto py-5 px-6`}
                {...formik.getFieldProps("companyName")}
                maxLength={254}
                readOnly={true}
              />

            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={2}>
              Company Website*
            </Form.Label>
            <Col sm={10} md={7}>
              <Form.Control
                name="position"
                type="text" placeholder="" className={`form-control inp-h form-control-solid h-auto py-5 px-6`}
                {...formik.getFieldProps("companyWebsite")}
                maxLength={254}
              />
              {formik.touched.companyWebsite && formik.errors.companyWebsite ?
                (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.companyWebsite}</div>
                  </div>
                )
                : null}
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={2}>
              Company Phone Number*
            </Form.Label>
            <Col sm={10} md={7}>
              <PhoneInput name="companyPhone" className={`form-control form-control-solid h-auto py-5 px-6 tel`}
                {...formik.getFieldProps("companyPhone")}
                onChange={(value, data, event, formattedValue) => handlePhoneChange(value, data, event, formattedValue)}
                value={companyDialCode + " " + companyPhone}
                inputProps={{
                  name: 'companyPhone',
                  required: true,
                  autoFocus: true
                }}
                isValid={PhoneValidation}
                countryCodeEditable={false}
                defaultCountry="in"
                  onlyCountries={["in"]}
                  masks={{
                    in: "..........",
              
                  }}
              />
              {formik.touched.companyPhone && formik.errors.companyPhone ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.companyPhone}</div>
                </div>
              ) : null}

              {formik.touched.companyPhone && companyPhoneErrorMSg ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{companyPhoneErrorMSg}</div>
                </div>
              ) : null}


            </Col>
          </Form.Group>


          <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
            <Form.Label column sm={2}>
              Company Address*
            </Form.Label>
            <Col sm={10} md={7}>
              <Form.Control as="textarea"
                name="companyAddress"

                className={`form-control form-control-solid h-auto py-5 px-6 `}
                {...formik.getFieldProps("companyAddress")}
                maxLength={1000}
              />
              {formik.touched.companyAddress && formik.errors.companyAddress ?
                (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.companyAddress}</div>
                  </div>
                )
                : null}
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={2}>
              Country*
            </Form.Label>
            <Col sm={10} md={7}>
              <ReactFlagsSelect
                selected={'IN'}
                onSelect={onSelect}
                {...formik.getFieldProps("companyCountry")}
                countries={["IN"]}
                searchPlaceholder="Search countries"
                searchable={false}
                id="my-flag"
              />
            </Col>
          </Form.Group>


        {StateList && (selectedState || selectedState ===0)  &&
          <Form.Group as={Row}>
            <Form.Label className="pt-2" column sm={2}>
              state*
            </Form.Label>
            <Col sm={10} md={7}>
            <Autocomplete size="small"
                  id="roles-titles"
                  options={StateList}
                  renderInput={(params) => (
                    <TextField {...params} label="Select State" margin="normal" variant="outlined" />
                  )}
                  value={stateName}
                  getOptionLabel={
                    (options) => options.state}
                  onChange={(event, newValue) => {
                    handleSelectChange(newValue, event);
                  }}

                />
             {formik.touched.companyState && !stateName ?
                (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">Required</div>
                  </div>
                )
                : null}
            </Col>
          </Form.Group>
          }

          
          <Form.Group as={Row}>
            <Form.Label column sm={2}>
              Company PAN
            </Form.Label>
            <Col sm={10} md={7}>
              <Form.Control
                name="position"
                type="text" placeholder="" className={`form-control inp-h form-control-solid h-auto py-5 px-6`}
                {...formik.getFieldProps("companyPan")}
                maxLength={254}
              />
            </Col>
          </Form.Group>



          <Form.Group as={Row}>
            <Form.Label column sm={2}>
              Company GST
            </Form.Label>
            <Col sm={10} md={7}>
              <Form.Control
                name="position"
                type="text" placeholder="" className={`form-control inp-h form-control-solid h-auto py-5 px-6`}
                {...formik.getFieldProps("companyGst")}
                maxLength={254}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={2}>
              Company Logo
            </Form.Label>
            <Col sm={10} md={7}>
              <div className="upload-container" style={{ "position": "relative" }}>
                <div className="upload-btn-wrapper" >
                  <Link to="#" className="btn btn-file upload-btn" type="btn" onClick={triggerClick}>Choose File </Link>
                  <input type="file" className="d-none" name="file"
                    {...formik.getFieldProps("file")}
                    accept=".png,.jpeg,.jpg"
                    onChange={handleUpload}
                    id="fileUpload-inp"
                  />
                </div>
                <span className="text-dark text-overflow-company-inp"
                  style={{ "position": "absolute", "top": "50%", "transform": "translate(5px, -50%)" }}> {logo ? 'Company Logo' : "No File Selected"} </span>
              </div>
              <div>
              </div>

              <div>
                <span className="text-muted">
                  <small className="fw-600" > Note: File Type PNG/JPEG/JPG And Max Size 20MB. Recommended size (130px x 115px)</small>
                </span>
              </div>
            </Col>
            {logo ? (
              <Link to="#"   >
                <Form.Label column sm="2" className="text-theme-green"  >
                  <span >
                    <img src="/media/VRekruitIcons/delete.svg" className="c-p" width="24" onClick={(e) => deletefileLogo(logo)} title="Delete" alt="Delete" ></img>
                  </span>
                </Form.Label>
              </Link>
            ) : ""}
          </Form.Group>


          {formData.companyImages.map((ele, ind) =>
            <Form.Group as={Row} key={ind}>
              <Form.Label column sm={2}>
                {ind == 0 ? 'Company Images' : null}
              </Form.Label>

              <Col sm={7} md={7}>
                <div className="upload-container" style={{ "position": "relative" }}>
                  <div className="upload-btn-wrapper" >
                    <Link to="#" className="btn btn-file upload-btn" type="btn" onClick={() => triggerThanksvideosUpload(`file-inp-comapany-images-${ind}`)}>Choose File </Link>
                    <input type="file" className="d-none" name="file"
                      accept=".jpg,.jpeg,.png"
                      onChange={(e) => handleImageFilesUpload(e, (res) => {
                        var returnData = res.data.data.returnData;
                        var url = returnData.url;
                        formData.companyImages[ind] = { url: url };
                        formData.companyImages = [...formData.companyImages]
                        SetFormData(formData);
                        setCompanyImages(formData.companyImages);
                      })}
                      id={`file-inp-comapany-images-${ind}`}
                    />
                  </div>
                  <span className="text-dark text-overflow-company-inp" style={{ "position": "absolute", "top": "50%", "transform": "translate(5px, -50%)" }}> {companyImages[ind]?.url ? `Company Image ${ind+1}` : "No File Selected"} </span>
                </div>
                <div>
                </div>
                <div>
                  <span className="text-muted">
                    <small className="fw-600" > Note: File Type PNG/JPEG/JPG And Max Size 20MB. Recommended size (975px x 579px)</small>
                  </span>
                </div>
              </Col>


              <Form.Label column sm="3" className="text-theme-green"  >
                <span className="d-flex" >
                  {formData.companyImages.length !== ind + 1 ? (
                    <>
                      <img src="/media/VRekruitIcons/delete.svg" className={`c-p mr-5 `} onClick={(e) => deleteCompanyImages(ind, ele)} width="24" title="Delete" alt="Delete"  />
                    </>
                  ) : ""}
                  {formData.companyImages.length === ind + 1 ? (
                    <>
                      {formData.companyImages.length === ind - 1 ? (
                        <>
                          <img src="/media/VRekruitIcons/delete.svg" className="c-p xy" onClick={(e) => deleteCompanyImages(ind, ele)} width="24" title="Delete" alt="Delete"  />
                        </>
                      ) :
                        <>
                          {ind < 4 ?
                            (

                              <>
                                <img src="/media/VRekruitIcons/addmore.svg" className={`  ${!companyImages[ind]?.url ? "p-none mr-5" : "c-p mr-5"}`} onClick={addMoreCompanyImages} width="24" title="Add" alt="Add"  />
                              </>
                            )
                            : ""}


                          {companyImages[ind]?.url ? (
                            <img src="/media/VRekruitIcons/delete.svg" className="c-p xy " onClick={(e) => deleteCompanyImages(ind, ele)} width="24" title="Delete" alt="Delete"  />
                          ) : ""}
                        </>
                      }
                    </>
                  ) : ""}

                </span>
              </Form.Label>
            </Form.Group>
          )}




          <Form.Group as={Row}>
            <Form.Label column sm={2}>
              Company Video
            </Form.Label>
            <Col sm={10} md={7}>
              <div className="upload-container" style={{ "position": "relative" }}>
                <div className="upload-btn-wrapper" >
                  <Link to="#" className="btn btn-file upload-btn" type="btn" onClick={() => triggerComapnyVideo(`file-inp-comapany-video`)}>Choose File </Link>
                  <input type="file" className="d-none" name="file"
                    {...formik.getFieldProps("companyVideo")}
                    accept=".mp4"
                    onChange={(e) => handleVideoFileUpload(e, (res) => {
                      var returnData = res.data.data.returnData;
                      var url = returnData.url;
                      setVideoFileName(url);
                    })}
                    id={`file-inp-comapany-video`}
                  />
                </div>
                <span className="text-dark c-p text-overflow-company-inp" style={{ "position": "absolute", "top": "50%", "transform": "translate(5px, -50%)" }}> {videoFileName ? 'Company Video' : "No File Selected"}  </span>
              </div>
              <div>
              </div>

              <div>
                <span className="text-muted">
                  <small className="fw-600" > Note: File Type MP4 And Max Size 200MB And Max Duration 2Mins </small>
                </span>
              </div>

            </Col>
            {videoFileName ? (
              <Link to="#"   >
                <Form.Label column sm="2" className="text-theme-green"  >
                  <span >
                    <img src="/media/VRekruitIcons/delete.svg" onClick={(e) => deleteFileVideo(videoFileName)} className="c-p" width="24" title="Delete" alt="Delete"  ></img>
                  </span>
                </Form.Label>
              </Link>
            ) : ""}
          </Form.Group>

          {formData.introVideos.map((ele, ind) =>
            <Form.Group as={Row} key={ind}>
              <Form.Label column sm={2}>
                {ind == 0 ? 'Company Welcome Video' : null}
              </Form.Label>
              {/* <Col sm={3} md={3}>
                <Form.Control
                  name="name"
                  value={introVideos[ind]?.name}
                  type="text" onChange={(e) => {
                    var INvideos = [...formData.introVideos];
                    INvideos[ind].name = e.target.value;
                    formData.introVideos[ind].name = e.target.value;
                    SetFormData({ ...formData, introVideos: INvideos });
                    setIntroVideos(INvideos);
                  }} placeholder="Name of the Video" className={`form-control inp-h form-control-solid h-auto py-5 px-6`}
                  maxLength={254}
                />
                {isformSubmitted && !ele.name && ele.url ?
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block pl-3">Required</div>
                  </div>
                  : ""
                }
              </Col> */}
              <Col sm={7} md={7}>
                <div className="upload-container" style={{ "position": "relative" }}>
                  <div className="upload-btn-wrapper" >
                    <Link to="#" className="btn btn-file upload-btn" type="btn" onClick={() => triggerInrtovideosUpload(`file-inp-comapany-intro-${ind}`)}>Choose File </Link>
                    <input type="file" className="d-none" name="file"
                      accept=".mp4"
                      onChange={(e) => handleVideoFileUpload(e, (res) => {
                        var returnData = res.data.data.returnData;
                        var url = returnData.url;
                        formData.introVideos[ind] = { name: formData.introVideos[ind].name, url: url };
                        formData.introVideos = [...formData.introVideos]
                        SetFormData(formData);
                        setIntroVideos(formData.introVideos);
                      })}
                      id={`file-inp-comapany-intro-${ind}`}

                    />
                  </div>
                  <span className="text-dark text-overflow-company-inp" style={{ "position": "absolute", "top": "50%", "transform": "translate(5px, -50%)" }}> {introVideos[ind]?.url ? `Company Welcome Video ${ind+1}`: "No File Selected"} </span>
                </div>
                <div>
                </div>
                <div>
                  <span className="text-muted">
                    <small className="fw-600" > Note: File Type MP4 And Max Size 200MB And Max Duration 2Mins </small>
                  </span>
                </div>
              </Col>

              <Form.Label column sm="3" className="text-theme-green"  >
                <span className="d-flex" >
                  {formData.introVideos.length !== ind + 1 ? (
                    <>

                      <img src="/media/VRekruitIcons/delete.svg" className={`c-p mr-5`} onClick={(e) => deleteIntrovideo(ind, ele)} width="24" title="Delete" alt="Delete"  />
                    </>
                  ) : ""}
                  {formData.introVideos.length === ind + 1 ? (
                    <>
                      {formData.introVideos.length === ind - 1 ? (
                        <>
                          <img src="/media/VRekruitIcons/delete.svg" className="c-p xy" onClick={(e) => deleteIntrovideo(ind, ele)} width="24" title="Delete" alt="Delete"  />
                        </>
                      ) :
                        <>


                          {ind < 2 ?
                            (

                              <>
                                <img src="/media/VRekruitIcons/addmore.svg" className={` ${!introVideos[ind]?.url ? "p-none mr-5" : "c-p mr-5"}`} onClick={addMoreIntroVideo} width="24" title="Delete" alt="Delete"  />
                              </>
                            )
                            : ""}

                          {introVideos[ind]?.url ? (
                            <img src="/media/VRekruitIcons/delete.svg" className="c-p xy " onClick={(e) => deleteIntrovideo(ind, ele)} width="24" title="Delete" alt="Delete"  />
                          ) : ""}
                        </>
                      }
                    </>
                  ) : ""}

                </span>
              </Form.Label>
            </Form.Group>
          )}


          {formData.thanksVideos.map((ele, ind) =>
            <Form.Group as={Row} key={ind}>
              <Form.Label column sm={2}>
                {ind == 0 ? 'Company Thank You Video' : null}
              </Form.Label>

              {/* <Col sm={3} md={3}>
                <Form.Control
                  name="name"
                  value={thanksVideos[ind]?.name}
                  type="text" onChange={(e) => {
                    var videos = [...formData.thanksVideos];
                    videos[ind].name = e.target.value;
                    formData.thanksVideos[ind].name = e.target.value;
                    SetFormData({ ...formData, thanksVideos: videos });
                    setThanksVideos(videos);
                  }} placeholder="Name of the Video" className={`form-control inp-h form-control-solid h-auto py-5 px-6`}
                  maxLength={254}
                />
                {isformSubmitted && !ele.name && ele.url ?
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block pl-3">Required</div>
                  </div>
                  : ""
                }
              </Col> */}

              <Col sm={7} md={7}>
                <div className="upload-container" style={{ "position": "relative" }}>
                  <div className="upload-btn-wrapper" >
                    <Link to="#" className="btn btn-file upload-btn" type="btn" onClick={() => triggerThanksvideosUpload(`file-inp-comapany-thanks-${ind}`)}>Choose File </Link>
                    <input type="file" className="d-none" name="file"
                      accept=".mp4"
                      onChange={(e) => handleVideoFileUpload(e, (res) => {
                        var returnData = res.data.data.returnData;
                        var url = returnData.url;
                        formData.thanksVideos[ind] = { name: formData.thanksVideos[ind].name, url: url };
                        formData.thanksVideos = [...formData.thanksVideos]
                        SetFormData(formData);
                        setThanksVideos(formData.thanksVideos);
                      })}
                      id={`file-inp-comapany-thanks-${ind}`}
                    />
                  </div>
                  <span className="text-dark text-overflow-company-inp" style={{ "position": "absolute", "top": "50%", "transform": "translate(5px, -50%)" }}> {thanksVideos[ind]?.url ? `Company Thanks Video ${ind+1}` : "No File Selected"} </span>
                </div>
                <div>
                </div>
                <div>
                  <span className="text-muted">
                    <small className="fw-600" > Note: File Type MP4 And Max Size 200MB And Max Duration 2Mins </small>
                  </span>
                </div>

              </Col>

              <Form.Label column sm="3" className="text-theme-green"  >
                <span className="d-flex" >
                  {formData.thanksVideos.length !== ind + 1 ? (
                    <>
                      <img src="/media/VRekruitIcons/delete.svg" className={`c-p `} onClick={(e) => deleteThanksvideo(ind, ele)} width="24" title="Delete" alt="Delete"  />
                    </>
                  ) : ""}
                  {formData.thanksVideos.length === ind + 1 ? (
                    <>
                      {formData.thanksVideos.length === ind - 1 ? (
                        <>
                          <img src="/media/VRekruitIcons/delete.svg" className="c-p " onClick={(e) => deleteThanksvideo(ind, ele)} width="24" title="Delete" alt="Delete"  />
                        </>
                      ) :
                        <>

                          {ind < 2 ?
                            (

                              <>
                                <img src="/media/VRekruitIcons/addmore.svg" className={` ${!thanksVideos[ind]?.url ? "p-none mr-5" : "c-p mr-5"}`} onClick={addMoreThanksVideo} width="24" title="Add" alt="Add"  />
                              </>
                            )
                            : ""}
                          {thanksVideos[ind]?.url ? (
                            <img src="/media/VRekruitIcons/delete.svg" className="c-p xy " onClick={(e) => deleteThanksvideo(ind, ele)} width="24" title="Delete" alt="Delete"  />
                          ) : ""}
                        </>
                      }
                    </>
                  ) : ""}

                </span>
              </Form.Label>
            </Form.Group>
          )}




          {formData.youtubeVideos.map((ele, ind) =>
            <Form.Group as={Row} key={ind}>
              <Form.Label column sm={2}>
                {ind == 0 ? 'Youtube Video Link' : null}
              </Form.Label>
              <Col sm={7} md={7}>
                <Form.Control
                  name="name"
                  type="text" onChange={(e) => {

                    var YTvideos = [...formData.youtubeVideos];
                    YTvideos[ind].name = e.target.value;
                    formData.youtubeVideos[ind].url = e.target.value;
                    SetFormData({ ...formData, youtubeVideos: YTvideos });
                    setYoutubeVideos(YTvideos);

                  }} placeholder="Youtube Video Link" className={`form-control inp-h form-control-solid h-auto py-5 px-6`}
                  value={youtubeVideos[ind]?.url}
                  maxLength={254}
                />
              </Col>


              <Form.Label column sm="3" className="text-theme-green"  >
                <span className="d-flex" >
                  {formData.youtubeVideos.length !== ind + 1 ? (
                    <>
                      <img src="/media/VRekruitIcons/delete.svg" className={`c-p `} onClick={(e) => deleteYoutubevideo(ind, ele)} width="24" title="Delete" alt="Delete"  />
                    </>
                  ) : ""}
                  {formData.youtubeVideos.length === ind + 1 ? (
                    <>
                      {formData.youtubeVideos.length === ind - 1 ? (
                        <>
                          <img src="/media/VRekruitIcons/delete.svg" className="c-p" onClick={(e) => deleteYoutubevideo(ind, ele)} width="24" title="Delete" alt="Delete"  />
                        </>
                      ) :
                        <>

                          {ind < 2 ?
                            (

                              <>
                                <img src="/media/VRekruitIcons/addmore.svg" className={` ${!youtubeVideos[ind]?.url ? "p-none mr-5" : "c-p mr-5"}`} onClick={addMoreYoutubeVideo} width="24" title="Add" alt="Add"  />

                              </>
                            )
                            : ""}



                          {youtubeVideos[ind]?.url ? (
                            <img src="/media/VRekruitIcons/delete.svg" className="c-p xy" onClick={(e) => deleteYoutubevideo(ind, ele)} width="24" title="Delete" alt="Delete"  />
                          ) : ""}
                        </>
                      }
                    </>
                  ) : ""}

                </span>
              </Form.Label>

            </Form.Group>
          )}



          <Form.Group as={Row}>
            <Form.Label column sm={2}>
              Company Presenatation
            </Form.Label>
            <Col sm={10} md={7}>
              <div className="upload-container" style={{ "position": "relative" }}>
                <div className="upload-btn-wrapper" >
                  <Link to="#" className="btn btn-file upload-btn" type="btn" onClick={triggerClickCompanypresentation}>Choose File </Link>
                  <input type="file" className="d-none" name="companyPresentation"
                    {...formik.getFieldProps("companyPresentation")}
                    accept=".pdf,.doc,.docx,.ppt,.pptx"
                    onChange={(e) => uploadFilePresentation(e)}
                    id="fileUpload-presentation"
                  />
                </div>
                <span className="text-dark text-overflow-company-inp" style={{ "position": "absolute", "top": "50%", "transform": "translate(5px, -50%)" }}> {Presentation ? 'Company Presentation' : "No File Selected"} </span>
              </div>
              <div>
              </div>
              <div>
                <span className="text-muted">
                  <small className="fw-600" > Note: File Type PDF/DOC/DOCX/PPT/PPTX And Max Size 20MB  </small>
                </span>
              </div>
            </Col>
            <Form.Label column sm="3" className="text-theme-green"  >
              <span className="d-flex" >
                <>
                  {Presentation ? (
                    <img src="/media/VRekruitIcons/delete.svg" className="c-p xy ml-5" onClick={(e) => deletePresentationFile(Presentation)} width="24" />
                  ) : ""}
                </>
              </span>
            </Form.Label>
          </Form.Group>
          <div className="pb-5 pt-5"></div>
        </Col>
      </Form>
      <DeleteConfirmationModal show={modalShow} onHide={() => setModalShow(false)} />
      <DeleteaPresentationFileConfirmationModal show={presentationShow} onHide={() => setPresentationShow(false)} />
      <DeleteaintroConfirmationModal show={introShow} onHide={() => setIntroShow(false)} />
      <DeletecompanyImageonfirmationModal show={companyImageShow} onHide={() => SetCompanyImageShow(false)} />
      <DeletecompanyVideofirmationModal show={companyVideoShow} onHide={() => SetCompanyVideoShow(false)} />
      <DeleteThankVideofirmationModal show={thankVideoShow} onHide={() => SetThanksVideoShow(false)} />
      <DeleteYTVideofirmationModal show={youtubeVideoShow} onHide={() => SetYoutubeVideoShow(false)} />
      <DeleteConfirmationModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
                  
  }
}

export default EditCompanyDetails