/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React, { useEffect, useState } from 'react';
import PropTypes, { string } from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormFile from 'react-bootstrap/FormFile'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Table from 'react-bootstrap/Table';
import Helmet from 'react-helmet'
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import AsyncSelect from 'react-select/async';
import { useParams, useHistory } from 'react-router-dom';
import alertService from '../../_services/alertservice';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'
import { truncate } from 'lodash';
import AssignedInterview from './AssignedInterview';
import ReactPlayer from "react-player";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { logout } from '../../../actions/auth';
import { useDispatch } from 'react-redux';

const animatedComponents = makeAnimated();

const initialValues = {
  position: "",
  jobDescription: "",
  file: "",
  fileName:"",
  assignedTo: [],
  private: "",
  status: ""
};

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

function ViewExpiredInterview() {
  const [requistions, setUsersList] = useState([]);
  const [UserById, setUsersbyId] = useState([]);
  const [colourOptions, setColourOptions] = useState([]);
  const [AssignedDefaultOptions, setDefaultAssignedOptions] = useState([]);
  const [name, setName] = useState([]);
  const [idList, setIdList] = useState("");
  const [renderPage, setRenderPage] = useState(false);
  const [createdByUser, SetCreatedUserByUser] = useState("");
  const [modifiedUserByUser, SetModifiedUserByUser] = useState("");

  const AssignedOptions = (req) => {
    if (req && req.length) {
      var options = [];
      req.forEach(element => {
        options.push(
          { value: element.id, label: element.firstName + " " + element.lastName }
        )
      });
      setDefaultAssignedOptions(options);
    }
  }

  const update_requisition = (values) => {
    const reqData = {
      position: values.position,
      jobDescription: values.jobDescription,
      file: values.file,
      assignedTo: idList,
      id: UserById.id,
      private: values.private
    }
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/update_requisition`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(reqData)
    })
      .then((res) => res.json())
      .then((res) => {
        if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
  
        else  if (res.status == true) {
          // disableLoading();
          alertService.alert({ message: res.message, type: 'success' });

        }
        else {
          alertService.alert({ message: res.message, type: 'error' });

        }
      })
      .catch(() => {

      });
  }

  const setSt = (req) => {
    if (req && req.length) {
      var options = [];
      req.forEach(element => {
        options.push(
          { value: element.id, label: element.firstName + " " + element.lastName }
        )
      });
      setColourOptions(options);
    }
  }


  const getrequisitionbyid = () => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/review_detail?id=` + id, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {

        if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
        else  if (res.status == true) {
          setUsersbyId(res.data);

         if(res.data.interview.interviewQuestions){
          SetselectedQuestionDetails(res.data.interview.interviewQuestions[0]);
          SetvideoUrl(res.data.interview.interviewQuestions[0].interviewQuestionDetail.videoPath);
         }
         SetselectedIndex("1");

       
           var formdatavalues = 
          {
           ...formData,
            candidateStatus: res.data.candidateStatus,
            companyRemarks: res.data.companyRemarks,
          }
          SetFormData(formdatavalues)

        }
        else {
       
        }
      })
      .catch(() => {

      });
  }

  function SetFormic() {
    formik = useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema: AddNewRequistionSchema,
      validator: () => { },
      onSubmit: (values) => {
        // enableLoading();
        update_requisition(values);
        // setDefaultAssignedOptions();
      },
    });
  }

  // const getuserslist = () => {
  //   const token = localStorage.getItem('x-access-token');
  //   fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/users_list`, {
  //     method: 'GET',
  //     headers: {
  //       'content-type': 'application/json',
  //       'x-access-token': token
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res.status == true) {
  //         setUsersList(res.data);
  //         setSt(res.data);
  //       }
  //       else {

  //       }
  //     })
  //     .catch(() => {

  //     });
  // }


  useEffect(() => {
    let mounted = true;
    // getuserslist();
    getrequisitionbyid();
    return () => mounted = false;
  }, [])
  const AddNewRequistionSchema = Yup.object().shape({});

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };



  var formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: AddNewRequistionSchema,
    validator: () => { },
    onSubmit: (values) => {
      // enableLoading();
      update_requisition(values);
    },
  });


  const [modalShow, setModalShow] = React.useState(false);


  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }


  const reqsave = () => {
    let savebtn = document.getElementById("req-save-btn");
    savebtn.click();
  }


  let {id} = useParams();





  const history = useHistory();
  const dispatch = useDispatch();
  const logoutMethod = () => {
    dispatch(logout());
    history.push('/auth/signin');
};

  
  const CanDelete = () => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/edit_delete_premission?module=requisition&moduleId=` + id, {
      method: 'Get',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
        
          if(res.data.canDelete) {
            DeleteListItem();
            setModalShow(false);  
            if(res.data.deleteWarningMessage)
            {
             alertService.alert({ message: res.data.deleteWarningMessage, type: 'error' });
            }
           }
        }
        else {
          alertService.alert({ message: res.message, type: 'error' });
          setModalShow(false);

        }
      })

  }
  
  const histroy = useHistory();

  const canEdit = () => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/edit_delete_premission?module=requisition&moduleId=` + id, {
      method: 'Get',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          if(res.data.canEdit)
          histroy.push("/edit-requistion/"+id);
          if(res.data.editWarningMessage)
            alertService.alert({ message: res.data.editWarningMessage, type: 'error' });
          }
        else {
          alertService.alert({ message: res.message, type: 'error' });

        }
      })
    
  }





  const DeleteListItem = () => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/delete_requisition/` + id, {
      method: 'delete',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {

        if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
        else  if (res.status == true) {
          //  view_requisition();
          alertService.alert({ message: res.message, type: 'success' });
          let linkItem = document.getElementById("view-requistion-link");
          linkItem.click();
          setModalShow(false);
        }
        else {
          alertService.alert({ message: res.message, type: 'error' });
          setModalShow(false);

        }
      })

  }



  const ChangeDateFormat = () => {
    let dateObj = UserById.createdAt;
    if (typeof UserById.createdAt !== 'object') {
      dateObj = new Date(UserById.createdAt);
    }
    return `${('0' + dateObj.getUTCDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${dateObj.getUTCFullYear()}`;
  }

  const GetFormattedDate = (date) => {
    var cdate = new Date(date);
    var dd = cdate.getDate();

    var mm = cdate.getMonth() + 1;
    var yyyy = cdate.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }
    return `${dd}-${mm}-${yyyy}`
  }

const [selectedQuestionDetails, SetselectedQuestionDetails ] = useState();
const [selectedVideo, SetvideoUrl] = useState("");
const [selectedIndex, SetselectedIndex ] = useState("1");


const setSelectedQuestion = (x,videourl,ind) => {
  SetselectedQuestionDetails(x);
  SetvideoUrl(videourl);
  SetselectedIndex(ind+1);

}

  const handleFormInput = (e) => {
   SetFormData({
     ...formData,
     [e.target.name]: e.target.value
   })

 }
 

  const [formData, SetFormData] = useState(
    {
      candidateStatus: "",
      companyRemarks: "",
    }
  )




// api/v1/interview/update_review

const[loading,SetLoading]=useState(false);
const update_candidateReview = (e) => {
  const token = localStorage.getItem('x-access-token');
  SetLoading(true);
  fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/update_review/`+ id, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      'x-access-token': token
    },
    body: JSON.stringify(formData)
  })
    .then((res) => res.json())
    .then((res) => {

      if(res.sessionExpired){
        alertService.alert({ message :res.message, type : 'error'  });
        logoutMethod();
        SetLoading(false);
      }
      else if (res.status == true) {
        alertService.alert({ message: res.message, type: 'info' });
        getrequisitionbyid();
        SetLoading(false);
      }
      else {
        if (res.errors) {
          var errmsg = "";
          res.errors.forEach(function (value, index, array) {
            errmsg = errmsg + "(" + (index + 1) + ") " + value.msg + "\n\r";

          });
          alertService.alert({ message: errmsg, type: 'error' });
          SetLoading(false);
        }
        else {
          alertService.alert({ message: res.message, type: 'error' });
          SetLoading(false);
        }
      }
    })
    .catch(() => {
      SetLoading(false);

    });
}

if(  localStorage.getItem("userTypeId") == "1")
{
    
   return ""

}
else {



  return (
    <>
      <Helmet>
        <title> View Requisition  </title>
      </Helmet>




      {/* subeader */}
      <div id="kt_subheader" className="subheader py-2 py-lg-4    subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Review</h3></div>
          </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
      {/* subeader */}
      <Col className={classes.root} >
        <Row className="bg-white pt-4 pl-4 pb-4 align-items-center">
          <Col md={6} sm={12} offset={4} className="pr-3 pl-3">
            <div>
              <div className="d-flex flex-grow-1 min-vh-70 align-items-center  justify-content-start   rounded">
                <div>
                  <h5 className="font-weight-bold f-w-600 pl-5" >  Expired Interview &nbsp;&nbsp; {'>'} &nbsp;&nbsp; View Interview  </h5>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} sm={12} offset={4} className="pr-3 pl-3">
            <div>
              <div className="d-flex flex-grow-1 min-vh-70 align-items-center justify-content-end rounded mt-1">
                <div className="text-muted d-block">
                  <button onClick={update_candidateReview} type="button" className="btn btn-theme-green btn-lg mr-5"  
                  disabled={loading}>
                    Save
                    {loading && <span className="mr-5 ml-2 spinner spinner-white"></span>}
                  </button>
                  <Link to="/expired-interviews" type="button" className="btn btn-theme btn-lg mr-5  text-white">
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col md={12} sm={12} className="separator separator-solid mb-7 mt-5"></Col>
        </Row>

        <Link className="d-none" id="view-requistion-link" to="/view-requistion"> View Requisition</Link>
        <div className="Content-custom-padding">
          <Row>
            <Col md={6} sm={12} offset={4} className=" pt-5 pb-5 pr-3 pl-3">

              <div>
                {/* <i class="fa fa-link fa-2x"> </i>

       <p> Requistion Id </p>
       <p> 12345678 </p> */}
                <div className="bg--tile rounded">

                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4  pl-4 ">
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/requisitionid.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">First Name</h6>
                    <p className="text-dark mt-0"> {  UserById && UserById.interviewUserDetail ?  UserById?.interviewUserDetail?.firstName : ""} </p>
                  </div>

                </div>
                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4  pl-4 ">
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/requisitionid.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Last Name</h6>
                    <p className="text-dark mt-0"> {  UserById && UserById.interviewUserDetail ?  UserById?.interviewUserDetail?.lastName : ""} </p>
                  </div>

                </div>
                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/email (1).svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark"> Email Address</h6>
                    <p className="text-dark mt-0">
                      {UserById && UserById.interviewUserDetail ? UserById.interviewUserDetail.email : ""}
                    </p>
                  </div>
                </div>

                <div className="separator separator-solid bg-white"></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4  pl-4   mb-0">
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/call (1).svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Phone</h6>
                    <p >
                      {UserById && UserById.interviewUserDetail ? UserById.interviewUserDetail.phone : ""}
                    </p>
                  </div>
                </div>

                </div>
                

              </div>

            </Col>



            <Col md={6} sm={12} offset={4} >
              <div className="bg--tile rounded">
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4  pl-4   mt-5 mb-0"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    {/* <i className="icon-2x text-dark-50 flaticon-user" /> */}
                    <img className="d-inline black-svg"  width="25" src="/media/VRekruitIcons/InterviewManage.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Interview Name</h6>

                    <p className="text-dark mt-0">
                    {UserById && UserById.interview ? UserById.interview.interviewTitle : ""}
                    </p>
                  </div>
                </div>

                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/status.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark"> Requisition Title </h6>
                    <p className="text-dark mt-0">
                    {UserById && UserById.requisition ? UserById.requisition.position : ""}
                    </p>
                  </div>
                </div>
                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/calendar.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark"> Invitation Date</h6>
                    <p className="text-dark mt-0">
                      {UserById.createdAt ? GetFormattedDate(UserById.updatedAt) : ""}
                    </p>
                  </div>
                </div>

                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/status.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark"> Status</h6>
                    <p className="text-dark mt-0">
                      {UserById?  UserById.candidateStatus: ""}
                    </p>
                  </div>
                </div>
              </div>
            </Col> 
           
            <Col md={12}> 
            <img className="d-inline black-svg"  width="25" src="/media/VRekruitIcons/InterviewManage.svg" />
            <span className="pl-5">Interview Response</span>
            </Col>

            <Col md={3} sm={6} offset={1} className="mt-5 "  >
            <div className="bg--tile rounded box-questions">
                  <div className="flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4  pb-3 pr-4   mt-0 mb-0">
                   { 
                   UserById && UserById.interview && UserById.interview.interviewQuestions && UserById.interview.interviewQuestions.map((x, ind) =>  
                    
                      
                    <div className="text-muted d-block bg-white mr-5 mb-5 mt-2" style={{"width":"100%","borderRadius":"5px"}} >
                      <h6 className="text-dark pl-3 pt-5 pb-2 d-flex justify-content-space-between align-items-center c-p"
                      onClick={()=> setSelectedQuestion(x,x.interviewQuestionDetail?.videoPath,ind)}
                      >  Question {ind + 1}
                      <i className="fa fa-play-circle fa-2x mr-3" />
                      {/* <img src ="/media/VRekruitIcons/play.svg" className="fa  fa-2x mr-3" /> */}
                      </h6>
                      <div className="separator separator-solid bg-grey"></div>
                      <h6 className="text-dark pl-3 pt-5 pb-4 d-flex justify-content-space-between align-items-center c-p"
                        onClick={()=> setSelectedQuestion(x,x.interviewQuestionAnswer?.answer,ind)}
                      >  Answer {ind + 1}
                      <i className="fa fa-play-circle fa-2x mr-3" />
                      </h6>                    
                      </div>
                    
                      )}
     
                    </div>
                </div>
              </Col>

              <Col md={9} sm={6} offset={1} className="mt-5"  >
            <div className="bg--tile rounded pt-5 pb-5">
                  <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4  pl-5   mt-3 mb-3 mr-auto">
                    <ReactPlayer url={ selectedVideo? selectedVideo :""} width="98%" height="540px" controls={true} />
                  </div> 



                  <div className="text-muted d-block bg-white question-box  mb-5 mt-2 ml-5 mr-5" style={{"width":"96.6%","borderRadius":"5px"}}>
                      <h6 className="text-dark pl-3  pt-5 pb-2 d-flex justify-content-space-between align-items-center "> 
                        Question {selectedIndex ? selectedIndex +  ":"+ " " : " "}
                         {selectedQuestionDetails && selectedQuestionDetails.interviewQuestionDetail?.question ? selectedQuestionDetails.interviewQuestionDetail?.question:""}
                      </h6>                    
                      </div>
           
                </div>
              </Col>

            <Col md={12} className="mt-5"> 
            <img className="d-inline result-ico" width="25" src="/media/VRekruitIcons/result.png" />
            <span className="pl-5">Result</span>
            </Col>
              <Col md={12} sm={12} offset={4} className="pb-5 mt-0 pl-5 pr-5"  >
                <div className="bg--tile rounded">
                  <div className="d-flex flex-grow-1 min-vh-70 align-items-top mt-5 pt-4 pr-5 pl-5" >
                      {/* <p className="text-dark mt-0">Account Manager  Job Responsbility</p>    */}
                      {/* value={formData.difficultyLevel} */}
                      {[ 'radio'].map((type) => (
                      <div key={`inline-${type}`} className="mb-3 mr-5 ml-5 " style={{"display":"flex","justifyContent":"space-between","width":"100%"}}> 
                        <Form.Check inline label="Pending" className="pr-3-inp d-block-sm"  name="candidateStatus" value="pending" type={type} id={`inline-${type}-1`} 
                         onChange={(e) => handleFormInput(e)}
                        checked={formData && formData?.candidateStatus ===  "pending" ? true: false  }
                        />
                        <Form.Check inline label="Rejected" className="pr-3-inp d-block-sm"  name="candidateStatus" value="rejeted"  type={type} id={`inline-${type}-2`}
                        onChange={(e) => handleFormInput(e)}
                        checked={formData && formData?.candidateStatus ===  "rejeted" ? true: false  }

                        />
                        <Form.Check inline label="Selected" className="pr-3-inp d-block-sm" name="candidateStatus" value="selected" type={type} id={`inline-${type}-3`} 
                        onChange={(e) => handleFormInput(e)}
                        checked={formData && formData?.candidateStatus ===  "selected" ? true: false  }

                        />

                       <Form.Check inline label="Next Round" className="pr-3-inp d-block-sm" name="candidateStatus" value="next_round" type={type} id={`inline-${type}-4`} 
                        onChange={(e) => handleFormInput(e)}
                        checked={formData && formData?.candidateStatus ===  "next_round" ? true: false  }

                       />
                      </div>
                    ))}
                  </div>
                </div>
              </Col>


            <Col md={12} className="mt-5"> 
            <img className="d-inline" width="25" src="/media/VRekruitIcons/Remarks.png" />
            <span className="pl-5">Remarks</span>
            </Col>
              <Col md={12} sm={12} offset={4} className="pb-5 mt-3"  >
           
                     <textarea placeholder="Please Enter Remarks" type="textarea" 
                     rows="3" className={`form-control form-control-solid h-auto py-5 px-6`}
                      name="companyRemarks"
                      resizable="true"
                      onChange={(e) => handleFormInput(e)}
                      value={formData.companyRemarks}
                      >   
                        {formData.companyRemarks}
                      </textarea>                 
              </Col>
          </Row>

          {renderPage && (
            <Form onSubmit={formik.handleSubmit} >
              <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                <Button id="req-save-btn" type="submit" className={`btn btn-primary btn-theme font-weight-bold px-9 py-4  my-3 d-none`}>
                  <span>Save </span>
                  <span className="ml-3  spinner-white"></span>
                </Button>
              </div>
            </Form>
          )}
        </div>
      </Col>



    </>
  );
          
}
}

export default ViewExpiredInterview