/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React from 'react';
import {Link} from 'react-router-dom'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'



function Header(props) {
    return (
        <>
        <Navbar collapseOnSelect expand="lg" bg="white" variant="light"  style={{ "background": "#fff !important","display":"flow-root","position":"relative"}}>
        <Row className="" style={{"position":"relative"}}>
        <Navbar.Brand href="#">
           <img className="img-responsive mb-5 ml-xl2-5 img-responsive logo-header-sm pl-2" width="200" src="/media/logos/UPDATED_VRekruit _Logo.png"  />    
          </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{ "position":"absolute", "right":"10px","top": "6px"}} />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
            </NavDropdown>
          </Nav>
          <Nav>
            <Link to="/homepage" className={`fs15rem  font-weight-bold mr-20 pl-2 mt-3 mb-3 ${window.location.pathname ==="/homepage"   ? 'text-theme-green' : 'text-dark'}`}>
               Home
            </Link>
      
            <Link  to="/contact"  
            className={`fs15rem font-weight-bold  mr-20 pl-2 mt-3 mb-3 ${window.location.pathname ==="/contact" ? 'active-nav-link' : 'text-dark'}`}
            >
              Contact Us
            </Link>
      
            <Link  to="/auth/signin"
                  className={`fs15rem font-weight-bold mr-20 pl-2 mt-3 mb-3 ${window.location.pathname ==="/auth/signin" ? 'active-nav-link' : 'text-dark'}`}
            >
              Login 
            </Link>
            <Link  to="/auth/registration"
             className={`fs15rem font-weight-bold  mr-20 pl-2 mt-3 mb-3 ${window.location.pathname ==="/auth/registration" ? 'active-nav-link' : 'text-dark'}`}
      
             >
               <span className="bg-theme-green text-white pt-3 pb-3 pr-3 pl-3 br-15">
                 Register Now
               </span>
            </Link>
          </Nav>
        </Navbar.Collapse>
        </Row>
      </Navbar>
      </>
    );
}

export default Header;