/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React from "react";
import {Link, Switch, Redirect} from "react-router-dom";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {ContentRoute} from "../../../../_metronic/layout"
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import Helmet from "react-helmet"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Collapsible from 'react-collapsible';
import LoginAside from "./LoginAside"
import { ExpandMore } from '@material-ui/icons';
import Form from 'react-bootstrap/Form'
import Header from './Header'
import Footer from './Footer'
import Map from './Map'
import MapNew from "./MapNew";



export default function Contact() {




  const handleSubmit = () => {
  console.log("");
}

const handleChange = () => {
  console.log("");
}

const scrollToTop = () =>{
  window.scrollTo({
    top: 0, 
    behavior: 'smooth'
    /* you can also use 'auto' behaviour
       in place of 'smooth' */
  });
};



  return (
  <>
     <Helmet>
         <title> VRekruit | Contact</title>
     </Helmet>

<Header/>
<section id="cs1"  style={{"backgroundColor":"#003577"}}>
<Container className="pt-5 mt-20 mb-0 text-center mr-auto" >
<Row className="d-flex space-around">
   <Col md={12} sm={12}>
     <div className="mr-auto text-white fs-30 mb-20">
       <h2 className="text-white text-center fs2rem">
          Contact Us
      </h2>
     </div>
    </Col>
    </Row>
</Container>
</section>

 <section className="bg-white">

  <span className="Privacy-Policy-At-Website-Name-accessible-at-Websitecom-one-of-our-main-priorities-is-the-privacy-of-our-visitors-This-Privacy-Policy-document-contains-types-of-information-that-is-collected-and-recorded-by-Website-Name-and-how-we-use-it-If-you-have">
   <h1 className=" pl-5 pt-5 pb-5text-style-1 text-center mt-20">Submit a Request</h1>
   <div className="mb -5 line" style={{"width":"65px"}}></div>

   <Container>
<Row className="mr-auto mt-10 mb-20">

  <Col md={7} className="mr-auto">

       <Form noValidate onSubmit={handleSubmit}>
            <Form.Group  controlId="validationFormik01">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group  controlId="validationFormik02">
              <Form.Label> Email </Form.Label>
              <Form.Control
                type="email"
                name="email"
                onChange={handleChange}
              />

            </Form.Group>

            <Form.Group  controlId="validationFormik03">
              <Form.Label>Phone No.</Form.Label>
              <Form.Control
                type="number"
                // placeholder="City"
                name="phone"
                // value={values.city}
                onChange={handleChange}
                // isInvalid={!!errors.city}
              />

              <Form.Control.Feedback type="invalid">
                {/* {errors.city} */}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group  controlId="validationFormik04">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                // placeholder="State"
                name="description"
                // value={values.state}
                onChange={handleChange}
                // isInvalid={!!errors.state}
              />
              <Form.Control.Feedback type="invalid">
                {/* {errors.state} */}
              </Form.Control.Feedback>
            </Form.Group>

          <button type="submit" className="btn btn-theme-green btn-lg mr-5 mb-5" style={{"width":"100%"}}>Submit</button>
        </Form>
        </Col>

        {/* <Col className="mr-auto mt-5" sm={12} md={7}>
         <Map />
        </Col> */}
        


        </Row>

    </Container>
    <Row className="mr-auto mt-10 mb-20">
    <Col className="mr-auto " sm={12}>
          <MapNew />
        </Col>
        </Row>
  
   </span>





</section>




<section className="bg-info-1 pt-5">
<Container>
  <Row className="mt-20 mb-20 d-flex space-around">
    <Col sm={12} md={4} >
    <img className="img-responsive mb-5 " width="200" src="/media/logos/UPDATED_VRekruit _Logo.png"  />   
    <p className="text-left"> 
    A fully integrated cloud-based asynchronous video interview and <br/>
talent solution
    </p>
    </Col>

    <Col sm={12} md={4} >
    <h3 className="text-left pt-3 fw-600"> 
       Contact Info
    </h3>
    {/* <p className="text-left pt-3"> 
    <img className="d-inline mr-3" width="25" alt="" src="/media/VRekruitIcons/address.svg" />
    Building No. 000, Industrial Area,
    <br/>
    <span className="ml-10">   Mumbai- 000000 </span>    
    </p> */}
    <p className="text-left pt-3"> 
    <img className="d-inline mr-3" width="25" alt="" src="/media/VRekruitIcons/email (1).svg" />
       admin@vrekruit.com
    </p>
    {/* <p className="text-left pt-3"> 
    <img className="d-inline mr-3" width="25" alt="" src="/media/VRekruitIcons/call (1).svg" />    
      +91 89968465798
    </p> */}
    </Col>

    <Col sm={12} md={4}   >
    {/* <h3 className="text-left pt-3 pb-3 fw-600"> 
      Follow us On
    </h3>

    <img className="d-inline mr-3 c-p" width="25" alt="" src="/media/VRekruitIcons/facebook-app-symbol.svg" />
    <img className="d-inline mr-3 c-p" width="25" alt="" src="/media/VRekruitIcons/google-plus-logo.svg" />
    <img className="d-inline mr-3 c-p" width="25" alt="" src="/media/VRekruitIcons/linkedin-blue.png" />
    <img className="d-inline mr-3 c-p" width="25" alt="" src="/media/VRekruitIcons/twitter-blue.png" /> */}
    <h3 className="text-left  pb-3 text-theme-green"> 
        Download Our Apps 
    </h3>
    <h3>
    <a href={`${process.env.REACT_APP_ANDROID_APP_URL}`} target="_blank">
    <img className="img-responsive mb-5  mt-2 br-10 mr-5 c-p" width="150px" src="/media/VRekruitIcons/google.png"  />  
    </a>
    <a href={`${process.env.REACT_APP_IOS_APP_URL}`} target="_blank">

     <img className="img-responsive mb-5  br-10  mt-2 c-p" width="150px"  src="/media/VRekruitIcons/apple.png"  />  
     </a>
    </h3>
    </Col>
  </Row>
</Container>

</section>

<Footer/>
  </>
             
           
  );
}
