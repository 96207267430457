import React from 'react'
import {Link} from "react-router-dom";

import Helmet from "react-helmet"



function ThankPage() {
    return (
        <>
            <Helmet>
                <title> VRekruit | Thank You Page</title>
            </Helmet>
            <div>
            <div className="d-flex justify-content-center align-items-center mt-5">  
            <img className="img-responsive mb-5" width="200" src="/media/logos/UPDATED_VRekruit _Logo.png"  />
            </div>
            <div className="d-flex justify-content-center align-items-center mt-5">  
            <center>
            <h1>     
                Thank you for verifying your account. <br />Please login with the email address and password submitted during registration           
             </h1>
             </center>
            </div>
            </div>
          
           <div className="text-center mt-5">

                <Link className="btn btn-primay" to="/auth/signin">

                <button className="btn btn-primary">
                  Sign In!
                </button>                
                </Link>  
        </div>
        </>
    )
}

export default ThankPage
