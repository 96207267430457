/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Helmet from 'react-helmet'
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import AsyncSelect from 'react-select/async';
import alertService from '../../_services/alertservice';
import { useHistory, Link } from "react-router-dom";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReactFlagsSelect from 'react-flags-select';
import Modal from 'react-bootstrap/Modal'
import axios from 'axios';
import ReactPlayer from "react-player";
import {GlassMagnifier} from "react-image-magnifiers";
import ModalImage from "react-modal-image";
import { logout } from '../../../actions/auth';
import { useDispatch } from 'react-redux';


const initialValues = {
  companyName: "",
  companyWebsite: "",
  logo: "",
  companyPhone: "",
  companyAddress: "",
  companyCountry: "",
  companyDialCode: "",
  companyImages: [""],
  companyIntroVideos: [""],
  companyIntroVideoNames: [""],
  companyThanksVideoNames: [],
  companyThanksVideos: [""],
  companyYoutubeLinks: [""],
  companyVideo: [],
  companyPresentation: ""
};


function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));


function EditCompanyDetails() {
  const [name, setName] = useState([]);
  const [pdFileName, SetSeletedFileName] = useState("");
  const [hasfile, Sethasfile] = useState(false);
  const history = useHistory();
  const [companyDetails, setCompanyDetails] = useState([]);
  const getCompanyDetails = () => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setCompanyDetails(res.data);
          initialValues.companyName = res.data.companyName;
          initialValues.companyWebsite = res.data.companyWebsite;
          initialValues.companyAddress = res.data.companyAddress;
          initialValues.companyPhone = res.data.companyPhone;
          initialValues.logo = res.data.logo;
          setLogoName(res.data.logo);
          SetPresentationFile(res.data.companyPresentation)
          setCompanyPhone(res.data.companyPhone);
          setCompanyDialCode(res.data.companyDialCode);
          setSelected(res.data.companyCountry);
          initialValues.companyCountry = res.data.companyCountry;
          setVideoFileName(res.data.companyVideo);
          var introVideo = res.data.companyIntroVideos.split(",").map((ele,ind) => {
            return { url: ele, name : res.data.companyIntroVideoNames.split(",")[ind]}
          } );   
          var thankVideo = res.data.companyThanksVideos.split(",").map((ele,ind) => {
            return { url: ele, name : res.data.companyThanksVideoNames.split(",")[ind]}
          } ); 
          
          var ytvideos = res.data.companyYoutubeLinks.split(",").map((ele,ind) => {
            return {  url : res.data.companyYoutubeLinks.split(",")[ind]}
          } ); 

          var CompanyImages = res.data.companyImages.split(",").map((ele,ind) => {
            return {  url : res.data.companyImages.split(",")[ind]}
          } ); 
          setCompanyImages(CompanyImages);
          SetFormData({
            ...formData,
            introVideos: introVideo,
            thanksVideos:thankVideo,
            youtubeVideos:ytvideos,
            companyImages: CompanyImages
          });
          setIntroVideos(introVideo);
          setThanksVideos(thankVideo);
          setYoutubeVideos(ytvideos);
          formik.resetForm();
          //setSt(res.data);
        }
        else if(res.sessionExpired){
          logoutMethod();
          alertService.alert({ message :res.message, type : 'error'  });
          history.push("/auth/signin")
        }
        })
      .catch(() => {
      });
  }
  
  const dispatch = useDispatch();
  const logoutMethod = () => {
    dispatch(logout());
    history.push('/auth/signin');
};

  const edit = (values) => {
    var id = companyDetails.id;
    const reqData = {
      companyName: values.companyName,
      companyAddress: values.companyAddress,
      companyWebsite: values.companyWebsite,
      companyCountry: "india",
      logo: logo,
      companyVideo: videoFileName,
      companyImages: formData.companyImages.filter(x => x.url).map(x => x.url).join(),
      companyIntroVideoNames: formData.introVideos.filter(x => x.url).map(x => x.name).join(),
      companyIntroVideos: formData.introVideos.filter(x => x.url).map(x => x.url).join(),
      companyThanksVideoNames: formData.thanksVideos.filter(x => x.url).map(x => x.name).join(),
      companyThanksVideos: formData.thanksVideos.filter(x => x.url).map(x => x.url).join(),
      companyYoutubeLinks: formData.youtubeVideos.filter(x => x.url).map(x => x.url).join(),  
      companyPresentation: Presentation,
      companyCountry: selected,
      phone: phone,
      companyDialCode: companyDialCode,
    }
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/` + id, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(reqData)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          alertService.alert({ message: res.message, type: 'success' });
          history.push('/company-mangement/view');
        }
        else if(res.sessionExpired){
          logoutMethod();
          alertService.alert({ message :res.message, type : 'error'  });
        }
        else {
          alertService.alert({ message: res.message, type: 'error' });
        }
      })
      .catch(() => {

      });
  }

  useEffect(() => {
    let mounted = true;
    getCompanyDetails();
    return () => mounted = false;
  }, [])


  const AddNewRequistionSchema = Yup.object().shape({
    // companyName: Yup.string()
    //    .required('Required'),
    //    companyWebsite: Yup.string()
    //    .required('Required'),
    //    companyName: Yup.string()
    //    .required('Required'),
    //    companyAddress: Yup.string()
    //    .required('Required'),
    //    companyCountry: Yup.string()
    //    .required('Required'),
    //    companyPhone: Yup.string()
    //    .required('Required'),
    // file: Yup.string()
    // .required('Required'),
    //   assignedTo: Yup.array()
    //   .required('Required'),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AddNewRequistionSchema,
    validateOnChange: true,
    validateOnBlur: false,
    validator: () => { },
    onSubmit: (values) => {
      edit(values);
    },
  });


  const [formData, SetFormData] = useState(
    {
      introVideos: [{ url: "", name: "" }],
      companyImages: [{ url: ""}],
      thanksVideos: [{ url: "", name: "" }],
      youtubeVideos: [{ url: ""}],
    }
  )

  const addMoreIntroVideo = () => {
    if (formData.introVideos.length > 2) {
      alertService.alert({ message: "Maximmun 3 videos are Allowed", type: 'info' });
    }
    else {
      SetFormData({
        ...formData,
        introVideos: [...formData.introVideos, { url: "", name: "" }],
      });
      setIntroVideos(formData.introVideos);
      //  formData.introVideos.push("");
    }
  }

  const [thanksVideos, setThanksVideos] = useState([{name:"", url:""}]);
  const addMoreThanksVideo = () => {
    if (formData.thanksVideos.length > 4) {
      alertService.alert({ message: "Maximmun 5 videos are Allowed", type: 'info' });
    }
    else {
      SetFormData({
        ...formData,
        thanksVideos: [...formData.thanksVideos, { url: "", name: "" }],
      });
      setThanksVideos(formData.thanksVideos);
      //  formData.introVideos.push("");
    }
  }


  const [companyImages, setCompanyImages] = useState([{ url:""}]);
  const addMoreCompanyImages = () => {
    if (formData.companyImages.length > 4) {
      alertService.alert({ message: "Maximmun 5 images are Allowed", type: 'info' });
    }
    else {
      SetFormData({
        ...formData,
        companyimages: [...formData.companyImages, { url: ""}],
      });
      setCompanyImages(formData.companyImages);
      //  formData.introVideos.push("");
    }
  }

 const [youtubeVideos, setYoutubeVideos] = useState([{ url:""}]);
 
 const addMoreYoutubeVideo = () => {
  if (formData.youtubeVideos.length > 2) {
    alertService.alert({ message: "Maximmun 3 videos Links are Allowed", type: 'info' });
  }
  else {
    SetFormData({
      ...formData,
      youtubeVideos: [...formData.youtubeVideos, { url: ""} ],
    });
    setYoutubeVideos(formData.youtubeVideos);
    //  formData.introVideos.push("");
  }
}

  const deleteIntrovideo = (ind, ele) => {
    if (formData.introVideos.length > 1) {
      const newintroVideos = [...formData.introVideos];
      newintroVideos.splice(ind, 1);
      SetFormData({
        ...formData,
        introVideos: newintroVideos,
      })
    }
  }

  const deleteThanksvideo = (ind, ele) => {
    if (formData.thanksVideos.length > 1) {
      const newthanksVideos = [...formData.thanksVideos];
      newthanksVideos.splice(ind, 1);
      SetFormData({
        ...formData,
        thanksVideos: newthanksVideos,
      })
    }
  }

  const deleteYoutubevideo = (ind, ele) => {
    if (formData.youtubeVideos.length > 1) {
      const newyoutubeVideos = [...formData.youtubeVideos];
      newyoutubeVideos.splice(ind, 1);
      SetFormData({
        ...formData,
        youtubeVideos: newyoutubeVideos,
      })
    }
  }

  const deleteCompanyImages = (ind, ele) => {
    if (formData.companyImages.length > 1) {
      const newCompanyImages = [...formData.companyImages];
      newCompanyImages.splice(ind, 1);
      SetFormData({
        ...formData,
        companyImages: newCompanyImages
      })
    }
  }

const [companyVideo,setCompanyVideo ] = useState() 
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  function handleChangeIndex(index) {
    setValue(index);
  }

  const reqsave = () => {
    let savebtn = document.getElementById("req-save-btn");
    savebtn.click();
  }

  const [companyPhone, setCompanyPhone] = useState("");
  const [companyDialCode, setCompanyDialCode] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneErrorMSg, setPhoneErrorMsg] = useState("");
  const [companyPhoneErrorMSg, setCompanyPhoneErrorMsg] = useState("");
  const handlePhoneChange = (value, data, event, formattedValue) => {
    setPhone(value.slice(data.dialCode.length));
    setDialCode(data.dialCode);
    setCompanyPhone(value.slice(data.dialCode.length));
    setCompanyDialCode(data.dialCode)

  }
  const [selected, setSelected] = useState("");
  const onSelect = (code) => {
    setSelected(code);
    alert(value)
  }
  const countries = ["US", "GB", "DE", "FR", "NG", "ES", "IN"];
  const blacklistCountries = false;

  const triggerClick = () => {
    let ClickItem = document.getElementById("fileUpload-inp");
    if (ClickItem) {
      ClickItem.click();
    }
  }


  const triggerClickCompanypresentation = () => {
    let ClickItem = document.getElementById("fileUpload-presentation");
    if (ClickItem) {
      ClickItem.click();
    }
  }

  const triggerComapanyImagesUpload = (inputId) => {
    let ClickItem = document.getElementById(inputId);
    if (ClickItem) {
      ClickItem.click();
    }
  }

  const triggerInrtovideosUpload = (inputId) => {
    let ClickItem = document.getElementById(inputId);
    if (ClickItem) {
      ClickItem.click();
    }
  }

  const triggerThanksvideosUpload = (inputId) => {
    let ClickItem = document.getElementById(inputId);
    if (ClickItem) {
      ClickItem.click();
    }
  }

  const triggerComapnyVideo = (inputId) => {
    let ClickItem = document.getElementById(inputId);
    if (ClickItem) {
      ClickItem.click();
    }
  }

  const [logoUrl, setFileUrl] = useState();

  const deleteFileVideo = (url) => {
    const token = localStorage.getItem('x-access-token');
    var reqData = logo;
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/delete_file`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify({
        fileName: [url]
      })

    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          //  view_requisition();
          setVideoFileName("");
          alertService.alert({ message: res.message, type: 'success' });
        }
        else {
          alertService.alert({ message: res.message, type: 'error' });

        }
      })
  }

  // delete file 
  const deletefileLogo = (url) => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/delete_file`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify({
        fileName: [url]
      })

    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          //  view_requisition();
          setLogoName("");
          alertService.alert({ message: res.message, type: 'success' });
        }
        else if(res.sessionExpired){
          logoutMethod();
          alertService.alert({ message :res.message, type : 'error'  });
        }
        else {
          alertService.alert({ message: res.message, type: 'error' });

        }
      })
  }


const deleteCompanyLogo = () => {

}

const[ deletefileURLs, setDeleteFileURLs ] =  useState([]);
const [modalShow, setModalShow] = React.useState(false);

function DeleteConfirmationModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Body>
        <Modal.Title id="contained-modal-title-vcenter text-center">
          <p className="text-center">
          <img alt="logo" className="brand-dim" src="/media/logos/UPDATED_VRekruit _Logo.png" />
            </p>
          <p className="text-dark text-center pt-3"> Are you sure want to delete?</p>
        </Modal.Title>
      </Modal.Body>
      <Modal.Title className="d-flex justify-content-center align-items-center pb-5" >
      <p className="text-center">

      <Button className="btn btn-theme text-white btn-lg mr-5  pl-5 pr-5 pt-3 pb-3" style={{"minWidth": "100px"}} onClick=""> Yes </Button>
     <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green" style={{"minWidth": "100px"}}  onClick={props.onHide}>No</Button> 
     </p>
      </Modal.Title>
    </Modal>
  );
}
  


  // Logo File Upload
  const handleUpload = (ev) => {
    let file = ev.target.files[0];
    if (file) {
      // Split the filename to get the name and type
      let fileParts = ev.target.files[0].name.split('.');
      let fileType = fileParts[1];
      axios.post(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/upload_file`, {
        fileName: fileParts[0] + "." + fileParts[1],
        fileType: fileType,
        fileFolder: fileType == 'jpeg' ? "image" : "file"
      },
        {
          headers: {
            'x-access-token': localStorage.getItem('x-access-token'),
          }
        })
        .then(response => {
          if(response.status){
            var returnData = response.data.data.returnData;
            var signedRequest = returnData.signedRequest;
            var url = returnData.url;
            setFileUrl(url);
            setLogoName(returnData.url);
            if (url) {
              initialValues.logo = returnData.url;
            }
  
            // Put the fileType in the headers for the upload
            var options = {
              headers: {
                'Content-Type': fileParts[1]
              }
            };
            axios.put(signedRequest, file, options)
              .then(result => {
              })
              .catch(error => {
                alert("ERROR " + JSON.stringify(error));
              })

          }
            else if(response.sessionExpired){
              logoutMethod();
              alertService.alert({ message :response.message, type : 'error'  });
            }
        })
        .catch(error => {
          alert(JSON.stringify(error));
        })
    }
  }




  const deleteCompanyImage = (url) => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/delete_file`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify({
        fileName: [url]
      })

    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          //  view_requisition();
          url = "";
          alertService.alert({ message: res.message, type: 'success' });
        }
        else {
          alertService.alert({ message: res.message, type: 'error' });

        }
      })
  }

  const [videoFileName, setVideoFileName] = useState();
  const [introVideos, setIntroVideos] = useState([{name:"", url:""}]);
  const [logo, setLogoName] = useState("")
  const [Presentation, SetPresentationFile] = useState("");
  

  const prevent = (e) => {
   e.preventDefault();
  }

if(localStorage.getItem("userType")  !== "Company Admin"){
 return ""
}
else {
   
  return (
    <>
      <Helmet>
        <title> Company Management </title>
      </Helmet>

     {/* subeader */}
      <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Company Management</h3></div>
          </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
      {/* subeader */}




      <Col className="bg-white" >
      
      <Row className="bg-white mb-0 pb-0 pt-5 sticky-header"> 
          <Col md={6} sm={12} offset={4} className="pr-3 pl-3">
            <div>
              <div className="d-flex flex-grow-1 align-items-center  justify-content-start   rounded">
                <div>
                  <h5 className="font-weight-bold f-w-600 pl-5 pt-2" > Company Information </h5>
                  <p className=" text-danger f-w-600 pl-5 pt-2" > Note: Click on Edit to Brand your Company Page </p>

                </div>
              </div>
            </div>
          </Col>
          <Col md={6} sm={12} offset={4} className="pr-3 pl-3">
            <div>
              <div className="d-flex flex-grow-1 align-items-center justify-content-end rounded mt-1">
                <div className="text-muted d-block">
                  <Link to="/company-mangement/edit"  className="btn btn-theme-green btn-lg mr-5"  >
                    Edit
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col md={12} sm={12} className="separator separator-solid "></Col>
        </Row>


        <Col md={12} sm={12} offset={4} className="pt-10" >
              <div className="bg--tile rounded">
       

                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline " alt="" width="25" src="/media/VRekruitIcons/building.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Company Name</h6>
                    <p className="text-dark mt-0">
                      { companyDetails && companyDetails.companyName ? companyDetails.companyName  : ""}            
                  </p>
                  </div>
                </div>
                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" alt="" src="/media/VRekruitIcons/globe.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Company Website</h6>
                    <p className="text-dark mt-0">
                    { companyDetails && companyDetails.companyWebsite ? companyDetails.companyWebsite  : ""}            
                    </p>
                  </div>
                </div>

                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4" >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" alt="" src="/media/VRekruitIcons/call (1).svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Company Phone Number</h6>
                    <p className="text-dark mt-0">

                    { companyDetails && companyDetails.companyDialCode ? companyDetails.companyDialCode  : ""}   
                    { companyDetails && companyDetails.companyPhone ? " "+ companyDetails.companyPhone  : ""}    
                    </p>        
                  </div>
                </div>


                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" alt="" src="/media/VRekruitIcons/address.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Company Address</h6>
                    <p className="text-dark mt-0">

                    { companyDetails && companyDetails.companyAddress ? " "+ companyDetails.companyAddress  : ""}   
                    </p>         
                  </div>
                </div>


                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25"  alt="" src="/media/VRekruitIcons/country.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Country</h6>
                    { companyDetails && companyDetails.companyCountry && companyDetails.companyCountry != 0 ?
                     <ReactFlagsSelect
                     style={{"position": "relative","left": "-16px !important"}}
                     selected={companyDetails.companyCountry  ? companyDetails.companyCountry :"IN"}
                     selected={"IN"}
                     disabled
                     className="view-country"
                     /> : ""}            
                  </div>
                </div>

                
                {companyDetails?.companyState != 0 &&
                <>
                  <div className="separator separator-solid bg-white" ></div>
                  <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                    <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                      <img className="d-inline" width="25"  alt="" src="/media/VRekruitIcons/country.svg" />
                    </div>
                    <div className="text-muted d-block">
                      <h6 className="text-dark">Company State</h6>
                      <p className="text-dark mt-0">

                        {companyDetails && companyDetails.companyState  ?
                            companyDetails.companyState     : ""}  
                       </p>          
                    </div>
                  </div>
                </>
                }

                

                {companyDetails && companyDetails.companyPan ?  
                <>
                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25"  alt="" src="/media/VRekruitIcons/id-card-user.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Company PAN</h6>
                    <p className="text-dark mt-0">
                    { companyDetails && companyDetails.companyPan ? companyDetails.companyPan  : ""}            
                    </p>
                             
                  </div>
                </div>
                </>
                :""}

               {companyDetails && companyDetails.companyGst ?  
               <>               
               <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25"  alt="" src="/media/VRekruitIcons/GST-TAX.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Company GST</h6>
                    <p className="text-dark mt-0">
                    { companyDetails && companyDetails.companyGst ? companyDetails.companyGst  : ""}            
                    </p>
         
                  </div>
                </div>
                </>
                :""}



              </div>  
            </Col>

          {logo ?(
          <>
          <h6 className="text-dark mt-5 pl-5">Company Logo</h6>
           <Row offset={4} className="mt-5  custom-row-css" >
                  <Col sm={6} md={3} className="pt-5 pb-5" >
                  {/* <img className="cursor-zoom-in" src={logo ? logo : ""} width="100%" alt="logo" height="120px" controls={true} />    */}
                    <ModalImage
                    className="cursor-zoom-in"
                      small={logo}
                      large={logo}
                      alt=""
                      hideDownload={true}
                      hideZoom={true}

                    />       
                  </Col>
                 
            </Row>
            </>
            ):""}


            {formData.companyImages && formData.companyImages.length &&  formData.companyImages.filter(x=> x.url).length > 0  ? (

            <>
            <h6 className="text-dark mt-5 pl-5">Company Images</h6>
            <Row  offset={4} className="mt-5 custom-row-css" >
              
                  {formData.companyImages.map((ele, ind) =>
                      <Col sm={6} md={3} key={ind} className="pt-5">
                        <ModalImage
                    className="cursor-zoom-in"
                      small={companyImages[ind]?.url ? companyImages[ind].url : ""}
                      large={companyImages[ind]?.url ? companyImages[ind].url : ""}
                      alt=""
                      hideDownload={true}
                      hideZoom={true}

                    />  
                        <p>
                      </p>
                      </Col>
                    )}
                
            </Row>
            </>
            ):""}

            {videoFileName ? (
            <>
            <h6 className="text-dark mt-5 pl-5">Company Video</h6>
            <Col md={12} sm={12} offset={4} className="mt-5" >
              <div className="bg--tile rounded">      
                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pb-4 pt-4 pl-4" >               
                    <Col sm={12} md={4}>
                         <ReactPlayer url={videoFileName ? videoFileName : ""} width="100%"  height="190px" controls={true} />
                     </Col>
                </div>
              </div>
            </Col>
            </>
            ):""}


        {formData.introVideos && formData.introVideos.length &&  formData.introVideos.filter(x=> x.url).length > 0  ? (

             <>
            <h6 className="text-dark mt-5 pl-5">Company Welcome Video</h6>
            <Row className="mt-5 custom-row-css"  >
                          
                  {formData.introVideos.map((ele, ind) =>
                    <Col sm={12} md={4} key={ind} className="pt-5 pb-5">
                      <ReactPlayer url={introVideos[ind]?.url ? introVideos[ind].url : ""} width="100%" height="190px" controls={true} />
                      {/* <p className="mt-2 mb-0 text-center">{introVideos[ind]?.name}</p> */}
                    </Col>
                    )}
               
            </Row>
            </>
            ):""
            }


         {formData.thanksVideos && formData.thanksVideos.length &&  formData.thanksVideos.filter(x=> x.url).length > 0  ? (
           <>
            <h6 className="text-dark mt-5 pl-5">Company Thank You Video</h6>
            <Row  className="mt-5 custom-row-css" >
                       
                 {formData.thanksVideos.map((ele, ind) =>
                    <Col sm={12} md={4} key={ind} className="pt-5 pb-5">
                      <ReactPlayer url={thanksVideos[ind]?.url ? ele.url : ""} width="100%" height="190px" controls={true} />
                      {/* <p className="mt-2 mb-0 text-center">{thanksVideos[ind]?.name}</p> */}
                    </Col>
                    )}
               
            </Row>
            </>
         ):""}


      {formData.youtubeVideos && formData.youtubeVideos.length &&  formData.youtubeVideos.filter(x=> x.url).length > 0  ? (
            <>
            <h6 className="text-dark mt-5 pl-5">Youtube Video Link</h6>
            <Row  className="mt-5 custom-row-css" >    
                  {formData.youtubeVideos.map((ele, ind) =>
                    <Col sm={12} md={4} key={ind} className="pt-5 pb-5">
                      <ReactPlayer url={youtubeVideos[ind]?.url ? youtubeVideos[ind].url : ""} width="100%" height="190px" controls={true} />
                    </Col>
                    )}
            </Row>
            </>
            ):""}

            {Presentation? (
            <>
            <h6 className="text-dark mt-5 pl-5">Presentation File</h6>
            <Col md={12} sm={12} offset={4} className="mt-5" >
              <div className="bg--tile rounded">      
                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pb-4 pt-4 pl-4"  >               
                  <div className="text-muted d-flex">    
                  <Col sm={6} md={3} className="d-flex  align-items-center">
                  <Link  to="#" className="fs-20 d-flex  align-items-center text-primary" onClick={(event) => { event.preventDefault(); window.open(Presentation); }}>
                  <img src="/media/VRekruitIcons/pdf (1).svg" className="d-inline" alt="img" width="100%" height="100px" controls={true} />

                  Company  Presentation
                      </Link>                  
                  </Col>
                  </div>
                </div>
              </div>
            </Col>
            </>
            ):""}


        <div className="pb-5 pt-5"></div>
      </Col>
    </>
 
  );
            }
}

export default EditCompanyDetails