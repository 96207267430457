/* eslint-disable no-restricted-imports */
/* jshint asi:true */

import React from "react";
import Helmet from "react-helmet"
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormFile from 'react-bootstrap/FormFile'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Table from 'react-bootstrap/Table'



const customTotal = (from, to, size) => (
   <span className="react-bootstrap-table-pagination-total">
     Showing { from } to { to } of { size } Results
   </span>
 );


 const columns = [{
   dataField: 'Question',
   text: 'Question',
   sort: true

 }, {
   dataField: 'Type',
   text: 'Type',
   sort: true

 },
 {
   dataField: 'ParentCategory',
   text: 'Parent Category',
   sort: true

 },
 {
   dataField: 'SubCategory',
   text: 'Sub Category',
   sort: true

 },
 {
   dataField: 'DifficultyLevel',
   text: 'Difficulty Level',
   sort: true

 },
 {
   dataField: 'ModifiedBy',
   text: 'Modified By',
   sort: true

   
 },
 {
   dataField: 'Actions',
   text: 'Action',
   sort: true

 },

];



const selectRow = {
   mode: 'radio',
   clickToSelect: true
 };
 
{/* <i className="fa fa-trash"></i>  <i className="fa fa-eye"> </i>  <i className="fa fa-edit"></i> */}
 const Questions = 
 [ {
    Question:"Lorem Ipsum Dolar Sit Amet",
    Type:"MCQ",
    ParentCategory:"SAMPLE",
    SubCategory:"Sample",
    DifficultyLevel:"Hard",
    ModifiedBy:"User 1",
    Actions: <i className="fa fa-trash"></i>
   },
   {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>
   },
     {
      Question:"Aorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>
   },
     {
      Question:"Aorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>
   },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>
   },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     },
     {
      Question:"Lorem Ipsum Dolar Sit Amet",
      Type:"MCQ",
      ParentCategory:"SAMPLE",
      SubCategory:"Sample",
      DifficultyLevel:"Hard",
      ModifiedBy:"User 1",
      Actions: <i className="fa fa-trash"></i>

     }
 ]

 
 const options = {
   paginationSize: 4,
   pageStartIndex: 0,
   // alwaysShowAllBtns: true, // Always show next and previous button
   // withFirstAndLast: false, // Hide the going to First and Last page button
   // hideSizePerPage: true, // Hide the sizePerPage dropdown always
   // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
   firstPageText: '<<',
   prePageText: '<',
   nextPageText: '>',
   lastPageText: '>>',
   nextPageTitle: 'First page',
   prePageTitle: 'Pre page',
   firstPageTitle: 'Next page',
   lastPageTitle: 'Last page',
   showTotal: true,
   paginationTotalRenderer: customTotal,
   disablePageTitle: true,
   sizePerPageList: [{
     text: '10', value: 10
   }, {
     text: '10', value: 10
   }, {
     text: 'All', value: Questions.length
   }] // A numeric array is also available. the purpose of above example is custom the text
 };


 function TabContainer({ children, dir }) {
    return (
      <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
        {children}
      </Typography>
    );
  }
  
  TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired,
  };
  
  const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      
    },
  }));



 function ManageTest() {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
  
    function handleChange(event, newValue) {
      setValue(newValue);
    }
  
    function handleChangeIndex(index) {
      setValue(index);
    }

    return <>
        <Helmet>
            <title> VRekruit | Manage Test </title>
       </Helmet>

       <div className="bg-white mb-5 d-flex justify-content-space-between" style={{"padding":"40px"}}>
       <div>
         <h3> Share Test With Candidate </h3>
         <p> Fill the Information Below </p>
      </div>
      <div>
      <button className="btn btn-theme-green btn-lg mr-5">
         Previous
      </button>
      <button className="btn btn-theme-green text-white btn-lg mr-5">
         Send Test
      </button>

      <button className="btn btn-theme text-white btn-lg mr-5">
         Cancel
      </button>
      </div>
      </div>
      
      {/* <switch> */}

      <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Basic Detail" />
          <Tab label="Add Candidate" />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabContainer dir={theme.direction}>
            <BootstrapTable className="bg-white" keyField='id' data={ Questions } columns={ columns } pagination={ paginationFactory(options) }   />
        </TabContainer>
        <TabContainer dir={theme.direction}>
        <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column sm="2">
                       Upload CSV File
                    </Form.Label>
                    <Col sm="7">
                    <Form.Group as={Col} controlId="formGridState">
                    <Form.Control as="file">
                    <Form.File id="exampleFormControlFile1" />
                    </Form.Control>
                    </Form.Group>
                    <p className="text-theme-green ml-5 c-p"> <i className="fa fa-download text-theme-green"> </i> Download CSV Template</p>
                    </Col>
        </Form.Group>
          <BootstrapTable className="bg-white" keyField='id' data={ Questions } columns={ columns } pagination={ paginationFactory(options) }   />            
        </TabContainer>
      </SwipeableViews>
    </div>
     
      {/* <BootstrapTable className="bg-white" keyField='id' data={ Questions } columns={ columns } pagination={ paginationFactory(options) }   selectRow={ selectRow } /> */}


    </>;
}

export default ManageTest