/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormFile from 'react-bootstrap/FormFile'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Table from 'react-bootstrap/Table'
import { divide } from 'lodash';
import Helmet from 'react-helmet'
import { Link, useHistory } from "react-router-dom";
import { Redirect } from 'react-router';
import alertService from '../../../_services/alertservice';
import { Button } from 'react-bootstrap';
import { CALL_HISTORY_METHOD } from 'react-router-redux';
import Modal from 'react-bootstrap/Modal'
import Slider from '@material-ui/core/Slider';
import { isAndroid, isIOS } from "react-device-detect";





// const useStyles = makeStyles(theme => ({
//   root: {
//     backgroundColor: theme.palette.background.paper,
//   },
// }));


{/* <tab style css statrt> */}
function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}
TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

{/* <tab style css statrt> */}

const initialValues = {
  status: "open",
  page: 1,
  limit: 10,
  assignedToMe: false,
  createdByMe: "0",
  orderBy: "Id",
  orderType: "ASC"
}

function Interview() {
    const interviewKey = 'abc'

  const [QuestionsList, setQuestionsList] = useState([]);
  const [staticData, setStaticData] = useState(10);
  const [parentCategories, setParentCategories] = useState([]);
  const [searchTerm, SetSearchTerm] = useState("");
  const [createdbyme, SetCreatedbyme] = useState(false);
  const [assignedbyme, SetAssignedbyme] = useState(false);
  const [status, SetStatus] = useState("");
  const [selectedId, setSelectedOption] = useState(true);
  const [subCategory, setSubCategory] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [categorySelected, SetcategorySelected] = useState("");
  const [subcategorySelected, SetSubcategorySelected] = useState("");
  const [difficultyLevel, setDifficultyLevel] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [buttonState,SetButtonState] = useState({
    "previewBtnState": true,
    "addQuestionButtonState": true,
  })
  const [rangeValues, SetRangeValues ]= useState({
    "minRange": 10,
    "maxRange": 70, 
  })



  const[bankType,SetBankType]= useState("private");
  const handleOptionChange = (e) =>  {
      var banktypeval = e;
      SetBankType(banktypeval);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }
   
  const [value, setValue] = React.useState([10, 70]);

  const handleChange = (event, newValue) => {
    
    setValue(newValue);
    SetRangeValues({
      ...rangeValues,
       minRange: newValue[0],
       maxRange: newValue[1],
    })
  };


  function valuetext(value) {
    return `${value}`;
  }
  

  const resetFilter = () => {
    SetAssignedbyme(false);
    SetCreatedbyme(false)
    SetSearchTerm("");
    SetStatus("");
    get_List_without_filters();
  }

  // filter option 
  const SetSearchTermData = (x) => {
    let data = x.target.value;
    if (data) {
      SetSearchTerm(x.target.value);
    }
    else {
      SetSearchTerm("");
    }
  }

  function handleCategorySelect(event) {
    if (event.target.value) {
      SetcategorySelected(event.target.value);
    }
    else {
      SetcategorySelected("");
    }
    // document.getElementById("subcategoryvalue").value= ""    
    var parentId = (event.target.value);
    get_sub_categories(parentId);
  }

  function handleSubCategorySelect(event) {
    if (event.target.value) {
      SetSubcategorySelected(event.target.value);
    }
    else {
      SetSubcategorySelected("");
    }

  }


  function handleDifficultyLevelSelect(event) {
    if (event.target.value) {
      setDifficultyLevel(event.target.value);
    }
    else {
      setDifficultyLevel("");

    }

  }


  function handleQuestionTypeSelect(event) {
    if (event.target.value) {
      setQuestionType(event.target.value);
    }
    else {
      setQuestionType("");

    }

  }

  const SetAssignedbymeData = (x) => {
    SetAssignedbyme(x.target.checked);
  }

  const SetCreatedbymeData = (x) => {
    SetCreatedbyme(x.target.checked);
  }

  // filter option 


  var i = 1;
  const get_Question_List = () => {

    const token = localStorage.getItem('x-access-token');
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/interview/questions?page=1&orderType=ASC&limit=1000`;
    //  history.push("/searchTerm");
     if(bankType){
      url = url + "&bankType=" + bankType;
    }

    if (difficultyLevel) {
      url = url + "&difficultyLevel=" + difficultyLevel;
    }

    if (categorySelected) {
      url = url + "&categoryId=" + categorySelected;

    }

    if (subcategorySelected) {
      url = url + "&subCategoryId=" + subcategorySelected;

    }

    if (questionType) {
      url = url + "&questionType=" + questionType;

    }

    if (searchTerm) {
      url = url + "&searchBy=" + searchTerm;
    }

    if(rangeValues){
      url = url + "&minMarks=" +  rangeValues.minRange  +  "&maxMarks=" +  rangeValues.maxRange
    }



    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setQuestionsList(res.data);
          setStaticData(staticData + 10);
        }
        else {
        }
      })
      .catch(() => {
      });
  }



  const get_List_without_filters = () => {
    const token = localStorage.getItem('x-access-token');
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/interview/questions?page=1&orderType=DESC&limit=1000`;
    if(bankType){
      url = url + "&bankType=" + bankType;
    }
    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setQuestionsList(res.data);
          setStaticData(staticData + 10);
        }
        else {
        }
      })
      .catch(() => {
      });
  }


  const get_parent_categories = (values) => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/categories?page=1&limit=1000`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(values)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setParentCategories(res.data);
        }
        else {
        }
      })
      .catch(() => {
      });
  }


const histroy = useHistory() 

  useEffect(() => {
    let mounted = true;
    // get_Question_List();
    // get_parent_categories();

    if (isAndroid) {
      const url =
          `intent://dev.vrekruit.com/#Intent;scheme=https;package=com.vrekruit.app;view=CandidateInterview;id=${interviewKey}end`;

      window.location.replace(url);
      //  history.push(url);

  } else if (isIOS) {
      window.location.replace("instagram://");

      setTimeout(() => {
          window.location.replace(
              "https://apps.apple.com/us/app/instagram/id389801252"
          );
      }, 10000);
  }



    return () => mounted = false;
  }, [])

  const classes = useStyles();
  const theme = useTheme();


  const [QuestionData,setQuestionData]= useState({})

  const actionControls = {
    onClick: (e, row, rowIndex) => {
      if (row) {
        let id = row.id;
        if (e.target.classList.contains("view-option")) {
          setQuestionData(row);
          setModalShow(true);

        }
        else if(e.target.classList.contains("delete-option")) {
         const newtestQuestions = [...testQuestions ];
          newtestQuestions.splice(rowIndex,1);
          setTestQuestions(newtestQuestions)
          
        }

      }
    }
  };


  function actionFormatter(column, colIndex) {
    return (
      <div className="text-left d-flex">
        <img src="/media/VRekruitIcons/eyeorview.svg" className="view-option c-p view-eye-ico-1" />
      </div>
    );
  }


  function actionFormatterTestQuestion(column, colIndex) {
    return (
      <div className="text-left d-flex">
        <img src="/media/VRekruitIcons/eyeorview.svg" className="view-option c-p view-eye-ico-1" />

        <img src="/media/VRekruitIcons/delete.svg" className="delete-option c-p view-eye-ico ml-5" />

      </div>
    );
  }


  function CreatedByUserFormater(column, colIndex) {
    return (
      <span>
        {column.firstName}  {column.lastName}
      </span>
    );
  }

  function CreatedByUserFormaterTest(columnsTestQustionData, colIndex) {
    return (
      <span>
        {columnsTestQustionData.firstName}  {columnsTestQustionData.lastName}
      </span>
    );
  }


  function headerformatter(column, colIndex) {
    return (
      <div>
        {column.text}
        <img src="/media/VRekruitIcons/sort.svg" className="sort-icon c-p" width="30" height="30" />
      </div>
    );
  }




  function headerformatterAction(column, colIndex) {
    return (
      <div>
        Action
      </div>
    );
  }



  const get_sub_categories = (parentId) => {
    if (parentId) {
      const token = localStorage.getItem('x-access-token');
      fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/sub_categories?page=1&limit=1000&parentCategoryId=` + parentId, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'x-access-token': token
        },
        // body: JSON.stringify(values)
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status == true) {
            setSubCategories(res.data);

          }

        })
        .catch(() => {
        });
    }
    else {
      setSubCategories([]);
    }
  }
  const history = useHistory();

  const Create_Test = (e) => {

    e.preventDefault();

    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/create`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(formData)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {

          alertService.alert({ message: res.message, type: 'info' });
          history.push('/interview/manage-interview');

        }
        else {
          if (res.errors) {
            var errmsg = "";
            res.errors.forEach(function (value, index, array) {
              errmsg = errmsg + "(" + (index + 1) + ") " + value.msg + "\n\r";

            });
            alertService.alert({ message: errmsg, type: 'error' });

          }

          else {
            alertService.alert({ message: res.message, type: 'error' });
          }
        }

      })
      .catch(() => {

      });
  }


  const save_share_test = (e) => {

    e.preventDefault();
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/test/create`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(formData)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          
          alertService.alert({ message: res.message, type: 'info' });
          history.push('/manage/share-interview/'+ res.data.id);

        }
        else {
          if (res.errors) {
            var errmsg = "";
            res.errors.forEach(function (value, index, array) {
              errmsg = errmsg + "(" + (index + 1) + ") " + value.msg + "\n\r";

            });
            alertService.alert({ message: errmsg, type: 'error' });

          }

          else {
            alertService.alert({ message: res.message, type: 'error' });
          }
        }

      })
      .catch(() => {

      });
  }





  const [isSelected, SelectedQuestion] = useState(false);
  
  
  const [testQuestions, setTestQuestions] = useState([]);

  const [formData, SetFormData] = useState(
    {
      interviewTitle:"",
      questions: [],
    }
  )

  const setSelectedValue = (value) => {
    if (formData.questions.find(x => x.questionId == value)) {
      formData.questions.splice(formData.questions.findIndex(x => x.questionId == value), 1)
    } else {
      formData.questions.push({ questionId: value });
    }


    SetFormData({
      ...formData,
      questions: formData.questions
    })
    
    if(formData.questions.length > 0) {
      SetButtonState({
        ...buttonState,
        addQuestionButtonState: false
      })
    }
    else {
      SetButtonState({
        ...buttonState,
        addQuestionButtonState: true
      })
    }
  }

  const settestName = (event) => {
    SetFormData({
      ...formData,
      interviewTitle: event.target.value
    })
  }

  const [totalTestMarks, SetTotalTestMarks] = useState();  
  const addQuestionsToTest = () => {
    formData.questions.forEach(que => {
      var element = QuestionsList.splice(QuestionsList.findIndex(x => x.id == que.questionId), 1);
      testQuestions.push(element[0]);
    });
    setTestQuestions(testQuestions);
    var totalMarks = testQuestions.map(x => x.marks).reduce((a, b) => a + b, 0);
    SetTotalTestMarks(totalMarks);  
    alertService.alert({ message : "Questions Added Successfully", type : 'success'  });
  }

  function selectCheckboxFormatter(column, colIndex) {
    return (
      <div className="text-left">
        <Form.Check inline label="" className="ml-2 mt-2" value={column} name="questionId" onChange={() => setSelectedValue(column)} />
      </div>
    );
  }

  function selectallFormatter(column, colIndex) {
    return (
      <div className="text-left">
        <Form.Check inline label="" className="ml-2 mt-2" />
      </div>
    );
  }

  var columns = [
    {
      dataField: 'id',
      formatter: selectCheckboxFormatter,
      headerFormatter: selectallFormatter,
    },

    {
      dataField: 'question',
      text: "Question",
      sort: true,
      headerFormatter: headerformatter
    },
   
    {
      dataField: 'interviewCategory.categoryName',
      text: "Parent Category",
      sort: true,
      headerFormatter: headerformatter
    },
    {
      dataField: 'interviewSubCategory.subCategoryName',
      text: "Sub Category",
      sort: true,
      headerFormatter: headerformatter
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
      formatter: actionFormatter,
      headerFormatter: headerformatterAction
    },
  ];

  var columnsTestQustionData = [
    {
      dataField: 'question',
      text: "Question",
      sort: true,
      headerFormatter: headerformatter
    },
    {
      dataField: 'questionCategory.categoryName',
      text: "Parent Category",
      sort: true,
      headerFormatter: headerformatter
    },
    {
      dataField: 'questionSubCategory.subCategoryName',
      text: "Sub Category",
      sort: true,
      headerFormatter: headerformatter
    },


    {
      dataField: "action",
      text: "Action",
      sort: true,
      formatter: actionFormatterTestQuestion,
      headerFormatter: headerformatterAction
    },
  ];



  const options = {
    paginationSize: 5,
    // custom: true,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    //  withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    onPageChange: (page, sizePerPage) => {
    
    },
    disablePageTitle: true
  };

  const [showpage, setShowPage] = useState({
    showCreateTest: true,
    showPreviewTestPage: false,
  })



  const showCreatePage = () => {
    setShowPage({
      ...showpage,
      showCreateTest: true,
      showPreviewTestPage: false
    })
  }

  const showPreviewPage = () => {
    setShowPage({
      ...showpage,
      showCreateTest: false,
      showPreviewTestPage: true
    })
  }



  const [modalShow, setModalShow] = React.useState(false);


  function ShowQuestionAnswerModal(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
        <p   onClick={props.onHide} className="c-p custom-close-css text-right" style={{"height":"10px"}} aria-hidden="true">×</p>
        <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          >
          <Tab label="Question" />
          <Tab label="Answer"   />
        </Tabs>
      </AppBar>


      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
       <TabContainer dir={theme.direction}>
            
       <Row>
          <Form.Label className="fw-600" column sm={3}>
             Question
          </Form.Label>
          <Col sm={9} md={7}>
          <Form.Control as="text" >
           {QuestionData ? QuestionData.question: "" }
          </Form.Control>        
          </Col>          
        </Row>

        <Row className="mt-5">
          <Form.Label className="fw-600" column sm={3}>
             Question Image
          </Form.Label>
          <Col sm={9} md={7}>
          <Form.Control as="text">
          {QuestionData ? QuestionData.imagePath: "" }
          </Form.Control>        
          </Col>          
        </Row>

        </TabContainer>
    <TabContainer dir={theme.direction}  >
    <Table responsive>
  <thead>
    <tr className="text-center">
      <th>Type Answer* </th>
      <th>Choose Correct* </th>
      <th>Answer Image</th>
    </tr>
  </thead>
  <tbody>

{/* 
  answer: "answer 1"
createdAt: "2021-06-16T12:40:48.000Z"
createdBy: 5
id: 173
imageName: ""
imagePath: ""
modifiedBy: 5
questionId: 76
rightAnswer: false
updatedAt: */}

    {QuestionData &&  QuestionData.answers && QuestionData.answers.map(x=>
    <tr className="text-center">  
       <td style={{"maxWidth":"150px"}}>  
      <Form.Control as="textarea" placeholder="type here..." rows={3} 
        name='answers'
        // data-index={ind}
        data-name='answer'
     >
      {x.answer}
     </Form.Control>

    </td>   
    {['radio'].map(type => (
    <div key={`custom-inline-${type}`} className="mb-3 mt-3">
    <Form.Check inline pr-3 label="Correct" name="correctOption" type={type} id={`inline-${type}-1`}
      name='answers'
      data-name='rightAnswer'
      checked={x.rightAnswer}
    />
    </div>
  ))}        
  </tr>
   )  }
  </tbody>
</Table>
{/* <button type="submit" className="submit-btn"> submit button </button> */}          
      </TabContainer>
      </SwipeableViews>
        </Modal.Body>
      
      </Modal>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          Create Interview
        </title>
      </Helmet>

      {/* subeader */}
      <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Interview Management</h3></div>
          </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
      {/* subeader */}

      {/*Start  addQuestionSection */}
      { showpage.showCreateTest ? (
        <Col className={classes.root} >
          <Row className="bg-white  d-flex flex-grow-1 align-items-center  justify-content-start">
            <Col md={6} sm={6} offset={4} className="pr-3 pl-3 mt-3">
              <div>
                <div className="   rounded">
                  <div>
                    <h5 className="font-weight-bold f-w-600 pl-5" > Create Interview </h5>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} sm={6} offset={4} className="pr-3 pl-3 mt-3">
              <div>
                <div className="d-flex flex-grow-1 align-items-center justify-content-end rounded mt-1">
                  <div className="text-muted d-block">
                    <Link to="#" onClick={showPreviewPage} type="button" 
                    className={`btn btn-theme-green btn-lg mr-5 mt-2 mb-0 ${testQuestions.length==0 ? "p-none" : ""}`}

                    
                    >
                      Preview Interview
                    </Link>
                    {/* <Link to="/preview-test"  onClick={Create_Test}  type="button" className="btn btn-theme-green btn-lg mr-5 mt-2 mb-0"  >
              Preview Test
           </Link> */}
                    <Button type="button" className="btn btn-theme-green btn-lg mr-5 mt-2 mb-0"
                    
                    className={`btn btn-theme-green btn-lg mr-5 mt-2 mb-0 ${formData.questions.length==0 ? "p-none" : ""}`}

                    onClick={addQuestionsToTest}  
                      >
                      Add Question
                    </Button>
                    <Link to="/manage-test" type="button" className="btn btn-theme text-white btn-lg mr-5 mt-2 mb-0"  >
                      Cancel
                </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5"></Col>
          </Row>
          <Col className="Content-custom-padding mt-5">
            <Row >
              <Form.Label className="fw-600" column sm="2">
                Interview Name*
                    </Form.Label>
              <Col sm="7" className="ml-0 pl-0" >
                <Form.Group as={Col} controlId="formGridState">
                  <Form.Control type="text"
                    name="interviewTitle"
                    value={formData.interviewTitle}
                    onChange={(e) => settestName(e)}

                  >
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row as={Row} controlId="formaBankType"
              style={{ "display": "flex", "alignItems": "baseline" }}>
              <Form.Label className="fw-600" column sm="2">
                Select Interview Question
                    </Form.Label>
              <Col sm="5">
                <Form.Group as={Col} controlId="formGridState" >
                  {['radio'].map((type) => (
                    <div key={`inline-${type}`} className="mb-3 ml-0" style={{ "display": "flex", "justifyContent": "space-between", "width": "100%" }}>
                      <Form.Check inline label="Private Question Bank" className="pr-3-inp" name="bankType" value="private" type={type} id={`inline-${type}-1`} 
                       onChange={() => handleOptionChange("private")}
                       checked={ bankType === "private" ? true : false}
                      />
                      <Form.Check inline label="Public Question Bank" className="pr-3-inp" name="bankType" value="public" type={type} id={`inline-${type}-2`} 
                       onChange={() => handleOptionChange("public")}
                       checked={ bankType === "public" ? true : false}
                      />

                    </div>
                  ))}
                </Form.Group>
              </Col>
            </Row>



            <Row className="align-items-center mt-0">


              <Col sm={4} md={3} className="mb-2">
                <Form.Control as="select" placeholder="Status" value={categorySelected} onChange={(e) => handleCategorySelect(e)} >
                  <option value="">Parent Category</option>
                  {parentCategories.map
                    (x => <option value={x.id}>{x.categoryName}</option>)
                  }
                </Form.Control>
              </Col>

              <Col sm={4} md={3} className="mb-2">
                <Form.Control as="select" placeholder="-Sub Category-" value={subcategorySelected} onChange={(e) => handleSubCategorySelect(e)} >
                  <option value="">Sub Category</option>
                  {subCategories.map
                    (x => <option value={x.id}>{x.subCategoryName}</option>)
                  }
                </Form.Control>
              </Col>
     

              <Col sm={4} md={4} className="d-flex" className="mb-2">
                <div style={{ "position": "relative" }}>
                  <div className="input-group">
                    <Form.Control type="text" value={searchTerm ? searchTerm : ""} placeholder="Search..." id="search-text" onInput={(e) => SetSearchTermData(e)} style={{ "paddingLeft": "35px", }} />
                    <div className="input-group-prepend">
                    </div>
                  </div>
                  <i className="fa fa-search" style={{ "position": "absolute", "left": "7px", "top": "50%", "transform": "translate(7px, -50%)", "borderRadius": "8px" }} ></i>
                </div>
              </Col>



              <Col sm={4} md={2}  className="mb-2 text-right">
                <Button type="button" className="btn btn-search ml-5 mb-sm-2" id="filter-btn" onClick={get_Question_List} style={{ "borderRadius": "8px" }} >
                  Apply
              </Button>

              <Button type="button" className="btn btn-reset ml-5   mb-sm-2 btn btn-primary" id="filter-btn"  style={{ "borderRadius": "8px" }} >
                  Reset              </Button>
              </Col>

            </Row>
            <div>
            {QuestionsList &&  QuestionsList.length > 20 &&  QuestionsList.length > 0  && (
              <BootstrapTable className="bg-white" keyField='id' data={QuestionsList} columns={columns} options={options} pagination={paginationFactory(options)} rowEvents={actionControls} />
            )}
           {QuestionsList &&  QuestionsList.length === 0  && (
             <>
              <BootstrapTable className="bg-white" keyField='id' data={QuestionsList} columns={columns} options={options}  rowEvents={actionControls} />

             <p className="text-center mt-5 pt-5" style={{"fontSize":"30px","minHeight":"200px","display":"flex","justifyContent":"center","alignItems":"center"}}> 
                <small>
                    Please select the above filters {`&`} 
                    <br/>
                    click on search to get the questions.
                </small>
             </p>
              </>
           )}
            {QuestionsList  && QuestionsList.length <= 20 && QuestionsList.length > 0 &&
            (
              <BootstrapTable className="bg-white" keyField='id' data={QuestionsList} columns={columns} options={options}  rowEvents={actionControls} />
            )
            }
          </div>
          </Col>
        </Col>
      ) : ""}
      {/*End addQuestionSection */}







      {/*Start previewTestSection */}
      { showpage.showPreviewTestPage ? (
        <Col className={classes.root} >
          <Row className="bg-white  d-flex flex-grow-1 align-items-center  justify-content-start">
            <Col md={6} sm={6} offset={4} className="pr-3 pl-3 mt-3">
              <div>
                <div className="   rounded">
                  <div>
                    <h5 className="font-weight-bold f-w-600 pl-5" > Create Interview &nbsp;&nbsp; {'>'} &nbsp;&nbsp; Preview Interview  </h5>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} sm={6} offset={4} className="pr-3 pl-3 mt-3">
              <div>
                <div className="d-flex flex-grow-1 align-items-center justify-content-end rounded mt-1">
                  <div className="text-muted d-block">
                    <Button onClick={Create_Test} type="button" className="btn btn-theme-green btn-lg mr-5 mt-2 mb-0"  >
                      Save
                    </Button>
                    <Button  onClick={(e) => save_share_test(e)} type="button" className="btn btn-theme-green btn-lg mr-5 mt-2 mb-0"  >
                      Save & Share
                    </Button>
                    <Link to="#" onClick={showCreatePage} type="button" className="btn btn-theme text-white btn-lg mr-5 mt-2 mb-0"  >
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5"></Col>
          </Row>
          <Col className="Content-custom-padding mt-5 mb-0 mb-sm-5">
            <Row >
              <Form.Label className="fw-600" column sm="2">
                 Interview Name
                </Form.Label>
              <Col sm="7" className="ml-0 pl-0" >
                <Form.Group as={Col} controlId="formGridState">
                  <Form.Control type="text"
                    name="testTitle"
                    value={formData.interviewTitle}
                    onChange={(e) => settestName(e)}
                  >
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col className="text-right mt-2">
                <Link to="#" onClick={showCreatePage} className="c-p d-inline text-theme-green"><img src="/media/VRekruitIcons/addmore.svg" className="c-p mr-3" width="24" />Add More Question</Link>
              </Col>
            </Row>

            <div>
            {testQuestions &&  testQuestions.length > 20 &&  testQuestions.length > 0  && (
              <BootstrapTable className="bg-white" keyField='id' data={testQuestions} columns={columns} options={options} pagination={paginationFactory(options)} rowEvents={actionControls} />
            )}
           {testQuestions &&  testQuestions.length === 0  && (
             <>
              <BootstrapTable className="bg-white" keyField='id' data={testQuestions} columns={columns} options={options}  rowEvents={actionControls} />
             <p className="text-center mt-5 pt-5" style={{"fontSize":"30px","minHeight":"200px","display":"flex","justifyContent":"center","alignItems":"center"}}>
                No Records Found
             </p>
              </>
           )}
            {testQuestions  && testQuestions.length <= 20 && testQuestions.length > 0 &&
            (
              <BootstrapTable className="bg-white" keyField='id' data={testQuestions} columns={columns} options={options}  rowEvents={actionControls} />
            )
            }
          </div>
          </Col>
        </Col>
      ) : ""}

      {/*End previewTestSection */}

      <ShowQuestionAnswerModal
  show={modalShow}
  onHide={() => setModalShow(false)}
/>

    </>
  );
}

export default Interview