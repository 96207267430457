/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormFile from 'react-bootstrap/FormFile'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Table from 'react-bootstrap/Table';
import Helmet from 'react-helmet'
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import AsyncSelect from 'react-select/async';
import { useParams} from 'react-router-dom';
import alertService from '../../_services/alertservice';
import { useHistory,Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { logout } from '../../../actions/auth';
import axios from 'axios';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';




const animatedComponents = makeAnimated();
  

const initialValues = {
  position: "",
  jobDescription: "",
  file: "",
  fileName:"",  
  assignedTo:[],
  requisitionId:"",
  private:"",
  status:""
};




function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}




TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));


  function EditRequisitionByID() {  
    const [ requistions, setUsersList ] = useState([]); 
    const [ UserById, setUsersbyId ] = useState([]); 
    const [ colourOptions,  setColourOptions]=  useState([]);
    const [ AssignedDefaultOptions,  setDefaultAssignedOptions]=  useState([]);
    const [name, setName] = useState([]);
    const [idList, setIdList] = useState("");
    const [renderPage, setRenderPage]= useState(false);
    const [privateflag, Setprivateflag] = useState(false)
    const [jobDescription,setJobDescription] = useState()
    const [validate,setValidate] = useState(false)


    const [editorState, setEditorState] = useState(
      () => EditorState.createEmpty(),
    );

    const onEditorStateChange = (editorState) => {
      setEditorState(editorState)
      setJobDescription(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    }

  const Setprivateflagstatus = () => {

     if(privateflag){
      Setprivateflag(false)
     }
     else{
      Setprivateflag(true)
     }
  } 

   const  AssignedOptions = (req) => {
      if(req && req.length) {
        var options = [];
        req.forEach(element => {
          options.push(
            { value: element.id, label: element.firstName + " " + element.lastName }
          )
        });
        setDefaultAssignedOptions(options);
        var list = options.map(x=> x.value).join(",");
        setIdList(list);
      }
    }

    const history = useHistory();
    const [loading,SetLoading]=useState(false);

    const edit_requisition = (values) =>{
      setValidate(true);
      if(!jobDescription){
        return false
      }
      SetLoading(true);
      const reqData = {
        position: values.position ,
        jobDescription: jobDescription,
        file: pdFName,
        fileName: pdFileName,
        status: values.status,
        assignedTo:idList,
        id:UserById.id,
        private: privateflag
        }
      const token = localStorage.getItem('x-access-token');  
      fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/update_requisition`, {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          'x-access-token': token
        },
        body: JSON.stringify(reqData)
      })
      .then((res) => res.json())
      .then((res) => {
        if(res.status==true){
        // disableLoading();
        SetLoading(false);
        alertService.alert({ message: res.message, type : 'success'  });
        history.push('/view-requistions/'+ id ) ;
        }
        else if(res.sessionExpired){
          logoutMethod();
          SetLoading(false);
          alertService.alert({ message :res.message, type : 'error'  });
        }
        else{
          alertService.alert({ message: res.message, type : 'error'  });
          SetLoading(false);
        }
      })
      .catch(() => {
        SetLoading(false);
      });
      }
      
      const dispatch = useDispatch();
      const logoutMethod = () => {
        history.push('/auth/signin');
        dispatch(logout());
    };      
    const setSt = (req) => {
      if(req && req.length) {
        var options = [];
        req.forEach(element => {
          options.push(
            { value: element.id, label: element.firstName + " " + element.lastName }
          )
        });
        setColourOptions(options);
      }
    }
     function handleMultipeInput(value)  {
    //     e.preventDefault();        
        setName(value);
        setIdListItem(value);   
    };
    const setIdListItem = (name) =>{
      if(name) {
        var namelist = [];
        name.forEach(element => {
          namelist.push(element.value)
        });
        const list = namelist.join(", ");
        setIdList(list);
      }
    }
    const getrequisitionbyid = () =>{
      const token = localStorage.getItem('x-access-token');
      fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/get_requisition?id=`+id, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'x-access-token': token
        },
      })
      .then((res) => res.json())
      .then((res) => {
        if(res.status==true){
          setUsersbyId(res.data);

          initialValues.position = res.data.position;
          initialValues.requisitionId = res.data.requisitionId;
          initialValues.status = res.data.status;
          initialValues.private = res.data.private;
          initialValues.jobDescription = res.data.jobDescription;
          setJobDescription(res.data.jobDescription)
          const contentBlock = htmlToDraft(res.data.jobDescription);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState)
          }
          SetName(res.data.file);
          SetSeletedFileName(res.data.fileName);
          // initialValues.assignedTo = res.data.assignedTo;
          Setprivateflag(res.data.private);
          AssignedOptions(res.data.assignedToUsers)
          formik.resetForm();
          setRenderPage(true)
          // setSt(res.data);
        // disableLoading();
        // alert(res.message);
        // setSubmitting(false);
    
        }
        else{
          // alert(res.message);
          // disableLoading();
          // setSubmitting(false);
        }
      })
      .catch(() => {
        // disableLoading();
        // setSubmitting(false);
      });
      }

      function SetFormic(){
        formik = useFormik({
          initialValues,
          enableReinitialize:true,
          validationSchema: AddNewRequistionSchema,  
          validator: () => {},    
            onSubmit: (values) => {
            // enableLoading();
            edit_requisition(values);
            // setDefaultAssignedOptions();
          },
        }); 
      }
    
    
    const getuserslist = () =>{
      const token = localStorage.getItem('x-access-token');
      fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/users_list?removeMe=true`, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'x-access-token': token
        },
      })
      .then((res) => res.json())
      .then((res) => {
        if(res.status==true){
          setUsersList(res.data);
          setSt(res.data);
        }
        else{
         
        }
      })
      .catch(() => {
       
      });
      }

    useEffect(() => {
      let mounted = true;
      getuserslist();
      getrequisitionbyid();

      return () => mounted = false;
    }, [])

    const AddNewRequistionSchema = Yup.object().shape({
      position: Yup.string()
      .required('Required'),
      jobDescription: Yup.string()
      .required('Required'),
      // file: Yup.string()
      // .required('Required'),
      assignedTo: Yup.array()
      .required('Required'),
  });


    const getInputClasses = (fieldname) => {
      if (formik.touched[fieldname] && formik.errors[fieldname]) {
        return "is-invalid";
      }
      if (formik.touched[fieldname] && !formik.errors[fieldname]) {
        return "is-valid";
      }
      return "";
    };



    var formik = useFormik({
      initialValues,
      enableReinitialize:true,
      validationSchema: AddNewRequistionSchema,  
      validator: () => {},    
        onSubmit: (values) => {
        // enableLoading();
        edit_requisition(values);
      },
    }); 


  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
   }

  function handleChangeIndex(index) {
    setValue(index);
  }


  const reqsave = () => {
    let savebtn = document.getElementById("req-save-btn");
    savebtn.click(); 
  }


  let { id } = useParams();
  const [pdFileName, SetSeletedFileName] = useState("");
  const [pdFName, SetName] = useState("");
  const [hasfile,Sethasfile] = useState(false);
  

  const uploadFile = (ev) => {
    let file = ev.target.files[0];
    if(file){
      var bytes = ev.target.files[0].size;
      var Mbytes  =    bytes / (1024*1024);
    }

    if (file && Mbytes <= 20 ) {      
      // Split the filename to get the name and type
      let fileParts = ev.target.files[0].name.split('.');
      let fileType = fileParts.slice(-1).pop()
      if(fileType){
        fileType = fileType.toLowerCase()
      }
      if( fileType === "doc" || fileType ===  "docx" || fileType ===  "pdf"){
        var fileName;
        axios.post(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/upload_file`, {
          fileName: fileParts[0] + "." + fileParts[1],
          fileType: "application/"+fileType,
          fileFolder:  "file"
        },
          {
            headers: {
              'x-access-token': localStorage.getItem('x-access-token'),
            }
          })
          .then(response => {            
            if(response.data.sessionExpired){
              logoutMethod();
              alertService.alert({ message :response.data.message, type : 'error'  });
            }
            else if(response.status){

            var returnData = response.data.data.returnData;
            var signedRequest = returnData.signedRequest;
            var url = returnData.url;
            if (url) {
              SetName(returnData.url);
              SetSeletedFileName(fileParts[0] + "." + fileType);
              Sethasfile(true);
            }
            var options = {
              headers: {
                'Content-Type': 'application/'+ fileType,
                'Content-Disposition':"inline",              
              }
            };
            axios.put(signedRequest, file, options)
              .then(result => {
              })
              .catch(error => {
                alert("ERROR " + JSON.stringify(error));
              })
            }
              
          })
          .catch(error => {
            alert(JSON.stringify(error));
          })
  

      }
      else {
        alertService.alert({ message: "File Format is Not Allowed", type: 'error' });
      }
    }
    else if(file && Mbytes > 20 ) {
      alertService.alert({ message: "Max Allowed File Size is 20MB", type: 'error' });
    }
  }

  const triggerClick = () => {
    let ClickItem = document.getElementById("fileUpload-inp");
     if(ClickItem){
       ClickItem.click();
     }
   } 
   



  
  return (
<>
<Helmet>
  <title> View Requistion </title>
</Helmet>
  {/* subeader */}
  <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
      <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <div className="d-flex align-items-center flex-wrap mr-1">
          <div className="d-flex align-items-baseline mr-5">
            <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Requisition Management</h3></div>
            </div>
          <div className="d-flex align-items-center" />
        </div>
    </div>
    {/* subeader */}

  
<Col className={classes.root} >

    <Row className="bg-white mb-0 pb-0 pt-5 sticky-header"> 
    <Col md={6} sm={12} offset= {4}  className="pr-3 pl-3">      
      <div>
       <div className="d-flex flex-grow-1 align-items-center  justify-content-start   rounded">     
        <div>
           <h5 className="font-weight-bold f-w-600 pl-3 mt-3" > Edit Requisition </h5>

        </div>
      </div>
      </div>
      </Col>
      <Col md={6} sm={12} offset= {4}  className="pr-3 pl-3 text-right">
      <div>
       <div className="d-flex flex-grow-1 align-items-center justify-content-end    rounded">
        <div className="text-muted d-block mt-1">
           <button type="submit" className="btn btn-theme-green btn-lg mr-5 mb-sm-5"  onClick={reqsave} 
           disabled={loading}
           >
              Save
              {loading && <span className="ml-3 spinner spinner-white"></span>}
           </button>
           <Link to={"/view-requistions/"+ id} type="button" className="btn btn-theme btn-lg mr-5 mb-sm-5 text-white">
              Cancel
           </Link>
      </div>
      </div>
      </div>
      </Col>
      <Col md={12} sm={12} className="separator separator-solid mb-0"></Col>  
  </Row>  
  

<>

      { renderPage && (
      <Form onSubmit={formik.handleSubmit} className="Content-custom-padding pt-10">
               <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Requisition Id*
          </Form.Label>
            <Col sm={10} md={7}>
                  <Form.Control type="text" placeholder=""  className={`form-control form-control-solid h-auto py-5 px-6 no-drop`} {...formik.getFieldProps("requisitionId")} readOnly/>
            </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Job Title*
          </Form.Label>
            <Col sm={10} md={7}>
                  <Form.Control type="text" placeholder=""  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("position")}`} {...formik.getFieldProps("position")}/>
                  {formik.touched.position && formik.errors.position ? 
                  (
                  <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.position}</div>
                    </div>
                  ) 
                  : null}
            </Col>
        </Form.Group>

        <Form.Group as={Row}>
         <Form.Label column sm={2}> Job Description*</Form.Label>
           <Col sm={10} md={7}>
          <Editor
            defaultEditorState={editorState}
            //onChange={setEditorState}
            onEditorStateChange={onEditorStateChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
         /> 
          {validate && !jobDescription ? 
          (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">Required</div>
            </div>
          ) 
          : null}
            </Col>
          </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Attach File
          </Form.Label>
          <Col sm={10} md={7}>
          <div className="upload-container"  style={{"position":"relative"}}>  
            <div className="upload-btn-wrapper" >
           
          <Link to="#"  className="btn btn-file upload-btn" type="btn"  onClick={triggerClick}>Choose File </Link>
              <input type="file"  className="d-none" name="file" 
                // {...formik.getFieldProps("file")}
                accept = ".pdf,.doc,.docx"
                onChange={(e) => uploadFile(e)}  
                id="fileUpload-inp"
              />



            </div>
             <span className="text-dark c-p" style={{"position": "absolute", "top":"50%","transform": "translate(5px, -50%)" }}> { pdFileName ? pdFileName : "No File Selected" } </span>
          </div>
          <div>
          <span className="text-muted">
            <small className="fw-600" > Note: File Type PDF/DOC</small>
          </span> 
     
          </div> 
          </Col>          
        </Form.Group>







        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Assign Requisition  
          </Form.Label>
          <Col sm={10} md={7}>
          
           {/* </Form.Control>   */}
          <Select closeMenuOnSelect={false} components={animatedComponents} defaultValue={AssignedDefaultOptions} isMulti options={colourOptions}
           onChange={handleMultipeInput}/>
          {formik.touched.assignedTo && formik.errors.assignedTo ? 
          (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.assignedTo}</div>
            </div>
          ) 
          : null}  
          </Col>
        </Form.Group>


          <Form.Group as={Row}>
            <Form.Label column sm={2}>
              Status
            </Form.Label>
            <Col sm={10} md={7}>
            <Form.Control as="select" 
            {...formik.getFieldProps("status")}>
              <option value=""  disabled> 
              -status-
              {/* {UserById.status}   */}
              </option>
              <option value="open" >Open</option>
              <option value="hold" >Hold</option>
              <option value="close">Close</option>
            </Form.Control>
            {formik.touched.status && formik.errors.status ? 
          (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.status}</div>
            </div>
          ) 
          : null}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="pt-3">
            <Form.Label column sm={2} className="mt-0 pt-0" >
              Mark as Private
            </Form.Label>
            <Col sm={10} md={7}>
                <Form.Check  className="mt-0 pt-0" aria-label="option 1" size="lg" 
                {...formik.getFieldProps("private")}
                checked={privateflag}
                  onChange ={Setprivateflagstatus}
                />
            </Col>
            </Form.Group>
            
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">         
              <Button id="req-save-btn" type="submit" className={`btn btn-primary btn-theme font-weight-bold px-9 py-4 my-3 d-none`}>
                <span>Save </span>
                <span className="ml-3  spinner-white"></span>
              </Button>            
            </div>  
        </Form>
     ) }


</>
    </Col>
</>
  );
}

export default EditRequisitionByID 