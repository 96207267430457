/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React from "react";
import {Link, Switch, Redirect} from "react-router-dom";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {ContentRoute} from "../../../../_metronic/layout"
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import Helmet from "react-helmet"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Collapsible from 'react-collapsible';
import LoginAside from "./LoginAside"
import { ExpandMore } from '@material-ui/icons';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import Header from './Header'
import Footer from './Footer'



export default function PrivacyPage() {

  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };


  return (

  <>
      
     <Helmet>
         <title> VRekruit | Homepage</title>
     </Helmet>


<Header/>

<section id="cs1"  style={{"backgroundColor":"#003577"}}>
<Container className="pt-5 mt-20 mb-0 text-center mr-auto" >
<Row className="d-flex space-around">
   <Col md={12} sm={12}>
     <div className="mr-auto text-white fs-30 mb-20">
       <h2 className="text-white text-center fs2rem">
          VRekruit Privacy Policy
       </h2>
     </div>
    </Col>
    </Row>
</Container>
</section>




<section className="bg-white">
<span className="Privacy-Policy-At-Website-Name-accessible-at-Websitecom-one-of-our-main-priorities-is-the-privacy-of-our-visitors-This-Privacy-Policy-document-contains-types-of-information-that-is-collected-and-recorded-by-Website-Name-and-how-we-use-it-If-you-have">
  <h1 className=" pl-5 pt-5 pb-5text-style-1">
      VRekruit Privacy Policy
    </h1>
<p className="pl-5 pt-5">
At VRekruit, we are committed to protecting your privacy and support a general policy of openness about how we collect, use and disclose your personal information. Our practice as regards use of your Personal Information is as set forth below in this Privacy Policy Statement. As a condition to use of VRekruit Services, you consent to the terms of the Privacy Policy Statement as it may be updated from time to time. This Privacy Policy Statement applies exclusively to www.VRekruit.com.  </p>

 <p className="pl-5 pt-5">
 This Privacy Statement only covers information collected at this Web Site, and does not cover any information collected at any other web site or offline by VRekruit, our affiliates or any other company (unless specifically stated).  </p>



  
  
  <h1 className=" pl-5 pt-5 pb-5text-style-2">INFORMATION COLLECTED</h1>
  <p className="pl-5 pt-5">
       We collect two kinds of information from you: non-personal information and personal information. Personal information is information we collect through this Web Site that we can use to specifically identify you, and may include your:
    </p>

 
  <ul className="pl-5 ml-5">
  <li>name, telephone number, date of birth, designation and e-mail address</li>
  <li>credit card or similar information</li>
  <li>	past employment history</li>
   <li> information you provide in online tests, including responses to test questions </li>
   <li> record videos of yourself asking questions to potential candidates you will interview. When you do, you will provide personal information, including but not limited to your name and company that may be included in the video. </li>
  <li> Record videos of yourself answering questions including your achievements & accomplishments.</li>

</ul>   
  <p className="pl-5 pt-5">
     By voluntarily providing us with personal information, you are consenting to our use of it in accordance with this Privacy Statement. If you provide personal information, you acknowledge and agree that such personal information may be transferred from your current location to the offices and servers of VRekruit and the authorized third parties referred to herein located in India and overseas.  
   </p>

   
  <p className="pl-5 pt-5">
     Non-personal information is information we collect through this Web Site that does not identify you by name or as an individual person. It may include information like: 
   </p>
  
  <p className="pl-5 pt-5">
  When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
  </p>

  <ul className="pl-5 ml-5">
  <li>
     the type of web browser software your computer uses;
  </li>
  <li>
  pages your computer visited and date and time your computer accessed our pages;
  </li>
  <li>	past employment history</li>
   <li> information you provide in online tests, including responses to test questions </li>
   <li> personal information that has been de-identified; and </li>
  <li> the IP (internet protocol) address associated with your computer.</li>

</ul>  




  
  <h1 className=" pl-5 pt-5 pb-5text-style-4">Cookies: </h1>
  
  <p className="pl-5 pt-5">
  We collect non-personal information from your computer's web browser. A cookie is an information file that the Web Site places on your computer for record keeping and site navigation purposes. The cookie placed on your computer by the Web Site does not store your name, e-mail address or any other personal information about you. Your browser software can be set to reject cookies, including the cookie from our Web Site. However, please note that if you reject our cookies, you will not be able to use this Web Site.
  </p>




<h1 className=" pl-5 pt-5 pb-5text-style-5">USE AND DISCLOSURE OF INFORMATION</h1>

<p className="pl-5 pt-5">
 VRekruit uses the personal information you provide in a manner that is consistent with this Privacy Statement. If you provide personal information for a certain reason, we may use the personal information in connection with the reason for which it was provided. For instance, if you contact us by email, we will use the personal information you provide to answer your question or resolve your problem. Also, if you provide personal information in order to obtain access to VRekruit’s services, we will use your personal information to provide you with access to such services and to monitor your use of such services. VRekruit and its subsidiaries and affiliates may also use your personal information and other personally non-identifiable information collected to help us customize our services or the messages you receive and improve the content and functionality of our services. VRekruit may use this information to communicate with you. If we do so, any marketing communication we send you will contain instructions permitting you to "opt-out" of receiving future marketing communications. In addition, if at any time you wish not to receive any future marketing communications or you wish to have your name deleted from our mailing lists, please contact us as indicated below. Note however that as user of VRekruit’s services you cannot opt-out of some administrative communications that are reasonably necessary, such as billing or service notifications. If VRekruit intends on using any personal information in any manner that is not consistent with this Privacy Statement, you will be informed of such anticipated use prior to or at the time at which the personal information is collected. 
</p>


<h1 className=" pl-5 pt-5 pb-5text-style-5">SHARING PERSONAL INFORMATION WITH THIRD PARTIES</h1>

<p className="pl-5 pt-5">
  We take the privacy of our users very seriously, and except with your consent or as described in this privacy statement we will not sell, rent, or otherwise provide your personally identifiable information to third parties. 
</p>

<p className="pl-5 pt-5">
  VRekruit may share personal information with third parties engaged to assist us in providing services to you or to carry out one or more of the purposes described above. These service providers are prohibited from using your personal information for any purpose other than to provide this assistance and are required to protect personal information collected by them on behalf of VRekruit or disclosed to them by VRekruit and to comply with the general privacy principles described in this Privacy Policy. 
</p>

<p className="pl-5 pt-5">
  VRekruit reserves the right to disclose personal information to a third party if a law, regulation, search warrant, subpoena or court order legally requires or authorizes us to do so. 
</p>

<p className="pl-5 pt-5">
   VRekruit also reserves the right to disclose and/or transfer personal information to a third party in the event of a proposed or actual purchase, sale (including a liquidation, realization, foreclosure or repossession), lease, merger, amalgamation or any other type of acquisition, disposal, transfer, conveyance or financing of all or any portion of VRekruit or of any of the business or assets or shares of VRekruit or a division thereof in order for you to continue to receive the same products and services from the third party. you order from the web site. in such cases those third parties may have access to your personal information in order to provide the services on our behalf.
</p>



<h1 className=" pl-5 pt-5 pb-5text-style-5">LINKS TO THIRD PARTY SITES </h1>

<p className="pl-5 pt-5">
   VRekruit website may offer links to other third party websites. You should be aware that operators of linked websites may also collect your personal information (including information generated through the use of cookies) when you link to their websites. VRekruit is not responsible for how such third 
   parties collect, use or disclose your personal information, so it is important to familiarize yourself with their privacy policies before providing them with your personal information. 
</p>

<h1 className=" pl-5 pt-5 pb-5text-style-5">RETENTION AND YOUR CHOICES AND ABILITY TO ACCESS PERSONAL INFORMATION</h1>

<p className="pl-5 pt-5">
Even if you are no longer registered, we will maintain copies of your information in our internal records, systems, and databases. We will continue to treat your personal information in accordance with this Privacy Statement.
</p>

<p className="pl-5 pt-5">
    VRekruit provides individuals reasonable opportunity to access and update their personal information. You have the right to obtain confirmation from VRekruit that we have retained personal data about you, as well as a written description of the nature of that personal data, the purposes for which it is being used, the sources of the personal data and a list of the recipients with whom we have shared your personal data. You also have the right to request the modification or deletion of any personal information retained by VRekruit that is inaccurate. Please contact us at the address appearing below to inquire about your personal information. VRekruit reserves the right to charge a modest fee for providing you access to your personal information.
</p>




<h1 className=" pl-5 pt-5 pb-5text-style-5">
IMPORTANT INFORMATION REGARDING MINORS 
 </h1>
<p className="pl-5 pt-5">
Children under the age of 16 are not eligible to use our service. VRekruit disclaims any and all responsibility for personal information it may receive from children under the age of 16. 
 
</p>

<h1 className=" pl-5 pt-5 pb-5text-style-5">
CHANGES TO PRIVACY POLICY 
 </h1>
<p className="pl-5 pt-5">
 
VRekruit reserves the right to modify this Privacy Statement at anytime without notice to reflect changes in legal or regulatory obligations or changes in the manner in which we deal with personal information. The Privacy Statement posted at any time or from time to time via this website shall be deemed to be the Privacy Statement then in effect. 
</p>

<h1 className=" pl-5 pt-5 pb-5text-style-5">
HOW TO CONTACT US 
  
 </h1>
<p className="pl-5 pt-5">
Send us an email at admin@vrekruit.com
</p>


</span>
</section>




<section className="bg-info-1 pt-5">
<Container>
  <Row className="mt-20 mb-20 d-flex space-around">
    <Col sm={12} md={4} >
    <img className="img-responsive mb-5 " width="200" src="/media/logos/UPDATED_VRekruit _Logo.png"  />   
    <p className="text-left"> 
    There are many variations of 
    passages of Lorem Ipsum available, 
    <br/>
    but the majority have suffered alteration in some form
    </p>

    </Col>

    <Col sm={12} md={4} >
    <h3 className="text-left pt-3 fw-600"> 
       Contact Info
    </h3>
    {/* <p className="text-left pt-3"> 
    <img className="d-inline mr-3" width="25" alt="" src="/media/VRekruitIcons/address.svg" />

    Building No. 000, Industrial Area,
    <br/>
    <span className="ml-10">   Mumbai- 000000 </span>    
    </p> */}
    <p className="text-left pt-3"> 
    <img className="d-inline mr-3" width="25" alt="" src="/media/VRekruitIcons/email (1).svg" />
       admin@vrekruit.com
    </p>
    {/* <p className="text-left pt-3"> 
    <img className="d-inline mr-3" width="25" alt="" src="/media/VRekruitIcons/call (1).svg" />    
      +91 89968465798
    </p> */}

    </Col>

    <Col sm={12} md={4} >

    <h3 className="text-left pt-3 pb-3 fw-600"> 
      Follow us On
    </h3>


    <img className="d-inline mr-3 c-p" width="25" alt="" src="/media/VRekruitIcons/facebook-app-symbol.svg" />

    <img className="d-inline mr-3 c-p" width="25" alt="" src="/media/VRekruitIcons/google-plus-logo.svg" />

    <img className="d-inline mr-3 c-p" width="25" alt="" src="/media/VRekruitIcons/linkedin-blue.png" />
   
    <img className="d-inline mr-3 c-p" width="25" alt="" src="/media/VRekruitIcons/twitter-blue.png" />




    <h3 className="text-left pt-3 mt-4 pb-3 text-theme-green"> 
     Download Our Apps 
    </h3>
    <h3>
    <img className="img-responsive mb-5  mt-2 br-10 mr-5" width="150px" src="/media/VRekruitIcons/google.png"  />  
     <img className="img-responsive mb-5  br-10  mt-2" width="150px"  src="/media/VRekruitIcons/apple.png"  />  
    </h3>


    </Col>

  </Row>
</Container>

</section>
<Footer/>

</>           
  );
}
