/* eslint-disable no-restricted-imports */
/* jshint asi:true */

import React, { useState,useEffect } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../modules/Auth/_redux/authRedux"
import Helmet from "react-helmet"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import alertService from "../_services/alertservice";
import { logout } from "../../actions/auth";
import { useDispatch } from 'react-redux';
import { useHistory,Link } from "react-router-dom";




// const { REACT_APP_TEST } = process.env;
// console.log(REACT_APP_TEST)
/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/
/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/



function Profile(props) {
  const { intl } = props;

  useEffect(() => {
    let mounted = true;
    getUserProfile(); 
    return () => mounted = false;
  }, [])

const[ userProfileData,SetUserProfileData]= useState();
   
  const getUserProfile = () =>{
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/user/get_profile`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
    .then((res) => res.json())
    .then((res) => {
      if(res.status==true){
        SetUserProfileData(res.data);
      }
      else if(res.sessionExpired){
        alertService.alert({ message :res.message, type : 'error'  });
        logoutMethod();
      }
      else{
      }
    })
    .catch(() => {
    });
    }
  
    const history = useHistory() 
    const dispatch = useDispatch();
    const logoutMethod = () => {
      dispatch(logout());
      history.push('/auth/signin');
  };
  

  return (
    <>
      <Helmet>
        <title> VRekruit | Profile</title>
      </Helmet>

         {/* subeader */}
         <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Profile</h3></div>
          </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
      {/* subeader */}
   


    <Row className="bg-white pt-4 pb-4">
    <Col md={6} sm={12} offset= {4}  className="pr-3 pl-3">      
      <div>
       <div className="d-flex flex-grow-1 align-items-center  justify-content-start   rounded">     
        <div>
            <h5 className="font-weight-bold f-w-600 pl-5" >My Profile </h5>
            {/* <p className="text-muted mb-0 pl-5"> Update Your Personal Information</p> */}
        </div>
      </div>
      </div>
      </Col>

      {(localStorage.getItem("userTypeId")==="1" || localStorage.getItem("userTypeId")==="2") ?  
      <Col md={6} sm={12} offset= {4}  className="pr-3 pl-3">
      <div>
       <div className="d-flex flex-grow-1 align-items-center justify-content-end    rounded">
        <div className="text-muted d-block mt-1">
           <Link to="/profile-edit" type="button" className="btn btn-theme-green btn-lg mr-5" >
              Edit
           </Link>
      </div>
      </div>
      </div>
      </Col>
     :"" }



      <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5 mb-5"></Col>  

      <div className="container pb-5" style={{"minHeight":"33vh"}}>


      <Col md={12} sm={12} offset={4} >
              <div className="bg--tile rounded">
       

                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline grey-ico-30" width="25" src="/media/VRekruitIcons/role.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark"> Role</h6>
                    <p className="text-dark mt-0">
                      {localStorage.getItem("userType")}
                    </p>
                  </div>
                </div>
                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/avatar.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Name</h6>
                    <p className="text-dark mt-0">
                      { userProfileData && userProfileData.firstName ? userProfileData.firstName  : ""}
                      { userProfileData && userProfileData.lastName ? " "+ userProfileData.lastName  : ""}

                    </p>
                  </div>
                </div>

                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4" pl-4 >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/email (1).svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Email Address</h6>
                    {/* <p className="text-dark mt-0">{UserById.private ? "Yes" : "No"}</p> */}
                    { userProfileData && userProfileData.email ? userProfileData.email  : ""}

                  </div>
                </div>


                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4" pl-4 >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/call (1).svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Phone</h6>
                    { userProfileData && userProfileData.dialCode ? userProfileData.dialCode  : ""}
                    { userProfileData && userProfileData.phone ? " "+ userProfileData.phone  : ""}


                  </div>
                </div>


              </div>

              
            </Col>


           
            </div> 
      </Row>

      </>
      
  );
}

export default injectIntl(connect(null, auth.actions)(Profile));
