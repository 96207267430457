/* eslint-disable no-restricted-imports */
/* jshint asi:true */

import React, { useEffect, useState,useRef } from 'react';
import Helmet from "react-helmet"
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormFile from 'react-bootstrap/FormFile'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Table from 'react-bootstrap/Table'
import { Button } from 'react-bootstrap';
import { useParams, Link, useHistory } from "react-router-dom";
import Select from 'react-select';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
// import TextField from '@material-ui/core/TextField';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import * as XLSX from 'xlsx';
import Modal from 'react-bootstrap/Modal'
import alertService from '../../../_services/alertservice';
import ReactPlayer from "react-player";


import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch } from 'react-redux';
import { logout } from '../../../../actions/auth';
import { createFormattedDateTimePartsComponent } from 'react-intl/src/components/createFormattedComponent';
import DateFnsUtils from "@date-io/date-fns"; // import
import {MuiPickersUtilsProvider } from "@material-ui/pickers";
// import { DateTimePicker } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";






const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });



const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));




const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total">
    Showing {from} to {to} of {size} Results
  </span>
);



const selectRow = {
  mode: 'radio',
  clickToSelect: true
};



function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};


function ShareInterview() {

  useEffect(() => {
    let mounted = true;
    get_Job_Requestion_List();
    get_Company_Details();
    return () => mounted = false;
  }, [])


  const [jobRequesitonList, SetJobRequesitonList] = useState();
  const [pdFileName, SetSeletedFileName] = useState("");
  const [hasfile,Sethasfile] = useState(false);
  const [candidateListUpdated,setCandidateListUpdated] = useState(false);
  

 


  // csv upload code start
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  
  const dispatch = useDispatch();
  const logoutMethod = () => {
    dispatch(logout());
    history.push('/auth/signin');
};

const ref = useRef();


  const history = useHistory();

  // process CSV data
  const processData = dataString => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/); 
  
    var list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"')
              d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"')
              d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }
 
        // remove the blank rows
        if (Object.values(obj).filter(x => x).length > 0) {
          list.push(obj);
        }
      }
    }
 
    // prepare columns list from headers
    const columns = headers.map(c => ({
      name: c,
      selector: c,
    }));

  list = list.map(x=> {
    
   return { email : x["Email Address"], firstName : x["First Name"], lastName: x["Last Name"], dialCode: x["Dial Code"], phone: x["Phone Number"] }
  });
  var errmsg= ""
  var phoneList = list.map(x => x.phone);
  var emailList = list.map(x => x.email);
  if (hasDuplicates(phoneList)) {
    errmsg += "Duplicate phone numbers are present in list. ";
  }
  if (hasDuplicates(emailList)) {
    errmsg += "Duplicate emails are present in list. ";
  }


  var invalidDialCode = list.some(x => (x.dialCode !== "+91" &&  x.dialCode !=="91"));
  var invalidPhone = list.some(x => x.phone.length != 10 );


  if(invalidDialCode){
    errmsg += "Country code other than +91 is not supported.";
  }
  


  if(errmsg) {
    var ele = document.getElementById("fileUpload-inp");
    var ele1 = document.getElementById("fileUpload-inp1");
    if(ele){
      ele.value = "";
    }
    if(ele1){
      ele1.value = "";
    }


    SetSeletedFileName("");
    alertService.alert({ message: errmsg, type: 'error' });
    return;
  }

  var emailRegex =  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  list.forEach((x, i) => {
    var row = i + 1;
    if(!x.firstName) {
      errmsg = "Required " + "First Name at Row " + i+1 + ", "; 
    }
    if(!x.lastName) {
      errmsg = "Required " + "Last Name at Row " + i+1 + ", "; 
    }
    if(!x.email) {
      errmsg = "Required " + "Email at Row " + i+1 + ", "; 
    }
    if(x.email){
      if(!emailRegex.test(x.email))   {
        errmsg = "Invalid " + "Email Address at Row "+ i+ ", "; 
      }
    }
    if(!x.phone) {
      errmsg = "Required " + "Email Phone at Row " + i + ", "; 
    }
    if(x.phone){
      if(x.phone.length !== 10  ) {
        errmsg = "Invalid " + "Phone Number must be 10 digits" + i+ ", "; 
      }
    }
  });
  
  list.unshift({
    email: "",
    firstName: "",
    lastName: "",
    dialCode: "+91",
    phone: ""
  });
  if(!errmsg) {
    SetFormData({
      ...formData, users : list
    });
    Sethasfile(true);
  }
  else{
    alertService.alert({ message: errmsg, type: 'warning' });
    ref.current.value = "";
    Sethasfile(false);
    SetSeletedFileName("");

  }   
    setColumns(columns);
  }

  function hasDuplicates(array) {
    return (new Set(array)).size !== array.length;
}

 const uploadFile = (e) => {
   if(e.target.files.length> 0) {
  // alert('Selected file: ' + x.target.files[0].name);
  SetSeletedFileName(e.target.files[0].name);
  // if(e.target.files[0].name){
  //   Sethasfile(true);
  // }
  // else{
  //   Sethasfile(false)
  // }
  // formik.values.file =  x.target.files[0].name;
  const file = e.target.files[0];
  const reader = new FileReader();
  reader.onload = (evt) => {
    /* Parse data */
    const bstr = evt.target.result;
    const wb = XLSX.read(bstr, { type: 'binary' });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
    processData(data);
  };
  reader.readAsBinaryString(file);

   }
   else {


    SetSeletedFileName("");
   }

 }


 const triggerClickagain = () => {
  if(formData.users.length > 1   ) {
    setModalShow(true);

  }
  else {
    confirmUpload();
  }
 

 
 } 

 const confirmUpload = () => {
  let ClickItem = document.getElementById("fileUpload-inp1");
  if(ClickItem){
    ClickItem.click();
    setModalShow(false);
    setConfirmattion(false);
  }
}

const declineUpload = () => {
  setModalShow(false);
}


const [ introVideoList, SetIntroVideoList]= useState([]);
const [ thankVideoList, SetThankVideoList]= useState([]);
const [prevRequisitionId,setPrevRequisitionId]= useState();
const [prevRound,setPrevRound]= useState();


const [ introVideoNameList, SetIntroVideoNameList]= useState([]);
const [ thankVideoNameList, SetThankVideoNameList]= useState([]);

//get Company details 
const get_Company_Details = () => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
            SetIntroVideoList(res.data.companyIntroVideos.split(","));
            SetIntroVideoNameList(res.data.companyIntroVideoNames.split(","))            
            SetThankVideoNameList(res.data.companyThanksVideoNames.split(","))
            SetThankVideoList(res.data.companyThanksVideos.split(","));
        }else if(res.sessionExpired){
            alertService.alert({ message :res.message, type : 'error'  });
            logoutMethod();
          }
        else {

        }
      })
      .catch(() => {

      });
  }

// csv upload code end
const get_Job_Requestion_List = () => {
    const token = localStorage.getItem('x-access-token');
    var roleId  = localStorage.getItem("userTypeId");
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/company/requisitions?page=1&limit=1000&orderBy=id&orderType=ASC&status=open`;
    if(roleId ==="3" || roleId ==="4" ) {
      url = url + "&assignedToMe=true&createdByMe=true" 
    }

    fetch( url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          SetJobRequesitonList(res.data);

        }else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
        else {

        }
      })
      .catch(() => {

      });
}
 
const [isForm1Submitted ,SetForm1Submitted]= useState(false); 


let { id } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {

    SetForm1Submitted(true);
    if(formData.requisitionId  && formData.interviewRound  && formData.localTime && formData.sendInterview !==""){
      var body = document.body;
    var html = document.documentElement; 
    body.scrollTop = 0
    html.scrollTop = 0
      if(newValue== 1) {
        SetButtonText("Previous");
        setValue(newValue);
        getCandidatesList()
      }
      else {
        SetButtonText("Next");
        setValue(newValue);
  
      }
      }
  }

 const [buttonText, SetButtonText] = useState("Next");
  function handleChangeIndex(index) {
    var body = document.body;
    var html = document.documentElement; 
    body.scrollTop = 0
    html.scrollTop = 0
    setValue(index);
    if(index=== 0) {
      SetButtonText("Next")
    }
    else  {
      SetButtonText("Previous")
      
    }
  }

  const changeButtonText = () => {
    SetForm1Submitted(true);
    if(buttonText=="Next") {
      if(formData.requisitionId  && formData.interviewRound && formData.localTime && formData.sendInterview !==""){
      handleChangeIndex(1);
      SetButtonText("Previous")
      getCandidatesList()
      }
    }
    else {
      handleChangeIndex(0);
      SetButtonText("Next")
    }
  }


const [modalShow, setModalShow] = React.useState(false);
const [confirmation,setConfirmattion] =useState(false);

  function DeleteConfirmationModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            <p className="text-center">
            <img alt="logo" className="brand-dim" src="/media/logos/UPDATED_VRekruit _Logo.png" />
              </p>
            <p className="text-dark text-center pt-3"> 
              If you upload another csv file. Then previous will be removed.
           </p>
          </Modal.Title>
        </Modal.Body>
        <Modal.Title className="d-flex justify-content-center align-items-center pb-5" >
        <p className="text-center">

        <Button className="btn btn-theme text-white btn-lg mr-5  pl-5 pr-5 pt-3 pb-3" onClick={confirmUpload} style={{"minWidth": "100px"}} > Yes </Button>
       <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green" onClick={declineUpload} style={{"minWidth": "100px"}}  >No</Button> 
       </p>
        </Modal.Title>
      </Modal>
    );
  }

  function FormRow() {
    return (
      <React.Fragment>

        <Grid item xs={1}>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}>First Name*</Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}>Last Name*</Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>Phone Number*</Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>Email Id*</Paper>
        </Grid>
      </React.Fragment>
    );
  }

  const handleFormInput = (e, b) => {
    SetFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

 const SetlocalTime = (e) => {
  var date =e._d;
  var dateformatted = GetFormattedDate(e._d);
  if (new Date() < new Date(e._d)) {
  SetFormData({
    ...formData,
    localTime: e._d,
    expiryTime: dateformatted
  })
} 
else {
  alertService.alert({ message :"Invalid Date Time", type : 'error'  });
}
}


const GetFormattedDate = (date) => {
  var cdate = date;
  var dd = cdate.getDate();
  var mm = cdate.getMonth() + 1;
  var yyyy = cdate.getFullYear();
  var hour = cdate.getHours();
  var min = cdate.getMinutes();
  var sec = cdate.getSeconds();
  
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  if (parseInt(hour) < 10) {
    hour = '0' + hour;
  }
  if (parseInt(min) < 10) {
    min = '0' + min;
  }
  if ( parseInt(sec) < 10) {
    sec = '0' + sec;
  }
  return `${yyyy}-${mm}-${dd} ` + `${hour}:${min}:${sec}`
}




const [showCandidateError, SetShowCandidateError] = useState(false);
const [showInvalidEmail, SetInvalidEmailError] = useState(false);
const [duplicatePhone, setDuplicatePhone] = useState(false);
const [duplicateEmail, setDuplicateEmail] = useState(false);
const [candidateListData,SetCandidateListData]=useState([]);

useEffect(() => {
}, [candidateListUpdated,duplicatePhone,duplicateEmail,showCandidateError])

const addNewCandidate = () => {
  var firstCandidate = formData.users[0];
  var isEmailValid = false;
  var emailRegex =  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if(firstCandidate.email){
       if(emailRegex.test(firstCandidate.email))   {
         isEmailValid = true;
       }
  }
    SetInvalidEmailError(!isEmailValid);
    var phoneList = formData.users.map(x => x.phone);
    var emailList = formData.users.map(x => x.email);
    var hasDupEmail = hasDuplicates(emailList);
    var hasDupPhone  =hasDuplicates(phoneList);
      setDuplicatePhone(hasDupPhone);
      setDuplicateEmail(hasDupEmail);
    
  if (firstCandidate.firstName 
    && firstCandidate.lastName
    && firstCandidate.email 
    && firstCandidate.phone
    && firstCandidate.phone.length === 10
    && isEmailValid 
    && !hasDupEmail
    && !hasDupPhone
    ) {
  formData.users.unshift({
    email: "",
    firstName: "",
    lastName: "",
    dialCode: "+91",
    phone: ""
  })
  SetFormData({ ...formData, users : formData.users});
  SetCandidateListData(formData.users);
  SetShowCandidateError(false);
} else {
  SetShowCandidateError(true);
}

}

const onCandidateDetailsChange = (index, propertyName, value) => {
  formData.users[index][propertyName] = value;
  SetFormData({ ...formData, users : formData.users});
  SetCandidateListData(formData.users);

}

const deleteCandidateDetail = (index) => {
  formData.users.splice(index, 1);
  SetFormData({ ...formData, users : formData.users});
  SetCandidateListData(formData.users);

}

const onDateChange = (e) => {
   SetFormData({ ...formData, localTime : formData.localTime});
}

  const [formData, SetFormData] = useState(
    {
      interviewId: id,
      requisitionId: "",
      timeDuration: "",
      passingPercentage: "",
      bankType:"private",
      localTime: "",
      expiryTime:"",
      interviewRound:"",
      sendInterview: "",
      introVideo:"",
      thanksVideo:"",
      // capturePhoto: "false",
      users: [{
        email: "",
        firstName: "",
        lastName: "",
        dialCode: "+91",
        phone: ""
      }]
    }
  )

  const handlePhoneChange = (value, data, event, formattedValue,ind) => {
    if(ind==0)  {  
      formData.users[ind]["dialCode"] = data.dialCode;
      formData.users[ind]["phone"] = value.replace(data.dialCode, "");;
      SetFormData({ ...formData, users : formData.users});
    
    }
  }

  const getCandidatesList = () => {
    const token = localStorage.getItem('x-access-token');
    var oldRequsitionId = prevRequisitionId
    var oldRound = prevRound
    var interviewRound = formData.interviewRound
    var requisitionId = formData.requisitionId

    if(oldRequsitionId == requisitionId && oldRound == interviewRound){

    }else{
      setPrevRequisitionId(requisitionId)
      setPrevRound(interviewRound)
    
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/get_requisition_candidates?requisitionId=${requisitionId}&interviewRound=${interviewRound}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          if(res.data && res.data.length){
            SetFormData({
              ...formData, users : res.data
            });
          } else if(res.data && res.data.length == 0){
            SetFormData({
              ...formData,
              users: [{
                email: "",
                firstName: "",
                lastName: "",
                dialCode: "+91",
                phone: ""
              }]
            })
          }
         
        }else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
        else {
          if (res.errors) {
            var errmsg = "";
            res.errors.forEach(function (value, index, array) {
              errmsg = errmsg + "(" + (index + 1) + ") " + value.msg + "\n\r";

            });
            alertService.alert({ message: errmsg, type: 'error' });
          }

          else {
            alertService.alert({ message: res.message, type: 'error' });
          }
        }
      })
      .catch(() => {
      });
    }
  }
  const  shareInterview = async (e) => {  
    var firstCandidate = formData.users[0];
     if(firstCandidate.firstName != '' || firstCandidate.lastName != '' || firstCandidate.email != '' || firstCandidate.phone != ''){
      var isEmailValid = false;
      var emailRegex =  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(firstCandidate.email){
           if(emailRegex.test(firstCandidate.email))   {
             isEmailValid = true;
           }
      }
        SetInvalidEmailError(!isEmailValid);
        var phoneList = formData.users.map(x => x.phone);
        var emailList = formData.users.map(x => x.email);
        var hasDupEmail = hasDuplicates(emailList);
        var hasDupPhone  =hasDuplicates(phoneList);
        setDuplicatePhone(hasDupPhone);
        setDuplicateEmail(hasDupEmail);
        
      if (firstCandidate.firstName 
        && firstCandidate.lastName
        && firstCandidate.email 
        && firstCandidate.phone
        && firstCandidate.phone.length === 10
        && isEmailValid 
        && !hasDupEmail
        && !hasDupPhone
        ) {      
      SetFormData({ ...formData, users : formData.users});
      SetCandidateListData(formData.users);
      SetShowCandidateError(false);
      setCandidateListUpdated(!candidateListUpdated)
    
      
      
    } else {      
      SetShowCandidateError(true);
      setCandidateListUpdated(!candidateListUpdated)

      return false;
    }       
    }   

   if(formData.users.length > 0 )
  {
    if (new Date() < new Date(formData.localTime)) {      
    } 
    else {
      alertService.alert({ message :"Invalid Date Time", type : 'error'  });
      return false
    }
    e.preventDefault();
    formData.users.map(user => {
      user.dialCode = "+91"
      return user;
    })
    formData.users = formData.users.filter(x=> x.email);
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/share`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(formData)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          alertService.alert({ message: res.message, type: 'info' });
          SetFormData(
            {
                interviewId: id,
                requisitionId: "",
                timeDuration: "",
                passingPercentage: "",
                localTime: "",
                expiryTime: "",
                sendInterview: "",
                introVideo:"",
                thanksVideo:"",
                capturePhoto: "",
                users: [{
                  email: "",
                  firstName: "",
                  lastName: "",
                  dialCode: "+91",
                  phone: ""
                }]
              }
          )
          history.push("/interview/manage-interview")
        }else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
        else {
          if (res.errors) {
            var errmsg = "";
            res.errors.forEach(function (value, index, array) {
              errmsg = errmsg + "(" + (index + 1) + ") " + value.msg + "\n\r";

            });
            alertService.alert({ message: errmsg, type: 'error' });
          }

          else {
            alertService.alert({ message: res.message, type: 'error' });
          }
        }
      })
      .catch(() => {
      });
    }
    else {
      alertService.alert({ message: "Please add candidate details first to share interview", type: 'error' });
    }
  }

  const triggerClick = () => {
    let ClickItem = document.getElementById("fileUpload-inp");
     if(ClickItem){
       ClickItem.click();
     }
   } 


 const  clearAllData = () => {

  SetFormData({
    ...formData,
    users: [{
      email: "",
      firstName: "",
      lastName: "",
      dialCode: "+91",
      phone: ""
    }]
  })
  SetSeletedFileName("");
  var ele = document.getElementById("fileUpload-inp");
  var ele1 = document.getElementById("fileUpload-inp1");
  if(ele){
    ele.value = "";
  }
  if(ele1){
    ele1.value = "";
  }

 }
   

   const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
 const OpenIntroModal = () => {
    get_Company_Details();
    setTimeout(() => {
      SetIntroVideosInModal(true);
      SetThanksVideosInModal(false);
      setOpen(true);
    }, 100);
 }

const [showIntroVideos, SetIntroVideosInModal ] = useState(false);
const [showThanksVideos, SetThanksVideosInModal ] = useState(false);

 const OpenThanksModal = () => {
    get_Company_Details();
    setTimeout(() => {
      SetIntroVideosInModal(false);
      SetThanksVideosInModal(true); 
      setOpen(true);
    }, 100);
 }

  
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);
  const [open, setOpen] = React.useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('md');

function CustomizedDialogs() {  
return (
<div>
<Dialog 
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    fullScreen={fullScreen}
    onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      {/* Temporary Hiddeen */}
  <DialogTitle id="customized-dialog-title" onClose={handleClose}>
  {/* <Row>
            <Form.Label className="fw-600 " column="sm" lg={3}>
              Select Bank*
            </Form.Label>
            <Col md={6}>
              <Form.Group as={Col} controlId="formGridStatetest" size="sm"   value={formData.banktype}>

                {['radio'].map((type) => (
                  <div key={`inline-${type}`} className="mb-3" style={{ "display": "flex", "justifyContent": "space-between", "width": "100%" }}>
                    <Form.Check onChange={(e) => handleFormInput(e)} inline label="Private Bank" className="pr-3-inp" name="bankType" value="private" type={type} id={`inline-${type}-1`}  checked={formData.bankType=== "public" ? true: "false"} />
                    <Form.Check onChange={(e) => handleFormInput(e)} inline label="Public Bank" className="pr-3-inp" name="bankType" value="public" type={type} id={`inline-${type}-2`} checked={formData.bankType=== "private" ? true: "false"} />
                  </div>
                ))}
              </Form.Group>
            </Col>
          </Row> */}
  </DialogTitle>
  <DialogContent dividers className="mt-5 mb-5">
    <Typography gutterBottom >
    {showIntroVideos? (  
   <>   
     { introVideoList.filter(x => x).map((x, ind)  => 
        <Row className="pt-5" >
              <Form.Label className="fw-600 centered text-right" column sm="2">
              <Form.Group as={Col} controlId="formGridStatetest" size="sm" value={formData.introVideo}>
                {['radio'].map((type) => (
                  <div key={`inline-${type}`} className="mb-3" >
                    <Form.Check onChange={(e) => handleFormInput(e)} inline label="" onClick={handleClose} className="pr-3-inp" name="introVideo" value={x} type={type} id={`inline-${type}-1`}  checked={formData.introVideo === x ? true: false}  />
                  </div>
                ))}
              </Form.Group>
              </Form.Label>
              <Col sm="10">
                <Form.Group as={Col} className="mb-0" controlId="formGridStatexr" >
                   <video className="video-container video-container-overlay" style={{"width":"100%","height":"300px"}} autoPlay={false} loop muted={false}
                   controls
                   src={x}
                   preload="metadata"
                   >
                  </video>
                </Form.Group >
                {/* <div className="pl-4 pr-4">
                  <p className="text-center name-border mt-0">
                  {introVideoNameList && introVideoNameList[ind] ? introVideoNameList[ind]:""}
                  </p>
                </div> */}
              </Col>
            </Row> 
       )}
     


     {introVideoList && introVideoList.filter(x=> x).length === 0 ?
       <p className="text-center mt-100 mb-100">
           Please contact Company Admin to add Intro Video      
         </p>
       :""
     }
    </>

    ):
    ""
    }
{showThanksVideos? (  
  <> 
    {thankVideoList.filter(x=> x).map((x,ind ) => 
        <Row className="pt-5" >
              <Form.Label className="fw-600 centered text-right" column sm="2">
              <Form.Group as={Col} controlId="formGridStatetest" size="sm" value={formData.thanksVideo}>
                {['radio'].map((type) => (
                  <div key={`inline-${type}`} className="mb-3" >
                    <Form.Check onChange={(e) => handleFormInput(e)} onClick={handleClose} inline label="" className="pr-3-inp" name="thanksVideo" value={x} type={type} id={`inline-${type}-1`}  checked={formData.thanksVideo === x ? true: false}  />
                  </div>
                ))}
              </Form.Group>
              </Form.Label>
              <Col sm="10">
                <Form.Group as={Col} className="mb-0" controlId="formGridStatexrq" >
                   <video className="video-container video-container-overlay" style={{"width":"100%","height":"300px"}} autoPlay={false} loop muted={false}
                   controls
                   >
                    <source src={x} type="video/mp4" />
                  </video>
                </Form.Group>
                {/* <div className="pl-4 pr-4">
                <p className="text-center name-border mt-0">
                  { thankVideoNameList && thankVideoNameList[ind]  ? thankVideoNameList[ind]:"" }
                  </p>
                </div> */}
              </Col>
            </Row>                               
    )}

    {thankVideoList && thankVideoList.filter(x=> x).length === 0 ?
         <p className="text-center mt-100 mb-100">
           Please contact Company Admin to add Thank You Video      
         </p>
       :""
     }
    </>
    ):""}
    </Typography>
  </DialogContent>
  <DialogActions>
  
  </DialogActions>
</Dialog>
</div>
);
  }

//  const [today,setToday]= useState();
 
//   const today = new Date();
//   var dd = String(today.getDate()).padStart(2, '0');
//   var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
//   var yyyy = today.getFullYear();
//   today = mm + '/' + dd + '/' + yyyy;
//   setToday(today);

const openDateTimePicker = () => {
    let ele = document.getElementById("date-time-picker");
    if(ele){
      ele.click();
    }
}

 if(localStorage.getItem("userTypeId") == "1")
  {
      
     return ""
    //  history.push("/error/error-v1")
  
  }
  else {




  return(
     <>
    <Helmet>
      <title> VRekruit | Share Interview </title>
    </Helmet>

    {/* subeader */}
    <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
      <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <div className="d-flex align-items-center flex-wrap mr-1">
          <div className="d-flex align-items-baseline mr-5">
            <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Interview Management </h3></div>
        </div>
        <div className="d-flex align-items-center" />
      </div>
    </div>
    {/* subeader */}

    <Col className="bg-white sticky-header position-sticky">
      <Row className="bg-white  d-flex flex-grow-1 align-items-center  justify-content-start ">
        <Col md={6} sm={6} offset={4} className="pr-3 pl-3 mt-3">
          <div>
            <div className=" d-flex flex-grow-1 align-items-center  justify-content-start   rounded">
              <div>
                <h5 className="font-weight-bold f-w-600 pl-5" > Share Interview With Candidate  </h5>
              </div>
            </div>
          </div>
        </Col>
        <Col md={6} sm={6} offset={4} className="pr-3 pl-3 mt-3">
          <div>
            <div className="d-flex flex-grow-1 align-items-center justify-content-end rounded mt-1">
              <div className="text-muted d-block">
                <Link to="#" type="button"
                  className={`btn btn-theme-green btn-lg mr-5 mt-2 mb-0`}
                  onClick={changeButtonText}>
                   {buttonText}
                </Link> 

                {value===1 ?   
                  
                <Button onClick={shareInterview} type="button" className="btn btn-theme-green btn-lg mr-5 mt-2 mb-0" id="share-interview-btn"                  
                  >
                  Send Interview
                </Button>
              :""
              }
                <Link to="/interview/manage-interview" type="button" className="btn btn-theme text-white btn-lg mr-5 mt-2 mb-0"  >
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </Col>
        <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5"></Col>
      </Row>
    </Col>

    {/* <switch> */}
    <div className={classes.root} style={{ "backgroundColor": "white", "minHeight": "60vh" }}>
      <AppBar position="" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Basic Detail"   />
          <Tab label="Add Candidate" />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}>
        <TabContainer dir={theme.direction}>
          <Row className="mb-15">
            <Form.Label className="fw-600" column="sm" lg={3}>
              Job Requisition*
            </Form.Label>
            <Col md={6}>
              <Form.Control as="select" name="requisitionId" size="sm" value={formData.requisitionId} onChange={(e) => handleFormInput(e)} >
                <option value=""> Select Job Requisition</option>  
                {jobRequesitonList && jobRequesitonList.map
                  (x => <option value={x.id}>{x.requisitionId}   {x.position}</option>)
                }
              </Form.Control>
                     {isForm1Submitted && !formData.requisitionId ?
                     <div className="fv-plugins-message-container" >
                <div className="fv-help-block">Required</div>
              </div> 
               : null} 
            </Col>
          </Row>

          <Row className="mb-15">
            <Form.Label className="fw-600" column="sm" lg={3}>
              Expiry Time*
            </Form.Label>
            <Col md={6}>
          
              <MuiPickersUtilsProvider utils={MomentUtils}>
                 <KeyboardDateTimePicker
                      value={formData.localTime ?formData.localTime:""}
                      clearable
                      onChange={(e) => SetlocalTime(e)}
                      label=""
                      name="expiryTime"
                      disablePast
                      format="yyyy/MM/DD hh:mm a"
                    /> 
               </MuiPickersUtilsProvider>

              {isForm1Submitted && !formData.expiryTime ?
                     <div className="fv-plugins-message-container" >
                <div className="fv-help-block">Required</div>
              </div> 
               : null} 
            </Col>
          </Row>
          <Row className="mb-15">
            <Form.Label className="fw-600" column="sm" lg={3}>
              Send Interview Result to the Candidate*
            </Form.Label>
            <Col md={2}>
              <Form.Group as={Col} controlId="formGridStatetest" size="sm" value={formData.sendInterview}>
                {['radio'].map((type1) => (
                  <div key={`inline-${type1}`} className="mb-3" style={{ "display": "flex", "justifyContent": "space-between", "width": "100%" }}>
                    <Form.Check onChange={(e) => 
                      handleFormInput(e)} 
                      inline label="Yes" 
                      className="pr-3-inp" 
                      name="sendInterview" 
                      value="true" 
                      type={type1} 
                      id={`inline-${type1}-21`}
                    />
                    <Form.Check 
                    onChange={(e) => handleFormInput(e)} 
                    inline label="No" 
                    className="pr-3-inp" 
                    name="sendInterview" 
                    value="false" 
                    type={type1} 
                    id={`inline-${type1}-22`}  
                  />
                  </div>
                ))}

                {isForm1Submitted && formData.sendInterview ==="" ?
                     <div className="fv-plugins-message-container" >
                <div className="fv-help-block">Required</div>
              </div> 
               : null} 
              </Form.Group>
            </Col>
          </Row>


          <Row className="mb-15">
            <Form.Label className="fw-600" column="sm" lg={3}>
              Interview Round*
            </Form.Label>
            <Col md={6}>
              <Form.Control as="select" name="interviewRound" size="sm" value={formData.interviewRound} onChange={(e) => handleFormInput(e)} >
                <option value=""> Select Interview Round</option>  
                   <option value="One">1st</option>
                   <option value="Two">2nd</option>
                   <option value="Three">3rd</option>
                   <option value="Four">4th</option>
                   <option value="Five">5th</option>

              </Form.Control>
              {isForm1Submitted && !formData.interviewRound ?
                     <div className="fv-plugins-message-container" >
                <div className="fv-help-block">Required</div>
              </div> 
               : null} 
            </Col>
          </Row>


          <Row className="mb-15">
            <Form.Label className="fw-600" column="sm" lg={3}>
              Select Company Welcome Video
            </Form.Label>
            {!formData.introVideo ? 
            <Col md={6}>
            <p className="text-theme-green c-p" onClick={OpenIntroModal} style={{"width":"maxContent"}}>
                <i className="text-theme-green fa fa-plus fa-1x plus-box"></i>
                Select Company Welcome Video
             </p>
           </Col>   
           :
           <>
           <Col sm={12} md={4}  className="pt-5 pb-5 d-flex">
           <ReactPlayer url={formData.introVideo ? formData.introVideo : ""} width="100%" height="190px" controls={true} />
           <i   onClick={OpenIntroModal} className="text-theme-green fa fa-edit fa-2x plus-box edit-1-ico"></i>
           </Col>
           </>
           }
          </Row>
          <Row className="mb-15">
            <Form.Label className="fw-600" column="sm" sm={6} md={6} lg={3}>
            Select Company Thankyou Video
            </Form.Label>
          {!formData.thanksVideo ? 
            <Col md={6}>
            <p className="text-theme-green c-p" onClick={OpenThanksModal} style={{"width":"maxContent"}}>
                <i className="text-theme-green fa fa-plus fa-1x plus-box"></i>
                Select Company Thankyou Video
             </p>
           </Col>   
           :
           <>
           <Col sm={12} md={4}  className="pt-5 pb-5 d-flex">
           <ReactPlayer url={formData.thanksVideo ? formData.thanksVideo : ""} width="100%" height="190px" controls={true} />
           <i onClick={OpenThanksModal} className="text-theme-green fa fa-edit fa-2x plus-box edit-1-ico"></i>
           </Col>
           </>
           }
          </Row>
        </TabContainer>
        <TabContainer dir={theme.direction}>
        <div>
        {hasfile  ? (  
        <Row className="mt-5 mb-15">
          <Form.Label className="fw-600" column sm={2}>
            Attach File for Bulk Upload* {Sethasfile}
          </Form.Label>
          <Col sm={10} md={7}>
          <div className="upload-container"  style={{"position":"relative"}}>  
            <div className="upload-btn-wrapper" >
              <Link to="#" className="btn btn-file upload-btn" type="btn" value={pdFileName} onClick={triggerClickagain}>Choose File </Link>
              <input type="file"  className="d-none" name="file" 
                accept=".csv,.xlsx,.xls"
                onChange={(e) => uploadFile(e)}  
                id="fileUpload-inp1"
              />
            </div>
             <span className="text-dark c-p" style={{"position": "absolute", "top":"50%","transform": "translate(5px, -50%)" }}> { pdFileName ? pdFileName : "No File Selected" } </span>
          </div>
          <div>
          </div>

          <div>
          <a target="_blank" href="https://vrekruitdev.s3.ap-south-1.amazonaws.com/vrekruit/CandidateOnlyTemplete.csv" className="text-theme-green ml-5">
           <i className="fa fa-download text-theme-green"> </i> Download CSV Template
          </a> 
          </div> 
          </Col>   
        </Row>  
        ) :""}
        </div> 
       { formData.users.length>1 ? (
        <p className="text-theme-green c-p text-right" onClick={clearAllData}> Clear All</p>
        ) :""}
          <div className="text-center">
            <Grid container spacing={1}>
              <Grid container item xs={12} spacing={3}>
                <FormRow />
              </Grid>
              <Grid container item xs={12} spacing={3}>
                { 
                
                formData.users.map((x, ind) => {
                  return <React.Fragment>
                    <Grid item xs={1}>
                    </Grid>
          
                    <Grid item xs={2} >
                      <Form.Control size="md" id="floatingPasswordCustom" value={x.firstName} type="text" onChange={(e) => onCandidateDetailsChange(ind, "firstName", e.target.value)} placeholder=""  readOnly={ind==0?false:true}  required/>
                      { showCandidateError && !x.firstName ? 
                      <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-left">Required</div>
                     </div>
                      : null}
                    </Grid>
                    <Grid item xs={2}>
                      <Form.Control size="md" id="floatingPasswordCustom" value={x.lastName}  onChange={(e) => onCandidateDetailsChange(ind, "lastName", e.target.value)}  type="text" placeholder="" readOnly={ind==0?false:true} />
                      { showCandidateError && !x.lastName ? 
                      <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-left">Required</div>
                     </div>
                      : null}
                    </Grid>
                    <Grid item xs={3}>
                      <PhoneInput  name="phone" onChange={(value, data, event, formattedValue) => handlePhoneChange(value, data, event, formattedValue, ind)} value={ x.dialCode + " " +  x.phone}
                      disabled={ind==0?false:true}
                      countryCodeEditable={false}
                      defaultCountry="in"
                      onlyCountries={["in"]}
                      masks={{
                          in: "..........",
                    
                      }}
                      />
                      { showCandidateError && !x.phone ? 
                      <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-left">Required</div>
                     </div>
                      : null}
                       { showCandidateError && x.phone && (x.phone.length < 10 ||  x.phone.length > 10) ?
                      <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-left">Phone Number must be 10 digits</div>
                     </div>
                      : null}

                    { showCandidateError && ind == 0 && duplicatePhone  ? 
                      <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-left">Phone Number Already Exists</div>
                     </div>
                      : null}
                    </Grid>
                    <Grid item xs={3}>
                      <Form.Control size="md" id="floatingPasswordCustom"  type="email" placeholder="" 
                      value={x.email} onChange={(e) => onCandidateDetailsChange(ind, "email", e.target.value)}
                      readOnly={ind==0?false:true}
                      />
                       { showCandidateError && !x.email ? 
                      <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-left">Required</div>
                     </div>
                      : null}
                   { showInvalidEmail && x.email && ind == 0 ? 
                      <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-left">Invalid Email</div>
                     </div>
                      : null}

                    { showCandidateError && duplicateEmail && ind == 0 ? 
                      <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-left">Email Already Exists</div>
                     </div>
                      : null}

                    </Grid>                     
                     {(ind === 0 ?
                      (<Grid item xs={1} >
                        <span onClick={addNewCandidate} className="c-p"><img src="/media/VRekruitIcons/addmore.svg" className="c-p" width="24" title="Add" alt="Add"  /></span>
                      </Grid>)
                      :
                      (<Grid item xs={1} >
                        <span className="c-p" onClick={()=> deleteCandidateDetail(ind)} ><img src="/media/VRekruitIcons/delete.svg" className="c-p" width="24"  title="Delete" alt="Delete"  /></span>
                      </Grid>)
                     )}                                          
                  </React.Fragment>
                })
                }
              </Grid>
            </Grid>
          </div>
        <Row className="mt-5"></Row>
        <Row className="mt-5 text-center">
        </Row>
        { !hasfile ? (        
        <Row className="mt-5">
          <Col sm={12}>         
           <p className="text-center">OR</p>
           </Col>
          <Form.Label className="fw-600" column sm={2}>
             Attach File for Bulk Upload*
          </Form.Label>
          <Col sm={10} md={7}>
          <div className="upload-container"  style={{"position":"relative"}}>  
            <div className="upload-btn-wrapper" >
              <Link to="#" className="btn btn-file upload-btn" type="btn" value={pdFileName} onClick={triggerClick}>Choose File </Link>
              <input type="file"    className="d-none" name="file" 
                accept=".csv,.xlsx,.xls"
                onChange={(e) => uploadFile(e)}  
                id="fileUpload-inp"
              />
            </div>
             <span className="text-dark c-p" style={{"position": "absolute", "top":"50%","transform": "translate(5px, -50%)" }}> { pdFileName ? pdFileName : "No File Selected" } </span>
          </div>
          <div>
          </div>

          <div>
          <a target="_blank" href="https://vrekruitdev.s3.ap-south-1.amazonaws.com/vrekruit/CandidateOnlyTemplete.csv" className="text-theme-green ml-5">
           <i className="fa fa-download text-theme-green"> </i> Download CSV Template
          </a> 
          </div> 
          </Col>          
        </Row>
        ): ""}
        </TabContainer>
      </SwipeableViews>
    </div>
    <DeleteConfirmationModal show={modalShow} onHide={() => setModalShow(false)} />
    <CustomizedDialogs></CustomizedDialogs>
  </>
  
  
  );
        }

}

export default ShareInterview