/* eslint-disable no-restricted-imports */
/* jshint asi:true */

import React, { useEffect, useState } from 'react';
import Helmet from "react-helmet"
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Button } from 'react-bootstrap';
import { useParams, Link } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import alertService from '../../_services/alertservice';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import * as XLSX from 'xlsx';
import Modal from 'react-bootstrap/Modal'



const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};




function ShareTest() {

  useEffect(() => {
    let mounted = true;
    get_Job_Requestion_List();
    return () => mounted = false;
  }, [])


  const [jobRequesitonList, SetJobRequesitonList] = useState();
  const [pdFileName, SetSeletedFileName] = useState("");
  const [hasfile,Sethasfile] = useState(false);



  // csv upload code start
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
 
  // process CSV data
  const processData = dataString => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
 
    var list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"')
              d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"')
              d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }
 
        // remove the blank rows
        if (Object.values(obj).filter(x => x).length > 0) {
          list.push(obj);
        }
      }
    }
 
    // prepare columns list from headers
    const columns = headers.map(c => ({
      name: c,
      selector: c,
    }));

  list = list.map(x=> {
    
   return { email : x["Email Address"], firstName : x["First Name"], lastName: x["Last Name"], dialCode: x["Dial Code"], phone: x["Phone Number"] }
  });
  var errmsg= ""
  var phoneList = list.map(x => x.phone);
  var emailList = list.map(x => x.email);
  if (hasDuplicates(phoneList)) {
    errmsg += "Duplicate phone numbers are present in list. ";
  }
  if (hasDuplicates(emailList)) {
    errmsg += "Duplicate emails are present in list. ";
  }

  if(errmsg) {
    alertService.alert({ message: errmsg, type: 'warning' });
    return;
  }

  var emailRegex = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
  list.forEach((x, i) => {
    var row = i + 1;
    if(!x.firstName) {
      errmsg = "Required " + "First Name at Row " + i+1 + ", "; 
    }
    if(!x.lastName) {
      errmsg = "Required " + "Last Name at Row " + i+1 + ", "; 
    }
    if(!x.email) {
      errmsg = "Required " + "Email at Row " + i+1 + ", "; 
    }
    if(x.email){
      if(!emailRegex.test(x.email))   {
        errmsg = "Invalid " + "Email Address at Row "+ i+ ", "; 
      }
    }
    if(!x.phone) {
      errmsg = "Required " + "Email Phone at Row " + i + ", "; 
    }
    if(x.phone){
      if(x.phone.length < 6 || x.phone.length > 15 ) {
        errmsg = "Invalid " + "Min 6 and max 15 Char are allowed Row" + i+ ", "; 
      }
    }
  });
  
  list.unshift({
    email: "",
    firstName: "",
    lastName: "",
    dialCode: "+91",
    phone: ""
  });
  if(!errmsg) {
    SetFormData({
      ...formData, users : list
    });
  }
  else{
    alertService.alert({ message: errmsg, type: 'warning' });
  }   
    setColumns(columns);
  }

  function hasDuplicates(array) {
    return (new Set(array)).size !== array.length;
}

 const uploadFile = (e) => {
   if(e.target.files.length> 0) {
  // alert('Selected file: ' + x.target.files[0].name);
  SetSeletedFileName(e.target.files[0].name);
  if(e.target.files[0].name){
    Sethasfile(true);
  }
  else{
    Sethasfile(false)
  }
  // formik.values.file =  x.target.files[0].name;
  const file = e.target.files[0];
  const reader = new FileReader();
  reader.onload = (evt) => {
    /* Parse data */
    const bstr = evt.target.result;
    const wb = XLSX.read(bstr, { type: 'binary' });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
    processData(data);
  };
  reader.readAsBinaryString(file);

   }
   else {
    e.target.files[0].name = "";
    SetSeletedFileName("");
   }

 }


 const triggerClickagain = () => {
  setModalShow(true);
 
 } 

 const confirmUpload = () => {
  let ClickItem = document.getElementById("fileUpload-inp1");
  if(ClickItem){
    ClickItem.click();
    setModalShow(false);
    setConfirmattion(false);
  }
}

const declineUpload = () => {
  setModalShow(false);
}


// csv upload code end


const get_Job_Requestion_List = () => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/requisitions?page=1&limit=1000&orderBy=id&orderType=ASC`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          SetJobRequesitonList(res.data);

        }
        else {

        }
      })
      .catch(() => {

      });
  }


let { id } = useParams();

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

 const [buttonText, SetButtonText] = useState("Next");
  function handleChangeIndex(index) {
    setValue(index);
    if(index== -1) {
      SetButtonText("Next")
    }
    else {
      SetButtonText("Previous")
    }
  }

  const changeButtonText = () => {
    if(buttonText=="Next") {
      handleChangeIndex(1);
      SetButtonText("Previous")
    }
    else {
      handleChangeIndex(0);
      SetButtonText("Next")
    }
  }


const [modalShow, setModalShow] = React.useState(false);
const [confirmation,setConfirmattion] =useState(false);

  function DeleteConfirmationModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            <p className="text-center">
            <img alt="logo" className="brand-dim" src="/media/logos/UPDATED_VRekruit _Logo.png" />
              </p>
            <p className="text-dark text-center pt-3"> Are you sure want to Reupload file ?</p>
          </Modal.Title>
        </Modal.Body>
        <Modal.Title className="d-flex justify-content-center align-items-center pb-5" >
        <p className="text-center">

        <Button className="btn btn-theme text-white btn-lg mr-5  pl-5 pr-5 pt-3 pb-3" onClick={confirmUpload} style={{"minWidth": "100px"}} > Yes </Button>
       <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green" onClick={declineUpload} style={{"minWidth": "100px"}}  >No</Button> 
       </p>
        </Modal.Title>
      </Modal>
    );
  }





  function FormRow() {
    return (
      <React.Fragment>

        <Grid item xs={2}>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}>First Name*</Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}>Last Name*</Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}>Phone Number*</Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}>Email Id*</Paper>
        </Grid>
      </React.Fragment>
    );
  }


  const handleFormInput = (e) => {
    SetFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }
const [showCandidateError, SetShowCandidateError] = useState(false);
const [showInvalidEmail, SetInvalidEmailError] = useState(false);
const [duplicatePhone, setDuplicatePhone] = useState(false);
const [duplicateEmail, setDuplicateEmail] = useState(false);





const addNewCandidate = () => {
  var firstCandidate = formData.users[0];
  var isEmailValid = false;
  var emailRegex = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
  if(firstCandidate.email){
       if(emailRegex.test(firstCandidate.email))   {
         isEmailValid = true;
       }
  }
    SetInvalidEmailError(!isEmailValid);
    var phoneList = formData.users.map(x => x.phone);
    var emailList = formData.users.map(x => x.email);
    var hasDupEmail = hasDuplicates(emailList);
    var hasDupPhone  =hasDuplicates(phoneList);
      setDuplicatePhone(hasDupPhone);
      setDuplicateEmail(hasDupEmail);
    

  if (firstCandidate.firstName 
    && firstCandidate.lastName
    && firstCandidate.email 
    && firstCandidate.phone
    && firstCandidate.phone.length > 6 
    && firstCandidate.phone.length < 15
    && isEmailValid 
    && !hasDupEmail
    && !hasDupPhone
    ) {
  formData.users.unshift({
    email: "",
    firstName: "",
    lastName: "",
    dialCode: "+91",
    phone: ""
  })
  SetFormData({ ...formData, users : formData.users});
  SetShowCandidateError(false);

} else {
  SetShowCandidateError(true);
}
}

const onCandidateDetailsChange = (index, propertyName, value) => {
  formData.users[index][propertyName] = value;
  SetFormData({ ...formData, users : formData.users});
}

const deleteCandidateDetail = (index) => {
  formData.users.splice(index, 1);
  SetFormData({ ...formData, users : formData.users});
}

const onDateChange = (e) => {
   SetFormData({ ...formData, expiryTime : formData.expiryTime});

}


  const [formData, SetFormData] = useState(
    {
      testId: id,
      requisitionId: "",
      timeDuration: "",
      passingPercentage: "",
      expiryTime: "",
      sendTest: "",
      capturePhoto: "",
      users: [{
        email: "",
        firstName: "",
        lastName: "",
        dialCode: "+91",
        phone: ""
      }]
    }
  )


  const handlePhoneChange = (value, data, event, formattedValue,ind) => {
    if(ind==0)  {
      formData.users[ind]["dialCode"] = data.dialCode;
      
      formData.users[ind]["phone"] = value.replace(data.dialCode, "");;
      SetFormData({ ...formData, users : formData.users});
    }
  }



  const shareTest = (e) => {
    e.preventDefault();
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/test/share`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(formData)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          alertService.alert({ message: res.message, type: 'info' });
          SetFormData(
            {
              testId: id,
              requisitionId: "",
              timeDuration: "",
              passingPercentage: "",
              expiryTime: "",
              sendTest: "",
              capturePhoto: "",
              users: [{
                email: "",
                firstName: "",
                lastName: "",
                dialCode: "+91",
                phone: ""
              },]
            }
          )
        }
        else {
          if (res.errors) {
            var errmsg = "";
            res.errors.forEach(function (value, index, array) {
              errmsg = errmsg + "(" + (index + 1) + ") " + value.msg + "\n\r";

            });
            alertService.alert({ message: errmsg, type: 'error' });
          }

          else {
            alertService.alert({ message: res.message, type: 'error' });
          }
        }
      })
      .catch(() => {
      });
  }

  const triggerClick = () => {
    let ClickItem = document.getElementById("fileUpload-inp");
     if(ClickItem){
       ClickItem.click();
     }
   } 


 const  clearAllData = () => {
    SetFormData(
      {
        testId: id,
        requisitionId: "",
        timeDuration: "",
        passingPercentage: "",
        expiryTime: "",
        sendTest: "",
        capturePhoto: "",
        users: [{
          email: "",
          firstName: "",
          lastName: "",
          dialCode: "+91",
          phone: ""
        },]
      }
    )
   }

  return <>
    <Helmet>
      <title> VRekruit | Manage Test </title>
    </Helmet>

    {/* subeader */}
    <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
      <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <div className="d-flex align-items-center flex-wrap mr-1">
          <div className="d-flex align-items-baseline mr-5">
            <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Test Management </h3></div>
        </div>
        <div className="d-flex align-items-center" />
      </div>
    </div>
    {/* subeader */}

    <Col>
      <Row className="bg-white  d-flex flex-grow-1 align-items-center  justify-content-start">
        <Col md={6} sm={6} offset={4} className="pr-3 pl-3 mt-3">
          <div>
            <div className="   rounded">
              <div>
                <h5 className="font-weight-bold f-w-600 pl-5" > Share Test With Candidate  </h5>
              </div>
            </div>
          </div>
        </Col>
        <Col md={6} sm={6} offset={4} className="pr-3 pl-3 mt-3">
          <div>
            <div className="d-flex flex-grow-1 align-items-center justify-content-end rounded mt-1">
              <div className="text-muted d-block">
                <Link to="#" type="button"
                  className={`btn btn-theme-green btn-lg mr-5 mt-2 mb-0`}
                  onClick={changeButtonText}
                  >

                   { buttonText }
                 
                  
                </Link>
                {/* <Link to="/preview-test"  onClick={Create_Test}  type="button" className="btn btn-theme-green btn-lg mr-5 mt-2 mb-0"  >
              Preview Test
           </Link> */}
                <Button onClick={shareTest} type="button" className="btn btn-theme-green btn-lg mr-5 mt-2 mb-0"

                  className={`btn btn-theme-green btn-lg mr-5 mt-2 mb-0`}>
                  Send Test
                </Button>
                <Link to="#" type="button" className="btn btn-theme text-white btn-lg mr-5 mt-2 mb-0"  >
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </Col>
        <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5"></Col>
      </Row>
    </Col>





    {/* <switch> */}
    <div className={classes.root} style={{ "backgroundColor": "white", "minHeight": "60vh" }}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Basic Detail" />
          <Tab label="Add Candidate" />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabContainer dir={theme.direction}>
          {/* <BootstrapTable className="bg-white" keyField='id' data={ Questions } columns={ columns } pagination={ paginationFactory(options) }   /> */}


          <Row className="mb-5">
            <Form.Label column="sm" lg={2}>
              Job Requestion
            </Form.Label>
            <Col md={6}>
              <Form.Control as="select" name="requisitionId" size="sm" value={formData.requisitionId} onChange={(e) => handleFormInput(e)} >
                <option value=""> Select Job Requestion</option>  
                {jobRequesitonList && jobRequesitonList.map
                  (x => <option value={x.requisitionId}>{x.requisitionId}   {x.position}</option>)
                }
              </Form.Control>
            </Col>
          </Row>


          <Row className="mb-5">
            <Form.Label column="sm" lg={2}>
              Passing Percentage*
            </Form.Label>
            <Col md={6}>
              <Form.Control size="sm" type="number" name="passingPercentage" placeholder="" onChange={(e) => handleFormInput(e)} />
            </Col>
          </Row>

          <Row className="mb-5">
            <Form.Label column="sm" lg={2}>
              Test Duration (minutes*)
            </Form.Label>
            <Col md={6}>
              <Form.Control size="sm" type="number" placeholder="" name="timeDuration" onChange={(e) => handleFormInput(e)} />
            </Col>
          </Row>


          <Row className="mb-5">
            <Form.Label column="sm" lg={2}>
              Expiry Time*
            </Form.Label>
            <Col md={6}>
              <TextField
                id="datetime-local"
                label=""
                name="expiryTime"
                type="datetime-local"
                defaultValue="2017-05-24T10:30"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) =>  handleFormInput(e)}
              />
            </Col>
          </Row>



          <Row className="mb-5">
            <Form.Label column="sm" lg={2}>
              Send Test Result to the Candidate*
            </Form.Label>
            <Col md={2}>
              <Form.Group as={Col} controlId="formGridStatetest" size="sm" value={formData.sendTest}>
                {['radio'].map((type) => (
                  <div key={`inline-${type}`} className="mb-3" style={{ "display": "flex", "justifyContent": "space-between", "width": "100%" }}>
                    <Form.Check onChange={(e) => handleFormInput(e)} inline label="Yes" className="pr-3-inp" name="sendTest" value="true" type={type} id={`inline-${type}-1`} />

                    <Form.Check onChange={(e) => handleFormInput(e)} inline label="No" className="pr-3-inp" name="sendTest" value="false" type={type} id={`inline-${type}-2`} />
                  </div>
                ))}
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-5">
            <Form.Label column="sm" lg={2}>
              Capture Photo of the candidate in every 30 seconds*
            </Form.Label>
            <Col md={2}>
              <Form.Group as={Col} controlId="formGridStatephoto" size="sm" value={formData.capturePhoto}>
                {['radio'].map((type) => (
                  <div key={`inline-${type}`} className="mb-3" style={{ "display": "flex", "justifyContent": "space-between", "width": "100%" }}>
                    <Form.Check
                      onChange={(e) => handleFormInput(e)}
                      inline label="Yes"
                      className="pr-3-inp"
                      name="capturePhoto"
                      value="true"
                      type={type}
                      id={`inline-${type}-1`} />

                    <Form.Check inline label="No"
                      onChange={(e) => handleFormInput(e)}
                      className="pr-3-inp"
                      name="capturePhoto"
                      value="false"
                      type={type}
                      id={`inline-${type}-2`} />
                  </div>
                ))}
              </Form.Group>
            </Col>
          </Row>

        </TabContainer>
        <TabContainer dir={theme.direction}>


        <div>
          

        {hasfile  ? (  
        <Row className="mt-5 mb-5">
          <Form.Label column sm={2}>
            Attach File* {Sethasfile}
          </Form.Label>
          <Col sm={10} md={7}>
          <div className="upload-container"  style={{"position":"relative"}}>  
            <div className="upload-btn-wrapper" >
              <Link to="#" className="btn btn-file upload-btn" type="btn" value={pdFileName} onClick={triggerClickagain}>Choose File </Link>
              <input type="file"  className="d-none" name="file" 
                accept=".csv,.xlsx,.xls"
                onChange={(e) => uploadFile(e)}  
                id="fileUpload-inp1"
              />
            </div>
             <span className="text-dark c-p" style={{"position": "absolute", "top":"50%","transform": "translate(5px, -50%)" }}> { pdFileName ? pdFileName : "No File Selected" } </span>
          </div>
          <div>
          </div>

          <div>
          <a target="_blank" href="https://vrekruitdev.s3.ap-south-1.amazonaws.com/vrekruit/CandidateOnlyTemplete.csv" className="text-theme-green ml-5">
           <i className="fa fa-download text-theme-green"> </i> Download CSV Template
          </a> 
          {/* { formik.touched.file &&  !hasfile ? 
          (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">Required</div>
            </div>
          ) 
          : null} */}
          </div> 
          </Col>          
        </Row>  
        ) :""}
        </div> 


       { formData.users.length>1 ? (
        <p className="text-theme-green c-p text-right" onClick={clearAllData}> Clear All</p>
        ) :""}

          <div className="text-center">
            <Grid container spacing={1}>
              <Grid container item xs={12} spacing={3}>
                <FormRow />
              </Grid>
              <Grid container item xs={12} spacing={3}>
                { 
                
                formData.users.map((x, ind) => {
                  return <React.Fragment>
                    <Grid item xs={2} >
                    </Grid>
                    <Grid item xs={2} >
                      <Form.Control size="md" id="floatingPasswordCustom" value={x.firstName} type="text" onChange={(e) => onCandidateDetailsChange(ind, "firstName", e.target.value)} placeholder=""  readOnly={ind==0?false:true}  required/>
                      { showCandidateError && !x.firstName ? 
                      <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-left">Required</div>
                     </div>
                      : null}
                    </Grid>
                    <Grid item xs={2}>
                      <Form.Control size="md" id="floatingPasswordCustom" value={x.lastName}  onChange={(e) => onCandidateDetailsChange(ind, "lastName", e.target.value)}  type="text" placeholder="" readOnly={ind==0?false:true} />
                      { showCandidateError && !x.lastName ? 
                      <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-left">Required</div>
                     </div>
                      : null}
                    </Grid>
                    <Grid item xs={2}>
                      {/* <Form.Control id="floatingPasswordCustom" value={x.phone} onChange={(e) => onCandidateDetailsChange(ind, "phone", e.target.value)}  type="number" placeholder="" /> */}

                      <PhoneInput  name="phone" onChange={(value, data, event, formattedValue) => handlePhoneChange(value, data, event, formattedValue, ind)} value={ x.dialCode + " " +  x.phone}
                      disabled={ind==0?false:true}
                      countryCodeEditable={false}
                        defaultCountry="in"
                          onlyCountries={["in"]}
                          masks={{
                            in: "..........",
                      
                          }}
                      />
                      { showCandidateError && !x.phone ? 
                      <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-left">Required</div>
                     </div>
                      : null}
                       { showCandidateError && x.phone && (x.phone.length < 6 || x.phone.length > 15) ?
                      <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-left">Phone number should be 6 to 15 char long</div>
                     </div>
                      : null}

                    { showCandidateError && ind == 0 && duplicatePhone  ? 
                      <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-left">Phone Already Exists</div>
                     </div>
                      : null}
                    </Grid>
                    <Grid item xs={2}>
                      <Form.Control size="md" id="floatingPasswordCustom"  type="email" placeholder="" 
                      value={x.email} onChange={(e) => onCandidateDetailsChange(ind, "email", e.target.value)}
                      readOnly={ind==0?false:true}
                      />
                       { showCandidateError && !x.email ? 
                      <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-left">Required</div>
                     </div>
                      : null}
                   { showInvalidEmail && x.email && ind == 0 ? 
                      <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-left">Invalid Email</div>
                     </div>
                      : null}

                    { showCandidateError && duplicateEmail && ind == 0 ? 
                      <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-left">Email Already Exists</div>
                     </div>
                      : null}

                    </Grid>                     
                     {(ind === 0 ?
                      (<Grid item xs={2} >
                        <span onClick={addNewCandidate} className="c-p"><img src="/media/VRekruitIcons/addmore.svg" className="c-p" width="24" /></span>
                      </Grid>)
                      :
                      (<Grid item xs={2} >
                        <span className="c-p" onClick={()=> deleteCandidateDetail(ind)} ><img src="/media/VRekruitIcons/delete.svg" className="c-p" width="24" /></span>
                      </Grid>)
                     )}                                          
                  </React.Fragment>
                })
                }
              </Grid>
            </Grid>
          </div>
        <Row className="mt-5"></Row>
        <Row className="mt-5 text-center">
        </Row>
        { !hasfile ? (        
        <Row className="mt-5">
          <Col sm={12}>         
           <p className="text-center">OR</p>
           </Col>
          <Form.Label column sm={2}>
            Attach File*
          </Form.Label>
          <Col sm={10} md={7}>
          <div className="upload-container"  style={{"position":"relative"}}>  
            <div className="upload-btn-wrapper" >
              <Link to="#" className="btn btn-file upload-btn" type="btn" value={pdFileName} onClick={triggerClick}>Choose File </Link>
              <input type="file"  className="d-none" name="file" 
                accept=".csv,.xlsx,.xls"
                onChange={(e) => uploadFile(e)}  
                id="fileUpload-inp"
              />
            </div>
             <span className="text-dark c-p" style={{"position": "absolute", "top":"50%","transform": "translate(5px, -50%)" }}> { pdFileName ? pdFileName : "No File Selected" } </span>
          </div>
          <div>
          </div>

          <div>
          <a target="_blank" href="https://vrekruitdev.s3.ap-south-1.amazonaws.com/vrekruit/CandidateOnlyTemplete.csv" className="text-theme-green ml-5">
           <i className="fa fa-download text-theme-green"> </i> Download CSV Template
          </a> 
          {/* { formik.touched.file &&  !hasfile ? 
          (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">Required</div>
            </div>
          ) 
          : null} */}
          </div> 
          </Col>          
        </Row>
        ): ""}
        </TabContainer>
      </SwipeableViews>
    </div>
    {/* <BootstrapTable className="bg-white" keyField='id' data={ Questions } columns={ columns } pagination={ paginationFactory(options) }   selectRow={ selectRow } /> */}
    <DeleteConfirmationModal show={modalShow} onHide={() => setModalShow(false)} />

  </>;
}

export default ShareTest