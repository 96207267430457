/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React, { useEffect, useState,useRef,useCallback } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormFile from 'react-bootstrap/FormFile'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import TextField from '@material-ui/core/TextField';
import LimitedMultiSelect from './AutoCompleteWithLimit';
import Table from 'react-bootstrap/Table'
import { divide } from 'lodash';
import Helmet from 'react-helmet'
import { Link, useHistory } from "react-router-dom";
import { Redirect } from 'react-router';
import alertService from '../../../_services/alertservice';
import { Button } from 'react-bootstrap';
import { CALL_HISTORY_METHOD } from 'react-router-redux';
import Modal from 'react-bootstrap/Modal'
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { useDispatch } from 'react-redux';
import { textFilter } from 'react-bootstrap-table2-filter';
import table from './Table';
import {sortableContainer, sortableElement } from 'react-sortable-hoc';
//import { Draggable } from "react-drag-reorder";
//import { DndProvider, useDrag, useDrop } from 'react-dnd'
//	import { HTML5Backend } from 'react-dnd-html5-backend'
  import update from 'immutability-helper';
import {arrayMoveImmutable} from 'array-move';
import { logout } from '../../../../actions/auth';
import Tooltip from "@material-ui/core/Tooltip";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const BlueOnGreenTooltip = withStyles({
  tooltip: {
    color: "white",
    backgroundColor: "#59B230",
    fontSize: "11px"
  }
})(Tooltip);

const SortableItem = sortableElement(({testQuestion,index,handleRemoveQues,handleClickOpen1,showEditQuesTime,showTimeUpdate, editTimeQuesId,updateQuesAnsTime, parseToHHMMSS}) => {
  return ( 
    <tr style={{'cursor':'move'}}>
    <td>{testQuestion.question}</td>
    <td>{testQuestion.interviewCategory.categoryName}</td>
    <td>{testQuestion.interviewSubCategory ? testQuestion.interviewSubCategory.subCategoryName : ''}</td> 
    <td> {showTimeUpdate && editTimeQuesId == testQuestion.id ? ( <input type="text" id={`edit-time-${testQuestion.id}`} className="form-control" defaultValue={testQuestion.answerDuration} onBlur={(e) => updateQuesAnsTime(e)} />) : (<span style={{"maxWidth":"100px"}}> {parseToHHMMSS(testQuestion.answerDuration)} <img src="/media/VRekruitIcons/clock.png" className="fa-pencil-alt c-p" onClick={() =>showEditQuesTime(testQuestion)}/></span>)}</td>
    <td><div className="text-left d-flex"><img src="/media/VRekruitIcons/eyeorview.svg" className="view-option c-p view-eye-ico-1" title="View" alt="View" onClick={() => handleClickOpen1(testQuestion)}/>
    <img src="/media/VRekruitIcons/delete.svg" className="delete-option c-p view-eye-ico ml-5" title="Delete" alt="Delete" onClick={() => handleRemoveQues(testQuestion,index)}/></div></td>
    </tr>)

} )

const SortableContainer = sortableContainer(({children}) => {
  return <table className="table table-bordered">
    <thead>
    <tr>
              <th className="text-uppercase" >Question</th>
              <th className="text-uppercase" >Parent Category</th>
              <th className="text-uppercase" >Sub Category</th>
              <th className="text-uppercase" >Time to Answer</th>
              <th className="text-uppercase" >Action</th>


            </tr>
            </thead>
            <tbody>{children}</tbody></table>;
});

// const useStyles = makeStyles(theme => ({
//   root: {
//     backgroundColor: theme.palette.background.paper,
//   },
// }));


{/* <tab style css statrt> */}
function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}
TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

{/* <tab style css statrt> */}

const initialValues = {
  status: "open",
  page: 1,
  limit: 10,
  assignedToMe: false,
  createdByMe: "0",
  orderBy: "Id",
  orderType: "ASC"
}

function CreateInterview(){

  const [QuestionsList, setQuestionsList] = useState([]);
  const [isSelected, SelectedQuestion] = useState(false);
  const [testQuestions, setTestQuestions] = useState([]);
  const [staticData, setStaticData] = useState(10);
  const [parentCategories, setParentCategories] = useState([]);
  const [fitmentsList, setFitmentsList] = useState([]);
  const [fitmentsListIds, setFitmentsIds] = useState("");
  const [selectedFitments, setSelectedFitments] = useState([]);
  const [errorText, setErrorText] = useState("")
  const [searchTerm, SetSearchTerm] = useState("");
  const [createdbyme, SetCreatedbyme] = useState(false);
  const [assignedbyme, SetAssignedbyme] = useState(false);
  const [status, SetStatus] = useState("");
  const [selectedId, setSelectedOption] = useState(true);
  const [subCategory, setSubCategory] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [categorySelected, SetcategorySelected] = useState("");
  const [subcategorySelected, SetSubcategorySelected] = useState("");
  const [difficultyLevel, setDifficultyLevel] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [buttonState,SetButtonState] = useState({
    "previewBtnState": true,
    "addQuestionButtonState": true,
  })
  const [rangeValues, SetRangeValues ]= useState({
    "minRange": 10,
    "maxRange": 70,
  })

  const [formData, SetFormData] = useState(
    {
      interviewTitle:"",
      // To Maintain List Of Selected Questions
      questions: [],
    }
  )


 const onSortEnd = ({oldIndex, newIndex}) => {
   var updatesTestQuestions = arrayMoveImmutable(testQuestions, oldIndex, newIndex)
  setTestQuestions(updatesTestQuestions)
  var updatedtestquestion = updatesTestQuestions.map(ele=> {
    return { questionId: ele.id ,answerTime:ele.answerDuration}
  });
  SetAddedQuestionList(updatedtestquestion);   
  };
    const handleSelectFitments = (value, i) => {
        var selectedFitmentIds = '';
        var found = '';
        if (value) {
            for (let i = 0; i < value.length; i++) {
                found = fitmentsList.find(fitment => fitment.parameterName === value[i]);
                console.log(found.id)
                selectedFitmentIds += selectedFitmentIds ? ',' + found.id : '' +found.id
            }
        }
        setSelectedFitments(value)
        setFitmentsIds(selectedFitmentIds)
    }

    function handleMultipeInput(value)  {
      //     e.preventDefault();        
       //   setName(value);
          setIdListItem(value);   
      };
      const setIdListItem = (name) =>{
        if(name) {
          var namelist = [];
          name.forEach(element => {
            namelist.push(element.value)
          });
          const list = namelist.join(", ");
          setFitmentsIds(list);
         
            var options1 = [];
            name.forEach(element => {
              options1.push(
                { value: element.id, label: element.label}
              )
            });
            setSelectedFitments(options1);
          }
      }

  const dispatch = useDispatch();
  const logoutMethod = () => {
    dispatch(logout());
    history.push('/auth/signin');
};

  const[bankType,SetBankType]= useState("private");
  const handleOptionChange = async (e) =>  {
      var banktypeval = e;
    await  SetBankType(banktypeval);

  }

  function handleChangeIndex(index) {
    setValue(index);
    }

    const handleSelectFitmentChange = () => {

    }

  const [value, setValue] = React.useState([10, 70]);

  const handleChange = (event, newValue) => {

    setValue(newValue);
    SetRangeValues({
      ...rangeValues,
       minRange: newValue[0],
       maxRange: newValue[1],
    })
  };


  function valuetext(value) {
    return `${value}`;
  }


  const resetFilter = () => {
    SetcategorySelected("");
     SetSubcategorySelected("");
    SetSearchTerm("");
    setQuestionsList([]);
    SetFilter(false);
  }


  // filter option
  const SetSearchTermData = (x) => {
    let data = x.target.value;
    if (data) {
      SetSearchTerm(x.target.value);
    }
    else {
      SetSearchTerm("");
    }
  }

  function handleCategorySelect(event) {
    if (event.target.value) {
      SetcategorySelected(event.target.value);
    }
    else {
      SetcategorySelected("");
    }
    // document.getElementById("subcategoryvalue").value= ""
    var parentId = (event.target.value);
    get_sub_categories(parentId);
  }

  function handleSubCategorySelect(event) {
    if (event.target.value) {
      SetSubcategorySelected(event.target.value);
    }
    else {
      SetSubcategorySelected("");
    }

  }


  function handleDifficultyLevelSelect(event) {
    if (event.target.value) {
      setDifficultyLevel(event.target.value);
    }
    else {
      setDifficultyLevel("");

    }

  }


  function handleQuestionTypeSelect(event) {
    if (event.target.value) {
      setQuestionType(event.target.value);
    }
    else {
      setQuestionType("");

    }

  }

  const SetAssignedbymeData = (x) => {
    SetAssignedbyme(x.target.checked);
  }

  const SetCreatedbymeData = (x) => {
    SetCreatedbyme(x.target.checked);
  }

  // filter option




  var i = 1;
  const[loadingList,SetLoadingList]=useState(false);
  const[filter,SetFilter]=useState(false);
  const get_Question_List = () => {

    SetFilter(true);
    const token = localStorage.getItem('x-access-token');
    SetLoadingList(true);
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/interview/questions?page=1&limit=1000`;
    //  history.push("/searchTerm");
     if(bankType){
      url = url + "&bankType=" + bankType;
    }

    if (difficultyLevel) {
      url = url + "&difficultyLevel=" + difficultyLevel;
    }

    if (categorySelected) {
      url = url + "&categoryId=" + categorySelected;

    }

    if (subcategorySelected) {
      url = url + "&subCategoryId=" + subcategorySelected;

    }

    if (questionType) {
      url = url + "&questionType=" + questionType;

    }

    if (searchTerm) {
      url = url + "&searchBy=" + searchTerm;
    }

    if(rangeValues){
      url = url + "&minMarks=" +  rangeValues.minRange  +  "&maxMarks=" +  rangeValues.maxRange
    }



    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          var questionsList = res.data.filter(x=> testQuestions.findIndex(que => que.id == x.id) < 0);
          setQuestionsList(questionsList);
          setStaticData(staticData + 10);
          SetLoadingList(false);
        }else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
          SetLoadingList(false);

        }
        else {
          SetLoadingList(false);

        }
      })
      .catch(() => {
        SetLoadingList(false);

      });
  }



  const get_List_without_filters = () => {
    const token = localStorage.getItem('x-access-token');
    SetLoadingList(true);
    SetFilter(false);
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/interview/questions?page=1&limit=1000`;
    if(bankType){
      url = url + "&bankType=" + bankType;
    }
    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setQuestionsList(res.data);
          setStaticData(staticData + 10);
          SetLoadingList(false);
          SetFilter(false);

        }
        else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
          SetLoadingList(false);
          SetFilter(false);

        }
        else {
          SetLoadingList(false);
          SetFilter(false);

        }
      })
      .catch(() => {
        SetLoadingList(false);
        SetFilter(false);

      });
  }


  const get_parent_categories = (values) => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/categories?page=1&limit=1000&bankType=${bankType}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(values)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setParentCategories(res.data);
        }
        else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
        else {
        }
      })
      .catch(() => {
      });
  }

  const get_fitments_list = () => {
        const token = localStorage.getItem('x-access-token');
        fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/fitments?page=1&limit=10000`, {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'x-access-token': token
            },
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.status == true) {
                    setFitmentsList(res.data);
                    setSt(res.data)
                }
                else if (res.sessionExpired) {
                    alertService.alert({ message: res.message, type: 'error' });
                    logoutMethod();
                }
                else {
                }
            })
            .catch(() => {
            });
    }


  useEffect(() => {
    let mounted = true;
    // get_Question_List();
      get_fitments_list();
    get_parent_categories();
    get_Job_Requestion_List();
    return () => mounted = false;
  }, [])

    useEffect(() => {
        get_parent_categories(); // This is be executed when `loading` state changes
    }, [bankType])
  
    useEffect(() => {
     console.log(formData) // This is be executed when `loading` state changes
  }, [formData])

  const classes = useStyles();
  const theme = useTheme();



  const[ questionID,SetQuestionId] = useState("");
  const [QuestionData,setQuestionData]= useState({})

  const setSt = (req) => {
    if(req && req.length) {
      var options = [];
      req.forEach(element => {
        options.push(
          { value: element.id, label: element.parameterName}
        )
      });
      setColourOptions(options);
    }
  }

  const actionControls = {
    onClick: (e, row, rowIndex) => {
      if (row) {
        let id = row.id;
        if (e.target.classList.contains("view-option")) {
          SetQuestionId(row.id);
          setQuestionData(row);
          handleClickOpen();
        }
        else if(e.target.classList.contains("delete-option")) {
           SetDelId(row.id);
           SetModalShow3(true);
           SetRowIndex(rowIndex);
        }
      }
    }
  };

  const handleClickOpen1 = (row) => {
    SetQuestionId(row.id);
    setQuestionData(row);
    setOpen(true);
  };
  const handleRemoveQues = (ques,index) =>{

          SetDelId(ques.id);
           SetModalShow3(true);
           SetRowIndex(index);
           showCreatePage()
           showPreviewPage()
  }

  const[rowindexvalue,SetRowIndex] = useState();

  const deletethisques = (delId) => {
    const newtestQuestions = [...testQuestions ];
    var deletedquestionIndex =  newtestQuestions.findIndex(obj => 
       obj.id === delId // just pick this one
    )
//    var deletedquestion =  newtestQuestions.filter(obj,i => {
   //   return obj.id === delId // just pick this one
  //  })
    var deletedquestion = newtestQuestions.splice(deletedquestionIndex,1);


    // readd deleted question in mainlist
    QuestionsList.push(deletedquestion[0]);
    QuestionsList.map(x => x);
    var selectedQuestions = formData.questions.filter(x=> x.questionId == deletedquestion.id);
    SetFormData({
       ...formData,
      questions: selectedQuestions
    });


    //  updated question after deletation
    var updatedtestquestion = newtestQuestions.map(ele=> {
      return { questionId: ele.id ,answerTime:ele.answerDuration}
    });
    SetAddedQuestionList(updatedtestquestion);

    setTestQuestions(newtestQuestions)
    var TimeinSeconds = newtestQuestions.map(x => x.answerDuration).reduce((a, b) => parseInt(a) + parseInt(b), 0)
    var formattedTime =  parseToHHMMSS(TimeinSeconds);
    SetTotalAnswerTime(formattedTime);
    SetModalShow3(false);
  }


  function actionFormatter(column, colIndex) {
    return (
      <div className="text-left d-flex">
        <img src="/media/VRekruitIcons/eyeorview.svg" className="view-option c-p view-eye-ico-1" title="View" alt="View" />
      </div>
    );
  }


  function actionFormatterTestQuestion(column, colIndex) {
    return (
      <div className="text-left d-flex">
        <img src="/media/VRekruitIcons/eyeorview.svg" className="view-option c-p view-eye-ico-1" title="View" alt="View" />

        <img src="/media/VRekruitIcons/delete.svg" className="delete-option c-p view-eye-ico ml-5" title="Delete" alt="Delete"  />

      </div>
    );
  }


  function CreatedByUserFormater(column, colIndex) {
    return (
      <span>
        {column.firstName}  {column.lastName}
      </span>
    );
  }

  function CreatedByUserFormaterTest(columnsTestQustionData, colIndex) {
    return (
      <span>
        {columnsTestQustionData.firstName}  {columnsTestQustionData.lastName}
      </span>
    );
  }

  function headerformatter(column, colIndex) {
    return (
      <div>
        {column.text ? column.text: ""}
        <img src="/media/VRekruitIcons/sort.svg" className="sort-icon c-p" width="30" height="30" />
      </div>
    );
  }

  function headerformatterAction(column, colIndex) {
    return (
      <div>
        Action
      </div>
    );
  }

  const get_sub_categories = (parentId) => {
    if (parentId) {
      const token = localStorage.getItem('x-access-token');
      fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/sub_categories?page=1&limit=1000&parentCategoryId=` + parentId, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'x-access-token': token
        },
        // body: JSON.stringify(values)
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status == true) {
            setSubCategories(res.data);
          }
          else if(res.sessionExpired){
            alertService.alert({ message :res.message, type : 'error'  });
            logoutMethod();
          }

        })
        .catch(() => {
        });
    }
    else {
      setSubCategories([]);
    }
  }

  const history = useHistory();
  const[loading,SetLoading]=useState(false);
    const Save_Interview = (e) => {
    setFormSubmmmited(true);

    if(!(formData.interviewTitle && formData.interviewTitle.replace(/\s/g, '').length > 0))
    {
         return  "";
    }


    if(addedQuestionList.length > 0){

  var TimeinSeconds = testQuestions.map(x => x.answerDuration).reduce((a, b) => parseInt(a) + parseInt(b), 0)
  if(TimeinSeconds <= 5400){
   const reqdata = {
    interviewTitle: formData.interviewTitle,
       fitments: fitmentsListIds,
    questions:  addedQuestionList
   }


    e.preventDefault();
    const token = localStorage.getItem('x-access-token');
    SetLoading(true);
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/create`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(reqdata)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {

          alertService.alert({ message: res.message, type: 'info' });
          SetLoading(false);
          history.push('/interview/manage-interview');
        }else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
          SetLoading(false);
        }
        else {
          if (res.errors) {
            var errmsg = "";
            res.errors.forEach(function (value, index, array) {
              errmsg = errmsg + "(" + (index + 1) + ") " + value.msg + "\n\r";
            });
            alertService.alert({ message: errmsg, type: 'error' });
            SetLoading(false);
          }

          else {
            alertService.alert({ message: res.message, type: 'error' });
            SetLoading(false);
          }
        }

      })
      .catch(() => {
        SetLoading(false);
      });
    }
     else{
      alertService.alert({ message : "Max Allowed  Answer Duration is 90 Minutes", type:'warning'});
      SetLoading(false);
     }
    }
    else {
      alertService.alert({ message: "Please Add Question First to Create Interview", type: 'error' });
      SetLoading(false);
    }
  }


const [ isformSubmitted,setFormSubmmmited]=useState(false)

   const [loadingshare,SetLoadingshare]=useState(false);
  const save_share_test = (e) => {

    setFormSubmmmited(true);
    if(!(formData.interviewTitle && formData.interviewTitle.replace(/\s/g, '').length > 0))
    {
         return  "";
    }

    if(addedQuestionList.length > 0){
      var TimeinSeconds = testQuestions.map(x => x.answerDuration).reduce((a, b) => parseInt(a) + parseInt(b), 0)
      if(TimeinSeconds <= 5400)
    {
    const reqdata = {
      interviewTitle: formData.interviewTitle,
        fitments: fitmentsListIds,
      questions:  addedQuestionList
     }

    const token = localStorage.getItem('x-access-token');
    SetLoadingshare(true);
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/create`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(reqdata)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {

          alertService.alert({ message: res.message, type: 'info' });
          SetLoadingshare(false);
          history.push('/interview/share-interview/'+ res.data.id);
        }else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
          SetLoadingshare(false);
        }
        else {
          if (res.errors) {
            var errmsg = "";
            res.errors.forEach(function (value, index, array) {
              errmsg = errmsg + "(" + (index + 1) + ") " + value.msg + "\n\r";

            });
            alertService.alert({ message: errmsg, type: 'error' });
            SetLoadingshare(false);

          }

          else {
            alertService.alert({ message: res.message, type: 'error' });
            SetLoadingshare(false);

          }
        }

      })
      .catch(() => {
        SetLoadingshare(false);


      });
    }
       else{
        alertService.alert({ message : "Max Allowed  Answer Duration is 90 Minutes", type:'warning'});
        SetLoadingshare(false);

       }

    }

      else {
        alertService.alert({ message: "Please Add Question First to Create Interview", type: 'error' });
        SetLoadingshare(false);
      }


  }

  
  

  const setSelectedValue = (value) => {
    if (formData.questions.find(x => x.questionId == value.id)) {
      formData.questions.splice(formData.questions.findIndex(x => x.questionId == value.id), 1)
    }
    else {
      formData.questions.push({ questionId: value.id ,answerTime:value.answerDuration});
    }
    console.log(formData)
    SetFormData({
      ...formData,
      questions: formData.questions
    });
  }

  const settestName = (event) => {
    console.log(event.target.value)
    SetFormData({
      ...formData,
      interviewTitle: event.target.value
    })
    console.log(formData)
  }

  const [totalAnswerTime, SetTotalAnswerTime] = useState();
  const[ totalAnswerTimeINSeconds,SettotalAnswerTimeINSeconds] = useState();
  const [addedQuestionList,SetAddedQuestionList] = useState([]);


  function parseToHHMMSS(sec) {
    var sec_num = parseInt(sec, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return(hours === "00" ? "" :  hours +' hour ')
          + ( minutes === "00" ? "" :  minutes === "01" ? minutes  + ' minute ' : minutes  +' minutes ' )
          + ( seconds === "00" ? "" :  seconds === "01" ? seconds  + ' second ' : seconds  +' seconds ' )
    }



  const addQuestionsToTest = () => {


    setFormSubmmmited(true);

    if(!(formData.interviewTitle && formData.interviewTitle.replace(/\s/g, '').length > 0))
    {
         return  "";
    }

    else{

    var testQuestionsCount = testQuestions.length;
    var newtestQuestions = testQuestions.map(x=> x);
    var localQuestionsList = QuestionsList.map(x=> x);

    formData.questions.forEach(que => {
      var ind =  localQuestionsList.findIndex(x => x.id == que.questionId);
      if (ind > -1) {
      var element = localQuestionsList.splice(ind, 1);
      newtestQuestions.push(element[0]);
      }
      var updatedtestquestion = newtestQuestions.map(ele=> {
        return { questionId: ele.id ,answerTime:ele.answerDuration}
      });
      SetAddedQuestionList(updatedtestquestion);
    });

    var TimeinSeconds = newtestQuestions.map(x => x.answerDuration).reduce((a, b) => parseInt(a) + parseInt(b), 0)
    // if (TimeinSeconds <= 5400) {
      setTestQuestions(newtestQuestions);
      setQuestionsList(localQuestionsList.map(x=> x));
      SettotalAnswerTimeINSeconds(TimeinSeconds)
      var formattedTime =  parseToHHMMSS(TimeinSeconds);
      SetTotalAnswerTime(formattedTime);

      if(newtestQuestions.length > testQuestionsCount){
        alertService.alert({ message : "Questions Added Successfully", type : 'success'  });
      }
      else {
        alertService.alert({ message : "Please Select Questions First!", type : 'error'  });
      }
    // }
    // else {
    //   alertService.alert({ message : "Max Answer Duration Exceeded", type:'warning'});
    // }
  }
  }

  function selectCheckboxFormatter(column, colIndex) {
    var checked = false
    var exists = formData.questions.some(el => el.questionId === column)
    if(exists){
      checked = true
    }
    return (
      <div className="text-left">
        <Form.Check inline label="" className="ml-2 mt-2" defaultChecked={checked} value={column} name="questionId" onChange={() => setSelectedValue(colIndex)} />
      </div>
    );
  }


  function TimetoAnsweFormatter(column, colIndex) {
    var parsedTime = parseToHHMMSS(column)
    return (
      <>
         <span style={{"maxWidth":"100px"}}>
         {parsedTime}
         { /* {column +" "}  seconds */}
        <img src="/media/VRekruitIcons/clock.png" className="fa-pencil-alt c-p" />
        </span>
        </>
    );
  }


  function TimetoAnswePreviewScreenFormatter(column, colIndex) {
    var parsedTime = parseToHHMMSS(column)

    return (
      <>
         <span style={{"maxWidth":"100px"}}>
         {parsedTime}
      {/*  { column? column +" ":"" }  seconds */}
        </span>
        </>
    );
  }

  function selectallFormatter(column, colIndex) {
    return (
      <div className="text-left">
        {/* <Form.Check inline label="" className="ml-2 mt-2" /> */}
      </div>
    );
  }

  var columns = [
    {
      dataField: 'id',
      formatter: selectCheckboxFormatter,
      headerFormatter: selectallFormatter,
      editable: false
    },

    {
      dataField: 'question',
      text: "Question",
      sort: true,
      editable: false,
      headerFormatter: headerformatter
    },

    {
      dataField: 'interviewCategory.categoryName',
      text: "Parent Category",
      sort: true,
      editable: false,
      headerFormatter: headerformatter
    },
    {
      dataField: 'interviewSubCategory.subCategoryName',
      text: "Sub Category",
      sort: true,
      editable: false,
      headerFormatter: headerformatter
    },

    {
      dataField: 'answerDuration',
      text: "Time to Answer",
      sort: true,
      editable:false,
      formatter: TimetoAnswePreviewScreenFormatter,
      headerFormatter: headerformatter
    },

    {
      dataField: "action",
      text: "Action",
      sort: true,
      editable: false,
      formatter: actionFormatter,
      headerFormatter: headerformatterAction
    },
  ];


  var columnaddedquestion = [

    {
      dataField: 'question',
      text: "Question",
      sort: true,
      editable: false,
      headerFormatter: headerformatter
    },

    {
      dataField: 'interviewCategory.categoryName',
      text: "Parent Category",
      sort: true,
      editable: false,
      headerFormatter: headerformatter
    },
    {
      dataField: 'interviewSubCategory.subCategoryName',
      text: "Sub Category",
      editable: false,
      sort: true,
      headerFormatter: headerformatter
    },

    {
      dataField: 'answerDuration',
      text: "Time to Answer",
      sort: true,
      editable: true,
      formatter: TimetoAnsweFormatter,
      headerFormatter: headerformatter
    },

    {
      dataField: "action",
      text: "Action",
      editable: false,
      sort: true,
      formatter: actionFormatterTestQuestion,
      headerFormatter: headerformatterAction
    },
  ];




  const options = {
    paginationSize: 5,
    // custom: true,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    //  withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    rowReorder: true,
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,

    onPageChange: (page, sizePerPage) => {

    },
    disablePageTitle: true,
    sizePerPageList: [ {
      text: '20', value: 20
    },{
    text: '50', value: 50
    },
    {
      text: 'All',
    }] // A numeric array is also available. the purpose of above example is custom the text
  };

  const [showpage, setShowPage] = useState({
    showCreateTest: true,
    showPreviewTestPage: false,
  })



  const showCreatePage = () => {
    setShowPage({
      ...showpage,
      showCreateTest: true,
      showPreviewTestPage: false
    })
    QuestionsList.map(x => x);
  }

  const showPreviewPage = () => {
    SetDisablenexttab(true);
 if(formData.interviewTitle){

  const token = localStorage.getItem('x-access-token');
  var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/interview/check_interview_title?interviewTitle=${formData.interviewTitle}`;
  
  fetch( url, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'x-access-token': token
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status == true) {
        setShowPage({
          ...showpage,
          showCreateTest: false,
          showPreviewTestPage: true
        })

      }else if(res.sessionExpired){
        alertService.alert({ message :res.message, type : 'error'  });
        logoutMethod();
      }
      else {
        alertService.alert({ message :res.message, type : 'error'  });
        return false;
      }
    })
    .catch(() => {

    });

    
  }
  }
  const blockInvalidChar = e => ['e', 'E', '+', '-','.'].includes(e.key) && e.preventDefault();



  const [modalShow, setModalShow] = React.useState(false);



  const [modalShow3, SetModalShow3] = React.useState(false);
  const [modalShow4, SetModalShow4] = React.useState(false);
  const [jobRequesitonList, SetJobRequesitonList] = React.useState([]);
  const [selectedRequisition, SetSelectedRequisition] = React.useState('');
  const [requisitionDetail, SetRequisitionDetail] = React.useState('');
  const [ colourOptions,  setColourOptions]=  useState([]);


  
  const[delId, SetDelId]= useState();

  const get_Job_Requestion_List = () => {
    const token = localStorage.getItem('x-access-token');
    var roleId  = localStorage.getItem("userTypeId");
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/company/requisitions?page=1&limit=1000&orderBy=id&orderType=ASC`;
    if(roleId ==="3" || roleId ==="4" ) {
      url = url + "&assignedToMe=true&createdByMe=true" 
    }

    fetch( url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          SetJobRequesitonList(res.data);

        }else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
        else {

        }
      })
      .catch(() => {

      });
}

const get_Job_Requestion_Detail = (RequisitionId) => {
  const token = localStorage.getItem('x-access-token');
  var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/company/get_requisition?id=${RequisitionId}`;
  
  fetch( url, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'x-access-token': token
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status == true) {
        SetRequisitionDetail(res.data);

      }else if(res.sessionExpired){
        alertService.alert({ message :res.message, type : 'error'  });
        logoutMethod();
      }
      else {

      }
    })
    .catch(() => {

    });
}

  const showRequestionPopup = () => {
    SetModalShow4(true)
  }

  const handleChangeRequestion = (e) => {
    SetSelectedRequisition(e.target.value)
    get_Job_Requestion_Detail(e.target.value)
  }
  

  function DeleteConfirmationModal(props) {
    return (
    <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >

        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            <p className="text-center">
            <img alt="logo" className="brand-dim" src="/media/logos/UPDATED_VRekruit _Logo.png" />
              </p>
            <p className="text-dark text-center pt-3"> Are you sure want to remove  this question?</p>
          </Modal.Title>
        </Modal.Body>
        <Modal.Title className="d-flex justify-content-center align-items-center pb-5" >
        <p className="text-center">

        <Button className="btn btn-theme text-white btn-lg mr-5  pl-5 pr-5 pt-3 pb-3" style={{"minWidth": "100px"}} onClick={() => deletethisques(delId)}> Yes </Button>
       <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green" style={{"minWidth": "100px"}}  onClick={props.onHide}>No</Button>
       </p>
        </Modal.Title>
      </Modal>
    );
  }

  


  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('lg');


  function CustomizedDialogs() {
    return (
      <div>

        <Dialog
        onClose={handleClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        fullScreen={fullScreen}
        aria-labelledby="customized-dialog-title"
        open={open}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          </DialogTitle>
          <DialogContent >
            <Typography gutterBottom>
            <Row className="pt-5" >
              <Form.Label className="fw-600" column sm="2">
                Question Text
              </Form.Label>
              <Col sm="10">
                <Form.Group as={Col}  >
                  <p className="mt-2 css-p-tag" >
                        {QuestionData ? QuestionData.question: "" }
                    </p>
                </Form.Group>
              </Col>
            </Row>
            </Typography>
            <Typography gutterBottom>
            <Row className="pt-5" >
              <Form.Label className="fw-600" column sm="2">
                Question Video
              </Form.Label>
              <Col sm="10">
                <Form.Group as={Col}  >
                   <video className="video-container video-container-overlay modal-video-player" autoPlay="false" loop muted={false}
                   controls
                   poster={QuestionData.videoQuestion ? '' : '/media/bg/audio.gif'}
                   src={QuestionData ? QuestionData.videoPath: "" }
                   preload="metadata"
                   >                   
                  </video>
                </Form.Group>
              </Col>
            </Row>
            </Typography>
            <Typography gutterBottom>
            <Row className="pt-5" >
              <Form.Label className="fw-600" column sm="2">
                Keywords
              </Form.Label>
              <Col sm="10">
                <Form.Group as={Col}  >
                <p className="mt-2 css-p-tag" >
                {QuestionData ? QuestionData.keywords: "" }
                  </p>
                </Form.Group>
              </Col>
            </Row>
            </Typography>
          </DialogContent>

        </Dialog>
      </div>
    );
  }

  const style = {
    borderBottom: '2px solid #EBEDF3',
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: 'move',
};

const headingStyle = {
  fontWeight: 600,
  fontSize: '1rem',
  borderBottomWidth: '1px',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  color: '#003577',
  fontColor: '#003577'
};
const [editTimeQuesId, setEditTimeQuesId] = useState('')
const [showTimeUpdate, setShowTimeUpdate] = useState(false)


const showEditQuesTime = (testQuestion) => {
  setEditTimeQuesId(testQuestion.id)
  setShowTimeUpdate(true)
}

const updateQuesAnsTime = (event) => {
  const newValue = event.target.value
  console.log()
  var inputId = event.target.id.replace('edit-time-', "");
  console.log(newValue)
  console.log(testQuestions)
  setTimeout(() => {
    if( parseInt(newValue) > 0 &&  parseInt(newValue) <  5401 && Number.isInteger(parseInt(newValue)) &&   newValue % 1 === 0  ){
  //  done(true); // contine to save the changes
      var updatedtestquestion1 = testQuestions.map(x => (x.id == inputId ? { ...x, answerDuration: newValue } : x));
      console.log(updatedtestquestion1)
      SetAddedQuestionList(updatedtestquestion1);
      setTestQuestions(updatedtestquestion1)
      
  //    var updatedtestCards = cards.map(x => (x.id == inputId ? { ...x, answerDuration: newValue } : x));
   //   setCards(updatedtestCards);
      var updatedtestquestion = testQuestions.map(ele=> {
      return { questionId: ele.id ,answerTime:ele.answerDuration}
    });
    SetAddedQuestionList(updatedtestquestion);
    
    var TimeinSeconds = testQuestions.map(x => x.answerDuration).reduce((a, b) => parseInt(a) + parseInt(b), 0)
    var formattedTime =  parseToHHMMSS(TimeinSeconds);
       SetTotalAnswerTime(formattedTime);
       setShowTimeUpdate(false)
  }
     else {
    //  done(false); // reject the changes
      if(newValue < 5401){
        alertService.alert({ message : "Time to answer should be more than 0 seconds", type : 'warning'  });

      }
      else {
        alertService.alert({ message : "Time to answer should not be more than 5400 seconds", type : 'warning'  });
      }                    }
  }, 0);

}

const [disablenexttab,SetDisablenexttab]=useState(false);

  return (
    <>
      <Helmet>
        <title>
          Create Interview
        </title>
      </Helmet>


      {/* subeader */}
      <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Interview Management</h3></div>
          </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
      {/* subeader */}



      {/*Start  addQuestionSection */}
      { showpage.showCreateTest ? (
        <Col className={classes.root} >
          <Row className="bg-white  d-flex flex-grow-1 align-items-center justify-content-start sticky-header">
            <Col md={4} sm={4} offset={4} className="pr-3 pl-3 mt-3">
              <div>
                <div className="   rounded">
                  <div>
                    <h5 className="font-weight-bold f-w-600 pl-5" > Create Interview </h5>
                    <p className="text-b f-w-600 pl-5 ">
                    {/* <img width="24" src="/media/VRekruitIcons/costblue.svg" alt="" className="flaticon2-profile text-success bg-cost mr-3"/> */}

                    Credit Balance: V {localStorage?.getItem("companyCreditBalance")   }</p>

                  </div>
                </div>
              </div>
            </Col>
            <Col md={8} sm={8} offset={4} className="pr-3 pl-3 mt-3">
              <div>
                <div className="d-flex flex-grow-1 align-items-center justify-content-end rounded mt-1">
                  <div className="text-muted d-block">
                  <Button type="button" className="btn btn-theme-green btn-lg mr-5 mt-2 mb-0"
                       onClick={showRequestionPopup}>
                      View Requisition
                    </Button>
                    <Link to="#" onClick={showPreviewPage} type="button"
                    className={`btn btn-theme-green btn-lg mr-5 mt-2 mb-0 ${addedQuestionList.length==0  ? "p-none" : ""}` }>
                      Preview Interview
                    </Link>
                    <Button type="button" className={`btn btn-theme-green btn-lg mr-5 mt-2 mb-0 ${formData.questions.length==0 ? "p-none" : ""}`}
                       onClick={addQuestionsToTest}>
                      Add Question
                    </Button>
                    <Link to="/interview/manage-interview" type="button" className="btn btn-theme text-white btn-lg mr-5 mt-2 mb-0"  >
                      Cancel
                </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5"></Col>
          </Row>

          <Col className="Content-custom-padding mt-5">
            <Row >
              <Form.Label className="fw-600" column sm="2">
                Interview Name* <BlueOnGreenTooltip title="Please use Interview Name matching with the Job Requisition.  Ensure Interview Round No. is mentioned in Suffix for future reference" placement="top">
                <i className='fa fa-info text-primary bd-primary' style={{'font-size':'smaller'}}></i></BlueOnGreenTooltip>
                    </Form.Label>
              <Col sm="7" className="ml-0 pl-0" >
                <Form.Group as={Col} >
                  <Form.Control type="text"
                    name="interviewTitle"
                    value={formData.interviewTitle}
                    onChange={(e) => settestName(e)}

                  >
                  </Form.Control>
                  {isformSubmitted && !formData.interviewTitle ?
                     <div className="fv-plugins-message-container" >
                <div className="fv-help-block">Required</div>
              </div>
               : null}

                 {isformSubmitted && formData.interviewTitle && !formData.interviewTitle.replace(/\s/g, '').length    ?
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block pl-3">Required</div>
                  </div>
                  :null}


                  {!isformSubmitted && disablenexttab && !formData.interviewTitle ?
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block pl-3">Required</div>
                  </div>
                  :null}
                </Form.Group>
              </Col>
                      </Row>
                      <Row className="pt-5"  >
                          <Form.Label className="fw-600" column sm="2">
                              Select Fitment Parameter
              </Form.Label>
              <Col sm="7" className="ml-0 pl-0" >
              <Form.Group as={Col}  >
                          <Select closeMenuOnSelect={false} components={animatedComponents} defaultValue={selectedFitments} isMulti options={colourOptions}
           onChange={handleMultipeInput} />
                              
                                  

                              </Form.Group>
                              </Col>
                          

                      </Row>


            {/* <Row>
              <Form.Label  className="fw-600"  column sm="2">
                Total Answer Time
                    </Form.Label>
              <Col sm="7" className="ml-0 pl-0" >
                <Form.Group as={Col} >
                  <Form.Control type="text"
                    name="" value={totalAnswerTime ? totalAnswerTime   : ""}
                    readOnly>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row> */}

            <Row as={Row} controlid="formaBankType"
              style={{ "display": "flex", "alignItems": "baseline" }}>
              <Form.Label className="fw-600" column sm="2">
                Select Interview Question
                    </Form.Label>
              <Col sm="5">
                <Form.Group as={Col}  >
                  {['radio'].map((type) => (
                    <div key={`inline-${type}`} className="mb-3 ml-0" style={{ "display": "flex", "justifyContent": "space-between", "width": "100%" }}>
                      <Form.Check inline label="Private Question Bank" className="pr-3-inp" name="bankType" value="private" type={type} id={`inline-${type}-1`}
                       onChange={() => handleOptionChange("private")}
                       checked={ bankType === "private" ? true : false}
                      />
                      <Form.Check inline label="Public Question Bank" className="pr-3-inp" name="bankType" value="public" type={type} id={`inline-${type}-2`}
                       onChange={() => handleOptionChange("public")}
                       checked={ bankType === "public" ? true : false}
                      />

                    </div>
                  ))}
                </Form.Group>
              </Col>
            </Row>

            <Row className="align-items-center mt-0">
              <Col sm={4} md={3} className="mb-2">
                <Form.Control as="select" placeholder="Status" value={categorySelected} onChange={(e) => handleCategorySelect(e)} >
                  <option value="">Parent Category</option>
                  {parentCategories.map
                    (x => <option value={x.id}>{x.categoryName}</option>)
                  }
                </Form.Control>
              </Col>

              <Col sm={4} md={3} className="mb-2">
                <Form.Control as="select" placeholder="-Sub Category-" value={subcategorySelected} onChange={(e) => handleSubCategorySelect(e)} >
                  <option value="">Sub Category</option>
                  {subCategories.map
                    (x => <option value={x.id}>{x.subCategoryName}</option>)
                  }
                </Form.Control>
              </Col>

              <Col sm={4} md={4} className="d-flex mb-2">
                <div style={{ "position": "relative" }}>
                  <div className="input-group">
                    <Form.Control type="text" value={searchTerm ? searchTerm : ""} placeholder="Search..." id="search-text" onInput={(e) => SetSearchTermData(e)} style={{ "paddingLeft": "35px", }} />
                    <div className="input-group-prepend">
                    </div>
                  </div>
                  <i className="fa fa-search" style={{ "position": "absolute", "left": "7px", "top": "50%", "transform": "translate(7px, -50%)", "borderRadius": "8px" }} ></i>
                </div>
              </Col>

              <Col sm={4} md={2}  className="mb-2 text-right">
                <Button type="button" className="btn btn-search ml-5 mb-sm-2" id="filter-btn" onClick={get_Question_List} style={{ "borderRadius": "8px" }} >
                  Apply
              </Button>

              <Button type="button" onClick={resetFilter} className="btn btn-reset ml-5   mb-sm-2 btn btn-primary" id="filter-btn"  style={{ "borderRadius": "8px" }} >
                  Reset
              </Button>
              </Col>

            </Row>
            <div>
            {QuestionsList &&  QuestionsList.length > 20 &&  QuestionsList.length > 0  && (
              <BootstrapTable

              className="bg-white" keyField='id' data={QuestionsList} columns={columns} options={options} pagination={paginationFactory(options)} rowEvents={actionControls} />
            )}
           {QuestionsList &&  QuestionsList.length === 0  && (
             <>
              <BootstrapTable

              className="bg-white" keyField='id' data={QuestionsList} columns={columns} options={options}  rowEvents={actionControls} />

            {!loadingList ?
             <p className="text-center mt-5 pt-5" style={{"fontSize":"30px","minHeight":"200px","display":"flex","justifyContent":"center","alignItems":"center"}}>

                 {!filter ?
                <small>
                    Please select the above filters {`&`}
                    <br/>
                    click on apply button.
                </small>
                :""}


                {filter?
                <small>
                   No Records Found!
                </small>
                :""
                }
             </p>
             :""}


             {loadingList ?
            <p className="text-center mt-5 pt-5" style={{"fontSize":"30px","minHeight":"200px","display":"flex","justifyContent":"center","alignItems":"center"}}>
              <ele id="no-data">
                 <i className="fas fa-spinner fa-pulse fa-2x"></i>
              </ele>
            </p>
             :""}

              </>
           )}
            {QuestionsList  && QuestionsList.length <= 20 && QuestionsList.length > 0 &&
            (
              <BootstrapTable
              className="bg-white" keyField='id' data={QuestionsList} columns={columns} options={options}  rowEvents={actionControls} />
            )
            }
          </div>
          </Col>
        </Col>
      ) : ""}
      {/*End addQuestionSection */}







      {/*Start previewTestSection */}
      { showpage.showPreviewTestPage ? (
        <Col className={classes.root} >
          <Row className="bg-white  d-flex flex-grow-1 align-items-center  justify-content-start sticky-header">
            <Col md={6} sm={6} offset={4} className="pr-3 pl-3 mt-3">
              <div>
                <div className="   rounded">
                  <div>
                    <h5 className="font-weight-bold f-w-600 pl-5" > Create Interview
                    {/* &nbsp;&nbsp; {'>'} &nbsp;&nbsp; Preview Interview   */}


                    </h5>

                    <p className="text-b f-w-600 pl-5">Credit Balance: V {localStorage?.getItem("companyCreditBalance")   }</p>

                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} sm={6} offset={4} className="pr-3 pl-3 mt-3">
              <div>
                <div className="d-flex flex-grow-1 align-items-center justify-content-end rounded mt-1">
                  <div className="text-muted d-block">
                    <Button onClick={Save_Interview} type="button"
                     className={`btn btn-theme-green btn-lg mr-5 mt-2 mb-0 ${addedQuestionList.length==0  ? "p-none" : ""}` }
                    disabled={loading}
                    >
                      Save
                      {loading && <span className="mr-5 ml-2 spinner spinner-white"></span>}
                    </Button>

                    {localStorage.getItem("userTypeId") !== "1"  ?
                    <Button  onClick={(e) => save_share_test(e)} type="button"
                      className={`btn btn-theme-green btn-lg mr-5 mt-2 mb-0 ${addedQuestionList.length==0  ? "p-none" : ""}` }
                      disabled={loadingshare}
                      >
                      Save & Share
                      {loadingshare && <span className="mr-5 ml-2 spinner spinner-white"></span>}

                    </Button>
                     :""}

                    <Link to="#" onClick={showCreatePage} type="button" className="btn btn-theme text-white btn-lg mr-5 mt-2 mb-0"  >
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5"></Col>
          </Row>
          <Col className="Content-custom-padding mt-5 mb-0 mb-sm-5">
            <Row >
              <Form.Label className="fw-600" column sm="2">
                 Interview Name <BlueOnGreenTooltip title="Please use Interview Name matching with the Job Requisition.  Ensure Interview Round No. is mentioned in Suffix for future reference" placement="top">
                <i className='fa fa-info text-primary bd-primary' style={{'font-size':'smaller'}}></i></BlueOnGreenTooltip>
                </Form.Label>
              <Col sm="7" className="ml-0 pl-0" >
                <Form.Group as={Col} >
                  <Form.Control type="text"
                    name="testTitle"
                    value={formData.interviewTitle}
                    onChange={(e) => settestName(e)}
                  >
                  </Form.Control>
                  {isformSubmitted && !formData.interviewTitle ?
                     <div className="fv-plugins-message-container" >
                  <div className="fv-help-block">Required</div>
                  </div>
               : null}

                  {isformSubmitted && formData.interviewTitle && !formData.interviewTitle.replace(/\s/g, '').length    ?
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block pl-3">Required</div>
                  </div>
                  :null}
                </Form.Group>
              </Col>
              <Col className="text-right mt-2">
                <Link to="#" onClick={showCreatePage} className="c-p d-inline text-theme-green"><img src="/media/VRekruitIcons/addmore.svg" className="c-p mr-3" width="24" />Add More Questions</Link>
              </Col>
            </Row>
            <Row className="pt-5"  >
                          <Form.Label className="fw-600" column sm="2">
                              Select Fitment Parameter
              </Form.Label>
              <Col sm="7" className="ml-0 pl-0" >
              <Form.Group as={Col}  >
                          <Select closeMenuOnSelect={false} components={animatedComponents} value={selectedFitments} isMulti options={colourOptions}
           onChange={handleMultipeInput} />
                              
                                  

                              </Form.Group>
                              </Col>
                          

                      </Row>

            <Row>
              <Form.Label column sm="2"  className="fw-600 " >
                Total Answer Time
              </Form.Label>
              <Col sm="7" className="ml-0 pl-0" >
                <Form.Group as={Col} >
                  <Form.Control type="text"
                   className="no-drop"
                    name="" value={totalAnswerTime ? totalAnswerTime   : ""}
                    readOnly>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <div>
           
           {testQuestions &&  testQuestions.length === 0  && (
             <>
              <BootstrapTable
              cellEdit={ cellEditFactory({
                beforeSaveCell:(oldValue, newValue, row, column, done) => {
                  setTimeout(() => {
                    if( parseInt(newValue) > 0 &&  parseInt(newValue) <  5401 && Number.isInteger(parseInt(newValue)) &&   newValue % 1 === 0  ){
                    done(true); // contine to save the changes
                    var updatedtestquestion = testQuestions.map(ele=> {
                      return { questionId: ele.id ,answerTime:ele.answerDuration}
                    });
                    SetAddedQuestionList(updatedtestquestion);
                    var TimeinSeconds = testQuestions.map(x => x.answerDuration).reduce((a, b) => parseInt(a) + parseInt(b), 0)
                    var formattedTime =  parseToHHMMSS(TimeinSeconds);
                       SetTotalAnswerTime(formattedTime);
                  }
                     else {
                      done(false); // reject the changes
                      if(newValue < 5401){
                        alertService.alert({ message : "Time to answer should be more than 0 seconds", type : 'warning'  });

                      }
                      else {
                        alertService.alert({ message : "Time to answer should not be more than 5400 seconds", type : 'warning'  });
                      }                    }
                  }, 0);
                  return { async: true };
                },
                mode: 'click',
                blurToSave: true,
                onKeyDown:{blockInvalidChar}
            }) }
              className="bg-white" keyField='id' data={testQuestions} columns={columnaddedquestion} options={options}  rowEvents={actionControls} />

             <p className="text-center mt-5 pt-5" style={{"fontSize":"30px","minHeight":"200px","display":"flex","justifyContent":"center","alignItems":"center"}}>
                No Records Found
             </p>


              </>
           )}
            {testQuestions  && testQuestions.length > 0 &&

            (
            <>
            


      <SortableContainer onSortEnd={onSortEnd} helperClass="sortable-helper" distance={1} lockAxis="y">
        {testQuestions.map((testQuestion, index) => (
          <SortableItem key={`item-${testQuestion.id}-${index}`} index={index} testQuestion={testQuestion} handleRemoveQues={handleRemoveQues} handleClickOpen1={handleClickOpen1} showEditQuesTime={showEditQuesTime} showTimeUpdate={showTimeUpdate} editTimeQuesId={editTimeQuesId} updateQuesAnsTime={updateQuesAnsTime} parseToHHMMSS={parseToHHMMSS} />
        ))}
      </SortableContainer>
   
              </>
            )
            }
          </div>
          </Col>
        </Col>
      ) : ""}

      {/*End previewTestSection */}

      <DeleteConfirmationModal show={modalShow3} onHide={() => SetModalShow3(false)} />

     
    <Modal  show={modalShow4}
      
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='modal-dialog-scrollable'
      >

        <Modal.Body>
      { /*  <Row className="">
            <Form.Label className="fw-600" column="md" lg={3}>
              Job Requisition*
            </Form.Label>
            <Col md={6}>
              <Form.Control as="select" name="requisitionId" size="sm" value={selectedRequisition} onChange={(e) => handleChangeRequestion(e)} >
                <option value=""> Select Job Requisition</option>  
                {jobRequesitonList && jobRequesitonList.map
                  (x => <option value={x.id}>{x.requisitionId}   {x.position}</option>)
                }
              </Form.Control>
                      
            </Col>
              </Row> */}
          <Row className="mt-5">
          <Col md={12} sm={12} offset={4} className="mt-5"  >
            <div className="bg--tile rounded">
  
                    
                    <div className="text-muted d-flex align-items-top pt-4 pl-4   mt-0 mb-0 ">
                      <div className='d-flex'>
                    <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                      <img className="d-inline" width="25" src="/media/VRekruitIcons/Job Title.svg" />
                    </div>
                    <div className='d-block' style={{ width: '150px' }}>
                      <h6 className="text-dark">Job Requisition*</h6>
                      </div>
                      </div>
                      <div className='mb-5 w-50'>
                      <Form.Control as="select" name="requisitionId" size="md" value={selectedRequisition} onChange={(e) => handleChangeRequestion(e)} >
                <option value=""> Select Job Requisition</option>  
                {jobRequesitonList && jobRequesitonList.map
                  (x => <option value={x.id}>{x.requisitionId}   {x.position}</option>)
                }
              </Form.Control>
              </div>
              </div>

                  </div>
           
                
              </Col>
          <Col md={12} sm={12} offset={4} className="mt-5"  >
            <div className="bg--tile rounded">
                  <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4   mt-0 mb-0">
                    <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                      <img className="d-inline" width="25" src="/media/VRekruitIcons/Job Title.svg" />
                    </div>
                    <div className="text-muted d-block">
                      <h6 className="text-dark">Job Title</h6>
                      <p className="text-dark mt-0">{requisitionDetail ? requisitionDetail.position : ''}</p>
                    </div>

                  </div>
           
                </div>
              </Col>

              <Col md={12} sm={12} offset={4} className="pb-5 mt-3"  >
                <div className="bg--tile rounded">
               
                  <div className="d-flex flex-grow-1 min-vh-70 align-items-top mt-5 pt-4 pl-4" >
                    <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                      <img className="d-inline" width="25" src="/media/VRekruitIcons/job-description.svg" />
                    </div>
                    <div className="text-muted d-block">
                      <h6 className="text-dark">Job Description</h6>
                      {/* <p className="text-dark mt-0">Account Manager  Job Responsbility</p>    */}
                      {requisitionDetail ? 
                      <div dangerouslySetInnerHTML={{__html: requisitionDetail.jobDescription}} />
                      : ''
                      }
                      

                    </div>
                  </div>
                </div>
              </Col>
          </Row>
        </Modal.Body>
        <Modal.Title className="d-flex justify-content-center align-items-center pb-5" >
        <p className="text-center">

       <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green" style={{"minWidth": "100px"}}  onClick={() => SetModalShow4(false)}>Close</Button>
       </p>
        </Modal.Title>
      </Modal>
    

      <CustomizedDialogs></CustomizedDialogs>

    </>
  );
}

export default CreateInterview
