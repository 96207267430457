/* eslint-disable no-restricted-imports */
/* jshint asi:true */


import React, { useState, useEffect } from "react";
import  { useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Link, Redirect } from "react-router-dom";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormFile from 'react-bootstrap/FormFile'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import alertService from '../../../_services/alertservice';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import VideoRecorder from 'react-video-recorder'
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import EditorFormatListBulleted from "material-ui/svg-icons/editor/format-list-bulleted";
import { useDispatch } from 'react-redux';
import { logout } from '../../../../actions/auth';
import FileUploadProgress  from 'react-fileupload-progress';
import { isAndroid, isIOS,isSafari } from "react-device-detect";



const animatedComponents = makeAnimated();




const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


{/* <tab style css statrt> */ }
function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}
TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

}));

{/* <tab style css statrt> */ }


function InterviewAddQuestion(props) {
  const autoC = useRef(null);
  const autoC1 = useRef(null);
  const [formData, SetFormData] = useState(
    {
      question: "",
      categoryId: "",
      subCategoryId: "",
      videoPath: "",
      videoName: "",
      answerDuration: "",
      keywords: []
    }
  )



  
  const blockInvalidChar = e => [
    'E', 
    '+', 
    '-',
    '.',  
    "\\",
    ".",
    "+",
    "*",
    "?",
    "[",
    "^",
    "]",
    "$",
    "(",
    ")",
    "{",
    "}",
    "=",
    "!",
    "<",
    ">",
    "|",
    ":",
    "&",
    "#",
    "_",
    "%",
    "@",
    "'",
    ";",
    "-"].includes(e.key) && e.preventDefault();

const blockemoji = () => {
  var elekey = document.getElementById("keyword-ID");  
  elekey.addEventListener( "input", event => {
  
    elekey.value = elekey.value.replace( /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g, '');
    elekey.value = elekey.value.replace(/['"]/g, "")

  }, false);
   
   elekey.addEventListener( "paste", event => {
       elekey.value = elekey.value.replace(/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g, '');
       elekey.value = elekey.value.replace(/['"]/g, "")
   }, false);

  //  blockInvalidChar();
  


}











  const blockSpaceChar = e => [' '].includes(e.key) && e.preventDefault();



  const handleFormInput = (e) => {
    if (e && e.target.name === 'answers') {
      var index = parseInt(e.target.getAttribute('data-index'));
      var name = e.target.getAttribute('data-name');
      if (name === "rightAnswer") {
        formData.answers.forEach(element => {
          element.rightAnswer = false;
        });
        if (e.target.value == "on") {
          formData.answers[index][name] = true;
        }
        else {
          formData.answers[index][name] = false;
        }
      }
      else {
        formData.answers[index][name] = e.target.value;
      }
      SetFormData({ ...formData });
    }
    else {
      SetFormData({
        ...formData,
        [e.target.name]: e.target.value
      })
    }
  }


  const dispatch = useDispatch();
  const logoutMethod = () => {
    dispatch(logout());
    history.push('/auth/signin');
};

 const [ isFormSubmitted, SetFormSubmitted]= useState(false);



  const history = useHistory();
  const [loading,SetLoading]=useState(false); 
  const [videoUploading,SetVideoLoading]=useState(false);
  const question_add = (e) => {
    SetFormSubmitted(true);
    if(formData.answerDuration <= 0){
      alertService.alert({ message: "Answer duration must be greater than 0 seconds", type: 'info' });
      return
     }
     if(formData.answerDuration > 5400){
      alertService.alert({ message: "Answer duration must not be greater than 5400 seconds", type: 'info' });
      return
     }

    if(formData.question && formData.question.replace(/\s/g, '').length  && selectedParentCategory  && formData.answerDuration)
    {
        if (formData.videoName) {
            formData.videoQuestion = true
        } else {
            formData.videoQuestion = false
        }
    const token = localStorage.getItem('x-access-token');
    SetLoading(true);
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/add_question`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(formData)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          alertService.alert({ message: res.message, type: 'info' });
          SetFormSubmitted(false);
          SetLoading(false);
          history.push('/interview/view-question');
        }else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
          SetLoading(false);

        }
        else {
          if (res.errors) {
            var errmsg = "";
            res.errors.forEach(function (value, index, array) {
              errmsg = errmsg + "(" + (index + 1) + ") " + value.msg + "\n\r";

            });
            alertService.alert({ message: errmsg, type: 'error' });
            SetLoading(false);

          }
          else {
            alertService.alert({ message: res.message, type: 'error' });
            SetLoading(false);

          }
        }
      })
      .catch(() => {
        SetLoading(false);
      });
    }
    }

  const [loadingnext,Setloadingnext]=useState(false)  
  const question_save_next = (e) => {
    SetFormSubmitted(true);
    if(formData.answerDuration <= 0){
      alertService.alert({ message: "Answer duration must be greater than 0 seconds", type: 'info' });
      return
     }

     if(formData.answerDuration > 5400){
      alertService.alert({ message: "Answer duration must not be greater than 5400 seconds", type: 'info' });
      return
     }

    if(formData.question && formData.question.replace(/\s/g, '').length  && selectedParentCategory  && formData.answerDuration)
    {
        if (formData.videoName) {
            formData.videoQuestion = true
        } else {
            formData.videoQuestion = false
        }
    const token = localStorage.getItem('x-access-token');
    Setloadingnext(true);
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/add_question`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(formData)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {


          SetFormData({
            ...formData,
           question: "",
          // categoryId: "",
          // subCategoryId: "",
          videoPath: "",
          videoName: "",
          answerDuration: "",
          keywords: []
        })       
        SetUploadState(false);
        SetKeywordsList([])
        setKeywords([]);
        // setCategoryselected(false);
        // setselectedParentCategoryId();
        // setSelectedParentCategory();
        // setselectedSubCategoryId();
        // SetSelectedsubCategoryData()
        // setSubCategories([]);
        // const ele = autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
        // const ele1 = autoC1.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
        // if (ele) ele.click();
        // if(ele1) ele1.click();
        alertService.alert({ message: res.message, type: 'info' });
        SetShowPage(false);
        SetShowPage(true);
        handleClose();
        SetFormSubmitted(false);
        Setloadingnext(false);
        }else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
          Setloadingnext(false);

        }
        else {
          if (res.errors) {
            var errmsg = "";
            res.errors.forEach(function (value, index, array) {
              errmsg = errmsg + "(" + (index + 1) + ") " + value.msg + "\n\r";

            });
            alertService.alert({ message: errmsg, type: 'error' });
            Setloadingnext(false);

          }
          else {
            alertService.alert({ message: res.message, type: 'error' });
            Setloadingnext(false);

          }
        }
      })
      .catch(() => {
        Setloadingnext(false);

      });

    }
  }

const[errorMsg ,SetErrormSG] =useState("");


const SetErrorMsg = () => {
  if(selectedParentCategory){

  }
}

const filter = createFilterOptions();


  const [parentCategories, setParentCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedParentCategoryId, setselectedParentCategoryId] = useState();
  const [selectedSubCategoryId, setselectedSubCategoryId] = useState();
  const [selectedId, setSelectedOption] = useState();
  const [fieldActive, setFieldActive] = useState(true);
  const [pdFileName, SetSeletedFileName] = useState("");

  const uploadFile = (x) => {
    SetSeletedFileName(x.target.files[0].name)
  }
  const [isCategoryselected, setCategoryselected] = useState(true)
  function handleSelectCategoryChange(value, i,reason) {
    if(reason==="clear"){
      setSelectedParentCategory("");
      setSelectedOption("");
      setCategoryselected(true);
      setSubCategories([]);
      SetSelectedsubCategoryData("");
      const ele = autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
      if (ele) ele.click();
    }  
    setSelectedParentCategory(value);
    if (value) {
      setSelectedOption(value.id);
      // document.getElementById("subcategoryvalue").value= ""    
      var parentId = (value.id);
      get_sub_categories(value.id);

      SetFormData({
        ...formData,
        categoryId: value.id
      })
      setCategoryselected(false)
    }
    else {
      setCategoryselected(true)
    }
  }
const[ selectedSubcat,SetSelectedsubCategoryData]=useState();
  function handleSelectSubCategoryChange(value, i) {
    console.log(value,i)
    if (value) {
      SetFormData({
        ...formData,
        subCategoryId: value.id
      })
    }
    setSubCatValue(value)
    SetSelectedsubCategoryData(value);

  }
  const get_parent_categories = (setDefault) => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/categories?page=1&limit=1000`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setParentCategories(res.data);
          if (setDefault) {
            setSelectedParentCategory(res.data[0]);
            setSubCategories([]);
          }
        }else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
        else {
        }
      })
      .catch(() => {
      });
  }
  const get_sub_categories = (parentId) => {
    if (parentId) {
      const token = localStorage.getItem('x-access-token');
      fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/sub_categories?page=1&limit=10&parentCategoryId=` + parentId, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'x-access-token': token
        },
        // body: JSON.stringify(values)
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status == true) {
            setSubCategories(res.data);

          }else if(res.sessionExpired){
            alertService.alert({ message :res.message, type : 'error'  });
            logoutMethod();
          }
          else {
            // alert(res.message);
          }
        })
        .catch(() => {
        });
    }
  }

  useEffect(() => {
    let mounted = true;
    get_parent_categories();
    return () => mounted = false;
  }, [])








  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }

  const Submit_Form = () => {
    var btnSubmit = document.getElementById("submit-btn");
    if (btnSubmit) {
      btnSubmit.click();
    }
  }

  const addMoreOption = () => {
    if (formData.answers.length > 4) {
      alertService.alert({ message: "Maximmun 5 Answers are Allowed", type: 'info' });
    }
    else {
      SetFormData({
        ...formData,
        answers: [...formData.answers, { answer: "", imagePath: "", imageName: "", rightAnswer: false }],
      })
    }
  }

  const DeleteAnswer = (value) => {
    if (formData.answers.length > 2) {
      const newAnswers = [...formData.answers];
      newAnswers.splice(value, 1);
      SetFormData({
        ...formData,
        answers: newAnswers,
      })
    }
    else {
      alertService.alert({ message: "Minimum 2 Answers are Required", type: 'warning' });

    }

  }


  // newparentid = res.data.id;
  // if(newparentid){
  //    SetSelectedCategoryId(newparentid);
  //     SetcategorySelected(false);
  // }
  const [parentCatValue, setParentCatValue] = React.useState(null);
  const [inputParentCatValue, setInputValue] = React.useState('');
  const [subCatValue, setSubCatValue] = React.useState("");
  const [inputSubCatValue, setSubCatInputValue] = React.useState('');

  const [selectedParentCategory, setSelectedParentCategory] = useState("")
  const add_Category = (values) => {
    // var CatName = document.getElementById("category").value;
    var CatName = document.getElementById("parent-cat-id-feild").value

    if (CatName) {
      const reqData = {
        "categoryName": CatName
      }
      const token = localStorage.getItem('x-access-token');

      fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/add_category`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'x-access-token': token
        },
        body: JSON.stringify(reqData)
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status == true) {
            alertService.alert({ message: res.message, type: 'success' });
            get_parent_categories(true);
            setCategoryselected(false);
            setModalShow(false);
           var  newparentid = res.data.id;
            if(newparentid){
              setselectedParentCategoryId(newparentid);
              setSelectedOption(newparentid)
              setSelectedParentCategory(res.data)
              SetFormData({
                ...formData,
                categoryId: newparentid
              })
            }

            const ele = autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
            ele.click();

          }else if(res.sessionExpired){
            alertService.alert({ message :res.message, type : 'error'  });
            logoutMethod();
          }
          else {
            alertService.alert({ message: res.message, type: 'error' });
            setModalShow(false);
          }
        })
        .catch(() => {
        });
    }

    else {
      alertService.alert({ message: "Please Enter Parent Category Name First", type: 'warning' });
    }
  }

  const showAnswerTab = () => {
    handleChangeIndex(1);
  }

  const [QuestionType, SetQuestionType] = useState("")
  const handleShowAnswer = (value, event) => {
    if (QuestionType === "MCQ" && value === "Subjective") {
      setModalShow3(true);
      // event.target.value==="Subjective";
    }
    else {
      SetQuestionType(value);
      if (value === "MCQ") {
        SetFormData({
          ...formData,
          answers: [
            { answer: "", imagePath: "", imageName: "", rightAnswer: false },
            { answer: "", imagePath: "", imageName: "", rightAnswer: false },
          ],
          questionType: value
        })
      } else {
        SetFormData({
          ...formData,
          questionType: value
        })
      }
    }
  }


  const ChangeQuestionType = () => {
    SetQuestionType("Subjective");
    setModalShow3(false);
    SetFormData({
      ...formData,
      questionType: "Subjective", answers: []
    })
  }

  const [modalShow3, setModalShow3] = React.useState(false);
  function DeleteConfirmationModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            <p className="text-center">
              <img alt="logo" className="brand-dim" src="/media/logos/UPDATED_VRekruit _Logo.png" />
            </p>
            <p className="text-dark text-center pt-3 pb-0 mb-0"> Are you sure want to change answer type?</p>
            <p className="text-dark text-center pt-0 mt-0"> Saved answer data will be lost if any. </p>

          </Modal.Title>
        </Modal.Body>
        <Modal.Title className="d-flex justify-content-center align-items-center pb-5" >
          <p className="text-center">
            <Button className="btn btn-theme text-white btn-lg mr-5  pl-5 pr-5 pt-3 pb-3" style={{ "minWidth": "100px" }} onClick={ChangeQuestionType}> Yes </Button>
            <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green" style={{ "minWidth": "100px" }} onClick={props.onHide}>No</Button>
          </p>
        </Modal.Title>
      </Modal>

    );
  }


  const [modalShow, setModalShow] = React.useState(false);
  function CategoryModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="d-flex justify-content-flex-end">
          <Modal.Title id="contained-modal-title-vcenter">
            <Button className="btn btn-theme text-white btn-lg mr-5 pl-4 pr-4 pt-3 pb-3" onClick={add_Category}> Save </Button>
            <Button className="btn btn-theme-green btn-lg mr-5 pl-4 pr-4 pt-3 pb-3 bg-theme-green" onClick={props.onHide}>Close</Button>  </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center d-flex">

            <div className="form-group row mb-0 mt-3">
              <label for="formPlaintextEmail" className="form-label col-form-label col-sm-3">Parent Category</label>
              <div className="col-sm-9"><div className="form-group col">
                <Form.Control size="lg" type="text" placeholder="" id="category" />
              </div>
              </div>
              <label for="formPlaintextEmail" className="text-theme-green form-label col-form-label col-sm-2">
              </label></div>
          </Modal.Title>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    );
  }

  const [modalShow1, setModalShow1] = React.useState(false);
  function SubCategoryCModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="d-flex justify-content-flex-end">
          <Modal.Title id="contained-modal-title-vcenter">
            <Button className="btn btn-theme text-white btn-lg mr-5 pl-4 pr-4 pt-3 pb-3" onClick={add_SubCategory}> Save </Button>
            <Button className="btn btn-theme-green btn-lg mr-5 pl-4 pr-4 pt-3 pb-3 bg-theme-green" onClick={props.onHide}>Close</Button>  </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center d-flex">

            <div className="form-group row mb-0 mt-3">
              <label for="formPlaintextEmail" className="form-label col-form-label col-sm-3">Sub Category</label>
              <div className="col-sm-9"><div className="form-group col">

                <Form.Control size="lg" type="text" placeholder="" id="subcategoryvalue" />
              </div>
              </div>
              <label for="formPlaintextEmail" className="text-theme-green form-label col-form-label col-sm-2">
              </label></div>
          </Modal.Title>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    );
  }




  const [keywordModalShow, setKeywordModalShow] = React.useState(false);
  function AddKeywordmodal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="d-flex justify-content-flex-end">
          <Modal.Title id="contained-modal-title-vcenter">
            <Button className="btn btn-theme text-white btn-lg mr-5 pl-4 pr-4 pt-3 pb-3" onClick={add_Keyword}> Save </Button>
            <Button className="btn btn-theme-green btn-lg mr-5 pl-4 pr-4 pt-3 pb-3 bg-theme-green" onClick={props.onHide}>Close</Button>  </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center d-flex">

            <div className="form-group row mb-0 mt-3">
              <label for="formPlaintextEmail" className="form-label col-form-label col-sm-3"> Keyword</label>
              <div className="col-sm-9"><div className="form-group col">
                <Form.Control size="lg" type="text" placeholder="Keyword"
                id="keyword-ID"
                onKeyDown={blockemoji}
                onKeyPress={blockInvalidChar}
                />
              </div>
              </div>
              <label for="formPlaintextEmail" className="text-theme-green form-label col-form-label col-sm-2">
              </label></div>
          </Modal.Title>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    );
  }

  const add_SubCategory = () => {

    var subCatName = document.getElementById("sub-cat-id-feild").value;
    if (subCatName && selectedId) {
      const reqData = {
        "subCategoryName": subCatName,
        "parentCategoryId": selectedId,
      }
      const token = localStorage.getItem('x-access-token');
      fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/add_sub_category`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'x-access-token': token

        },
        body: JSON.stringify(reqData)
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status == true) {
            alertService.alert({ message: res.message, type: 'success' });
            get_sub_categories(selectedId);
            var  newsubcatid = res.data.id;
            if(newsubcatid){
              SetFormData({
                ...formData,
                subCategoryId: newsubcatid
              })
            }

            setModalShow1(false)
            document.getElementById("subcategoryvalue").value = "";
          }else if(res.sessionExpired){
            alertService.alert({ message :res.message, type : 'error'  });
            logoutMethod();
          }
          else {
            alertService.alert({ message: res.message, type: 'error' });
            setModalShow1(false)
          }
        })
        .catch(() => {

        });


    }
    else {
      alertService.alert({ message: "Please Enter Sub Category Name First", type: 'warning' });
    }
  }

  //code for uploading question video



  const uploadInputTriggerClick = (inp) => {
    var UploadInp = document.getElementById(inp);
    if (UploadInp) {
      UploadInp.click();
    }
  }


  const [uploadState, SetUploadState] = useState(false);

  const DeleteUploadedFile = (value) => {
    setOpen(false)
    SetUploadState(false);
    SetFormData({
      ...formData,
      videoPath: "",
      videoName: "",
    })
    deleteselectedFiels(value)
  }


  const deleteselectedFiels = (url) => {
      const token = localStorage.getItem('x-access-token');
      fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/delete_file`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'x-access-token': token
        },
        body: JSON.stringify({
          fileName: [url]
        })
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status == true) {
            //  view_requisition();
            alertService.alert({ message: res.message, type: 'success' });
          }else if(res.sessionExpired){
            alertService.alert({ message :res.message, type : 'error'  });
            logoutMethod();
          }
          else {
            alertService.alert({ message: res.message, type: 'error' });
  
          }
        })
  }



const uploadQuestionVideo = (ev) => {
  let file = ev.target.files[0];
  if (file) {
    var Parts = file.name.split('.');
    var Type = Parts.slice(-1).pop() //Parts[1];
  }

  if (file && Type === "mp4") {
    var bytes = ev.target.files[0].size;
    var Mbytes = bytes / (1024 * 1024);
    window.URL = window.URL || window.webkitURL;
    var video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      //in seconds duration
      var duration = video.duration;
      if (duration > 120 && duration != "Infinity") {
        alertService.alert({ message: "Max Allowed Video Duration is 2 mins", type: 'error' });
        return;
      }
      else {
        if (file && Mbytes < 200) {
          // Split the filename to get the name and type
          let fileParts = file.name.split('.');
          let fileType = fileParts.slice(-1).pop()

          const formData1 = new FormData();
          formData1.append("fileName", fileParts[0] + "." + fileType);
          formData1.append("fileType","video/"+fileType);
          formData1.append("fileFolder", 'video');
          formData1.append("file", file);
          SetVideoLoading(true)
          axios.post(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/upload_file`, formData1
          /*{
            fileName: fileParts[0] + "." + fileParts[1],
            fileType: "video/" + fileType,
            fileFolder: "video",
          }*/,
            {
              headers: {
                'x-access-token': localStorage.getItem('x-access-token'),
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(response => {
              SetVideoLoading(false)
              if (response.data.sessionExpired) {
                logoutMethod();
                alertService.alert({ message: response.data.message, type: 'error' });
              }
              else if (response.status) {
                //var url = response.data.data.filePath;
                var returnData = response.data.data.returnData;
                var signedRequest = returnData.signedRequest;
                var url = returnData.url;
               //  setVideoFileName(fileParts[0]);
                if (url) {
                  // initialValues.companyThanksVideos.push(returnData.url);
      
                  SetFormData({
                    ...formData,
                    videoPath: url,
                    videoName: fileParts[0] + "." + fileType,
                  })
                  SetUploadState(true);

                }


                // Put the fileType in the headers for the upload
                var options = {
                  headers: {
                    'Content-Type': 'video/' + fileType,
                    'Content-Disposition': "inline"
                  }
                };
                axios.put(signedRequest, file, options)
                  .then(result => {
                    // alertService.alert( {message:JSON.stringify(response.message),type:'info'});
                  })
                  .catch(error => {
                    if (error.message) {
                      alertService.alert({ message: JSON.stringify(error.message), type: 'error' });
                    }
                    else {
                      alertService.alert({ message: JSON.stringify(error), type: 'error' });
                    }
                  }) 

              }

            })
            .catch(error => {
              SetVideoLoading(false)
              if (error.message) {
                alertService.alert({ message: JSON.stringify(error.message), type: 'error' });
              }
              else {
                alertService.alert({ message: JSON.stringify(error), type: 'error' });
              }
            })
        }

        else if (file && Mbytes > 200) {
          alertService.alert({ message: "Max Allowed File Size is 200MB", type: 'error' });
        }

      }
    }
    video.src = URL.createObjectURL(file);;
  }

  else if (file && Type !== "mp4") {
    console.log(Type)
    alertService.alert({ message: "File Format is Not Allowed", type: 'error' });
  }

}



const [KeyWords, setKeywords] = useState([]);
  const [colourOptions, setColourOptions] = useState(["Keyword 1, Keyword 2"]);
  const [keywordsList, SetKeywordsList] = useState([]);

  const add_Keyword = () => {
    if(KeyWords.length>2){
      alertService.alert({ message: "Maximum 3 keywords are allowed", type: 'error' });
      return
    }
    else{
    let KeyWordvalue = document.getElementById("keyword-ID").value;
    if (KeyWordvalue) {
      var newKeywords = [...KeyWords, { value: KeyWordvalue, label: KeyWordvalue }]
      setKeywords(newKeywords);
      setKeywordModalShow(false);
      var keywordsListItems = newKeywords.map(x => x.value).join();
      SetKeywordsList(keywordsListItems)
      SetFormData({
        ...formData,
        keywords: [keywordsListItems]
      })
    }
  }

  }


  function handleMultipeInput(value) {
    //     e.preventDefault();  

      setKeywords(value);
      // setIdListItem(value);
      var keywordsListItems = KeyWords.map(x => x.value).join();
      SetKeywordsList(keywordsListItems)
      SetFormData({
        ...formData,
        keywords: [keywordsListItems]
      })
    
  };


  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    SetIsRecording(false);
    setOpen(false);
  };

  const [isrecording, SetIsRecording]= useState(true);

  const [videoBlob, setVidoBlob] = useState();
  const [videoBlobFile, setVidoBlobFile] = useState();




 const stopRecord = () => {
    var stopbutton = document.querySelectorAll('[data-qa="stop-recording"]');
    if(stopbutton && stopbutton[0]) {
          stopbutton[0].click();
         SetIsRecording(false);
    }
    uploadblobfile()
 }



  const downloadVideo = (videoBlob) => {
    var videoType = ".webm"
    if(isSafari){
      videoType = ".mp4"
    }
    if(videoBlob) {
      var file = blobToFile(videoBlob, `Video-${Date.now()}${videoType}`);
      setVidoBlobFile(file)
    }
  //  uploadblobfile(file);

  }

  const uploadblobfile = () => {
    var file = videoBlobFile
    if (file) {
      var bytes = file.size;
      var Mbytes = bytes / (1024 * 1024);
    }
    if (file && Mbytes < 200) {
      // Split the filename to get the name and type
      let fileParts = file.name.split('.');
      let fileType = fileParts.slice(-1).pop()

      const formData1 = new FormData();
      formData1.append("fileName", fileParts[0] + "." + fileType);
      formData1.append("fileType","video/"+fileType);
      formData1.append("fileFolder", 'video');
      formData1.append("file", file);
      SetVideoLoading(true)


      axios.post(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/upload_file`, formData1 /*{
        fileName: fileParts[0] + "." + fileParts[1],
        fileType: "video/"+fileType,
        fileFolder: "video",
        file
        
      }*/,
        {
          headers: {
            'x-access-token': localStorage.getItem('x-access-token'),
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          SetVideoLoading(false)
          if(response.data.sessionExpired){
            logoutMethod();
            alertService.alert({ message :response.data.message, type : 'error'  });
            
          }
          else if(response.status){
         // var url = response.data.data.filePath;
         var returnData = response.data.data.returnData;
          var signedRequest = returnData.signedRequest;
          var url = returnData.url;
        //  setVideoFileName(fileParts[0]);
          if (url) {
            // initialValues.companyThanksVideos.push(returnData.url);
            SetFormData({
              ...formData,
              videoPath: url,
              videoName: fileParts[0] + "." + fileType,
            })
            SetUploadState(true);
          }
         // Put the fileType in the headers for the upload
          var options = {
            headers: {
              'Content-Type': 'video/'+ fileType,
              'Content-Disposition':"inline" 
            }
          };
          axios.put(signedRequest, file, options)
            .then(result => {
              setVidoBlobFile("")
            })
            .catch(error => {
              alertService.alert({ message: JSON.stringify(error), type: 'error' });
            }) 
          }
        })
        .catch(error => {
          SetVideoLoading(false)
          alertService.alert({ message: JSON.stringify(error), type: 'error' });
        })
    }

    else if (file && Mbytes > 200) {
      alertService.alert({ message: "Max Allowed File Size is 200MB", type: 'error' });
    }
  }

  function blobToFile(theBlob, fileName) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }
  // code for keyword multiselect
  const [showPage,SetShowPage]= useState(true)

  return (
    <>
    {showPage? (
      <>
      <Form onSubmit={(e) => question_add(e)}>
        {/* subeader */}
        <div id="kt_subheader" className="subheader py-2 py-lg-4    subheader-solid">
          <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <div className="d-flex align-items-center flex-wrap mr-1">
              <div className="d-flex align-items-baseline mr-5">
                <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Interview Management</h3></div>
            </div>
            <div className="d-flex align-items-center" />
          </div>
        </div>
        {/* subeader */}
        <Col className="bg-white" >
          <Row className="bg-white pt-4  pb-1 align-items-center sticky-header">
            <Col md={5} sm={12}  className="pr-3 pl-3">
              <div>
                <div className="d-flex flex-grow-1 align-items-center  justify-content-start   rounded">
                  <div>
                    <h5 className="font-weight-bold f-w-600 pl-5" > Question Management &nbsp;&nbsp; {'>'} &nbsp;&nbsp; Add Question </h5>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={7} sm={12}  className="pr-3 pl-3 text-right">
              <div>
                <div className="d-flex flex-grow-1 align-items-center justify-content-end rounded mt-1">
                  <div className="text-muted d-block">
                    <Link  onClick={question_save_next} className={`btn btn-theme-green btn-lg mr-5 mb-2 ${loadingnext? "p-none":""}`}
                    disabled={loadingnext}>
                      Submit {`&`}  Add Next Question
                      {loadingnext && <span className="mr-5 ml-2 spinner spinner-white"></span>}
                    </Link>
                    <Link onClick={question_add}  className={`btn btn-theme-green btn-lg mr-5 mb-2  ${loading? "p-none":""}`}
                      disabled={loading}>
                       Submit
                      {loading && <span className="mr-5 ml-2 spinner spinner-white"></span>}
                    </Link>
                    <Link to="/interview/view-question" type="button" className="btn btn-theme btn-lg mr-5  text-white mb-2">
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5"></Col>
          </Row>

          <div style={{ "minHeight": "70vh" }}>
            <Row className="pt-5"  >
              <Form.Label className="fw-600" column sm="2">
                Parent Category*
              </Form.Label>
              <Col sm="7">
                <Form.Group as={Col} >
                  <Autocomplete
                    name="categoryId"
                    size="small"
                    id=  "parent-cat-id-feild"
                    value={selectedParentCategory ? selectedParentCategory :""}
                    options={parentCategories}
                   // options={parentCategories.map((option) => option.categoryName)}
                   /* onChange={(event, newValue,reason) => {
                      handleSelectCategoryChange(newValue, event,reason);
                    }}*/

                   /* onChange={(event, newValue,reason) => {
                      if (typeof newValue === 'string') {
                        setParentCatValue({
                          categoryName: newValue,
                        });
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setParentCatValue({
                          categoryName: newValue.inputValue,
                        });
                      } else {
                        setParentCatValue(newValue);
                      }
                      handleSelectCategoryChange(newValue,event,reason);
                    }}*/
                    onChange={(event, newValue, reason) => {
                      handleSelectCategoryChange(newValue,event, reason);
                  }}
                                inputValue={inputParentCatValue}
                  onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                  }}

                    freeSolo

                 /*   filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      // Suggest the creation of a new value
                      const { inputValue } = params;
                      const isExisting = options.some((option) => inputValue === option.categoryName);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        categoryName: `Click on Plus Icon to Add This Parent Category`,
                      });
                    }
              
                      return filtered;
                    }}*/
                    selectOnFocus
                 // clearOnBlur
                  handleHomeEndKeys
                    defaultValue={parentCategories[0]}
                    getOptionLabel={(options) => options.categoryName}
                    renderInput={(params) => <TextField {...params} label="Select Parent Category" variant="outlined" />} 
                    ref={autoC1}                    
                    />
                  {isFormSubmitted && !selectedParentCategory ?
                  <div className="fv-plugins-message-container" >
                    <div className="fv-help-block">Required</div>
                  </div> : null}
                </Form.Group>
              </Col>
              <Link to="#" className="d-c">
                <Form.Label className="fw-600" column sm="2" className="text-theme-green">
                  <span className="c-p">
                    <img src="/media/VRekruitIcons/addmore.svg" onClick={add_Category} className="c-p" width="24"></img>
                  </span>
                </Form.Label>
              </Link>
            </Row>
            <Row className="{`$(fieldActive) ? 'show' : 'hidden'`}" disabled={true}>
              <Form.Label className="fw-600" column sm="2">
                Sub Category
              </Form.Label>
              <Col sm="7">
                <Form.Group as={Col} >
                  <Autocomplete
                    className={`c-p ${isCategoryselected ? "p-none no-drop" : ""}`}
                    size="small"
                    value={subCatValue}
                    name="subCategoryId"
                    options={subCategories}
                  

                    onChange={(event, newValue, reason) => {
                      handleSelectSubCategoryChange(newValue, event,reason);
                  }}
                                inputValue={inputSubCatValue}
                  onInputChange={(event, newInputValue) => {
                    setSubCatInputValue(newInputValue);
                  }}

                    id="sub-cat-id-feild"
                    freeSolo

                /*    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      // Suggest the creation of a new value
                      const { inputValue } = params;
                      const isExisting = options.some((option) => inputValue === option.subCategoryName);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        subCategoryName: `Click on Plus Icon to Add This Sub Category`,
                      });
                    }
              
                      return filtered;
                    }} */
                    selectOnFocus
                //  clearOnBlur
                  handleHomeEndKeys
                  //  defaultValue={parentCategories[0]}
                //    getOptionLabel={(options) => options.subCategoryName}


                   getOptionLabel={(options) => options.subCategoryName ? options.subCategoryName : ""}
                    renderInput={(params) => <TextField {...params} label="Select Sub Category" variant="outlined" />} 
                    ref={autoC}
                    />
                </Form.Group>
              </Col>
              <Link to="#" onClick={add_SubCategory} className={`c-p ${isCategoryselected ? "p-none no-drop" : ""}`} >
                <Form.Label className="fw-600" column sm="2" className="text-theme-green"  >
                  <span >
                    <img src="/media/VRekruitIcons/addmore.svg" width="24"></img>
                  </span>
                </Form.Label>
              </Link>
            </Row>
            <Row >
              <Form.Label className="fw-600" column sm="2">
                Keywords
              </Form.Label>
              <Col sm="7">
                <Form.Group as={Col} id="k" className="pointer-none" >
                  <Select 
                    closeMenuOnSelect={true}
                   // components={animatedComponents}
                    defaultValue={KeyWords}
                    isMulti
                    placeholder="Add Keywords"
                    value={KeyWords}
                    onChange={handleMultipeInput} 
                    isSearchable={false}
                    />
                </Form.Group>
              </Col>
              <Form.Label className="fw-600" column sm="2" onClick={showAnswerTab} className={`text-theme-green c-p  ${QuestionType !== "MCQ" ? "d-none" : "d-inline"}`}  >
                <img src="/media/VRekruitIcons/addmore.svg" className="c-p mr-3" width="24" />
              </Form.Label>
              <Link to="#" className="d-c">
                <Form.Label className="fw-600" column sm="2" className="text-theme-green">
                  <span className={`c-p ${KeyWords.length > 2 ? "p-none no-drop" : ""}`}>
                    <img src="/media/VRekruitIcons/addmore.svg" onClick={() => setKeywordModalShow(true)} className="c-p" width="24"></img>
                  </span>
                </Form.Label>
              </Link>
            </Row>
            {!uploadState ? (
              <Row >
                <Form.Label className="fw-600" column sm={2}>
                  Question Video
                </Form.Label>
                <Col sm={5} md={2}>

                    <div className="upload-btn-wrapper-1 " style={{ "display": "flex", "justifyContent": "center", "alignItems": "center", "minHeight": "97px" }} >
                      <Link className="btn btn-file upload-btn text-theme-blue pt-1" onClick={() => uploadInputTriggerClick("video-upload-inp")}>
                      <p className="text-center">
                        <img src={toAbsoluteUrl("/media/VRekruitIcons/uploadfiles.svg")} className="mb-0" width="71" style={{"padding": "15px" }}></img>
                        <br />
                        Upload a Video File
                        <br />
                        .MP4 file upto 200MB is allowed
                        </p>
                      </Link>
                      <input type="file" name="videoPath"
                        accept="video/mp4"
                        id="video-upload-inp"
                        onChange={(e) => uploadQuestionVideo(e)}
                        style={{ "display": "none" }}
                      />
                    </div>
                  <div>
                  </div>

                </Col>
                <Col md={1} style={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }}>
                  OR
                </Col>
                <Col sm={5} md={2} >
                  <div className="upload-btn-wrapper-1" style={{ "display": "flex", "justifyContent": "center", "alignItems": "center", "minHeight": "97px" }} >
                    <Link className="btn btn-file upload-btn text-theme-blue pt-1" onClick={handleClickOpen}>

                      <p className="text-center">
                        <i className="fa fa-circle text-theme-blue text-center  mt-1 mb-0" style={{ "fontSize": "38px", "padding": "15px" }}></i>
                        <br />
                        Record A Video
                        <br/>
                        Maximum question record time is
                           { " " + process.env.REACT_APP_API_RECORDING_TIME_DURATION + " "}   
                         seconds                     
                        </p>
                    </Link>
                    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                      <AppBar className={classes.appBar}>
                        <Toolbar>
                          <button className={`btn-pop-up-1 mr-5 ${isrecording ? "" : ""}`} 
                          style={{ "minWidth": "140px" }} autoFocus color="inherit" onClick={() => stopRecord()}>
                          {videoUploading ? 'Uploading...' : 'Save'}  
                          </button>
                          <button className="btn-pop-up-2" style={{ "minWidth": "140px" }} autoFocus color="inherit" onClick={handleClose}>
                            Cancel
                          </button>
                        </Toolbar>
                      </AppBar>
                      <VideoRecorder
                        onStartRecording={() => {
                          setTimeout(() => {
                          var stopbutton = document.querySelectorAll('[data-qa="stop-recording"]');
                          if(stopbutton && stopbutton[0]) {
                                stopbutton[0].click();
                               SetIsRecording(false);
                            }                            
                          }, 1000 * 123);
                        }}
                        onRecordingComplete={ videoBlob => {
                          setVidoBlob(videoBlob);
                          SetIsRecording(false);
                          downloadVideo(videoBlob);
                        }}
                        onStopRecording={() => {
                          SetIsRecording(false);
                          downloadVideo(videoBlob);
                        }}


                        onTurnOffCamera={() => {
                          SetIsRecording(false);

                        }} 

                        onPauseRecording={() => {
                          SetIsRecording(false);
                          setVidoBlob(videoBlob);
                          downloadVideo(videoBlob);


                        }} 

                        onOpenVideoInput={() =>{
                          SetIsRecording(false);
                          setVidoBlob(videoBlob);
                          downloadVideo(videoBlob);
                        }} 
                        replayVideoAutoplayAndLoopOff
                        showReplayControls
                        />
                    </Dialog>
                  </div>
                  
                </Col>
             
                
              </Row>
            ) : ""}
            {uploadState ? (
              <Row className="mt-5">
                <Form.Label className="fw-600" column sm="2">
                  Question Video
                </Form.Label>
                <Col sm="7">
                  <Form.Group as={Col} style={{ "position": "relative" }} >
                    <Form.Control type="text" style={{ "paddingLeft": "59px" }}
                      name="videoName" onChange={(e) => handleFormInput(e)}
                      value={formData.videoName}
                      disabled={true}
                    >
                    </Form.Control>
                    <img src="/media/VRekruitIcons/video-file.svg" className=" files-ico" width="40" />
                    <i className="fa fa-times cancel-ico" onClick={()=>  DeleteUploadedFile(formData.videoPath)} />

                  </Form.Group>
                </Col>
              </Row>
            ) : ""}

            <Row className="pt-5" >
              <Form.Label className="fw-600" column sm="2">
                Question Text*
              </Form.Label>
              <Col sm="7">
                <Form.Group as={Col} >
                  <Form.Control as="textarea" placeholder="" rows={3}
                    name="question" onChange={(e) => handleFormInput(e)}
                    value={formData.question}
                  >
                  </Form.Control>
                  {isFormSubmitted && !formData.question   ?
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block pl-3">Required</div>
                  </div>
                  :null}

                 {isFormSubmitted && formData.question && !formData.question.replace(/\s/g, '').length    ?
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block pl-3">Required</div>
                  </div>
                  :null}
                </Form.Group>
              </Col>
            </Row>

            <Row >
              <Form.Label className="fw-600" column sm="2">
              Time Duration For Answer<br/> 
              (in Seconds)*
              </Form.Label>
              <Col sm="7">
                <Form.Group as={Col} >
                  <Form.Control type="number"
                    name="answerDuration" onChange={(e) => handleFormInput(e)}
                    value={formData.answerDuration}
                    min="1"
                    onKeyDown={blockInvalidChar}
                    >
                  </Form.Control>
                  {isFormSubmitted && !formData.answerDuration?
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block pl-3">Required</div>
                  </div>
                  :null}
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Col>
        {/* </Col> */}
      </Form>
      {videoUploading ? ( 
      <div id="cover-spin">
      </div>
      ) : ""}
    
      </>
      
     ):"" }
     
      <DeleteConfirmationModal show={modalShow3} onHide={() => setModalShow3(false)} />
      <CategoryModal show={modalShow} onHide={() => setModalShow(false)} />
      <SubCategoryCModal show={modalShow1} onHide={() => setModalShow1(false)} />
      <AddKeywordmodal show={keywordModalShow} onHide={() => setKeywordModalShow(false)} />

    </>
  );
}

export default InterviewAddQuestion