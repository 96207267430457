
/* eslint-disable no-restricted-imports */
/* jshint asi:true */

import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import Helmet from "react-helmet"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import Carousel from 'react-bootstrap/Carousel'
import { useParams, useHistory } from 'react-router-dom';
import VideoRecorder from 'react-video-recorder'
import { isAndroid, isIOS, isSafari, isChrome, isFirefox, osName} from "react-device-detect";
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import {browserHistory} from 'react-router';
import alertService from "../../../_services/alertservice";
import axios from 'axios';
import {logout} from "../../../../actions/auth";
import { useDispatch } from 'react-redux';
import './recorder-custom.css'
import FileUploadProgress  from 'react-fileupload-progress';
import { Media, Player, controls, utils } from 'react-media-player'
import ReactPlayer from 'react-player'
//import myInterviewWidget from './IntWidget'

const queryString = require('query-string');



function InterviewMock(props) {

  const[showreplayCountdown,SetShowreplayCountdown]=useState(false);


{/* <player code start> */}

const {
  PlayPause,
  CurrentTime,
  Progress,
  SeekBar,
  Duration,
  MuteUnmute,
  Volume,
  Fullscreen,
} = controls;


let media_recorder = null;
let blobs_recorded = [];
let camera_stream = null;

/*
let video = document.querySelector("#video");
let start_button = document.querySelector("#start-record");
let stop_button = document.querySelector("#stop-record");
let download_link = document.querySelector("#download-video");




start_button.addEventListener('click', function() {
    media_recorder = new MediaRecorder(camera_stream, { mimeType: 'video/webm' });

    media_recorder.addEventListener('dataavailable', function(e) {
    	blobs_recorded.push(e.data);
    });

    media_recorder.addEventListener('stop', function() {
    	let video_local = URL.createObjectURL(new Blob(blobs_recorded, { type: 'video/webm' }));
    	download_link.href = video_local;

        stop_button.style.display = 'none';
        download_link.style.display = 'block';
    });

    media_recorder.start(1000);

    start_button.style.display = 'none';
    stop_button.style.display = 'block';
});

stop_button.addEventListener('click', function() {
	media_recorder.stop(); 
});

*/

// player code end
  const [ interviewAnswerData, SetinterviewAnswerData] = useState(
    {
      interviewQuestionId : "",
      answerName : "",
      answer : ""
    }
  )
  const [cameraEnabled, setCameraEnabled] = useState(false);
  const { keyboardControls } = utils
  const [ recorderAnswerTime, setRecorderAnswerTime ] = useState();
  const [ShowAnsVideo,SetShowAnsVideo]=useState(true)
  const [NextQues,SetNextState]=useState(false)
  const [AnswerVideoWasStopped,SetAnswerVideoWasStopped]=useState(false);
  const [quesReplayed,SetQuesReplayed]=useState([])
  const [answerVideoPlaying,SetAnswerVideoPlaying]=useState()
  const [answerStartIn,setAnswerStartIn]=useState()
  const [mediaRecorderObj, setMediaRecorderObj] = useState()
  const [showStopBtn,setShowStopBtn] = useState(false)





  
  const { intl } = props;    
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };


 //show LandingPage is landing page
 const[ showLandingPage, SetShowLandingPage] = useState(true);
 const[ showTermsPage, SetShowTermsPage] = useState(false);
 const[ showIntroVideo, SetShowIntroVideo] = useState(false);
 const[ showThanksVideo, SetShowThanksVideo] = useState(false);
 const[ showFinalThankyouPage, SetShowFinalThankyouPage] = useState(false);
 const [stopAnswerVideo,SetStopAnswerVideo]=useState(false)

 function SoundMeter(context) {
  this.context = context;
  this.instant = 0.0;
  this.slow = 0.0;
  this.clip = 0.0;
  this.script = context.createScriptProcessor(2048, 1, 1);
  const that = this;
  this.script.onaudioprocess = function(event) {
    const input = event.inputBuffer.getChannelData(0);
    let i;
    let sum = 0.0;
    let clipcount = 0;
    for (i = 0; i < input.length; ++i) {
      sum += input[i] * input[i];
      if (Math.abs(input[i]) > 0.99) {
        clipcount += 1;
      }
    }
    that.instant = Math.sqrt(sum / input.length);
    that.slow = 0.95 * that.slow + 0.05 * that.instant;
    that.clip = clipcount / input.length;
  };
}

SoundMeter.prototype.connectToSource = function(stream, callback) {
  try {
    this.mic = this.context.createMediaStreamSource(stream);
    this.mic.connect(this.script);
    // necessary to make sample run, but should not be.
    this.script.connect(this.context.destination);
    if (typeof callback !== 'undefined') {
      callback(null);
    }
  } catch (e) {
    console.error(e);
    if (typeof callback !== 'undefined') {
      callback(e);
    }
  }
};

SoundMeter.prototype.stop = function() {
  this.mic.disconnect();
  this.script.disconnect();
};


 const showtermspage = (value) => {
  SetShowLandingPage(false);
  SetShowTermsPage(true)
  if(value==="mock"){
  }
  else {
  }

 }

 const showHomePage = () => {
   SetShowLandingPage(true);
   SetShowTermsPage(false);
   SetShowIntroVideo(false);
   SetShowVideoQuestion(false);
   SetShowVideoAnswer(false);
 }



const[formSubmitted,SetformSubmitted]=useState(false)  
const [terms,SetTems]=useState(false);
const acceptTerms = () => {
  if(terms){
    SetTems(false)
  }
  else{
    SetTems(true)
  }
}  



  const[showVideoQuestion,SetShowVideoQuestion] = useState(false);
  const[showVideoAnswer,SetShowVideoAnswer] = useState(false);

  const DisplayvideoQuestion = () => {
    SetShowVideoQuestion(true);
    // // to play video question
    PlayVideoQuestionTimer();
    SetShowIntroVideo(false);
  }


const [introVideo, SetIntroVideo] = useState(); 
const [thanksVideo, SetThanksVideo] = useState(); 
const [YTVideos, SetYTVideos] = useState([]); 
const [FeatureImage, SetFeatureImg] = useState([]); 
const [interviewRules, SetInterviewRules] = useState([]); 
const [isRecording,SetIsRecording]=useState(false);
const[interviewDetails,SetInterviewDetails] = useState()
const [isIntroVideoEnded,SetIsIntroVideoEnded ]=useState(false);

const onIntroVideoEnded = () => {
   SetIsIntroVideoEnded(true);
}

useEffect(() => {
  let timer1 = null
  console.log('this is auto submit called')
  if(stopAnswerVideo){
    console.log('this is auto submit')
    timer1 =  setTimeout(() => {
    var stopbutton = document.getElementById("submitanswerbtn")
    console.log(stopbutton)
    
    if(stopbutton) {
      var questionId = document.getElementById("submitanswerbtn").getAttribute("data-current-ques");
      if(questionId == CurrentQuesIndex){
        stopbutton.click();
         SetIsRecording(false);
      }
      }                            
    }, 1000 * recorderAnswerTime);
  }else{
    return () => window.clearTimeout(timer1);
  }
  
  }, [stopAnswerVideo])

const [ succefullUploadCount, SetSuccefullUploadCount ]= useState(0);


const [CurrentQuesIndex,SetCurrentQuestionIndex]=useState(0);
const uploadblobfile = () => {
    DisplayNextQuestion();
}

const [InterviewQuestionData,setInterviewQuestionsData] = useState();
const get_Mock_Interview_questions = () => {
  var parsed = queryString.parse(props.location.search);
  var key = parsed.shareKey;
  fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/candidate/get_mock_interview_questions`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status == true) {
        var test = res.data;
        var testQue = test.map(x=> {
          x.interviewQuestionDetail.answerDuration = x.answerTime;
          x.interviewQuestionDetail.id = x.id;
          return x.interviewQuestionDetail;
        });
        
        setInterviewQuestionsData(testQue);
        setRecorderAnswerTime(testQue[CurrentQuesIndex].answerDuration + 3);
        SetQuestionCounter(testQue[CurrentQuesIndex].answerDuration )
        if(res.interviewRules){
          SetInterviewRules(res.interviewRules)
          SetShowTermsPage(true)
        }
        if(res.introVideo){
          SetIntroVideo(res.introVideo)          
        }
        if(res.thanksVideo){
          SetThanksVideo(res.thanksVideo)
        }
      }
  
    })
    .catch(() => {
    });
}


 const[ isRecordringControlsEnabled,SetIsRecordringControlsEnabled]=useState(false)
 const [seconds,SetSeconds] = useState(10)
 const [counter,SetCounter] = useState(null)

const DisplayNextQuestion = () => {    
if(CurrentQuesIndex < InterviewQuestionData.length - 1  ){
  clearInterval(counter);
  SetreplayButtonState(false)
  SetSeconds(10);
  SetShowVideoAnswer(false);
  SetShowVideoQuestion(true);
  // // to play video question
  
  SetCurrentQuestionIndex(CurrentQuesIndex+1)
  setRecorderAnswerTime(InterviewQuestionData[CurrentQuesIndex].answerDuration + 3);
  SetQuestionCounter(InterviewQuestionData[CurrentQuesIndex].answerDuration)
  PlayVideoQuestionTimer();
   }
   else  {
     if(thanksVideo){
      SetShowFinalThankyouPage(false);
      SetShowThanksVideo(true);
      SetShowVideoQuestion(false);
      SetShowVideoAnswer(false);
     } else{
      PreviewSubmittedAnswer(InterviewQuestionData[0].id)
      SetAnswerVideoPlaying(InterviewQuestionData[0].id)
      SetShowFinalThankyouPage(true);
      SetShowThanksVideo(false);
      SetShowVideoQuestion(false);
      SetShowVideoAnswer(false);
     }
      
    
   }
}


const [countdown,SetCountDown]=useState(3)
const [showPlayerStartingText,SetshowPlayerStartingText]=useState(false);
const [autoStart,SetAutoStart] =useState(false); 
const PlayVideoQuestionTimer = () => {
  var newcounter;
  SetCountDown(3); 
  SetshowPlayerStartingText(true);
  setTimeout(() => {
    // var ele = document.querySelector('.media-controls button');
    // if (ele ) {
    //   ele.click();
    // }   
    SetshowPlayerStartingText(false);
  }, 3500);

  var counter = 3;
  setInterval(() => {
    counter = counter -1; 
    if(counter>0){
      SetCountDown(counter); 
      if(counter >=1 ){
        SetshowPlayerStartingText(true); 
      }else{
       // SetshowPlayerStartingText(false); 
      //  if(CurrentQuesIndex > 0){
      //  onButtonClick()
      //  }
      //  var video = document.getElementById('ques-video')
    //    video.play()
      }
    }
   else {
    clearInterval(0);
   }
  },1000);
   
}



const [previewsubmittedanswer,SetPreviewSubmiitedAnswer]=useState(false);

const PreviewSubmittedAnswer = (quesId) => {
  if(answerVideoFiles)
  {   SetAnswerVideoPlaying(quesId)
    console.log(quesId)
    console.log(answerVideoFiles)
    var anwsData = answerVideoFiles.map(o => o.id == quesId).lastIndexOf(true);
    if(anwsData > -1){
      SetAnswerVideoFile(answerVideoFiles[anwsData].answerFile)
    }
  }
  SetPreviewSubmiitedAnswer(true);
} 



const [ShowUploadLoader,SetShowUploadLoader]= useState(false)
 const DisplayvideoAnswer = () => {
  if(ShowAnsVideo == false){
    SetAnswerVideoWasStopped(false)
    if(CurrentQuesIndex < InterviewQuestionData.length){
   SetreplayButtonState(false);
  // SetNextState(false);
   clearInterval(counter);
   SetShowVideoQuestion(false);
   SetShowVideoAnswer(true);
   SetIsRecording(true)
   startRecorder()
   setRecorderAnswerTime(InterviewQuestionData[CurrentQuesIndex].answerDuration + 3);
   SetQuestionCounter(InterviewQuestionData[CurrentQuesIndex].answerDuration)
  // startRecorder();
   setTimeout(() => {
     var startbutton = document.querySelectorAll('[data-qa="turn-on-camera"]');
     if( startbutton && startbutton[0]){
       startbutton[0].click();
     }
      
    },1000);
   }
 }
 }

 const [ShowRecordingText,SetShowRecordingText]=useState(false);

 const startRecorder = () => {
  let meterRefresh = null;
  let instantMeter = document.querySelector('#instant meter');
  
  setAnswerStartIn(3);
  let counterSeconds = 3
  setTimeout(() => {
    SetShowRecordingText(true);
    let intervalCond = setInterval(() => {
      //  if(isPageVisible ){
        counterSeconds = counterSeconds - 1;      
        if (counterSeconds >= 0 ) {
          setAnswerStartIn(counterSeconds);
        } else {
         
            clearInterval(intervalCond)
          
        //  ;
  
        }
     //  }
       }, 1000);
   }, 100);
   SetStopAnswerVideo(true);
  setTimeout(() => {
    var mimeType = 'video/webm'
    if(isSafari){
      mimeType = 'video/mp4'
    }
    
    media_recorder = new MediaRecorder(window.stream, { mimeType: mimeType });
    window.media_recorder = media_recorder
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
    window.audioContext = new AudioContext();
/*

   let audioContext = new AudioContext();
   let analyser = audioContext.createAnalyser();
   let microphone = audioContext.createMediaStreamSource(window.stream);
   let javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

      analyser.smoothingTimeConstant = 0.8;
      analyser.fftSize = 1024;

      microphone.connect(analyser);
      analyser.connect(javascriptNode);
      javascriptNode.connect(audioContext.destination);
      let VolumeCanvas = document.getElementById("#microphone-volume");
      console.log('VolumeCanvas',VolumeCanvas)
if(VolumeCanvas){
   let canvasContext = VolumeCanvas.getContext("2d");

      javascriptNode.onaudioprocess = function() {
          var array = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(array);
          var values = 0;

          var length = array.length;
          for (var i = 0; i < length; i++) {
            values += (array[i]);
          }

          var average = values / length;

//          console.log(Math.round(average - 40));

          canvasContext.clearRect(0, 0, 150, 300);
          canvasContext.fillStyle = '#FF0A55'; //was BadA55 (very cute)
          canvasContext.fillRect(0, 300 - average, 150, 300);
          canvasContext.fillStyle = '#F62626';
          canvasContext.font = "24px impact";
          canvasContext.fillText(Math.round(average - 40), 2, 30);
          // console.log (average);
        } // end fn stream
      }
*/
    media_recorder.addEventListener('dataavailable', function(e) {
    	blobs_recorded.push(e.data);
    });

    media_recorder.addEventListener('stop', function() {
    	let video_local = URL.createObjectURL(new Blob(blobs_recorded, { type: mimeType }));
      console.log('video bolb set',video_local)
      setVidoBlob(video_local);
      downloadVideo(CurrentQuesIndex,video_local)
    });
    
    StartQuestionTimer();    

    media_recorder.start(1000);

    const soundMeter = window.soundMeter = new SoundMeter(window.audioContext);
        soundMeter.connectToSource(window.stream, function(e) {
          if (e) {
           // alert(e);
            return;
          }
          meterRefresh = setInterval(() => {
            instantMeter = document.querySelector('#instant meter');
            if(instantMeter){
              instantMeter.value = (soundMeter.instant.toFixed(2) * 10);
            }
              //instantValueDisplay.innerText =
        //    slowMeter.value = slowValueDisplay.innerText =
         //     soundMeter.slow.toFixed(2);
         //   clipMeter.value = clipValueDisplay.innerText =
          //    soundMeter.clip;
          }, 200);
        });


    setMediaRecorderObj(media_recorder);
    var answerVideo = document.querySelector('video#answer_video')
    answerVideo.srcObject = window.stream
   // var startbutton = document.querySelectorAll('[data-qa="start-recording"]');
    // if(startbutton && startbutton[0])
    //  startbutton[0].click();
   },3400);


   setTimeout(() => {
    SetShowRecordingText(false);    
  }, 3100);

  setTimeout(() => {
    setShowStopBtn(true);    
  }, 5100);

  

 }
 
const[replayButtonState, SetreplayButtonState]=useState(false);
const [replayingQuesVideo,SetReplayingQuesVideo]=useState(false)


const ReplayVideo = () => {
  SetQuesReplayed(oldArray => [...oldArray, CurrentQuesIndex])
  SetNextState(true);
  SetShowreplayCountdown(false);
  // vidRef.play();
   var ele = document.querySelector('.media-controls button');
  if (ele ) {  

    clearInterval(counter);
    SetSeconds("00");
    SetreplayButtonState(false)
  
    ele.click();
  }
}

const [next,SetNext ]=useState(false)

// const[stop,SetStop]= useState(false)

const StartTimer = ()  => {
  SetShowreplayCountdown(true);
  SetreplayButtonState(true);

  if(!replayButtonState && !NextQues ){
    var counterSeconds = 10;
    SetIsRecordringControlsEnabled(true)
    SetSeconds(10);
    let intervalCond = setInterval(() => {
    //  if(isPageVisible ){
      counterSeconds = counterSeconds - 1;      
      if (counterSeconds >= 0 ) {
       SetSeconds(counterSeconds);
      } else {
        if(replayingQuesVideo === false){
          DisplayvideoAnswer();
          clearInterval(intervalCond)
        }
      //  ;

      }
   //  }
     }, 1000);
      SetCounter(intervalCond);
  }
  else {
    if(CurrentQuesIndex > 0 && !quesReplayed.includes(CurrentQuesIndex)){
      var counterSeconds = 10;

    SetSeconds(10);
    let intervalCond = setInterval(() => {
    //  if(isPageVisible ){
      counterSeconds = counterSeconds - 1;      
      if (counterSeconds >= 0 ) {
       SetSeconds(counterSeconds);
      } else {
        if(replayingQuesVideo === false){
        DisplayvideoAnswer();
       // startRecorder();
        SetreplayButtonState(false);
          clearInterval(intervalCond)
        }
      //  ;

      }
   //  }
     }, 1000);
     SetCounter(intervalCond);
    }else{
     DisplayvideoAnswer();
    // startRecorder();
     SetreplayButtonState(false);
    }
 
  }
 }

//  const stopRecording = (value) => {
//   clearInterval(value);
//  }



 const [questionCounter, SetQuestionCounter]= useState();
 
 
 const StartQuestionTimer = ()  => {
   
  setTimeout(() => {
    var counterSeconds = parseInt(InterviewQuestionData[CurrentQuesIndex]?.answerDuration)
    SetCounter(setInterval(() => {
     if (counterSeconds >= 0) {
      if(window.isTabFocus){
        counterSeconds = counterSeconds - 1;
        if (counterSeconds >= 0) {
        SetQuestionCounter(counterSeconds);
        }
      }
      else{
        clearInterval(counter);
      }
     } 
    }, 1000));
    
  }, 100);
  }

const StopRecorder = () => {
  
 
 var mediaRecorderState = mediaRecorderObj.state
if(mediaRecorderState != 'inactive'){
  mediaRecorderObj.stop();
  setShowStopBtn(false)
  SetStopAnswerVideo(false)
  SetIsRecording(false);
  SetShowreplayCountdown(false);
  clearInterval(counter);
  // var submianswerbtn = document.getElementById("submitanswerbtn");
  // if(submianswerbtn){
  //   submianswerbtn.click();
  // }
  
}
}

const [answerVideoFiles,SetAnswerVideoFiles] = useState([]);

const [answerVideoFile,SetAnswerVideoFile] = useState();

const downloadVideo = (Index,videoBlob) => {
  if(videoBlob) {
    console.log('downloadVideo',Index,videoBlob)
   // var file = blobToFile(videoBlob, `Video-${Date.now()}.mp4`);
   
      var blobvideourl = videoBlob//window.URL.createObjectURL(videoBlob);
      var answerVideos = answerVideoFiles
      answerVideos.push({id : InterviewQuestionData[Index].id,
      answerFile : blobvideourl})
      SetAnswerVideoFiles(answerVideos)
    }
  uploadblobfile();
  SetIsRecordringControlsEnabled(false)
}


const [uploadState, SetUploadState] = useState(false);
const histroy = useHistory();
const dispatch = useDispatch();
const logoutMethod = () => {
  dispatch(logout());
  histroy.push('/auth/signin');
};

const[VideoAnswerUrl, SetVideoAnswerUrl] =useState("")
function blobToFile(theBlob, fileName) {
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
}

 useEffect(() => {
  let mounted = true;
  var parsed = queryString.parse(props.location.search);
  var key = parsed.shareKey;
  if (isAndroid) {    
    window.location.replace(`intent://${process.env.REACT_APP_ANDROID_URL}?shareKey=${key}&module=interview#Intent;scheme=https;package=com.vrekruit.app;end`);
    return
}
 else if (isIOS) {
    window.location.replace("VRekruit://?shareKey="+key+"&module=interview");
    return
}
else{
    get_Mock_Interview_questions();
    get_Interview();
   // SetShowTermsPage(true)
}

    // // to play video question
    PlayVideoQuestionTimer();
    TakeCameraPermission();

return () => 
mounted =  false;


}, [])



  useEffect( async () => {
    SetShowAnsVideo(false)
    if(NextQues){
    await SetReplayingQuesVideo(true)
    }
  }, [NextQues]);

  useEffect( async () => {
    console.log('mediaRecorderObj',mediaRecorderObj)
  }, [mediaRecorderObj]);

  

  const TakeCameraPermission = () => {
    var counter = 0;
    var intervalId = setInterval(() => {
        counter++;
       
         if (navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: true, audio: true })
                .then(function (stream) {
                  var answerVideo = document.querySelector('video#answer_video');
                  window.stream = stream;
                  if(answerVideo){
                    
                    answerVideo.srcObject = stream;      
                  }          
                  setCameraEnabled(true)
                  clearInterval(intervalId)
                })
                .catch(function (error) {
                
                  setCameraEnabled(false)
                    alertService.alert({ message: "Please allow Camera and Microphone access to start the Interview", type: 'error' });
                });
        }
    }, 1000);

}

  const ProceedInstructions = () => {
   // myInterviewWidget.init()
    let internetStatus = navigator.onLine
    if(!internetStatus){
      alertService.alert({ message : 'Internet Disconnected', type : 'error'  });
      return false
    }
    if(!cameraEnabled){
      alertService.alert({ message: "Please allow Camera and Microphone access to start the Interview", type: 'error' });
      return false;
    }
    SetformSubmitted(true) ;
    if(terms) {
      SetShowLandingPage(false);
      SetShowTermsPage(false)
      if(introVideo)
       {
         SetShowIntroVideo(true)
       }
       else {
        SetShowVideoQuestion(true)
        
       }
    }
    }  
    

const get_Interview = () => {
    var parsed = queryString.parse(props.location.search);
    var key = parsed.shareKey;
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/candidate/get_interview?module=interview&shareKey=`+key, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          SetInterviewDetails(res.data);
        }
        else {
          histroy.push('/interview-expired');
        }
      })
      .catch(() => {
      });
  }


    const GoToHome = () => {
        var parsed = queryString.parse(props.location.search);
        var key = parsed.shareKey;
         histroy.push("/interview?shareKey="+key)
         SetShowVideoQuestion(false);
         SetShowVideoAnswer(false);
         SetShowFinalThankyouPage(false);
      }

      const OnthanksVideoEnded =()=> {
        PreviewSubmittedAnswer(InterviewQuestionData[0].id)
        SetAnswerVideoPlaying(InterviewQuestionData[0].id)
        SetShowFinalThankyouPage(true);
        SetShowThanksVideo(false);
        SetShowVideoQuestion(false);
        SetShowVideoAnswer(false);
      }
  

document.addEventListener("keydown", keydown);
document.addEventListener("keyup", keyup);
// document.addEventListener('contextmenu', event => event.preventDefault());

var ctrlKeyDown = false;
function keydown(e) { 
  if(e.keyCode == 116) {
        e.preventDefault();
  }
  else if ((e.which || e.keyCode) == 116 || ((e.which || e.keyCode) == 82 && ctrlKeyDown)) {
      // Pressing F5 or Ctrl+R
      e.preventDefault();
  } else if ((e.which || e.keyCode) == 17) {
      // Pressing  only Ctrl
      ctrlKeyDown = true;
  }
};

function keyup(e){
  // Key up Ctrl
  if ((e.which || e.keyCode) == 17) 
      ctrlKeyDown = false;
};


const [videoBlob, setVidoBlob] = useState();

const[videoEnded, SetVideoEnded] = useState(false)
const EnableInterviewButton = () => {
  SetVideoEnded(true);
}


return (
 
 <div style={{"overflowX":"hidden"}} className="bg-white" id="i">
   {!(isAndroid || isIOS) ?  
 <>        
    <Helmet>
       <title> VRekruit |  Interview </title>
    </Helmet>
{/* <LandingPage 1> */}
{showIntroVideo ? (
  <>
{/* <instuctionPage 1> */}
   <section className="bg-root-c">
    <div className="">
    
    <div className="d-flex">
    <Col md={12} className="bg-21">
    <div>
    <h1  className=" text-center text-white ml-20 pt-5 pb-5"> Introductory Video 
    </h1>
    </div>
    </Col>

    </div>
    <Col xs={12} md={12} className="text-center bg-root-c  p-rel" >
       <div className="pl-5 pr-5 pb-50px">
       <div className="ins-border">      
        <div className="d-flex justify-content-center align-items-center" style={{"width":"100%"}}>
 

        <Media className="d-block" style={{"position":"relative"}}>
        {mediaProps => (
          <div
            className="media"
           /// onKeyDown={keyboardControls.bind(null, mediaProps)}
          >
            <Player 
              src={introVideo? introVideo: ""}
              className="media-player" 
            autoPlay="true"
            playing="true"
            onEnded={onIntroVideoEnded}            
            />
            <div className="media-controls">
              {/* <PlayPause /> */}
              <CurrentTime />
              {/* <Progress /> */}
              <SeekBar />
              <Duration />
              {/* <MuteUnmute /> */}
              {/* <Volume /> */}
              {/* <Fullscreen /> */}
            </div>
          </div>
        )}
      </Media>


          </div>
        <div  className="d-flex justify-content-center align-items-center mt-5">
        <button      
        className={`btn btn-interview-download mb-5 ${isIntroVideoEnded ? "" : "no-drop"}`}
        onClick={DisplayvideoQuestion} 
        disabled={isIntroVideoEnded?false:true }
        > 
           Go To Interview
        </button> 
        </div>
      </div>
      </div>
    </Col>
    </div>
    </section> 
    </>
    ): ""}
    
{showVideoQuestion ? (
<>  
{/* <instuctionPage 1> */}
   <section className="bg-root-c">
    <div className="">
    <div className="d-flex">
    <Col md={10} className="bg-21">
    <div>
    <h1  className=" text-center text-white ml-5 pt-5 pb-5"> Video Question
    </h1>
    </div>
    </Col>
    <Col  className="bg-21">
    <div className="d-flex justify-content-end">
    <h6  className="bg-21 text-center text-white pt-5 pb-5 mt-3 text-underline c-p" 
     onClick = {GoToHome}
    > Back to Home
    </h6>
    </div>
    </Col>


    </div>
    <Col xs={12} md={12} className="text-center bg-root-c  p-rel" >
       <div className="pl-5 pr-5 pb-50px">
       <div className="ins-border">      
        <div className="d-flex justify-content-center" style={{"width":"100%"}}>
         <h2 className="pr-3">
         Question {CurrentQuesIndex+1} of { InterviewQuestionData?.length}
         </h2>

         <Col md={7}>

     
     {!showPlayerStartingText &&
        <Media className="d-block" style={{"position":"relative"}}>
        { 
        mediaProps => (
          <div
            className="media"
            onKeyDown={keyboardControls.bind(null, mediaProps)}
          >
            <Player 
              src={InterviewQuestionData? InterviewQuestionData[CurrentQuesIndex].videoPath: ""}
              poster={InterviewQuestionData? InterviewQuestionData[CurrentQuesIndex].videoQuestion ? '' : '/media/bg/audio.gif' : ''}
              className="media-player" 
              onEnded={StartTimer}
              autoPlay={true}
            />
            <div className="media-controls" style={{"margin": "auto"}}>
              <PlayPause  />
              <CurrentTime />
              {/* <Progress /> */}
              <SeekBar />
              <Duration />
              {/* <MuteUnmute /> */}
              {/* <Volume /> */}
              {/* <Fullscreen /> */}
            </div>
            {InterviewQuestionData?<h3 className="d-block mt-0 text-dark">{InterviewQuestionData[CurrentQuesIndex].question}</h3>: ''}
          </div>
        )}
      </Media>
      }

      {showPlayerStartingText   &&
        <div className="bg-vid" >
        <h1 className="recording-text" >

             Question Video Will Play Within 
           <br/>
           <span className="fs-80 text-center text-white">
             {countdown}
            </span>
             </h1>
         </div>
      }

        </Col>

        <div className={`${!showreplayCountdown ? "hideTimer":""}`}>
          <h2 className="pl-3">
            Replay Question
            <br/>
            Within
         </h2>
         <button className="btn btn-timer mt-5 mb-5"> 00:{seconds} Sec </button>
         </div>
        </div>
       
        
        <div  className="d-flex justify-content-center align-items-center mt-5">
          
         


        <button  
        id="replay-btn"
        className={`btn btn-interview-downloads mb-5 ml-5 ${replayButtonState ? "" : "no-drop"}`}        
        onClick={ReplayVideo}
        disabled={replayButtonState?false:true }
        > 
           Replay
        </button> 


        <button  
        className={`btn btn-interview-download mb-5 ml-5 ${isRecordringControlsEnabled ? "" : "no-drop"}`}        
        onClick={DisplayvideoAnswer}
        disabled={isRecordringControlsEnabled?false:true }
        > 
           Start Recording
        </button> 
        </div>
      </div>
      </div>
    </Col>
    </div>
    </section> 
    </>
    ): ""}
{showVideoAnswer && !showFinalThankyouPage && !showThanksVideo &&  (CurrentQuesIndex < InterviewQuestionData.length)? (
  <>
{/* <instuctionPage 1> */}
   <section className="bg-root-c">
    <div className="">
    
    <div className="d-flex">
    <Col md={10} className="bg-21">
    <div>
    <h1  className=" text-center text-white ml-5 pt-5 pb-5"> Record Answer
    </h1>
    </div>
    </Col>
    <Col  className="bg-21">
    <div className="d-flex justify-content-end">
    <h6  className="bg-21 text-center text-white pt-5 pb-5 mt-3 text-underline c-p" 
     onClick = {GoToHome}
    > Back to Home
    </h6>
    </div>
    </Col>

    </div>
    <Col xs={12} md={12} className="text-center bg-root-c  p-rel" >
       <div className="pl-5 pr-5 pb-50px">
       <div className="ins-border">      
        <div className="d-flex justify-content-center recorder-height" >
         <h2 className="pr-3">
         Answer {CurrentQuesIndex+1} of  {InterviewQuestionData.length} 
         </h2>
         <Col md={8} className="div-rec" >
         <div id="custom-video-id" style={{"position" : "relative"}}>
         <video id="answer_video" width="100%" autoPlay muted></video>
         <div id="meters" className="position-absolute" style={{"top" : "0", "right" :'10px', "z-index" : "99999"}}>
        <div id="instant"> 
        <div className="label d-none" >Slow:</div>          
            <meter high="0.25" max="1" value="0"></meter>
            <div className="value d-none"></div>
        </div>
        <div id="slow" className="d-none">
            <div className="label">Slow:</div>
            <meter high="0.25" max="1" value="0"></meter>
            <div className="value"></div>
        </div>
        <div id="clip" className="d-none">
            <div className="label">Clip:</div>
            <meter max="1" value="0"></meter>
            <div className="value"></div>
        </div>
    </div>      
         
        {/*} <VideoRecorder
                        onStartRecording={() => {                         
                          StartQuestionTimer();
                          SetStopAnswerVideo(true)
                        }}
                        onRecordingComplete={ videoBlob => {
                          setVidoBlob(videoBlob);
                          SetIsRecording(false);
                          SetShowreplayCountdown(false);
                          clearInterval(counter);
                          // var submianswerbtn = document.getElementById("submitanswerbtn");
                          // if(submianswerbtn){
                          //   submianswerbtn.click();
                          // }
                          downloadVideo(CurrentQuesIndex,videoBlob)
                        }}
                        onStopRecording={() => {
                          setVidoBlob(videoBlob);
                          SetIsRecording(false);
                          SetShowreplayCountdown(false);
                          clearInterval(counter);
                          // var submianswerbtn = document.getElementById("submitanswerbtn");
                          // if(submianswerbtn){
                          //   submianswerbtn.click();
                          // }
                          downloadVideo(CurrentQuesIndex,videoBlob)
                        }}

                        onTurnOnCamera={() => {
                          SetIsRecording(true);
                          startRecorder();
                        }}

                        onTurnOffCamera={() => {
                          SetIsRecording(false);
                        }} 

                        onPauseRecording={() => {
                          SetIsRecording(false);
                          clearInterval(counter);
                        }} 

                        onOpenVideoInput={() =>{
                          SetIsRecording(false);
                        }} 
                      /> */}
                      {!ShowRecordingText && showStopBtn?
                      <a onClick={StopRecorder} className="position-absolute" style={{ "zIndex": "99999","background" : "rgba(255, 255, 255, 0.4)","left" : 0, "right": 0, "width":"80px", "height":"80px", "borderRadius" : "50%", "margin": "0 auto",}}>
                       <span className="position-absolute" style={{ "border" : "none","left" : 0, "right": 0, "width":"40px", "height":"40px", "borderRadius" : "4%", "margin": "20px","background":"rgba(227, 73, 28, 0.6)"}}>&nbsp;</span>
                      </a>
                      :''
                        }
          </div>
          {ShowRecordingText ? 
            
            <p  className="recording-text"  > Recording will start within <br/>
            <span className="fs-80 text-center text-white"> {answerStartIn} </span>
            </p>

            :""
            }
          </Col>
           
          <div>
          <h2 className="pl-3">
            Submit Answer
            <br/>
             Within
         </h2>
         <button className="btn btn-timer mt-5 mb-5"> {questionCounter} Sec </button>
         </div>
        </div>
        <div  className="d-flex justify-content-center align-items-center mt-5">
        <button 
        className={`btn btn-interview-download mb-5  ${videoBlob ? "" : "no-drop"}`}        
        onClick={StopRecorder}
        id="submitanswerbtn" data-current-ques={CurrentQuesIndex}
        disabled={!showStopBtn}
        > 
           Submit Answer
        </button> 

        </div>

      </div>

      </div>
    </Col>
    </div>
    </section> 
    </>
    ): ""}
    {showTermsPage ? (
  <>
{/* <instuctionPage 1> */}
   <section className="bg-white min-75">
    <div className="">
    
    <div className="d-flex">
    <Col md={10} className="bg-21">
    <div>
    <h1  className=" text-center text-white ml-20 pt-5 pb-5 ml-10"> Important Instructions 
    </h1>
    </div>
    </Col>
    <Col  className="bg-21">
    <div className="d-flex justify-content-end">
    <h6  className="bg-21 text-center text-white pt-5 pb-5 mt-3 text-underline c-p" 
     onClick = {GoToHome}
    > Back to Home
    </h6>
    </div>
    </Col>
    </div>
    <Col xs={12} md={12} className="text-center bg-white  p-rel" >
       <div className="pl-5 pr-5 pb-50px">
       <div className="ins-border">      
   
        {interviewRules && interviewRules.map(
          x =>         {
                return <h4 key={x} className="pt-5 pb-5 text-left abcd" dangerouslySetInnerHTML={{ __html: x }} />
          }
        )}

        <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              value={terms}
              className="m-1 custm-cb-1"
              // {...formik.getFieldProps("acceptTerms")}
              onChange= {acceptTerms}
              checked={terms ===true? true:false}
            />
            <span />
            <h6 className="mt-2">
              I have read and understood all the instructions  
            </h6>
        </label>

       {formSubmitted && !terms  ?  
        <div className="fv-plugins-message-container text-left" >
            <div className="fv-help-block">Required</div>
          </div> 
      :""}
  


        <button onClick={ProceedInstructions} className="btn btn-interview-download mb-5"> 
           Click Here to Proceed
        </button>



      </div>
      </div>
    </Col>
    </div>
    </section> 
    </>
    ): ""}

{showThanksVideo ? (
  <>
{/* <instuctionPage 1> */}
   <section className="bg-root-c">
    <Col md={12} className="bg-21">
    <div>
    <h1  className=" text-center text-white ml-5 pt-5 pb-5"> Thank You Video 
    </h1>
    </div>
    </Col>

    <Col xs={12} md={12} className="text-center bbg-root-c  p-rel" >
       <div className="pl-5 pr-5 pb-50px">
       <div className="ins-border">      
        <div className="d-flex justify-content-center align-items-center" style={{"width":"100%"}}>
          
  
         <Media className="d-block" style={{"position":"relative"}}>
        {mediaProps => (
          <div
            className="media"
            onKeyDown={keyboardControls.bind(null, mediaProps)}
          >
          
          <Player 
              src={thanksVideo? thanksVideo: ""}
              className="media-player" 
            autoPlay="true"
            playing="true"
            onEnded={OnthanksVideoEnded}            
            />

            <div className="media-controls" style={{"maxWidth": "640px","margin": "auto"}}>
              <PlayPause />
              <CurrentTime />
              {/* <Progress /> */}
              <SeekBar />
              <Duration />
     
            </div>
          </div>
        )}
      </Media>
      


          </div>
      </div>
      </div>
    </Col>
    </section> 
    </>
    ): ""}

{/* ThankyouPage */}
{showFinalThankyouPage ? (
<>
<section className="bg-white mb-0">
  <Row  >
    <Col sm={12} xs={12} md={4} className="mt-50px text-center mb-50px" >
    { interviewDetails && interviewDetails.requisition?.company?.logo ?  
    <Image src={interviewDetails ? interviewDetails.requisition.company.logo: ""}  className="bg-circle"  width="120" height="110" alt="Company Logo bg-circle"></Image>
   : 
   <span  className="bg-image-logo d-flex justify-content-center align-items-center"   >
      <p className="mt-3">
       Company 
       <br/>
       Logo
       </p>
   </span>
    }
    </Col>
     
    <Col xs={12} sm={12} md={4} className="mt-50px text-center mb-50px " >
      <h6 className="text-theme-blue d-block text-left pt-2 pl-5"> 
      <img src="/media/logos/buildingreen.svg" className="icon-w30 fill-green" ></img>
      {interviewDetails ? interviewDetails.requisition.company.companyName: ""} 
       </h6>
       <h6 className="text-theme-blue d-block text-left pt-2 pl-5"> 
       <img src="/media/logos/globegreen.svg" className="icon-w30 fill-green" ></img>
       {interviewDetails ? interviewDetails.requisition.company.companyWebsite: ""} 
       </h6>
    </Col>
    <Col xs={12} sm={12} md={4} className="mt-50px text-center mb-50px" >
    <h6 className="text-theme-blue d-block text-left pt-2 pl-5"> 
      <>
      <img src="/media/logos/callgreen.svg" className="icon-w30 fill-green" ></img>
      {interviewDetails ? interviewDetails.requisition.company.companyDialCode + " ": ""}
      {interviewDetails ? interviewDetails.requisition.company.companyPhone + " ": ""}
      </>
      </h6>
      <h6 className="text-theme-blue d-block text-left pt-2 pl-5"> 
      <img src="/media/logos/addressgreen.svg" className="icon-w30 fill-green" ></img>
      {interviewDetails ? interviewDetails.requisition.company.companyAddress + " ": ""}
      </h6>
    </Col>
  </Row>
  </section>  
  <section className="" style={{"backgroundColor":"#003577","minHeight":"71vh"}}>
    <h2 className="text-center text-white d-block pt-10  mb-10"> Thank You!</h2>
      <p className= {`text-center text-white  mt-3 mb-5}`}>
      <button className="btn btn-interview" onClick={GoToHome} style={{"fontSize":"14px !important"}}> 
         Go To Home 
      </button>
      </p>

    { /*   <p className= {`text-center text-white  mt-10 mb-5 ${previewsubmittedanswer ? "d-none" : ""}`}>
      <button className="btn btn-interview" onClick={PreviewSubmittedAnswer} style={{"fontSize":"14px !important"}}> 
         Preview Answer 
      </button>
  </p> */}

      <h5 className= {`text-center text-white  mt-10 mb-5 `}>
        <Row>
      <Col md={6} sm={12} className="mr-auto" style={{"width":"100%","margin":"auto"}}>     

  {InterviewQuestionData.map((quesdata,i)=>
  <div key={i} className="d-flex justify-content-center align-items-center mt-5">
   
<div className="mt-3 d-flex align-items-center justify-content-center text-uppercase c-p" style={{"width": "60px", "height": "60px", "backgroundColor": "#59B230", "color": "white", "borderRadius": "30px"}}>{i+1}</div>
<a className={`pl-5 ${answerVideoPlaying == (quesdata.id) ? 'text-success': ''}`} onClick={()=>{PreviewSubmittedAnswer(quesdata.id)}}>Preview Interview Answer {i+1}</a>

</div>
  )}



      


      </Col>
      <Col md={6} sm={12} className="mr-auto" style={{"width":"100%","margin":"auto"}}>
      {/* <video  style={{"height":"40vh"}} controls>
       <source src={} type="video/mp4" />
      </video>   */}


      <ReactPlayer className="mr-auto" style={{"height":"30vh"}} autoPlay={true} url={answerVideoFile}  controls={true}	/>


      </Col>
      </Row>
      </h5>   
   </section>
    </>
) :""} 
</>
: 
<div className="d-flex justify-content-center align-items-center" style={{"height":"100vh"}}>
    <i className="fas fa-spinner fa-pulse fa-2x"></i>
</div> 
} 
   </div>
  );
}

export default InterviewMock;