/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Helmet from 'react-helmet'
import { Link, useHistory } from "react-router-dom";
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import alertService from "../../../_services/alertservice";

import { logout } from '../../../../actions/auth';
import DateFnsUtils from "@date-io/date-fns"; // import
import {MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker } from "@material-ui/pickers";
// import MultiRangeSlider from '../multiRangeSlider/MultiRangeSlider';

import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

import { useDispatch } from 'react-redux';
import download from 'downloadjs';



const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const initialValues = {
  status: "open",
  page: 1,
  limit: 10,
  assignedToMe: false,
  createdByMe: "0",
  orderBy: "Id",
  orderType: "ASC"
}

function ViewCompaniesListing() {
 
   const [payments, setPayments] = useState([]);
   const [staticData, setStaticData] = useState(10);
   const [searchTerm, SetSearchTerm] = useState("");
   const [createdbyme, SetCreatedbyme] = useState(false);
   const [assignedbyme, SetAssignedbyme] = useState(false);
   const [transactionType, SetTransactionType] = useState("");




// range slider code
   const [value, setValue] = React.useState([0,100 ]);
   const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function valuetext(value) {
    return `${value}`;
  }
  // range slider code



   const resetFilter = () => {   
    SetSearchTerm("");
    SetTransactionType("");
    get_List_without_filters();
    var elements  = document.getElementsByClassName("MuiInputBase-input");
    if(elements){
      elements[0].value=""
      elements[1].value=""
    }
    SetDateRangeData({
      ...dateRangeData,
      mindatelocal: "",
      mindate: "",
      maxdatelocal:"",
      maxdate:""
    })
   }

// filter option 
const SetSearchTermData = (x) => {
  let data =  x.target.value;
 if(data){
  SetSearchTerm(x.target.value);
 }
 else{
  SetSearchTerm("");
 }

} 

const SetTransactionData = (x) => {
    let data =  x.target.value;
   if(data){
     SetTransactionType(x.target.value);
   }
}

const SetAssignedbymeData = (x) => {
  SetAssignedbyme(x.target.checked);
}

const SetCreatedbymeData = (x) => {
  SetCreatedbyme(x.target.checked);
}


const [dateRangeData, SetDateRangeData] = useState(
  {
    mindatelocal:"",
    mindate:"",
    maxdatelocal:"",
    maxdate:"",
  }
)


const SetMindate = (e) => {
  let date;
  if(e?._d){
     date =e._d;
  }
  if(date){
  var dateformatted = GetFormattedDate(e._d);
  SetDateRangeData({
    ...dateRangeData,
    mindatelocal: e._d,
    mindate: dateformatted
  })
}
}


const SetMaxdate = (e) => {
  let date;
  if(e?._d){
     date =e._d;
  }
  if(date){
  var dateformatted = GetFormattedDate(e._d);
  SetDateRangeData({
    ...dateRangeData,
    maxdatelocal: e._d,
    maxdate: dateformatted
  })
}
} 



const GetFormattedDate = (date) => {
  var cdate = date;
  if(date){
  var dd = cdate.getDate();
  var mm = cdate.getMonth() + 1;
  var yyyy = cdate.getFullYear();

  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return `${yyyy}-${mm}-${dd}` 
}
}


const[minCreditFilter,SetMinCreditFilter]=useState();
const[maxCreditFilter,SetMaxCreditFilter]=useState();


// filter option 
  const get_Payment_List = () => {
 
    const token = localStorage.getItem('x-access-token');
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/admin/companies_list?orderBy&orderType&page=1&limit=1000000`;
    if(searchTerm) {
      url = url + "&searchBy=" + searchTerm;
    }
    if(transactionType) {
      url = url + "&transactionType=" + transactionType;
    }
     
    if(dateRangeData.mindate){
      url = url + "&startDate=" + dateRangeData.mindate;
    }
    if(dateRangeData.maxdate){
      url = url + "&endDate=" + dateRangeData.maxdate;
    }

    if(mincreditFilterAmount){
      url = url+ "&minCredits="+ mincreditFilterAmount;
    }

    if(maxcreditFilterAmount){
      url = url+ "&maxCredits="+ maxcreditFilterAmount;
    }
    


    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setPayments(res.data);     
             
          var min_credit = parseInt(res.minCreditsAdded);
          var max_credit= parseInt(res.maxCreditsAdded);

          SetMinCreditAmount(min_credit);
          SetMaxCreditAmount(max_credit);

        }
        else if(res.sessionExpired){
          logoutMethod();
          alertService.alert({ message :res.message, type : 'error'  });
        }  
        else {
        }
      })
      .catch(() => {
      });
  }


  const [mincreditAmount,SetMinCreditAmount]=useState();
  const [maxcreditAmount,SetMaxCreditAmount]=useState();


  const [mincreditFilterAmount,SetMinCreditFilterAmount]=useState();
  const [maxcreditFilterAmount,SetMaxCreditFilterAmount]=useState();


  const SetCreditFilter = (min, max) => {
    SetMinCreditFilterAmount(min);
    SetMaxCreditFilterAmount(max)
  }

  const dispatch = useDispatch();
  const logoutMethod = () => {
    dispatch(logout());
    history.push('/auth/signin');
};


const DownloadInvoice = async (value) => {
  const token = localStorage.getItem('x-access-token');
  var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/payment/download_invoice?id=`+ value;
const res = await fetch(url, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'x-access-token': token
    }
  })
  const blob = await res.blob();
  download(blob, 'invoice.pdf');
    
   
    
}






  const get_List_without_filters = () => {
    const token = localStorage.getItem('x-access-token');
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/payment/list?`;
    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setPayments(res.data);
          setStaticData(staticData + 10);
        }
        else {
        }
      })
      .catch(() => {
      });
  }



  useEffect(() => {
    let mounted = true;
    get_Payment_List();
    return () => mounted = false;
  }, [])

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();




  function actionFormatter(column, colIndex) {
    return (
      <div className="text-left">
        <img src="/media/VRekruitIcons/eyeorview.svg" className="view-option c-p view-eye-ico-1" title="View" alt="View" />     
      </div>
    );
  }

  function headerformatter(column, userdetails) {
    return (
      <div>
            {column.text}
            <img src="/media/VRekruitIcons/sort.svg" className="sort-icon c-p" width="30" height="30" alt="" />
      </div>
    );
  }


  function headerformatterAction(column, userdetails) {
    return (
      <div>
            {column.text}
      </div>
    );
  }






  function  TransTypeFormater(column, userdetails) {
    let type ="";
    if(userdetails.action === 'add'){
      type = "Buy"
       }  
      else if(userdetails.action === 'deduct'){
        type = "Deduct"
      }  
      else if(userdetails.action === 'hold'){
        type = "Assigned"
      }  
      else if(userdetails.action === 'release'){
        type = "Roleback"
      }
    return(
    <>  
      <span>
        {type} 
      </span>
      </>
    );
  }



  function  TransDateFormater(column, userdetails) {
    const date = new Date(column);
    const isoDate = date.toISOString();

    return(
      <span className="text-capitalize">
        {isoDate.substr(0, 10)} {isoDate.substr(11, 8)} 
      </span>
    );
  }


function  DesFormater(column, userdetails) {

    return(
    <>
        
      <span style={{"maxWidth":"380px","display":"inline-block"}}>
        {userdetails.companyAddress} 
      </span>
      </>
    );
  }



  function  CreditsFormatter(column, userdetails) {
    return(
      
      <div className="">
                 {userdetails.companyDialCode}{" "+ userdetails.companyPhone }
      </div>
    );
  }



  const actionControls = {
    onClick: (e, row, rowIndex) => {
   if(row) {
       let id = row.id;
       if (e.target.classList.contains("view-option")) {
       history.push("/comapanies-details/view/" + id)
       // get_Payment_List();
      }
      if(e.target.classList.contains("download-option")){
        DownloadInvoice(id);
      }

    }
  }
  };

  
 var columns = [   
  {
    dataField: 'companyName',
    text: "Company Name",
    sort: true,
    // formatter:TransTypeFormater,
    headerFormatter: headerformatter
  },
  
  {
    dataField: 'companyWebsite',
    text: "Company Website",
    sort: true,
    // formatter:TransDateFormater,
    headerFormatter: headerformatter
  },


  {
    dataField: 'companyAddress',
    text: "Company Address",
    sort: true,
    formatter:DesFormater,
    headerFormatter: headerformatter
  },
  {
    dataField: 'companyPhone',
    text: "Company Phone",
    sort: true,
    formatter:CreditsFormatter,
    headerFormatter: headerformatter
  },

  {
    dataField: "action",
    text: "Action",
    formatter: actionFormatter,
    // headerFormatter: headerformatter
  },
  ];


  const options = {
    paginationSize: 5,
    // custom: true,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    //  withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    onPageChange: (page, sizePerPage) => {
    },
    disablePageTitle: true,
    sizePerPageList: [ {
      text: '20', value: 20
    },{
    text: '50', value: 50
    },
    {
      text: 'All', value: payments.length
    }] // A numeric array is also available. the purpose of above example is custom the text    
  };


  const[ delId,setDeleteId] = useState();
  const DeleteUser = (delId) => {

    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/delete_user/` + delId, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setModalShow3(false);
          alertService.alert({ message: res.message, type: 'error' });
          get_Payment_List();
        }
        else {
          setModalShow3(false);
          alertService.alert({ message: res.message, type: 'error' });
        }
      })
      .catch(() => {
      });
    
  }

  
  const [modalShow3, setModalShow3] = React.useState(false);


  function DeleteConfirmationModal(props) {
    return (
    <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
    
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            <p className="text-center">
            <img alt="logo" className="brand-dim" src="/media/logos/UPDATED_VRekruit _Logo.png" />
              </p>
            <p className="text-dark text-center pt-3"> Are you sure want to delete this User's Account?</p>
          </Modal.Title>
        </Modal.Body>
        <Modal.Title className="d-flex justify-content-center align-items-center pb-5" >
        <p className="text-center">

        <Button className="btn btn-theme text-white btn-lg mr-5  pl-5 pr-5 pt-3 pb-3" style={{"minWidth": "100px"}} onClick={() => DeleteUser(delId)}> Yes </Button>
       <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green" style={{"minWidth": "100px"}}  onClick={props.onHide}>No</Button> 
       </p>
        </Modal.Title>
      </Modal>
    );
  }


  return (
    <>
      <Helmet>
        <title>
          Payment List
        </title>
      </Helmet>


      {/* subeader */}
      <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Companies Management</h3></div>
          </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
      {/* subeader */}


  <Col className={classes.root} >   
    <Row className="bg-white  d-flex flex-grow-1 align-items-center  justify-content-start mb-sm-5">
    <Col md={6} sm={6} offset= {4}  className="pr-3 pl-3 mt-3">      
      <div>
       <div className="   rounded">     
        <div>
        <h5 className="font-weight-bold f-w-600 pl-5" >List of Companies</h5>
        </div>
      </div>
      </div>
      </Col>
      {/* <Col md={6} sm={6} offset= {4}  className="pr-3 pl-3 mt-3">
      <div>
       <div className="d-flex flex-grow-1 align-items-center justify-content-end rounded mt-1">
        <div className="text-muted d-block">
           <Link to="/payment/add" type="button" className="btn btn-theme-green btn-lg mr-5 mt-2 mb-0"  >
              Buy Credits
           </Link>
       
      </div>
      </div>
      </div>
      </Col> */}
      <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5"></Col>  
      </Row>


        <Col className="Content-custom-padding" className="mb-sm-5">
          {/* <Row className="align-items-center space-around">
        


          <Col style={{"width":"max-content","display":"contents"}}>
               <span style={{ "position": "relative", "top": "-12px"}}>Date Range</span>
              </Col>
            <Col sm={4} md={2} className="mb-sm-5 ">
            <MuiPickersUtilsProvider utils={MomentUtils}>

                      <KeyboardDatePicker
                        size="small"
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Start Date"
                        id="min"
                        format="DD/MM/yyyy"
                         value={dateRangeData.mindatelocal ? dateRangeData.mindatelocal:""}
                         InputAdornmentProps={{ position: "start" }}
                        onChange={date => SetMindate(date)}
                        />
               </MuiPickersUtilsProvider>
               </Col>

               <Col sm={4} md={2} className="mb-sm-5 ">     
                <MuiPickersUtilsProvider utils={MomentUtils}>
          
                      <KeyboardDatePicker
                        size="small"
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="End Date"
                        id="max"
                        format="DD/MM/yyyy"
                        value={dateRangeData.maxdatelocal ? dateRangeData.maxdatelocal:""}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={date => SetMaxdate(date)}
                      />


                  </MuiPickersUtilsProvider>
                  </Col>

                  <Col style={{"width":"max-content","display":"contents"}}>
                   <span style={{ "position": "relative", "top": "-12px"}}>Amount</span>
                  </Col>

            <Col sm={4} md={2} className="d-flex mb-sm-5">
            <Form.Label >
              </Form.Label>
              <div style={{"position":"relative","width":"100%"}}>
          
            <div className="input-group" >
    
              <Form.Control type="text" value={searchTerm ? searchTerm : "" }  placeholder="Search..." id="search-text" className="border-radius-12" onInput={(e) => SetSearchTermData(e)}  style={{"paddingLeft":"35px",}} />
              <div className="input-group-prepend">
          
             </div>
              </div>
              <i className="fa fa-search"  style={{"position":"absolute","left":"7px","top": "50%","transform": "translate(7px, -50%)","borderRadius":"8px"}} ></i> 

              </div>
      
            </Col>

            <Col  sm={4} md={2} className="text-right mb-sm-5" >
            <Button  type="button" className="btn btn-search ml-5" id="filter-btn" onClick={get_Payment_List}  style={{"borderRadius":"8px"}} >
                 Apply
              </Button>
           
            <Button  type="button" className="btn btn-reset ml-5"  id="reset-filter-btn"  onClick={resetFilter}  style={{"borderRadius":"8px"}} >
                  Reset
              </Button>
            </Col>

          </Row> */}
         

          <div style={{"minHeight": "50vh"}}>
            {payments &&  payments.length > 20 &&  payments.length > 0  && (
              <BootstrapTable className="bg-white" keyField='id' data={payments} columns={columns} options={options} pagination={paginationFactory(options)} rowEvents={actionControls} />
            )}
           {payments &&  payments.length === 0  && (
             <>
              <BootstrapTable className="bg-white" keyField='id' data={payments} columns={columns} options={options}  rowEvents={actionControls} />
             <p className="text-center mt-5 pt-5" style={{"fontSize":"30px","minHeight":"200px","display":"flex","justifyContent":"center","alignItems":"center"}}>
                No Records Found
             </p>
              </>
           )}
            {payments  && payments.length <= 20 && payments.length > 0 &&
            (
              <BootstrapTable className="bg-white" keyField='id' data={payments} columns={columns} options={options}  rowEvents={actionControls} />
            )
            }
          </div>
        </Col>
      </Col>

      <DeleteConfirmationModal show={modalShow3} onHide={() => setModalShow3(false)} />


    </>
  );
}

export default ViewCompaniesListing