/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormFile from 'react-bootstrap/FormFile'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Table from 'react-bootstrap/Table'
import { divide } from 'lodash';
import Helmet from 'react-helmet'
import { Link, useHistory,useParams } from "react-router-dom";
import { Redirect } from 'react-router';
import alertService from '../../../_services/alertservice';
import { Button } from 'react-bootstrap';
import { CALL_HISTORY_METHOD } from 'react-router-redux';
import Modal from 'react-bootstrap/Modal'
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { useDispatch } from 'react-redux';
import { textFilter } from 'react-bootstrap-table2-filter';
import { logout } from '../../../../actions/auth';
import LimitedMultiSelect from '../CreateInterview/AutoCompleteWithLimit';
import {sortableContainer, sortableElement } from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';
import Tooltip from "@material-ui/core/Tooltip";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();




const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

// const useStyles = makeStyles(theme => ({
//   root: {
//     backgroundColor: theme.palette.background.paper,
//   },
// }));
const BlueOnGreenTooltip = withStyles({
  tooltip: {
    color: "white",
    backgroundColor: "#59B230",
    fontSize: "11px"
  }
})(Tooltip);

const SortableItem = sortableElement(({testQuestion,index,handleRemoveQues,handleClickOpen1,showEditQuesTime,showTimeUpdate, editTimeQuesId,updateQuesAnsTime, parseToHHMMSS}) => {
  return ( 
    <tr style={{'cursor':'move'}}>
    <td>{testQuestion.question}</td>
    <td>{testQuestion.interviewCategory.categoryName}</td>
    <td>{testQuestion.interviewSubCategory ? testQuestion.interviewSubCategory.subCategoryName : ''}</td> 
    <td> {showTimeUpdate && editTimeQuesId == testQuestion.id ? ( <input type="text" id={`edit-time-${testQuestion.id}`} className="form-control" defaultValue={testQuestion.answerDuration} onBlur={(e) => updateQuesAnsTime(e)} />) : (<span style={{"maxWidth":"100px"}}> {parseToHHMMSS(testQuestion.answerDuration)} <img src="/media/VRekruitIcons/clock.png" className="fa-pencil-alt c-p" onClick={() =>showEditQuesTime(testQuestion)}/></span>)}</td>
    <td><div className="text-left d-flex"><img src="/media/VRekruitIcons/eyeorview.svg" className="view-option c-p view-eye-ico-1" title="View" alt="View" onClick={() => handleClickOpen1(testQuestion)}/>
    <img src="/media/VRekruitIcons/delete.svg" className="delete-option c-p view-eye-ico ml-5" title="Delete" alt="Delete" onClick={() => handleRemoveQues(testQuestion,index)}/></div></td>
    </tr>)

} )

const SortableContainer = sortableContainer(({children}) => {
  return <table className="table table-bordered">
    <thead>
    <tr>
              <th className="text-uppercase" >Question</th>
              <th className="text-uppercase" >Parent Category</th>
              <th className="text-uppercase" >Sub Category</th>
              <th className="text-uppercase" >Time to Answer</th>
              <th className="text-uppercase" >Action</th>


            </tr>
            </thead>
            <tbody>{children}</tbody></table>;
});

{/* <tab style css statrt> */}
function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}
TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

{/* <tab style css statrt> */}

const initialValues = {
  status: "open",
  page: 1,
  limit: 10,
  assignedToMe: false,
  createdByMe: "0",
  orderBy: "Id",
  orderType: "ASC"
}

function EditInterview() {

  const [QuestionsList, setQuestionsList] = useState([]);
  const [staticData, setStaticData] = useState(10);
  const [parentCategories, setParentCategories] = useState([]);
  const [searchTerm, SetSearchTerm] = useState("");
  const [createdbyme, SetCreatedbyme] = useState(false);
  const [assignedbyme, SetAssignedbyme] = useState(false);
  const [status, SetStatus] = useState("");
  const [selectedId, setSelectedOption] = useState(true);
  const [subCategory, setSubCategory] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [categorySelected, SetcategorySelected] = useState("");
  const [subcategorySelected, SetSubcategorySelected] = useState("");
  const [difficultyLevel, setDifficultyLevel] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [fitmentsList, setFitmentsList] = useState([]);
  const [fitmentsListIds, setFitmentsIds] = useState("");
  const [selectedFitments, setSelectedFitments] = useState([]);
  const [errorText, setErrorText] = useState("")
  const [ colourOptions,  setColourOptions]=  useState([]);
  const [ AssignedDefaultOptions,  setDefaultAssignedOptions]=  useState([]);

  useEffect(() => {      
  }, [AssignedDefaultOptions])


  const [buttonState,SetButtonState] = useState({
    "previewBtnState": true,
    "addQuestionButtonState": true,
  })
  const [rangeValues, SetRangeValues ]= useState({
    "minRange": 10,
    "maxRange": 70, 
  })

  const [formData, SetFormData] = useState(
    {
      interviewTitle:"",
      // To Maintain List Of Selected Questions
      questions: [],
    }
  )

  const onSortEnd = ({oldIndex, newIndex}) => {
    var updatesTestQuestions = arrayMoveImmutable(testQuestions, oldIndex, newIndex)
   setTestQuestions(updatesTestQuestions)
   var updatedtestquestion = updatesTestQuestions.map((ele,orderId)=> {
     return { questionId: ele.id ,answerTime:ele.answerDuration,orderId:(orderId+1)}
   });
   SetAddedQuestionList(updatedtestquestion);   
   };
  const dispatch = useDispatch();
  const logoutMethod = () => {
    dispatch(logout());
    history.push('/auth/signin');
};

const handleSelectFitments = (value, i) => {
  var selectedFitmentIds = '';
  var found = '';
  if (value) {
      for (let i = 0; i < value.length; i++) {
          found = fitmentsList.find(fitment => fitment.parameterName === value[i]);
          console.log(found.id)
          selectedFitmentIds += selectedFitmentIds ? ',' + found.id : '' +found.id
      }
  }
  setSelectedFitments(value)
  setFitmentsIds(selectedFitmentIds)

}



function handleMultipeInput(value)  {
  //     e.preventDefault();        
   //   setName(value);
      setIdListItem(value);   
  };
  const setIdListItem = (name) =>{
    if(name) {
      var namelist = [];
      name.forEach(element => {
        namelist.push(element.value)
      });
      const list = namelist.join(", ");
      setFitmentsIds(list);
    }
  }

const setSt = (req) => {
  if(req && req.length) {
    var options = [];
    req.forEach(element => {
      options.push(
        { value: element.id, label: element.parameterName}
      )
    });
    setColourOptions(options);
  }
}

const  AssignedOptions = (req) => {
  if(req && req.length) {
    var options = [];
    req.forEach(element => {
      options.push(
        { value: element.id, label: element.parameterName}
      )
    });
    setDefaultAssignedOptions(options);
    var list = options.map(x=> x.value).join(",");
    setFitmentsIds(list);
  }
}


  const[bankType,SetBankType]= useState("private");
  const handleOptionChange = (e) =>  {
      var banktypeval = e;
      SetBankType(banktypeval);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }
   
  const [value, setValue] = React.useState([10, 70]);

  const handleChange = (event, newValue) => {
    
    setValue(newValue);
    SetRangeValues({
      ...rangeValues,
       minRange: newValue[0],
       maxRange: newValue[1],
    })
  };


  function valuetext(value) {
    return `${value}`;
  }
  

  const resetFilter = () => {
    SetcategorySelected("");
     SetSubcategorySelected("");
    SetSearchTerm("");
    setQuestionsList([])
  }

  // filter option 
  const SetSearchTermData = (x) => {
    let data = x.target.value;
    if (data) {
      SetSearchTerm(x.target.value);
    }
    else {
      SetSearchTerm("");
    }
  }

  function handleCategorySelect(event) {
    if (event.target.value) {
      SetcategorySelected(event.target.value);
    }
    else {
      SetcategorySelected("");
    }
    // document.getElementById("subcategoryvalue").value= ""    
    var parentId = (event.target.value);
    get_sub_categories(parentId);
  }

  function handleSubCategorySelect(event) {
    if (event.target.value) {
      SetSubcategorySelected(event.target.value);
    }
    else {
      SetSubcategorySelected("");
    }

  }


  function handleDifficultyLevelSelect(event) {
    if (event.target.value) {
      setDifficultyLevel(event.target.value);
    }
    else {
      setDifficultyLevel("");

    }

  }


  function handleQuestionTypeSelect(event) {
    if (event.target.value) {
      setQuestionType(event.target.value);
    }
    else {
      setQuestionType("");

    }

  }

  const SetAssignedbymeData = (x) => {
    SetAssignedbyme(x.target.checked);
  }

  const SetCreatedbymeData = (x) => {
    SetCreatedbyme(x.target.checked);
  }

  // filter option 
   
  var i = 1;
  const get_Question_List = () => {

    const token = localStorage.getItem('x-access-token');
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/interview/questions?page=1&orderType=DESC&limit=1000`;
    //  history.push("/searchTerm");
     if(bankType){
      url = url + "&bankType=" + bankType;
    }

    if (difficultyLevel) {
      url = url + "&difficultyLevel=" + difficultyLevel;
    }

    if (categorySelected) {
      url = url + "&categoryId=" + categorySelected;

    }

    if (subcategorySelected) {
      url = url + "&subCategoryId=" + subcategorySelected;

    }

    if (questionType) {
      url = url + "&questionType=" + questionType;

    }

    if (searchTerm) {
      url = url + "&searchBy=" + searchTerm;
    }

    if(rangeValues){
      url = url + "&minMarks=" +  rangeValues.minRange  +  "&maxMarks=" +  rangeValues.maxRange
    }

    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          var questionsList = res.data.filter(x=> testQuestions.findIndex(que => que.id == x.id) < 0);
          setQuestionsList(questionsList);
          setStaticData(staticData + 10);
        }else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
        else {
        }
      })
      .catch(() => {
      });
  }

  const get_fitments_list = () => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/fitments?page=1&limit=10000`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'x-access-token': token
        },
    })
        .then((res) => res.json())
        .then((res) => {
            if (res.status == true) {
                setFitmentsList(res.data);
                setSt(res.data)
                if(fitmentsListIds){
                  var selectedFitmentName =[]
                  var fitmentIds =  fitmentsListIds.split(',')
                  if(fitmentIds && fitmentIds.length){
                  for(var i = 0; i < fitmentsList.length; i++){
                    for(var j =0; j < fitmentIds.length; j++){
                      if(fitmentIds[j] == fitmentsList[i].id){
                        selectedFitmentName.push(fitmentsList[i].parameterName)
                     }
                   }
                 }
               }
                setSelectedFitments(selectedFitmentName)
              }
            }
            else if (res.sessionExpired) {
                alertService.alert({ message: res.message, type: 'error' });
                logoutMethod();
            }
            else {
            }
        })
        .catch(() => {
        });
}

  let { id } = useParams();

  const get_Interview_QuestionListdata = () => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/get_interview?id=`+id, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setFitmentsIds(res.data.fitments)          
          AssignedOptions(res.data.fitmentsList)

            SetFormData({
                ...formData,
                interviewTitle: res.data.interviewTitle,
                fitments: res.data.fitments,
              });
              var test = res.data.interviewQuestions;
              var testQue = test.map(x=> {
                x.interviewQuestionDetail.answerDuration = x.answerTime;
                return x.interviewQuestionDetail;
              });
              setTestQuestions(testQue);
              var updatedtestquestion = testQue.map((ele,orderId)=> {
                return { questionId: ele.id ,answerTime:ele.answerDuration, orderId:(orderId+1)}
              });
              SetAddedQuestionList(updatedtestquestion);


              var TimeinSeconds = res.data.totalAnswerTime;
              var formattedTime =  parseToHHMMSS(TimeinSeconds);
              SetTotalAnswerTime(formattedTime); 
        }
        else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
        else {
        }
      })
      .catch(() => {
      });
  }

  


  const get_List_without_filters = () => {
    const token = localStorage.getItem('x-access-token');
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/interview/questions?page=1&orderType=DESC&limit=1000`;
    if(bankType){
      url = url + "&bankType=" + bankType;
    }
    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setQuestionsList(res.data);
          setStaticData(staticData + 10);
        }
        else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
        else {
        }
      })
      .catch(() => {
      });
  }


  const get_parent_categories = (values) => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/categories?page=1&limit=1000`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(values)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setParentCategories(res.data);
        }
        else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
        else {
        }
      })
      .catch(() => {
      });
  }


  useEffect(() => {
    let mounted = true;
    // get_Question_List();
    get_fitments_list();
    get_parent_categories();
    get_Interview_QuestionListdata();
    return () => mounted = false;
  }, [])

  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    let mounted = true;
    if(fitmentsListIds && fitmentsListIds.length && fitmentsList && fitmentsList.length){
      var selectedFitmentName =[]
      var fitmentIds =  fitmentsListIds.split(',')
      if(fitmentIds && fitmentIds.length){
      for(var i = 0; i < fitmentsList.length; i++){
        for(var j =0; j < fitmentIds.length; j++){
          if(fitmentIds[j] == fitmentsList[i].id){
            selectedFitmentName.push(fitmentsList[i].parameterName)
         }
       }
     }
   }
    setSelectedFitments(selectedFitmentName)
  }
  }, [fitmentsListIds,fitmentsList])

  const[ questionID,SetQuestionId] = useState("");
  const [QuestionData,setQuestionData]= useState({})
  const [editTimeQuesId, setEditTimeQuesId] = useState('')
const [showTimeUpdate, setShowTimeUpdate] = useState(false)


const showEditQuesTime = (testQuestion) => {
  setEditTimeQuesId(testQuestion.id)
  setShowTimeUpdate(true)
}

const updateQuesAnsTime = (event) => {
  const newValue = event.target.value
  console.log()
  var inputId = event.target.id.replace('edit-time-', "");
  console.log(newValue)
  console.log(testQuestions)
  setTimeout(() => {
    if( parseInt(newValue) > 0 &&  parseInt(newValue) <  5401 && Number.isInteger(parseInt(newValue)) &&   newValue % 1 === 0  ){
  //  done(true); // contine to save the changes
      var updatedtestquestion1 = testQuestions.map(x => (x.id == inputId ? { ...x, answerDuration: newValue } : x));
      console.log(updatedtestquestion1)
      SetAddedQuestionList(updatedtestquestion1);
      setTestQuestions(updatedtestquestion1)
      
  //    var updatedtestCards = cards.map(x => (x.id == inputId ? { ...x, answerDuration: newValue } : x));
   //   setCards(updatedtestCards);
      var updatedtestquestion = testQuestions.map((ele,orderId)=> {
      return { questionId: ele.id ,answerTime:ele.answerDuration,orderId : (orderId+1)}
    });
    SetAddedQuestionList(updatedtestquestion);
    
    var TimeinSeconds = testQuestions.map(x => x.answerDuration).reduce((a, b) => parseInt(a) + parseInt(b), 0)
    var formattedTime =  parseToHHMMSS(TimeinSeconds);
       SetTotalAnswerTime(formattedTime);
       setShowTimeUpdate(false)
  }
     else {
    //  done(false); // reject the changes
      if(newValue < 5401){
        alertService.alert({ message : "Time to answer should be more than 0 seconds", type : 'warning'  });

      }
      else {
        alertService.alert({ message : "Time to answer should not be more than 5400 seconds", type : 'warning'  });
      }                    }
  }, 0);

}

  const actionControls = {
    onClick: (e, row, rowIndex) => {
      if (row) {
        let id = row.id;
        if (e.target.classList.contains("view-option")) {
          SetQuestionId(row.id);
          setQuestionData(row);
          handleClickOpen();

        }
        else if(e.target.classList.contains("delete-option")) {
           SetDelId(row.id);
           SetModalShow3(true);
           SetRowIndex(rowIndex);
        }
      }
    }
  };

  const handleClickOpen1 = (row) => {
    SetQuestionId(row.id);
    setQuestionData(row);
    setOpen(true);
  };
  const handleRemoveQues = (ques,index) =>{

          SetDelId(ques.id);
           SetModalShow3(true);
           SetRowIndex(index);
           showCreatePage()
           showPreviewPage()
  }

  const[rowindexvalue,SetRowIndex] = useState();

  

  const deletethisques = (delId) => {
    const newtestQuestions = [...testQuestions ];
    var deletedquestionIndex =  newtestQuestions.findIndex(obj => 
      obj.id === delId // just pick this one
   )
   var deletedquestion = newtestQuestions.splice(deletedquestionIndex,1);
    deletedQuestionList.push({ "questionId": delId})
    // readd deleted question in mainlist
    QuestionsList.push(deletedquestion[0]);
    QuestionsList.map(x => x);
    var selectedQuestions = formData.questions.filter(x=> x.questionId == deletedquestion.id);
    SetFormData({
       ...formData,
      questions: selectedQuestions
    });
    //  updated question after deletation
    // SetAddedQuestionList(newtestQuestions);
    setTestQuestions(newtestQuestions)   
    var updatedtestquestion = newtestQuestions.map((ele,orderId)=> {
      return { questionId: ele.id ,answerTime:ele.answerDuration,orderId:(orderId+1)}
    });
    SetAddedQuestionList(updatedtestquestion);

    var TimeinSeconds = newtestQuestions.map(x => x.answerDuration).reduce((a, b) => parseInt(a) + parseInt(b), 0)
    var formattedTime =  parseToHHMMSS(TimeinSeconds);
    SetTotalAnswerTime(formattedTime); 
    SetModalShow3(false);
  }


  function actionFormatter(column, colIndex) {
    return (
      <div className="text-left d-flex">
        <img src="/media/VRekruitIcons/eyeorview.svg" className="view-option c-p view-eye-ico-1" title="View" alt="View"  />
      </div>
    );
  }


  function actionFormatterTestQuestion(column, colIndex) {
    return (
      <div className="text-left d-flex">
        <img src="/media/VRekruitIcons/eyeorview.svg" className="view-option c-p view-eye-ico-1" title="View" alt="View"  />

        <img src="/media/VRekruitIcons/delete.svg" className="delete-option c-p view-eye-ico ml-5" title="Delete" alt="Delete"  />

      </div>
    );
  }


  function CreatedByUserFormater(column, colIndex) {
    return (
      <span>
        {column.firstName}  {column.lastName}
      </span>
    );
  }

  function CreatedByUserFormaterTest(columnsTestQustionData, colIndex) {
    return (
      <span>
        {columnsTestQustionData.firstName}  {columnsTestQustionData.lastName}
      </span>
    );
  }

  function headerformatter(column, colIndex) {
    return (
      <div>
        {column.text ? column.text: ""}
        <img src="/media/VRekruitIcons/sort.svg" className="sort-icon c-p" width="30" height="30" />
      </div>
    );
  }

  function headerformatterAction(column, colIndex) {
    return (
      <div>
        Action
      </div>
    );
  }

  const get_sub_categories = (parentId) => {
    if (parentId) {
      const token = localStorage.getItem('x-access-token');
      fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/sub_categories?page=1&limit=1000&parentCategoryId=` + parentId, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'x-access-token': token
        },
        // body: JSON.stringify(values)
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status == true) {
            setSubCategories(res.data);
          }
          else if(res.sessionExpired){
            alertService.alert({ message :res.message, type : 'error'  });
            logoutMethod();
          }

        })
        .catch(() => {
        });
    }
    else {
      setSubCategories([]);
    }
  }

  const history = useHistory();
  const Save_Interview = (e) => {
    setFormSubmmmited(true);
    if(!(formData.interviewTitle && formData.interviewTitle.replace(/\s/g, '').length > 0))
    {
         return  "";
    }

    if(addedQuestionList.length > 0){

      var TimeinSeconds = testQuestions.map(x => x.answerDuration).reduce((a, b) => parseInt(a) + parseInt(b), 0)
      if(TimeinSeconds <= 5400){

        const reqdata = {
          id: id,
          interviewTitle: formData.interviewTitle,
          fitments: fitmentsListIds,
          questions:  addedQuestionList,
          deletedQuestions: deletedQuestionList
        } 

   
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/update`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(reqdata)      
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {

          alertService.alert({ message: res.message, type: 'info' });
          history.push('/interview/manage-interview');

        }else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
        else {
          if (res.errors) {
            var errmsg = "";
            res.errors.forEach(function (value, index, array) {
              errmsg = errmsg + "(" + (index + 1) + ") " + value.msg + "\n\r";

            });
            alertService.alert({ message: errmsg, type: 'error' });

          }

          else {
            alertService.alert({ message: res.message, type: 'error' });
          }
        }

      })
      .catch(() => {

      });
    }
    else{
      alertService.alert({ message : "Max Allowed  Answer Duration is 90 Minutes", type:'warning'});

    }

    }
    else {
      alertService.alert({ message: "Please Add Question First to Create Interview", type: 'error' });
    }
  }

const [ isformSubmitted,setFormSubmmmited]=useState(false)
 
  const save_share_test = (e) => {
  
    setFormSubmmmited(true);
    if(!(formData.interviewTitle && formData.interviewTitle.replace(/\s/g, '').length > 0))
    {
         return  "";
    }
    
    if(addedQuestionList.length > 0){
      var TimeinSeconds = testQuestions.map(x => x.answerDuration).reduce((a, b) => parseInt(a) + parseInt(b), 0)
      if(TimeinSeconds <= 5400){
    const reqdata = {
      id:id,
      interviewTitle: formData.interviewTitle,
      fitments: fitmentsListIds,
      questions:  addedQuestionList,
      deletedQuestions: deletedQuestionList
     } 

    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/update`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(reqdata)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          alertService.alert({ message: res.message, type: 'info' });
          history.push('/interview/share-interview/'+ id);

        }else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
        else {
          if (res.errors) {
            var errmsg = "";
            res.errors.forEach(function (value, index, array) {
              errmsg = errmsg + "(" + (index + 1) + ") " + value.msg + "\n\r";

            });
            alertService.alert({ message: errmsg, type: 'error' });

          }

          else {
            alertService.alert({ message: res.message, type: 'error' });
          }
        }

      })
      .catch(() => {

      });

    }
    else{
      alertService.alert({ message : "Max Allowed  Answer Duration is 90 Minutes", type:'warning'});
    }
    }
    

      else {
        alertService.alert({ message: "Please Add Question First to Create Interview", type: 'error' });
  
      }

    
  }

  const [isSelected, SelectedQuestion] = useState(false);
  const [testQuestions, setTestQuestions] = useState([]);
 

  const setSelectedValue = (value) => {
    if (formData.questions.find(x => x.questionId == value.id)) {
      formData.questions.splice(formData.questions.findIndex(x => x.questionId == value.id), 1)
    } 
    else {
      formData.questions.push({ questionId: value.id ,answerTime:value.answerDuration});
    }
    SetFormData({
      ...formData,
      questions: formData.questions
    });
  }

  const settestName = (event) => {
    SetFormData({
      ...formData,
      interviewTitle: event.target.value
    })
  }

  const [totalAnswerTime, SetTotalAnswerTime] = useState();  
  const[ totalAnswerTimeINSeconds,SettotalAnswerTimeINSeconds] = useState();
  const [addedQuestionList,SetAddedQuestionList] = useState([]);
  const [deletedQuestionList,SetDeletedQuestionList ] = useState([]);


  function parseToHHMMSS(sec) {
    var sec_num = parseInt(sec, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return(hours === "00" ? "" :  hours +' hour ')
          + ( minutes === "00" ? "" :  minutes === "01" ? minutes  + ' minute ' : minutes  +' minutes ' )
          + ( seconds === "00" ? "" :  seconds === "01" ? seconds  + ' second ' : seconds  +' seconds ' )
    }


    
  const addQuestionsToTest = () => {
    setFormSubmmmited(true);
    if(!(formData.interviewTitle && formData.interviewTitle.replace(/\s/g, '').length > 0))
    {
         return  "";
    }

    else{

    var testQuestionsCount = testQuestions.length;
    var newtestQuestions = testQuestions.map(x=> x);
    var localQuestionsList = QuestionsList.map(x=> x);

    formData.questions.forEach(que => {
      var ind =  localQuestionsList.findIndex(x => x.id == que.questionId);
      if (ind > -1) {
      var element = localQuestionsList.splice(ind, 1);
      newtestQuestions.push(element[0]);
      }

      var updatedtestquestion = newtestQuestions.map((ele,orderId)=> {
        return { questionId: ele.id ,answerTime:ele.answerDuration,orderId:(orderId+1)}
      });
      SetAddedQuestionList(updatedtestquestion);
    });
    var TimeinSeconds = newtestQuestions.map(x => x.answerDuration).reduce((a, b) => parseInt(a) + parseInt(b), 0)
    // if (TimeinSeconds <= 5400) {
      setTestQuestions(newtestQuestions);
      setQuestionsList(localQuestionsList.map(x=> x));
      SettotalAnswerTimeINSeconds(TimeinSeconds)
     var formattedTime =  parseToHHMMSS(TimeinSeconds);
      SetTotalAnswerTime(formattedTime);    
      if(newtestQuestions.length > testQuestionsCount){
        alertService.alert({ message : "Questions Added Successfully", type : 'success'  });
      }
      else {
        alertService.alert({ message : "Please Select Questions First!", type : 'error'  });
      }
    // }
    }
  }

  function selectCheckboxFormatter(column, colIndex) {
    var checked = false
    var exists = formData.questions.some(el => el.questionId === column)
    if(exists){
      checked = true
    }
    return (
      <div className="text-left">
        <Form.Check inline label="" className="ml-2 mt-2" defaultChecked={checked} value={column} name="questionId" onChange={() => setSelectedValue(colIndex)} />
      </div>
    );
  }


  function TimetoAnsweFormatter(column, colIndex) {
    var parsedTime = parseToHHMMSS(column)
    return (      
      <>
         <span style={{"maxWidth":"100px"}}> 
         {parsedTime}
       {/* {column +" "}  seconds */}
        </span>
        </>
    );
  }


  function TimetoAnswePreviewScreenFormatter(column, colIndex) {
    var parsedTime = parseToHHMMSS(column)
    return (      
      <>
         <span style={{"maxWidth":"100px"}}> 
         {parsedTime}
       { /* { column? column +" ":"" }  seconds */}
        <img src="/media/VRekruitIcons/clock.png" className="fa-pencil-alt c-p" />
        </span>
        </>
    );
  }

  function selectallFormatter(column, colIndex) {
    return (
      <div className="text-left">
        {/* <Form.Check inline label="" className="ml-2 mt-2" /> */}
      </div>
    );
  }

  var columns = [
    {
      dataField: 'id',
      formatter: selectCheckboxFormatter,
      headerFormatter: selectallFormatter,
      editable: false
    },

    {
      dataField: 'question',
      text: "Question",
      sort: true,
      editable: false,
      headerFormatter: headerformatter
    },
   
    {
      dataField: 'interviewCategory.categoryName',
      text: "Parent Category",
      sort: true,
      editable: false,
      headerFormatter: headerformatter
    },
    {
      dataField: 'interviewSubCategory.subCategoryName',
      text: "Sub Category",
      sort: true,
      editable: false,
      headerFormatter: headerformatter
    },

    {
      dataField: 'answerDuration',
      text: "Time to Answer",
      sort: false,
      formatter: TimetoAnsweFormatter,
      headerFormatter: headerformatter
    },

    {
      dataField: "action",
      text: "Action",
      sort: true,
      editable: false,
      formatter: actionFormatter,
      headerFormatter: headerformatterAction
    },
  ];


  var columnaddedquestion = [

    {
      dataField: 'question',
      text: "Question",
      sort: true,
      editable: false,
      headerFormatter: headerformatter
    },
   
    {
      dataField: 'interviewCategory.categoryName',
      text: "Parent Category",
      sort: true,
      editable: false,
      headerFormatter: headerformatter
    },
    {
      dataField: 'interviewSubCategory.subCategoryName',
      text: "Sub Category",
      editable: false,
      sort: true,
      headerFormatter: headerformatter
    },

    {
      dataField: 'answerDuration',
      text: "Time to Answer",
      sort: true,
      editable: true,
      // filter: textFilter('.','+','-'),
      formatter: TimetoAnswePreviewScreenFormatter,
      headerFormatter: headerformatter
    },

    {
      dataField: "action",
      text: "Action",
      editable: false,
      sort: false,
      formatter: actionFormatterTestQuestion,
      headerFormatter: headerformatterAction
    },
  ];




  const options = {
    paginationSize: 5,
    // custom: true,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    //  withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    
    onPageChange: (page, sizePerPage) => {
   
    },
    disablePageTitle: true,
    sizePerPageList: [ {
      text: '20', value: 20
    },{
    text: '50', value: 50
    },
    {
      text: 'All', 
    }] // A numeric array is also available. the purpose of above example is custom the text    
  };

  const [showpage, setShowPage] = useState({
    showCreateTest: false,
    showPreviewTestPage: true,
  })



  const showCreatePage = () => {
    setShowPage({
      ...showpage,
      showCreateTest: true,
      showPreviewTestPage: false
    })
    QuestionsList.map(x => x);
  }


  const [disablenexttab,SetDisablenexttab]=useState(false);



  const showPreviewPage = () => {
    SetDisablenexttab(true);
 if(formData.interviewTitle){
    setShowPage({
      ...showpage,
      showCreateTest: false,
      showPreviewTestPage: true
    })
  }
  }




  const blockInvalidChar = e => ['e', 'E', '+', '-','.'].includes(e.key) && e.preventDefault();



  const [modalShow, setModalShow] = React.useState(false);

  function ShowQuestionAnswerModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
        <p   onClick={props.onHide} className="c-p custom-close-css text-right" style={{"height":"10px"}} aria-hidden="true">×</p>
        <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          >
          <Tab label="Question" />
          <Tab label="Answer"   />
        </Tabs>
      </AppBar>

      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
       <TabContainer dir={theme.direction}>
            

       <Row>
          <Form.Label className="fw-600" column sm={3}>
             Question
          </Form.Label>
          <Col sm={9} md={7}>
          <Form.Control as="text" >
           {QuestionData ? QuestionData.question: "" }
          </Form.Control>        
          </Col>          
        </Row>


        <Row className="mt-5">
          <Form.Label className="fw-600" column sm={3}>
             Question Image
          </Form.Label>
          <Col sm={9} md={7}>
          <Form.Control as="text">
          {QuestionData ? QuestionData.imagePath: "" }
          </Form.Control>        
          </Col>          
        </Row>

        </TabContainer>
    <TabContainer dir={theme.direction}  >
    <Table responsive>
  <thead>
    <tr className="text-center">
      <th>Type Answer* </th>
      <th>Choose Correct* </th>
      <th>Answer Image</th>
    </tr>
  </thead>
  <tbody>

    {QuestionData &&  QuestionData.answers && QuestionData.answers.map(x=>
    <tr className="text-center">  
       <td style={{"maxWidth":"150px"}}>  
      <Form.Control as="textarea" placeholder="type here..." rows={3} 
        name='answers'
        // data-index={ind}
        data-name='answer'
     >
      {x.answer}
     </Form.Control>

    </td>   
    {['radio'].map(type => (
    <div key={`custom-inline-${type}`} className="mb-3 mt-3">
    <Form.Check inline pr-3 label="Correct" name="correctOption" type={type} id={`inline-${type}-1`}
      name='answers'
      data-name='rightAnswer'
      checked={x.rightAnswer}
    />


    </div>
  ))}        
  </tr>
   )  }
  </tbody>
</Table>
{/* <button type="submit" className="submit-btn"> submit button </button> */}          
      </TabContainer>
      </SwipeableViews>
        </Modal.Body>
      
      </Modal>
    );
  }



  const [modalShow3, SetModalShow3] = React.useState(false);
  const[delId, SetDelId]= useState(); 

  function DeleteConfirmationModal(props) {
    return (
    <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
    
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            <p className="text-center">
            <img alt="logo" className="brand-dim" src="/media/logos/UPDATED_VRekruit _Logo.png" />
              </p>
            <p className="text-dark text-center pt-3"> Are you sure want to remove  this question?</p>
          </Modal.Title>
        </Modal.Body>
        <Modal.Title className="d-flex justify-content-center align-items-center pb-5" >
        <p className="text-center">

        <Button className="btn btn-theme text-white btn-lg mr-5  pl-5 pr-5 pt-3 pb-3" style={{"minWidth": "100px"}} onClick={() => deletethisques(delId)}> Yes </Button>
       <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green" style={{"minWidth": "100px"}}  onClick={props.onHide}>No</Button> 
       </p>
        </Modal.Title>
      </Modal>
    );
  }


  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);
  const [open, setOpen] = React.useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('lg');


  function CustomizedDialogs() {  
    return (
      <div>

        <Dialog 
        onClose={handleClose} 
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        fullScreen={fullScreen}
        aria-labelledby="customized-dialog-title" 
        open={open}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          </DialogTitle>
          <DialogContent >
            <Typography gutterBottom>
            <Row className="pt-5" >
              <Form.Label className="fw-600" column sm="2">
                Question Text
              </Form.Label>
              <Col sm="10">
                <Form.Group as={Col}  >
                  <p className="mt-2 css-p-tag" >
                        {QuestionData ? QuestionData.question: "" }
                    </p>
                </Form.Group>
              </Col>
            </Row>
            </Typography>
            <Typography gutterBottom>
            <Row className="pt-5" >
              <Form.Label className="fw-600" column sm="2">
                Question Video
              </Form.Label>
              <Col sm="10">
                <Form.Group as={Col}  >
                   <video className="video-container video-container-overlay modal-video-player" autoPlay="false" loop muted={false}
                   controls
                   poster={QuestionData.videoQuestion ? '' : '/media/bg/audio.gif'}
                   preload="metadata"
                   src={QuestionData ? QuestionData.videoPath: "" }
                   >
                  </video>
                </Form.Group>
              </Col>
            </Row>
            </Typography>
            <Typography gutterBottom>
            <Row className="pt-5" >
              <Form.Label className="fw-600" column sm="2">
                Keywords
              </Form.Label>
              <Col sm="10">
                <Form.Group as={Col}  >
                <p className="mt-2 css-p-tag" >
                {QuestionData ? QuestionData.keywords: "" }
                  </p>
                </Form.Group>
              </Col>
            </Row>
            </Typography>
          </DialogContent>
       
        </Dialog>
      </div>
    );
  }





  return (
    <>
      <Helmet>
        <title>
          Update Interview
        </title>
      </Helmet>


      {/* subeader */}
      <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Interview Management</h3></div>
          </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
      {/* subeader */}



      {/*Start  addQuestionSection */}
      { showpage.showCreateTest ? (
        <Col className={classes.root} >
          <Row className="bg-white  d-flex flex-grow-1 align-items-center  justify-content-start sticky-header">
            <Col md={6} sm={6} offset={4} className="pr-3 pl-3 mt-3">
              <div>
                <div className="   rounded">
                  <div>
                    <h5 className="font-weight-bold f-w-600 pl-5" > Update Interview </h5>
                    <p className="text-b f-w-600 pl-5 ">
                    {/* <img width="24" src="/media/VRekruitIcons/costblue.svg" alt="" class="flaticon2-profile text-success bg-cost mr-3"/> */}

                    Credit Balance: V {localStorage?.getItem("companyCreditBalance")   }</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} sm={6} offset={4} className="pr-3 pl-3 mt-3">
              <div>
                <div className="d-flex flex-grow-1 align-items-center justify-content-end rounded mt-1">
                  <div className="text-muted d-block">
                    <Link to="#" onClick={showPreviewPage} type="button" 
                    className={`btn btn-theme-green btn-lg mr-5 mt-2 mb-0 ` }>
                      Preview Interview
                    </Link>
                    <Button type="button" 
                       className={`btn btn-theme-green btn-lg mr-5 mt-2 mb-0 ${formData.questions.length==0 ? "p-none" : ""}`}
                       onClick={addQuestionsToTest}>
                      Add Question
                    </Button>
                    <Link to="/interview/manage-interview" type="button" className="btn btn-theme text-white btn-lg mr-5 mt-2 mb-0"  >
                      Cancel
                </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5"></Col>
          </Row>
          <Col className="Content-custom-padding mt-5">
            <Row >
              <Form.Label className="fw-600" column sm="2">
                Interview Name* <BlueOnGreenTooltip title="Please use Interview Name matching with the Job Requisition.  Ensure Interview Round No. is mentioned in Suffix for future reference" placement="top">
                <i className='fa fa-info text-primary bd-primary' style={{'font-size':'smaller'}}></i></BlueOnGreenTooltip>
                    </Form.Label>
              <Col sm="7" className="ml-0 pl-0" >
                <Form.Group as={Col} >
                  <Form.Control type="text"
                    name="interviewTitle"
                    value={formData.interviewTitle}
                    onChange={(e) => settestName(e)}

                  >
                  </Form.Control>
                  {isformSubmitted && !formData.interviewTitle ?
                     <div className="fv-plugins-message-container" >
                <div className="fv-help-block">Required</div>
              </div> 
               : null} 
                 {isformSubmitted && formData.interviewTitle && !formData.interviewTitle.replace(/\s/g, '').length    ?
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block pl-3">Required</div>
                  </div>
                  :null}

                  {!isformSubmitted && disablenexttab && !formData.interviewTitle ?
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block pl-3">Required</div>
                  </div>
                  :null}
                </Form.Group>
              </Col>
            </Row>

            {/* <Row>
              <Form.Label  className="fw-600"  column sm="2">
                Total Answer Time
                    </Form.Label>
              <Col sm="7" className="ml-0 pl-0" >
                <Form.Group as={Col} >
                  <Form.Control type="text"
                    name="" value={totalAnswerTime ? totalAnswerTime   : ""}
                    readOnly>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row> */}

            <Row as={Row} controlid="formaBankType"
              style={{ "display": "flex", "alignItems": "baseline" }}>
              <Form.Label className="fw-600" column sm="2">
                Select Interview Question
                    </Form.Label>
              <Col sm="5">
                <Form.Group as={Col}  >
                  {['radio'].map((type) => (
                    <div key={`inline-${type}`} className="mb-3 ml-0" style={{ "display": "flex", "justifyContent": "space-between", "width": "100%" }}>
                      <Form.Check inline label="Private Question Bank" className="pr-3-inp" name="bankType" value="private" type={type} id={`inline-${type}-1`} 
                       onChange={() => handleOptionChange("private")}
                       checked={ bankType === "private" ? true : false}
                      />
                      <Form.Check inline label="Public Question Bank" className="pr-3-inp" name="bankType" value="public" type={type} id={`inline-${type}-2`} 
                       onChange={() => handleOptionChange("public")}
                       checked={ bankType === "public" ? true : false}
                      />

                    </div>
                  ))}
                </Form.Group>
              </Col>
            </Row>
            <Row className="pt-5"  >
                          <Form.Label className="fw-600" column sm="2">
                              Select Fitment Parameter 1
              </Form.Label>
              <Col sm="7" className="ml-0 pl-0" >
              <Form.Group as={Col}  >
                      <Select closeMenuOnSelect={false} components={animatedComponents} value={AssignedDefaultOptions} isMulti options={colourOptions}
           onChange={handleMultipeInput} /> 

                              </Form.Group>
                              </Col>

                      </Row>

            <Row className="align-items-center mt-0">
              <Col sm={4} md={3} className="mb-2">
                <Form.Control as="select" placeholder="Status" value={categorySelected} onChange={(e) => handleCategorySelect(e)} >
                  <option value="">Parent Category</option>
                  {parentCategories.map
                    (x => <option value={x.id}>{x.categoryName}</option>)
                  }
                </Form.Control>
              </Col>

              <Col sm={4} md={3} className="mb-2">
                <Form.Control as="select" placeholder="-Sub Category-" value={subcategorySelected} onChange={(e) => handleSubCategorySelect(e)} >
                  <option value="">Sub Category</option>
                  {subCategories.map
                    (x => <option value={x.id}>{x.subCategoryName}</option>)
                  }
                </Form.Control>
              </Col>
     
              <Col sm={4} md={4} className="d-flex mb-2">
                <div style={{ "position": "relative" }}>
                  <div className="input-group">
                    <Form.Control type="text" value={searchTerm ? searchTerm : ""} placeholder="Search..." id="search-text" onInput={(e) => SetSearchTermData(e)} style={{ "paddingLeft": "35px", }} />
                    <div className="input-group-prepend">
                    </div>
                  </div>
                  <i className="fa fa-search" style={{ "position": "absolute", "left": "7px", "top": "50%", "transform": "translate(7px, -50%)", "borderRadius": "8px" }} ></i>
                </div>
              </Col>

              <Col sm={4} md={2}  className="mb-2 text-right">
                <Button type="button" className="btn btn-search ml-5 mb-sm-2" id="filter-btn" onClick={get_Question_List} style={{ "borderRadius": "8px" }} >
                  Apply
              </Button>

              <Button type="button" onClick={resetFilter}  className="btn btn-reset ml-5   mb-sm-2 btn btn-primary" id="filter-btn"  style={{ "borderRadius": "8px" }} >
                  Reset              
              </Button>
              </Col>

            </Row>
            <div>
            {QuestionsList &&  QuestionsList.length > 20 &&  QuestionsList.length > 0  && (
              <BootstrapTable 
              cellEdit={ cellEditFactory({ 
              beforeSaveCell:(oldValue, newValue, row, column, done) => {
                setTimeout(() => {
                  if( parseInt(newValue) > 0 && Number.isInteger(parseInt(newValue)) &&   newValue % 1 === 0  ){
                  done(true); // contine to save the changes
                }
                   else { 
                    done(false); // reject the changes
                    alertService.alert({ message : "Time to answer should be more than 0 seconds", type : 'warning'  });

                  }
                }, 0);
                return { async: true };
              },
              blurToSave: true,
                mode: 'click',
            }) }
            
              className="bg-white" keyField='id' data={QuestionsList} columns={columns} options={options} pagination={paginationFactory(options)} rowEvents={actionControls} />
            )}
           {QuestionsList &&  QuestionsList.length === 0  && (
             <>
              <BootstrapTable 
              cellEdit={ cellEditFactory({ 
                mode: 'click',
                blurToSave: true
            }) }
              className="bg-white" keyField='id' data={QuestionsList} columns={columns} options={options}  rowEvents={actionControls} />

             <p className="text-center mt-5 pt-5" style={{"fontSize":"30px","minHeight":"200px","display":"flex","justifyContent":"center","alignItems":"center"}}> 
                <small>
                    Please select the above filters {`&`} 
                    <br/>
                    click on search to get the questions.
                </small>
             </p>
              </>
           )}
            {QuestionsList  && QuestionsList.length <= 20 && QuestionsList.length > 0 &&
            (
              <BootstrapTable 
              cellEdit={ cellEditFactory({ 
                mode: 'click',
                blurToSave: true

            }) }
              className="bg-white" keyField='id' data={QuestionsList} columns={columns} options={options}  rowEvents={actionControls} />
            )
            }
          </div>
          </Col>
        </Col>
      ) : ""}
      {/*End addQuestionSection */}







      {/*Start previewTestSection */}
      { showpage.showPreviewTestPage ? (
        <Col className={classes.root} >
          <Row className="bg-white  d-flex flex-grow-1 align-items-center  justify-content-start sticky-header">
            <Col md={6} sm={6} offset={4} className="pr-3 pl-3 mt-3">
              <div>
                <div className="   rounded">
                  <div>
                    <h5 className="font-weight-bold f-w-600 pl-5" > Update Interview 
                    {/* &nbsp;&nbsp; {'>'} &nbsp;&nbsp; Preview Interview   */}

                    </h5>
                    <p className="text-b f-w-600 pl-5">
                    {/* <img width="24" src="/media/VRekruitIcons/costblue.svg" alt="" class="flaticon2-profile text-success bg-cost mr-3"/> */}

                    Credit Balance: V {localStorage?.getItem("companyCreditBalance")   }</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} sm={6} offset={4} className="pr-3 pl-3 mt-3">
              <div>
                <div className="d-flex flex-grow-1 align-items-center justify-content-end rounded mt-1">
                  <div className="text-muted d-block">
                    <Button onClick={Save_Interview} type="button"  
                     className={`btn btn-theme-green btn-lg mr-5 mt-2 mb-0 ` }>
                      Save
                    </Button>
                    {localStorage.getItem("userTypeId") !== "1"  ? 
                    <Button  onClick={(e) => save_share_test(e)} type="button" 
                      className={`btn btn-theme-green btn-lg mr-5 mt-2 mb-0` }>
                      Save & Share
                    </Button>
                     :""}

                    <Link to="/interview/manage-interview"  type="button" className="btn btn-theme text-white btn-lg mr-5 mt-2 mb-0"  >
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5"></Col>
          </Row>
          <Col className="Content-custom-padding mt-5 mb-0 mb-sm-5">
            <Row >
              <Form.Label className="fw-600" column sm="2">
                 Interview Name <BlueOnGreenTooltip title="Please use Interview Name matching with the Job Requisition.  Ensure Interview Round No. is mentioned in Suffix for future reference" placement="top">
                <i className='fa fa-info text-primary bd-primary' style={{'font-size':'smaller'}}></i></BlueOnGreenTooltip>
                </Form.Label>
              <Col sm="7" className="ml-0 pl-0" >
                <Form.Group as={Col} >
                  <Form.Control type="text"
                    name="testTitle"
                    value={formData.interviewTitle}
                    onChange={(e) => settestName(e)}
                  >
                  </Form.Control>
                  {isformSubmitted && !formData.interviewTitle ?
                     <div className="fv-plugins-message-container" >
                  <div className="fv-help-block">Required</div>
                  </div> 
               : null} 

                  {isformSubmitted && formData.interviewTitle && !formData.interviewTitle.replace(/\s/g, '').length    ?
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block pl-3">Required</div>
                  </div>
                  :null}
                </Form.Group>
              </Col>
              <Col className="text-right mt-2">
                <Link to="#" onClick={showCreatePage} className="c-p d-inline text-theme-green"><img src="/media/VRekruitIcons/addmore.svg" className="c-p mr-3" width="24" />Add More Questions</Link>
              </Col>
            </Row>

            <Row className="pt-5"  >
                          <Form.Label className="fw-600" column sm="2">
                              Select Fitment Parameter 
              </Form.Label>
                          <Col sm="7" className="ml-0 pl-0" >
                              <Form.Group as={Col} >
                                 <Select closeMenuOnSelect={false} components={animatedComponents} value={AssignedDefaultOptions} isMulti options={colourOptions}
           onChange={handleMultipeInput} /> 

                              </Form.Group>
                          </Col>

                      </Row>
            <Row>
              <Form.Label column sm="2"  className="fw-600" >
                Total Answer Time
              </Form.Label>
              <Col sm="7" className="ml-0 pl-0" >
                <Form.Group as={Col} >
                  <Form.Control type="text"
                   className="no-drop"
                    name="" value={totalAnswerTime ? totalAnswerTime   : ""}
                    readOnly>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <div>
            {testQuestions &&   testQuestions.length > 0  && (
               <SortableContainer onSortEnd={onSortEnd} helperClass="sortable-helper" distance={1} lockAxis="y">
               {testQuestions.map((testQuestion, index) => (
                 <SortableItem key={`item-${testQuestion.id}-${index}`} index={index} testQuestion={testQuestion} handleRemoveQues={handleRemoveQues} handleClickOpen1={handleClickOpen1} showEditQuesTime={showEditQuesTime} showTimeUpdate={showTimeUpdate} editTimeQuesId={editTimeQuesId} updateQuesAnsTime={updateQuesAnsTime} parseToHHMMSS={parseToHHMMSS} />
               ))}
             </SortableContainer>
            )}
           {testQuestions &&  testQuestions.length === 0  && (
             <>
              
             <p className="text-center mt-5 pt-5" style={{"fontSize":"30px","minHeight":"200px","display":"flex","justifyContent":"center","alignItems":"center"}}>
                No Records Found
             </p>
              </>
           )}
            
          </div>
          </Col>
        </Col>
      ) : ""}

      {/*End previewTestSection */}

      <DeleteConfirmationModal show={modalShow3} onHide={() => SetModalShow3(false)} />

      <CustomizedDialogs></CustomizedDialogs>

    </>
  );
}

export default EditInterview