/* eslint-disable no-restricted-imports */
/* jshint asi:true */

import React, { useState,useEffect } from "react";
import { Link,useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {  injectIntl } from "react-intl";
import * as auth from "../../modules/Auth/_redux/authRedux"
import Helmet from "react-helmet"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import alertService from "../../_services/alertservice";
import { logout } from "../../../actions/auth";
import { useDispatch } from 'react-redux';


function ViewUser(props) {
 /* const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [renderPage, setRenderPage]= useState(false); 
 // const [selectedRole,SetSelectedRole]= useState("");*/
  const history = useHistory();
  const[dialCode, setDialCode] = useState("");
  const[phone, setPhone] = useState("");
  const [roleName, setRoleName] = React.useState('');
  const [selectedRoleId, setInputValue] = React.useState('');
  
/*  const Roles = [
    { title: 'Recruiter', id: 4 },
    { title: 'Company Admin', id: 2 },
    { title: 'Hiring Manger', id: 3 },  
    { title: 'Super Admin', id: 1 },
  ]; 


  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

*/

  useEffect(() => {
   let mounted = true;
    getUserProfile(); 
   return () => mounted = false;
  }, [])


let {id} = useParams();
const[EmployeeDetails,SetEmployeeDetails]=useState();
  const getUserProfile = () =>{
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/get_user?id=`+ id, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
    .then((res) => res.json())
    .then((res) => {
      if(res.status===true){
        SetEmployeeDetails(res.data)
        setPhone(res.data.phone);
        setDialCode(res.data.dialCode);
        setInputValue(res.data.roleId)
        // initialValues.assignedTo = res.data.assignedTo;
        var roleId=  res.data.roleId;
       if(roleId){

        if(roleId === 1){
            setRoleName({ title: 'Super Admin', id: 1 })
           }  
          else if(roleId === 2){
            setRoleName({ title: 'Company Admin', id: 2 });
          }  
          else if(roleId === 3){
            setRoleName({ title: 'Hiring Manger', id: 3 });
          }  
          else if(roleId === 4){
            setRoleName({ title: 'Recruiter', id: 4 });
          }
        }              
       // setRenderPage(true)
      }
      
        else if(res.sessionExpired){
          logoutMethod();
          alertService.alert({ message :res.message, type : 'error'  });
        }

      
      else{
      }
    })
    .catch(() => {
 
    });
    }

    const dispatch = useDispatch();
    const logoutMethod = () => {
      dispatch(logout());
      history.push('/auth/signin');
  };


  if(localStorage.getItem("userTypeId") === "3")
  {
      
     return ""
    //  history.push("/error/error-v1")
  
  }
  else {


  return (
    <>
      <Helmet>
        <title> VRekruit |  Sub User Account</title>
      </Helmet>

         {/* subeader */}
         <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600"> User Management </h3></div>
          </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
      {/* subeader */}
  
    <Row className="bg-white mb-0 pb-0 pt-5 sticky-header"> 
    <Col md={6} sm={12} offset= {4}  className="pr-3 pl-3">      
      <div>
       <div className="d-flex flex-grow-1 align-items-center  justify-content-start   rounded">     
        <div>
            <h5 className="font-weight-bold f-w-600 pl-5" >  Sub User Account </h5>
        </div>
      </div>
      </div>
      </Col>
      <Col md={6} sm={12} offset= {4}  className="pr-3 pl-3">
      <div>
       <div className="d-flex flex-grow-1 align-items-center justify-content-end    rounded">
        <div className="text-muted d-block mt-1">

            {selectedRoleId  === 2  || (roleName.id ===4 && localStorage.getItem("userTypeId")==="4" )  ? "" : (
              <Link to={"/edit-details/user/" +id} type="submit" className="btn btn-theme-green btn-lg mr-5">
                  Edit
             </Link>
             )
            } 

           <Link to="/user/list" type="button" className="btn btn-theme btn-lg mr-5  text-white">
              Cancel
           </Link>
      </div>
      </div>
      </div>
      </Col>
      <div className="separator separator-solid mb-0 mt-0  col-md-12 col-sm-12" ></div>
       </Row>

       <Row className="bg-white pt-10 pb-4">

      <Col md={12} sm={12} offset={4} className="pt-2 pb-5 pl-5 pr-5" >
              <div className="bg--tile rounded">
  
              <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                  <img className="d-inline grey-ico-30" alt="" width="25" src="/media/VRekruitIcons/requisition id (1).svg" />
                </div>
                <div className="text-muted d-block">
                  <h6 className="text-dark"> Employee ID</h6>
                  <p className="text-dark mt-0">
                  { EmployeeDetails && EmployeeDetails.empId ? EmployeeDetails.empId  : ""}                    
                  </p>
                </div>
              </div>
                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline grey-ico-30"  alt="role" width="25" src="/media/VRekruitIcons/role.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Role</h6>
                    <p className="text-dark mt-0">
                      {roleName? roleName.title:""}
                    </p>
                  </div>
                </div>
     
                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25"  alt="" src="/media/VRekruitIcons/avatar.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Name</h6>
                    <p className="text-dark mt-0">
                      { EmployeeDetails && EmployeeDetails.firstName ? EmployeeDetails.firstName  : ""}
                      { EmployeeDetails && EmployeeDetails.lastName ? " "+ EmployeeDetails.lastName  : ""}
                    </p>
                  </div>
                </div>

                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4" pl-4 >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25"  alt="email" src="/media/VRekruitIcons/email (1).svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Email Address</h6>
                    { EmployeeDetails && EmployeeDetails.email ? EmployeeDetails.email  : ""}
                  </div>
                </div>


                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4" pl-4 >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25"  alt="call" src="/media/VRekruitIcons/avatar.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Status</h6>
                    { EmployeeDetails && EmployeeDetails.isDeleted  ? "Inactive"  : "Active"}
                  </div>
                </div>


                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4" pl-4 >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25"  alt="call" src="/media/VRekruitIcons/call (1).svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Phone</h6>

                    {dialCode? dialCode:""}
                    {phone? " "+ phone :""}
                   
                  </div>
                </div>
              </div>
            </Col>
      </Row>

      </>
      
  );
          }
}

export default injectIntl(connect(null, auth.actions)(ViewUser));
