/* eslint-disable no-restricted-imports */
/* jshint asi:true */


import React, { useState } from "react";





function LoginAside() {

  return (
    <>
    <div className="login-aside mt-50">
        <img src="/media/bg/lookingfortalent.jpg" className="img-fluid aside-img"  />
    </div>      
    </>
  );
}

export default LoginAside
