// eslint-disable-next-line no-restricted-globals

import axios from 'axios';
import { setAlert } from './alert';

import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT
} from './Types';
import setAuthToken from '../utils/setAuthToken';



import alertService from '../app/_services/alertservice'

// Load User
export const loadUser = () => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token)
    }
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.token
            }
        }
        const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}api/v1/user/get_profile`);
        if(res.data.status === true){
        dispatch({
            type: USER_LOADED,
            payload: res.data
        });
    }
    } catch (err) {
        dispatch({
            type: AUTH_ERROR
        })
    }
}
// Register User
export const register = ({ name, email, password }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ name, email, password });
    try {
        const res = await axios.post('/api/users', body, config);
        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data
        });
        dispatch(loadUser());
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        dispatch({
            type: REGISTER_FAIL
        })
    }
}


// Login User
export const login = (data, setLoading, setShowVerificationLink,histroy) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify(data);
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/v1/auth/signin`, body, config);
        setLoading(false);
        if(res.data.status===true){
            var roleId = res.data.data.roleId;
            var userType; 
            if(roleId === 1){
                userType = "Super Admin"
               }  
              else if(roleId === 2){
                userType = "Company Admin"
              }  
              else if(roleId === 3){
                userType = "Hiring Manger"
              }  
              else if(roleId === 4){
                userType = "Recruiter"
              }
              if(userType){
                localStorage.setItem("userType",userType );
                localStorage.setItem("userTypeId",roleId );
              }
              localStorage.setItem("companyName",res.data.data.companyName)
            if(res.data.data.isVerified === true){
                    if(res.data.data.tempPassword === true){
                        histroy.push("/change/temp-password");
                        localStorage.setItem("TempPasswoedToken",res.data.data.token)
                     }
                else
                {
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: res.data
                    });
                    dispatch(loadUser());
                }
               
          
                } 
                else{
                    localStorage.setItem("verification-token", res.data.data.token);
                    alertService.alert({ message : "Please verify your email address. Click on the resend verification email link below the login button.", type : 'error'  })
                    setShowVerificationLink(true);
                }
        }
          
        else{
            if( res.data.errors){
                var errmsg ="";
                res.data.errors.forEach( function(value, index, array) 
                {
                  errmsg = errmsg + "(" + (index + 1) + ") " +   value.msg +  "\n"  ; 
                });
                localStorage.setItem("verification-token", "");
                setShowVerificationLink(false);
                setLoading(false);
                alertService.alert({ message : errmsg, type : 'error'  });

                // alert(errmsg);
              }
             else{
                localStorage.setItem("verification-token", "");
                setShowVerificationLink(false);
                setLoading(false);
                alertService.alert({ message : res.data.message, type : 'error'  });
                // alert(res.data.message) 

             } 
        }
    }    
    catch (err) {
            if(err.message)  {
                alertService.alert({ message : err.message, type : 'error'  });
                setLoading(false);
            }
            else{
                alertService.alert({ message : err, type : 'error'  });
                setLoading(false);
            }
      
        dispatch({
            type: LOGIN_FAIL
        })
    }
}
// Logout / Clear Profile
export const logout = () => dispatch => {
    dispatch({ type: LOGOUT });
}