/* eslint-disable no-restricted-imports */
/* jshint asi:true */

import {React,useState } from "react";
import Helmet from "react-helmet"
import Card from 'react-bootstrap/Card'
import CardDeck from 'react-bootstrap/CardDeck'
import { Link, useHistory, } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../actions/auth";
import alertService from "../_services/alertservice";
import { useEffect } from "react";

// import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";



export function DashboardPage() {

  // const { Player, keyboardControls } = this.props


  const openPage = (value) => {
    var compName = document.getElementById(value);
    if(compName){
      compName.click();
    }

  }


  useEffect(() => {
    let mounted = true;
    getUserProfile(); 
    return () => mounted = false;
  }, [])

const[ userProfileData,SetUserProfileData]= useState();
   
  const getUserProfile = () =>{
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/user/get_profile`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
    .then((res) => res.json())
    .then((res) => {
      if(res.status==true){
        SetUserProfileData(res.data);
      }
      else if(res.sessionExpired){
        alertService.alert({ message :res.message, type : 'error'  });
        logoutMethod();
      }
      else{
      }
    })
    .catch(() => {
    });
    }
  
    const history = useHistory() 
    const dispatch = useDispatch();
    const logoutMethod = () => {
      dispatch(logout());
      history.push('/auth/signin');
  };
  
const CompanyManagment = () => {
  return(
<Card className="card-dasboard">
  <Link to="/company-mangement/view">  
    <Card.Body>
      <Card.Text className="text-center">
      <Card.Img variant="top" className="dashboard-card-img" style={{"fill": "white !important","color":"white"}}  src="/media/VRekruitIcons/company manage.svg" >
      </Card.Img>
      <p  className="text-theme-green f-w-500 mb-0">Company </p>
      <p className="text-theme-green f-w-500 mb-0">Management</p>
      </Card.Text>
    </Card.Body>
    </Link>
  </Card>
  )
}

const CompanyiesManagement = () => {
  return(
  <Card className="card-dasboard">
<Link to="/admin/comapanies-list">  
  <Card.Body>
    <Card.Text className="text-center">
    <Card.Img variant="top" className="dashboard-card-img" style={{"fill": "white !important","color":"white"}}  src="/media/VRekruitIcons/company manage.svg" >
    </Card.Img>
    <p  className="text-theme-green f-w-500 mb-0">Companies </p>
    <p className="text-theme-green f-w-500 mb-0">Management</p>
    </Card.Text>
  </Card.Body>
  </Link>
</Card>
  )
}

const UserManagement = () => {
  return (
  <Card className="card-dasboard">
  <Link to="#"  onClick={ ()=> openPage("user-management-component") } >  
    <Card.Body>
      <Card.Text className="text-center">
      <Card.Img variant="top" className="dashboard-card-img" style={{"fill": "white !important","color":"white"}}  src="/media/VRekruitIcons/UserManage.svg" >
      </Card.Img>
      <p  className="text-theme-green f-w-500 mb-0"> User</p>
      <p className="text-theme-green f-w-500 mb-0">Management</p>
      </Card.Text>
    </Card.Body>
    </Link>
  </Card>
  )
}

const RequisitionManagement = () => {
  return (
    <Card className="card-dasboard">
  <Link to="#"  onClick={ ()=> openPage("req-management-component") }  >  
    <Card.Body>
      <Card.Text className="text-center">
      <Card.Img variant="top" className="dashboard-card-img" style={{"fill": "white !important","color":"white"}}  src="/media/VRekruitIcons/Job Requisition.svg" >
      </Card.Img>
      <p  className="text-theme-green f-w-500 mb-0">Requisition</p>
      <p className="text-theme-green f-w-500 mb-0">Management</p>
      </Card.Text>
    </Card.Body>
    </Link >  
  </Card>
  )
}

const InterviewManagement = () => {
   return (
    <Card className="card-dasboard">
    <Link to="#" onClick={ ()=> openPage("int-management-component") }>  
      <Card.Body>
        <Card.Text className="text-center">
        <Card.Img variant="top" className="dashboard-card-img" style={{"fill": "white !important","color":"white"}}  src="/media/VRekruitIcons/InterviewManage.svg" >
        </Card.Img>
        <p  className="text-theme-green f-w-500 mb-0">Interview </p>
        <p className="text-theme-green f-w-500 mb-0">Management</p>
        </Card.Text>
      </Card.Body>
      </Link>
    </Card>
   )
}

const CandidateEvaluation = () => {
  return (<Card className="card-dasboard">
  <Link to="#" onClick={ ()=> openPage("candidate-evaluation-component") }>  
    <Card.Body>
      <Card.Text className="text-center">
      <Card.Img variant="top" className="dashboard-card-img" style={{"fill": "white !important","color":"white"}}  src="/media/VRekruitIcons/Evaluation.png" >
      </Card.Img>
      <p  className="text-theme-green f-w-500 mb-0"> Candidate </p>
      <p className="text-theme-green f-w-500 mb-0 " >Evaluation</p>
      </Card.Text>
    </Card.Body>
    </Link>
  </Card> )
}

const ReviewManagement = () => {
  return (
    <Card className="card-dasboard">
  <Link to="#" onClick={ ()=> openPage("review-management-component") }>  
    <Card.Body>
      <Card.Text className="text-center">
      <Card.Img variant="top" className="dashboard-card-img" style={{"fill": "white !important","color":"white"}}  src="/media/VRekruitIcons/Reports.svg" >
      </Card.Img>
      <p  className="text-theme-green f-w-500 mb-0"> Review </p>
      <p className="text-theme-green f-w-500 mb-0 " style={{"opacity":"0"}}>Review</p>
      </Card.Text>
    </Card.Body>
    </Link>
  </Card>
  )

}

const PaymentManagement = () => {
  return (
    <Card className="card-dasboard" onClick={()=>openPage("payment-management-component")}>
  <Link to="/admin/invoices">  
    <Card.Body>
      <Card.Text className="text-center">
      <Card.Img variant="top" className="dashboard-card-img" style={{"fill": "white !important","color":"white"}}  src="/media/VRekruitIcons/PAYMENT- MANAGEMENT.svg" >
      </Card.Img>
      <p  className="text-theme-green f-w-500 mb-0">Payment</p>
      <p className="text-theme-green f-w-500 mb-0">Management</p>
      </Card.Text>
    </Card.Body>
    </Link >  
  </Card>
  )
}

const BlankCard = () => {
  return (
    <Card className="card-dasboard p-none" style={{"opacity": "0"}}>
  
  </Card>
  )

}


    return <>
        <Helmet>
            <title> VRekruit | Dashboard</title>
       </Helmet>

      {/* subeader */}
             <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
           <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
             <div className="d-flex align-items-center flex-wrap mr-1">
               <div className="d-flex align-items-baseline mr-5">
                 <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Dashboard</h3></div>
                 </div>
              <div className="d-flex align-items-center" />
            </div>
         </div>
      {/* subeader */}
       <div>

         {/* Super Admin Dashboard */}
         {localStorage.getItem("userTypeId") === "1" ? ( 
           <CardDeck>
             <CompanyiesManagement></CompanyiesManagement>
             <InterviewManagement> </InterviewManagement>
             <PaymentManagement></PaymentManagement>

           </CardDeck>)
           : 
           ''}


           {/* Company Admin Dashboard */}
         {localStorage.getItem("userTypeId") === "2" ? ( 
           <>
           <CardDeck>
             <CompanyManagment></CompanyManagment>
             <UserManagement></UserManagement>
             <RequisitionManagement></RequisitionManagement>
           </CardDeck>
           <CardDeck>
           <InterviewManagement> </InterviewManagement>
             <ReviewManagement></ReviewManagement>
             <CandidateEvaluation></CandidateEvaluation>
           </CardDeck>
           </>
           
           )
           : 
           ''}

           {/* Hiring Manager Dashboard */}
         {localStorage.getItem("userTypeId") === "3" ? ( 
           <>
           <CardDeck>             
             <RequisitionManagement></RequisitionManagement>
             <InterviewManagement> </InterviewManagement>
             <ReviewManagement></ReviewManagement>
           </CardDeck>
           <CardDeck>           
             <CandidateEvaluation></CandidateEvaluation>
             <BlankCard></BlankCard>
             <BlankCard></BlankCard>
           </CardDeck>
           </>
           
           )
           : 
           ''}


           {/* Recutier Dashboard */}
         {localStorage.getItem("userTypeId") === "4" ? ( 
           <>
           <CardDeck>
            
             <UserManagement></UserManagement>
             <RequisitionManagement></RequisitionManagement>
             <InterviewManagement> </InterviewManagement>
           </CardDeck>
           <CardDeck>
           
             <ReviewManagement></ReviewManagement>
             <CandidateEvaluation></CandidateEvaluation>
             <BlankCard>  </BlankCard>
           </CardDeck>
           </>
           
           )
           : 
           ''}


       </div>
    </>;
}
