/* eslint-disable no-restricted-imports */
/* jshint asi:true */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FormikConsumer, useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import * as auth from "../../modules/Auth/_redux/authRedux"
import Helmet from "react-helmet"
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import { Formik,  Field } from 'formik';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import alertService from "../../_services/alertservice";
import { useHistory } from 'react-router-dom';



const initialValues = {
  amount:"",
};

function loadScript(src) {
	return new Promise((resolve) => {
		const script = document.createElement('script')
		script.src = src
		script.onload = () => {
			resolve(true)
		}
		script.onerror = () => {
			resolve(false)
		}
		document.body.appendChild(script)
	})
}



function Payment(props) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('Mehul')

  const ValidationFormScheme = Yup.object().shape({
    amount: Yup.number()
      .min(100, "Minimum 100 credits")
      .required('Please Enter credits')
  });
  const enableLoading = () => {
    setLoading(true);
  };
  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const history = useHistory();

  const formik = useFormik({
    initialValues,
    validationSchema: ValidationFormScheme,
    validateOnChange:true,
    validateOnBlur: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      if (values.password == values.confirmPassword) {
        AddMoney(values, setStatus, setSubmitting);
      }
    },
  });

  async function displayRazorpay(values,setStatus) {
    setModalShow(false)
   
		const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

  
		if (!res) {
      alertService.alert({ message : 'Razorpay SDK failed to load. Are you online?', type : 'error'  });
		//	alert('')
  

			return
		}
    
    const token = localStorage.getItem('x-access-token');
		const data = await fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/payment/razorpay`, { method: 'POST' ,
    headers: {
      'content-type': 'application/json',
      'x-access-token': token
    },
    body: JSON.stringify(values)
    }).then((res) => res.json()
		).then((res) => {
     
      if (res.status == true) { 
        disableLoading();
        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY,
          currency: res.data.currency,
          amount: res.data.amount.toString(),
          order_id: res.data.id,
          name: 'Add credits',
          description: 'Add credits to your company account',
        //	image: 'http://localhost:1337/logo.svg',
          handler: function (response) {
         //   alert(response.razorpay_payment_id)
         //   alert(response.razorpay_order_id)
          //  alert(response.razorpay_signature)
          history.push("/payment/ledger");
          alertService.alert({ message : 'Credits Added Sucessfully', type : 'success'  });
          },
          prefill: {
            name : res.data.user.firstName+ ' '+res.data.user.lastName,
            email: res.data.user.email,
            contact: res.data.user.dialCode+res.data.user.phone
          }
        }
        const paymentObject = new window.Razorpay(options)
        paymentObject.open()

      }
      else {
        alertService.alert({ message : res.message, type : 'error'  });
        disableLoading();
   //    setSubmitting(false);
      }
    })
    .catch(() => {
      disableLoading();
   //   setSubmitting(false);
    });

	}
  const [modalShow, setModalShow] = React.useState(false);
  const [summaryData, setSummaryData] = React.useState({});


 function  OrderSummaryModal(props) {
    return (
    <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >

        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            <p className="text-dark text-center pt-3 pb-0"> Order Summary</p>
          </Modal.Title>
        </Modal.Body>
        <Modal.Body>
        
          {summaryData && summaryData.credits ?
          <>
      
   
<table className="mr-auto credit-order-summary" style={{"backgroundSize":"100%"}}>
<tr className="">
  <td style={{"width":"150px","fontSize":"20px"}}> Credits </td>
  <td className="text-right" style={{"width":"150px","fontSize":"20px"}}>
    <span className=""> V </span>{summaryData.credits?.toFixed(2)} 
  </td>
   </tr>
   <tr>
     <td style={{"width":"150px","fontSize":"20px"}}> Sub Amount</td>
     <td className="text-right" style={{"width":"150px","fontSize":"20px"}}> <i class="fa fa-inr "></i> {summaryData.amount?.toFixed(2)}</td>
    </tr>
        {summaryData.iGst ?

        <tr className="  pt-3">
               <td style={{"width":"150px","fontSize":"20px"}}>IGST ({summaryData.iGstFee}%) </td> 
               <td className="text-right" style={{"width":"150px","fontSize":"20px"}}><i class="fa fa-inr "></i>  {summaryData.iGstAmount?.toFixed(2)}</td>
           </tr>
          :
          <>
          <tr className="  pt-3"> 
          <td style={{"width":"150px","fontSize":"20px"}}>SGST ({summaryData.stateGstFee}%)</td>
          <td className="text-right" style={{"width":"150px","fontSize":"20px"}}> <i class="fa fa-inr "></i> {summaryData.stateGstAmount?.toFixed(2)}</td>

           </tr>
        <tr className="  pt-3">
          <td style={{"width":"150px","fontSize":"20px"}}>CGST ({summaryData.centreGstFee}%) </td>
          <td className="text-right" style={{"width":"150px","fontSize":"20px"}}>  <i class="fa fa-inr "></i> {summaryData.centreGstAmount?.toFixed(2)}</td>

          </tr>
        </>
        }
        <tr className="text-dark  pt-3">
          <td style={{"width":"150px","fontSize":"20px"}}>Total Amount</td>
          <td className="text-right" style={{"width":"150px","fontSize":"20px"}}> <i class="fa fa-inr "></i> {summaryData.total?.toFixed(2)}   </td>
          </tr>
    </table>
        <p className="text-center mt-5">
        <Button className="btn btn-theme text-white btn-lg mr-5  pl-5 pr-5 pt-3 pb-3" style={{"minWidth": "100px"}} onClick={() => displayRazorpay({...summaryData},null)}> Pay </Button>
       <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green" style={{"minWidth": "100px"}}  onClick={props.onHide}>Cancel</Button>
       </p>
       </>

       : ''
          }
         
          </Modal.Body>
      </Modal>
    );
  }

  const AddMoney = (values, setStatus, setSubmitting) => {
 //   displayRazorpay(values,setStatus,setSubmitting)
    const token = localStorage.getItem('x-access-token');

    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/payment/get_credits_amount?credits=${values.amount}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token

      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          disableLoading();
          setModalShow(true)
          setSummaryData(res.data);
          console.log(summaryData)

        }
        else {
          alertService.alert({ message : res.message, type : 'error'  });
          disableLoading();
          setSubmitting(false);
        }
      })
      .catch(() => {
        disableLoading();
        setSubmitting(false);
      });
  }

  const  submitFormMethod = () => {
    var submitbtn =  document.getElementById("submit-btn");
    if(submitbtn) {
      submitbtn.click();
    }
  }



  if(localStorage.getItem("userTypeId") === "1")
  {
      
     return ""
    //  history.push("/error/error-v1")
  
  }
  else {



  return (
    <>
      <Helmet>
        <title> VRekruit | Payment</title>
      </Helmet>

         {/* subeader */}
         <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Credits</h3></div>
          </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
      {/* subeader */}



    <Row className="bg-white pt-4 pb-4">
    <Col md={6} sm={12} offset= {4}  className="pr-3 pl-3">
      <div>
       <div className="d-flex flex-grow-1 align-items-center  justify-content-start   rounded">
        <div>
            <h5 className="font-weight-bold f-w-600 pl-5" >Credits </h5>
            <p className="text-muted mb-0 pl-5"> </p>
        </div>
      </div>
      </div>
      </Col>
      <Col md={6} sm={12} offset= {4}  className="pr-3 pl-3">
      <div>
       <div className="d-flex flex-grow-1 align-items-center justify-content-end    rounded">
        <div className="text-muted d-block mt-1">


      </div>
      </div>
      </div>
      </Col>
      <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5 mb-5"></Col>

      <div className="container" style={{"minHeight":"60vh"}}>
            <Form  onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">

              <Form.Group as={Row}>
                <Form.Label column sm={2}>
                  Add Credit*
                </Form.Label>
                <Col sm={10} md={7}>
                <input
                    placeholder="Add Credit"
                    type="number"
                    className={`form-control form-control-solid h-auto py-5 px-6`}
                    name="amount"
                    {...formik.getFieldProps("amount")}
                  />
            {formik.touched.amount && formik.errors.amount ? (
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.amount}</div>
                </div>
            ) : null}
                </Col>
                <Col>
                <button type="submit" className="btn btn-theme-green btn-lg mt-2"   >
                     Add Credit
                </button>
                </Col>
              </Form.Group>






              <button type="submit" id="submit-btn" className="d-none" >
              {loading && <span className="ml-3 spinner spinner-white"></span>}
                 submit</button>
            </Form>
            </div>
      </Row>
      <OrderSummaryModal show={modalShow} onHide={() => setModalShow(false)} />
      </>

  );
           
   }
}

export default Payment;
