/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo,useState,useEffect} from "react";
//import {OverlayTrigger, Tooltip} from "react-bootstrap";
//import {useSelector} from "react-redux";
import objectPath from "object-path";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {UserProfileDropdown} from "./dropdowns/UserProfileDropdown";
//import {connect} from 'react-redux';
import { useHistory,Link } from "react-router-dom";
import {logout } from '../../../../actions/auth'
import { useDispatch } from 'react-redux';
import alertService from "../../../../app/_services/alertservice";



export function QuickUserToggler() {

 // const {user} = useSelector(state => state.auth);
  const uiService = useHtmlClassService();
  const [showMenu, SetShowmenu]= useState(false);




  const Menutoggle = async () => {
    if(showMenu){
      SetShowmenu(false);
    }
    else {
      await get_Company_Details();
      SetShowmenu(true);
    }
  }

  document.addEventListener('click', function(event) {

    var specifiedElement = document.getElementById('menu-toggler-item');
     if(specifiedElement){
      var isClickInside = specifiedElement.contains(event.target);
      if (isClickInside) {
      }
      else {
        SetShowmenu(false);
      }
    }
  });


  const history = useHistory();
  const dispatch = useDispatch();

  const logoutClick = () => {
    SetShowmenu(false);
    dispatch(logout());
    history.push('/auth/signin');
};


  const [UserPrfile, setUserProfile ] = useState({})
 // const [PageLoading , setPageLoading ] = useState(false)
    const getProfileData = () =>{
      const token = localStorage.getItem('x-access-token');
      fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/user/get_profile`, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'x-access-token': token
        },
      })
      .then((res) => res.json())
      .then((res) => {
        if(res.status===true){
          setUserProfile(res.data);  
       //   setPageLoading(true);
        }
      })
      .catch(() => {
      });
      }
    
    useEffect(() => {
      let mounted = true;
      getProfileData();
      get_Company_Details();

      return () => mounted = false;
    }, [])


  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);




  const [companyDetails ,SetCompanyDetails]=useState(); 
  //get Company details 
  const get_Company_Details = () => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          SetCompanyDetails(res.data);
          localStorage.setItem("companyCreditBalance",res.data?.balanceCredits)
        }else if(res.sessionExpired){
            alertService.alert({ message :res.message, type : 'error'  });
            logoutClick();
          }
        else {
  
        }
      })
      .catch(() => {
  
      });
  }



  return (
  
<>
  
<div style={{"position":"relative"}}> 
<div className=" d-flex align-items-center justify-content-center mr-10" id="menu-toggler-item" onClick={Menutoggle}>
  <span className="mr-5 c-p mt-2 fw-600 text-capitalize"> Hi, {UserPrfile.firstName} {UserPrfile.lastName }</span>
<div className="mt-3 d-flex align-items-center justify-content-center text-uppercase c-p"  style={{"width":"40px","height":"40px","backgroundColor": "#003577","color":"white","borderRadius":"5px"}}>
   {UserPrfile && UserPrfile.firstName ? UserPrfile.firstName?.charAt(0):"" }
</div>
</div>


{showMenu ? (
  <div x-placement="bottom-end" aria-labelledby className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl dropdown-menu show dropdown-menu-right theme-blue-bg" 
  style={{position: 'absolute', top: '60px',left:'-110%'}}>
  <div className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top" 
  style={{backgroundColor: '#003577'}}>
    <div className="symbol bg-white-o-15 mr-3">
      <span className="symbol-label text-theme-blue font-weight-bold font-size-h4 text-uppercase">
        {UserPrfile && UserPrfile.firstName ? UserPrfile.firstName?.charAt(0):"" }
        </span>
      </div>
      <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">  
         <span className="d-block fs-8 text-capitalize"> 
            {UserPrfile.firstName} {UserPrfile.lastName } 
         </span>
         <span className="d-block fs-1x text-capitalize"> 
         {localStorage.getItem("userType")}
         </span>
         <span className="d-block fs-1x text-capitalize"> 
         {localStorage.getItem("companyName")  }
         </span>
      </div>
 


      {/* <span className="label label-success label-lg font-weight-bold label-inline">3 messages</span> */}
      </div>
      <div className="navi navi-spacer-x-0 pt-5">
        <Link className="navi-item px-8 cursor-pointer" to="/profile">
          <div className="navi-link">
            <div className="navi-icon mr-2">
            {/* profile
            public\media\VRekruitIcons\edit.svg */}
              <img width="24" src="/media/VRekruitIcons/profile.svg" alt="profile" className="flaticon2-profile text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer text-theme-blue">My Profile</div>
                <div className="text-muted"> Manage Account Details
                {/* <span className="label label-light-danger label-inline font-weight-bold">update</span> */}
                </div>
                </div>
                </div>
                </Link>
                <Link to="/change-password" className="navi-item px-8">
                  <div className="navi-link">
                    <div className="navi-icon mr-2">
                    <img width="24" src="/media/VRekruitIcons/change password.svg" alt="password" className="flaticon2-profile text-success" />
                      </div>
                      <div  className="navi-text">
                        <div  className="font-weight-bold text-theme-blue">Change Password</div>
                        <div  className="text-muted">Change Your Password</div>
                        </div>
                        </div>

                        </Link>

                        {  localStorage.getItem("userTypeId") !=="1"   && 

                        <div to="#" className="navi-item px-8 p-none1 position-relavtive">
                        <div className="navi-link ">
                    <div className="navi-icon mr-2" >
                    <img width="24" src="/media/VRekruitIcons/costblue.svg" alt="password" className="flaticon2-profile text-success bg-cost" />
                      </div>
                      <div  className="navi-text">
                        <div  className="font-weight-bold text-theme-blue">Credit Balance  </div>
                        <div  className="text-muted">                            
                           V {companyDetails && companyDetails.balanceCredits ? companyDetails.balanceCredits:"0"} 
                          </div>
                        </div>
                        </div>
                        </div>

                        } 

            



                        { `${process.env.REACT_APP_API_ENVIRONMENT}`  === "DEV123"  ? (
                                <>
                                <Link onClick={Menutoggle} to="/payment" className="navi-item px-8">
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                  <img width="28" src="/media/VRekruitIcons/Money.svg" alt="credits" className="flaticon2-profile text-success" />
                                  </div>
                                  <div className="navi-text">
                                      <div  className="font-weight-bold text-theme-blue">Credits</div>
                                      <div  className="text-muted">Add Credits
                                      
                                      </div>
                                      </div>
                                    
                                    
                                      </div>
                                      </Link>

                                      </>
                        ):"" 
                     
                        }
      
                          <div className="navi-separator mt-3" />
                          <div className="navi-footer  px-8 py-5">
                            <Link className="btn btn-light-primary text-white font-weight-bold"
                              style={{"backgroundColor": "#003577"}}
                              to="#" onClick={logoutClick}>
                                Sign Out
                            </Link>
                      </div>
                    </div>
                 </div>


                   ) :""}

      
          <div className="topbar-item">
 
          </div>
          </div>
          

        {!layoutProps.offcanvas && (<UserProfileDropdown/>)}
      </>
  );
}
