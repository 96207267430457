/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React, { useEffect, useState ,useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Helmet from 'react-helmet'
import { Link, useHistory } from "react-router-dom";
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import alertService from "../../_services/alertservice";
import { logout } from '../../../actions/auth';
import DateFnsUtils from "@date-io/date-fns"; // import
import {MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker } from "@material-ui/pickers";
import { CSVLink } from "react-csv";


import { useDispatch } from 'react-redux';



const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const initialValues = {
  status: "open",
  page: 1,
  limit: 10,
  assignedToMe: false,
  createdByMe: "0",
  orderBy: "Id",
  orderType: "ASC"
}

function PaymentList() {
   const [payments, setPayments] = useState([]);
   const [staticData, setStaticData] = useState(10);
   const [searchTerm, SetSearchTerm] = useState("");
   const [createdbyme, SetCreatedbyme] = useState(false);
   const [assignedbyme, SetAssignedbyme] = useState(false);
   const [transactionType, SetTransactionType] = useState("");


   const resetFilter = () => {   
    SetSearchTerm("");
    SetTransactionType("");



    var elements  = document.getElementsByClassName("MuiInputBase-input");
    if(elements){
      elements[0].value=""
      elements[1].value=""
    }

    SetDateRangeData({
      ...dateRangeData,
      mindatelocal: "",
      mindate: "",
      maxdatelocal:"",
      maxdate:""
    })
    get_List_without_filters();
   }

// filter option 
const SetSearchTermData = (x) => {
  let data =  x.target.value;
 if(data){
  SetSearchTerm(x.target.value);
 }
 else{
  SetSearchTerm("");
 }

} 

const SetTransactionData = (x) => {
    let data =  x.target.value;
   if(data){
     SetTransactionType(x.target.value);
   }
}

const SetAssignedbymeData = (x) => {
  SetAssignedbyme(x.target.checked);
}

const SetCreatedbymeData = (x) => {
  SetCreatedbyme(x.target.checked);
}
const [paymentData, setPaymentData] = useState([])
 const csvLink = useRef()

var csvHeaders = [{
  label: 'Transaction Date / Time',
  key: 'created_at'
},{
  label: 'Transaction Type',
  key: 'action'
},
{
  label: 'Transaction Description',
  key: 'interview'
},{
  label: 'Credits',
  key: 'credits'
}
]

const[loadingcsv,SetLoadingcsv]=useState(false);
var csvData = {}
const exportToCSV = async  () => { 
  const token = localStorage.getItem('x-access-token');
  SetLoadingcsv(true);
  var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/payment/download_csv?download=1`;
  if(searchTerm) {
    url = url + "&searchBy=" + searchTerm;
  }
  if(transactionType) {
    url = url + "&transactionType=" + transactionType;
  }
  if(dateRangeData.mindate){
    url = url + "&startDate=" + dateRangeData.mindate;
  }
  if(dateRangeData.maxdate){
    url = url + "&endDate=" + dateRangeData.maxdate;
  }
  if(dateRangeData.maxdate && dateRangeData.mindate){
      
    let d1 = new Date(dateRangeData.mindate)
    let d2 = new Date(dateRangeData.maxdate)
    let d3 = new Date()
    if(d1 > d2){
      alertService.alert({ message : 'End Date cannot be before Start Date', type : 'error'  });        
     // SetLoading(false);
      let data1 = {}
     // setPayments(data1);  
     return false;
    } 
  }
  if(dateRangeData.mindate && !dateRangeData.maxdate){
    let d1 = new Date(dateRangeData.mindate)
    let d3 = new Date()
    if(d1 > d3){
      alertService.alert({ message : 'Start Date cannot be future date', type : 'error'  });        
    //  SetLoading(false);
      let data1 = {}
    //  setPayments(data1);  
     return false;
    }     
  }

  
 await fetch(url, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'x-access-token': token
    }
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status == true) {
        // setPayments(res.data);
        setPaymentData(res.data)      
        SetLoadingcsv(false);
        csvLink.current.link.click()
      }
      else if(res.sessionExpired){
        logoutMethod();
        SetLoadingcsv(false);
        alertService.alert({ message :res.message, type : 'error'  });
      }  
      else {
        SetLoadingcsv(false);
      }
    })
    .catch(() => {
      SetLoadingcsv(false);
    });
}
const dispatch = useDispatch();
const logoutMethod = () => {
dispatch(logout());
history.push('/auth/signin');
}





const [loading,SetLoading]=useState(false);



// filter option 
  const get_Payment_List = () => {
    const token = localStorage.getItem('x-access-token');
    SetLoading(true);

    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/payment/list?page=1&limit=10000`;
    if(searchTerm) {
      url = url + "&searchBy=" + searchTerm;
    }
    if(transactionType) {
      url = url + "&transactionType=" + transactionType;
    }

    if(dateRangeData.mindate){
      url = url + "&startDate=" + dateRangeData.mindate;
    }
    if(dateRangeData.maxdate){
      url = url + "&endDate=" + dateRangeData.maxdate;
    }
    if(dateRangeData.maxdate && dateRangeData.mindate){
      
      let d1 = new Date(dateRangeData.mindate)
      let d2 = new Date(dateRangeData.maxdate)
      let d3 = new Date()
      if(d1 > d3 || d2 > d3){
        alertService.alert({ message : 'Date cannot be future date', type : 'error'  });        
        SetLoading(false);
        let data1 = {}
        setPayments(data1);  
       return false;
      }
      if(d1 > d2){
        alertService.alert({ message : 'End Date cannot be before Start Date', type : 'error'  });        
        SetLoading(false);
        let data1 = {}
        setPayments(data1);  
       return false;
      } 
    }
    if(dateRangeData.mindate && !dateRangeData.maxdate){
      let d1 = new Date(dateRangeData.mindate)
      let d3 = new Date()
      if(d1 > d3){
        alertService.alert({ message : 'Date cannot be future date', type : 'error'  });        
        SetLoading(false);
        let data1 = {}
        setPayments(data1);  
       return false;
      }     
    }
    if(!dateRangeData.mindate && dateRangeData.maxdate){
      let d1 = new Date(dateRangeData.maxdate)
      let d3 = new Date()
      if(d1 > d3){
        alertService.alert({ message : 'Date cannot be future date', type : 'error'  });        
        SetLoading(false);
        let data1 = {}
        setPayments(data1);  
       return false;
      }     
    }

    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setPayments(res.data);
          SetLoading(false);
        }
        else if(res.sessionExpired){
          logoutMethod();
          alertService.alert({ message :res.message, type : 'error'  });
          SetLoading(false);
        }  
        else {
          SetLoading(false)
        }
      })
      .catch(() => {
        SetLoading(false)
      });
  }
  

  const get_List_without_filters = () => {
    const token = localStorage.getItem('x-access-token');
    SetLoading(true);
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/payment/list?page=1&limit=10000`;
    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setPayments(res.data);
          SetLoading(false)
          setStaticData(staticData + 10);
        }
        else {
          SetLoading(false)
        }
      })
      .catch(() => {
        SetLoading(false)
      });
  }

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const actionControls = {
    onClick: (e, row, rowIndex) => {
   if(row) {
       let id = row.id;
       if (e.target.classList.contains("view-option")) {
       history.push("/view-details/user/" + id)
       // get_Payment_List();
      }

      if (e.target.classList.contains("edit-option")) {
        history.push("/edit-details/user/" + id)
        // get_Payment_List();
       }

       if (e.target.classList.contains("delete-option")) {
        setModalShow3(true);
        setDeleteId(row.id)
       }
    }
  }
  };

  function actionFormatter(column, userdetails) {
    return (
      <div className="text-left d-flex">
        <img src="/media/VRekruitIcons/eyeorview.svg" className="view-option c-p view-eye-ico" alt="" />
        { userdetails && userdetails.roleId  === 2 ? "" : (
                  <img src="/media/VRekruitIcons/edit.svg" className="edit-option ml-3 c-p view-eye-ico" alt="" />
        )
         }
      </div>
    );
  }

  function headerformatter(column, userdetails) {
    return (
      <div>
            {column.text}
            <img src="/media/VRekruitIcons/sort.svg" className="sort-icon c-p" width="30" height="30" alt="" />
      </div>
    );
  }

  function headerformatterAction(column, userdetails) {
    return (
      <div>
            {column.text}
      </div>
    );
  }

  function  TransDateFormater(column, userdetails) {
  //  const date = new Date(column);
  //  const isoDate = date.toISOString();
    return(
      <span className="text-capitalize">
        {userdetails.created_at}
        {/* isoDate.substr(0, 10)} {isoDate.substr(11, 8) */} 
      </span>
    );
  }

  function  TransTypeFormater(column, userdetails) {
    let type ="";
    if(userdetails.action === 'add'){
      type = "Buy"
       }  
      else if(userdetails.action === 'deduct'){
        type = "Deduct"
      }  
      else if(userdetails.action === 'hold'){
        type = "Assigned"
      }  
      else if(userdetails.action === 'release'){
        type = "Roleback"
      }
    return(
    <>  
      <span>
        {type} 
      </span>
      </>
    );
  }

  function  DesFormater(column, userdetails) {
    var Des ="";
    if(userdetails.action === 'add'){
        Des = "Razorpay"
       }  
      else if(userdetails.action === 'deduct'){
        Des = "Interview"
      }  
      else if(userdetails.action === 'hold'){
        Des = "Interview"
      }  
      else if(userdetails.action === 'release'){
        Des = "From Hold"
      }
    return(
    <>
        
      <span>
        {Des} 
      </span>
      </>
    );
  }


  function  CreditsFormatter(column, userdetails) {
    return(      
        <div className="text-right">
            <Col sm={4} style={{ "min-width": "90px" }}>
                <span className={(userdetails.action == 'add' || userdetails.action == 'release') ? 'text-success' : 'text-blue'} > {(userdetails.action == 'add' || userdetails.action == 'release') ? '+ V ' : '- V  '} {column}  </span>
            </Col>
        </div>
    );
  }

 var columns = [{
    dataField: 'createdAt',
    text: "Transaction Date / Time",
    sort: true,
    formatter:TransDateFormater,
    headerFormatter: headerformatter
  },
  {
    dataField: 'action',
    text: "Transaction Type",
    sort: true,
    formatter:TransTypeFormater,
    headerFormatter: headerformatter
  },

  {
    dataField: '',
    text: "Transaction Description",
    sort: true,
    formatter:DesFormater,
    headerFormatter: headerformatter
  },
  {
    dataField: 'credits',
    text: "Credits",
    sort: true,
    formatter:CreditsFormatter,
    headerFormatter: headerformatter
  },  
  ];

  const options = {
    paginationSize: 5,
    // custom: true,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    //  withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    
    onPageChange: (page, sizePerPage) => {
   
    },
    disablePageTitle: true,
    sizePerPageList: [ {
      text: '20', value: 20
    },{
    text: '50', value: 50
    },
    {
      text: 'All', value: payments.length
    }] // A numeric array is also available. the purpose of above example is custom the text    
  };


  const[ delId,setDeleteId] = useState();
  const DeleteUser = (delId) => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/delete_user/` + delId, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setModalShow3(false);
          alertService.alert({ message: res.message, type: 'error' });
          get_Payment_List();
        }
        else {
          setModalShow3(false);
          alertService.alert({ message: res.message, type: 'error' });
        }
      })
      .catch(() => {
      });
    
  }

    const [modalShow3, setModalShow3] = React.useState(false);

  function DeleteConfirmationModal(props) {
    return (
    <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >  
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            <p className="text-center">
            <img alt="logo" className="brand-dim" src="/media/logos/UPDATED_VRekruit _Logo.png" />
              </p>
            <p className="text-dark text-center pt-3"> Are you sure want to delete this User's Account?</p>
          </Modal.Title>
        </Modal.Body>
        <Modal.Title className="d-flex justify-content-center align-items-center pb-5" >
        <p className="text-center">

        <Button className="btn btn-theme text-white btn-lg mr-5  pl-5 pr-5 pt-3 pb-3" style={{"minWidth": "100px"}} onClick={() => DeleteUser(delId)}> Yes </Button>
       <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green" style={{"minWidth": "100px"}}  onClick={props.onHide}>No</Button> 
       </p>
        </Modal.Title>
      </Modal>
    );
  }


  useEffect(() => {
    let mounted = true;
    get_Payment_List();
    get_Company_Details();
    return () => mounted = false;
  }, [])


 const [companyDetails ,SetCompanyDetails]=useState(); 
//get Company details 
const get_Company_Details = () => {
  const token = localStorage.getItem('x-access-token');
  fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'x-access-token': token
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status == true) {
        SetCompanyDetails(res.data);
        localStorage.setItem("companyCreditBalance",res.data.balanceCredits)
      }else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
      else {

      }
    })
    .catch(() => {

    });
}

  // balanceCredits
  // holdCredits


  const [dateRangeData, SetDateRangeData] = useState(
    {
      mindatelocal:"",
      mindate:"",
      maxdatelocal:"",
      maxdate:"",
    }
  )




  const SetMindate = (e) => {
    let date;
    if(e?._d){
       date =e._d;
    }
    if(date){
    var dateformatted = GetFormattedDate(e._d);
    SetDateRangeData({
      ...dateRangeData,
      mindatelocal: e._d,
      mindate: dateformatted
    })
  }
  }


  const SetMaxdate = (e) => {
    let date;
    if(e?._d){
       date =e._d;
    }
    if(date){
    var dateformatted = GetFormattedDate(e._d);
    SetDateRangeData({
      ...dateRangeData,
      maxdatelocal: e._d,
      maxdate: dateformatted
    })
  }
  } 
  
  
  
  const GetFormattedDate = (date) => {
    var cdate = date;
    if(date){
    var dd = cdate.getDate();
    var mm = cdate.getMonth() + 1;
    var yyyy = cdate.getFullYear();
  
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return `${yyyy}-${mm}-${dd}` 
  }
  }


  


  if(localStorage.getItem("userTypeId") === "1")
  {
      
     return ""
    //  history.push("/error/error-v1")
  
  }
  else {



  return (
    <>
      <Helmet>
        <title>
          Payment List
        </title>
      </Helmet>


      {/* subeader */}
      <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid mb-0 pb-0">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Payment Management</h3></div>
          </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
      {/* subeader */}


      <Col className="bg-white br-15 mt-0 pt-0" >   



    <Row className="bg-white  d-flex flex-grow-1 align-items-center  justify-content-start mb-sm-5">
    <Col md={6} sm={6} offset= {4}  className="pr-3 pl-3 mt-3">      
      <div>
       <div className="   rounded">     
        <div>
        <h5 className="font-weight-bold f-w-600 pl-5" >Credit Summary</h5>
        </div>
      </div>
      </div>
      </Col>
      <Col md={6} sm={6} offset= {4}  className="pr-3 pl-3 mt-3">
      <div>
       <div className="d-flex flex-grow-1 align-items-center justify-content-end rounded mt-1">
        <div className="text-muted d-block">
           <Link to="/payment/add" type="button" className="btn btn-theme-green btn-lg mr-5 mt-2 mb-0"  >
              Buy Credits
           </Link>
      </div>
      </div>
      </div>
      </Col>



      <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5"></Col>  
      </Row>
      <Col md={12} sm={12} offset={4} className="pl-5 pr-5" >
              <div className="rounded">
  
              <div className="d-flex flex-grow-1 pt-5 pb-5 align-items-top  pt-4 pl-4"  >
                <div className="mr-4  flex-shrink-0 text-center" style={{ width: '240px' }}>
                <h6 className="text-dark ml-0 text-left text-dark"> Credit Assigned</h6>
                </div>
                <div className="text-muted d-block">
                  <h6 className="">  V { companyDetails && companyDetails.holdCredits ? companyDetails.holdCredits:"0"} </h6>               
                </div>
              </div>
                <div className="d-flex flex-grow-1 pt-5 pb-5 align-items-top  pt-4 pl-4"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '240px' }}>
                  <h6 className="text-dark ml-0 text-left text-dark"> Credit Balance</h6>
                  </div>
                  <div className="text-muted d-block">
                     <h6 className=""> V {companyDetails && companyDetails.balanceCredits ? companyDetails.balanceCredits:"0"} </h6>
                  </div>
                </div>          
              </div>
            </Col>
    </Col>


      <Col className="bg-white br-15 mt-5" >   

    <Row className="">

      <Col md={6} sm={6} offset= {4}  className="pr-3 pl-3 mt-3" >      
      <div>
       <div className="rounded">     
        <div>
        <h5 className="font-weight-bold f-w-600 pl-5 pt-5" >Credit  Transaction</h5>
        </div>
      </div>
      </div>
      </Col>
      <Col md={6} sm={6} offset= {4}  className="pr-3 pl-3 mt-3">
      <div>
       <div className="d-flex flex-grow-1 align-items-center justify-content-end rounded mt-1">
        <div className="text-muted d-block">
           <button onClick={exportToCSV} type="button" className="btn btn-theme-green btn-lg mt-2 mb-0" disabled={loadingcsv}>
              Export to CSV
              {loadingcsv && <span className="mr-5 ml-2 spinner spinner-white"></span>}
           </button>
           <CSVLink
          headers={csvHeaders}
          filename="Payments.csv"
          data={paymentData}
          ref={csvLink}
        />
       
      </div>
      </div>
      </div>
      </Col>
      <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5"></Col>  

      </Row>



        <Col className="Content-custom-padding" className="mb-sm-5 mt-5">
          
          <Row className="align-items-center  pt-5">
            <Col sm={4} md={1} className="mb-sm-5 filter-min-width">
              <Form.Control as="select" placeholder="Status"  value={transactionType}  onChange={(e) => SetTransactionData(e)} >
                <option value="">Transaction Type</option>
                <option value="add"> Buy </option>
                <option value="hold"> Assigned </option>
                <option value="deduct"> Deduct </option>
                <option value="release"> Roleback </option>
              </Form.Control>
            </Col>




             <Col style={{"width":"max-content","display":"contents"}}>
               <span style={{ "position": "relative", "top": "-8px"}}>Date Range</span>
              </Col>
            <Col sm={4} md={2} className="mb-sm-5 filter-min-width">
            <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                        size="small"
                        autoOk
                        variant="inline"
                        clearable="true"
                        inputVariant="outlined"
                        label="Start Date"
                        format="DD/MM/yyyy"
                         id="min"
                         value={dateRangeData.mindatelocal ? dateRangeData.mindatelocal:null}
                         InputAdornmentProps={{ position: "start" }}
                        onChange={date => SetMindate(date)}
                        disableFuture={true}
                    />

               </MuiPickersUtilsProvider>
               </Col>

               <Col sm={4} md={2} className="mb-sm-5 filter-min-width">     
                <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                        autoOk
                        size="small"
                        variant="inline"
                        clearable="true"
                        inputVariant="outlined"
                        label="End Date"
                        id="max"
                        format="DD/MM/yyyy"
                        value={dateRangeData.maxdatelocal ? dateRangeData.maxdatelocal:null}
                         InputAdornmentProps={{ position: "start" }}
                         onChange={date => SetMaxdate(date)}
                         disableFuture={true}
                         />
                  </MuiPickersUtilsProvider>
                  </Col>

            <Col sm={4} md={2} className="d-flex mb-sm-5 filter-min-width">
            <Form.Label >
              </Form.Label>
              <div style={{"position":"relative","width":"100%"}}>
          
            <div className="input-group" >
    
              <Form.Control type="text" value={searchTerm ? searchTerm : "" }  placeholder="Search..." id="search-text" className="border-radius-12" onInput={(e) => SetSearchTermData(e)}  style={{"paddingLeft":"35px",}} />
              <div className="input-group-prepend">
          
             </div>
              </div>
              <i className="fa fa-search"  style={{"position":"absolute","left":"7px","top": "50%","transform": "translate(7px, -50%)","borderRadius":"8px"}} ></i> 

              </div>
      
            </Col>

            <Col  sm={4} md={3} className="text-right mb-sm-5" style={{ "maxWidth": "148px","display": "flex"}}> 
               <Button  type="button" className="btn btn-search mt-2 mb-2" id="filter-btn" onClick={get_Payment_List}  style={{"borderRadius":"8px"}} >
                 Apply
              </Button>
           
            <Button  type="button" className="btn btn-reset ml-2 mt-2 mb-2"  id="reset-filter-btn"  onClick={resetFilter}  style={{"borderRadius":"8px"}} >
                  Reset
              </Button>
            </Col>

          </Row>
         

          <div style={{"minHeight": "50vh"}}>
            {payments &&  payments.length > 20 &&  payments.length > 0  && (
              <BootstrapTable className="bg-white" keyField='id' data={payments} columns={columns} options={options} pagination={paginationFactory(options)} rowEvents={actionControls} />
            )}
           {payments &&  payments.length === 0  && (
             <>
              <BootstrapTable className="bg-white" keyField='id' data={payments} columns={columns} options={options}  rowEvents={actionControls} />
          
              {!loading && !dateRangeData.mindatelocal && !dateRangeData.mindatelocal  ?
             <p className="text-center mt-5 pt-5" style={{"fontSize":"30px","minHeight":"200px","display":"flex","justifyContent":"center","alignItems":"center"}}>
                No Records Found
             </p>
            :""}

            {!loading && dateRangeData.mindatelocal && dateRangeData.mindatelocal  ?
             <p className="text-center mt-5 pt-5" style={{"fontSize":"18px","minHeight":"200px","display":"flex","justifyContent":"center","alignItems":"center"}}>
                   Greater start date and smaller end date is accepted as selection criteria
             </p>
            :""}

             {loading ?
            <p className="text-center mt-5 pt-5" style={{"fontSize":"30px","minHeight":"200px","display":"flex","justifyContent":"center","alignItems":"center"}}> 
              <ele id="no-data">  
                 <i className="fas fa-spinner fa-pulse fa-2x"></i>
              </ele> 
            </p>
             :""}

              </>
           )}
            {payments  && payments.length <= 20 && payments.length > 0 &&
            (
              <BootstrapTable className="bg-white" keyField='id' data={payments} columns={columns} options={options}  rowEvents={actionControls} />
            )
            }
          </div>
        </Col>

        </Col>
   
      <DeleteConfirmationModal show={modalShow3} onHide={() => setModalShow3(false)} />

    </>
  );
          }
}

export default PaymentList