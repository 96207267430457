/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useEffect, useState } from 'react';
import SVG from "react-inlinesvg";
import {connect} from 'react-redux';
import { useHistory,Link } from "react-router-dom";
import { logout } from '../../../../../actions/auth';
import {toAbsoluteUrl} from "../../../../_helpers";
import { useDispatch } from 'react-redux';



export function QuickUser() {
  const history = useHistory();
  const dispatch = useDispatch();

  const logoutClick = () => {
      const toggle = document.getElementById("kt_quick_user_toggle");
      if (toggle) {
        toggle.click();
      }
      dispatch(logout());
      history.push('auth/signin');
  };


const [UserPrfile, setUserProfile ] = useState({})
const [PageLoading , setPageLoading ] = useState(false)
  const getProfileData = () =>{
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/user/get_profile`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
    .then((res) => res.json())
    .then((res) => {
      if(res.status==true){
        setUserProfile(res.data);  
        setPageLoading(true);
      }
    })
    .catch(() => {
    });
    }



  useEffect(() => {
    let mounted = true;
    getProfileData();
    return () => mounted = false;
  }, [])






  return (
      <>

      <div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas p-10">
        <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
          <h3 className="font-weight-bold m-0">
            User Profile
          </h3>
          <span className="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
            <i className="ki ki-close icon-xs text-muted"/>
          </span>
        </div>
        <div className="offcanvas-content pr-5 mr-n5">
          <div className="d-flex align-items-center mt-5">
            <div className="d-flex flex-column">
              { PageLoading &&
              <span className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
                  {UserPrfile.firstName} {UserPrfile.lastName }
              </span>
              }
              <div className="navi mt-2">
                <span className="navi-item">
                {/* <span className="navi-link p-0 pb-2">
                  <span className="navi-text text-muted text-hover-primary">
                  {UserPrfile ? UserPrfile.email : ""  }
                  </span>
                </span> */}
                <span className="navi-link p-0 pb-2">
                  <span className="navi-text text-muted text-hover-primary font-weight-bold">
                   {localStorage.getItem("userType")}
                  </span>
                </span>
                <span className="navi-link p-0 pb-2">
                  <span className="navi-text text-muted text-hover-primary font-weight-bold">
                  {localStorage.getItem("companyName")  }
                  </span>
                </span>
                </span>
              </div>       
            </div>
          </div>
          <div className="separator separator-dashed mt-8 mb-5"/>
          <div className="navi navi-spacer-x-0 p-0">
            <span className="navi-item">

            </span>

            <Link to="/change-password" className="navi-item">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-warning">
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/icons/Shopping/Chart-bar1.svg"
                        )}
                    ></SVG>
                  </span>
                  </div>
                </div>
                <div className="navi-text">
                  <span className="font-weight-bold"> 
                      Change Password 
                  </span>
                </div>
       

              </div>
            </Link>
            <Link to="#" onClick={logoutClick} className="navi-item">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-warning">
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/icons/Shopping/Chart-bar1.svg"
                        )}
                    ></SVG>
                  </span>
                  </div>
                </div>
                <div className="navi-text">
                  <span className="font-weight-bold"> 
                      Sign out
                  </span>
                </div>
              </div>
            </Link>
          </div>
          <div className="separator separator-dashed my-7"></div>
        </div>
      </div>





{/* new ui to be implemeted */}
{/* <div x-placement="bottom-end" aria-labelledby className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl dropdown-menu show dropdown-menu-right theme-blue-bg" style={{position: 'absolute', inset: '0px auto auto 0px', margin: '0px',left:'1600px', transform: 'translate(-250px, 65px)'}}>
  <div className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top" 
  style={{backgroundColor: '#003577'}}>
    <div className="symbol bg-white-o-15 mr-3">
      <span className="symbol-label text-success font-weight-bold font-size-h4">S</span>
      </div><div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">Sean Stark</div>
      <span className="label label-success label-lg font-weight-bold label-inline">3 messages</span>
      </div>
      <div className="navi navi-spacer-x-0 pt-5">
        <a className="navi-item px-8 cursor-pointer" href="/metronic/react/demo1/user-profile">
          <div className="navi-link">
            <div className="navi-icon mr-2">
              <i className="flaticon2-calendar-3 text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">My Profile</div>
                <div className="text-muted">Account settings and more<span className="label label-light-danger label-inline font-weight-bold">update</span>
                </div>
                </div>
                </div>
                </a>
                <a className="navi-item px-8">
                  <div className="navi-link">
                    <div className="navi-icon mr-2">
                      <i className="flaticon2-mail text-warning" />
                      </div>
                      <div className="navi-text">
                        <div className="font-weight-bold">My Messages</div>
                        <div className="text-muted">Inbox and tasks</div>
                        </div>
                        </div>
                        </a>
                        <a className="navi-item px-8">
                          <div className="navi-link">
                            <div className="navi-icon mr-2">
                              <i className="flaticon2-rocket-1 text-danger" />
                              </div>
                              <div className="navi-text">
                                <div className="font-weight-bold">My Activities</div>
                                <div className="text-muted">Logs and notifications</div>
                                </div>
                                </div>
                                </a>
                                <a className="navi-item px-8">
                                  <div className="navi-link">
                                    <div className="navi-icon mr-2"><i className="flaticon2-hourglass text-primary" /></div>
                                    <div className="navi-text">
                                      <div className="font-weight-bold">My Tasks</div>
                                      <div className="text-muted">latest tasks and projects</div>
                                      </div>
                                      </div>
                                      </a>
                                      <div className="navi-separator mt-3" />
                                      <div className="navi-footer  px-8 py-5">
                                        <a className="btn btn-light-primary font-weight-bold" href="/metronic/react/demo1/logout">Sign Out</a>
                           <a href="#" className="btn btn-clean font-weight-bold">Upgrade Plan
                           </a>
                      </div>
                    </div>
                 </div> */}

{/* new ui to be implemeted */}
      </>
  );
}

export default connect(null, {QuickUser})(logout);
