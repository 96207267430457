/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormFile from 'react-bootstrap/FormFile'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Table from 'react-bootstrap/Table'
import { divide } from 'lodash';
import Helmet from 'react-helmet'
import { Link, useHistory } from "react-router-dom";
import { Redirect } from 'react-router';
import alertService from '../../_services/alertservice';
import { Button } from 'react-bootstrap';
import { CALL_HISTORY_METHOD } from 'react-router-redux';
import Modal from 'react-bootstrap/Modal'
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ReactPlayer from "react-player";
import { logout } from '../../../actions/auth';
import { useDispatch } from 'react-redux';
import { useFormik } from "formik";
import * as Yup from "yup";
import Rating from '@material-ui/lab/Rating';
import { isAndroid, isIOS, isSafari, isChrome, isFirefox, osName} from "react-device-detect";
import ShakaPlayer from 'shaka-player-react';
import 'shaka-player/dist/controls.css';





const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

// const useStyles = makeStyles(theme => ({
//   root: {
//     backgroundColor: theme.palette.background.paper,
//   },
// }));


{/* <tab style css statrt> */}
function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}
TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

{/* <tab style css statrt> */}

const initialValues = {
  status: "open",
  page: 1,
  limit: 10,
  assignedToMe: false,
  createdByMe: "0",
  orderBy: "Id",
  orderType: "ASC"
}

function AssignedInterview() {


  const [showViewPage,SetShowViewPage] = useState(false);


{/* <View Page> */}

const [requistions, setUsersList] = useState([]);
const [UserById, setUsersbyId] = useState([]);
const [colourOptions, setColourOptions] = useState([]);
const [AssignedDefaultOptions, setDefaultAssignedOptions] = useState([]);
const [name, setName] = useState([]);
const [idList, setIdList] = useState("");
const [renderPage, setRenderPage] = useState(false);
const [createdByUser, SetCreatedUserByUser] = useState("");
const [modifiedUserByUser, SetModifiedUserByUser] = useState("");
const [fitmentsList, setFitmentsList] = useState([]);
const [fitmentsListUpdated, setFitmentsListUpdated] = useState(false);
const [remarks, setRemarks] = useState("");    
const [selectedQuestionDetails, SetselectedQuestionDetails ] = useState();
const [selectedVideo, SetvideoUrl] = useState("");
const [selectedIndex, SetselectedIndex ] = useState("1");
const AddNewRequistionSchema = Yup.object().shape({});

const [showpage, setShowPage] = useState({
  showCreateTest: true,
  showPreviewTestPage: false,
})

    const setFitmentRating = (score, id) => {
        console.log('called', score, id)
        for (var i = 0; i < fitmentsList.length; i++) {
            if (fitmentsList[i].id === id) {
                fitmentsList[i].fitmentScore = score;
                setFitmentsList(fitmentsList)
                setFitmentsListUpdated(!fitmentsListUpdated)

                // return;
            }
        }

    }

    useEffect(() => {
    }, [fitmentsListUpdated])

const showCreatePage = () => {
  setShowPage({
    ...showpage,
    showCreateTest: true,
    showPreviewTestPage: false
  })
}

const showPreviewPage = () => {
  setShowPage({
    ...showpage,
    showCreateTest: false,
    showPreviewTestPage: true
  })
}




const handleFormInput = (e) => {
  SetFormData({
    ...formData,
    [e.target.name]: e.target.value
  })
    if (e.target.name == 'remarks') {
        setRemarks(e.target.value)
    }

}


const setSelectedQuestion = (x,videourl,ind) => {
  SetselectedQuestionDetails(x);
  SetvideoUrl(videourl);
  SetselectedIndex(ind+1);
  if(!videourl){
    alertService.alert({ message: "Answer not submitted yet", type: 'error' });
  }
}


const AssignedOptions = (req) => {
  if (req && req.length) {
    var options = [];
    req.forEach(element => {
      options.push(
        { value: element.id, label: element.firstName + " " + element.lastName }
      )
    });
    setDefaultAssignedOptions(options);
  }
}

const update_requisition = (values) => {
  
  const reqData = {
    position: values.position,
    jobDescription: values.jobDescription,
    file: values.file,
    assignedTo: idList,
    id: UserById.id,
    private: values.private
  }
  const token = localStorage.getItem('x-access-token');
  fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/update_requisition`, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      'x-access-token': token
    },
    body: JSON.stringify(reqData)
  })
    .then((res) => res.json())
    .then((res) => {

      if(res.sessionExpired){
        alertService.alert({ message :res.message, type : 'error'  });
        logoutMethod();
      }

      else  if (res.status == true) {
        // disableLoading();
        getrequisitionbyid();
        alertService.alert({ message: res.message, type: 'success' });
      }
      else {
        alertService.alert({ message: res.message, type: 'error' });

      }
    })
    .catch(() => {

    });
}

const setSt = (req) => {
  if (req && req.length) {
    var options = [];
    req.forEach(element => {
      options.push(
        { value: element.id, label: element.firstName + " " + element.lastName }
      )
    });
    setColourOptions(options);
  }
}



const history = useHistory();
const dispatch = useDispatch();
const logoutMethod = () => {
  dispatch(logout());
  history.push('/auth/signin');
};



const getrequisitionbyid = (id) => {
  const token = localStorage.getItem('x-access-token');
  fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/review_detail?id=` + id, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'x-access-token': token
    },
  })
    .then((res) => res.json())
    .then((res) => {


      if(res.sessionExpired){
        alertService.alert({ message :res.message, type : 'error'  });
        logoutMethod();
      }

      else  if (res.status == true) {
        setUsersbyId(res.data);
       if(res.data.interview.interviewQuestions){
        SetselectedQuestionDetails(res.data.interview.interviewQuestions[0]);
        SetvideoUrl(res.data.interview.interviewQuestions[0].interviewQuestionDetail.videoPath);
       }
       SetselectedIndex("1");

         var formdatavalues = 
        {
         ...formData,
          candidateStatus: res.data.candidateStatus,
          companyRemarks: res.data.companyRemarks,
        }
          SetFormData(formdatavalues)

          setFitmentsList(res.data.fitmentsList)
          if (res.data.interviewReview) {

              formdatavalues =
              {
                  ...formData,
                  candidateStatus: res.data.candidateStatus,
                  remarks: res.data.interviewReview.remarks,
              }
              setRemarks(res.data.interviewReview.remarks)
              SetFormData(formdatavalues)

          }

      }
      else {
     
      }
    })
    .catch(() => {

    });
}


var formik = useFormik({
  initialValues,
  enableReinitialize: true,
  validationSchema: AddNewRequistionSchema,
  validator: () => { },
  onSubmit: (values) => {
    // enableLoading();
    update_requisition(values);
  },
});

function SetFormic() {
  formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: AddNewRequistionSchema,
    validator: () => { },
    onSubmit: (values) => {
      // enableLoading();
      update_requisition(values);
      // setDefaultAssignedOptions();
    },
  });
}



const GetFormattedDate = (date) => {
  var actualDate = date.split('.')
  actualDate =     actualDate[0].replace('T', ' ')
  var cdate = new Date(actualDate);
  var dd = cdate.getDate();

  var mm = cdate.getMonth() + 1;
  var yyyy = cdate.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }

  if (mm < 10) {
    mm = '0' + mm;
  }
  //return `${dd}-${mm}-${yyyy}`
  return cdate.toLocaleDateString()
}

const GetFormattedDateTime = (date) => {
  var actualDate = date.split('.')
  actualDate =     actualDate[0].replace('T', ' ')
  var cdate = new Date(actualDate);
  var dd = cdate.getDate();

  var mm = cdate.getMonth() + 1;
  var yyyy = cdate.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }

  if (mm < 10) {
    mm = '0' + mm;
  }
  //return `${dd}-${mm}-${yyyy}`
  return cdate.toLocaleString()
}


// const [formData, SetFormData] = useState(
//   {
//     candidateStatus: "",
//     companyRemarks: "",
//   }
// )




// const[loading,SetLoading] = useState(false);
const update_candidateReview = (id) => {

  var allFitmentGiven = 1
 /* if(fitmentsList.length == 0){
    alertService.alert({ message: 'Please give assessment', type: 'error' });
    return false
  } */

  let fitmentScores = []
    for (var i = 0; i < fitmentsList.length; i++) {

        fitmentScores.push({
            "fitmentId": fitmentsList[i].id,
            "fitmentScore": fitmentsList[i].fitmentScore
        })

        if(!fitmentsList[i].fitmentScore){
          allFitmentGiven = 0
        }
    }

  if(!allFitmentGiven){
    alertService.alert({ message: 'Please give rating to all assessments', type: 'error' });
    return false
  }
    if (!formData.remarks) {
        alertService.alert({ message: 'Please enter remarks ', type: 'error' });
        return false
    }
    const token = localStorage.getItem('x-access-token');
    
    formData.interviewUserId = id
    formData.fitmentScores = fitmentScores
SetLoading(true);
fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/add_review/`, {
  method: 'POST',
  headers: {
    'content-type': 'application/json',
    'x-access-token': token
  },
  body: JSON.stringify(formData)
})
  .then((res) => res.json())
  .then((res) => {
    if(res.sessionExpired){
      alertService.alert({ message :res.message, type : 'error'  });
      logoutMethod();
      SetLoading(false);
    }
    else if (res.status == true) {
      alertService.alert({ message: res.message, type: 'info' });
      get_Interview_List();
      SetLoading(false);
      SetShowViewPage(false);
        formData.remarks = null
        setRemarks('')
    }
    else {
      if (res.errors) {
        var errmsg = "";
        res.errors.forEach(function (value, index, array) {
          errmsg = errmsg + "(" + (index + 1) + ") " + value.msg + "\n\r";

        });
        alertService.alert({ message: errmsg, type: 'error' });
        SetLoading(false);
      }
      else {
        alertService.alert({ message: res.message, type: 'error' });
        SetLoading(false);

      }
    }
  })
  .catch(() => {
    SetLoading(false);

  });
}



// </view Page>


  const [QuestionsList, setQuestionsList] = useState([]);
  const [staticData, setStaticData] = useState(10);
  const [parentCategories, setParentCategories] = useState([]);
  const [searchTerm, SetSearchTerm] = useState("");
  const [createdbyme, SetCreatedbyme] = useState(false);
  const [assignedbyme, SetAssignedbyme] = useState(false);
  const [status, SetStatus] = useState("");
  const [selectedId, setSelectedOption] = useState(true);
  const [subCategory, setSubCategory] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [InterviewFilter, SetInterviewStatus] = useState("");
  const [resultFilter, SetResultStatus] = useState("");
  const [difficultyLevel, setDifficultyLevel] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [buttonState,SetButtonState] = useState({
    "previewBtnState": true,
    "addQuestionButtonState": true,
  })
  const [rangeValues, SetRangeValues ]= useState({
    "minRange": 10,
    "maxRange": 70, 
  })



  const[bankType,SetBankType]= useState("private");
  const handleOptionChange = (e) =>  {
      var banktypeval = e;
      SetBankType(banktypeval);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }
   
  const [value, setValue] = React.useState([10, 70]);

  const handleChange = (event, newValue) => {
    
    setValue(newValue);
    SetRangeValues({
      ...rangeValues,
       minRange: newValue[0],
       maxRange: newValue[1],
    })
  };


  function valuetext(value) {
    return `${value}`;
  }
  

  const resetFilter = () => {
    SetInterviewStatus("");
    SetResultStatus("");
    SetSearchTerm("");
    SetRequisitonData("");
    get_List_without_filters();
  }



  // filter option 
  const SetSearchTermData = (x) => {
    let data = x.target.value;
    if (data) {
      SetSearchTerm(x.target.value);
    }
    else {
      SetSearchTerm("");
    }
  }


  const [requistiondata, SetRequisitonData]=useState();


  const SetSearchRequisitionData = (x) => {
    let data = x.target.value;
    if (data) {
      SetRequisitonData(x.target.value);
    }
    else {
      SetRequisitonData("");
    }
  }


  function handleSelectedInterviewStatus(event) {

    if (event.target.value) {
      if(event.target.value!="submitted"){
        SetResultStatus("");
      }
      SetInterviewStatus(event.target.value);
    }
    else {
      SetInterviewStatus("");
      SetResultStatus("");
    }   
  }

  function handleSelectedResultStatus(event) {
    if (event.target.value) {
      SetResultStatus(event.target.value);
    }
    else {
      SetResultStatus("");
    }

  }


  function handleDifficultyLevelSelect(event) {
    if (event.target.value) {
      setDifficultyLevel(event.target.value);
    }
    else {
      setDifficultyLevel("");

    }

  }


  function handleQuestionTypeSelect(event) {
    if (event.target.value) {
      setQuestionType(event.target.value);
    }
    else {
      setQuestionType("");

    }

  }

  const SetAssignedbymeData = (x) => {
    SetAssignedbyme(x.target.checked);
  }

  const SetCreatedbymeData = (x) => {
    SetCreatedbyme(x.target.checked);
  }

  // filter option 

  const [loading,SetLoading]=useState(false);
  const [filter,SetFilter]=useState(false);

  var i = 1;
  const get_Interview_List = () => {

    const token = localStorage.getItem('x-access-token');
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/interview/review_list?page=1&limit=1000000000&listType=interrupted`;
    //  history.push("/searchTerm");
    SetLoading(true);
    SetFilter(true);
     if(resultFilter){
      url = url + "&candidateStatus=" + resultFilter;
    }

    if (InterviewFilter) {
      url = url + "&interviewStatus=" + InterviewFilter;
    }

    if (searchTerm) {
      url = url + "&candidateSearch=" + searchTerm;
    }

    if(requistiondata){
      url = url + "&searchBy=" + requistiondata;
    }

    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {

        if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
          SetLoading(false);

        }

        else if (res.status == true) {
          setQuestionsList(res.data);
          SetLoading(false);

        }
        else {
          SetLoading(false);
        }
      })
      .catch(() => {
        SetLoading(false);
      });
  }


  // const dispatch = useDispatch();
//   const logoutMethod = () => {
//     dispatch(logout());
//     history.push('/auth/signin');
// };



  const get_List_without_filters = () => {
    const token = localStorage.getItem('x-access-token');
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/interview/review_list?page=1&limit=1000000000&listType=interrupted`;
    SetFilter(false);
    SetLoading(true);


    if(bankType){
      url = url + "&bankType=" + bankType;
    }
    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setQuestionsList(res.data);
          SetLoading(false);
        }
        else {
          SetLoading(false);
        }
      })
      .catch(() => {
        SetLoading(false);
      });
  }


 


  useEffect(() => {
    let mounted = true;
    // get_Interview_List();
    return () => mounted = false;
  }, [])

  const classes = useStyles();
  const theme = useTheme();



  const[ questionID,SetQuestionId] = useState("");
  const [QuestionData,setQuestionData]= useState({})

  const actionControls = {
    onClick: (e, row, rowIndex) => {
      if (row) {
        let id = row.id;
        if (e.target.classList.contains("view-option")) {
        // history.push("/interview-detail/view/" + id)
        // SetShowViewPage(true);
        SetShowViewPage(true);
        getrequisitionbyid(id);
        }
        else if(e.target.classList.contains("delete-option")) {
           SetDelId(row.id);
           SetModalShow3(true);
           SetRowIndex(rowIndex);
        }
      }
    }
  };

  const[rowindexvalue,SetRowIndex] = useState();

  const deletethisques = (delId) => {
    const newtestQuestions = [...testQuestions ];
    newtestQuestions.splice(rowindexvalue,1);
    setTestQuestions(newtestQuestions)
    SetModalShow3(false);
  }

  const roundsList =[
    {
      value : 'One',
      title: '1st'
    },
    {
      value : 'Two',
      title: '2nd'
    },
    {
      value : 'Three',
      title: '3rd'
    },
    {
      value : 'Four',
      title: '4th'
    },
    {
      value : 'Five',
      title: '5th'
    }
    ]


  function actionFormatter(column, colIndex) {
    return (
      <div className="text-left d-flex">
        <img src="/media/VRekruitIcons/eyeorview.svg" className="view-option c-p view-eye-ico-1" title="View" alt="View"  />
      </div>
    );
  }


  function actionFormatterTestQuestion(column, colIndex) {
    return (
      <div className="text-left d-flex">
        <img src="/media/VRekruitIcons/eyeorview.svg" className="view-option c-p view-eye-ico-1" title="View" alt="View" />

        <img src="/media/VRekruitIcons/delete.svg" className="delete-option c-p view-eye-ico ml-5" title="Delete" alt="Delete"  />

      </div>
    );
  }




  // function CandidateNameFormaterTest(columnsTestQustionData, colIndex) {
  //   return (
  //     <span>
  //       {columnsTestQustionData.firstName}  {columnsTestQustionData.lastName}
  //     </span>
  //   );
  // }


  function headerformatter(column, colIndex) {
    return (
      <div>
        {column.text}
        <img src="/media/VRekruitIcons/sort.svg" className="sort-icon c-p" width="30" height="30" />
      </div>
    );
  }




  function headerformatterAction(column, colIndex) {
    return (
      <div>
        Action
      </div>
    );
  }


  function interviewRoundFormatter(column){
    const round = roundsList.filter((roundData,i) => { return  roundData.value == column})
    return (
     <> {round && round.length ? round[0].title : ''}
     </>
    );

  }



  const get_sub_categories = (parentId) => {
    if (parentId) {
      const token = localStorage.getItem('x-access-token');
      fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/sub_categories?page=1&limit=1000&parentCategoryId=` + parentId, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'x-access-token': token
        },
        // body: JSON.stringify(values)
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status == true) {
            setSubCategories(res.data);

          }

        })
        .catch(() => {
        });
    }
    else {
      setSubCategories([]);
    }
  }
  // const history = useHistory();

  const Create_Test = (e) => {

    e.preventDefault();

    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/create`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(formData)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {

          alertService.alert({ message: res.message, type: 'info' });
          history.push('/interview/manage-interview');

        }
        else {
          if (res.errors) {
            var errmsg = "";
            res.errors.forEach(function (value, index, array) {
              errmsg = errmsg + "(" + (index + 1) + ") " + value.msg + "\n\r";

            });
            alertService.alert({ message: errmsg, type: 'error' });

          }

          else {
            alertService.alert({ message: res.message, type: 'error' });
          }
        }

      })
      .catch(() => {

      });
  }


  const save_share_test = (e) => {

    e.preventDefault();
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/create`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(formData)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          
          alertService.alert({ message: res.message, type: 'info' });
          history.push('/interview/share-interview/'+ res.data.id);

        }
        else {
          if (res.errors) {
            var errmsg = "";
            res.errors.forEach(function (value, index, array) {
              errmsg = errmsg + "(" + (index + 1) + ") " + value.msg + "\n\r";

            });
            alertService.alert({ message: errmsg, type: 'error' });

          }

          else {
            alertService.alert({ message: res.message, type: 'error' });
          }
        }

      })
      .catch(() => {

      });
  }





  const [isSelected, SelectedQuestion] = useState(false);
  
  
  const [testQuestions, setTestQuestions] = useState([]);

  const [formData, SetFormData] = useState(
    {
      interviewTitle:"",
      questions: [],
    }
  )

  const setSelectedValue = (value) => {
    if (formData.questions.find(x => x.questionId == value)) {
      formData.questions.splice(formData.questions.findIndex(x => x.questionId == value), 1)
    } else {
      formData.questions.push({ questionId: value });
    }


    SetFormData({
      ...formData,
      questions: formData.questions
    })
    
    if(formData.questions.length > 0) {
      SetButtonState({
        ...buttonState,
        addQuestionButtonState: false
      })
    }
    else {
      SetButtonState({
        ...buttonState,
        addQuestionButtonState: true
      })
    }
  }

  const settestName = (event) => {
    SetFormData({
      ...formData,
      interviewTitle: event.target.value
    })
  }

  const [totalTestMarks, SetTotalTestMarks] = useState();  
  const addQuestionsToTest = () => {
    formData.questions.forEach(que => {
      var element = QuestionsList.splice(QuestionsList.findIndex(x => x.id == que.questionId), 1);
      setQuestionsList(QuestionsList.map(x=> x)) ;     
      testQuestions.push(element[0]);
    });
    setTestQuestions(testQuestions);
    alertService.alert({ message : "Questions Added Successfully", type : 'success'  });
  }



  function selectallFormatter(column, colIndex) {
    return (
      <div className="text-left">
        <Form.Check inline label="" className="ml-2 mt-2" />
      </div>
    );
  }


  function CandidateNameFormater(column, colIndex) {
    return (
      <span>
        {column.firstName}  {column.lastName}
      </span>
    );
  }


  
  function ResultFormater(column, colIndex) {

    if(column === "next_round") {
      column = "Next Round"
    }

    else if (column === "on_hold"){
      column = "On Hold"
    }
     
    return (

      <span className="text-capitalize">  
        {column}        
     </span>
    );
  }


  

  var columns = [
    {
      dataField: 'requisition.requisitionId',
      text: "REQUISITION ID",
      sort: true,
      headerFormatter: headerformatter
  },


    {
      dataField: 'requisition.position',
      text: "JOB TITLE",
      sort: true,
      headerFormatter: headerformatter
  },

    {
      dataField: 'interviewUserDetail',
      text: "CANDIDATE NAME",
      sort: true,
      formatter: CandidateNameFormater,
      headerFormatter: headerformatter
    },
   
    {
      dataField: 'interviewRound',
      text: "INTERVIEW ROUND",
      sort: true,
      formatter: interviewRoundFormatter,
      headerFormatter: headerformatter
    },
    {
        dataField: 'createdAt',
        text: "INVITATION DATE",
        sort: true,
        headerFormatter: headerformatter,
        formatter: (cell) => {
            let dateObj = cell;
            if (typeof cell !== 'object') {
              dateObj = new Date(cell);
            }
            return `${('0' + dateObj.getUTCDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${dateObj.getUTCFullYear()}`;
          }   
    },
    {
        dataField: 'interviewStatus',
        text: "STATUS",
        sort: true,
        headerFormatter: headerformatter
    },   
    {
        dataField: 'candidateStatus',
        text: "RESULT",
        formatter: ResultFormater,
        sort: true,
        headerFormatter: headerformatter
    },
    {
      dataField: "action",
      text: "ACTION",
      sort: true,
      formatter: actionFormatter,
      headerFormatter: headerformatterAction
    },
  ];






  const options = {
    paginationSize: 5,
    // custom: true,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    //  withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    onPageChange: (page, sizePerPage) => {
    },
    disablePageTitle: true,
    sizePerPageList: [ {
      text: '20', value: 20
    },{
    text: '50', value: 50
    },
    {
      text: 'All', 
    }] // A numeric array is also available. the purpose of above example is custom the text    
  };




  const [modalShow3, SetModalShow3] = React.useState(false);
  const[delId, SetDelId]= useState(); 

  function DeleteConfirmationModal(props) {
    return (
    <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
    
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            <p className="text-center">
            <img alt="logo" className="brand-dim" src="/media/logos/UPDATED_VRekruit _Logo.png" />
              </p>
            <p className="text-dark text-center pt-3"> Are you sure want to delete this question?</p>
          </Modal.Title>
        </Modal.Body>
        <Modal.Title className="d-flex justify-content-center align-items-center pb-5" >
        <p className="text-center">

        <Button className="btn btn-theme text-white btn-lg mr-5  pl-5 pr-5 pt-3 pb-3" style={{"minWidth": "100px"}} onClick={() => deletethisques(delId)}> Yes </Button>
       <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green" style={{"minWidth": "100px"}}  onClick={props.onHide}>No</Button> 
       </p>
        </Modal.Title>
      </Modal>
    );
  }


  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);
  const [open, setOpen] = React.useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('lg');


  function CustomizedDialogs() {  
    return (
      <div>

        <Dialog 
        onClose={handleClose} 
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        fullScreen={fullScreen}
        aria-labelledby="customized-dialog-title" 
        open={open}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          </DialogTitle>
          <DialogContent >
            <Typography gutterBottom>
            <Row className="pt-5" >
              <Form.Label className="fw-600" column sm="2">
                Question Text*
              </Form.Label>
              <Col sm="10">
                <Form.Group as={Col} controlId="formGridState" >
                  <Form.Control as="textarea" placeholder="" rows={3}
                    name="question" 
                    readOnly={true}
                  >
                       {QuestionData ? QuestionData.question: "" }

                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            </Typography>
            <Typography gutterBottom>
            <Row className="pt-5" >
              <Form.Label className="fw-600" column sm="2">
                Question Video*
              </Form.Label>
              <Col sm="10">
                <Form.Group as={Col} controlId="formGridState" >
                   <video className="video-container video-container-overlay modal-video-player" autoPlay="false" loop muted={false}
                   controls
                   src={QuestionData ? QuestionData.videoPath: "" }
                   poster={QuestionData ? QuestionData.videoQuestion ? '' : '/media/bg/audio.gif' : ''}
                   preload="metadata"
                   >
                  </video>
                </Form.Group>
              </Col>
            </Row>
            </Typography>
            <Typography gutterBottom>
            <Row className="pt-5" >
              <Form.Label className="fw-600" column sm="2">
                Keywords*
              </Form.Label>
              <Col sm="10">
                <Form.Group as={Col} controlId="formGridState" >
                  <Form.Control as="textarea" placeholder="" rows={3}
                    name="question" 
                    readOnly={true}
                  > 
                  {QuestionData ? QuestionData.keywords: "" }


                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            </Typography>
          </DialogContent>
       
        </Dialog>
      </div>
    );
  }


  if(  localStorage.getItem("userTypeId") == "1")
  {
      
     return ""
  
  }

  else{


  return (

       
    <>
      <Helmet>
        <title>
        Interrupted Interview
        </title>
      </Helmet>

{!showViewPage &&
<>

      {/* subeader */}
      <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Review </h3></div>
          </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
      {/* subeader */}
      {/*Start  addQuestionSection */}
        <Col className={classes.root} >
          <Row className="bg-white  d-flex flex-grow-1 align-items-center  justify-content-start">
            <Col md={6} sm={6} offset={4} className="pr-3 pl-3 mt-3">
              <div>
                <div className="mt-3 rounded">
                  <div>
                    <h5 className="font-weight-bold f-w-600 pl-5" > Interrupted Interview &nbsp;&nbsp; {'>'} &nbsp;&nbsp; Interview List
                    </h5>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5"></Col>
          </Row>
          <Col className="Content-custom-padding mt-5">



          <Row className="align-items-center space-around mt-0">
              {/* <Col sm={4} md={3} className="mb-2">
                <Form.Control as="select" placeholder="Status" value={InterviewFilter} onChange={(e) => handleSelectedInterviewStatus(e)} >
                  <option value=""> Status</option>
                  <option value="sent"> Sent</option>
                     <option value="submitted"> Submitted</option>   
                </Form.Control>
              </Col> */}

              <Col sm={4} md={3} className="mb-2 pl-0 ml-0">
                <Form.Control as="select" placeholder="-Sub Category-" value={resultFilter} onChange={(e) => handleSelectedResultStatus(e)} >
                  <option value="">Result</option>
                    <option value="pending"> Pending</option>
                    <option value="selected"> Selected</option>
                    <option value="on_hold"> On Hold</option>
                    <option value="next_round">Next Round</option>
                    <option value="rejected"> Rejected</option>                  
                </Form.Control>
              </Col>
     

              <Col sm={4} md={4} className="d-flex mb-2">
                <div style={{ "position": "relative" }}>
                  <div className="input-group">
                    <Form.Control type="text" value={searchTerm ? searchTerm : ""} placeholder="Search..." id="search-text" onInput={(e) => SetSearchTermData(e)} style={{ "paddingLeft": "35px", }} />
                    <div className="input-group-prepend">
                    </div>
                  </div>
                  <i className="fa fa-search" style={{ "position": "absolute", "left": "7px", "top": "50%", "transform": "translate(7px, -50%)", "borderRadius": "8px" }} ></i>
                </div>
              </Col>



              <Col sm={4} md={4}  className="mb-2 text-right">
                <Button type="button" className="btn btn-search ml-5 mb-sm-2" id="filter-btn" onClick={get_Interview_List} style={{ "borderRadius": "8px" }} >
                  Apply
              </Button>

              <Button type="button" onClick={resetFilter} className="btn btn-reset ml-5   mb-sm-2 btn btn-primary" id="filter-btn"  style={{ "borderRadius": "8px" }} >
                  Reset              
              </Button>
              </Col>

            </Row>
            <div>
            {QuestionsList &&  QuestionsList.length > 20 &&  QuestionsList.length > 0  && (
              <BootstrapTable className="bg-white" keyField='id' data={QuestionsList} columns={columns} options={options} pagination={paginationFactory(options)} rowEvents={actionControls} />
            )}
           {QuestionsList &&  QuestionsList.length === 0  && (
             <>
              <BootstrapTable className="bg-white" keyField='id' data={QuestionsList} columns={columns} options={options}  rowEvents={actionControls} />

            {!loading ?
             <p className="text-center mt-5 pt-5" style={{"fontSize":"30px","minHeight":"200px","display":"flex","justifyContent":"center","alignItems":"center"}}> 
                 
                 {!filter ? 
                <small>
                    Please select the above filters {`&`} 
                    <br/>
                    click on apply button.
                </small>
                :""}


                {filter?
                <small>
                   No Records Found!
                </small>
                :""
                }
             </p>
             :""}
    

             {loading ?
            <p className="text-center mt-5 pt-5" style={{"fontSize":"30px","minHeight":"200px","display":"flex","justifyContent":"center","alignItems":"center"}}> 
              <ele id="no-data">  
                 <i className="fas fa-spinner fa-pulse fa-2x"></i>
              </ele> 
            </p>
             :""}

              </>
           )}


      
            {QuestionsList  && QuestionsList.length <= 20 && QuestionsList.length > 0 &&
            (
              <BootstrapTable className="bg-white" keyField='id' data={QuestionsList} columns={columns} options={options}  rowEvents={actionControls} />
            )
            }
          </div>
          </Col>
        </Col>
      {/*End addQuestionSection */}
      <DeleteConfirmationModal show={modalShow3} onHide={() => SetModalShow3(false)} />
  
  </>
}
 
 {showViewPage && 
   <>
    <div id="kt_subheader" className="subheader py-2 py-lg-4    subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Review</h3></div>
          </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
      {/* subeader */}
      <Col className={classes.root} >
        <Row className="bg-white pt-4 pl-4 pb-0 mb-0 align-items-center sticky-header">
          <Col md={6} sm={12} offset={4} className="pr-3 pl-3">
            <div>
              <div className="d-flex flex-grow-1  align-items-center  justify-content-start   rounded">
                <div>
                  <h5 className="font-weight-bold f-w-600 pl-5" >  Interrupted Interview &nbsp;&nbsp; {'>'} &nbsp;&nbsp; View Interview  </h5>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} sm={12} offset={4} className="pr-3 pl-3 ">
            <div>
              <div className="d-flex flex-grow-1  align-items-center justify-content-end rounded">
                <div className="text-muted d-block">
                { UserById && UserById?.interviewStatus  !== "sent" && UserById?.candidateStatus  !== "rejected" && UserById?.candidateStatus  !== "selected" && UserById?.candidateStatus  !== "next_round" ? 
                  <button onClick={() => update_candidateReview(UserById.id)} type="button" className="btn btn-theme-green btn-lg mr-5"  
                  disabled={loading}
                  >
                    Save
                    {loading && <span className="mr-5 ml-2 spinner spinner-white"></span>}
                  </button>
                   :
                   ""}

                  <button onClick={() => SetShowViewPage(false)} type="button" className="btn btn-theme btn-lg mr-5  text-white">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Col>
          <Col md={12} sm={12} className="separator separator-solid  mt-5"></Col>
        </Row>

        <Link className="d-none" id="view-requistion-link" to="/view-requistion"> View Requisition</Link>
        <div className="Content-custom-padding pt-10">
          <Row>
            <Col md={6} sm={12} offset={4} className=" pt-5 pb-5 pr-3 pl-3">

              <div>
      
                <div className="bg--tile rounded">

                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4  pl-4 ">
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/requisitionid.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">First Name</h6>
                    <p className="text-dark mt-0"> 
                    {  UserById && UserById.interviewUserDetail ?  UserById?.interviewUserDetail?.firstName : ""}
                                         </p>
                  </div>

                </div>
                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4  pl-4 ">
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/requisitionid.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Last Name</h6>
                    <p className="text-dark mt-0"> 
                   
                    {  UserById && UserById.interviewUserDetail ? " "+ UserById?.interviewUserDetail?.lastName : ""}
                     </p>
                  </div>

                </div>
                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4  pl-4 ">
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/Interview Round.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Interview Round</h6>
                    <p className="text-dark mt-0"> {  UserById  ?  interviewRoundFormatter(UserById.interviewRound) : ""} </p>
                  </div>
                </div>
                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/email (1).svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark"> Email Address</h6>
                    <p className="text-dark mt-0">
                      {UserById && UserById.interviewUserDetail ? UserById.interviewUserDetail.email : ""}
                    </p>
                  </div>
                </div>

                <div className="separator separator-solid bg-white"></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4  pl-4   mb-0">
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/call (1).svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Phone</h6>
                    <p >
                      {UserById && UserById.interviewUserDetail ? UserById.interviewUserDetail.phone : ""}
                    </p>
                  </div>
                </div>

                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4  pl-4 ">
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/Interview Round.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">InterNet Down Count</h6>
                    <p className="text-dark mt-0"> {  UserById  ?  UserById?.internetDownCount : ""} </p>
                  </div>
              </div>

              </div>
              </div>


            </Col>



            <Col md={6} sm={12} offset={4} >
              <div className="bg--tile rounded">
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4  pl-4   mt-5 mb-0"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    {/* <i className="icon-2x text-dark-50 flaticon-user" /> */}
                    <img className="d-inline black-svg"  width="25" src="/media/VRekruitIcons/InterviewManage.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Interview Name</h6>

                    <p className="text-dark mt-0">
                    {UserById && UserById.interview ? UserById.interview.interviewTitle : ""}
                    </p>
                  </div>
                </div>

                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/status.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark"> Job Title </h6>
                    <p className="text-dark mt-0">
                    {UserById && UserById.requisition ? UserById.requisition.position : ""}
                    </p>
                  </div>
                </div>
                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/calendar.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark"> Invitation Date</h6>
                    <p className="text-dark mt-0">
                      {UserById &&  UserById.createdAt ? GetFormattedDate(UserById?.updatedAt) : ""}
                    </p>
                  </div>
                </div>

                <div className="separator separator-solid bg-white" ></div>
                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/calendar.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark"> Expiry Date</h6>
                    <p className="text-dark mt-0">
                      {UserById &&  UserById.createdAt ? GetFormattedDateTime(UserById?.expiryTime) : ""}
                    </p>
                  </div>
                </div>

                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/status.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark"> Status</h6>
                    <p className="text-dark mt-0 text-capitalize">
                      {UserById && UserById?  UserById.interviewStatus: ""}
                    </p>
                  </div>
                </div>

                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4  pl-4 ">
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/Interview Round.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">App Background Count</h6>
                    <p className="text-dark mt-0"> { UserById && UserById  ?  UserById?.backgroundCount : ""} </p>
                  </div>
                </div>
              </div>   
            </Col> 
           
            { UserById && UserById?.interviewStatus  !== "sent" ?
           <>
            <Col md={12}> 
            <img className="d-inline black-svg"  width="25" src="/media/VRekruitIcons/InterviewManage.svg" />
            <span className="pl-5">Interview Response</span>
            </Col>

            <Col md={3} sm={6} offset={1} className="mt-5 "  >
            <div className="bg--tile rounded box-questions">
                  <div className="flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4  pb-3 pr-4   mt-0 mb-0">
                   { UserById && UserById.interview && UserById.interview.interviewQuestions && UserById.interview.interviewQuestions.map((x, ind) =>  
                    
                      
                    <div className="text-muted d-block bg-white mr-5 mb-5 mt-2" key={x+ind} style={{"width":"100%","borderRadius":"5px"}} >
                      <h6 className="text-dark pl-3 pt-5 pb-2 d-flex justify-content-space-between align-items-center c-p"
                      onClick={()=> setSelectedQuestion(x,x.interviewQuestionDetail?.videoPath,ind)}
                      >  Question {ind + 1}
                      <i className="fa fa-play-circle fa-2x mr-3" />
                      {/* <img src ="/media/VRekruitIcons/play.svg" className="fa  fa-2x mr-3" /> */}
                      </h6>
                      <div className="separator separator-solid bg-grey"></div>
                      <h6 className="text-dark pl-3 pt-5 pb-4 d-flex justify-content-space-between align-items-center c-p"
                        onClick={()=> setSelectedQuestion(x,x.interviewQuestionAnswer?.answer,ind)}
                      >  Answer {ind + 1}
                      <i className="fa fa-play-circle fa-2x mr-3" />
                      </h6>                    
                      </div>
                    
                      )}
     
                    </div>
                </div>
              </Col>

              <Col md={9} sm={6} offset={1} className="mt-5"  >
            <div className="bg--tile rounded pt-5 pb-5">
                  <div className=" min-vh-70 align-items-top  pt-4  pl-5   mt-3 mb-3 mr-auto">
                  { /*<ShakaPlayer  height="540px" autoPlay={false} muted={true} src={selectedVideo ? selectedVideo+"#t=0.1": "" } />  */ }
                 
                  <video className="video-container video-container-overlay modal-video-player" height="540px" autoPlay={false} muted={false}
                   controls playsInline
                   src={selectedVideo ? selectedVideo+"#t=0.1": "" }
                   poster={selectedQuestionDetails && selectedQuestionDetails.interviewQuestionDetail?.videoQuestion ? "" :"/media/bg/audio.gif"}
                   ></video> 
                  
                  {/*  <ReactPlayer url={ selectedVideo? selectedVideo :""} width="98%" height="540px" controls={true} /> */}
                  </div> 

                  <div className="text-muted d-block bg-white question-box  mb-5 mt-2 ml-5 mr-5" style={{"width":"96.6%","borderRadius":"5px"}}>
                      <h6 className="text-dark pl-3  pt-5 pb-2 d-flex justify-content-space-between align-items-center "> 
                        Question {selectedIndex ? selectedIndex +  ":"+ " " : " "}
                         {selectedQuestionDetails && selectedQuestionDetails.interviewQuestionDetail?.question ? selectedQuestionDetails.interviewQuestionDetail?.question:""}
                      </h6>                    
                      </div>
           
                </div>
              </Col>
              {fitmentsList && fitmentsList.length > 0 ?
            <Col md={12} className="mt-5"> 
            <img className="d-inline result-ico" width="25" src="/media/VRekruitIcons/result.png" />
            <span className="pl-5">Assessment</span>
            </Col>    
            :''            
              }
                    <Col md={12} sm={12} offset={4} className="pb-5 pl-5 pr-5 mt-5"  >

                        {/* <p className="text-dark mt-0">Account Manager  Job Responsbility</p>    */}
                        {/* value={formData.difficultyLevel} */}
                        {fitmentsList ? fitmentsList.map((fitment, i) => (
                            <div key={`inline-${fitment.id}`} className={`bg--tile ${i == 0 ? 'rounded-top' : 'border-top border-light'} ${i == (fitmentsList.length - 1) ? 'rounded-bottom ' : ''}`}>
                                <div className="d-flex flex-grow-1  align-items-top  pt-4 pr-5 pl-5" >
                                    <div className="mb-3 mr-5 ml-5 " style={{ "display": "flex", "justifyContent": "space-between", "width": "100%" }}>
                                        {fitment.parameterName}
                                        <Rating
                                           readOnly={UserById?.candidateStatus  !== "rejected" && UserById?.candidateStatus  !== "selected" && UserById?.candidateStatus  !== "next_round" ? false : true}

                                            name={`simple-controlled-${fitment.id}`}
                                            value={fitment.fitmentScore}
                                            onChange={(event, newValue) => {
                                                setFitmentRating(newValue, fitment.id);
                                            }}
                                        />



                                    </div>
                                </div>
                            </div>
                        )) : ''}

                    </Col>
          </>
:""}

          {UserById && UserById?.interviewStatus  !== "sent" ?
              <>
            <Col md={12} className="mt-5"> 
            <img className="d-inline" width="25" src="/media/VRekruitIcons/Remarks.png" />
            <span className="pl-5">Remarks</span>
            </Col>
              <Col md={12} sm={12} offset={4} className="pb-5 mt-3"  >
                 <textarea placeholder="Please Enter Remarks" type="textarea" 
                     rows="3" className={`form-control form-control-solid h-auto py-5 px-6 bg-secondary`}
                      name="remarks"
                      resizable="true"
                      onChange={(e) => handleFormInput(e)}
                      readOnly={UserById?.candidateStatus  !== "rejected" && UserById?.candidateStatus  !== "selected" && UserById?.candidateStatus  !== "next_round" ? false : true}
                      value={remarks}
                      >   
                        {remarks}
                      </textarea>                 
              </Col>
               </>
              :"" }
          </Row>
          {renderPage && (
            <Form onSubmit={formik.handleSubmit} >
              <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                <Button id="req-save-btn" type="submit" className={`btn btn-primary btn-theme font-weight-bold px-9 py-4  my-3 d-none`}>
                  <span>Save </span>
                  <span className="ml-3  spinner-white"></span>
                </Button>
              </div>
            </Form>
          )}
        </div>
      </Col>  
  </>
 }
</>
  );       
 }
}

export default AssignedInterview