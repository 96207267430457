/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React, { useEffect, useState } from 'react';
import PropTypes, { string } from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormFile from 'react-bootstrap/FormFile'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Table from 'react-bootstrap/Table';
import Helmet from 'react-helmet'
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import AsyncSelect from 'react-select/async';
import { useParams, useHistory } from 'react-router-dom';
import alertService from '../../_services/alertservice';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'
import { truncate } from 'lodash';
import { logout } from '../../../actions/auth';
import { useDispatch } from 'react-redux';


const animatedComponents = makeAnimated();

const initialValues = {
  position: "",
  jobDescription: "",
  file: "",
  fileName:"",
  assignedTo: [],
  private: "",
  status: ""
};

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

function ViewRequistionRecord() {
  const [requistions, setUsersList] = useState([]);
  const [UserById, setUsersbyId] = useState([]);
  const [colourOptions, setColourOptions] = useState([]);
  const [AssignedDefaultOptions, setDefaultAssignedOptions] = useState([]);
  const [name, setName] = useState([]);
  const [idList, setIdList] = useState("");
  const [renderPage, setRenderPage] = useState(false);
  const [createdByUser, SetCreatedUserByUser] = useState("");
  const [modifiedUserByUser, SetModifiedUserByUser] = useState("");

  const AssignedOptions = (req) => {
    if (req && req.length) {
      var options = [];
      req.forEach(element => {
        options.push(
          { value: element.id, label: element.firstName + " " + element.lastName }
        )
      });
      setDefaultAssignedOptions(options);
    }
  }

  const history = useHistory() 

  const add_requisition = (values) => {
    const reqData = {
      position: values.position,
      jobDescription: values.jobDescription,
      file: values.file,
      assignedTo: idList,
      id: UserById.id,
      private: values.private
    }
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/update_requisition`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(reqData)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          // disableLoading();
          alertService.alert({ message: res.message, type: 'success' });

        }
        else {
          alertService.alert({ message: res.message, type: 'error' });

        }
      })
      .catch(() => {

      });
  }

  const setSt = (req) => {
    if (req && req.length) {
      var options = [];
      req.forEach(element => {
        options.push(
          { value: element.id, label: element.firstName + " " + element.lastName }
        )
      });
      setColourOptions(options);
    }
  }

  function handleMultipeInput(value) {
    //     e.preventDefault();        
    setName(value);
    setIdListItem(value);
  };

  const setIdListItem = (name) => {
    if (name) {
      var namelist = [];
      name.forEach(element => {
        namelist.push(element.value)
      });
      const list = namelist.join(", ");
      setIdList(list);
    }
  }

  const getrequisitionbyid = () => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/get_requisition?id=` + id, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setUsersbyId(res.data);
          SetCreatedUserByUser(res.data.createdByUser.firstName + " " + res.data.createdByUser.lastName);
          SetModifiedUserByUser(res.data.modifiedByUser.firstName + " " + res.data.modifiedByUser.lastName);


          initialValues.position = res.data.position;
          initialValues.status = res.data.status;
          initialValues.private = res.data.private;
          initialValues.jobDescription = res.data.jobDescription;
          initialValues.file = res.data.file;
          initialValues.fileName = res.data.fileName;


          AssignedOptions(res.data.assignedToUsers)
          formik.resetForm();
          setRenderPage(true)
          // setSt(res.data);
          // disableLoading();
          // alert(res.message);
          // setSubmitting(false);

        }
        else if(res.sessionExpired){
          logoutMethod();
          alertService.alert({ message :res.message, type : 'error'  });
        }
  
        else {
          // alert(res.message);
          // disableLoading();
          // setSubmitting(false);
        }
      })
      .catch(() => {
        // disableLoading();
        // setSubmitting(false);
      });
  }

  
  const dispatch = useDispatch();
  const logoutMethod = () => {
    dispatch(logout());
    history.push('/auth/signin');
};


  function SetFormic() {
    formik = useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema: AddNewRequistionSchema,
      validator: () => { },
      onSubmit: (values) => {
        // enableLoading();
        add_requisition(values);
        // setDefaultAssignedOptions();
      },
    });
  }

  const getuserslist = () => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/users_list`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setUsersList(res.data);
          setSt(res.data);
        }
        else {

        }
      })
      .catch(() => {

      });
  }


  useEffect(() => {
    let mounted = true;
    getuserslist();
    getrequisitionbyid();
    return () => mounted = false;
  }, [])
  const AddNewRequistionSchema = Yup.object().shape({});

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };



  var formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: AddNewRequistionSchema,
    validator: () => { },
    onSubmit: (values) => {
      // enableLoading();
      add_requisition(values);
    },
  });


  const [modalShow, setModalShow] = React.useState(false);


  function DeleteConfirmationModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            <p className="text-center">
            <img alt="logo" className="brand-dim" src="/media/logos/UPDATED_VRekruit _Logo.png" />
              </p>
            <p className="text-dark text-center pt-3"> Are you sure want to delete this requisition ?</p>
          </Modal.Title>
        </Modal.Body>
        <Modal.Title className="d-flex justify-content-center align-items-center pb-5" >
        <p className="text-center">

        <Button className="btn btn-theme text-white btn-lg mr-5  pl-5 pr-5 pt-3 pb-3" style={{"minWidth": "100px"}} onClick={CanDelete}> Yes </Button>
       <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green" style={{"minWidth": "100px"}}  onClick={props.onHide}>No</Button> 
       </p>
        </Modal.Title>
      </Modal>
    );
  }


  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }


  const reqsave = () => {
    let savebtn = document.getElementById("req-save-btn");
    savebtn.click();
  }


  let { id } = useParams();

  
  const CanDelete = () => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/edit_delete_premission?module=requisition&moduleId=` + id, {
      method: 'Get',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
        
          if(res.data.canDelete) {
            DeleteListItem();
            setModalShow(false);  
            if(res.data.deleteWarningMessage)
            {
             alertService.alert({ message: res.data.deleteWarningMessage, type: 'error' });
            }
           }
           else if(res.sessionExpired){
            setModalShow(false);
            alertService.alert({ message :res.message, type : 'error'  });
            logoutMethod();

          }
        }
        else {
          alertService.alert({ message: res.message, type: 'error' });
          setModalShow(false);

        }
      })

  }
  
  const histroy = useHistory();

  const canEdit = () => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/edit_delete_premission?module=requisition&moduleId=` + id, {
      method: 'Get',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          if(res.data.canEdit)
          histroy.push("/edit-requistion/"+id);
          if(res.data.editWarningMessage)
            alertService.alert({ message: res.data.editWarningMessage, type: 'error' });
          }
          else if(res.sessionExpired){
            logoutMethod();
            alertService.alert({ message :res.message, type : 'error'  });
          }
    
        else {
          alertService.alert({ message: res.message, type: 'error' });

        }
      })
    
  }

  

  const DeleteListItem = () => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/delete_requisition/` + id, {
      method: 'delete',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          //  view_requisition();
          alertService.alert({ message: res.message, type: 'success' });
          let linkItem = document.getElementById("view-requistion-link");
          linkItem.click();
          setModalShow(false);
        }
        else if(res.sessionExpired){
          logoutMethod();
          setModalShow(false);
          alertService.alert({ message :res.message, type : 'error'  });
        }
        else {
          alertService.alert({ message: res.message, type: 'error' });
          setModalShow(false);

        }
      })

  }



  const ChangeDateFormat = () => {
    let dateObj = UserById.createdAt;
    if (typeof UserById.createdAt !== 'object') {
      dateObj = new Date(UserById.createdAt);
    }
    return `${('0' + dateObj.getUTCDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${dateObj.getUTCFullYear()}`;
  }

  const GetFormattedDate = (date) => {
    var cdate = new Date(date);
    var dd = cdate.getDate();

    var mm = cdate.getMonth() + 1;
    var yyyy = cdate.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }
    return `${dd}-${mm}-${yyyy}`
  }




  return (
    <>
      <Helmet>
        <title> View Requisition  </title>
      </Helmet>




      {/* subeader */}
      <div id="kt_subheader" className="subheader py-2 py-lg-4    subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Requisition Management</h3></div>
          </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
      {/* subeader */}
      <Col className={classes.root} >
        <Row className="bg-white pt-4 pl-4  align-items-center sticky-header">
          
          <Col md={6} sm={12} offset={4} className="pr-3 pl-3">
            <div>
              <div className="d-flex flex-grow-1 min-vh-70 align-items-center  justify-content-start   rounded">
                <div>
                  <h5 className="font-weight-bold f-w-600 pl-5" > Requisition List &nbsp;&nbsp; {'>'} &nbsp;&nbsp; View Requisition  </h5>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} sm={12} offset={4} className="pr-3 pl-3">
            <div>
              <div className="d-flex flex-grow-1 min-vh-70 align-items-center justify-content-end rounded mt-1">
                <div className="text-muted d-block">
                {UserById && UserById.canEdit ?
                (
                  <button onClick={canEdit} type="button" className="btn btn-theme-green btn-lg mr-5"  >
                    Edit {UserById?.canEdit}
                    
                  </button>
                ):"" }

                {UserById && UserById.canDelete ?
                (
                  <button type="button" onClick={() => setModalShow(true)} className="btn btn-theme btn-lg mr-5  text-white">
                    Delete
                  </button>
                ):"" }
                <Link  type="button" className="btn btn-theme btn-lg mr-5  text-white" to="/view-requistion" >
                    Back 
                    
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col md={12} sm={12} className="separator separator-solid  mt-2"></Col>
        </Row>

        <Link className="d-none" id="view-requistion-link" to="/view-requistion"> View Requisition</Link>
        <div className="Content-custom-padding pt-10">
          <Row>
            <Col md={6} sm={12} offset={4} className=" pt-5 pb-5 pr-3 pl-3">

              <div>
                {/* <i className="fa fa-link fa-2x"> </i>

       <p> Requistion Id </p>
       <p> 12345678 </p> */}
                <div className="bg--tile rounded">

                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4   ">
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/requisitionid.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Requisition Id</h6>
                    <p className="text-dark mt-0"> {UserById.requisitionId} </p>
                  </div>

                </div>
                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4 " >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/created By.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Created By</h6>
                    <p className="text-dark mt-0">
                      {createdByUser}
                    </p>

                  </div>
                </div>
                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/calendar.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark"> Created Date</h6>
                    <p className="text-dark mt-0">
                      {UserById.createdAt ? GetFormattedDate(UserById.createdAt) : ""}
                    </p>
                  </div>
                </div>

                <div className="separator separator-solid bg-white"></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4   mb-0">
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/attachment.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Attached File</h6>
                    <p >
                      <a href={initialValues.file } className="text-theme-green mt-0 f-w-600" target="_blank">
                        {initialValues.fileName }
                      </a>
                    </p>
                  </div>
                </div>

                </div>
                

              </div>

            </Col>



            <Col md={6} sm={12} offset={4} >
              <div className="bg--tile rounded">
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4    mt-5 mb-0"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    {/* <i className="icon-2x text-dark-50 flaticon-user" /> */}
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/Assignedto.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark">Assigned To</h6>

                    <p className="text-dark mt-0">
                      {AssignedDefaultOptions.map((x) => x.label).join(",")}
                    </p>
                  </div>
                </div>

                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/status.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark"> Status</h6>
                    <p className="text-dark mt-0">
                      {UserById.status}
                    </p>
                  </div>
                </div>
                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/calendar.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark"> Modified Date</h6>
                    <p className="text-dark mt-0">
                      {UserById.createdAt ? GetFormattedDate(UserById.updatedAt) : ""}
                    </p>
                  </div>
                </div>

                <div className="separator separator-solid bg-white" ></div>
                <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4"  >
                  <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                    <img className="d-inline" width="25" src="/media/VRekruitIcons/private.svg" />
                  </div>
                  <div className="text-muted d-block">
                    <h6 className="text-dark"> Private</h6>
                    <p className="text-dark mt-0">{UserById.private ? "Yes" : "No"}</p>
                  </div>
                </div>
              </div>
            </Col>
           
            <Col md={12} sm={12} offset={4} className="mt-5"  >
            <div className="bg--tile rounded">
                  <div className="d-flex flex-grow-1 min-vh-70 align-items-top  pt-4 pl-4   mt-0 mb-0">
                    <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                      <img className="d-inline" width="25" src="/media/VRekruitIcons/Job Title.svg" />
                    </div>
                    <div className="text-muted d-block">
                      <h6 className="text-dark">Job Title</h6>
                      <p className="text-dark mt-0">{UserById.position}</p>
                    </div>

                  </div>
           
                </div>
              </Col>

              <Col md={12} sm={12} offset={4} className="pb-5 mt-3"  >
                <div className="bg--tile rounded">
               
                  <div className="d-flex flex-grow-1 min-vh-70 align-items-top mt-5 pt-4 pl-4" >
                    <div className="mr-4  flex-shrink-0 text-center" style={{ width: '40px' }}>
                      <img className="d-inline" width="25" src="/media/VRekruitIcons/job-description.svg" />
                    </div>
                    <div className="text-muted d-block">
                      <h6 className="text-dark">Job Description</h6>
                      {/* <p className="text-dark mt-0">Account Manager  Job Responsbility</p>    */}
                      <div dangerouslySetInnerHTML={{__html: UserById.jobDescription}} />
                  {/*     <p className="pre-line text-dark mt-0 pr-5">
                        {UserById.jobDescription}
                      </p>
 */}
                    </div>
                  </div>
                </div>
              </Col>


          </Row>


          {renderPage && (
            <Form onSubmit={formik.handleSubmit} >
              <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                <Button id="req-save-btn" type="submit" className={`btn btn-primary btn-theme font-weight-bold px-9 py-4  my-3 d-none`}>
                  <span>Save </span>
                  <span className="ml-3  spinner-white"></span>
                </Button>
              </div>
            </Form>
          )}
        </div>
      </Col>

      <DeleteConfirmationModal show={modalShow} onHide={() => setModalShow(false)} />


    </>
  );
}

export default ViewRequistionRecord