/* eslint-disable no-restricted-imports */
/* jshint asi:true */

import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../modules/Auth/_redux/authRedux"
import Helmet from "react-helmet"
import { Formik,  Field } from 'formik';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import PhoneInput from 'react-phone-input-2'
import alertService from "../_services/alertservice";
import { useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { logout } from "../../actions/auth";


const validateConfirmPassword = (pass, value) => {
  let error = "";
  if (pass && value) {
    if (pass !== value) {
      error = "Password not matched";
    }
  } else {
    error = "Password not matched";
  }
  return error;
};


// const { REACT_APP_TEST } = process.env;
// console.log(REACT_APP_TEST)
/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/
/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  dialCode:"",
  phone:"",
  firstName:"",
  lastName:"",
  email:"",
};



function ProfileEdit(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [renderPage, setRenderPage]= useState(false); 
 
  const ValidateFormSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('Required'),

    lastName: Yup.string()
      .required('Required'),

      email: Yup.string()
      .email("Enter a valid email")
      .required('Required'),
  

    //   oldPassword: Yup.string()
    //   .min(8, "Minimum 8 symbols")
    //   .max(12, "Maximum 12 symbols")
    //   .required('Please Enter your password')
    //   .matches(
    //     /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    //     "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    //   ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ValidateFormSchema,
    validateOnChange:false,
    validateOnBlur:false,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      if (values.password == values.confirmPassword) {
        UpdateProfile(values, setStatus, setSubmitting);
      }
    },
  });


  

  useEffect(() => {
    let mounted = true;
    getUserProfile(); 
    return () => mounted = false;
  }, [])




  const getUserProfile = () =>{
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/user/get_profile`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
    .then((res) => res.json())
    .then((res) => {
      if(res.status==true){
        initialValues.dialCode = res.data.dialCode;
        initialValues.phone = res.data.phone;
        initialValues.firstName = res.data.firstName;
        initialValues.lastName = res.data.lastName;
        initialValues.email = res.data.email;
        setPhone(res.data.phone);
        setDialCode(res.data.dialCode);
        formik.resetForm();
        setRenderPage(true)
    
  
      }
      else if(res.sessionExpired){
        alertService.alert({ message :res.message, type : 'error'  });
        logoutMethod();
      }
      else{
        // alert(res.message);
        // disableLoading();
        // setSubmitting(false);
      }
    })
    .catch(() => {
      // disableLoading();
      // setSubmitting(false);
    });
    }
    const dispatch = useDispatch();
    const logoutMethod = () => {
      dispatch(logout());
      history.push('auth/signin');
  };

  

    const history = useHistory();

  const UpdateProfile = (values, setStatus, setSubmitting) => {

    if (companyPhoneErrorMSg){
      disableLoading();
      setSubmitting(false);
      return;
    }

    else {

     var reqdata = {
        dialCode: phone?  dialCode:"",
        phone: phone,
        firstName: values.firstName,
        lastName:values.lastName,
        email:values.email,
     }

    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/user/update_profile`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token

      },
      body: JSON.stringify(reqdata)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          disableLoading();
          alertService.alert({ message : res.message, type : 'success'  });
          history.push("/profile")
          setSubmitting(false);

        }
        else {
          alertService.alert({ message : res.message, type : 'error'  });
          disableLoading();
          setSubmitting(false);
        }
      })
      .catch(() => {
        disableLoading();
        setSubmitting(false);
      });
    }
  }

  const  submitFormMethod = () => {
    var submitbtn =  document.getElementById("submit-btn");
    if(submitbtn) {
      submitbtn.click();
    }
  }


  const[companyPhone, setCompanyPhone] = useState("");
  const[companyPhoneErrorMSg, setCompanyPhoneErrorMsg] = useState("");


  const PhoneValidation =(value, country) => {
    if (!phone) {
      setCompanyPhoneErrorMsg("Required")
    }
    else if (phone.length !== 10) {
      setCompanyPhoneErrorMsg("Phone Number must be 10 digits")
    }
    else {
      setCompanyPhoneErrorMsg("");
      return true;
    }
  }

  const[dialCode, setDialCode] = useState("");
  const[phone, setPhone] = useState("");

  const handlePhoneChange = (value, data, event, formattedValue) => {
   if(data.dialCode){
      setPhone(value.slice(data.dialCode.length));
      setDialCode("+"+ data.dialCode);
    }
   
  }


  if( !(localStorage.getItem("userTypeId") ==="1" || localStorage.getItem("userTypeId") ==="2")) {
     
     return "";
  }
  
  else{


  return (
    <>
      <Helmet>
        <title> VRekruit | Update Profile</title>
      </Helmet>

         {/* subeader */}
         <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Profile</h3></div>
          </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
      {/* subeader */}
   


    <Row className="bg-white pt-4 pb-4">
    <Col md={6} sm={12} offset= {4}  className="pr-3 pl-3">      
      <div>
       <div className="d-flex flex-grow-1 align-items-center  justify-content-start   rounded">     
        <div>
            <h5 className="font-weight-bold f-w-600 pl-5" >My Profile </h5>
            <p className="text-muted mb-0 pl-5"> Update Your Personal Information</p>
        </div>
      </div>
      </div>
      </Col>
      <Col md={6} sm={12} offset= {4}  className="pr-3 pl-3">
      <div>
       <div className="d-flex flex-grow-1 align-items-center justify-content-end    rounded">
        <div className="text-muted d-block mt-1">
           <button type="submit" className="btn btn-theme-green btn-lg mr-5"  onClick={submitFormMethod} >
              Save
           </button>
           <Link to="/profile" type="button" className="btn btn-theme btn-lg mr-5  text-white">
              Cancel
           </Link>
      </div>
      </div>
      </div>
      </Col>
      <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5 mb-5"></Col>  

      <div className="container" style={{"minHeight":"60vh"}}>
            <Form  onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">

              <Form.Group as={Row}>
                <Form.Label column sm={2}>
                  Role
                </Form.Label>
                <Col sm={10} md={7}>
                  <Form.Control type="text" placeholder="" className={`form-control inp-h form-control-solid h-auto py-5 px-6 no-drop`}
                    value={localStorage.getItem("userType")}
                    readOnly={true}
                    />
                  
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    First Name*
                  </Form.Label>
                  <Col sm={10} md={7}>
                    <Form.Control type="text" placeholder="" className={`form-control inp-h form-control-solid h-auto py-5 px-6`}
                      {...formik.getFieldProps("firstName")}/>
                      {formik.touched.firstName && formik.errors.firstName ? 
                        (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.firstName}</div>
                          </div>
                        ) 
                        : null}
                  </Col>
              </Form.Group>
              <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                  Last Name*
                  </Form.Label>
                  <Col sm={10} md={7}>
                    <Form.Control type="text" placeholder="" className={`form-control inp-h form-control-solid h-auto py-5 px-6`}
                      {...formik.getFieldProps("lastName")}/>
                     {formik.touched.lastName && formik.errors.lastName ? 
                        (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.lastName}</div>
                          </div>
                        ) 
                        : null}
                  </Col>
              </Form.Group>

              <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    Email*
                  </Form.Label>
                  <Col sm={10} md={7}>
                    <Form.Control type="text" placeholder="" className={`form-control inp-h form-control-solid h-auto py-5 px-6 no-drop`}
                      {...formik.getFieldProps("email")}
                       readOnly={true}
                      />
                       {formik.touched.email && formik.errors.email ? 
                        (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.email}</div>
                          </div>
                        ) 
                        : null}
                  </Col>
              </Form.Group>

              <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    Phone
                  </Form.Label>
                  <Col sm={10} md={7}>
                  <PhoneInput name="phone" className={`form-control form-control-solid h-auto py-5 px-6 tel`}  
                    {...formik.getFieldProps("phone")}
                    name="phone"
                    onChange={ (value, data, event, formattedValue) => handlePhoneChange(value, data, event, formattedValue)}
                    inputProps={{
                      name: 'phone',
                      required: false,
                      autoFocus: true
                    }}
                    countryCodeEditable={false}
                    defaultCountry="in"
                      onlyCountries={["in"]}
                      masks={{
                        in: "..........",
                  
                      }}                   
                    isValid={PhoneValidation} 
                    value={ dialCode + phone }   />
             
               {formik.touched.phone && companyPhoneErrorMSg ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{companyPhoneErrorMSg}</div>
                </div>
              ) : null}
                  </Col>
              </Form.Group>

             
              <button type="submit" id="submit-btn" className="d-none" >
              {loading && <span className="ml-3 spinner spinner-white"></span>}

                 submit</button>
            </Form>   
            </div> 
      </Row>

      </>
      
  );
                  }
}

export default injectIntl(connect(null, auth.actions)(ProfileEdit));
