/* eslint-disable no-restricted-imports */
/* jshint asi:true */
// eslint-disable-next-line no-restricted-globals

import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import Helmet from "react-helmet"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Tab from 'react-bootstrap/Tab'
import TabContainer from 'react-bootstrap/TabContainer'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Container from 'react-bootstrap/Container'
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LoginAside from './LoginAside'
import  alertService  from '../../../_services/alertservice';
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
// alertService.alert({ message :"Hi", type : 'error'  });
import Header from './Header'
import Footer from './Footer'
//import css module
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReactFlagsSelect from 'react-flags-select';
import { useHistory } from 'react-router-dom';



const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    flexBasis: 200,
  },
}));


const initialValues = {
  email: "", 
  password: "", 
  confirmPassword:"",
  companyName: "",
  companyWebsite:"",
  companyCountry:"",
  companyAddress:"",
  firstName:"",
  lastName:"",
  dialCode:"", 
  companyDialCode:"", 
  phone:"",
  companyPhone:"",  
  companyStateId:""
};

function Registration(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [terms, setacceptTerms] = useState(false);
  const[companyPhone, setCompanyPhone] = useState("");
  const[companyDialCode, setCompanyDialCode] = useState("");
  const[dialCode, setDialCode] = useState("");
  const[phone, setPhone] = useState("");
  const[companyPhoneErrorMSg, setCompanyPhoneErrorMsg] = useState("");

const acceptTerms = () => {
  if(terms){
    setacceptTerms(false)
  }
  else {
    setacceptTerms(true)
  }
}
  
  const handlePhoneChange = (value, data, event, formattedValue) => {
    setPhone(value.slice(data.dialCode.length));
    setDialCode("+" + data.dialCode);
    setCompanyPhone(value.slice(data.dialCode.length));
    setCompanyDialCode("+" + data.dialCode)
  }



  const RegistrationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
       .required("Required"),       

       password: Yup.string()
      .required('Required')
      .min(8, 'Must Contain min 8 Characters!')
      .max(12, 'Must Contain max 12 Characters!!')
      .matches(
        /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
      companyName: Yup.string()
      .required('Required'),

  

      firstName: Yup.string()
      .required('Required'),

      lastName: Yup.string()
      .required('Required'),

      // phone: Yup.string()
      // .required('required'),

      companyAddress : Yup.string()
      .required('Required'),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

 
const opensecondtab = (values) =>{

 if( !companyPhoneErrorMSg && !formik.errors.companyName && !formik.errors.companyAddress  && selected && stateName)
 {
   let tab2 = document.getElementById("left-tabs-example-tab-second");
   tab2.click(); 
   scrollToTop();
 }
 else{

 }
}

const openfirsttab = () =>{
  let tab2 = document.getElementById("left-tabs-example-tab-first");
  tab2.click();
}


  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    validateOnChange:true,
    validateOnBlur: false,
      onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      values.phone = phone;
      values.companyPhone = companyPhone;
      values.country = selected;
      Signup(values, setStatus, setSubmitting);
    },
  }); 
  const histroy = useHistory();

  const Signup = (values, setStatus, setSubmitting) => {

    
    if(values.password !== values.confirmPassword || !stateName  ){
      setLoading(false);
      return;
  }
  else if (phoneErrorMSg){
    setLoading(false);
    return;
  }

 else{

      const reqData= {
          email: values.email, 
          password: values.password, 
          companyName: values.companyName,
          companyWebsite: values.companyWebsite,
          companyCountry:  selected,
          companyAddress: values.companyAddress,
          firstName: values.firstName,
          lastName: values.lastName,
          dialCode:"+91",
          companyDialCode: companyDialCode,  
          dialCode: dialCode, 
          phone: phone,
          companyPhone:companyPhone,  
          companyStateId:selectedState
      }
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/auth/signup`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(reqData)
    })
    .then((res) => res.json())
      .then(res => {
          if (res.status) {
            // return document.location.href ="/auth/signin";
            histroy.push("/auth/signin");
            alertService.alert({ message : "Registration Successful. Please click on the verification link sent on the registered email id.", type : 'success'  });
        }
        else  {
          if(res.errors){
            var errmsg ="";
            res.errors.forEach( function(value, index, array) 
            {
              errmsg = errmsg + "(" + (index + 1) + ") " +   value.msg +  "\n"  ; 
            });
             alertService.alert({ message :errmsg, type : 'error'  });

          }

          else {
             alertService.alert({ message :res.message, type : 'error'  });
          }
         setSubmitting(false);
          setLoading(false);
        }
      })   

    }

    }

 
    const classes = useStyles();
    const [values, setValues] = React.useState({
      password: '',
      showPassword: false
    });

    const [values1, setValues1] = React.useState({
      password: '',
      showConfirmPassword: false
    });
  
    const handleChange = prop => event => {
      setValues({ ...values, [prop]: event.target.value });
    };
  
    const handleClickShowPassword = () => {
      setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleClickShowConfirmPassword = () => {
      setValues1({ ...values1, showConfirmPassword: !values1.showConfirmPassword });
    };


    const [selected, setSelected] = useState("IN");
    const onSelect = (code) =>  {
      setSelected(code);
    }

  
    const countries = ["US", "GB", "DE", "FR", "NG", "ES","IN"];
    const blacklistCountries = false;
    


    const scrollToTop = () =>{
      window.scrollTo({
        top: 0, 
        behavior: 'smooth'
        /* you can also use 'auto' behaviour
           in place of 'smooth' */
      });
    };



    const[phoneErrorMSg, SetPhoneErrorMsg] = useState("");

    const PersonPhoneValidation = (value, country) => {
       if ( phone && phone.length !== 10) {
        SetPhoneErrorMsg("Phone Number must be 10 digits")
      }
      else {
        SetPhoneErrorMsg("");
        return true;
      }
    }



    const PhoneValidation = (value, country) => {
      if (!companyPhone) {
        setCompanyPhoneErrorMsg("Required")
      }
      else if (companyPhone.length !== 10) {
        setCompanyPhoneErrorMsg("Phone Number must be 10 digits")
      }
      else {
        setCompanyPhoneErrorMsg("");
        return true;
      }
    }





    const [StateList, SetStateList] = useState([
        { "id": 1, "state": "ANDAMAN AND NICOBAR ISLANDS", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 2, "state": "ANDHRA PRADESH", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 3, "state": "ARUNACHAL PRADESH", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 4, "state": "ASSAM", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 5, "state": "BIHAR", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 6, "state": "CHATTISGARH", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 7, "state": "CHANDIGARH", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 8, "state": "DAMAN AND DIU", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 9, "state": "DELHI", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 10, "state": "DADRA AND NAGAR HAVELI", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 11, "state": "GOA", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 12, "state": "GUJARAT", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 13, "state": "HIMACHAL PRADESH", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 14, "state": "HARYANA", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 15, "state": "JAMMU AND KASHMIR", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 16, "state": "JHARKHAND", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 17, "state": "KERALA", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 18, "state": "KARNATAKA", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 19, "state": "LAKSHADWEEP", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 20, "state": "MEGHALAYA", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 21, "state": "MAHARASHTRA", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 22, "state": "MANIPUR", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 23, "state": "MADHYA PRADESH", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 24, "state": "MIZORAM", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 25, "state": "NAGALAND", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 26, "state": "ORISSA", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 27, "state": "PUNJAB", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 28, "state": "PONDICHERRY", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 29, "state": "RAJASTHAN", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 30, "state": "SIKKIM", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 31, "state": "TAMIL NADU", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 32, "state": "TRIPURA", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 33, "state": "UTTARAKHAND", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 34, "state": "UTTAR PRADESH", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 35, "state": "WEST BENGAL", "countryId": 1, "createdAt": null, "updatedAt": null },
        { "id": 36, "state": "TELANGANA", "countryId": 1, "createdAt": null, "updatedAt": null }]);
    const getStateList = () =>{
      const token = localStorage.getItem('x-access-token');
      fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/get_states?countryId=1`, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'x-access-token': token
        },
      })
      .then((res) => res.json())
      .then((res) => {
        if(res.status==true){
          SetStateList(res.data);
        }
        else{
        
        }
      })
      .catch(() => {
      });
      }


      const [stateName, setStateName] = React.useState('');
    const [selectedState, setSelectedState] = React.useState('');
  
    const handleSelectChange = (value,e) => {
      if(value){
        setSelectedState(value.id);
        setStateName(value)      
      }  
    }


      useEffect(() => {
        let mounted = true;
        getStateList(); 
        return () => mounted = false;
      }, [])

    
      
  return (
    <>
    <Helmet>
       <title> VRekruit | Registration</title>
    </Helmet>
         

 <Header/>



         
    <div className="d-flex flex-column flex-root md-screen">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white "
          id="kt_login">
        <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden center-flex">
        <div  className="" id="kt_login_signin_form"  >   
      {/*  <img className="img-responsive mb-5 mt-1" width="200" src="/media/logos/UPDATED_VRekruit _Logo.png"  />     */}
      {/*begin::Form*/}

      {/*begin:: first name*/}
      <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework ">
      
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Nav variant="pills" className="flex-row">
                    <Nav.Item className="mb-4" style={{"display":"flex","alignItems": "center"}}>
                      <Nav.Link eventKey="first" id="firstkey">
                         1
                      </Nav.Link>
                       <span className="ml-2"> Company 
                      <br/> 
                      Details 
                      </span>  
                      <span className=" pl-5 pr-5"> {">"} </span>

                    </Nav.Item>

                    <Nav.Item  className="mb-4" style={{"display":"flex","alignItems": "center"}}>

                      <Nav.Link className="p-none1" eventKey="second" id="secondkey">
                        2
                      </Nav.Link>
                      <span className="ml-2"> 
                        Contact Person 
                        <br/>
                        Details
                      </span>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                    <div className=" mt-5 mb-5 mb-lg-0 flex-column-auto justify-content-center py-5 mb-5">
                    <h3 className="text-theme-blue font-weight-bold mt-5">
                      Create  Account
                    </h3>
                    <p className="text-grey">Already Have an Account?
                      <Link to="/auth/signin" className="text-theme-blue">
                        <span style={{"color": "#60A3FF","fontWeight":"bold"}}> Log In </span>
                      </Link>
                    </p>
                  </div>
                 
                {/*begin:: companyName: */}
                <div className="form-group fv-plugins-icon-container">
                <label className="form-label auth-lable" htmlFor="formGridCompanyName">Company Name*</label>
                  <input
                    placeholder="Company Name"
                    type="text"
                    className={`form-control form-control-solid h-auto py-12p pyb-12p`}
                    name="companyName"
                    {...formik.getFieldProps("companyName")}
                  />
                  {formik.touched.companyName && formik.errors.companyName ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.companyName}</div>
                    </div>
                  ) : null}
                </div>
                {/*end:: companyName: */}

        {/*begin:: companyWebsite: */}
        <div className="form-group fv-plugins-icon-container">
        <label className="form-label auth-lable" htmlFor="formGridCompanyWebsite">Company Website</label>
          <input
            placeholder="Company Website"
            type="text"
            className={`form-control form-control-solid h-auto py-12p pyb-12p`}
            name="companyWebsite"
            {...formik.getFieldProps("companyWebsite")}
          />
          {formik.touched.companyWebsite && formik.errors.companyWebsite ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.companyWebsite}</div>
            </div>
          ) : null}
        </div>
        {/*end:: companyWebsite: */}

      {/*begin::Company Phone: */}
      <div className="form-group fv-plugins-icon-container">
      <label className="form-label auth-lable" htmlFor="formGridPhone">Company Phone Number*</label>
        <div className="input-group">
        <PhoneInput name="companyPhone" className={`form-control form-control-solid h-auto py-12p pyb-12p tel`}  
        {...formik.getFieldProps("companyPhone")}
        name="companyPhone"
        onChange={ (value, data, event, formattedValue) => handlePhoneChange(value, data, event, formattedValue)}
        inputProps={{
          name: 'companyPhone',
          required: true,
          autoFocus: true
        }}
          countryCodeEditable={false}
          defaultCountry="in"
            onlyCountries={["in"]}
            masks={{
              in: "..........",
        
            }}

           isValid={PhoneValidation}
           value="+91"/>
          {formik.touched.companyPhone && formik.errors.companyPhone ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.companyPhone}</div>
            </div>
          ) : null}

        {formik.touched.companyPhone && companyPhoneErrorMSg ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{companyPhoneErrorMSg}</div>
                </div>
              ) : null}
        </div>
        </div>
        {/*end:: Company Phone: */}

        {/*begin:: companyAddress: */}
        <div className="form-group fv-plugins-icon-container">
        <label className="form-label auth-lable" htmlFor="formGridCompanyAddress">Company Address*</label>
          <textarea
            placeholder="Company Address"
            type="textarea" rows="3"
            className={`form-control form-control-solid h-auto py-12p pyb-12p`}
            name="companyAddress"
            maxLength={1000}
            id="length"
            {...formik.getFieldProps("companyAddress")}
          />
          {formik.touched.companyAddress && formik.errors.companyAddress ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.companyAddress }</div>
            </div>
          ) : null}
        </div>

        {/*end:: companyAddress: */}
        {/* start: Company Country */}
        <div className="form-group fv-plugins-icon-container">
        <label className="form-label auth-lable" htmlFor="formGridCountry">Country*</label>
        
      <ReactFlagsSelect
        selected={selected  ? selected :"IN"}
        onSelect={onSelect}
        blacklistCountries={blacklistCountries}
        optionsSize={14}
        countries={["IN"]}
        searchPlaceholder="Search countries"
        searchable={false}
      />
      
        </div>


      {StateList &&
        <div className="form-group fv-plugins-icon-container" id="mui1">
        <label className="form-label auth-lable" htmlFor="formGridCountry">State*</label>
              <Autocomplete size="small"
                  id="roles-titles"
                  options={StateList}
                  renderInput={(params) => (
                    <TextField {...params} label="Select State" margin="normal" variant="outlined" />
                  )}
                  value={stateName}
                  getOptionLabel={
                    (options) => options.state}
                  onChange={(event, newValue) => {
                    handleSelectChange(newValue, event);
                  }}

                />
               {formik.touched.companyState && !stateName ?
                (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">Required</div>
                  </div>
                )
                : null}
               </div>
               }

        

          {/* End: Company Country */}
          <div className="form-group d-flex flex-wrap justify-content-center align-items-center"> 
         
         {!formik.errors.companyName && !formik.errors.companyAddress  && !companyPhoneErrorMSg  && selected && stateName ? (
          <Link  onClick={opensecondtab}
           className={`btn btn-primary btn-theme-green font-weight-bold  pl-5 pr-5 btn-con auth-btns-text auth-btns`} >
            Continue
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </Link> 
          ) : 
          
          <Link  onClick={opensecondtab}
           className={`btn btn-primary btn-theme-green font-weight-bold  pl-5 pr-5 btn-con auth-btns-text auth-btns`} style={{"cursor":"no-drop"}}>
            Continue
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </Link> 
          }           
        </div>
          </Tab.Pane>
                  
        <Tab.Pane eventKey="second">
        <div className=" mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 mb-5">
          <h2 className="text-theme-blue font-weight-bold mt-5">
            Create  Account
          </h2>
          <p>
            Contact Person's Details
          </p>
        </div>            
        <div className="form-group fv-plugins-icon-container">
        <label className="form-label auth-lable" htmlFor="formGridFirstName">First Name*</label>
          <input
            placeholder="First Name"
            size="sms"
            type="text"
            className={`form-control form-control-solid h-auto py-12p pyb-12p `}
            name="firstName"
            {...formik.getFieldProps("firstName")}
          />
          {formik.touched.firstName && formik.errors.firstName ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.firstName}</div>
            </div>
          ) : null}
        </div>

      {/*begin:: first name*/}
              
      {/*begin:: last name*/}
        <div className="form-group fv-plugins-icon-container">
        <label className="form-label auth-lable" htmlFor="formGridLastName">Last Name*</label>
          <input
            placeholder="Last Name"
            type="text"
            className={`form-control form-control-solid h-auto py-12p pyb-12p `}
            name="LastName"
            {...formik.getFieldProps("lastName")}
          />
          {formik.touched.lastName && formik.errors.lastName ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.lastName}</div>
            </div>
          ) : null}
        </div>

        {/*start :: email*/}

        <div className="form-group fv-plugins-icon-container">
        <label className="form-label auth-lable" htmlFor="formGridEmail">Email Address*</label>
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-12p pyb-12p`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
              {/*end :: email*/}


      {/*begin:: Phone: */}  
      <div className="form-group fv-plugins-icon-container">
      <label className="form-label auth-lable" htmlFor="formGridPhone">Phone Number</label>
        <div className="input-group">
        <PhoneInput name="phone" className={`form-control form-control-solid h-auto py-12p pyb-12p tel`}
        name="phone"
        {...formik.getFieldProps("phone")}
        onChange={(value, data, event, formattedValue) => handlePhoneChange(value, data, event, formattedValue)}
        value="+91"
        countryCodeEditable={false}
        defaultCountry="in"
          onlyCountries={["in"]}
          masks={{
            in: "..........",
      
          }}
        isValid={PersonPhoneValidation}

      />
 
            {formik.touched.phone && phoneErrorMSg ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{phoneErrorMSg}</div>
                </div>
              ) : null}
        </div>
        </div>
        {/*end:: Phone: */}


  
            <div className="form-group fv-plugins-icon-container">
                <span className="d-flex justify-content-space-between"> 
                <label className="form-label auth-lable" htmlFor="formGridPassword">Password*</label>
                <label className="form-label auth-lable" htmlFor="formGridPassword1">
                </label>
                </span>
                <TextField
                    id="filled-adornment-password"
                    className={`1clsx(classes.margin, classes.textField) form-control form-control-solid h-auto `}
                    variant="filled"
                    type={values.showPassword ? 'text' : 'password'}
                    label="Password"
                    value={values.password}
                    onChange={handleChange('password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    name="password"
                    {...formik.getFieldProps("password")}
                  />   
                  <span className="d-flex" > 
                  {formik.touched.password && formik.errors.password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.password}</div>
                    </div>
                  ) : null}
            
                  </span>
                </div>

                <div className="form-group fv-plugins-icon-container">
                <span className="d-flex justify-content-space-between"> 
                <label className="form-label auth-lable" htmlFor="formGridConfirmPassword"> Confirm Password*</label>
                <label className="form-label auth-lable" htmlFor="formGridConfirmPassword">
                </label>
                </span>
                <TextField
                    id="filled-adornment-confirmPassword"
                    className={`1clsx(classes.margin, classes.textField) form-control form-control-solid h-auto `}
                    variant="filled"
                    type={values1.showConfirmPassword ? 'text' : 'password'}
                    label="Password"
                    value={values.confirmPassword}
                    onChange={handleChange('password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                          >
                            {values1.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    name="confirmPassword"
                  {...formik.getFieldProps("confirmPassword")}
                  />   
                  <span className="d-flex" > 
                  {(formik.touched.confirmPassword || formik.isSubmitting) && formik.values.confirmPassword != formik.values.password ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {(<div>Password do not match</div>)}
                    </div>
                  </div>
                ) : null}
           
                  </span>
                  <span className="mt-2">
                    <small >
                       Note: Use atleast 8 characters Use upper and lower case characters. Use atleast one number. Use atleast one special character. Max 12 characters
                    </small>
                  </span>
                </div>

        <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"

              {...formik.getFieldProps("acceptTerms")}
              onChange= {acceptTerms}
            />
            <span />
            <>
            I agree the</> 
            <a href="/terms" target="_blank" rel="noopener noreferrer"  className="mr-1 text-theme-green pl-2" rel="noopener noreferrer">
         <span className="text-theme-green"> Terms & Conditions </span> 
            </a>
            and
            <a href="/privacy" target="_blank" rel="noopener noreferrer" className="mr-1 text-dark pl-2" rel="noopener noreferrer">
            <span className="text-theme-green"> Privacy Policy </span> 
            </a>
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>
            <div className="form-group d-flex flex-wrap justify-content-space-between mt-50 auth-btns">         
                <Link to="#" className="d-flex justify-content-center align-items-center" style={{"alignItems":"center","display":"flex","justifyContent":"center"}} onClick={openfirsttab}   disabled={formik.isSubmitting} 
                className={`btn btn-theme btn-primary mw-40`}>
                Previous
                  {process.env.PUBLIC_URL}
                </Link>
                <button id="kt_login_signin_submit per-50 " type="submit" disabled={!(terms)} 
                className={`btn btn-theme-green mw-40  ${terms ? '' : 'no-drop'}`}>
                      Register    {process.env.PUBLIC_URL}
                  {loading && <span className="ml-3 spinner spinner-white"></span>}
                </button>  
            </div>                            
            </Tab.Pane>
          </Tab.Content>
    </Tab.Container>
      </form>      
      {/*end::Form*/}
    </div>
    </div>           
            {/*side image::Aside*/}
            <LoginAside />
        {/* end:: Side Image */}   
      </div>
    </div>

   <Footer/>

    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));