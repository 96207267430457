/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormFile from 'react-bootstrap/FormFile'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Table from 'react-bootstrap/Table'
import { divide } from 'lodash';
import Helmet from 'react-helmet'
import { Link, useHistory } from "react-router-dom";
import { Redirect } from 'react-router';
import alertService from '../../../_services/alertservice';
import { Button } from 'react-bootstrap';
import { CALL_HISTORY_METHOD } from 'react-router-redux';
import Modal from 'react-bootstrap/Modal'
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch } from 'react-redux';
import { logout } from '../../../../actions/auth';






const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const initialValues = {
  status: "open",
  page: 1,
  limit: 10,
  assignedToMe: false,
  createdByMe: "0",
  orderBy: "Id",
  orderType: "ASC"
}

function InterviewViewQuestion() {
   var ModuleId;
   const [QuestionsList, setQuestionsList] = useState([]);
   const [staticData, setStaticData] = useState(10);
   const [ parentCategories, setParentCategories ] = useState([]);
   const [searchTerm, SetSearchTerm] = useState("");
   const [createdbyme, SetCreatedbyme] = useState(false);
   const [assignedbyme, SetAssignedbyme] = useState(false);
   const [status, SetStatus] = useState("");
   const [selectedId,setSelectedOption] = useState(true);
   const [ subCategory, setSubCategory ] = useState([]);
   const [ subCategories, setSubCategories ] = useState([]);
   const [categorySelected, SetcategorySelected] = useState("");
   const [subcategorySelected, SetSubcategorySelected] = useState("");
   const [difficultyLevel, setDifficultyLevel ] = useState("");
   const [questionType, setQuestionType ] = useState("")
   const dispatch = useDispatch();



   const resetFilter = () => {
    SetAssignedbyme(false);
    SetCreatedbyme(false)
    SetSearchTerm("");
    SetStatus("");
    SetcategorySelected("");
    SetSubcategorySelected("");
    get_List_without_filters();
   }


// filter option
const SetSearchTermData = (x) => {
  let data =  x.target.value;
 if(data){
  SetSearchTerm(x.target.value);
 }
 else{
  SetSearchTerm("");
 }
}


function handleCategorySelect(event) {
  if(event.target.value){
    SetcategorySelected(event.target.value);
     get_sub_categories(event.target.value);
     hideLoader();
  }
  else{
    SetcategorySelected("");
    setSubCategories([]);
    hideLoader();
  }
  // document.getElementById("subcategoryvalue").value= ""
  var parentId = (event.target.value);
  // get_sub_categories(parentId);
}

function handleSubCategorySelect(event) {
  if(event.target.value){
    SetSubcategorySelected(event.target.value);
    hideLoader();
  }
  else{
    SetSubcategorySelected("");
    hideLoader();
  }

}


function handleDifficultyLevelSelect(event) {
  if(event.target.value){
    setDifficultyLevel(event.target.value);
  }
  else {
    setDifficultyLevel("");

  }

}



function handleQuestionTypeSelect(event) {
  if(event.target.value){
    setQuestionType(event.target.value);
  }
  else {
    setQuestionType("");

  }

}


const SetAssignedbymeData = (x) => {
  SetAssignedbyme(x.target.checked);
}

const SetCreatedbymeData = (x) => {
  SetCreatedbyme(x.target.checked);
}

// filter option


  var i = 1;
  const get_Question_List = () => {
    const token = localStorage.getItem('x-access-token');
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/interview/questions?page=1&limit=1000&orderBy=id&orderType=DESC`;
    //  history.push("/searchTerm");
    if(difficultyLevel) {
      url = url + "&difficultyLevel=" + difficultyLevel;

    }
    if(categorySelected) {
      url = url + "&categoryId=" + categorySelected;
    }
    if(subcategorySelected) {
      url = url + "&subCategoryId=" + subcategorySelected;
    }
    if(searchTerm) {
      url = url + "&searchBy=" + searchTerm;
    }
    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setQuestionsList(res.data);
          setStaticData(staticData + 10);
          hideLoader();
        }else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
        else {
        }
      })
      .catch(() => {
      });
  }



  const get_List_without_filters = () => {
    const token = localStorage.getItem('x-access-token');
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/interview/questions?page=1&limit=1000&orderBy=id&orderType=DESC`;
    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setQuestionsList(res.data);
          setStaticData(staticData + 10);
        }else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
        else {
        }
      })
      .catch(() => {
      });
  }



  const get_parent_categories = (values) => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/categories?page=1&limit=1000`, {

      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(values)
    })
    .then((res) => res.json())
    .then((res) => {
      if(res.status==true){
        setParentCategories(res.data);
      }else if(res.sessionExpired){
        alertService.alert({ message :res.message, type : 'error'  });
        logoutMethod();
      }
      else{
      }
    })
    .catch(() => {
    });
    }




  useEffect(() => {
    let mounted = true;
    get_Question_List();
    get_parent_categories();
    hideLoader();
    return () => mounted = false;
  }, [])

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();



  function actionFormatter(column, colIndex) {
    return (
      <div className="text-left d-flex">
        <img src="/media/VRekruitIcons/eyeorview.svg"  className="view-option c-p view-eye-ico-1" title="View" alt="View"  />

        <img src="/media/VRekruitIcons/edit.svg" className="edit-option c-p view-eye-ico ml-5" title="Edit" alt="Edit"  />


        <img src="/media/VRekruitIcons/delete.svg" className="delete-option c-p view-eye-ico ml-5" title="Delete" alt="Delete"  />

      </div>
    );
  }

  function  CreatedByUserFormater(column, colIndex) {
    return(
      <span>
        {column.firstName}  {column.lastName}
      </span>
    );
  }


  function headerformatter(column, colIndex) {
    return (
      <div>
        {column.text}
        <img src="/media/VRekruitIcons/sort.svg" className="sort-icon c-p" width="30" height="30" />
      </div>
    );
  }


  function headerformatterAction(column, colIndex) {
    return (
      <div>
         Action
      </div>
    );
  }



  const get_sub_categories = (value) =>{
    if(value){
      const token = localStorage.getItem('x-access-token');
      fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/sub_categories?page=1&limit=1000&parentCategoryId=` + value, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'x-access-token': token
        },
      })
      .then((res) => res.json())
      .then((res) => {
        if(res.status==true){
          setSubCategories(res.data);

        }else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }

      })
      .catch(() => {
      });
    }
    else {
      setSubCategories([]);
    }
  }

  function  questionformatter(column, colIndex) {
    return(
      <div className="overflow-text1">
          {column}
      </div>
    );
  }



 var columns = [{
    dataField: 'question',
    text: "Question Title",
    sort: true,
    formatter: questionformatter,
     headerFormatter: headerformatter,
     style: { 'width': '40%' }
  },

  {
    dataField: 'interviewCategory.categoryName',
    text: "Parent Category",
    sort: true,
    headerFormatter: headerformatter
  },
  {
    dataField: 'interviewSubCategory.subCategoryName',
    text: "Sub Category",
    sort: true,
    headerFormatter: headerformatter
  },
  {
    dataField: "action",
    text: "Action",
    sort: true,
    formatter: actionFormatter,
    headerFormatter: headerformatterAction
  },
  ];


  const options = {
    paginationSize: 5,
    // custom: true,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    //  withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    onPageChange: (page, sizePerPage) => {

    },
    disablePageTitle: true,
    sizePerPageList: [ {
      text: '20', value: 20
    },{
    text: '50', value: 50
    },
    {
      text: 'All', value: QuestionsList.length
    }] // A numeric array is also available. the purpose of above example is custom the text
  };


  const[ questionID,SetQuestionId] = useState("");
  const [QuestionData,setQuestionData]= useState({})
  const actionControls = {
    onClick: (e, row, rowIndex) => {
   if(row) {

       if (e.target.classList.contains("view-option")) {

        SetQuestionId(row.id);
        setQuestionData(row);
        handleClickOpen();
      //  history.push("/view-QuestionsList/" + id)
       // get_Question_List();
      }
      if (e.target.classList.contains("delete-option")) {
        SetQuestionId(row.id);
        ModuleId =  row.id;
        CanDeleteQuestion(row.id)
        // history.push("/view-QuestionsList/" + id)
        // get_Question_List();
       }
       if (e.target.classList.contains("edit-option")) {
        SetQuestionId(row.id);
        canEditQuestion(row.id)
        ModuleId =  row.id;
        // get_Question_List();
       }
    }
  }
  };
  const [modalShow, setModalShow] = React.useState(false);
  function DeleteConfirmationModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            <p className="text-center">
              <img alt="logo" className="brand-dim" src="/media/logos/UPDATED_VRekruit _Logo.png" />
            </p>
            <p className="text-dark text-center pt-3"> Are you sure want to delete this Question?</p>
          </Modal.Title>
        </Modal.Body>
        <Modal.Title className="d-flex justify-content-center align-items-center pb-5" >
        <p className="text-center">
        <Button className="btn btn-theme text-white btn-lg mr-5  pl-5 pr-5 pt-3 pb-3" style={{"minWidth": "100px"}} onClick={() => DeleteQuestion(questionID)}> Yes </Button>
       <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green" style={{"minWidth": "100px"}}  onClick={props.onHide}>No</Button>
       </p>
        </Modal.Title>
      </Modal>
    );
  }
  const DeleteQuestion = (questionID) => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/interview/delete_question/`+ questionID , {
      method: 'delete',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          alertService.alert({ message: res.message, type: 'success' });
          setModalShow(false);
          get_Question_List();
        }else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
        else {
          alertService.alert({ message: res.message, type: 'error' });
          setModalShow(false);
        }
      })
  }

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('lg');

  function CustomizedDialogs() {
    return (
      <div>
        <Dialog
        onClose={handleClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        fullScreen={fullScreen}
        aria-labelledby="customized-dialog-title"
        open={open}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          </DialogTitle>
          <DialogContent >
            <Typography gutterBottom>
            <Row className="pt-5" >
              <Form.Label className="fw-600" column sm="2">
                Question Text
              </Form.Label>
              <Col sm="10">
                <Form.Group as={Col} >
                  <p className="mt-2 css-p-tag"

                  >
                       {QuestionData ? QuestionData.question: "" }

                  </p>
                </Form.Group>
              </Col>
            </Row>
            </Typography>
            <Typography gutterBottom>
            <Row className="pt-5" >
              <Form.Label className="fw-600" column sm="2">
                Question Video
              </Form.Label>
              <Col sm="10">
                <Form.Group as={Col} >
                   <video className="video-container video-container-overlay modal-video-player" autoPlay={false} muted={false}
                   controls
                   poster={QuestionData.videoQuestion ? '' : '/media/bg/audio.gif'}
                   src={QuestionData ? QuestionData.videoPath+"#t=0.1": "" }
                   >
                  { /*  <source src={QuestionData ? QuestionData.videoPath: "" } type="video/mp4" /> */ }
                  </video>
                </Form.Group>
              </Col>
            </Row>
            </Typography>
            <Typography gutterBottom>
            <Row className="pt-5" >
              <Form.Label className="fw-600" column sm="2">
                Keywords
              </Form.Label>
              <Col sm="10">
              <Form.Group>
                <p className="mt-2 css-p-tag"
                  >
                  {QuestionData ? QuestionData.keywords: "" }
                  </p>
                </Form.Group>
              </Col>
            </Row>
            </Typography>
          </DialogContent>

        </Dialog>
      </div>
    );
  }


  const logoutMethod = () => {
    dispatch(logout());
    history.push('/auth/signin');
};



  const canEditQuestion = (value) => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/edit_delete_premission?module=interview_question&moduleId=` + value, {
      method: 'Get',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          if(res.data.canEdit){
            // setModalShow6(true);
            history.push("/interview/edit-question/" + value)

          }
          else if(res.data.editMessage)
            alertService.alert({ message: res.data.editMessage, type: 'error' });
          }
          else if(res.sessionExpired){
            // setModalShow6(false);
            logoutMethod();
            alertService.alert({ message :res.message, type : 'error'  });
          }

        else {
          alertService.alert({ message: res.message, type: 'error' });

        }
      })

  }


  const CanDeleteQuestion = (value) => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/edit_delete_premission?module=interview_question&moduleId=` + value, {
      method: 'Get',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === true) {
          if(res.data.canDelete) {
            // setModalShow3(true);
            setModalShow(true);

          }
          if(res.data.deleteMessage)
            {
              setModalShow(false);
             alertService.alert({ message: res.data.deleteMessage, type: 'error' });
            }
           else if(res.sessionExpired){
            setModalShow(false);
            alertService.alert({ message :res.message, type : 'error'  });
            logoutMethod();
          }
        }else if(res.sessionExpired){
          alertService.alert({ message :res.message, type : 'error'  });
          logoutMethod();
        }
        else {
          alertService.alert({ message: res.message, type: 'error' });
          setModalShow(false);
        }
      })
  }


 const  hideLoader = () => {
  setTimeout(function(){
    var ele  =   document.getElementById("no-data");
    if(ele) {
      ele.innerHTML = "No records found!"
    }
  }, 3000);
 }


  return (
    <>
      <Helmet>
        <title>
          View Question
        </title>
      </Helmet>


      {/* subeader */}
      <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Interview  Management</h3></div>
          </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
      {/* subeader */}


      <Col className={classes.root} >



    <Row className="bg-white  d-flex flex-grow-1 align-items-center  justify-content-start sticky-header">
    <Col md={6} sm={6} offset= {4}  className="pr-3 pl-3 mt-3">
      <div>
       <div className="   rounded">
        <div>
        <h5 className="font-weight-bold f-w-600 pl-5" > Question Management &nbsp;&nbsp; {'>'} &nbsp;&nbsp; Question List  </h5>
        </div>
      </div>
      </div>
      </Col>
      <Col md={6} sm={6} offset= {4}  className="pr-3 pl-3 mt-3">
      <div>
       <div className="d-flex flex-grow-1 align-items-center justify-content-end rounded mt-1">
        <div className="text-muted d-block">


           <Button  type="button" className="btn btn-theme-green btn-lg mr-5 mt-2 mb-0 d-none"  >
              View Public Question Bank
           </Button>

           <Link to="/interview/add-question" type="button" className="btn btn-theme-green btn-lg mr-5 mt-2 mb-0"  >
              Add Question
           </Link>

      </div>
      </div>
      </div>
      </Col>
      <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5"></Col>
      </Row>


        <Col className="Content-custom-padding">
          <Row className="align-items-center mt-5">
            <Col sm={4} md={3} className="mb-sm-5">
              <Form.Control as="select" placeholder="Status"  value={categorySelected}  onChange={(e) => handleCategorySelect(e)} >
                <option value="">-Parent Category-</option>
                {parentCategories.map
                      ( x=><option value={x.id}>{x.categoryName}</option>)
                }
\              </Form.Control>
            </Col>

            <Col sm={4} md={3} className="mb-sm-5">
              <Form.Control as="select" placeholder="-Sub Category-"  value={subcategorySelected}  onChange={(e) => handleSubCategorySelect(e)} >
                <option value="">-Sub Category-</option>


                {subCategories.map
                      ( x=><option value={x.id}>{x.subCategoryName}</option>)
                }


              </Form.Control>
            </Col>


            <Col sm={4} md={3} className="d-flex mb-sm-5">
            <Form.Label >
              </Form.Label>
              <div style={{"position":"relative", "width":"100%"}}>
                <div className="input-group">
                  <Form.Control type="text" value={searchTerm ? searchTerm : "" }   className="border-radius-12" placeholder="Search..." id="search-text" onInput={(e) => SetSearchTermData(e)}  style={{"paddingLeft":"35px",}} />
                     <div className="input-group-prepend">
                    </div>
                </div>
                    <i className="fa fa-search"  style={{"position":"absolute","left":"7px","top": "50%","transform": "translate(7px, -50%)","borderRadius":"8px"}} ></i>
                </div>
            </Col>


            <Col  sm={4} md={3} className="text-right mb-sm-5">
            <Button  type="button" className="btn btn-search ml-5 mb-sm-2" id="filter-btn" onClick={get_Question_List}  style={{"borderRadius":"8px"}} >
                 Apply
              </Button>

            <Button  type="button" className="btn btn-reset ml-5 pl-3 pr-3 mb-sm-2" id="reset-filter-btn"  onClick={resetFilter}  style={{"borderRadius":"8px"}} >
                  Reset
              </Button>
            </Col>

          </Row>
          <div>
            {QuestionsList &&  QuestionsList.length > 20 &&  QuestionsList.length > 0  && (
              <BootstrapTable className="bg-white" keyField='id' data={QuestionsList} columns={columns} options={options} pagination={paginationFactory(options)} rowEvents={actionControls} />
            )}

           {QuestionsList &&  QuestionsList.length === 0  && (
             <>
              <BootstrapTable className="bg-white" keyField='id' data={QuestionsList} columns={columns} options={options}  rowEvents={actionControls} />
             <p className="text-center mt-5 pt-5" style={{"fontSize":"30px","minHeight":"200px","display":"flex","justifyContent":"center","alignItems":"center"}}>

                 <ele id="no-data">
                 <i className="fas fa-spinner fa-pulse fa-2x"></i>
                  </ele>
             </p>
              </>
           )}
            {QuestionsList  && QuestionsList.length <= 20 && QuestionsList.length > 0 &&
            (
              <BootstrapTable
                   className="bg-white" keyField='id' data={QuestionsList} columns={columns} options={options}  rowEvents={actionControls} />
            )
            }
          </div>
        </Col>
      </Col>



      <DeleteConfirmationModal show={modalShow} onHide={() => setModalShow(false)} />
      <CustomizedDialogs></CustomizedDialogs>

    </>
  );
}

export default InterviewViewQuestion
