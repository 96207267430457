/* eslint-disable no-restricted-imports */
/* jshint asi:true */

import React, { useState,useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../modules/Auth/_redux/authRedux"
import Helmet from "react-helmet"
import { Formik,  Field } from 'formik';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import PhoneInput from 'react-phone-input-2'
import alertService from "../../_services/alertservice";
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useDispatch } from 'react-redux';
import { logout } from "../../../actions/auth";



// const { REACT_APP_TEST } = process.env;
// console.log(REACT_APP_TEST)
/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/
/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  dialCode:"",
  phone:"",
  firstName:"",
  lastName:"",
  email:"",
  password:"",
  roleId:"",
  empId:""
};



function AddNewUser(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [renderPage, setRenderPage]= useState(false); 
  const [selectedRole,SetSelectedRole]= useState("");
  
  const Roles = [
    { title: 'Recruiter', id: 4 },   
    { title: 'Hiring Manager', id: 3 }   
  ];

 
  const ValidationSchema = Yup.object().shape({
      firstName: Yup.string()
      .required('Required'),
      lastName: Yup.string()
      .required('Required'),
      email: Yup.string()
      .email("Wrong email format")
      .required('Required'),
      // roleId: Yup.string()
      // .required('Required'),
      password: Yup.string()
      .required('required')
      .min(8, 'Must Contain min 8 Characters!')
      .max(12, 'Must Contain max 12 Characters!!')
      .matches(
        /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ValidationSchema,
    validateOnChange:true,
    validateOnBlur:true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
        add_user_account(values, setStatus, setSubmitting);
    },
  });


  // password field
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false
  });

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };
 
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  // password field

  useEffect(() => {
    let mounted = true;
    // getUserProfile(); 
    return () => mounted = false;
  }, [])


  const getUserProfile = () =>{
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/user/get_profile`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
    .then((res) => res.json())
    .then((res) => {
      if(res.status==true){
        initialValues.dialCode = res.data.dialCode;
        initialValues.phone = res.data.phone;
        initialValues.firstName = res.data.firstName;
        initialValues.lastName = res.data.lastName;
        initialValues.email = res.data.email;
        setPhone(res.data.phone);
        setDialCode(res.data.dialCode);
        // initialValues.assignedTo = res.data.assignedTo;
        formik.resetForm();
        setRenderPage(true)
        // setSt(res.data);
      // disableLoading();
      // alert(res.message);
      // setSubmitting(false);  
      }
      else{
        // alert(res.message);
        // disableLoading();
        // setSubmitting(false);
      }
    })
    .catch(() => {
      // disableLoading();
      // setSubmitting(false);
    });
    }
    const history = useHistory();


const [formSubmit,SetFormSubmit]=useState(false);

  const add_user_account = (values, setStatus, setSubmitting) => {
    SetFormSubmit(true);
  if(!roleName){
    setLoading(false);
     return;
  }

  else if(phoneErrorMsg) {
    setLoading(false);
     return;

  }

 else{

     var reqdata = {
        dialCode: phone? dialCode:"",
        phone: phone,
        firstName: values.firstName,
        lastName:values.lastName,
        email:values.email,
        roleId: selectedRoleId,
        password:values.password,
        empId:values.empId  
     }

    const token = localStorage.getItem('x-access-token');
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/company/add_user`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(reqdata)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          disableLoading();
          setLoading(false);
          alertService.alert({ message : res.message, type : 'success'  });
          setSubmitting(false);
          history.push("/user/list")
        }
        else if(res.sessionExpired){
          logoutMethod();
          setLoading(false);
          alertService.alert({ message :res.message, type : 'error'  });
        }
        else {
          if (res.errors) {
            var errmsg = "";
            res.errors.forEach(function (value, index, array) {
              errmsg = errmsg + "(" + (index + 1) + ") " + value.msg + "\n\r";

            });
            alertService.alert({ message: errmsg, type: 'error' });
          }

          else{
            alertService.alert({ message : res.message, type : 'error'  });
          }
          disableLoading();
          setLoading(false);
          setSubmitting(false);
        }
      })
      .catch(() => {
        disableLoading();
        setLoading(false);
        setSubmitting(false);
      });
    }
  }

  const dispatch = useDispatch();
  const logoutMethod = () => {
    dispatch(logout());
    history.push('/auth/signin');
};


  const  submitFormMethod = () => {
    var submitbtn =  document.getElementById("submit-btn");
    if(submitbtn) {
      submitbtn.click();
    }
  }






  const[phoneErrorMsg, SetPhoneErrorMsg] = useState("");

  const PersonPhoneValidation = (value, country) => {
     if ( phone && phone.length !== 10) {
      SetPhoneErrorMsg("Phone Number must be 10 digits")
    }
    else {
      SetPhoneErrorMsg("");
      return true;
    }
  }


  const[dialCode, setDialCode] = useState("");
  const[phone, setPhone] = useState("");

  const handlePhoneChange = (value, data, event, formattedValue) => {
    if(data.dialCode){
      setPhone(value.slice(data.dialCode.length));
      setDialCode("+" + data.dialCode);
    }
  }

  const [roleName, setRoleName] = React.useState('');
  const [selectedRoleId, setInputValue] = React.useState('');

  const handleSelectChange = (value,e) => {
    if(value){
      setInputValue(value.id);
      setRoleName(value)      
    }  
  }



  if( localStorage.getItem("userTypeId") === "3")
  {
      
     return ""
    //  history.push("/error/error-v1")
  
  }
  else {


  return (
    <>
      <Helmet>
        <title> VRekruit | Create Sub User Account</title>
      </Helmet>

         {/* subeader */}
         <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600"> User Management </h3></div>
          </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
      {/* subeader */}
  
    
     <Row className="bg-white mb-0 pb-0 pt-5 sticky-header"> 
    <Col md={6} sm={12} offset= {4}  className="pr-3 pl-3">      
      <div>
       <div className="d-flex flex-grow-1 align-items-center  justify-content-start   rounded">     
        <div>
            <h5 className="font-weight-bold f-w-600 pl-5" > Create Sub User Account </h5>
            <p className="text-muted mb-0 pl-5"> Fill the information below to create sub user's account</p>
        </div>
      </div>
      </div>
      </Col>
      <Col md={6} sm={12} offset= {4}  className="pr-3 pl-3 text-center">
      <div>
       <div className="d-flex flex-grow-1 align-items-center justify-content-end    rounded">



        <div className="text-muted d-block mt-1" >
           <button type="submit" className="btn btn-theme-green btn-lg mr-5 "  onClick={submitFormMethod} 
           disabled={loading}
           >
              Save
              {loading && <span className="ml-3 spinner spinner-white"></span>}

           </button>
           <Link to="/user/list" type="button" className="btn btn-theme btn-lg mr-5  text-white">
              Cancel
           </Link>
      </div>
      </div>
      </div>
      </Col>
      <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5"></Col>  

      </Row>
      <Row className="bg-white pt-10 pb-4">


      <div className="container" style={{"minHeight":"60vh"}}>
            <Form  onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">


            <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    Employee Id
                  </Form.Label>
                  <Col sm={10} md={7}>
                    <Form.Control type="text" placeholder="" className={`form-control inp-h form-control-solid h-auto py-5 px-6`}
                      {...formik.getFieldProps("empId")}
                      
                      />
                      {/* {formik.touched.empId && formik.errors.empId ? 
                        (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.empId}</div>
                          </div>
                        ) 
                        : null} */}
                  </Col>
              </Form.Group>


              <Form.Group as={Row}>
                <Form.Label column sm={2}>
                  Role*
                </Form.Label>
                <Col sm={10} md={7}>
  
                <Autocomplete size="small"
                  id="roles-titles"
                  options={Roles}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Role" margin="normal" variant="outlined" />
                  )}
                  value={roleName}
                  getOptionLabel={
                    (options) => options.title}
                  onChange={(event, newValue) => {
                    handleSelectChange(newValue, event);
                  }}
                />
                   {formik.touched.roleId && formik.errors.roleId ? 
                        (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.roleId}</div>
                          </div>
                        ) 
                        : null}


                      {formik.touched.roleId && !roleName ? (
                         <div className="fv-plugins-message-container">
                        <div className="fv-help-block">Required</div>
                       </div>
                   ) : null}
                  
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    First Name*
                  </Form.Label>
                  <Col sm={10} md={7}>
                    <Form.Control type="text" placeholder="" className={`form-control inp-h form-control-solid h-auto py-5 px-6`}
                      {...formik.getFieldProps("firstName")}
                      />
                      {formik.touched.firstName && formik.errors.firstName ? 
                        (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.firstName}</div>
                          </div>
                        ) 
                        : null}

                        {/* {formik.touched.password && formik.errors.password ? 
                        (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.password}</div>
                          </div>
                        ) 
                        : null} */}
                  </Col>
              </Form.Group>
              <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                  Last Name*
                  </Form.Label>
                  <Col sm={10} md={7}>
                    <Form.Control type="text" placeholder="" className={`form-control inp-h form-control-solid h-auto py-5 px-6`}
                      {...formik.getFieldProps("lastName")}/>
                    {formik.touched.lastName && formik.errors.lastName ? 
                        (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.lastName}</div>
                          </div>
                        ) 
                        : null}

                  </Col>
              </Form.Group>

              <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    Email Address*
                  </Form.Label>
                  <Col sm={10} md={7}>
                    <Form.Control type="text" placeholder="" className={`form-control inp-h form-control-solid h-auto py-5 px-6`}
                      {...formik.getFieldProps("email")}
                      autoComplete="off"
                      />
                         {formik.touched.email && formik.errors.email ? 
                        (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.email}</div>
                          </div>
                        ) 
                        : null}
                  </Col>
              </Form.Group>

              <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    Phone Number
                  </Form.Label>
                  <Col sm={10} md={7}>
                  <PhoneInput name="companyPhone" className={`form-control form-control-solid h-auto py-5 px-6 tel`}  
                    {...formik.getFieldProps("phone")}
                    name="phone"
                    onChange={ (value, data, event, formattedValue) => handlePhoneChange(value, data, event, formattedValue)}
                    inputProps={{
                      name: 'phone',
                      required: false,
                      autoFocus: true
                    }}
                    // value={ dialCode + phone }
                    value="+91"
                    countryCodeEditable={false}
                      defaultCountry="in"
                        onlyCountries={["in"]}
                        masks={{
                          in: "..........",
                    
                        }}
                    isValid={PersonPhoneValidation}

                     />
                    {formik.touched.phone  && formSubmit && phoneErrorMsg ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{phoneErrorMsg}</div>
                    </div>
                    ) : null}
                </Col>
            
              </Form.Group>


              <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    Password*
                  </Form.Label>
                  <Col sm={10} md={7}>
                  <TextField
                    id="filled-adornment-password"
                    className={`1clsx(classes.margin, classes.textField) form-control form-control-solid h-auto `}
                    variant="filled"
                    type={values.showPassword ? 'text' : 'password'}
                    label="Password"
                    onChange={handleChange('password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    name="password"
                    {...formik.getFieldProps("password")}
                    autoComplete="off"
                  />  
                      {formik.touched.password && formik.errors.password ? 
                        (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.password}</div>
                          </div>
                        )   
                      : null}
                  </Col>
              </Form.Group>
              <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                  </Form.Label>
                  <Col sm={10} md={7}>
                    <small>
                      Note: Use atleast 8 characters Use upper and lower case characters. Use atleast 
                      one number. Use atleast one special character. Max 12 characters
                    </small>
                  </Col>
              </Form.Group>
  
              <button type="submit" id="submit-btn" className="d-none" >
                  {loading && <span className="ml-3 spinner spinner-white"></span>}
                    submit
              </button>
            </Form>   
            </div> 
      </Row>

      </>
      
  );
                 }
}

export default injectIntl(connect(null, auth.actions)(AddNewUser));
