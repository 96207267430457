import React from 'react'
import {Link} from "react-router-dom";


function TokenExpire() {
    return (
        <div>
           <div>
            <div className="d-flex justify-content-center align-items-center mt-5">  
            <img className="img-responsive mb-5" width="200" src="/media/logos/UPDATED_VRekruit _Logo.png"  />
            </div>
            <div className="d-flex justify-content-center align-items-center mt-5">  

            <h1>     
                 The verification link has expired. Please contact the administrator to generate a new link            
           </h1>
            </div>
        </div>    
        <div className="text-center text-center mt-5">

            <Link className="btn btn-primay" to="/">

            <button className="btn btn-primary"  >
              Take me to Homepage!
            </button>                
            </Link>  
            </div>        
        </div>
    )
}

export default TokenExpire

