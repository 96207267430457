/* eslint-disable no-restricted-imports */
/* jshint asi:true */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormFile from 'react-bootstrap/FormFile'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Table from 'react-bootstrap/Table'
import { divide } from 'lodash';
import Helmet from 'react-helmet'
import { useParams, Link, useHistory } from "react-router-dom";
import { Redirect } from 'react-router';
import alertService from '../../_services/alertservice';
import { Button } from 'react-bootstrap';
import { CALL_HISTORY_METHOD } from 'react-router-redux';
import Slider from '@material-ui/core/Slider';
import Modal from 'react-bootstrap/Modal'





const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const initialValues = {
  status: "open",
  page: 1,
  limit: 10,
  assignedToMe: false,
  createdByMe: "0",
  orderBy: "Id",
  orderType: "ASC"
}

function PreviewTest() {


 
   const [QuestionsList, setQuestionsList] = useState([]);
   const [staticData, setStaticData] = useState(10);
   const [ parentCategories, setParentCategories ] = useState([]); 
   const [searchTerm, SetSearchTerm] = useState("");
   const [createdbyme, SetCreatedbyme] = useState(false);
   const [assignedbyme, SetAssignedbyme] = useState(false);
   const [status, SetStatus] = useState("");
   const [selectedId,setSelectedOption] = useState(true);
   const [ subCategory, setSubCategory ] = useState([]);
   const [ subCategories, setSubCategories ] = useState([]);  
   const [categorySelected, SetcategorySelected] = useState("");
   const [subcategorySelected, SetSubcategorySelected] = useState("");
   const [difficultyLevel, setDifficultyLevel ] = useState("");
   const [questionType, setQuestionType ] = useState("")


   function valuetext(value) {
    return `${value}`;
  }

   const resetFilter = () => {
    SetSearchTerm("");
    get_List_without_filters();
   }






// filter option 
const SetSearchTermData = (x) => {
  let data =  x.target.value;
 if(data){
  SetSearchTerm(x.target.value);
 }
 else{
  SetSearchTerm("");
 }
} 

function handleCategorySelect(event) {
  if(event.target.value){
    SetcategorySelected(event.target.value);  
  }
  else{
    SetcategorySelected("");
  }          
  // document.getElementById("subcategoryvalue").value= ""    
  var parentId = (event.target.value);
  get_sub_categories(parentId);
}

function handleSubCategorySelect(event) {
  if(event.target.value){
    SetSubcategorySelected(event.target.value);
  }
  else{
    SetSubcategorySelected("");
  }          
 
}


function handleDifficultyLevelSelect(event) {
  if(event.target.value){
    setDifficultyLevel(event.target.value);
  }
  else {
    setDifficultyLevel("");

  }
          
}








function handleQuestionTypeSelect(event) {
  if(event.target.value){
    setQuestionType(event.target.value);
  }
  else {
    setQuestionType("");

  }
          
}

const SetAssignedbymeData = (x) => {
  SetAssignedbyme(x.target.checked);
}

const SetCreatedbymeData = (x) => {
  SetCreatedbyme(x.target.checked);
}


// filter option 


  var i = 1;
  const get_Test_List = () => {
 
    const token = localStorage.getItem('x-access-token');
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/test/get_list?page=1&limit=10&orderBy=id&orderType=ASC`;

    // if(valueQuestionsCount) {
    //   url = url + "&minQuestions=" + valueQuestionsCount[0] + "&maxQuestions=" + valueQuestionsCount[1] ;
    // }

    // if(valueMarks) {
    //   url = url + "&minMarks=" + valueMarks[0] + "&maxMarks=" + valueMarks[1]   ;
    // }

    if(searchTerm) {
      url = url + "&searchBy=" + searchTerm;
    }

    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setQuestionsList(res.data);
          setStaticData(staticData + 10);
        }
        else {
        }
      })
      .catch(() => {
      });
  }



  const get_List_without_filters = () => {
    const token = localStorage.getItem('x-access-token');
    var url = `${process.env.REACT_APP_API_BASE_URL}api/v1/test/get_list?page=1&limit=10&orderBy=id&orderType=ASC`;
    var url = '';
    fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          setQuestionsList(res.data);
          setStaticData(staticData + 10);
        }
        else {
        }
      })
      .catch(() => {
      });
  }


  const get_parent_categories = (values) => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/test/categories?page=1&limit=10`, {
      
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
      body: JSON.stringify(values)
    })
    .then((res) => res.json())
    .then((res) => {
      if(res.status==true){
        setParentCategories(res.data);
      }
      else{
      }
    })
    .catch(() => {
    });
    }


  useEffect(() => {
    let mounted = true;
    get_Test_List();
    // get_parent_categories();
    return () => mounted = false;
  }, [])

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();


  const [testId, SetTestId] = useState();


  const actionControls = {
    onClick: (e, row, rowIndex) => {
   if(row) {
       let id = row.id;
       if (e.target.classList.contains("view-option")) {
        history.push("/manage/view-test/"+id)
       // get_Test_List();
      }

      if (e.target.classList.contains("delete-option")) {
      setModalShow(true);
      SetTestId(id)
      }


      if (e.target.classList.contains("edit-option")) {
        SetTestId(id)
        history.push("/manage/edit-test/"+id)
        }

        if (e.target.classList.contains("share-option")) {
          SetTestId(id)
          history.push("/manage/share-test/"+id)
          }



    }
  }
  };


  function actionFormatter(column, colIndex) {
    return (
      <div className="text-left d-flex">
        <img src="/media/VRekruitIcons/eyeorview.svg"  className="view-option c-p view-eye-ico-1" />

        <img src="/media/VRekruitIcons/edit.svg" className="edit-option c-p view-eye-ico ml-5" />


        <img src="/media/VRekruitIcons/delete.svg" className="delete-option c-p view-eye-ico ml-5" />

        <img src="/media/VRekruitIcons/share.svg" className="share-option c-p view-eye-ico ml-5" />


      </div>
    );
  }


  function  CreatedByUserFormater(column, colIndex) {
    return(
      <span>
        {column.firstName}  {column.lastName} 
      </span>
    );
  }


  function headerformatter(column, colIndex) {
    return (
      <div>
        {column.text}
        <img src="/media/VRekruitIcons/sort.svg" className="sort-icon c-p" width="30" height="30" />
      </div>
    );
  }




  function headerformatterAction(column, colIndex) {
    return (
      <div>
         Action
      </div>
    );
  }



  const get_sub_categories = (parentId) =>{
    if(parentId){
      const token = localStorage.getItem('x-access-token');
      fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/test/sub_categories?page=1&limit=10&parentCategoryId=` + parentId, {        
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'x-access-token': token
        },
        // body: JSON.stringify(values)
      })
      .then((res) => res.json())
      .then((res) => {
        if(res.status==true){
          setSubCategories(res.data);
   
        }
       
      })
      .catch(() => {
      });
    }
    else {
      setSubCategories([]);
    }
  }


  

const [isSelected, SelectedQuestion] = useState(false);


const [formData, SetFormData] = useState(
  {
    testTitle:"",   
    questions:[ ],
  }
)
 
const setSelectedValue = (value) => {
  if(formData.questions.find(x => x.questionId == value)) {
    var deleted = formData.questions.splice(formData.questions.findIndex(x=> x.questionId == value) ,1)
  } else {
    formData.questions.push({questionId: value});
  }
  SetFormData({
    ...formData,
    questions: formData.questions
  })
}


  

    




 var columns = [  
  {
    dataField: 'testTitle',
    text: "TEST NAME",
    sort: true,
    headerFormatter: headerformatter
  },
  
  
  
  {
    dataField: 'questionCount',
    text: "No. of Questions",
    sort: true,
    headerFormatter: headerformatter
  },
  {
    dataField: 'marksCount',
    text: "Total Marks",
    sort: true,
    headerFormatter: headerformatter
  },

  {
    dataField: 'createdByUser'  ,
    text: "Created By",
    sort: true,
    formatter: CreatedByUserFormater,
    headerFormatter: headerformatter
  },



  {
    dataField: "action",
    text: "Action",
    sort: true,
    formatter: actionFormatter,
    headerFormatter: headerformatterAction
  },
  ];


  const options = {
    paginationSize: 5,
    // custom: true,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    //  withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    

    onPageChange: (page, sizePerPage) => {
 
    },
    disablePageTitle: true,
    sizePerPageList: [ {
      text: '20', value: 20
    },{
    text: '50', value: 50
    },
    {
      text: 'All', value: QuestionsList.length
    }] // A numeric array is also available. the purpose of above example is custom the text
    
  };


  const [valueMarks, setMarksValue] = React.useState([25, 75]);
  const [valueQuestionsCount, setQuestionCountValue] = React.useState([25, 75]);

  const handleChangeMarks = (event, newValue) => {
    setMarksValue(newValue);
  };

  const handleChangeQuestionCount = (event, newValue) => {
    setQuestionCountValue(newValue);
  };


  const [modalShow, setModalShow] = React.useState(false);


  function DeleteConfirmationModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            <p className="text-center">
            <img alt="logo" className="brand-dim" src="/media/logos/UPDATED_VRekruit _Logo.png" />
              </p>
            <p className="text-dark text-center pt-3"> Are you sure want to delete this test ?</p>
          </Modal.Title>
        </Modal.Body>
        <Modal.Title className="d-flex justify-content-center align-items-center pb-5" >
        <p className="text-center">

        <Button className="btn btn-theme text-white btn-lg mr-5  pl-5 pr-5 pt-3 pb-3" style={{"minWidth": "100px"}} onClick={DeleteListItem}> Yes </Button>
       <Button className="btn btn-theme-green btn-lg pl-5 pr-5 pt-3 pb-3 bg-theme-green" style={{"minWidth": "100px"}}  onClick={props.onHide}>No</Button> 
       </p>
        </Modal.Title>
      </Modal>
    );
  }

  const DeleteListItem = () => {
    const token = localStorage.getItem('x-access-token');
    fetch(`${process.env.REACT_APP_API_BASE_URL}api/v1/test/delete/` + testId , {
      method: 'delete',
      headers: {
        'content-type': 'application/json',
        'x-access-token': token
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status == true) {
          alertService.alert({ message: res.message, type: 'success' });
          setModalShow(false);
          get_Test_List();
        }
        else {
          alertService.alert({ message: res.message, type: 'error' });
          setModalShow(false);
        }
      })
  }

  return (
    <>
      <Helmet>
        <title>
           Manage Test
        </title>
      </Helmet>


      {/* subeader */}
      <div id="kt_subheader" className="subheader py-2 py-lg-4   subheader-solid">
        <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex align-items-baseline mr-5">
              <h3 className="text-dark font-weight-bold my-2 mr-5 f-w-600">Test Management</h3></div>
          </div>
          <div className="d-flex align-items-center" />
        </div>
      </div>
      {/* subeader */}

    <Col className={classes.root} >   
    <Row className="bg-white  d-flex flex-grow-1 align-items-center  justify-content-start">
    <Col md={6} sm={6} offset= {4}  className="pr-3 pl-3 mt-3">      
      <div>
       <div className="   rounded">     
        <div>
        <h5 className="font-weight-bold f-w-600 pl-5" > Test Bank  </h5>
        </div>
      </div>
      </div>
      </Col>
      <Col md={6} sm={6} offset= {4}  className="pr-3 pl-3 mt-3">
      <div>
       <div className="d-flex flex-grow-1 align-items-center justify-content-end rounded mt-1">
        <div className="text-muted d-block">
           <Link  to="/manual-test" className="btn btn-theme-green btn-lg mr-5 mt-2 mb-0"  >
              Create Test 
           </Link>
      </div>
      </div>
      </div>
      </Col>
      <Col md={12} sm={12} className="separator separator-solid mb-0 mt-5"></Col>  
      </Row>

      <Row className="mt-5 mb-sm-5">
        <Col sm={6} md={3}>
              <div className="d-flex">
                  <Typography id="range-slider" gutterBottom style={{"width":"120px"}}>
                    Total Marks
                  </Typography>
                  <Slider
                    value={valueMarks}
                    onChange={handleChangeMarks}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                   // getAriaValueText={valuetext}
                  />
                </div>
        </Col>


        <Col sm={6} md={3}>
              <div className="d-flex">
                  <Typography id="range-slider" gutterBottom style={{"width":"220px"}}>
                    No. of Questions
                  </Typography>
                  <Slider
                    value={valueQuestionsCount}
                    onChange={handleChangeQuestionCount}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                   // getAriaValueText={valuetext}
                  />
                </div>
        </Col>


        <Col sm={6} md={3} className="d-flex">
              
                <div style={{ "position": "relative","width":"100%" }}>
                  <div className="input-group">
                    <Form.Control type="text" value={searchTerm ? searchTerm : ""} placeholder="Search..." id="search-text" onInput={(e) => SetSearchTermData(e)} style={{ "paddingLeft": "35px", }} />
                    <div className="input-group-prepend">
                    </div>
                  </div>
                  <i className="fa fa-search" style={{ "position": "absolute", "left": "7px", "top": "50%", "transform": "translate(7px, -50%)", "borderRadius": "8px" }} ></i>
                </div>
              </Col>



              <Col sm={6} md={3} className="text-right" >
                <Button type="button" onClick={get_Test_List} className="btn btn-search ml-5" id="filter-btn" style={{ "borderRadius": "8px" }} >
                  Filter
              </Button>
              <Button type="button" onClick={resetFilter} className="btn reset-filter-btn  ml-5" id="filter-btn" style={{ "borderRadius": "8px" }} >
                  Reset
              </Button>
              </Col>

        </Row>

        <Col className="Content-custom-padding mt-5 mb-0 mb-sm-5">
 

          <div style={{"minHeight":"50vh"}}>
          
            {QuestionsList  && QuestionsList.length > 20 && (
              <BootstrapTable className="bg-white" keyField='id' data={QuestionsList} columns={columns} options={options} pagination={paginationFactory(options)} rowEvents={actionControls} />
            )}
            {QuestionsList  && QuestionsList.length <= 20 &&
            (
              <BootstrapTable className="bg-white" keyField='id' data={QuestionsList} columns={columns} options={options}  rowEvents={actionControls} />
            )
            } 
       
          </div>
        </Col>
      </Col>


      <DeleteConfirmationModal show={modalShow} onHide={() => setModalShow(false)} />


    </>
  );
}

export default PreviewTest